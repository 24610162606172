import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { TYPE_ICON, showBasePopup } from '@/components/base-popup/BasePopup';

import useAuthorization from '@/hooks/useAuthorization';

import { PROJECT_LIST_URL } from '@/utils/constants/RouteContants';

import { convertScenarioTypeToStep } from '../../ProjectExternalProxy';
import { SCENARIO_TYPES } from '../../contants';
import Categories from '../components/categories';
import Header, { HEADER_EVENT, STEP } from '../components/header';

const FirstStep = (props: { headerTitle?: string }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { headerTitle = t('project:add:title') } = props;
  const navigate = useNavigate();
  const formContext = useFormContext();
  const { user } = useAuthorization();
  const selectedCategory = formContext.watch('scenarioType');

  const handleClick = (event: HEADER_EVENT) => {
    if (event === HEADER_EVENT.NEXT && selectedCategory && selectedCategory !== SCENARIO_TYPES.EMPTY) {
      formContext.setValue('selectedStep', 2, { shouldDirty: false });
    } else if (event === HEADER_EVENT.CANCEL) {
      navigate(PROJECT_LIST_URL);
    }
  };

  const initFormValueForScenarioType = (scenarioType: SCENARIO_TYPES) => {
    formContext.reset(
      (prev) => ({
        ...prev,
        ...convertScenarioTypeToStep(scenarioType, undefined, user)
      }),
      {
        keepDirtyValues: false,
        keepDirty: false
      }
    );
  };

  const onSelectCategory = async (value: SCENARIO_TYPES) => {
    const currentValue = formContext.getValues('scenarioType');
    if (currentValue === value) return;
    const { isDirty } = formContext.formState;
    if (!isDirty && !id) {
      initFormValueForScenarioType(value);
      return;
    }
    try {
      const response = await showBasePopup({
        title: String(t('common:MSG_P_010:title')),
        msg: String(t('common:MSG_P_010:content')),
        type: TYPE_ICON.CONFIRM
      });
      if (response !== 'confirm') return;
      initFormValueForScenarioType(value);
    } catch (error) {}
  };
  return (
    <div className='flex flex-col gap-4 h-full'>
      <div className='sticky flex justify-between top-0 z-50 bg-gray1'>
        <div className='title-24 whitespace-pre-line font-bold text-24'>{headerTitle}</div>
        <Header
          buttonProps={[undefined, undefined, { disabled: !selectedCategory || selectedCategory === SCENARIO_TYPES.EMPTY }, undefined, undefined]}
          step={STEP.FIRST}
          onClick={handleClick}
        />
      </div>
      <div className='h-[calc(-136px + 100vh)] overflow-y-scroll w-full border border-solid border-gray2 rounded-lg p-4 bg-white'>
        <p className='subtitle-18 text-black'>{t('project:scenario:title_scenario')}</p>
        <Categories selected={selectedCategory} onChange={onSelectCategory} />
      </div>
    </div>
  );
};

export default FirstStep;
