import { Col, Row } from 'antd';
import { forwardRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseCard } from '@/components/base-card/BaseCard';
import { BaseCollapse } from '@/components/base-collapse/BaseCollapse';
import { FormAttachment } from '@/components/form-attachment/FormAttachment';
import { EditorWithCounter } from '@/components/form-box-editor';

import { NOTE_BASIC_INFO_MAX_LENGTH } from '@/utils/constants/AppConstants';
import { stripHtml } from '@/utils/helpers/globalHelper';

type Props = {
  formMethod: any;
};

const UploadFilesComponent = forwardRef((props: Props, ref) => {
  const { formMethod } = props;
  const { t } = useTranslation();
  const questions = useMemo(() => {
    return stripHtml(formMethod.getValues('note') || '').length ? 1 : 0;
  }, [formMethod.watch('note')]);
  return (
    <BaseCollapse
      ref={ref}
      id='projectAttachments'
      title={t('basic_information:additional_information')}
      isError={!!formMethod?.formState?.errors?.note || !!formMethod?.formState?.errors?.projectAttachments}
    >
      <Row className='mt-2'>
        <Col span={24}>
          <BaseCard title={t('basic_information:note')} total={1} questions={questions}>
            <EditorWithCounter
              name='note'
              limit={NOTE_BASIC_INFO_MAX_LENGTH}
              defaultValue={formMethod.getValues('note')}
              toolbar={null}
              placeholder={t('basic_information:place_holder_note') || ''}
              editorWrapperProps={{ className: '!h-[148px]' }}
            />
          </BaseCard>
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col span={24}>
          <BaseCard className='mt-4' title={t('basic_information:attachment_file')}>
            <FormAttachment
              name='projectAttachments'
              defaultValue={formMethod.getValues('projectAttachments') || []}
              documentType='attachments'
            ></FormAttachment>
          </BaseCard>
        </Col>
      </Row>
    </BaseCollapse>
  );
});

export default UploadFilesComponent;
