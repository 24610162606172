import { KeyboardEventHandler } from 'react';
import { Control, FieldValues, useController } from 'react-hook-form';

import IconSearch from '@/assets/icons/base-filter/icon-search.svg';

import './SearchBox.scss';

interface ISearchBoxProps {
  disabled?: boolean;
  placeholder?: string;
  maxLength?: number;
  name: string;
  control?: Control<FieldValues>;
  onSubmit: () => void;
}

const SearchBox = ({ disabled, placeholder, maxLength, control, name, onSubmit }: ISearchBoxProps) => {
  const { field } = useController({
    name,
    control,
    rules: { maxLength }
  });

  const autoTrim = () => {
    if (field.value) {
      const trimValue = field.value.trim();
      field.onChange(trimValue);
    }
  };

  const handleOnKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key !== 'Enter') return;
    autoTrim();
    onSubmit();
  };

  return (
    <div className='search-box-wrapper'>
      <IconSearch width={20} height={20} />
      <input
        {...field}
        autoComplete='off'
        disabled={disabled}
        type='text'
        placeholder={placeholder}
        maxLength={maxLength}
        onKeyDown={handleOnKeyDown}
        onBlur={autoTrim}
      />
    </div>
  );
};

export default SearchBox;
