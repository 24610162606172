import { useAppSelector } from '@/hooks';
import { Checkbox, Drawer, Spin, Tabs } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AppTooltip from '@/components/app-tooltip/AppTooltip';
import { EmtyIcon } from '@/components/icon-svg/IconSvg';

import { setAlertNotification } from '@/redux/globalReducer';
import {
  fetchCountNoticeUnRead,
  fetchDataNotice,
  fetchReadNotice,
  setCurrentTabNotice,
  setNoticeData,
  setOpenNotice,
  setPayloadFilterNotice,
  setPayloadSearchNotice,
  setRefreshPage
} from '@/redux/notice/noticeReducer';
import { AppDispatch } from '@/redux/store';

import useAuthorization from '@/hooks/useAuthorization';
import useFetch from '@/hooks/useFetch';
import useMutation from '@/hooks/useMutation';

import { API } from '@/utils/constants/Apis';
import { LOCALSTORAGE, TYPE } from '@/utils/constants/AppConstants';
import { HTTP_STATUS_CODE } from '@/utils/constants/Http';
import { DataViewer } from '@/utils/helpers/common';
import { returnPageCreateNotice } from '@/utils/helpers/notification';
import { INoticeData, IParamFilterNoticeProps } from '@/utils/interfaces/notificationInterface';

import Bookmark from '@/assets/icons/notification/Bookmark.svg';
import BookmarkSimple from '@/assets/icons/notification/BookmarkSimple.svg';
import Display from '@/assets/icons/notification/Eye.svg';
import Hidden from '@/assets/icons/notification/EyeSlash.svg';

import Footer from './components/Footer';
import Header from './components/Header';
import TypeMessageNotice from './components/TypeMessageNotice';
import { TYPE_CHECK_BOX, TYPE_NOTICE_EXTERNAL, TYPE_NOTICE_INTERNAL_ADMIN, TYPE_NOTICE_INTERNAL_USER } from './constants';
import { IAccountData, INoticeHiddenProps, ITitleNoticeProps } from './models';

import './Styles.scss';

interface INoticeForm {
  title?: string;
  width?: number;
}

const NoticeForm = ({ title, width }: INoticeForm) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const open = useAppSelector((state) => state.notice.openNotice);
  const formMethod = useForm<any>({
    mode: 'all'
  });
  const [loadingFilter, setLoadingFilter] = useState<boolean>(false);
  const { isInternalRole, isInternalAdmin } = useAuthorization();
  const typeNotice = isInternalRole ? (isInternalAdmin ? TYPE_NOTICE_INTERNAL_ADMIN : TYPE_NOTICE_INTERNAL_USER) : TYPE_NOTICE_EXTERNAL;
  const informationUser = JSON.parse(String(localStorage.getItem(LOCALSTORAGE.USER)));
  const noticeData = useAppSelector((state) => state.notice.noticeData);
  const currentTab = useAppSelector((state) => state.notice.currentTabNotice);
  const payloadSearch = useAppSelector((state) => state.notice.payloadSearchNotice);
  const payloadFilter = useAppSelector((state) => state.notice.payloadFilterNotice);
  const { data: defaultParamFilter } = useFetch<IParamFilterNoticeProps>(API.GET_ASSIGN_NOTICE, 'GET');
  const drawerNoticeUnRead = useAppSelector((state) => state.notice.drawerNoticeUnRead);
  const countFieldFilter = useAppSelector((state) => state.notice.countFieldFilterNotice);
  const [noticeHiddenChoose, setNoticeHiddenChoose] = useState<IAccountData[]>([]);
  const [showUiHidden, setShowUiHidden] = useState(false);
  const [showButtonHidden, setShowButtonHidden] = useState(false);

  // Off drawer form notice
  const handleOnClose = () => {
    dispatch(setOpenNotice(false));
  };

  const { mutate: postHiddenNotice } = useMutation(API.POST_HIDDEN_NOTICE, {
    method: 'POST',
    bodyType: 'json'
  });
  const { mutate: postAssignNotice } = useMutation(API.POST_ASSIGN_NOTICE, {
    method: 'POST',
    bodyType: 'json'
  });
  const { mutate: postFlagNotice } = useMutation(API.GET_FLAG_NOTICE, {
    method: 'POST',
    bodyType: 'json'
  });

  const generateDefaultFilterForm = () => {
    if (DataViewer.isShallowEmpty(payloadFilter)) {
      return {
        formValue: {}
      };
    }

    const { flag, hidden, order, reporter, reporterFlag } = payloadFilter;
    const pushNoticeType = [...(flag ? [TYPE_CHECK_BOX.NOTICE_FLAG] : []), ...(hidden ? [TYPE_CHECK_BOX.NOTICE_HIDDEN] : [])];
    const pushReportCheck = reporterFlag ? [TYPE_CHECK_BOX.SHOW_LIST_ACCOUNT] : [];

    return {
      formValue: {
        primary: {
          order,
          noticeType: pushNoticeType,
          showListAccount: pushReportCheck,
          accountSelect: reporter
        }
      }
    };
  };

  const itemsTagInternalUser = [
    {
      label: t('notification:project'),
      type: TYPE_NOTICE_INTERNAL_ADMIN.PROJECT,
      total: 0
    },
    {
      label: t('notification:task'),
      type: TYPE_NOTICE_INTERNAL_ADMIN.TASK,
      total: 0
    },
    {
      label: t('notification:approval_result'),
      type: TYPE_NOTICE_INTERNAL_ADMIN.RESULTS,
      total: 0
    },
    {
      label: t('notification:external'),
      type: TYPE_NOTICE_INTERNAL_ADMIN.EXTERNAL,
      total: 0
    }
  ];

  const tagRequest = {
    label: t('notification:approval_request'),
    type: TYPE_NOTICE_INTERNAL_ADMIN.REQUEST,
    total: 0
  };

  const itemsTagExternal = [
    {
      label: t('notification:task'),
      type: TYPE_NOTICE_EXTERNAL.PROJECT,
      total: 0
    },
    {
      label: t('notification:notice'),
      type: TYPE_NOTICE_EXTERNAL.ANNOUNCEMENT,
      total: 0
    },
    {
      label: t('notification:others'),
      type: TYPE_NOTICE_EXTERNAL.OTHERS,
      total: 0
    }
  ];

  const itemsTagInternal = structuredClone([...itemsTagInternalUser]);
  if (isInternalAdmin) {
    itemsTagInternal.splice(3, 0, tagRequest);
  }

  const [titleTagNotice, setTitleTagNotice] = useState<ITitleNoticeProps[]>(isInternalRole ? itemsTagInternal : itemsTagExternal);

  // Set total notice unread for each type
  useEffect(() => {
    const setCountNotice = titleTagNotice.map((item) => ({
      ...item,
      total: drawerNoticeUnRead[item.type] ?? item.total
    }));
    setTitleTagNotice(setCountNotice);
  }, [drawerNoticeUnRead]);

  useEffect(() => {
    if (defaultParamFilter) {
      const { flag, hidden, order } = defaultParamFilter;
      const searchNotice = {
        type: typeNotice.PROJECT,
        hidden,
        flag,
        sorts: 'createdDate=' + order,
        pageIndex: 0,
        pageSize: 150
      };
      dispatch(fetchDataNotice(searchNotice));
      dispatch(fetchCountNoticeUnRead(isInternalRole));
      dispatch(setPayloadSearchNotice(searchNotice));
      dispatch(setPayloadFilterNotice(defaultParamFilter));
      dispatch(setCurrentTabNotice(typeNotice.PROJECT));
      setShowButtonHidden(Boolean(defaultParamFilter.hidden));
    }
  }, [defaultParamFilter]);

  const onChangeTab = (index: string) => {
    // Get type notice with index
    const keys = Object.keys(typeNotice);
    const key = keys[Number(index) - 1];
    const currentTab = typeNotice[key];
    dispatch(setCurrentTabNotice(currentTab));

    // Update data with new tab key
    const searchNotice = {
      ...payloadSearch,
      type: currentTab,
      pageIndex: 0,
      pageSize: 150
    };
    dispatch(fetchDataNotice(searchNotice));
    setNoticeHiddenChoose([]);
    setShowUiHidden(false);
  };

  const handleFilterAndSearch = async (values: FieldValues, isFilter?: boolean) => {
    setLoadingFilter(true);
    const hidden = (values?.primary?.noticeType ?? []).includes(TYPE_CHECK_BOX.NOTICE_HIDDEN);
    const flag = (values?.primary?.noticeType ?? []).includes(TYPE_CHECK_BOX.NOTICE_FLAG);
    // Set payload api search notice
    const searchNotice = {
      type: currentTab,
      hidden,
      flag,
      searchKey: values?.searchNotice,
      sorts: 'createdDate=' + values.primary?.order,
      pageIndex: 0,
      pageSize: 150
    };
    if (isFilter) {
      // Set payload api assign payload filter
      const filterNotice = {
        order: values.primary?.order,
        hidden,
        flag,
        reporterFlag: (values.primary?.showListAccount ?? []).includes(TYPE_CHECK_BOX.SHOW_LIST_ACCOUNT),
        reporter: values.primary?.accountSelect
      };
      await postAssignNotice(filterNotice);
      dispatch(setPayloadFilterNotice(filterNotice));
      dispatch(fetchCountNoticeUnRead(isInternalRole));
    }
    dispatch(fetchDataNotice(searchNotice));
    dispatch(setPayloadSearchNotice(searchNotice));

    setShowButtonHidden(Boolean(searchNotice.hidden));
    setShowUiHidden(false);
    setNoticeHiddenChoose([]);
    setLoadingFilter(false);
  };

  const onFilter = async (values: FieldValues) => {
    await handleFilterAndSearch(values, true);
  };

  const onSearch = async (values: FieldValues) => {
    await handleFilterAndSearch(values);
  };

  const handleReadedNotice = async (data: INoticeData, indexNotice: number) => {
    handleOnClose();
    // If notice readed don't call the api
    if (!data.readed) {
      // Update data change type readed is true
      dispatch(fetchReadNotice({ notice: data, index: indexNotice }));
      dispatch(fetchCountNoticeUnRead(isInternalRole));
    }

    const urlNavigate = await returnPageCreateNotice(data);
    // Go to page send notice
    if (urlNavigate && urlNavigate !== window.location.pathname) {
      navigate(urlNavigate);
    } else {
      // Set state show loading refresh page is true
      dispatch(setRefreshPage(true));

      // Set state show loading refresh page is false
      setTimeout(() => {
        dispatch(setRefreshPage(false));
      }, 0);
    }
  };

  const handleFlagNotice = async (idFlag: string, index: number) => {
    // Update data have flag
    const updatedData = structuredClone(noticeData.data);
    updatedData[index] = { ...updatedData[index], flag: !updatedData[index]?.flag };
    dispatch(setNoticeData(updatedData));

    // Call api flag notice
    await postFlagNotice({ flags: [{ id: idFlag }] });
  };

  const handleChooseNoticeHidden = (e: CheckboxChangeEvent, item: INoticeData, index: number) => {
    const { checked } = e.target;
    const updatedData = structuredClone(noticeData.data);
    updatedData[index] = { ...updatedData[index], hiddenCheck: checked };
    dispatch(setNoticeData(updatedData));

    setNoticeHiddenChoose((prevNotices) => {
      const exists = prevNotices.some((notice) => notice.id === item.id);
      if (exists) {
        return prevNotices.filter((notice) => notice.id !== item.id);
      } else {
        return [...prevNotices, item];
      }
    });
  };

  const handleShowUiHidden = (value: boolean) => {
    if (!value) {
      // Reset all notice choose hidden to original
      setNoticeHiddenChoose([]);
      const updatedData = noticeData.data.map((item) => (item.hiddenCheck === true ? { ...item, hiddenCheck: false } : item));
      dispatch(setNoticeData(updatedData));
    }
    setShowUiHidden(value);
  };

  const remove = async (payloadRequest: INoticeHiddenProps) => {
    // Call api hidden notice choose
    const result = await postHiddenNotice(payloadRequest);
    if (result?.status === HTTP_STATUS_CODE.SUCCESS) {
      dispatch(
        setAlertNotification({
          show: true,
          type: TYPE.SUCCESS,
          message: t('common:MSG_C_022')
        })
      );
    }
    setNoticeHiddenChoose([]);

    // Remove hidden choose from list notice
    const idsToRemove = payloadRequest.hiddenNotices.map((item: any) => item.id);
    const updatedData = noticeData.data.filter((item) => !idsToRemove.includes(item.id));
    dispatch(setNoticeData(updatedData));
    // Call api re-count unread messages
    dispatch(fetchCountNoticeUnRead(isInternalRole));
  };

  const handleHiddenOnlyNotice = (item: INoticeData) => {
    remove({ hiddenNotices: [{ id: item.id }] });
  };

  const handleHiddenNotices = () => {
    const payloadRequest = {
      hiddenNotices: noticeHiddenChoose.map((notice) => ({ id: notice.id }))
    };
    remove(payloadRequest);
  };

  const renderTagTitle = (title: string, unreadNotice: number) => {
    return (
      <div className='title-drawer d-flex pt-4'>
        <div className='body-700 pb-2'>{title}</div>
        <div className='min-w-[24px]'>
          {unreadNotice > 0 && <div className='count-notice max-h-[23px] ml-1 mt-[-12px] px-2'>{unreadNotice > 99 ? '99+' : unreadNotice}</div>}
        </div>
      </div>
    );
  };

  const renderScrollUi = (data: INoticeData[]) =>
    data?.map((item, index) => (
      <div
        key={index + 1}
        className={classNames('item-drawer', 'justify-space-between', ' py-2', item.readed ? '' : 'unread-item', item.hidden ? 'hidden-item' : '')}
        onClick={() => {
          handleReadedNotice(item, index);
        }}
      >
        <div className='d-flex pl-5'>
          <div className='checkbox-form center-item'>
            {showUiHidden && (
              <Checkbox
                checked={item.hiddenCheck}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={(e) => {
                  handleChooseNoticeHidden(e, item, index);
                }}
              />
            )}
          </div>
          <TypeMessageNotice data={item} />
        </div>
        <div className='center-item min-w-[68px]'>
          {!item.hidden && (
            <div className='center-item h-[20px]'>
              {item.flag ? (
                <Bookmark
                  className='cursor-pointer'
                  onClick={(e) => {
                    e.stopPropagation();
                    handleFlagNotice(item.id, index);
                  }}
                />
              ) : (
                <AppTooltip title={t('notification:flagged')}>
                  <BookmarkSimple
                    className='icon-hover cursor-pointer'
                    onClick={(e) => {
                      e.stopPropagation();
                      handleFlagNotice(item.id, index);
                    }}
                  />
                </AppTooltip>
              )}
            </div>
          )}
          {item.hidden ? (
            <AppTooltip title={t('notification:display')}>
              <Display
                className='ml-3 cursor-pointer'
                onClick={(e) => {
                  e.stopPropagation();
                  handleHiddenOnlyNotice(item);
                }}
              />
            </AppTooltip>
          ) : (
            <AppTooltip title={t('notification:hidden')}>
              <Hidden
                className='icon-hover ml-3 cursor-pointer'
                onClick={(e) => {
                  e.stopPropagation();
                  handleHiddenOnlyNotice(item);
                }}
              />
            </AppTooltip>
          )}
        </div>
      </div>
    ));

  const renderTagData = (data: INoticeData[]) => {
    if (loadingFilter || noticeData.loading) {
      return (
        <div className='text-center mt-10'>
          <Spin size='large' />
        </div>
      );
    }

    if (data.length === 0) {
      return (
        <div className='flex flex-col items-center mt-[34px] '>
          <EmtyIcon className='w-[60px] h-[60px] mb-3' />
          <div className='body-400'>{t('question:not_data')}</div>
        </div>
      );
    }

    return (
      <div className='body-drawer'>
        <div className={classNames('form-item', 'overflow-auto', noticeHiddenChoose.length > 0 ? '' : 'form-item-hidden')}>
          {renderScrollUi(data)}
        </div>
        {noticeHiddenChoose.length > 0 && <Footer totalNoticeChoose={noticeHiddenChoose.length} onSubmit={handleHiddenNotices} />}
      </div>
    );
  };

  return (
    <Drawer
      title={
        <Header
          onFilter={(values) => {
            onFilter(values);
          }}
          onSearch={(values) => {
            onSearch(values);
          }}
          form={formMethod}
          title={title}
          data={noticeData.data}
          total={countFieldFilter}
          valueFilter={generateDefaultFilterForm()}
          informationUser={informationUser}
          uiHidden={showUiHidden}
          buttonHidden={!showButtonHidden}
          showUiHidden={handleShowUiHidden}
        />
      }
      onClose={handleOnClose}
      open={open}
      width={width}
      closable={false}
      className='form-drawer'
    >
      <Tabs
        defaultActiveKey='1'
        destroyInactiveTabPane={true}
        onChange={onChangeTab}
        items={titleTagNotice.map((item, index) => {
          const id = String(index + 1);
          return {
            key: id,
            label: renderTagTitle(item.label, item.total),
            children: renderTagData(noticeData.data)
          };
        })}
      />
    </Drawer>
  );
};

export default NoticeForm;
