import type { PaginationProps } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Footer.module.scss';

interface TableFooterProps extends PaginationProps {
  hasFilter: boolean;
}

const TableFooter = (props: TableFooterProps) => {
  const { current = 0, pageSize = 0, total = 0, hasFilter } = props;
  const { t } = useTranslation();
  const start = total ? (current - 1) * pageSize + 1 : 0;
  const end = Math.min(current * pageSize, total);

  return (
    <div className='flex justify-between items-center mt-4'>
      <span
        className={styles.footerLabel}
      >{`${hasFilter ? t('common:table:search_result') : ''} ${start}-${end} / ${total}${t('common:table:record')}`}</span>
    </div>
  );
};

export default TableFooter;
