import { Breadcrumb } from 'antd';
import { BreadcrumbItemType, BreadcrumbSeparatorType } from 'antd/es/breadcrumb/Breadcrumb';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PathMatch, useMatch, useNavigate, useParams } from 'react-router-dom';

import {
  ACCOUNT_MASTER_LIST_URL,
  ADD_TASK_OF_PROJECT_URL,
  ANNOUNCEMENT_LIST_URL,
  APPROVE_REJECT_ACCOUNT_MASTER_URL,
  BATCH_REGISTRATION_PROJECT_URL,
  CREATE_ACCOUNT_MASTER_URL,
  CREATE_ANNOUNCEMENT_URL,
  CREATE_PROJECT_INQUIRY_URL,
  CREATE_PROJECT_URL,
  CREATE_TEMPLATE_URL,
  EDIT_ANNOUNCEMENT_URL,
  EDIT_PROJECT_URL,
  EDIT_TASK_OF_PROJECT_URL,
  EDIT_TEMPLATE_URL,
  PROJECT_FILE_LIST_URL,
  PROJECT_LIST_URL,
  PROJECT_QUESTION_DETAIL_URL,
  PROJECT_QUESTION_LIST_URL,
  STAKEHOLDER_UPDATE_URL,
  STAKEHOLDER_VIEW_URL,
  TEMPLATE_LIST_URL,
  UPDATE_ACCOUNT_MASTER_URL,
  VIEW_ANNOUNCEMENT_URL,
  VIEW_PROJECT_URL,
  VIEW_TASK_OF_PROJECT_URL,
  VIEW_TEMPLATE_URL
} from '@/utils/constants/RouteContants';

import ArrowRightIcon from '@/assets/icons//arrow-right.svg';

import BreadCrumbTitle from './BreadCrumbTitle';

import './BreadCrumb.scss';

const Separator = () => {
  return (
    <div className='mt-[2px]'>
      <ArrowRightIcon />
    </div>
  );
};

export const LINKED_LIST_ID = {
  PROJECT_LIST: 'PROJECT_LIST',
  VIEW_PROJECT: 'VIEW_PROJECT',
  ANNOUNCEMENT_LIST: 'ANNOUNCEMENT_LIST',
  ANNOUNCEMENT_VIEW: 'ANNOUNCEMENT_VIEW',
  CREATE_ANNOUNCEMENT: 'CREATE_ANNOUNCEMENT',
  ANNOUNCEMENT_EDIT: 'ANNOUNCEMENT_EDIT',
  PROJECT_FILE_LIST: 'PROJECT_FILE_LIST',
  EDIT_PROJECT: 'EDIT_PROJECT',
  CREATE_PROJECT: 'CREATE_PROJECT',
  TEMPLATE_LIST: 'TEMPLATE_LIST',
  VIEW_TEMPLATE: 'VIEW_TEMPLATE',
  CREATE_TEMPLATE: 'CREATE_TEMPLATE',
  EDIT_TEMPLATE: 'EDIT_TEMPLATE',
  ACCOUNT_MASTER_LIST: 'ACCOUNT_MASTER_LIST',
  CREATE_ACCOUNT_MASTER: 'CREATE_ACCOUNT_MASTER',
  APPROVE_REJECT_ACCOUNT_MASTER: 'APPROVE_REJECT_ACCOUNT_MASTER',
  UPDATE_ACCOUNT_MASTER: 'UPDATE_ACCOUNT_MASTER',
  PROJECT_INQUIRY: 'PROJECT_INQUIRY',
  STAKEHOLDER_VIEW: 'STAKEHOLDER_VIEW',
  STAKEHOLDER_UPDATE: 'STAKEHOLDER_UPDATE',
  ADD_TASK_OF_PROJECT: 'ADD_TASK_OF_PROJECT',
  EDIT_TASK_OF_PROJECT: 'EDIT_TASK_OF_PROJECT',
  VIEW_TASK_OF_PROJECT: 'VIEW_TASK_OF_PROJECT',
  QUESTIONNAIRE_LIST: 'QUESTIONNAIRE_LIST',
  PROJECT_QUESTION_DETAIL_URL: 'PROJECT_QUESTION_DETAIL',
  BATCH_REGISTRATION_URL: 'BATCH_REGISTRATION'
} as const;
export type ILinkedListId = (typeof LINKED_LIST_ID)[keyof typeof LINKED_LIST_ID];
interface ILinkedList {
  id: string;
  href?: string;
  match: PathMatch<string> | null;
  title: React.ReactNode;
  children?: ILinkedList[];
  className?: string;
  externalLink?: string;
}
const AppBreadCrumb = () => {
  const { t } = useTranslation();
  const { id, idProject } = useParams();
  const navigate = useNavigate();

  const MATCHES = {
    PROJECT_LIST: useMatch(PROJECT_LIST_URL),
    CREATE_PROJECT: useMatch(CREATE_PROJECT_URL),
    VIEW_PROJECT: useMatch(VIEW_PROJECT_URL(':id')),
    EDIT_PROJECT: useMatch(EDIT_PROJECT_URL(':id')),
    ADD_TASK_OF_PROJECT: useMatch(ADD_TASK_OF_PROJECT_URL(':idProject')),
    EDIT_TASK_OF_PROJECT: useMatch(EDIT_TASK_OF_PROJECT_URL(':idProject', ':idTask')),
    ANNOUNCEMENT_LIST: useMatch(ANNOUNCEMENT_LIST_URL(':id')),
    ANNOUNCEMENT_VIEW: useMatch(VIEW_ANNOUNCEMENT_URL(':id', ':announcementId')),
    ANNOUNCEMENT_EDIT: useMatch(EDIT_ANNOUNCEMENT_URL(':id', ':announcementId')),
    PROJECT_FILE_LIST: useMatch(PROJECT_FILE_LIST_URL(':id')),
    CREATE_ANNOUNCEMENT: useMatch(CREATE_ANNOUNCEMENT_URL(':id')),
    TEMPLATE_LIST: useMatch(TEMPLATE_LIST_URL),
    VIEW_TEMPLATE: useMatch(VIEW_TEMPLATE_URL(':id')),
    EDIT_TEMPLATE: useMatch(EDIT_TEMPLATE_URL(':id')),
    CREATE_TEMPLATE: useMatch(CREATE_TEMPLATE_URL),
    ACCOUNT_MASTER_LIST: useMatch(TEMPLATE_LIST_URL),
    CREATE_ACCOUNT_MASTER: useMatch(CREATE_ACCOUNT_MASTER_URL),
    APPROVE_REJECT_ACCOUNT_MASTER: useMatch(APPROVE_REJECT_ACCOUNT_MASTER_URL(':id')),
    UPDATE_ACCOUNT_MASTER: useMatch(UPDATE_ACCOUNT_MASTER_URL(':id')),
    CREATE_PROJECT_INQUIRY: useMatch(CREATE_PROJECT_INQUIRY_URL(':id')),
    STAKEHOLDER_VIEW: useMatch(STAKEHOLDER_VIEW_URL(':id')),
    STAKEHOLDER_UPDATE: useMatch(STAKEHOLDER_UPDATE_URL(':id')),
    VIEW_TASK_OF_PROJECT: useMatch(`/${VIEW_TASK_OF_PROJECT_URL(':idProject', ':idTask')}`),
    QUESTIONNAIRE_LIST: useMatch(PROJECT_QUESTION_LIST_URL(':id')),
    PROJECT_QUESTION_DETAIL: useMatch(PROJECT_QUESTION_DETAIL_URL(':id', ':formId')),
    BATCH_REGISTRATION: useMatch(BATCH_REGISTRATION_PROJECT_URL)
  };
  const LINKED_LIST: ILinkedList[] = [
    {
      id: LINKED_LIST_ID.PROJECT_LIST,
      externalLink: PROJECT_LIST_URL,
      match:
        MATCHES.VIEW_PROJECT ||
        MATCHES.EDIT_PROJECT ||
        MATCHES.ADD_TASK_OF_PROJECT ||
        MATCHES.EDIT_TASK_OF_PROJECT ||
        MATCHES.CREATE_PROJECT ||
        MATCHES.ANNOUNCEMENT_LIST ||
        MATCHES.ANNOUNCEMENT_VIEW ||
        MATCHES.CREATE_ANNOUNCEMENT ||
        MATCHES.ANNOUNCEMENT_EDIT ||
        MATCHES.PROJECT_FILE_LIST ||
        MATCHES.CREATE_PROJECT_INQUIRY ||
        MATCHES.STAKEHOLDER_VIEW ||
        MATCHES.STAKEHOLDER_UPDATE ||
        MATCHES.VIEW_TASK_OF_PROJECT ||
        MATCHES.QUESTIONNAIRE_LIST ||
        MATCHES.PROJECT_QUESTION_DETAIL ||
        MATCHES.BATCH_REGISTRATION,
      title: t('sider:project_list'),
      children: [
        {
          id: LINKED_LIST_ID.VIEW_PROJECT,
          externalLink: VIEW_PROJECT_URL(id ?? ''),
          match:
            MATCHES.VIEW_PROJECT ||
            MATCHES.ANNOUNCEMENT_LIST ||
            MATCHES.ANNOUNCEMENT_VIEW ||
            MATCHES.CREATE_ANNOUNCEMENT ||
            MATCHES.ANNOUNCEMENT_EDIT ||
            MATCHES.PROJECT_FILE_LIST ||
            MATCHES.CREATE_PROJECT_INQUIRY ||
            MATCHES.STAKEHOLDER_VIEW ||
            MATCHES.STAKEHOLDER_UPDATE ||
            MATCHES.QUESTIONNAIRE_LIST ||
            MATCHES.PROJECT_QUESTION_DETAIL,
          title: <BreadCrumbTitle path={LINKED_LIST_ID.VIEW_PROJECT} />,
          children: [
            {
              id: LINKED_LIST_ID.ANNOUNCEMENT_LIST,
              externalLink: ANNOUNCEMENT_LIST_URL(id ?? ''),
              match: MATCHES.ANNOUNCEMENT_LIST || MATCHES.ANNOUNCEMENT_VIEW || MATCHES.CREATE_ANNOUNCEMENT || MATCHES.ANNOUNCEMENT_EDIT,
              title: t('sider:announcement_list'),
              children: [
                {
                  id: LINKED_LIST_ID.ANNOUNCEMENT_VIEW,
                  externalLink: MATCHES.ANNOUNCEMENT_VIEW?.pathname,
                  match: MATCHES.ANNOUNCEMENT_VIEW,
                  title: t('sider:announcement_view')
                },
                {
                  id: LINKED_LIST_ID.CREATE_ANNOUNCEMENT,
                  externalLink: MATCHES.CREATE_ANNOUNCEMENT?.pathname,
                  match: MATCHES.CREATE_ANNOUNCEMENT,
                  title: t('sider:create_announcement')
                },
                {
                  id: LINKED_LIST_ID.ANNOUNCEMENT_EDIT,
                  externalLink: MATCHES.ANNOUNCEMENT_EDIT?.pathname,
                  match: MATCHES.ANNOUNCEMENT_EDIT,
                  title: t('sider:announcement_view')
                }
              ]
            },
            {
              id: LINKED_LIST_ID.PROJECT_FILE_LIST,
              externalLink: PROJECT_FILE_LIST_URL(id ?? ''),
              match: MATCHES.PROJECT_FILE_LIST,
              title: t('sider:file_list')
            },
            {
              id: LINKED_LIST_ID.PROJECT_INQUIRY,
              externalLink: CREATE_PROJECT_INQUIRY_URL(id ?? ''),
              match: MATCHES.CREATE_PROJECT_INQUIRY,
              title: t('sider:contact_us')
            },
            {
              id: LINKED_LIST_ID.STAKEHOLDER_VIEW,
              externalLink: STAKEHOLDER_VIEW_URL(id ?? ''),
              match: MATCHES.STAKEHOLDER_VIEW,
              title: t('sider:related_party_settings')
            },
            {
              id: LINKED_LIST_ID.STAKEHOLDER_UPDATE,
              externalLink: STAKEHOLDER_UPDATE_URL(id ?? ''),
              match: MATCHES.STAKEHOLDER_UPDATE,
              title: t('sider:related_party_settings')
            },
            {
              id: LINKED_LIST_ID.QUESTIONNAIRE_LIST,
              externalLink: PROJECT_QUESTION_LIST_URL(id ?? ''),
              match: MATCHES.QUESTIONNAIRE_LIST || MATCHES.PROJECT_QUESTION_DETAIL,
              title: t('project:question_list:title'),
              children: [
                {
                  id: LINKED_LIST_ID.PROJECT_QUESTION_DETAIL_URL,
                  externalLink: MATCHES.PROJECT_QUESTION_DETAIL?.pathname,
                  match: MATCHES.PROJECT_QUESTION_DETAIL,
                  title: t('project:question_detail:title')
                }
              ]
            }
          ]
        },
        {
          id: LINKED_LIST_ID.EDIT_PROJECT,
          externalLink: MATCHES.EDIT_PROJECT?.pathname,
          match: MATCHES.EDIT_PROJECT,
          title: <BreadCrumbTitle path={LINKED_LIST_ID.EDIT_PROJECT} />
        },
        {
          id: LINKED_LIST_ID.CREATE_PROJECT,
          externalLink: MATCHES.CREATE_PROJECT?.pathname,
          match: MATCHES.CREATE_PROJECT,
          title: t('project:add:title')
        },
        {
          id: LINKED_LIST_ID.ADD_TASK_OF_PROJECT,
          externalLink: VIEW_PROJECT_URL(idProject ?? ''),
          match: MATCHES.ADD_TASK_OF_PROJECT,
          title: <BreadCrumbTitle path={LINKED_LIST_ID.ADD_TASK_OF_PROJECT} />,
          children: [
            {
              id: LINKED_LIST_ID.ADD_TASK_OF_PROJECT,
              externalLink: MATCHES.ADD_TASK_OF_PROJECT?.pathname,
              match: MATCHES.ADD_TASK_OF_PROJECT,
              title: t('project:task_of_project:add_title')
            }
          ]
        },
        {
          id: LINKED_LIST_ID.EDIT_TASK_OF_PROJECT,
          externalLink: VIEW_PROJECT_URL(idProject ?? ''),
          match: MATCHES.EDIT_TASK_OF_PROJECT,
          title: <BreadCrumbTitle path={LINKED_LIST_ID.EDIT_TASK_OF_PROJECT} />,
          children: [
            {
              id: LINKED_LIST_ID.EDIT_TASK_OF_PROJECT,
              externalLink: MATCHES.EDIT_TASK_OF_PROJECT?.pathname,
              match: MATCHES.EDIT_TASK_OF_PROJECT,
              title: t('project:task_of_project:task_detail')
            }
          ]
        },
        {
          id: LINKED_LIST_ID.VIEW_TASK_OF_PROJECT,
          externalLink: VIEW_PROJECT_URL(idProject ?? ''),
          match: MATCHES.VIEW_TASK_OF_PROJECT,
          title: <BreadCrumbTitle path={LINKED_LIST_ID.VIEW_TASK_OF_PROJECT} />,
          children: [
            {
              id: LINKED_LIST_ID.VIEW_TASK_OF_PROJECT,
              externalLink: MATCHES.VIEW_TASK_OF_PROJECT?.pathname,
              match: MATCHES.VIEW_TASK_OF_PROJECT,
              title: t('project:task_of_project:task_detail')
            }
          ]
        },
        {
          id: LINKED_LIST_ID.BATCH_REGISTRATION_URL,
          externalLink: MATCHES.BATCH_REGISTRATION?.pathname,
          match: MATCHES.BATCH_REGISTRATION,
          title: t('batch_registration:title')
        }
      ]
    },
    {
      id: LINKED_LIST_ID.TEMPLATE_LIST,
      externalLink: TEMPLATE_LIST_URL,
      match: MATCHES.VIEW_TEMPLATE || MATCHES.EDIT_TEMPLATE || MATCHES.CREATE_TEMPLATE,
      title: t('sider:template_list'),
      children: [
        {
          id: LINKED_LIST_ID.VIEW_TEMPLATE,
          externalLink: MATCHES.VIEW_TEMPLATE?.pathname,
          match: MATCHES.VIEW_TEMPLATE,
          title: t('sider:template_details')
        },
        {
          id: LINKED_LIST_ID.CREATE_TEMPLATE,
          externalLink: MATCHES.CREATE_TEMPLATE?.pathname,
          match: MATCHES.CREATE_TEMPLATE,
          title: t('sider:template_add_new')
        },
        {
          id: LINKED_LIST_ID.EDIT_TEMPLATE,
          externalLink: MATCHES.EDIT_TEMPLATE?.pathname,
          match: MATCHES.EDIT_TEMPLATE,
          title: t('sider:template_edit')
        }
      ]
    },
    {
      id: LINKED_LIST_ID.ACCOUNT_MASTER_LIST,
      externalLink: ACCOUNT_MASTER_LIST_URL,
      match: MATCHES.CREATE_ACCOUNT_MASTER || MATCHES.APPROVE_REJECT_ACCOUNT_MASTER || MATCHES.UPDATE_ACCOUNT_MASTER,
      title: t('sider:account_master_list'),
      children: [
        {
          id: LINKED_LIST_ID.CREATE_ACCOUNT_MASTER,
          externalLink: MATCHES.CREATE_ACCOUNT_MASTER?.pathname,
          match: MATCHES.CREATE_ACCOUNT_MASTER,
          title: t('sider:account_master_add_new')
        },
        {
          id: LINKED_LIST_ID.APPROVE_REJECT_ACCOUNT_MASTER,
          externalLink: MATCHES.APPROVE_REJECT_ACCOUNT_MASTER?.pathname,
          match: MATCHES.APPROVE_REJECT_ACCOUNT_MASTER,
          title: t('sider:account_master_update')
        },
        {
          id: LINKED_LIST_ID.UPDATE_ACCOUNT_MASTER,
          externalLink: MATCHES.UPDATE_ACCOUNT_MASTER?.pathname,
          match: MATCHES.UPDATE_ACCOUNT_MASTER,
          title: t('sider:account_master_update')
        }
      ]
    }
  ];

  const calculateBreadCrumb = () => {
    const items: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[] = [];
    const loop = (list: ILinkedList[]) => {
      const matched = list.find((item) => Boolean(item.match));
      if (matched) {
        const newBreadCrumbItem = {
          ...matched,
          onClick: () => matched.externalLink && navigate(matched.externalLink)
        };
        items.push(newBreadCrumbItem);
      }
      if (matched?.children?.length) loop(matched.children);
    };
    loop(LINKED_LIST);
    return items;
  };
  return (
    <div className='app-breadcrumb-header flex justify-center items-center ml-[24px]'>
      <Breadcrumb separator={<Separator />} items={calculateBreadCrumb()} />
    </div>
  );
};

export default AppBreadCrumb;
