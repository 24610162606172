import { axiosExternal } from '@pages/project-management/project-questionaire/external-questions/http';

import { API } from '@utils/constants/Apis';

const validateToken = (token: string) => {
  return axiosExternal.post(API.EXTERNAL_QUESTION_VALIDATE_TOKEN, { token });
};

const getPassword = ({ token, code }: { token: string; code: string }) => {
  return axiosExternal.post(API.EXTERNAL_QUESTION_AUTHENTICATE, {
    token,
    code
  });
};

const getListExternalQuestion = (token: string) => {
  return axiosExternal.get(API.GET_EXTERNAL_QUESTION_LIST(token));
};

const getDetailExternalQuestion = (token: string, id: string) => {
  return axiosExternal.get(API.GET_EXTERNAL_QUESTION_DETAIL(token, id));
};

const updateExternalQuestion = ({ token, id }: { token: string; id: string }, body: object) => {
  return axiosExternal.put(API.PUT_EXTERNAL_QUESTION_EDIT(token, id), body);
};

export { validateToken, getPassword, getListExternalQuestion, getDetailExternalQuestion, updateExternalQuestion };
