import React, { useEffect, useRef } from 'react';

/**
 * This component is used to preview the selected value in the dropdown
 * It will remove the selected value in view if it's not in the options list
 * @param options your dropdown options
 * @param value your current value
 * @param children it's must be a Select component
 */
const SelectionOverrideLabel = ({ children, label }: { children: JSX.Element; label?: string }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!wrapperRef.current) return;
    const inputElm = wrapperRef.current.querySelector('.ant-select .ant-select-selector .ant-select-selection-item');
    if (!inputElm || inputElm.textContent === '') return;
    setTimeout(() => {
      inputElm.innerHTML = label ?? '';
      inputElm.setAttribute('title', label ?? '');
    }, 0);
  });

  return <div ref={wrapperRef}>{children}</div>;
};

export default SelectionOverrideLabel;
