import AddAccountMaster from '@/pages/account-master-management/add-edit/Add';
import ApproveRejectAccountMaster from '@/pages/account-master-management/add-edit/ApproveReject';
import UpdateAccountMaster from '@/pages/account-master-management/add-edit/Update';
import AccountMasterList from '@/pages/account-master-management/list/List';

import { E, V } from '@/hooks/useAuthorization';

import { ROUTER_IDS } from '@/utils/constants';
import {
  ACCOUNT_MASTER_LIST,
  APPROVE_REJECT_ACCOUNT_MASTER_LIST,
  CREATE_NEW_ACCOUNT_MASTER_LIST,
  UPDATE_ACCOUNT_MASTER_LIST
} from '@/utils/constants/AccountMaster';
import { EXTERNAL_ADMIN, INTERNAL_ADMIN, INTERNAL_GENERAL_USER } from '@/utils/constants/AppConstants';
import {
  ACCOUNT_MASTER_LIST_URL,
  APPROVE_REJECT_ACCOUNT_MASTER_URL,
  CREATE_ACCOUNT_MASTER_URL,
  UPDATE_ACCOUNT_MASTER_URL
} from '@/utils/constants/RouteContants';
import { MAP_PATH_PRE_CHECK_404 } from '@/utils/helpers/routeLoaders';
import { IRoute } from '@/utils/interfaces/route';

const AccountMasterRoutes: IRoute[] = [
  {
    path: ACCOUNT_MASTER_LIST_URL,
    name: ACCOUNT_MASTER_LIST,
    action: V,
    element: <AccountMasterList />,
    auth: true,
    role: [INTERNAL_ADMIN, INTERNAL_GENERAL_USER, EXTERNAL_ADMIN],
    id: ROUTER_IDS.ACCOUNT_MASTER_LIST
  },
  {
    path: CREATE_ACCOUNT_MASTER_URL,
    name: CREATE_NEW_ACCOUNT_MASTER_LIST,
    action: E,
    element: <AddAccountMaster />,
    auth: true,
    role: [INTERNAL_ADMIN, EXTERNAL_ADMIN]
  },
  {
    path: APPROVE_REJECT_ACCOUNT_MASTER_URL(':id'),
    name: UPDATE_ACCOUNT_MASTER_LIST,
    action: E,
    element: <ApproveRejectAccountMaster />,
    auth: true,
    role: [INTERNAL_ADMIN, EXTERNAL_ADMIN],
    preCheck404: MAP_PATH_PRE_CHECK_404[APPROVE_REJECT_ACCOUNT_MASTER_URL(':id')]
  },
  {
    path: UPDATE_ACCOUNT_MASTER_URL(':id'),
    name: APPROVE_REJECT_ACCOUNT_MASTER_LIST,
    action: E,
    element: <UpdateAccountMaster />,
    auth: true,
    role: [INTERNAL_ADMIN, EXTERNAL_ADMIN],
    preCheck404: MAP_PATH_PRE_CHECK_404[UPDATE_ACCOUNT_MASTER_URL(':id')]
  }
];

export default AccountMasterRoutes;
