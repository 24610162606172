import { type SelectProps, Tag } from 'antd';
import React, { MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';

import AppTooltip from '@/components/app-tooltip/AppTooltip';
import { AutoCompleteSelection } from '@/components/form-auto-complete';
import { EditorWithCounter } from '@/components/form-box-editor';
import { FormInput } from '@/components/form-input/FormInput';

import { EDITOR_CHARACTER_LIMITED, FIELD } from '../constants';

const EmailFieldLabel = () => {
  const { t } = useTranslation();
  return (
    <>
      <p className='subtitle-14'>{t('inquiry:email_address_label')}</p>
      <div className='text-negative subtitle-14 font-medium '>(*)</div>
    </>
  );
};

const tagRender: SelectProps['tagRender'] = (props) => {
  const { value } = props;

  if (!value) return <></>;

  if (value.length > 30) {
    return (
      <Tag {...props} className='custom-tag-wrapper mb-1'>
        <AppTooltip title={value}>
          <span>{value.slice(0, 30) + '...'}</span>
        </AppTooltip>
      </Tag>
    );
  }

  return (
    <Tag {...props} className='custom-tag-wrapper mb-1'>
      <span>{value}</span>
    </Tag>
  );
};
interface IUserInfoProps {
  defaultValues?: { [key in FIELD]?: any };
  textEditorRef: MutableRefObject<any>;
}

const InquiryForm = ({ defaultValues, textEditorRef }: IUserInfoProps) => {
  const { t } = useTranslation();

  return (
    <div className='grid grid-cols-3 gap-[16px]'>
      <div className='col-span-1'>
        <FormInput
          defaultValue={defaultValues?.name}
          name={FIELD.NAME}
          labelTx='inquiry:user_name_label'
          placeholderTx='inquiry:user_name_placeholder'
          tooltipProps={{
            trigger: 'hover',
            className: 'truncate text-ink font-bold',
            title: defaultValues?.name
          }}
          disabled={true}
        />
      </div>
      <div className='col-span-1'>
        <FormInput
          className='col-span-1'
          defaultValue={defaultValues?.company}
          name={FIELD.COMPANY}
          labelTx='inquiry:company_label'
          placeholderTx='inquiry:company_placeholder'
          tooltipProps={{
            trigger: 'hover',
            className: 'truncate text-ink font-bold',
            title: defaultValues?.company
          }}
          disabled={true}
        />
      </div>
      <div className='col-span-1'>
        <FormInput
          className='col-span-1'
          defaultValue={defaultValues?.phoneNumber}
          name={FIELD.PHONE}
          labelTx='inquiry:phone_label'
          placeholderTx='inquiry:phone_placeholder'
          maxLength={18}
          inputMode='numeric'
        />
      </div>
      <div className='col-span-3'>
        <AutoCompleteSelection
          showError
          name={FIELD.EMAILS}
          defaultValue={defaultValues?.emails}
          Label={<EmailFieldLabel />}
          placeholder={t('inquiry:email_address_placeholder') ?? ''}
          tagRender={tagRender}
        />
      </div>
      <div className='col-span-3'>
        <EditorWithCounter
          name={FIELD.CONTENT}
          ref={textEditorRef}
          label={'inquiry:content_label'}
          required
          limit={EDITOR_CHARACTER_LIMITED}
          defaultValue={defaultValues?.content}
          toolbar={null}
          placeholder={t('inquiry:content_placeholder') || ''}
          editorWrapperProps={{ className: '!h-[250px]' }}
        />
      </div>
    </div>
  );
};

export default InquiryForm;
