import { Spin } from 'antd';
import classNames from 'classnames';
import { EmitterSource, Range } from 'quill';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Control, useController } from 'react-hook-form';

import { useMessageYupTranslation } from '@/hooks/useI18n';

import { ErrorInput } from '../error-input/ErrorInput';
import Editor, { IEditorProps } from './Editor';
import { calculateCharacters } from './utils';

import './Styles.scss';
import 'quill/dist/quill.core.css';

const DEFAULT_TOOLBAR_OPTIONS = [['bold', 'italic', 'underline', 'strike', { color: [] }, { background: [] }]];
export interface IBasicFormEditorProps {
  name: string;
  control?: Control;
  className?: string;
  defaultValue?: string;
  theme?: string;
  placeholder?: string;
  readOnly?: boolean;
  isBaselineError?: boolean;
  toolbar?: any[] | null; // null for hidden toolbar
  disabled?: boolean;
  blockEnter?: boolean;
  editorWrapperProps?: {
    className?: string;
    style?: React.CSSProperties;
  };
  modules?: IEditorProps['modules'];

  onBlur?: () => void;
  onChange?: (value: string, rawText: string) => void;
  onFocus?: () => void;
  extraElement?: React.ReactNode;
}

export const SUFFIX_FOR_VALIDATE = '_with_length$';
const BasicFormEditor = (props: IBasicFormEditorProps, ref: any) => {
  const {
    name,
    control,
    className = '',
    defaultValue = '',
    theme = 'snow',
    placeholder = '',
    readOnly = false,
    isBaselineError = false,
    toolbar,
    disabled = false,
    blockEnter,
    editorWrapperProps,
    onBlur,
    onChange,
    onFocus,
    modules,
    extraElement
  } = props;
  const quillRef = useRef(ref?.current);
  const {
    field,
    fieldState: { error }
  } = useController({
    name: name as string,
    defaultValue,
    control
  });

  const { field: fieldCounter } = useController({
    name: `${name}${SUFFIX_FOR_VALIDATE}`
  });

  const [focus, setFocus] = useState<boolean>(false);
  const msgError = useMessageYupTranslation(error?.message);
  const [loading] = useState<boolean>(false);

  useEffect(() => {
    if (ref?.current) quillRef.current = ref?.current;
  }, [ref]);

  useEffect(() => {
    quillRef.current?.enable(!disabled);
  }, [quillRef, disabled]);

  const handleChangeSelection = (range: Range, oldRange: Range, source: EmitterSource) => {
    if (range === null && oldRange !== null) {
      handleBlur();
    } else if (range !== null && oldRange === null) {
      handleFocus();
    }
  };

  const handleChange = (args: any) => {
    const content = quillRef.current.getSemanticHTML();
    const characterCount = calculateCharacters(quillRef.current);
    fieldCounter.onChange(characterCount);
    field.onChange(content);
    onChange && onChange(content, quillRef.current.getText());
  };

  const handleFocus = () => {
    setFocus(true);
  };

  const handleBlur = () => {
    setFocus(false);
    onBlur && onBlur();
  };

  const onKeyUpEditor = (e: KeyboardEvent) => {
    if (blockEnter) {
      if (e.keyCode === 13) {
        e.preventDefault();
        e.stopPropagation();
      }
    }
  };

  const getToolBarOptions = () => {
    if (toolbar === null) return;
    if (toolbar === undefined) return DEFAULT_TOOLBAR_OPTIONS;
    return toolbar;
  };

  return (
    <div className={classNames('form-text-editor', className ?? '', focus ? 'focus-editor' : '', error || isBaselineError ? 'error' : '')}>
      {loading && (
        <div className='loading-editor'>
          <div className='flex items-center justify-center w-full h-full'>
            <Spin size='large' />
          </div>
        </div>
      )}
      <div className={`h-full ${toolbar === null ? 'tool-bar--hidden' : 'tool-bar--visible'}`}>
        {isBaselineError && <div className='h-[26px]' />}
        <Editor
          ref={ref ?? quillRef}
          readOnly={readOnly}
          defaultValue={defaultValue}
          modules={{
            magicUrl: true,
            toolbar: getToolBarOptions(),
            ...modules
          }}
          placeholder={placeholder}
          theme={theme}
          onChangeSelection={handleChangeSelection}
          onChange={handleChange}
          onKeyUp={onKeyUpEditor}
          onFocus={onFocus}
          editorWrapperProps={editorWrapperProps}
        />
        {(error !== undefined || isBaselineError) && (
          <ErrorInput classNameLabel={isBaselineError ? 'truncate' : ''} className={isBaselineError ? '!mt-0 h-[26px]' : ''} error={msgError} />
        )}
      </div>
      {extraElement}
    </div>
  );
};

export default forwardRef(BasicFormEditor);
