export const ColorDefault = {
  white: '#FFFFFF',
  gray1: '#F5F5F7',
  gray2: '#DBDBE0',
  gray3: '#9E9FAC',
  gray4: '#53556C',
  black: '#0D102F',

  green10: '#e7f1ef',
  green30: '#b8d4d0',
  green50: '#88b6b1',
  green70: '#589a92',
  green75: '#5d9488',
  green: '#116e63',

  yellow10: ' #fef6e9',
  yellow30: ' #fbe3be',
  yellow50: ' #f8d093',
  yellow70: ' #f6be68',
  yellow: '#f2a227',

  negative: '#F03D3E',
  link: '#076CE3',
  lnk: '#12212E',
  warning: '#F2A227',
  action: '#116E63',
  positive: '#36B368'
};
