import dayjs from 'dayjs';
import { v4 as uuid } from 'uuid';

import { GENDER, GO_WITH_FAMILY } from '@/pages/project-management/project-list/constants';

import { UPLOAD_STATUS } from '@/utils/constants/AppConstants';
import { IUserProfile } from '@/utils/interfaces/user';

import { SCENARIO_TYPES } from '../../contants';
import { DEFAULT_FORM_OBSERVER, STEP_CRUMB_STATUS } from '../../contants/projectExternalProxy';
import {
  DEFAULT_SCENARIO_C_STEP_2,
  DEFAULT_SCENARIO_C_STEP_3,
  DEFAULT_SCENARIO_C_STEP_4,
  DEFAULT_SCENARIO_C_STEP_5,
  DEFAULT_SCENARIO_C_STEP_6,
  DEFAULT_SCENARIO_C_STEP_7,
  DEFAULT_SCENARIO_C_STEP_8,
  HAS_OTHER_STAKE_HOLDER
} from './constants';

export const convertResponseForCType = (data: any, user?: IUserProfile | null) => {
  const formDefault = { ...DEFAULT_FORM_OBSERVER, scenarioType: SCENARIO_TYPES.SCENARIO_C };
  if (!data) {
    formDefault.steps = [
      {
        ...DEFAULT_SCENARIO_C_STEP_2
      },
      {
        ...DEFAULT_SCENARIO_C_STEP_3
      },
      {
        ...DEFAULT_SCENARIO_C_STEP_4
      },
      {
        ...DEFAULT_SCENARIO_C_STEP_5
      },
      {
        ...DEFAULT_SCENARIO_C_STEP_6,
        companyId: user?.organizationId
      },
      {
        ...DEFAULT_SCENARIO_C_STEP_7
      },
      {
        ...DEFAULT_SCENARIO_C_STEP_8
      }
    ];
    formDefault.stepStatus = [
      ...DEFAULT_FORM_OBSERVER.stepStatus,
      {
        id: 2,
        value: STEP_CRUMB_STATUS.TODO
      },
      {
        id: 3,
        value: STEP_CRUMB_STATUS.TODO
      },
      {
        id: 4,
        value: STEP_CRUMB_STATUS.TODO
      },
      {
        id: 5,
        value: STEP_CRUMB_STATUS.TODO
      },
      {
        id: 6,
        value: STEP_CRUMB_STATUS.TODO
      },
      {
        id: 7,
        value: STEP_CRUMB_STATUS.TODO
      },
      {
        id: 8,
        value: STEP_CRUMB_STATUS.TODO
      }
    ];
  } else {
    const projectApplicant = {
      ...data.projectApplicant,
      dateOfBirth: data.projectApplicant.dateOfBirth ? dayjs(data.projectApplicant.dateOfBirth).format('YYYY/MM/DD') : undefined,
      departureDate: data.projectApplicant.departureDate ? dayjs(data.projectApplicant.departureDate).format('YYYY/MM/DD') : undefined,
      gender: data.projectApplicant.gender ? GENDER.female : data.projectApplicant.gender === false ? GENDER.male : null
    };
    let projectStakeholders: any = [];
    data.projectStakeholders.forEach((item: any) => {
      if (item.stakeholderEmail !== user?.email) {
        projectStakeholders.push({
          ...item,
          uid: uuid()
        });
      }
    });
    const projectFamilies = data.projectFamilies.map((item: any) => ({
      ...item,
      dateOfBirth: item.dateOfBirth ? dayjs(item.dateOfBirth).format('YYYY/MM/DD') : undefined,
      gender: item.gender ? GENDER.female : item.gender === false ? GENDER.male : null,
      uid: uuid()
    }));

    const step2 = {
      ...DEFAULT_SCENARIO_C_STEP_2,
      projectApplicant
    };
    const step3 = {
      ...DEFAULT_SCENARIO_C_STEP_3,
      goWithFamily: data.goWithFamily ? GO_WITH_FAMILY.YES : data.goWithFamily === false ? GO_WITH_FAMILY.NO : '',
      projectFamilies
    };
    const step4 = {
      ...DEFAULT_SCENARIO_C_STEP_4,
      visaCategoryId: data.visaCategoryId,
      travelTypeId: data.travelTypeId ?? null,
      projectAssignmentInfo: {
        ...data.projectAssignmentInfo
      }
    };
    const step5 = {
      ...DEFAULT_SCENARIO_C_STEP_5,
      localSupportOption: data.localSupportOption,
      projectLocalSupporters: data.projectLocalSupporters.map((item: any) => ({
        ...item,
        uid: uuid()
      }))
    };
    const step6 = {
      ...DEFAULT_SCENARIO_C_STEP_6,
      hasOtherStakeHolder: data.hasOtherStakeHolder
        ? HAS_OTHER_STAKE_HOLDER.YES
        : data.hasOtherStakeHolder === false
          ? HAS_OTHER_STAKE_HOLDER.NO
          : '',
      companyId: user?.organizationId,
      projectStakeholders
    };
    const step7 = {
      ...DEFAULT_SCENARIO_C_STEP_7,
      note: data.note,
      projectAttachments: data?.attachments.map((item: any) => ({
        ...item,
        status: UPLOAD_STATUS.DONE
      }))
    };
    formDefault.steps = [
      step2,
      step3,
      step4,
      step5,
      step6,
      step7,
      {
        ...DEFAULT_SCENARIO_C_STEP_8
      }
    ];
    formDefault.selectedStep = data.step;
    formDefault.stepStatus = data.stepStatus;
  }
  return formDefault;
};

export const parseHtmlString = (htmlString: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const phoneNumber = doc.getElementById('value-phone-scenario-e')?.textContent || '';
  const requestContent = doc.getElementById('value-note-scenario-e')?.innerHTML || '';
  return {
    'value-phone-scenario-e': phoneNumber,
    'value-note-scenario-e': requestContent
  };
};
