import { Col, Row } from 'antd';
import { Dayjs } from 'dayjs';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BaseButton } from '@/components/base-button/BaseButton';
import { FormInputDate } from '@/components/form-input-date/FormInputDate';
import { FormInputSelect } from '@/components/form-input-select/FormInputSelect';

import useOptionsGlobal from '@/hooks/useOptionsGlobal';

import { CATEGORY_PROCESS_OPTION } from '@/utils/constants/master-data/task/task';
import { DataInput } from '@/utils/helpers/common';
import { Ii18n } from '@/utils/interfaces/i18n';

import Minus from '@/assets/icons/Minus.svg';

import { IFilterSearchForm } from './utils/interface';

interface IFilterProps {
  formMethod?: any;
  defaultValues?: any;
  onSubmit: (values: IFilterSearchForm['primary']) => void;
}

const FormFilter = ({ onSubmit, defaultValues }: IFilterProps) => {
  const form = useForm<IFilterSearchForm['primary']>({
    defaultValues: {
      ...defaultValues,
      updatedDateFrom: DataInput.switchFormatDate(defaultValues.updatedDateFrom) as Dayjs,
      updatedDateTo: DataInput.switchFormatDate(defaultValues.updatedDateTo) as Dayjs
    }
  });
  const { t }: Ii18n = useTranslation();
  const { countryOptions } = useOptionsGlobal();

  const categoryOption = useMemo(
    () =>
      CATEGORY_PROCESS_OPTION.map((item: any) => ({
        ...item,
        label: t(item.label)
      })),
    [CATEGORY_PROCESS_OPTION]
  );

  const disabledButtonReset = () => {
    const { category, countryId, updatedDateFrom, updatedDateTo } = form.watch();
    return !category && !countryId && !updatedDateFrom && !updatedDateTo;
  };

  const handleSubmit = (values: any) => {
    const { updatedDateFrom, updatedDateTo } = values ?? {};
    const convertValue = {
      ...values,
      updatedDateFrom: DataInput.switchFormatDate(updatedDateFrom),
      updatedDateTo: DataInput.switchFormatDate(updatedDateTo)
    };
    onSubmit(convertValue);
  };

  const handleReset = () => {
    form.reset({
      category: '',
      countryId: '',
      updatedDateFrom: '',
      updatedDateTo: ''
    });
  };

  const capitalizeFirstLetter = (stringText: string) => {
    return stringText.charAt(0).toUpperCase() + stringText.slice(1);
  };

  return (
    <>
      <FormProvider {...form}>
        <div className='p-4 w-[500px]'>
          <h3 className='text-xl font-semibold'>{t('project:list:filter_btn_label')}</h3>
          <div className='item mb-5'>
            <FormInputSelect
              name='category'
              placeholderTx={`${t('common:MSG_001_select', { field: t('master_data:task:category') })}`}
              labelTx={t('master_data:task:category')}
              options={categoryOption}
            />
          </div>
          <div className='item mb-5'>
            <FormInputSelect
              searchMaxLength={128}
              name='countryId'
              options={countryOptions}
              labelTx={capitalizeFirstLetter(t('master_data:task:country_name'))}
              placeholderTx={t('common:MSG_001_select', { field: t('master_data:task:country_name') }) ?? ''}
            />
          </div>
          <div className='row mb-8'>
            <Row gutter={16}>
              <Col span={11}>
                <FormInputDate
                  name='updatedDateFrom'
                  labelTx='master_data:phrase:edited_date'
                  placeholder={t('placeholder:date')}
                  disabledDate={(d) => {
                    return Boolean(form.getValues('updatedDateTo') && d.startOf('day').isAfter(form.getValues('updatedDateTo')));
                  }}
                />
              </Col>
              <Col span={2}>
                <div className='mt-10'>
                  <Minus />
                </div>
              </Col>
              <Col span={11}>
                <FormInputDate
                  name='updatedDateTo'
                  label='&nbsp;'
                  placeholder={t('placeholder:date')}
                  disabledDate={(d) => {
                    return Boolean(form.getValues('updatedDateFrom') && d.endOf('day').isBefore(form.getValues('updatedDateFrom')));
                  }}
                />
              </Col>
            </Row>
          </div>
          <div className='flex justify-end space-x-2'>
            <div className='w-[120px]'>
              <BaseButton disabled={disabledButtonReset()} onClick={handleReset} type='tertiary' size='medium'>
                {t('project:filter:btn_reset')}
              </BaseButton>
            </div>
            <div className='w-[120px]'>
              <BaseButton onClick={form.handleSubmit(handleSubmit)} type='primary' size='medium'>
                <span>{t('project:filter:btn_aply')} </span>
              </BaseButton>
            </div>
          </div>
        </div>
      </FormProvider>
    </>
  );
};

export default FormFilter;
