import { type SelectProps, Tag } from 'antd';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import AppTooltip from '@/components/app-tooltip/AppTooltip';
import { FormAttachment } from '@/components/form-attachment/FormAttachment';
import { AutoCompleteSelection } from '@/components/form-auto-complete';
import { EditorWithCounter } from '@/components/form-box-editor';
import { FormInput } from '@/components/form-input/FormInput';
import { InfoIcon } from '@/components/icon-svg/IconSvg';

import { CONTENT_MAX_LENGTH } from '@/utils/constants/AppConstants';

import { FIELD_PROJECT, MAX_TAG } from '../constants';

import '../InquiryProject.scss';

const EmailFieldLabel = () => {
  const { t } = useTranslation();
  return (
    <>
      <p className='subtitle-14'>{t('inquiry:email_address_label')}</p>
      <div className='text-negative subtitle-14 font-medium '>(*)</div>
    </>
  );
};

const tagRender: SelectProps['tagRender'] = (props) => {
  const { value } = props;

  if (!value) return <></>;

  if (value.length > MAX_TAG) {
    return (
      <Tag {...props} className='custom-tag-wrapper'>
        <AppTooltip title={value}>
          <span>{value.slice(0, MAX_TAG) + '...'}</span>
        </AppTooltip>
      </Tag>
    );
  }

  return (
    <Tag {...props} className='custom-tag-wrapper'>
      <span>{value}</span>
    </Tag>
  );
};
interface IUserInfoProps {
  formMethod?: any;
  defaultValues?: { [key in FIELD_PROJECT]?: any };
}

const InquiryForm = ({ formMethod, defaultValues }: IUserInfoProps) => {
  const { t } = useTranslation();

  return (
    <>
      <FormProvider {...formMethod}>
        <div className='grid grid-cols-3 gap-[16px] mb-[12px]'>
          <div className='col-span-1'>
            <FormInput
              name={FIELD_PROJECT.PROJECT_ID}
              defaultValue={defaultValues?.projectId}
              labelTx='inquiry:proposal_number'
              placeholderTx='inquiry:proposal_number'
              tooltipProps={{
                trigger: 'hover',
                className: 'truncate text-ink font-bold',
                title: defaultValues?.projectId
              }}
              disabled={true}
            />
          </div>
          <div className='col-span-1'>
            <FormInput
              name={FIELD_PROJECT.NAME}
              defaultValue={defaultValues?.name}
              labelTx='inquiry:user_name_label'
              placeholderTx='inquiry:user_name_placeholder'
              tooltipProps={{
                trigger: 'hover',
                className: 'truncate text-ink font-bold',
                title: defaultValues?.name
              }}
              disabled={true}
            />
          </div>
          <div className='col-span-1'>
            <FormInput
              name={FIELD_PROJECT.COMPANY}
              defaultValue={defaultValues?.company}
              labelTx='inquiry:company_label'
              placeholderTx='inquiry:company_placeholder'
              tooltipProps={{
                trigger: 'hover',
                className: 'truncate text-ink font-bold',
                title: defaultValues?.company
              }}
              disabled={true}
            />
          </div>
        </div>
        <div className='grid grid-cols-2 gap-[16px] mb-[12px]'>
          <div className='col-span-1'>
            <AutoCompleteSelection
              showError
              name={FIELD_PROJECT.EMAILS}
              defaultValue={defaultValues?.emails}
              Label={<EmailFieldLabel />}
              placeholder={t('inquiry:email_address_placeholder') ?? ''}
              tagRender={tagRender}
            />
          </div>
          <div className='col-span-1'>
            <FormInput
              className='col-span-1'
              name='phoneNumber'
              labelTx='inquiry:phone_label'
              placeholderTx='inquiry:phone_placeholder'
              maxLength={18}
              inputMode='numeric'
            />
          </div>
        </div>
        <div className='mb-[12px]'>
          <EditorWithCounter
            name={FIELD_PROJECT.CONTENT}
            limit={CONTENT_MAX_LENGTH}
            label={'inquiry:content_label'}
            toolbar={null}
            placeholder={`${t('common:MSG_001_textbox', { field: t('inquiry:content_label') })}`}
            editorWrapperProps={{ className: '!h-[200px]' }}
            styleCounterLabel='text-[#9E9FAC]'
            required
          />
        </div>
        <div>
          <span className='text-[14px] font-medium leading-[22px] text-[#12212E]'>{t('inquiry:attachment')}</span>
          <div className='flex py-2'>
            <div className='py-[2px] mr-[4px]'>
              <InfoIcon width={14.63} height={14.63} />
            </div>
            <div className='font-normal leading-[22px] text-[14px] text-[#525A68]'>
              <p>{t('inquiry:notice_attachment')}</p>
            </div>
          </div>
          <FormAttachment
            name={FIELD_PROJECT.ATTACHMENTS_INQUIRY}
            defaultValue={defaultValues?.attachmentsInquiry || []}
            documentType='attachments'
          ></FormAttachment>
        </div>
      </FormProvider>
    </>
  );
};

export default InquiryForm;
