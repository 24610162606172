import { useAppSelector } from '@/hooks';
import { Dropdown, Layout, Space, Switch } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import NoticeForm from '@/pages/notification-management/NoticeForm';
import { showTutorialPopup } from '@/pages/tutorial';

import PopupNotice from '@/components/popup-notice/PopupNotice';
import AppTooltip from '@components/app-tooltip/AppTooltip';

import { setOnOffAlert, setOpenNotice } from '@/redux/notice/noticeReducer';
import { AppDispatch } from '@/redux/store';

import useMutation from '@/hooks/useMutation';
import useAuthorization, { NOTIFICATION_SHOW, V } from '@hooks/useAuthorization';

import { API } from '@/utils/constants/Apis';
import { API_URL_WITH_PORT } from '@utils/constants/AppConstants';
import { MY_PROFILE_URL, PRIVACY_POLICY_URL } from '@utils/constants/RouteContants';
import { Ii18n } from '@utils/interfaces/i18n';
import { generatePasswordChangeLink } from '@utils/services/AuthenticationApiService';

import Bell from '../assets/icons/Bell.svg';
import IconDropDown from '../assets/icons/Drop-down-arrow.png';
import AppBreadCrumb from './BreadCrumb';

import './Header.scss';

const { Header } = Layout;

type SidebarProps = {
  colorBgContainer: string;
  sidebarVisible?: boolean;
};

const HeaderMoi: React.FC<SidebarProps> = ({ colorBgContainer, sidebarVisible }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { user, isInternalRole, hasPermission } = useAuthorization();
  const { t }: Ii18n = useTranslation();
  const unreadNotice = useAppSelector((state) => state.notice.totalNoticeUnRead);
  const [openMenuUser, setOpenMenuUser] = useState<boolean>(false);
  const [showPopupNotice, setShowPopupNotice] = useState<boolean>(user?.receiveAlert ?? false);

  useEffect(() => {
    dispatch(setOnOffAlert(showPopupNotice));
  }, [showPopupNotice]);

  const { mutate: putOnOffAlert } = useMutation(API.PUT_ON_OFF_ALERT, {
    method: 'PUT',
    bodyType: 'json'
  });

  const handleShowOnOffAlert = async (type: boolean) => {
    setShowPopupNotice(type);
    await putOnOffAlert({ receiveAlert: type });
  };

  const fetchAndOpenChangePasswordLink = async () => {
    try {
      const res = await generatePasswordChangeLink();
      if (res.data) {
        window.location.href = `${process.env.REACT_APP_AUTHEN_URL}${res.data}&redirect_url=${window.location.href}`;
      }
    } catch (error) {}
  };

  const handleShowTutorial = () => {
    showTutorialPopup();
    setOpenMenuUser(false);
  };

  const items: (ItemType & { tabIndex?: number })[] = isInternalRole
    ? [
        {
          label: (
            <div className='content-dropdown' onClick={() => navigate(MY_PROFILE_URL)}>
              {t('personal_information:information')}
            </div>
          ),
          key: '0',
          tabIndex: 0
        },
        {
          label: (
            <div onClick={() => fetchAndOpenChangePasswordLink()} className='content-dropdown'>
              {t('personal_information:change_password')}
            </div>
          ),
          key: '1',
          tabIndex: 0
        },
        {
          label: (
            <a href={`${API_URL_WITH_PORT}${PRIVACY_POLICY_URL}?redirectUrl=${window.location.href}`} className='content-dropdown'>
              {t('personal_information:privacy_policy')}
            </a>
          ),
          key: '2',
          tabIndex: 0
        },
        {
          label: (
            <div className='justify-space-between'>
              <div className='content-dropdown cursor-initial'>{t('personal_information:notification_alerts')}</div>
              <Switch
                className='mr-2'
                checked={showPopupNotice}
                onClick={(checked) => {
                  handleShowOnOffAlert(checked);
                }}
              />
            </div>
          ),
          key: '3',
          tabIndex: 0
        }
      ]
    : [
        {
          label: (
            <div className='content-dropdown' onClick={() => navigate(MY_PROFILE_URL)}>
              {t('personal_information:information')}
            </div>
          ),
          key: '0',
          tabIndex: 0
        },
        {
          label: (
            <div onClick={() => fetchAndOpenChangePasswordLink()} className='content-dropdown'>
              {t('personal_information:change_password')}
            </div>
          ),
          key: '1',
          tabIndex: 0
        },
        {
          label: (
            <a href={`${API_URL_WITH_PORT}${PRIVACY_POLICY_URL}?redirectUrl=${window.location.href}`} className='content-dropdown'>
              {t('personal_information:privacy_policy')}
            </a>
          ),
          key: '2',
          tabIndex: 0
        },
        {
          label: (
            <div onClick={() => handleShowTutorial()} className='content-dropdown'>
              {t('personal_information:tutorial')}
            </div>
          ),
          key: '3',
          tabIndex: 0
        },
        {
          label: (
            <div className='justify-space-between'>
              <div className='content-dropdown cursor-initial'>{t('personal_information:notification_alerts')}</div>
              <Switch
                className='mr-2'
                checked={showPopupNotice}
                onClick={(checked) => {
                  handleShowOnOffAlert(checked);
                }}
              />
            </div>
          ),
          key: '4',
          tabIndex: 0
        }
      ];

  /**
   * Define state
   */
  // const handleURL = () => {
  //   let url = '';
  //   switch (listScreenDisplay) {
  //     case backScreenList[0]:
  //       url = PROJECT_LIST_URL;
  //       break;
  //     case backScreenList[1]:
  //       url = TEMPLATE_LIST_URL;
  //       break;
  //     case backScreenList[2]:
  //       url = ACCOUNT_LIST_URL;
  //       break;
  //     case backScreenList[3]:
  //       url = ANNOUNCEMENT_LIST_URL;
  //       break;
  //   }
  //   navigate(url);
  //   handelTurnOffPopup();
  // };

  return (
    <Header
      id='app-header'
      style={{
        background: colorBgContainer
      }}
    >
      <div className='header'>
        <AppBreadCrumb />
        <div className='right-part'>
          {/*<LanguageSwitcher />*/}
          {hasPermission(NOTIFICATION_SHOW, V) && (
            <div
              className={`right-part-icon mr-2 ${sidebarVisible ? 'right-part-icon-active' : 'right-part-icon-inactive'}`}
              tabIndex={0}
              id='app-notify-icon'
              onClick={() => {
                dispatch(setOpenNotice(true));
              }}
            >
              <Bell width={18} height={18} style={{ fontSize: '18px' }} />
              {unreadNotice > 0 ? <span className='right-part-noti'>{unreadNotice > 99 ? '99+' : unreadNotice}</span> : null}
            </div>
          )}

          <NoticeForm title={t('notification:title')} width={640} />

          <Dropdown
            menu={{ items }}
            open={openMenuUser}
            onOpenChange={(open) => setOpenMenuUser(open)}
            trigger={['click']}
            disabled={sidebarVisible}
            overlayClassName='over-lay-drop-down-header'
            autoFocus
            className='app-user-login-dropdown'
          >
            <div
              className='flex items-center gap-4'
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setOpenMenuUser(!openMenuUser);
                }
              }}
            >
              <div className='user-login-right-part max-w-[260px]'>
                <AppTooltip className='text-overflow-1-line-clamp body-700 text-[#12212E] break-all' title={user?.name}>
                  {user?.name}
                </AppTooltip>
              </div>
              <Space>
                <img src={IconDropDown} alt='FileTextActive3' />
              </Space>
            </div>
          </Dropdown>
        </div>
      </div>
      <PopupNotice />
    </Header>
  );
};

export default HeaderMoi;
