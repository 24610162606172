import classNames from 'classnames';

import AppTooltip from '../app-tooltip/AppTooltip';

interface IAppLabel {
  label: string | null;
  title: React.ReactNode;
  hasTooltip?: boolean;
  labelClass?: string;
  titleClass?: string;
}

const AppLabelSpace = (props: IAppLabel) => {
  const { label, title, hasTooltip, labelClass, titleClass = 'font-bold' } = props;

  return (
    <>
      {hasTooltip ? (
        <div className='flex items-center w-full leading-[160%]'>
          <div className={classNames('flex-none text-14 font-normal !m-0 min-w-[180px] text-lnk', labelClass)}>{`${label} :`}</div>
          <AppTooltip className={classNames('ml-2 truncate text-lnk', titleClass)} title={title}>
            {title}
          </AppTooltip>
        </div>
      ) : (
        <div className={`text-14 font-normal !m-0 leading-[160%] text-lnk flex`} style={{ fontWeight: '500' }}>
          {label && <div className={classNames('min-w-[180px] max-w-[180px]', labelClass)}>{label}</div>}
          <span className={classNames('text-lnk w-[calc(100%-180px)]', titleClass)} style={{ overflowWrap: 'anywhere' }}>
            {title}
          </span>
        </div>
      )}
    </>
  );
};

export default AppLabelSpace;
