import { Col, Row } from 'antd';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { LANGUAGE } from '@/pages/project-management/project-list/constants';

import { BaseButton } from '@/components/base-button/BaseButton';
import { BaseCard } from '@/components/base-card/BaseCard';
import BaseRadioGroups from '@/components/common/base-radio';
import FormItem from '@/components/common/form-item';
import { FormInput } from '@/components/form-input/FormInput';
import { FormSelect } from '@/components/form-select/FormSelect';
import { InfoIcon, PlusIcon } from '@/components/icon-svg/IconSvg';

import { REQUIRED_DOT } from '@/utils/constants/AppConstants';

import { useFormScenarioCContext } from '..';
import { relativePath } from '../../utils';
import { GO_WITH_LOCAL_SUPPORTER, MAX_FIELD_PERSON_NUMBER, PROJECT_LOCAL_SUPPORTER_FIELDS } from '../constants';

const PARENT_PATH = 'steps[3]';
export default function Step5() {
  const { t } = useTranslation();
  const form = useFormScenarioCContext<'step-5'>();
  const PATHS = {
    PROJECT_LOCAL_SUPPORTER: relativePath('projectLocalSupporters', PARENT_PATH),
    GO_WITH_LOCAL_SUPPORTER: relativePath('localSupportOption', PARENT_PATH)
  };
  const { append, remove } = useFieldArray({
    name: PATHS.PROJECT_LOCAL_SUPPORTER,
    control: form.control
  });
  const [localSupporters] = form.watch([PATHS.PROJECT_LOCAL_SUPPORTER]);
  const counters = (index: number) => {
    const watchingFieldPath = {
      all: PROJECT_LOCAL_SUPPORTER_FIELDS.map((field) => relativePath(field, `${PATHS.PROJECT_LOCAL_SUPPORTER}[${index}]`))
    };
    const watchFields = form.watch(watchingFieldPath.all);
    const watchFieldsValid = watchFields.filter((field: any) => field);
    const results = {
      total: PROJECT_LOCAL_SUPPORTER_FIELDS.length,
      questions: watchFieldsValid.length ?? 0
    };
    return results;
  };
  const language = [
    {
      label: t('common:language_jp'),
      value: LANGUAGE.japan,
      id: LANGUAGE.japan
    },
    {
      label: t('common:language_en'),
      value: LANGUAGE.english,
      id: LANGUAGE.english
    }
  ];
  const getChildrenPath = (index: number, field: string) => `${PATHS.PROJECT_LOCAL_SUPPORTER}[${index}].${field}`;
  const addNewLocalSupporter = () => {
    if (localSupporters?.length >= MAX_FIELD_PERSON_NUMBER) return;
    append({
      name: '',
      address: '',
      email: '',
      language: null,
      uid: uuid()
    });
  };
  const handleRemove = (index: number) => {
    remove(index);
  };
  return (
    <div className='mt-5'>
      <p className='title-20 text-black'>{t('project:scenario_b:local_supporter')}</p>
      <p className='subtitle-18 text-black mt-3'>
        {t('project:scenario_b:local_representative_question')}
        <span className='text-textError'>{REQUIRED_DOT}</span>
      </p>
      <FormItem name={PATHS.GO_WITH_LOCAL_SUPPORTER}>
        <BaseRadioGroups
          className='mt-3'
          isVertical
          options={[
            {
              label: t('common:options:needed'),
              value: GO_WITH_LOCAL_SUPPORTER.YES
            },
            {
              label: t('common:options:not_needed'),
              value: GO_WITH_LOCAL_SUPPORTER.NO
            },
            {
              label: t('common:options:unknown'),
              value: GO_WITH_LOCAL_SUPPORTER.NOT_SURE
            }
          ]}
          onChange={(value: any) => {
            if (value === GO_WITH_LOCAL_SUPPORTER.NO || value === GO_WITH_LOCAL_SUPPORTER.NOT_SURE) {
              form.setValue(PATHS.PROJECT_LOCAL_SUPPORTER, []);
              form.clearErrors(PATHS.PROJECT_LOCAL_SUPPORTER);
            } else if (value === GO_WITH_LOCAL_SUPPORTER.YES && !localSupporters?.length) {
              addNewLocalSupporter();
            }
          }}
        />
      </FormItem>
      {form.watch(PATHS.GO_WITH_LOCAL_SUPPORTER) === GO_WITH_LOCAL_SUPPORTER.YES && (
        <>
          <p className='subtitle-18 text-black mt-3'>{t('project:scenario_b:local_representative_guide')}</p>
          <div className='flex py-2'>
            <div className='py-[2px] mr-[4px]'>
              <InfoIcon width={14.63} height={14.63} />
            </div>
            <div className='font-normal leading-[22px] text-[14px] text-[#525A68]'>
              <p>{t('project:scenario_b:local_representative_information')}</p>
            </div>
          </div>
          {localSupporters?.map((item: any, index: number) => (
            <BaseCard
              key={item.uid}
              className='mt-3'
              index={index}
              title={`${t('basic_information:local_contact')} ${index + 1}`}
              total={counters(index).total}
              questions={counters(index).questions}
              showIconRemove={localSupporters?.length > 1}
              onRemove={handleRemove}
            >
              <Row gutter={16}>
                <Col span={6}>
                  <FormInput
                    required
                    defaultValue={form.getValues(getChildrenPath(index, 'name'))}
                    name={getChildrenPath(index, 'name')}
                    labelTx='project:add:local_representative:name'
                    placeholderTx={String(t('project:add:local_representative:name_placeholder'))}
                  />
                </Col>
                <Col span={6}>
                  <FormInput
                    required
                    defaultValue={form.getValues(getChildrenPath(index, 'address'))}
                    name={getChildrenPath(index, 'address')}
                    labelTx='project:add:local_representative:address'
                    placeholderTx={String(t('project:add:local_representative:address_placeholder'))}
                  />
                </Col>
                <Col span={6}>
                  <FormInput
                    required
                    defaultValue={form.getValues(getChildrenPath(index, 'email'))}
                    name={getChildrenPath(index, 'email')}
                    labelTx='project:add:local_representative:email'
                    placeholderTx={String(t('project:add:local_representative:email_placeholder'))}
                  />
                </Col>
                <Col span={6}>
                  {index === 0 && (
                    <FormSelect
                      required
                      id={getChildrenPath(index, 'language')}
                      name={getChildrenPath(index, 'language')}
                      label={t('project:add:local_representative:language')}
                      placeholder={t('project:add:local_representative:language_placeholder')}
                      options={language}
                    />
                  )}
                </Col>
              </Row>
            </BaseCard>
          ))}
          <div className='max-w-[160px] mt-3'>
            <BaseButton
              icon={<PlusIcon />}
              type='secondary'
              block
              size='medium'
              disabled={localSupporters?.length === MAX_FIELD_PERSON_NUMBER}
              onClick={addNewLocalSupporter}
            >
              {t('project:add:local_representative:add')}
            </BaseButton>
          </div>
        </>
      )}
    </div>
  );
}
