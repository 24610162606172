// Notification
export const ID_SYSTEM = '717063dd-aa8e-4c84-9ecd-cbc903062d8f';
export const TYPE_NOTICE_MESSAGE = {
  AN_001: 'MSG_AN_001',
  AN_002: 'MSG_AN_002',
  C_016: 'MSG_C_016',
  C_017: 'MSG_C_017',
  C_018: 'MSG_C_018',
  P_007: 'MSG_P_007',
  P_008: 'MSG_P_008',
  P_011: 'MSG_P_011',
  P_014: 'MSG_P_014',
  P_015: 'MSG_P_015',
  P_016: 'MSG_P_016',
  P_017: 'MSG_P_017',
  P_018: 'MSG_P_018',
  P_019: 'MSG_P_019',
  P_020: 'MSG_P_020',
  P_028: 'MSG_P_028',
  P_029: 'MSG_P_029',
  P_039: 'MSG_P_039'
};
export const PAGE_SEND_NOTICE = {
  BASIC: 'Basic',
  DRAFT: 'draft',
  ANNOUNCEMENT: 'Announcement',
  PROJECT_TASK_DETAIL: 'Project_task_detail',
  USER_LIST: 'accounts-master',
  STAKE_HOLDER: 'stakeholder',
  EDIT_USER: 'edit-other',
  QUESTIONAIRE: 'Questionaire',
  NOT_FOUND: 'not-found'
};
