export const TYPE_NONE = 'none';
export const TYPE_VALIDATE = 'validate';
export const TYPE_PROCESS = 'process';
export const TYPE_SUBMITTED = 'submitted';
export const TYPE_DONE = 'done';

export type STATUS_FILE = typeof TYPE_NONE | typeof TYPE_PROCESS | typeof TYPE_VALIDATE | typeof TYPE_SUBMITTED | typeof TYPE_DONE;

export const BLOB_PATH_FILE = 'project-template-excel/一括申込のテンプレート.xlsx';

export const DEFAULT_FILE_ERROR_RES = {
  errorDataRegisterProjects: []
};
