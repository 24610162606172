import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { setAlertNotification } from '@/redux/globalReducer';

import { TYPE } from '@/utils/constants/AppConstants';
import { handleCommonError } from '@/utils/helpers/common';
import { replaceStringWithPattern } from '@/utils/helpers/globalHelper';

import { axiosInstance } from '@/config/http';

import { useAppDispatch } from '.';

export interface IApiResponseDataList<T> {
  totalCount: number;
  totalPage: number;
  pageSize: number;
  pageIndex: number;
  data: T;
}

interface UseMutationOptions {
  method: 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'GET';
  bodyType?: 'json' | 'form-data';
  showToastSuccess?: boolean;
  showToastError?: boolean;
  defaultErrorMsg?: string;
  defaultSuccessMsg?: string;
  paramMsg?: Record<string, any>;
  instance?: any;
}

const useMutation = (endPoint: string, options: UseMutationOptions, query?: any) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { instance = axiosInstance } = options ?? {};

  const { showToastSuccess, showToastError, defaultErrorMsg, defaultSuccessMsg, paramMsg = {} } = options;

  const clearError = () => {
    setError(undefined);
  };

  const mutate = useCallback(
    async (body: FieldValues, pathObj?: Record<string, string>) => {
      try {
        setLoading(true);
        let result = null;
        const newEndPoint = replaceStringWithPattern(endPoint, pathObj ?? {});
        switch (options.method) {
          case 'POST':
            result = await instance.post(newEndPoint, body, query);
            break;
          case 'PUT':
            result = await instance.put(newEndPoint, body);
            break;
          case 'GET':
            result = await instance.get(newEndPoint, {
              params: { ...query }
            });
            break;
          case 'PATCH':
            result = await instance.patch(newEndPoint, body);
            break;
          case 'DELETE':
            result = await instance.delete(newEndPoint, {
              data: body
            });
            break;
          default:
            break;
        }
        showToastSuccess &&
          dispatch(
            setAlertNotification({
              show: true,
              type: TYPE.SUCCESS,
              message: t(`common:${defaultSuccessMsg}`, paramMsg)
            })
          );
        setError(undefined);
        return result;
      } catch (error) {
        const errorAlert = handleCommonError(error as AxiosError, t(`common:${defaultErrorMsg}`, paramMsg) ?? '', () => {}, paramMsg);
        showToastError &&
          dispatch(
            setAlertNotification({
              show: true,
              type: TYPE.ERROR,
              message: errorAlert.message
            })
          );
        setError(error as Error);
      } finally {
        setLoading(false);
      }
    },
    [endPoint, options.method]
  );

  return { loading, error, mutate, clearError };
};

export default useMutation;
