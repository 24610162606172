import { memo, useMemo } from 'react';
import styled from 'styled-components';

interface ISeachFilterResult {
  resultSearch: Result;
  isFilter: boolean;
}
type Result = {
  current: number | undefined;
  pageSize: number | undefined;
  total: number | undefined;
};

/**
 * @deprecated styled-components must be removed. It's very rarely used
 * Please use scss or tailwind
 */
const SearchFilterResult = ({ resultSearch, isFilter }: ISeachFilterResult) => {
  const { current, total, pageSize } = resultSearch;

  const firstElement = useMemo(() => {
    if (!current || !pageSize) return 1;
    if (current === 1) return 1;
    if (current > 1) {
      return current * pageSize - pageSize + 1;
    }
    return 1;
  }, [resultSearch]);

  const lastElement = useMemo(() => {
    if (current && total && pageSize) {
      const lastElement = current * pageSize;
      if (lastElement < total) {
        return lastElement;
      } else {
        return total;
      }
    }
    return pageSize;
  }, [resultSearch]);

  const totalElement = useMemo(() => {
    return total !== null && total !== undefined ? total : 0;
  }, [resultSearch]);

  return <ContentFilterResult>{`${isFilter ? '検索結果 : ' : ''}${firstElement}-${lastElement} / ${totalElement}件`}</ContentFilterResult>;
};

const ContentFilterResult = styled.span`
  color: var(--neutrals-black, #12212e);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
`;
export default memo(SearchFilterResult);
