import { useAppSelector } from '@/hooks';
import { notification } from 'antd';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { fetchCountNoticeUnRead, fetchReadNotice, setOpenNotice, setPopupNotice, setRefreshPage } from '@/redux/notice/noticeReducer';
import { AppDispatch } from '@/redux/store';

import useAuthorization from '@/hooks/useAuthorization';

import BellRinging from '@/assets/icons/notification/BellRinging.svg';

import AppTooltip from '../app-tooltip/AppTooltip';

const PopupNotice = () => {
  const { isInternalRole } = useAuthorization();
  const { show, url, dataNotice, title, projectCode, projectCompany, projectAddress } = useAppSelector((state) => state.notice.popupNotice);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const onOffAlert = useAppSelector((state) => state.notice.onOffAlert);

  const openPageCreateNotice = async () => {
    const urlNavigate = String(url);
    // Go to page send notice
    if (urlNavigate && urlNavigate !== window.location.pathname) {
      navigate(urlNavigate);
    } else {
      // Set state show loading refresh page is true
      dispatch(setRefreshPage(true));

      // Set state show loading refresh page is false
      setTimeout(() => {
        dispatch(setRefreshPage(false));
      }, 0);
    }

    // Off drawer form notice
    dispatch(setOpenNotice(false));

    // Update data change type readed is true
    dispatch(fetchReadNotice({ notice: dataNotice, isReadAlert: true }));
    dispatch(fetchCountNoticeUnRead(isInternalRole));
  };

  const close = () => {
    dispatch(
      setPopupNotice({
        show: false
      })
    );
  };

  const renderMessage = () => {
    return (
      <AppTooltip className='form-title' title={title}>
        <div className='form-message body-700'>{title}</div>
      </AppTooltip>
    );
  };

  const renderDescription = () => {
    const title = [projectCode, projectCompany, projectAddress].filter((i) => i).join(' ');
    return (
      <div className='form-description body-small-bold'>
        <AppTooltip className='form-title d-flex' title={title}>
          {projectCode && (
            <div className='max-w-[95px]'>
              <span className='pr-3 block truncate'>{projectCode}</span>
            </div>
          )}
          {projectCompany && (
            <div className='max-w-[95px]'>
              <span className='pr-3 block truncate'>{projectCompany}</span>
            </div>
          )}
          {projectAddress && (
            <div className='max-w-[95px]'>
              <span className='block truncate'>{projectAddress}</span>
            </div>
          )}
        </AppTooltip>
      </div>
    );
  };

  const renderIcon = () => {
    return (
      <div className='!w-[52px] !mr-[12px] common-block-center mb-4'>
        <div className='form-icon w-[38px] h-[38px] common-block-center items-center'>
          <BellRinging />
        </div>
      </div>
    );
  };

  const openNotice = () => {
    const key = dataNotice?.createdDate;
    notification.open({
      key,
      onClick: () => {
        openPageCreateNotice();
        notification.destroy(key);
      },
      onClose: close,
      message: renderMessage(),
      description: renderDescription(),
      icon: renderIcon(),
      placement: 'bottomRight',
      className: 'form-notice cursor-pointer !w-[400px] !p-[20px]'
    });
  };

  useEffect(() => {
    if (show && onOffAlert) {
      openNotice();
      close();
    }
  }, [show, onOffAlert]);

  if (!show) {
    return null;
  }
};

export default PopupNotice;
