import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { EditorWithCounter } from '@/components/form-box-editor';
import { FormInputSelect } from '@/components/form-input-select/FormInputSelect';
import { FormInput } from '@/components/form-input/FormInput';
import FormEditor from '@components/form-box-editor/FormEditor';

import { NOTE_MAX_LENGTH } from '@/utils/constants/AppConstants';
import { getSupportTypeMemoList } from '@/utils/services/ProjectApiService';

import { IOptions, ISupportType } from '../model';

const HEADER_HEIGHT = 52 + 12;
const OUTSIDE_PADDING = 20 * 2;
const BODY_PADDING = 24 * 2;
const ANOTHER_SECTION_HEIGHT = 32 + 12 + 32 + 12 + 32 + 12 + 70 + 12 + 70 + 12;
const NOTE_LABEL_HEIGHT = 30;
const OUTSIDE_NOTE_HEIGHT = HEADER_HEIGHT + OUTSIDE_PADDING + BODY_PADDING + ANOTHER_SECTION_HEIGHT + NOTE_LABEL_HEIGHT + 8;

export const ProjectMemoForm = () => {
  const methods = useFormContext();
  const { t } = useTranslation();
  const [supportTypeOptions, setSupportTypeOptions] = useState<IOptions[]>([]);

  useEffect(() => {
    getSupportTypeData();
  }, []);

  const eMoNumber = methods?.getValues('emoNumber');
  const folderValue = methods?.getValues('folder');
  const location = methods?.getValues('location');
  const memoValue = methods?.watch('memo');

  const getSupportTypeData = async () => {
    try {
      const res = await getSupportTypeMemoList();
      if (res?.data) {
        const dataTemp = res.data.map((item: ISupportType) => {
          return {
            label: item.name,
            value: item.id,
            id: item.id
          };
        });
        setSupportTypeOptions(dataTemp);
      }
    } catch (error) {}
  };

  return (
    <div className='grow flex flex-col gap-[12px]'>
      <div>
        <FormInput
          defaultValue={location}
          name='location'
          labelTx={t('project_memo:location')}
          placeholderTx={t('placeholder:text_box', { field: t('project_memo:location') })}
        />
      </div>
      <div className='flex flex-row gap-[18px]'>
        <div className='basis-1/2'>
          <FormInput
            defaultValue={eMoNumber}
            name='emoNumber'
            labelTx={t('project_memo:e_mo_number')}
            placeholderTx={t('placeholder:text_box', { field: t('project_memo:e_mo_number') })}
          />
        </div>
        <div className='basis-1/2'>
          <FormInputSelect
            name='supportTypeId'
            label={t('project_memo:support_type')}
            placeholder={t('placeholder:select', { field: t('project_memo:support_type') })}
            options={supportTypeOptions}
            overflowTooltip='unset'
          />
        </div>
      </div>
      <div>
        <p className='subtitle-14 mb-2'>{t('project_memo:folder')}</p>
        <FormEditor
          name='folder'
          defaultValue={folderValue}
          toolbar={null}
          editorWrapperProps={{ className: '!rounded-[10px]' }}
          placeholder={t('placeholder:text_box', { field: t('project_memo:folder') })}
        />
      </div>
      <div className='grow'>
        <EditorWithCounter
          label={t('project_memo:memo')}
          name='memo'
          limit={NOTE_MAX_LENGTH}
          defaultValue={memoValue}
          className='h-full'
          toolbar={null}
          placeholder={t('placeholder:text_box', { field: t('project_memo:memo') })}
          editorWrapperProps={{ style: { height: `calc(100vh - ${OUTSIDE_NOTE_HEIGHT}px)`, minHeight: 280 } }}
        />
      </div>
    </div>
  );
};
