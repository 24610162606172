import { Button, ButtonProps } from 'antd';
import classNames from 'classnames';
import { debounce } from 'lodash';

import './BaseButton.scss';

export type SizeType = 'large' | 'medium' | 'small';
export type Type = 'primary' | 'secondary' | 'tertiary' | 'button' | 'danger' | 'text' | 'outline-red';

export interface IBaseButton extends Omit<ButtonProps, 'type' | 'size' | 'className'> {
  type?: Type;
  size?: SizeType;
  className?: string;
  isSubmit?: boolean;
}

export function BaseButton({ type = 'primary', size = 'medium', className = '', isSubmit, children, ...rest }: IBaseButton) {
  const debouncedClick = debounce(async (event: any) => {
    rest.onClick && rest.onClick(event);
  }, 300);

  return (
    <div className='base-button-container'>
      <Button
        className={`${classNames(type, size, className)}`}
        onClick={(event: any) => {
          debouncedClick(event);
        }}
        {...rest}
      >
        {typeof children === 'string' ? <p> {children} </p> : children}
      </Button>
    </div>
  );
}
