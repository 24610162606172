import { yupResolver } from '@hookform/resolvers/yup';
import { Space } from 'antd';
import { isNil } from 'lodash';
import React, { useCallback, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { BaseButton } from '@/components/base-button/BaseButton';
import { showBasePopup } from '@/components/base-popup/BasePopup';

import useFetch from '@/hooks/useFetch';
import useMutation from '@/hooks/useMutation';

import { API } from '@/utils/constants/Apis';
import { HTTP_STATUS_CODE } from '@/utils/constants/Http';
import { DOCUMENT_LIST_URL } from '@/utils/constants/RouteContants';
import { renderProjectCode } from '@/utils/helpers/project';

import { IProjectInfo } from '../add-edit/models';
import DocumentListComponent from './components/DocumentListComponent';
import { DOCUMENT_TYPE, SUBMISSION_MOT } from './constants';
import { IDocumentListOption, IFormMethodRequiredDocument } from './model';
import { generateFilterDocument, mapValueForItemDeleted, mapValueSubmissionToMot } from './utils';
import { FormRequiredDocument } from './validation';

import './DocumentList.scss';

const EditDocumentList = () => {
  const requiredDocumentRef = useRef<any>(null);
  const internalDocumentRef = useRef<any>(null);
  const [documentNameOption, setDocumentNameOption] = useState<IDocumentListOption[]>([]);
  const [defaultValues, setDefaultValues] = useState<IFormMethodRequiredDocument>();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const { data: projectData } = useFetch<IProjectInfo>(API.GET_PROJECT_INFO(id ?? ''), 'GET');
  const { mutate: requiredDocument } = useMutation(API.GET_DOCUMENT_LIST(id ?? ''), {
    method: 'POST',
    bodyType: 'json'
  });

  const { mutate: getDocumentNameList, loading } = useMutation(API.GET_DOCUMENT_NAME_LIST, {
    method: 'POST',
    bodyType: 'json'
  });

  const { mutate: saveDocumentList } = useMutation(API.UPDATE_DOCUMENT_LIST(id ?? ''), {
    method: 'PUT',
    bodyType: 'json',
    showToastError: true,
    showToastSuccess: true,
    defaultSuccessMsg: t('common:MSG_C_003', { item: t('button:keep') }) ?? ''
  });

  const formMethod = useForm<IFormMethodRequiredDocument>({
    mode: 'all',
    resolver: yupResolver(FormRequiredDocument),
    defaultValues: () => getDefaultValues()
  });

  const { isDirty, isSubmitting } = formMethod?.formState;

  const getDefaultValues = async () => {
    const response = await requiredDocument({});
    if (!response?.data) return {};
    const { projectRequiredDocuments = [], projectInternalDocuments = [] } = response?.data;
    const mapRequiredDocuments = projectRequiredDocuments.map((item: any) => {
      const { submissionToMot } = item || {};
      return {
        ...item,
        submissionToMot: isNil(submissionToMot) ? null : submissionToMot ? SUBMISSION_MOT.need : SUBMISSION_MOT.unnecessary,
        isShowSubmittedName: true,
        currentChecked: false
      };
    });
    const mapInternalDocuments = projectInternalDocuments.map((item: any) => {
      return {
        ...item,
        isShowSubmittedName: true,
        currentChecked: false
      };
    });
    const defaultValues = {
      ...response?.data,
      projectRequiredDocuments: mapRequiredDocuments,
      projectInternalDocuments: mapInternalDocuments
    };
    getDocumentName(defaultValues?.projectId, defaultValues?.projectCountryId);
    setDefaultValues(defaultValues);
    return defaultValues;
  };

  const getDocumentName = async (projectId: string = '', projectCountryId: string = '') => {
    const filter = generateFilterDocument({ projectId, countryId: projectCountryId });
    try {
      const responses = await getDocumentNameList({
        filter,
        pageIndex: 0,
        pageSize: 10000,
        sorts: 'updatedDate=desc'
      });

      if (responses?.status === HTTP_STATUS_CODE.SUCCESS) {
        const options = responses?.data?.data?.map((document: any) => {
          const { name, id, numOfCopy, note, submissionToMot, countryId, managementCode } = document || {};
          return {
            label: managementCode,
            name,
            value: id,
            numberOfCopy: numOfCopy,
            note,
            submissionToMot,
            countryId
          };
        });
        setDocumentNameOption(options);
      }
    } catch (error) {}
  };

  const handleSave = () => {
    if (isSubmitting) {
      return;
    }
    formMethod.handleSubmit(onSubmitEdit, onError)();
  };

  const onSubmitEdit = async () => {
    const { projectRequiredDocuments: projectRequiredDocumentsDefault, projectInternalDocuments: projectInternalDocumentsDefault } =
      defaultValues || {};
    const { projectRequiredDocuments, projectInternalDocuments } = formMethod.getValues();
    const body = {
      projectRequiredDocuments: mapValueSubmissionToMot(
        mapValueForItemDeleted(projectRequiredDocuments, projectRequiredDocumentsDefault),
        DOCUMENT_TYPE.projectRequiredDocuments
      ),
      projectInternalDocuments: mapValueSubmissionToMot(
        mapValueForItemDeleted(projectInternalDocuments, projectInternalDocumentsDefault),
        DOCUMENT_TYPE.projectInternalDocuments
      )
    };

    return new Promise(async (resolve, reject) => {
      try {
        const result = await saveDocumentList(body);
        formMethod.reset((pre) => ({ ...pre, isDirty: false }), { keepValues: true, keepDirty: false });
        if (result?.status === HTTP_STATUS_CODE.SUCCESS) {
          setTimeout(() => {
            navigate(DOCUMENT_LIST_URL(id ?? ''));
            resolve(result);
          }, 500);
        }
      } catch (error: any) {
        reject(error);
      }
    });
  };

  const onError = async (err: any) => {
    let firstInValidKey: string | undefined = Object.keys(err)?.[0];
    const formCollapseMapping = {
      projectRequiredDocuments: requiredDocumentRef,
      projectInternalDocuments: internalDocumentRef
    };
    // Open all collapse invalid
    for (let key in err) {
      if (formCollapseMapping.hasOwnProperty(key) && key in formCollapseMapping) {
        const formRef = formCollapseMapping[key as keyof typeof formCollapseMapping];
        const idRef = formRef.current?.getIdElement();
        formRef.current?.setCollapse(idRef);
      }
    }
    // Scroll to first collapse invalid
    if (firstInValidKey !== undefined && formCollapseMapping.hasOwnProperty(firstInValidKey) && firstInValidKey in formCollapseMapping) {
      const formRef = formCollapseMapping[firstInValidKey as keyof typeof formCollapseMapping];
      const idRef = formRef.current?.getIdElement();
      const element = document.getElementById(`base-collapse-${idRef}`);
      element?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleCancel = useCallback(async () => {
    if (isDirty) {
      const title = t('common:MSG_C_002:title');
      const msg = t('common:MSG_C_002:description');
      const showPopup = await showBasePopup({
        title,
        msg,
        type: 'discard'
      });
      if (showPopup === 'confirm') {
        navigate(DOCUMENT_LIST_URL(id ?? ''));
      }
    } else navigate(DOCUMENT_LIST_URL(id ?? ''));
  }, [isDirty]);

  const title = renderProjectCode(projectData, t('basic_information:proposal_number'));

  return (
    <div className='form-basic-information document-list'>
      <FormProvider {...formMethod}>
        <div className='project-tabs-content container-scroll flex flex-col'>
          <header className='header-basic-information flex flex-col sticky top-0'>
            <div className='flex flex-row justify-between'>
              <div className='flex flex-col'>
                <div className='title-24'>{t('required_documents:required_documents_list')}</div>
                <div className='title-14'>
                  {title}
                  <span className='proposal-number'>{projectData?.code ? `(${projectData.code})` : ''}</span>
                </div>
              </div>
              <Space size={16}>
                <div className='min-w-[120px]'>
                  <BaseButton block size='medium' type='tertiary' onClick={handleCancel}>
                    {t('basic_information:cancel')}
                  </BaseButton>
                </div>
                <div className='min-w-[120px]'>
                  <BaseButton disabled={!isDirty || isSubmitting} block size='medium' onClick={handleSave}>
                    <span> {t('basic_information:save')} </span>
                  </BaseButton>
                </div>
              </Space>
            </div>
          </header>
          <div className='flex flex-col h-[calc(100vh-100px)] overflow-y-scroll pb-4 gap-3'>
            <DocumentListComponent
              ref={requiredDocumentRef}
              formMethod={formMethod}
              type={DOCUMENT_TYPE.projectRequiredDocuments}
              documentNameOption={documentNameOption}
              loading={loading}
              getDocumentName={() => getDocumentName(defaultValues?.projectId, defaultValues?.projectCountryId)}
            />
            <DocumentListComponent
              ref={internalDocumentRef}
              formMethod={formMethod}
              type={DOCUMENT_TYPE.projectInternalDocuments}
              documentNameOption={documentNameOption}
              loading={loading}
              getDocumentName={() => getDocumentName(defaultValues?.projectId, defaultValues?.projectCountryId)}
            />
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

export default EditDocumentList;
