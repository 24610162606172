export enum TASK_TYPE {
  TODO = 'TODO',
  OTHER = 'OTHER',
  MOT = 'MOT'
}

export const TASK_TYPE_COLOR: { [key in TASK_TYPE]: string } = {
  TODO: '#F2A227',
  OTHER: '#076CE3',
  MOT: '#525A68'
};
