import { Tooltip } from 'antd';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import './AppTooltip.scss';

const AppTooltip = forwardRef(({ children, title, width, styles, overflow, ...rest }: any, ref) => {
  const defaultInnerStyle = {
    maxWidth: width ?? '500px',
    overflow: overflow ?? 'auto',
    padding: '8px 16px',
    // width: 'fit-content',
    fontSize: '14px',
    maxHeight: '300px'
  };

  const [titleTooltip, setTiteTooltip] = useState<any>('');

  useEffect(() => {
    setTiteTooltip(title);
  }, [title]);

  useImperativeHandle(ref, () => ({
    setTitle(title: string) {
      setTiteTooltip(title);
    }
  }));

  return (
    <Tooltip placement='top' color='#12212E' overlayInnerStyle={{ ...defaultInnerStyle, ...styles }} title={titleTooltip} {...rest}>
      {children}
    </Tooltip>
  );
});
export default AppTooltip;
