import { IRelativeField } from '@/components/common/postal-code-input';

export const APPLICANT_FIELDS = [
  'lastName',
  'firstName',
  'lastNameRomaji',
  'firstNameRomaji',
  'primaryNational',
  'secondaryNational',
  'dateOfBirth',
  'gender',
  'postalCode',
  'prefecture',
  'prefectureRoman',
  'city',
  'cityRoman',
  'suburb',
  'suburbRoman',
  'streetAddress',
  'apartmentName',
  'placeOfResidence',
  'currentCompany',
  'departureDate'
];
export const REQUIRED_APPLICANT_FIELDS = [
  'lastName',
  'firstName',
  'lastNameRomaji',
  'firstNameRomaji',
  'primaryNational',
  'dateOfBirth',
  'gender',
  'postalCode',
  'prefecture',
  'prefectureRoman',
  'city',
  'cityRoman',
  'suburb',
  'suburbRoman',
  'streetAddress',
  'apartmentName',
  'currentCompany',
  'departureDate'
];

export const ASSIGNMENT_INFO_FIELDS = ['countryId', 'companyName', 'address'];

export const REQUIRED_ASSIGNMENT_FIELDS = ['countryId'];

export const POSTAL_CODE_RELATIVE_FIELDS: IRelativeField[] = [
  {
    name: 'prefecture',
    key: 'prefecture'
  },
  {
    name: 'prefectureRoman',
    key: 'prefecture_roman'
  },
  {
    name: 'city',
    key: 'city'
  },
  {
    name: 'cityRoman',
    key: 'city_roman'
  },
  {
    name: 'suburb',
    key: 'suburb'
  },
  {
    name: 'suburbRoman',
    key: 'suburb_roman'
  },
  {
    name: 'streetAddress',
    key: 'street_address'
  },
  {
    name: 'apartmentName',
    key: 'office'
  }
];
