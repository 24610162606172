import { BaseOptionSelect } from '../../components/base-beauty-select/BaseBeautySelect';

const statusOptions: BaseOptionSelect[] = [
  {
    type: 'not-start',
    label: '開始前',
    value: 'not-start'
  },
  {
    type: 'processing',
    label: '進行中',
    value: 'processing'
  },
  {
    type: 'visa-issuance',
    label: 'ビザ発給',
    value: 'visa-issuance'
  },
  {
    type: 'completed',
    label: '完了',
    value: 'completed'
  },
  {
    type: 'cancel',
    label: '中止',
    value: 'cancel'
  }
];
enum STATUS {
  NOT_START = 'not-start',
  PROCESSING = 'processing',
  VISA_ISSUANCE = 'visa_issuance',
  COMPLETED = 'completed',
  CANCEL = 'cancel',
  DRAFT = 'draft'
}

export { statusOptions, STATUS };
