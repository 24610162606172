import StakeholderUpdate from '@pages/stakeholder-management/edit/index';
import StakeholderView from '@pages/stakeholder-management/view/index';

import { V } from '@hooks/useAuthorization';

import { STAKEHOLDER_UPDATE, STAKEHOLDER_VIEW } from '@/utils/constants/stakeholder';
import { MAP_PATH_PRE_CHECK_404 } from '@/utils/helpers/routeLoaders';
import { ALL_ROLE } from '@utils/constants/AppConstants';
import { STAKEHOLDER_UPDATE_URL, STAKEHOLDER_VIEW_URL } from '@utils/constants/RouteContants';
import { IRoute } from '@utils/interfaces/route';

const StakeholderRoutes: IRoute[] = [
  {
    path: STAKEHOLDER_VIEW_URL(':id'),
    name: STAKEHOLDER_VIEW,
    action: V,
    element: <StakeholderView />,
    auth: true,
    role: ALL_ROLE,
    preCheck404: MAP_PATH_PRE_CHECK_404[STAKEHOLDER_VIEW_URL(':id')]
  },
  {
    path: STAKEHOLDER_UPDATE_URL(':id'),
    name: STAKEHOLDER_UPDATE,
    action: V,
    element: <StakeholderUpdate />,
    auth: true,
    role: ALL_ROLE,
    preCheck404: MAP_PATH_PRE_CHECK_404[STAKEHOLDER_UPDATE_URL(':id')]
  }
];

export default StakeholderRoutes;
