import dayjs from 'dayjs';
import { isNil } from 'lodash';

import { QUERY_CONDITION, QUERY_OPERATION, QUERY_TYPE } from '@/utils/constants';
import QueryFactory, { QueryItem } from '@/utils/helpers/filters/base';
import { intersectArrays } from '@/utils/helpers/globalHelper';

import { DOCUMENT_TYPE, OTHER_COUNTRY_ID, SUBMISSION_MOT } from '../constants';
import { IDocumentList, IQueryFilterDocumentParams, IQueryFilterParams } from '../model';

class DocumentQueryFactory extends QueryFactory {
  filterCountry(id: string) {
    const newItem = this.initNewQuery(QUERY_CONDITION.EMPTY);
    newItem?.childrens?.push(
      new QueryItem('countryId', QUERY_TYPE.GUID, id, QUERY_OPERATION.EQUAL, QUERY_CONDITION.EMPTY),
      new QueryItem('countryId', QUERY_TYPE.GUID, (id = OTHER_COUNTRY_ID), QUERY_OPERATION.EQUAL, QUERY_CONDITION.OR)
    );
    this.append(newItem);
    return this;
  }

  filterProject(id: string) {
    const newItem = this.initNewQuery(QUERY_CONDITION.AND);
    newItem?.childrens?.push(
      new QueryItem().modules.guidWith('', { fieldTitle: 'projectId' }),
      new QueryItem('projectId', QUERY_TYPE.GUID, id, QUERY_OPERATION.EQUAL, QUERY_CONDITION.OR)
    );
    this.append(newItem);
    return this;
  }
}

export const generateFilter = (params: IQueryFilterParams): string => {
  const filterFactory = new DocumentQueryFactory();
  const { keywordSearch } = params ?? {};
  if (keywordSearch) {
    const fields = ['submissionToMot.ToLower()', 'submissionStatus.ToLower()', 'comunicationStatus.ToLower()'];
    filterFactory.searchingBy(fields, keywordSearch.toLowerCase());
  }

  return filterFactory.sanitize().stringifyData();
};

export const generateFilterDocument = (params: IQueryFilterDocumentParams): string => {
  const { projectId, countryId } = params;
  const filterFactory = new DocumentQueryFactory();
  if (countryId) {
    filterFactory.filterCountry(countryId);
  }
  if (projectId) {
    filterFactory.filterProject(projectId);
  }

  const result = filterFactory.sanitize().stringifyData();
  return result;
};

export const mapValueSubmissionToMot = (document: IDocumentList[], type: string) => {
  return document.map((item: any, index: number) => {
    const dateConfirm = () => {
      if (item?.currentChecked) return dayjs();
      if (!item.isConfirm && !item?.currentChecked) return null;
      if (item.isConfirm && !item?.currentChecked) return item?.dateConfirm;
    };
    if (type === DOCUMENT_TYPE.projectRequiredDocuments) {
      const { submissionToMot } = item || {};
      return {
        ...item,
        submissionToMot: isNil(submissionToMot) ? submissionToMot : submissionToMot === SUBMISSION_MOT.need,
        displayOrder: index + 1,
        dateConfirm: dateConfirm()
      };
    }
    return {
      ...item,
      displayOrder: index + 1,
      dateConfirm: dateConfirm()
    };
  });
};

export const mapValueForItemDeleted = (dataUpdate: IDocumentList[], dataDefault: IDocumentList[] = []) => {
  const commonItems = intersectArrays(dataUpdate, dataDefault, 'id');
  const commonIdSet = new Set(commonItems.map((i) => i.id));
  const dataInsertOrUpdate = dataUpdate.map((item: any) => ({
    ...item,
    id: commonIdSet.has(item.id) ? item.id : undefined
  }));
  const dataDelete = dataDefault.filter((item: any) => !commonIdSet.has(item.id)).map((item: any) => ({ ...item, deleted: true }));
  return [...dataInsertOrUpdate, ...dataDelete];
};
