import TemplateListV2 from '@/pages/template-management/list';

import { ROUTER_IDS } from '@/utils/constants';
import { MAP_PATH_PRE_CHECK_404 } from '@/utils/helpers/routeLoaders';

import { CREATE_TEMPLATE_ROUTER, EDIT_TEMPLATE_ROUTER, TEMPLATE_LIST, V, VIEW_TEMPLATE_ROUTER } from '../hooks/useAuthorization';
import TemplateAddNewAndEdit from '../pages/template-management/add-edit/AddEditTemplateLayout';
import TemplateViewDetail from '../pages/template-management/view/ViewDetail';
import { INTERNAL_ADMIN, INTERNAL_GENERAL_USER } from '../utils/constants/AppConstants';
import { CREATE_TEMPLATE_URL, EDIT_TEMPLATE_URL, TEMPLATE_LIST_URL, VIEW_TEMPLATE_URL } from '../utils/constants/RouteContants';
import { IRoute } from '../utils/interfaces/route';

const TemplateRoutes: IRoute[] = [
  {
    path: TEMPLATE_LIST_URL,
    name: TEMPLATE_LIST,
    action: V,
    element: <TemplateListV2 />,
    auth: true,
    role: [INTERNAL_ADMIN, INTERNAL_GENERAL_USER],
    id: ROUTER_IDS.TEMPLATE_LIST
  },
  {
    path: VIEW_TEMPLATE_URL(':id'),
    name: VIEW_TEMPLATE_ROUTER,
    action: V,
    element: <TemplateViewDetail />,
    auth: true,
    role: [INTERNAL_ADMIN, INTERNAL_GENERAL_USER],
    preCheck404: MAP_PATH_PRE_CHECK_404[VIEW_TEMPLATE_URL(':id')]
  },
  {
    path: CREATE_TEMPLATE_URL,
    name: CREATE_TEMPLATE_ROUTER,
    action: V,
    element: <TemplateAddNewAndEdit />,
    auth: true,
    role: [INTERNAL_ADMIN]
  },
  {
    path: EDIT_TEMPLATE_URL(':id'),
    name: EDIT_TEMPLATE_ROUTER,
    action: V,
    element: <TemplateAddNewAndEdit />,
    auth: true,
    role: [INTERNAL_ADMIN],
    preCheck404: MAP_PATH_PRE_CHECK_404[EDIT_TEMPLATE_URL(':id')]
  }
];

export default TemplateRoutes;
