import React from 'react';
import { useTranslation } from 'react-i18next';

import AppTooltip from '@/components/app-tooltip/AppTooltip';

import { DataViewer } from '@/utils/helpers/common';

interface IEditHistoryData {
  createdDate?: string;
  createdName?: string;
  updatedDate?: string;
  updatedName?: string;
}

interface IEditHistoryProps {
  data: IEditHistoryData;
}

const EditHistory = (props: IEditHistoryProps) => {
  const { createdName, createdDate = '', updatedName, updatedDate = '' } = props.data;

  const { t } = useTranslation();
  return (
    <div className='flex justify-end gap-4 mb-4'>
      <div className='flex flex-col gap-2 items-start font-normal text-[#525A68] leading-[19px]'>
        <AppTooltip className='truncate inline-block w-[131px] text-[12px] body-400' title={DataViewer.display(createdName)}>
          {`${t('master_data:author')}: ${DataViewer.display(createdName)}`}
        </AppTooltip>
        <AppTooltip className='truncate inline-block w-[131px] text-[12px] body-400' title={DataViewer.displayTime(createdDate)}>
          {`${t('master_data:created_date')}: ${DataViewer.displayTime(createdDate)}`}
        </AppTooltip>
      </div>
      <div className='flex flex-col gap-2 items-start font-normal text-[#525A68] leading-[19px]'>
        <AppTooltip className='truncate inline-block w-[131px] text-[12px] body-400' title={DataViewer.display(updatedName)}>
          {`${t('master_data:updater')}: ${DataViewer.display(updatedName)}`}
        </AppTooltip>
        <AppTooltip className='truncate inline-block w-[131px] text-[12px] body-400' title={DataViewer.displayTime(updatedDate)}>
          {`${t('master_data:updated_date')}: ${DataViewer.displayTime(updatedDate)}`}
        </AppTooltip>
      </div>
    </div>
  );
};

export default EditHistory;
