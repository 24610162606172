import dayjs from 'dayjs';
import React, { forwardRef } from 'react';
import { FieldValues, RegisterOptions, useController, useFormContext } from 'react-hook-form';

import AppTooltip from '@/components/app-tooltip/AppTooltip';
import { ErrorInput } from '@/components/error-input/ErrorInput';

import { useMessageYupTranslation } from '@/hooks/useI18n';

import { REQUIRED_DOT } from '@/utils/constants/AppConstants';
import { formatDateTime } from '@/utils/helpers/globalHelper';
import { ColorDefault } from '@/utils/themes/color';

import ErrorIcon from '@assets/icons/WarningCircle.svg';

import './index.scss';

interface FormItemProps {
  name: string;
  label?: string;
  required?: boolean;
  children: React.JSX.Element; // Replace 'BaseRadioGroups' with 'any' if the type is not defined
  rules?: Omit<RegisterOptions<FieldValues, string>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'> | undefined;
  showErrorOption?: 'icon' | 'text';
  showLabel?: boolean;
  needTrimValue?: boolean;
}

const mappingError: { [key: string]: any } = {
  maxLength: { keyT: 'common:MSG_C_011' },
  number: { keyT: 'common:MSG_C_032' },
  fullsize: { keyT: 'common:MSG_C_027' },
  halfsize: { keyT: 'common:MSG_C_028' }
};

const FormItem: React.ForwardRefRenderFunction<any, FormItemProps> = (
  { needTrimValue, children, showLabel = true, showErrorOption = 'text', name, label, required, rules },
  ref
) => {
  const { control } = useFormContext();
  const {
    field,
    fieldState: { error }
  } = useController({ name, control, rules });
  const msgError = useMessageYupTranslation(
    error?.message
      ? error?.message
      : JSON.stringify({
          keyT: mappingError[error?.type?.toString() ?? '']?.keyT ?? '',
          options: { field: label, limited: rules?.maxLength ?? 0 }
        })
  );

  const handleChange = (e: any, ...params: any) => {
    const callbackFunc = params[params.length - 1];
    const remainParams = params.slice(0, -1);
    let valueInput = e?.currentTarget ? e?.currentTarget.value : e;
    if (valueInput instanceof dayjs) {
      valueInput = formatDateTime(e as any);
    }
    field.onChange(valueInput);
    callbackFunc?.(e, ...remainParams);
  };

  const handleOnBlur = (e: any) => {
    let valueInput = e?.currentTarget ? e?.currentTarget.value : e;
    if (needTrimValue && typeof valueInput === 'string') {
      const newValue = valueInput.trim();
      if (valueInput !== newValue) {
        field.onChange(newValue);
      }
    }
    field.onBlur();
  };

  return (
    <div className='form-item-controller'>
      {showLabel && label && (
        <div className='label-container'>
          <span>{label}</span>
          {required && (
            <div className='require' color={ColorDefault.negative}>
              {REQUIRED_DOT}
            </div>
          )}
        </div>
      )}
      {React.Children.map(children, (child) =>
        React.cloneElement(child as any, {
          ...field,
          onChange: (e: any, ...params: any[]) => {
            handleChange(e, ...params, (child as any).props.onChange);
          },
          onBlur: (e: any, ...params: any[]) => {
            handleOnBlur(e, ...params, (child as any).props.onChange);
          },
          suffix:
            showErrorOption === 'icon' && error ? (
              <AppTooltip title={msgError}>
                <ErrorIcon width={18} height={18} className='error-icon' />
              </AppTooltip>
            ) : (
              <></>
            ),
          status: error ? 'error' : undefined,
          ref
        })
      )}
      {error !== undefined && showErrorOption === 'text' && (
        <ErrorInput classNameLabel={'truncate'} className={'!mt-0 h-[26px]'} error={msgError ?? ''} />
      )}
    </div>
  );
};

export default forwardRef(FormItem);
