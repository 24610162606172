import { useTranslation } from 'react-i18next';

import ArrowCancel from '@assets/images/ArrowCancel.png';

export const Expired = () => {
  const { t } = useTranslation();

  return (
    <div className='h-screen flex flex-col gap-[24px] items-center justify-center'>
      <img src={ArrowCancel} width={270} height={252} alt='expired-image' />
      <div className='text-24 font-bold'>{t('questionnaire:url_expired')}</div>
    </div>
  );
};
