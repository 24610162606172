import { QUERY_CONDITION } from '@/utils/constants';
import QueryFactory, { QueryItem } from '@/utils/helpers/filters/base';

import { STATUS } from '../contants';
import { IQueryFilterParams } from '../models';

export class PhrasesQueryFactory extends QueryFactory {
  getById(value: string, field: string) {
    const newQuerySection = this.initNewQuery(this.data?.length ? QUERY_CONDITION.AND : QUERY_CONDITION.EMPTY);
    const picIdQuery = new QueryItem().modules.guidWith(value, { fieldTitle: field });
    newQuerySection.childrens?.push(picIdQuery);
    this.append(newQuerySection);
    return this;
  }

  updatedDateFrom(date: string) {
    const newQuerySection = this.initNewQuery(this.data?.length ? QUERY_CONDITION.AND : QUERY_CONDITION.EMPTY);
    newQuerySection?.childrens?.push(new QueryItem().modules.fromDate(date, { fieldTitle: 'updatedDate' }));
    this.append(newQuerySection);
    return this;
  }

  updatedDateTo(date: string) {
    const newQuerySection = this.initNewQuery(this.data?.length ? QUERY_CONDITION.AND : QUERY_CONDITION.EMPTY);
    newQuerySection?.childrens?.push(new QueryItem().modules.toDate(date, { fieldTitle: 'updatedDate' }));
    this.append(newQuerySection);
    return this;
  }
}

export const generateFilter = (params?: IQueryFilterParams): string => {
  const filterFactory = new PhrasesQueryFactory();

  if (params?.keyword) {
    const fields = ['userNameSearch.ToLower()', 'email.ToLower()', 'updatedName.ToLower()'];
    filterFactory.searchingBy(fields, params.keyword.toLowerCase());
  }

  if (params?.organizationId) {
    filterFactory.getById(params.organizationId, 'organizationId');
  }

  if (params?.roleId) {
    filterFactory.getById(params.roleId, 'roleId');
  }

  if (params?.status) {
    switch (params?.status) {
      case STATUS.active:
        filterFactory.getById('true', 'status');
        break;
      case STATUS.inactive:
        filterFactory.getById('false', 'waitingCreate');
        filterFactory.getById('false', 'status');
        break;
      default:
        filterFactory.getById('true', 'waitingCreate');
        break;
    }
  }

  if (params?.updateDateFrom) {
    filterFactory.updatedDateFrom(params?.updateDateFrom);
  }

  if (params?.updateDateTo) {
    filterFactory.updatedDateTo(params?.updateDateTo);
  }

  return filterFactory.sanitize().stringifyData();
};
