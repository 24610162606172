import React from 'react';
import { useTranslation } from 'react-i18next';

import AppLabel from '../../../components/app-label/AppLabel';
import { FormInputSelect } from '../../../components/form-input-select/FormInputSelect';
import { FormInput } from '../../../components/form-input/FormInput';
import { IUserProfile } from '../../../utils/interfaces/user';

interface IProfileFormProps {
  isEdit?: boolean;
  user?: IUserProfile | null;
}

const FormMyProfile = (props: IProfileFormProps) => {
  const { t } = useTranslation();
  const { user, isEdit } = props;

  return (
    <div>
      <div className='p-6 grid grid-cols-2 gap-6 form-my-profile'>
        {isEdit ? (
          <div className='col-span-2'>
            <FormInput
              id='input-my-profile-user-name'
              disabled={true}
              required
              name='name'
              defaultValue={user?.name || undefined}
              labelTx='my_profile:user_name'
              placeholderTx={t('placeholder:text_box', { field: t('my_profile:user_name').toLowerCase() }) ?? ''}
            />
          </div>
        ) : (
          <div className='col-span-1'>
            <AppLabel label={t('my_profile:user_name')} title={user?.name} hasTooltip />
          </div>
        )}
        {isEdit ? (
          <div className='col-span-2'>
            <FormInputSelect
              name='organizationName'
              disabled={true}
              required
              labelTx='my_profile:company_name'
              placeholderTx={t('placeholder:select', { field: t('my_profile:company_name').toLowerCase() }) ?? ''}
            />
          </div>
        ) : (
          <div className='col-span-1'>
            <AppLabel label={t('my_profile:company_name')} title={user?.organizationName} hasTooltip />
          </div>
        )}
        {isEdit ? (
          <div className='col-span-2'>
            <FormInput
              defaultValue={user?.email || undefined}
              id='input-my-profile-email-address'
              required
              disabled={true}
              name='email'
              labelTx='my_profile:email_address'
              placeholderTx={t('placeholder:text_box', { field: t('my_profile:email_address').toLowerCase() }) ?? ''}
            />
          </div>
        ) : (
          <div className='col-span-1'>
            <AppLabel label={t('my_profile:email_address')} title={user?.email} hasTooltip />
          </div>
        )}
        {isEdit ? (
          <div className='col-span-2'>
            <FormInput
              defaultValue={user?.roleName || undefined}
              id='input-my-profile-role'
              required
              disabled={true}
              name='roleName'
              labelTx='my_profile:role'
              placeholderTx={t('placeholder:text_box', { field: t('my_profile:role').toLowerCase() }) ?? ''}
            />
          </div>
        ) : (
          <div className='col-span-1'>
            <AppLabel label={t('my_profile:role')} title={user?.roleName || ''} hasTooltip />
          </div>
        )}
      </div>
    </div>
  );
};

export default FormMyProfile;
