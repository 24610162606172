import { Spin } from 'antd';

const LoadingPage = () => {
  return (
    <div className='text-center loading-page'>
      <Spin size='large' />
    </div>
  );
};

export default LoadingPage;
