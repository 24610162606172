import { Popover, Space } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { DEFAULT_MAX_ITEM_ALLOW, SORT_LATEST, TYPE } from '@/utils/constants/AppConstants';

import Slider from '../../assets/icons/Sliders.svg?react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import useOptionsGlobal from '../../hooks/useOptionsGlobal';
import { setAlertNotification, setLoadingPage } from '../../redux/globalReducer';
import { _generateFilterSearchQuery, filterHelper } from '../../utils/helpers/globalHelper';
import { disabledByStatus } from '../../utils/helpers/project';
import { IProject } from '../../utils/interfaces/Project';
import { InformationDetailSecurityProps } from '../../utils/interfaces/security';
import { IParams } from '../../utils/interfaces/service';
import { ITemplate } from '../../utils/interfaces/template';
import { getTemplates } from '../../utils/services/template';
import { BaseButton } from '../base-button/BaseButton';
import { showBasePopup } from '../base-popup/BasePopup';
import { FormInputSelect } from '../form-input-select/FormInputSelect';

import './ReferenceTemplateFilter.scss';

const defaultSecurityProps: InformationDetailSecurityProps = {
  referenceTemplateViewable: true,
  referenceTemplateSelectable: true,
  filterViewable: true,
  filterClickable: true
};

type ReferenceTemplateFilterProps = {
  referenceTemplateFilterSecurityProps?: InformationDetailSecurityProps;
  dataBasicInfo?: IProject;
  onRefresh?: () => void;
  method: any;
  getTemplateDetail: (templateId?: string, isFromChangeTemplate?: boolean) => void;
  formOptions: DefaultOptionType[];
  categoryOptions: DefaultOptionType[];
  disabled?: boolean;
};

const ReferenceTemplateFilter = ({
  referenceTemplateFilterSecurityProps,
  dataBasicInfo,
  getTemplateDetail,
  categoryOptions,
  formOptions,
  method,
  disabled
}: ReferenceTemplateFilterProps) => {
  referenceTemplateFilterSecurityProps = { ...defaultSecurityProps, ...referenceTemplateFilterSecurityProps };
  const { t } = useTranslation();

  const [filterPopupVisible, setFilterPopupVisible] = useState<boolean>(false);
  const [numberFiltered, setNumberFiltered] = useState<number>(0);
  const [templateOptions, setTemplateOptions] = useState<DefaultOptionType[]>([]);
  const [disabledButtonCancel, setDisabledButtonCancel] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [filtered, setFiltered] = useState<{
    categoryId: string;
    formId: string;
    countryId?: string;
  } | null>();
  const [loadingTemplates, setLoadingTemplates] = useState<boolean>(false);
  const [templateParams, setTemplateParams] = useState<IParams>({
    pageSize: DEFAULT_MAX_ITEM_ALLOW,
    pageIndex: 0,
    sorts: SORT_LATEST
  });
  const [totalTemplatesPage, setTotalTemplatesPage] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const patterns = useAppSelector((state) => state.global.processPatterns);
  const { id } = useParams();
  // Get data templates
  const { otherCountry } = useOptionsGlobal();

  const formMethod = useForm<{
    categoryId: string;
    formId: string;
    templateId: string;
    patternId: string;
  }>({
    mode: 'all',
    shouldUnregister: false,
    defaultValues: {
      categoryId: '',
      formId: ''
    }
  });
  const { categoryId, formId, patternId } = formMethod.watch();
  const { documents, materials, tasks } = method.watch();
  const projectDetail = useAppSelector((state) => state.project.projectDetails);
  const countryId = dataBasicInfo?.countryId || otherCountry?.id;

  /**
   * Fetch master data
   */
  // handle countries list

  useEffect(() => {
    if (filterPopupVisible && filtered) {
      formMethod.reset(filtered);
    }
    if (filtered) {
      const filter = { ...filtered };
      delete filter.countryId;
      const numberFilter: number = Object.values(filter).filter((v: any) => v).length;
      setNumberFiltered(numberFilter);
    }
  }, [filterPopupVisible, filtered]);

  const onClearFilter = () => {
    formMethod.reset({
      categoryId: '',
      formId: '',
      patternId: ''
    });
  };

  const onApplyFilter = () => {
    let values: any = { ...formMethod.getValues() };
    if (countryId) {
      values = {
        countryId,
        ...values
      };
    }
    setFiltered(values);
    setFilterPopupVisible(false);
    setTemplateOptions([]);
    setTemplateParams({
      ...templateParams,
      pageIndex: 0,
      filter: filterHelper(values)
    });
  };

  useEffect(() => {
    const isValid = !(categoryId || formId || patternId);
    setDisabledButtonCancel(isValid);
  }, [categoryId, formId, patternId]);

  const getTemplateList = async () => {
    setLoadingTemplates(true);
    let newFilter = [];
    const objectFilter = JSON.parse(templateParams.filter || '') || [];
    const filterCountry = objectFilter?.find((filed: any) => filed?.fieldTitle === 'countryId') || {};
    if (isEmpty(filterCountry)) {
      newFilter = objectFilter;
    } else {
      const mapFilterWithOutCountry = objectFilter?.filter((filed: any) => filed?.fieldTitle !== 'countryId');
      const filterDualCountry = {
        childrens: [
          filterCountry,
          {
            fieldTitle: 'countryId',
            queryType: 'text',
            queryValue: otherCountry?.id,
            operation: 'eq',
            queryCondition: 'or'
          }
        ],
        queryCondition: ''
      };
      newFilter = [filterDualCountry, ...mapFilterWithOutCountry];
    }
    newFilter = newFilter.map((f: any, i: number) => {
      return {
        ...f,
        queryCondition: i === 0 ? '' : 'and'
      };
    });
    try {
      const { data: res } = await getTemplates({
        ...templateParams,
        filter: JSON.stringify(newFilter)
      });
      const templatesRes: ITemplate[] = res?.data?.filter((item: any) => item?.id !== id) || [];
      if (templatesRes.length) {
        let options: DefaultOptionType[] = templatesRes.map((c: ITemplate) => {
          return {
            label: c.name,
            value: c.id
          };
        });
        setTotalTemplatesPage(res?.totalPage || 0);
        if (res?.totalCount && res?.totalCount > totalCount) {
          setTotalCount(res?.totalCount);
        }
        setTemplateOptions(options);
      } else {
        if (dataBasicInfo?.templateName && dataBasicInfo?.templateId) {
          setTemplateOptions([
            {
              label: dataBasicInfo?.templateName,
              value: dataBasicInfo?.templateId
            }
          ]);
        }
      }
      setTimeout(() => {
        setLoadingTemplates(false);
      }, 500);
    } catch (error) {
      if (dataBasicInfo?.templateName && dataBasicInfo?.templateId) {
        setTemplateOptions([
          {
            label: dataBasicInfo?.templateName,
            value: dataBasicInfo?.templateId
          }
        ]);
      }
      setLoadingTemplates(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (countryId) {
      setTemplateParams({
        ...templateParams,
        filter: filterHelper({ countryId })
      });
    }
  }, [countryId]);

  useEffect(() => {
    if (templateParams.filter) {
      getTemplateList();
    }
  }, [templateParams]);

  const dispatch = useAppDispatch();

  // Filter content
  const FilterComponents = (
    <FormProvider {...formMethod}>
      <Space className='template-filter-form filter grid grid-flow-row auto-rows-[auto_1fr_auto]' direction='vertical'>
        <div className='header'>{t('template_layout:filter')}</div>
        <div className='body'>
          <div className='row column'>
            <div className='filter-item'>
              <FormInputSelect
                disabled={disabled}
                name='categoryId'
                labelTx='template_layout:visa_category'
                options={categoryOptions}
                allowClear
                placeholderTx={t('placeholder:select', { field: t('template_layout:visa_category') }) ?? ''}
                onChange={() => setDisabledButtonCancel(false)}
              />
            </div>
          </div>
          <div className='row column'>
            <div className='filter-item'>
              <FormInputSelect
                disabled={disabled}
                name='formId'
                labelTx='template:form'
                options={formOptions}
                placeholderTx={t('placeholder:select', { field: t('template_layout:form') }) ?? ''}
                onChange={() => setDisabledButtonCancel(false)}
              />
            </div>
          </div>
          <div className='row column'>
            <div className='filter-item'>
              <FormInputSelect
                disabled={disabled}
                name='patternId'
                labelTx='template_layout:procedual_process'
                placeholder={t('placeholder:select', { field: t('template_layout:procedual_process') }) as string}
                options={patterns?.map((e) => ({ label: e.name, value: e.id }))}
                className='min-w-[293px]'
              />
            </div>
          </div>
        </div>
        <div className='row !justify-end filter-footer'>
          <BaseButton disabled={disabledButtonCancel} onClick={onClearFilter} size='medium' type='tertiary' style={{ width: '100%' }}>
            {t('project:filter:btn_reset')}
          </BaseButton>
          <BaseButton onClick={() => onApplyFilter()} size='medium' style={{ width: '100%' }}>
            <span>{t('project:filter:btn_aply')} </span>
          </BaseButton>
        </div>
      </Space>
    </FormProvider>
  );

  const onScroll = (e: any) => {
    setFilterPopupVisible(false);
  };

  useEffect(() => {
    const elementsScroll = document.querySelectorAll('.container-scroll');
    for (let index = 0; index < elementsScroll.length; index++) {
      const elementScroll = elementsScroll[index];
      elementScroll.removeEventListener('scroll', onScroll);
      elementScroll.addEventListener('scroll', onScroll, { passive: true });
    }

    return () => {
      for (let index = 0; index < elementsScroll.length; index++) {
        const elementScroll = elementsScroll[index];
        elementScroll?.removeEventListener('scroll', onScroll);
      }
    };
  }, []);

  const FilterReference = referenceTemplateFilterSecurityProps?.filterViewable && (
    <Popover
      open={filterPopupVisible && referenceTemplateFilterSecurityProps?.filterClickable}
      onOpenChange={(val) => {
        !disabled && setFilterPopupVisible(val);
      }}
      placement='bottomRight'
      arrow={false}
      overlayInnerStyle={{ padding: '0' }}
      content={FilterComponents}
      trigger='click'
    >
      <div
        className={`px-[16px] flex items-center h-full w-max cursor-pointer relative
                ${disabledByStatus(projectDetail?.status) && 'pointer-events-none !cursor-not-allowed'}`}
      >
        <Space direction='horizontal'>
          <div className={`subtitle-14 text-black w-max ${disabledByStatus(projectDetail?.status) && 'opacity-[0.4]'}`}>
            {t('template_layout:filter')}
          </div>
          <img src={Slider} className={`h-[18px] w-[18px] p-0 ${disabledByStatus(projectDetail?.status) && 'opacity-[0.4]'}`} alt='sliders' />
        </Space>
        {!!filtered && numberFiltered > 0 && <span className='number-items'>{numberFiltered}</span>}
      </div>
    </Popover>
  );
  const handleTemplateChange = async (templateId?: string) => {
    if (!templateId) return true;
    if (!documents?.length && !materials?.length && !tasks?.length) {
      const isSucess = await onChangeTemplate(templateId as string);
      return isSucess;
    }
    const showConfirmRefer = await showBasePopup({
      type: TYPE.CONFIRM,
      title: t('common:MSG_C_010.title') || '',
      msg: t('common:MSG_C_010.content') ?? ''
    });
    if (showConfirmRefer === TYPE.CONFIRM) {
      const isSucess = await onChangeTemplate(templateId as string);
      return isSucess;
    }
    return false;
  };

  const onChangeTemplate = async (templateId: string) => {
    try {
      dispatch(setLoadingPage(true));
      await getTemplateDetail(templateId, true);
      return true;
    } catch (error: any) {
      if (error?.response) {
        let errorMessage: string = '';
        if (error?.response?.data?.fields?.[0]?.errorCode?.includes('NOT_FOUND')) {
          errorMessage = t(`common:MSG_079`, { name: t('template_layout:reference_template') }) || '';
        } else {
          errorMessage = t('common:MSG_026a', { name: dataBasicInfo?.project_id });
        }
        dispatch(
          setAlertNotification({
            show: true,
            type: 'error',
            position: 'top',
            message: errorMessage
          })
        );
      }
      dispatch(setLoadingPage(false));
      return false;
    }
  };
  return (
    <div>
      <FormInputSelect
        disabled={disabled || !referenceTemplateFilterSecurityProps?.referenceTemplateSelectable || disabledByStatus(projectDetail?.status)}
        name='templateId'
        options={templateOptions}
        allowClear={true}
        rightChildren={FilterReference}
        isConfirmPopup={true}
        loadDataOptions={!isLoading}
        loading={loadingTemplates}
        totalCount={totalCount}
        handleSelect={(value: any) => {
          return handleTemplateChange(value);
        }}
        handleSearchByApi={(keyword: string) => {
          setLoadingTemplates(true);
          setTimeout(() => {
            setTemplateOptions([]);
            if (keyword) {
              const objectFilter = JSON.parse(templateParams.filter || '[]') || [];
              const querySearch = _generateFilterSearchQuery(['nameSearch'], keyword);
              setTemplateParams({
                ...templateParams,
                filter: JSON.stringify([...objectFilter, querySearch])
              });
            } else {
              setTemplateParams({
                ...templateParams,
                filter: filterHelper({ countryId: dataBasicInfo?.countryId })
              });
            }
          }, 300);
        }}
        onPopupScroll={async (e: any) => {
          const { target }: any = e;

          if (!loadingTemplates && target.scrollTop > 250 && target.scrollTop + target.offsetHeight === target.scrollHeight) {
            if (templateParams.pageIndex < totalTemplatesPage - 1) {
              setTemplateParams({
                ...templateParams,
                pageIndex: ++templateParams.pageIndex
              });
            }
          }
        }}
        labelTx='template_layout:reference_template'
        fieldPlaceholderI18n='template:template_reference_name'
      />
    </div>
  );
};

export default ReferenceTemplateFilter;
