import * as yup from 'yup';

import { stepFiveSchema } from './form/step-five/validation';
import { stepFourSchema } from './form/step-four/validations';
import { stepSixSchema } from './form/step-six/validation';
import { stepThreeSchema } from './form/step-three/validations';
import { stepTwoSchema } from './form/step-two/validations';

const dynamicSchema = yup.lazy((value) => {
  switch (value.stepId) {
    case 2:
      return stepTwoSchema;
    case 3:
      return stepThreeSchema;
    case 4:
      return stepFourSchema;
    case 5:
      return stepFiveSchema;
    case 6:
      return stepSixSchema;
    default:
      return yup.object().notRequired();
  }
});

export const schemaScenarioB = yup.object().shape({
  steps: yup.array().of(dynamicSchema)
});
