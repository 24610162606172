import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { cloneDeep } from 'lodash';

import { CUSTOM_HOOK_EVENTS, dispatchCustomEvent } from '@/hooks/useEventListener';

import { API } from '@/utils/constants/Apis';
import { parseJson } from '@/utils/helpers/globalHelper';
import { NOT_EXITS_URL } from '@utils/constants/RouteContants';

import i18n from '../i18n';
import { setAlertNotification } from '../redux/globalReducer';
import { store } from '../redux/store';
import { API_URL, API_URL_WITH_PORT, AXIOS_TIMEOUT, ERROR_CODE, LIST_API_EXCEPTIONS, LOCALSTORAGE } from '../utils/constants/AppConstants';

const token = localStorage.getItem('access_token') ?? '';
const { t } = i18n;

const listCacheUrls = [API.GET_MASTER_COUNTRIES, API.GET_MASTER_COUNTRIES_PUBLIC];
export class SetupAxios {
  public instance: AxiosInstance;
  private _isFetchingToken: boolean = false;

  constructor(contentType = 'application/json') {
    this.instance = axios.create({
      baseURL: API_URL,
      timeout: AXIOS_TIMEOUT,
      headers: {
        'Content-Type': contentType || 'application/json',
        Authorization: 'Bearer ' + token
      }
    });

    this.instance.interceptors.response.use(
      (response) => {
        // Do something with response data
        return response;
      },
      (error: AxiosError) => {
        const data: any = error?.response?.data || null;
        const url = error?.response?.config?.url;

        if (error.response?.status === 401) {
          return this._handleAuthorization(error);
        }
        // Check if the URL matches the specified pattern
        const urlMatchResult = url ? /^\/([^\/]+\/){3}/.exec(url) : null;
        const isMatch = urlMatchResult && LIST_API_EXCEPTIONS.includes(urlMatchResult[0]);
        // Do something with response error
        if (
          !isMatch &&
          (error.code?.includes(ERROR_CODE.FORBIDDEN_ACCESS_DENIED) ||
            error.code?.includes(ERROR_CODE.SECURITY_EXCEPTION) ||
            data?.errorCode?.includes(ERROR_CODE.SECURITY_EXCEPTION))
        ) {
          window.location.href = NOT_EXITS_URL; // Redirect 404 when not permession
          return Promise.reject(error);
        }
        if (!window.navigator.onLine && error.code?.includes(ERROR_CODE.ERR_NETWORK)) {
          // Show message when error network
          try {
            store.dispatch(
              setAlertNotification({
                show: true,
                type: 'error',
                position: 'top',
                message: t(`common:error_network`)
              })
            );
          } catch (err) {}
          return Promise.reject(error);
        }
        if (error.code?.includes(ERROR_CODE.COMMON_EXCEPTION)) {
          // Show message when bad request
          try {
            store.dispatch(
              setAlertNotification({
                show: true,
                type: 'error',
                position: 'top',
                message: t(`common:MSG_089`)
              })
            );
          } catch (err) {}
          return Promise.reject(error);
        }
        if (data?.errorCode?.includes(ERROR_CODE.COMMON_EXCEPTION) || error?.response?.status === 503) {
          // Show message when bad request
          try {
            store.dispatch(
              setAlertNotification({
                show: true,
                type: 'error',
                position: 'top',
                message: t(`common:MSG_089`)
              })
            );
            let handleError = cloneDeep(error);
            delete handleError.response;
            return Promise.reject(handleError);
          } catch (err) {
            return Promise.reject(error);
          }
        }
        if (
          error.response?.status === 400 &&
          data?.fields.some((item: { errorCode: string }) => item.errorCode.includes(ERROR_CODE.NOT_FOUND)) &&
          data.fields.findIndex((f: any) => f.name === 'TemplateId') === -1
        ) {
          try {
            store.dispatch(
              setAlertNotification({
                show: true,
                type: 'error',
                position: 'top',
                message: t(`common:MSG_NOT_FOUND`)
              })
            );
            let handleError = cloneDeep(error);
            return Promise.reject(handleError);
          } catch (err) {
            return Promise.reject(error);
          }
        }
        if (data?.errorCode?.includes(ERROR_CODE.VALIDATION)) {
          const fields = data.fields || [];
          let handleError = cloneDeep(error);
          const isAsynchronousData = fields.length ? fields.map((item: any) => item.errorCode).includes(ERROR_CODE.VERSION_MISMATCH) : false;
          if (isAsynchronousData) {
            const message = `${t('common:MSG_C_015')}`;
            store.dispatch(
              setAlertNotification({
                show: true,
                type: 'error',
                position: 'top',
                message
              })
            );
          }
          return Promise.reject(handleError);
        }
        return Promise.reject(error);
      }
    );
  }

  private async _handleFetchCache(url: string, config: AxiosRequestConfig = {}) {
    const configAPI = await this._getConfig(config);
    if (!listCacheUrls.includes(`/${url}`)) {
      return this.instance
        .get(url, {
          ...config,
          ...configAPI
        })
        .then((response) => response);
    }
    const cacheKey = `cache_${url}`;
    const cached = parseJson(localStorage.getItem(cacheKey) ?? '');
    const cacheTime = 60 * 60 * 1000; // Cache duration: 1 hour
    if (cached && cached?.timestamp + cacheTime > Date.now()) {
      return cached.response;
    }

    // If no valid cache, fetch new data
    try {
      return this.instance
        .get(url, {
          ...config,
          ...configAPI
        })
        .then(
          (
            response // Cache the new data with a timestamp
          ) => {
            localStorage.setItem(cacheKey, JSON.stringify({ response, timestamp: Date.now() }));
            return response;
          }
        );
    } catch (error) {
      console.error('Failed to fetch data:', error);
      throw error;
    }
  }

  private async _handleAuthorization(error: AxiosError) {
    const { config: originAxiosConfigs } = error;
    let resolve: (val: any) => void = () => {};
    let reject: (val: any) => void = () => {};
    const retryOriginalRequest = new Promise((res, rej) => {
      resolve = res;
      reject = rej;
    });

    if (!this._isFetchingToken) {
      this._isFetchingToken = true;
      this._renewToken()
        .then((responses) => {
          if (!responses) {
            dispatchCustomEvent(CUSTOM_HOOK_EVENTS.ACCESS_DENIED);
            reject(error);
          } else {
            const { token, refreshToken } = responses;
            if (originAxiosConfigs) originAxiosConfigs.headers.Authorization = `Bearer ${token}`;
            localStorage.setItem(LOCALSTORAGE.ACCESS_TOKEN, token);
            localStorage.setItem(LOCALSTORAGE.REFRESS_TOKEN, refreshToken);
            resolve(axios(originAxiosConfigs as any));
          }
        })
        .catch(() => {
          dispatchCustomEvent(CUSTOM_HOOK_EVENTS.ACCESS_DENIED);
          reject(error);
        })
        .finally(() => {
          this._isFetchingToken = false;
        });
    }

    return retryOriginalRequest;
  }

  private async _renewToken(): Promise<{ token: string; refreshToken: string } | null> {
    const refreshToken = localStorage.getItem(LOCALSTORAGE.REFRESS_TOKEN);
    const params = {
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
      scope: process.env.REACT_APP_API_SCOPE,
      client_id: process.env.REACT_APP_API_CLIENT_ID
    };
    const res = await this.post(`connect/token`, params, {
      baseURL: API_URL_WITH_PORT,
      headers: {
        Authorization: ``,
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
    if (!res?.data) return null;
    return {
      token: res.data.access_token,
      refreshToken: res.data.refresh_token
    };
  }

  private async _getConfig(config: AxiosRequestConfig = {}) {
    const token = (await localStorage.getItem('access_token')) ?? '';
    const configAPI = config?.headers
      ? {
          ...config.headers,
          Authorization: `Bearer ${token}`
        }
      : {
          headers: {
            Authorization: `Bearer ${token}`
          }
        };
    return configAPI;
  }

  public get = async (url = '', config: AxiosRequestConfig = {}): Promise<AxiosResponse> => {
    return this._handleFetchCache(url, config);
  };

  public post = async (url = '', body = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse> => {
    const configAPI = await this._getConfig(config);

    return this.instance
      .post(url, body, {
        ...config,
        ...configAPI
      })
      .then((response) => response);
  };

  public patch = async (url = '', body = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse> => {
    const configAPI = await this._getConfig(config);

    return this.instance
      .patch(url, body, {
        ...config,
        ...configAPI
      })
      .then((response) => response);
  };

  public put = async (url = '', body = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse> => {
    const configAPI = await this._getConfig(config);

    return this.instance
      .put(url, body, {
        ...config,
        ...configAPI
      })
      .then((response) => response);
  };

  public delete = async (url = '', config: AxiosRequestConfig = {}): Promise<AxiosResponse> => {
    const configAPI = await this._getConfig(config);

    return this.instance
      .delete(url, {
        ...config,
        ...configAPI
      })
      .then((response) => response);
  };
}

export const axiosInstance = new SetupAxios();
