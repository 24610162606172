import classNames from 'classnames';
import React from 'react';

import TableBase, { ITableCustomProps } from '@/components/common/table-base';

import { IQuestion } from '../modals';

interface IQuestionTableProps extends ITableCustomProps<IQuestion> {}

const QuestionTable = (props: IQuestionTableProps) => {
  const { className, ...rest } = props;
  return (
    <div>
      <TableBase
        groupRowRender={(record: any) => {
          return (
            <div className='question-category'>
              <span className='subtitle-18 text-white'>{record?.name}</span>
            </div>
          );
        }}
        groupHeaderClassName='!bg-green hover:!bg-green70 h-[45px]'
        className={classNames('question-detail mt-2', className)}
        rowSelection={null}
        scrollTable={{ maxHeight: 'calc(100vh - 220px)' }}
        {...rest}
      />
    </div>
  );
};

export default QuestionTable;
