import React from 'react';
import { useTranslation } from 'react-i18next';

import InfoIcon from '@assets/icons/Info.svg';

import ApplicantForm from './Applicant';
import AssignmentInfo from './AssignmentInfo';
import TravelType from './TravelType';
import VisaInformation from './VisaInfomation';

const ScenarioAStepTwo = () => {
  const { t } = useTranslation();
  return (
    <div className='scenario-a-step-two'>
      <div className='scenario-a-step-two__form-title'>
        <p className='title-20'>{t('project:scenario_a:applicant_title')}</p>
        <div className='flex items-center mt-[8px] mb-[12px]'>
          <InfoIcon className='block mr-[4px]' width={18} height={18} />
          <span className='body-400'>{t('project:scenario_a:applicant_description')}</span>
        </div>
      </div>
      <div className='form-section'>
        <ApplicantForm parentPath='steps[0].projectApplicant' />
      </div>
      <div className='form-section mt-[12px]'>
        <AssignmentInfo parentPath='steps[0].projectAssignmentInfo' />
      </div>
      <div className='form-section mt-[12px]'>
        <VisaInformation parentPath='steps[0]' />
      </div>
      <div className='form-section mt-[12px]'>
        <TravelType parentPath='steps[0]' />
      </div>
    </div>
  );
};

export default ScenarioAStepTwo;
