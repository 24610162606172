import React from 'react';
import { useTranslation } from 'react-i18next';

import EmptyDataWithIcon from '@/components/EmptyData/EmptyDataWithIcon';

import { ICommentViewProps } from '../View';
import { IComment } from '../models';
import CommentBox from './CommentBox';

interface ICommentListProps {
  data: ICommentViewProps['list'];
  onClickEditBtn: (comment: IComment) => void;
  loading: boolean;
}
const CommentList = ({ data, onClickEditBtn, loading }: ICommentListProps) => {
  const { t } = useTranslation();
  if (loading) return <></>;

  if (!data?.length)
    return (
      <div className='mt-[170px]'>
        <EmptyDataWithIcon
          title={null}
          description={{
            content: 'common:no_data'
          }}
          button={null}
        />
      </div>
    );

  const firstUnreadIndex = data.findIndex((comment) => !comment.readed);

  return (
    <div className='scroll-wrapper'>
      {data.map((item, index) => (
        <>
          {index === firstUnreadIndex && (
            <div id='unRead' className='relative text-link text-center select-none' key='unReadComment'>
              <span className='absolute top-[11px] left-0 border-t border-dashed border-link w-full border-b-0 border-l-0 border-r-0' />
              <span className='body-400 relative bg-white px-[8px] text-12'>{t('widgets:comment_function:comment_unread')}</span>
            </div>
          )}
          <CommentBox key={item.id} comment={item} onClickEditBtn={onClickEditBtn} />
        </>
      ))}
    </div>
  );
};

export default CommentList;
