import { AxiosRequestConfig } from 'axios';

import { axiosInstance } from '../../config/http';
import { AXIOS_TIMEOUT_UPLOAD } from '../constants/AppConstants';
import { CONFIG_HEADER_DOWNLOAD } from '../helpers/fileHelper';

export const uploadSingleFileWithType = async (documentType: string, payload: FormData, config: AxiosRequestConfig) => {
  const token = (await localStorage.getItem('access_token')) ?? '';
  const response = await axiosInstance.post(`/sta/files/up/${documentType}`, payload, {
    ...config,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`
    },
    timeout: AXIOS_TIMEOUT_UPLOAD
  });
  return response;
};

export const downloadSingleFileWithType = async (filepath: string, token: string, config: AxiosRequestConfig) => {
  const response = await axiosInstance.get(`/sta/files/down/${filepath}?token=${token}`, {
    ...config,
    timeout: AXIOS_TIMEOUT_UPLOAD
  });
  return response;
};

export const getTokenDownLoad = async () => {
  const response = await axiosInstance.post(`/sta/files/token`);
  return response;
};

export const downloadMultipleFiles = async (params: {
  token: string;
  documents: {
    documentName: string;
    filePaths: string[];
  }[];
}) => {
  const response = await axiosInstance.post(`sta/files/down-multiple/documents`, params, CONFIG_HEADER_DOWNLOAD);
  return response;
};

export const uploadMultipleFileWithType = async (documentType: string, payload: FormData, config = {}) => {
  const token = (await localStorage.getItem('access_token')) ?? '';
  const response = await axiosInstance.post(`/sta/files/up-multiple/${documentType}`, payload, {
    ...config,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`
    },
    timeout: AXIOS_TIMEOUT_UPLOAD
  });
  return response;
};
