import React from 'react';

import { CheckIcon, CloseIcon } from '@/components/icon-svg/IconSvg';

interface TranslateFlgProps {
  translateFlag: boolean;
}

const TranslateFlag: React.FC<TranslateFlgProps> = ({ translateFlag }) => {
  return <div className='flex items-center justify-center'>{translateFlag ? <CheckIcon className='icon' /> : <CloseIcon className='icon' />} </div>;
};

export default TranslateFlag;
