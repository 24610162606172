import { Space } from 'antd';
import { Key, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { IconDownload } from '@/components/icon-svg/IconSvg';

import Trash from '@/assets/icons/TrashIcon.svg';

import { BaseButton } from '../../base-button/BaseButton';

interface ITableHeader<T> {
  isShowDelete: boolean;
  isShowDownload: boolean;
  selectedRowKeys: Key[];
  selectedRow: T[];
  titleSelectedRowsCustom?: string;
  handleDeleteClick?: (keys: Key[], row?: T[]) => void;
  handleDownloadClick?: (keys: React.Key[], row?: T[]) => void;
  children?: ReactNode;
}

const TableHeader = <T extends {}>({
  isShowDelete,
  isShowDownload,
  selectedRow,
  selectedRowKeys,
  titleSelectedRowsCustom,
  handleDeleteClick,
  handleDownloadClick,
  children
}: ITableHeader<T>) => {
  const [t] = useTranslation();

  return (
    <>
      {selectedRowKeys.length > 0 && (
        <div className='flex items-center wrap-delete-table mb-[16px]'>
          <div className='font-[500] mr-2'>{`${selectedRowKeys.length}${titleSelectedRowsCustom ? titleSelectedRowsCustom : t('common:selected')}`}</div>
          {(isShowDelete || isShowDownload) && (
            <Space size={16} direction='horizontal'>
              {isShowDownload && (
                <BaseButton
                  onClick={() => {
                    handleDownloadClick && handleDownloadClick(selectedRowKeys, selectedRow);
                  }}
                  type='secondary'
                  size='small'
                  icon={<IconDownload className='' />}
                >
                  {t('template_layout:download_button')}
                </BaseButton>
              )}
              {isShowDelete && selectedRowKeys.length > 0 && (
                <BaseButton
                  onClick={() => {
                    handleDeleteClick && handleDeleteClick(selectedRowKeys, selectedRow);
                  }}
                  icon={<Trash />}
                  className='delete-btn !min-w-[0] !rounded'
                  type='outline-red'
                  size='small'
                >
                  {t('template_layout:delete_button')}
                </BaseButton>
              )}
            </Space>
          )}
        </div>
      )}
      {selectedRowKeys.length === 0 && children}
    </>
  );
};

export default TableHeader;
