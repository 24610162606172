/* eslint-disable @typescript-eslint/no-unused-vars */
import { Checkbox, CheckboxProps } from 'antd';
import { memo } from 'react';
import { useController } from 'react-hook-form';

export interface IFormCheckboxProps extends CheckboxProps {
  name: string;
  isBaselineError?: boolean;
  handleChange?: (val: any) => void;
}
export const FormCheckbox = memo(({ name, isBaselineError, defaultChecked, handleChange, ...rest }: IFormCheckboxProps) => {
  // state
  const { field } = useController({
    name: name as string,
    defaultValue: defaultChecked
  });
  // render
  return (
    <div>
      {isBaselineError && <div className='h-[26px]' />}
      <Checkbox
        ref={field.ref}
        checked={field.value}
        onChange={(e) => {
          field.onChange(e.target.checked);
          handleChange && handleChange(e.target.checked);
        }}
        {...rest}
      />
      {isBaselineError && <div className='h-[26px]' />}
    </div>
  );
});
