import BaseFilterResult from '../../filter-result/BaseFilterResult';

type Result = {
  current: number | undefined;
  pageSize: number | undefined;
  total: number | undefined;
};

interface ITableFooter {
  paginationInfo: Result;
  isFilter: boolean;
}

const TableFooter = ({ paginationInfo, isFilter = false }: ITableFooter) => {
  return (
    <>
      <BaseFilterResult
        isFilter={isFilter}
        resultSearch={{
          current: paginationInfo.current,
          pageSize: paginationInfo.pageSize,
          total: paginationInfo?.total
        }}
      />
    </>
  );
};

export default TableFooter;
