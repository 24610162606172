import Quill from 'quill';
import { Op } from 'quill/core';

const Inline: any = Quill.import('blots/inline');

export const MENTION_BLOT_DATASET = {
  USER_ID: 'data-user-id',
  TYPE: 'quill-type'
};
export const MENTION_BLOT_TYPE = 'mention';
export const MENTION_BLOT_STYLES = {
  COLOR: '#076CE3',
  FONT_WEIGHT: 'bold',
  FONT_SIZE: '100%'
};
export default class MentionBlot extends Inline {
  blotName = 'MentionBlot';
  tagName = 'span';
  static create(attributes: { id: string; type: string }) {
    const node = super.create();
    if (!attributes?.id || attributes?.id === 'null' || attributes?.type !== MENTION_BLOT_TYPE) return node;
    node.setAttribute(MENTION_BLOT_DATASET.USER_ID, attributes.id);
    node.setAttribute(MENTION_BLOT_DATASET.TYPE, MENTION_BLOT_TYPE);
    node.style.color = MENTION_BLOT_STYLES.COLOR;
    node.style.fontWeight = MENTION_BLOT_STYLES.FONT_WEIGHT;
    node.style.fontSize = MENTION_BLOT_STYLES.FONT_SIZE;

    // Make the content non-editable
    node.setAttribute('contenteditable', 'false');
    node.className = 'select-none';
    return node;
  }

  static formats(node: HTMLElement) {
    return {
      id: node.getAttribute(MENTION_BLOT_DATASET.USER_ID),
      type: node.getAttribute(MENTION_BLOT_DATASET.TYPE)
    };
  }

  static value(node: HTMLElement) {
    const userId = node.getAttribute(MENTION_BLOT_DATASET.USER_ID);
    const type = node.getAttribute(MENTION_BLOT_DATASET.TYPE);
    if (!userId || type !== MENTION_BLOT_TYPE) return null;
    return {
      id: userId,
      type
    };
  }

  static getAttributeFromOp(op: Op) {
    const { MentionBlot } = op.attributes ?? {};
    if (!MentionBlot) return null;
    const { id, type } = (MentionBlot as MentionBlot) ?? {};
    return { id, type };
  }
}

// required init for custom blots
MentionBlot.blotName = 'MentionBlot';
MentionBlot.tagName = 'span';
