import { DataViewer } from '@/utils/helpers/common';

import '../index.scss';

interface FileUploadDetailProps {
  description?: string;
}

const FileUploadDetail = ({ description }: FileUploadDetailProps) => {
  return (
    <>
      <div className='header-view-task-detail-project flex flex-col gap-[12px] mt-[8px]'>
        <div className='p-[16px] border-collapse'>
          <div
            className='w-full text-[14px] leading-[22px]'
            dangerouslySetInnerHTML={{ __html: DataViewer.displayAsSanitizeHTML((description as string) || '') }}
          ></div>
        </div>
      </div>
    </>
  );
};

export default FileUploadDetail;
