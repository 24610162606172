import classNames from 'classnames';
import { Delta } from 'quill/core';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import AppTooltip from '@/components/app-tooltip/AppTooltip';
import { BaseButton } from '@/components/base-button/BaseButton';
import FileAttachment, { FILE_ATTACHMENT_STATUS } from '@/components/form-attachment/FileAttachment';
import BasicFormEditor from '@/components/form-box-editor';
import QuillToolbarModuleSelection, { IModuleOptionChild, IModuleToolbarOption } from '@/components/form-box-editor/components/SelectBox';
import { CUSTOM_MODULES } from '@/components/form-box-editor/modules';
import { STATUS_EMBED_IMAGE_BLOT } from '@/components/form-box-editor/modules/EmbedImageBlot';

import { DataViewer } from '@/utils/helpers/common';

import ArrowSquareInIcon from '@assets/icons/ArrowSquareIn.svg';
import CloseIcon from '@assets/icons/CloseIcon.svg';

import { ICommentWidgetContainerProps } from './Container';
import CommentList from './components/CommentList';
import SendButton, { SEND_BUTTON_MODE, SEND_BUTTON_STATUS } from './components/SendButton';
import { COMMENT_MODE, MAXIMUM_FILE, WIDGET_TYPE, WidgetType } from './constants';
import { FileTypeComment, IComment } from './models';

import './styles/View.scss';

const UploadFileIcon = ({ color = '#12212E', onClick }: { color?: string; onClick?: React.MouseEventHandler<HTMLButtonElement> }) => {
  return (
    <button onClick={onClick}>
      <svg width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M9.31622 4.80452L3.45919 10.76C3.26529 10.974 3.1611 11.2544 3.16821 11.5431C3.17532 11.8318 3.29318 12.1067 3.49738 12.3109C3.70158 12.5151 3.97648 12.6329 4.26518 12.64C4.55388 12.6471 4.83425 12.543 5.04825 12.349L12.0303 5.26858C12.4181 4.84057 12.6265 4.27982 12.6122 3.70243C12.598 3.12504 12.3623 2.57522 11.9539 2.16682C11.5455 1.75842 10.9957 1.52271 10.4183 1.50849C9.84092 1.49428 9.28017 1.70265 8.85216 2.09045L1.87013 9.17092C1.23796 9.80309 0.882813 10.6605 0.882812 11.5545C0.882813 12.4485 1.23796 13.3059 1.87013 13.9381C2.5023 14.5703 3.3597 14.9254 4.25372 14.9254C5.14774 14.9254 6.00515 14.5703 6.63732 13.9381L12.41 8.17952'
          stroke={color}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </button>
  );
};
export interface ICommentViewProps extends ICommentWidgetContainerProps {
  refEditor: any;
  list: IComment[];
  onChangeFile: (files: File[]) => void;
  onSubmit: () => void;
  onDownloadFile: (file: FileTypeComment) => void;
  onDeleteFile: (id: string) => void;
  onFocusEditor?: () => void;
  handleCloseEditMode?: () => void;
  onClickEditBtn: (comment: IComment) => void;
  type: WidgetType;
  options?: IModuleToolbarOption[];
  openCommentChatWindow?: () => void;
  showTitle?: boolean;
  loading: boolean;
  loadingAttachments: boolean;
}

const CommentView = (props: ICommentViewProps) => {
  const {
    refEditor,
    list,
    type,
    className,
    options,
    showTitle = true,
    loading,
    loadingAttachments,
    onSubmit,
    onChangeFile,
    onDownloadFile,
    onDeleteFile,
    handleCloseEditMode,
    onFocusEditor,
    onClickEditBtn,
    openCommentChatWindow
  } = props;
  const form = useFormContext();
  const [chat, mode, files] = form.watch(['chat', 'mode', 'files']);
  const { isDirty, isSubmitting } = form.formState;
  const { t } = useTranslation();
  const [loadingEmbedImage, setLoadingEmbedImage] = useState(false);
  const [embedImage, setEmbedImage] = useState(false);

  useEffect(() => {
    const content = refEditor.current.getContents();
    const embedImageUploading = content.ops.some(
      (op: any) =>
        op?.insert?.[CUSTOM_MODULES.EMBED_IMAGE_BLOT]?.status === STATUS_EMBED_IMAGE_BLOT.UPLOADING ||
        op?.insert?.[CUSTOM_MODULES.EMBED_IMAGE_BLOT]?.status === STATUS_EMBED_IMAGE_BLOT.ERROR
    );
    setLoadingEmbedImage(embedImageUploading);
    const embedImageUploaded = content.ops.some(
      (op: any) => op?.insert?.[CUSTOM_MODULES.EMBED_IMAGE_BLOT]?.status === STATUS_EMBED_IMAGE_BLOT.UPLOADED
    );
    setEmbedImage(embedImageUploaded);
  }, [chat]);

  const buttonStatus = () => {
    const currentFiles = files.filter((el: FileTypeComment) => !el.deleted);
    const existFileError = files.filter((el: FileTypeComment) => el.errorMsg?.length && !el.deleted);
    if (
      !isDirty ||
      isSubmitting ||
      (DataViewer.isEmptyAfterTrim(chat) <= 0 && !currentFiles.length && !embedImage) ||
      !!existFileError.length ||
      currentFiles.length > MAXIMUM_FILE ||
      loadingEmbedImage ||
      loadingAttachments
    )
      return SEND_BUTTON_STATUS.DISABLED;
    return chat || currentFiles || embedImage ? SEND_BUTTON_STATUS.ACTIVE : SEND_BUTTON_STATUS.DISABLED;
  };

  const getModules = () => {
    if (!options || type === WIDGET_TYPE.INTERNAL) {
      return {
        mention: {
          getSuggestions: () => {}
        },
        allowEmbedImage: true
      };
    }
    return {
      fileAttachment: {
        buttonIcon: <UploadFileIcon />,
        onChangeFile
      },
      mention: {
        getSuggestions: () => {}
      },
      allowEmbedImage: true
    };
  };

  const handleSubmit = () => {
    if (buttonStatus() === SEND_BUTTON_STATUS.DISABLED) return;
    onSubmit();
  };

  const onSelectPhrase = (item: IModuleOptionChild) => {
    if (!item.content || !refEditor?.current) return;
    const cursorPosition = refEditor.current.selection.savedRange?.index;
    const clipboardDelta = refEditor.current.clipboard.convert({ html: item.content });
    const delta = new Delta().retain(cursorPosition).concat(clipboardDelta);
    refEditor.current.updateContents(delta, 'user');
  };

  const stylesTooltip = {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '160%'
  };

  return (
    <div className={classNames('comment-widget', className)}>
      {showTitle && (
        <div className='flex items-center justify-between comment-widget__title'>
          <p className='text-18'>{t('widgets:comment_function:title')}</p>
          <AppTooltip title={t('widgets:comment_function:open_new_window')} styles={stylesTooltip}>
            <BaseButton onClick={openCommentChatWindow} type='text' className='!min-w-[20px] !p-0 h-[25px]'>
              <ArrowSquareInIcon />
            </BaseButton>
          </AppTooltip>
        </div>
      )}
      <div id='listComment' className={classNames('comment-widget__list', mode === COMMENT_MODE.EDIT && '!pb-[46px]')}>
        <CommentList data={list} loading={loading} onClickEditBtn={onClickEditBtn} />
      </div>
      <div className='comment-widget__combo-box'>
        <div className={classNames('comment-widget__editing-toolbar', mode === COMMENT_MODE.EDIT ? 'visible' : 'invisible')}>
          <div className='pl-[12px]'>{t('widgets:comment_function:edit_message')}</div>
          <CloseIcon width={18} height={18} className='cursor-pointer active:scale-95 mr-[12px]' onClick={handleCloseEditMode} />
        </div>
        <BasicFormEditor
          name='chat'
          ref={refEditor}
          defaultValue={chat}
          placeholder={t('widgets:comment_function:editor_place_holder') ?? ''}
          modules={getModules()}
          onFocus={onFocusEditor}
          extraElement={type === WIDGET_TYPE.INTERNAL ? <QuillToolbarModuleSelection options={options} onSelect={onSelectPhrase} /> : undefined}
        />
        <div className='comment-widget__attachment'>
          {files.map(
            (file: FileTypeComment) =>
              !file.deleted && (
                <FileAttachment
                  key={`file-${file.name}-${file.id}`}
                  label={file.name ?? ''}
                  status={file?.status ?? FILE_ATTACHMENT_STATUS.EDIT}
                  onDelete={() => onDeleteFile(file.id ?? '')}
                  onDownload={() => onDownloadFile(file)}
                  msgErr={file?.errorMsg ?? ''}
                />
              )
          )}
        </div>
        <SendButton
          className='comment-widget__send-button'
          onClick={handleSubmit}
          status={buttonStatus()}
          mode={mode === 'default' ? SEND_BUTTON_MODE.SEND : SEND_BUTTON_MODE.EDIT}
        />
      </div>
    </div>
  );
};

export default CommentView;
