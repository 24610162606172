import { SortOrder } from 'antd/es/table/interface';
import classNames from 'classnames';
import React from 'react';

import IconDown from '@assets/icons/table/sorter-icon-down.svg';
import IconUp from '@assets/icons/table/sorter-icon-up.svg';

const Sorter = ({ sortOrder }: { sortOrder: SortOrder }) => {
  return (
    <div className='flex flex-col'>
      <IconUp
        className={classNames('mb-[2px] cursor-pointer group-hover:opacity-100', sortOrder === 'ascend' ? 'fill-green opacity-100' : 'opacity-50')}
      />
      <IconDown className={classNames('cursor-pointer group-hover:opacity-100', sortOrder === 'descend' ? 'fill-green opacity-100' : 'opacity-50')} />
    </div>
  );
};

export default Sorter;
