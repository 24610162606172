import { yupResolver } from '@hookform/resolvers/yup';
import { Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import AppSubLabel from '@/components/app-label/AppSubLabel';
import AppTooltip from '@/components/app-tooltip/AppTooltip';
import { BaseButton } from '@/components/base-button/BaseButton';
import { TYPE_ICON, showBasePopup } from '@/components/base-popup/BasePopup';
import { ConfirmCancelEditing } from '@/components/confirm-popup';
import { EditorWithCounter } from '@/components/form-box-editor';

import { IAlertNotificationState, setAlertNotification, setLoadingPage } from '@/redux/globalReducer';

import { ERROR_CODE, NOTE_FILED_MAX_LENGTH, TYPE } from '@/utils/constants/AppConstants';
import { HTTP_STATUS_CODE } from '@/utils/constants/Http';
import { ACCOUNT_MASTER_LIST_URL, EDIT_PROJECT_URL, NOT_EXITS_URL, STAKEHOLDER_VIEW_URL } from '@/utils/constants/RouteContants';
import { handleCommonError } from '@/utils/helpers/common';
import { Ii18n } from '@/utils/interfaces/i18n';
import { getAccountMasterDetail, putApproveRejectAccountMaster } from '@/utils/services/AccountMasterApiService';
import { getProjectCheck } from '@/utils/services/ProjectApiService';

import Approve from '@/assets/icons/Check.svg';
import Reject from '@/assets/icons/Reject.svg';
import Close from '@/assets/icons/close.svg';
import Warning from '@/assets/icons/template/icon-warning.svg';

import BaseFormAccountMater from './BaseFormAccountMater';
import { FormApproveReject, FormUpdateAccountMaster } from './FormValidation';
import { ACCOUNT_MASTER_DEFAULT_VALUE, ACTION } from './contants';
import { IFormMethodUpdateAccountMaster } from './models';

import './Add.scss';

const ApproveReject = () => {
  const [actionAccount, setActionAccount] = useState<string | undefined>(undefined);
  const { t }: Ii18n = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formApproveMethod = useForm<IFormMethodUpdateAccountMaster>({
    mode: 'all',
    resolver: yupResolver(FormUpdateAccountMaster),
    defaultValues: () => accountMasterDetailData()
  });
  const formRejectMethod = useForm<IFormMethodUpdateAccountMaster>({
    mode: 'all',
    resolver: yupResolver(FormApproveReject),
    defaultValues: () => accountMasterDetailData()
  });

  const [isEdit, setIsEdit] = useState(false);

  const waitingCreate = formRejectMethod.watch('waitingCreate');

  const accountMasterDetailData = async () => {
    try {
      dispatch(setLoadingPage(true));
      const data = await getAccountMasterDetail(id as string);
      dispatch(setLoadingPage(false));
      return { ...(data || ACCOUNT_MASTER_DEFAULT_VALUE), status: true };
    } catch (error: any) {
      switch (error?.code) {
        case ERROR_CODE.ERR_BAD_REQUEST:
          navigate(NOT_EXITS_URL);
          break;
        default:
          break;
      }
      return ACCOUNT_MASTER_DEFAULT_VALUE;
    }
  };

  const handleApproveRejectAccount = async () => {
    actionAccount === ACTION.APPROVE
      ? formApproveMethod.handleSubmit(onSubmitApproveReject, onError)()
      : formRejectMethod.handleSubmit(onSubmitApproveReject, onError)();
  };
  const onSubmitApproveReject = async (val: any) => {
    try {
      const idProject = formRejectMethod.getValues('projectId');
      const projectIsDraft = idProject ? (await getProjectCheck(idProject)).data?.isDraft : false;
      const res = await putApproveRejectAccountMaster(getPayloadRequest(val, projectIsDraft, idProject));
      if (res.status === HTTP_STATUS_CODE.SUCCESS) {
        dispatch(
          setAlertNotification({
            show: true,
            type: TYPE.SUCCESS,
            message: t('common:MSG_C_003', { item: t('button:keep') })
          })
        );
        setIsEdit(false);
        setTimeout(() => {
          navigate(ACCOUNT_MASTER_LIST_URL);
        }, 500);
      }
    } catch (error) {
      const callback = (mess: IAlertNotificationState) => dispatch(setAlertNotification(mess));
      handleAccountMasterException(error, callback);
    }
  };
  const handleAccountMasterException = (err: any, callback: (mess: IAlertNotificationState) => void) => {
    let errorMessage = t('common:MSG_C_014');
    handleCommonError(err, errorMessage, callback);
  };
  const onError = async (err: any) => {};

  const getPayloadRequest = (formData: any, projectDraft: boolean, idProject?: string) => {
    return {
      id: formRejectMethod.getValues('id'),
      action: actionAccount,
      reasonReject: formData.reasonReject,
      version: formRejectMethod.getValues('version'),
      projectCode: formRejectMethod.getValues('projectCode'),
      projectId: idProject,
      urlRedirect: window.location.origin + (projectDraft ? EDIT_PROJECT_URL(String(idProject)) : STAKEHOLDER_VIEW_URL(String(idProject))),
      name: formApproveMethod.getValues('name'),
      roleId: formApproveMethod.getValues('roleId'),
      status: formApproveMethod.getValues('status')
    };
  };

  const handleIsUiReject = async (val?: string) => {
    if (val === ACTION.REJECT) {
      formRejectMethod.setValue('reasonReject', '');
    }
    return setActionAccount(val);
  };

  const handleCancel = async () => {
    if (isEdit) {
      const show = await showBasePopup({
        type: TYPE_ICON.CONFIRM_CANCEL_EDIT
      });
      show === TYPE.CONFIRM && handleIsUiReject();
    } else {
      handleIsUiReject();
    }
    setIsEdit(false);
    formRejectMethod.reset();
  };

  useEffect(() => {
    setIsEdit(formRejectMethod.formState.isDirty);
  }, [formRejectMethod.formState.isDirty]);

  return (
    <div id='account-master-management'>
      <div className='account-master-container h-full'>
        <FormProvider {...formRejectMethod}>
          <div className='header flex justify-between'>
            <div className='title-24 whitespace-pre-line font-bold text-24 leading-7'>{t('account_master:edit:title')}</div>
            <Space size={16}>
              <div className='min-w-[120px]'>
                <BaseButton disabled={!actionAccount} block size='medium' type='tertiary' onClick={handleCancel}>
                  {t('button:cancel')}
                </BaseButton>
              </div>
              <div className='min-w-[120px]'>
                <BaseButton disabled={!actionAccount} block size='medium' onClick={handleApproveRejectAccount}>
                  <span> {t('button:keep')} </span>
                </BaseButton>
              </div>
            </Space>
          </div>
          <div className='account-master-form account-master-tabs-content px-5 py-4 rounded-[10px] border-[#DBDBE0] gap-1'>
            {actionAccount === ACTION.APPROVE ? (
              <FormProvider {...formApproveMethod}>
                <BaseFormAccountMater formMethod={formApproveMethod} isEdit={true} />
              </FormProvider>
            ) : (
              <>
                <AppSubLabel label={t('account_master:edit:username:label')} title={formRejectMethod.watch('name')} />
                <div className='d-flex mb-2'>
                  <div className='mr-12'>
                    {waitingCreate ? (
                      <AppSubLabel
                        title={formRejectMethod.getValues('email')}
                        label={t('account_master:edit:email:label')}
                        subLabel={!actionAccount ? t('account_master:list:pending') : t('stakeholder:label:remanded')}
                        icon={!actionAccount ? <Warning /> : <Close className='w-[20px] ver-align-bottom' />}
                        classSubLabel={!actionAccount ? 'waiting-approve' : 'rejected'}
                        maxWidthLabel={'max-w-[calc(100vw-670px)]'}
                      />
                    ) : (
                      <AppSubLabel
                        label={t('account_master:edit:email:label')}
                        subLabel={t('account_master:list:remanded')}
                        title={formRejectMethod.getValues('email')}
                        icon={<Close className='w-[20px]' />}
                        classSubLabel={'rejected'}
                        maxWidthLabel={'max-w-[calc(100vw-670px)]'}
                      />
                    )}
                  </div>
                  {!actionAccount && waitingCreate && (
                    <>
                      <AppTooltip
                        className='truncate text-[#12212E] font-bold ant-dropdown-trigger'
                        title={t('account_master:list:admit')}
                        onClick={() => handleIsUiReject(ACTION.APPROVE)}
                      >
                        <Approve className='w-[30px] h-[30px]' />
                      </AppTooltip>
                      <AppTooltip
                        className='ml-3 truncate text-[#12212E] font-bold ant-dropdown-trigger'
                        title={t('account_master:list:remand')}
                        onClick={() => handleIsUiReject(ACTION.REJECT)}
                      >
                        <Reject className='w-[30px] h-[30px]' />
                      </AppTooltip>
                    </>
                  )}
                </div>
                {actionAccount === ACTION.REJECT && (
                  <div className='mb-2'>
                    <EditorWithCounter
                      name='reasonReject'
                      limit={NOTE_FILED_MAX_LENGTH}
                      toolbar={null}
                      label={t('account_master:edit:reason_remand:label') ?? ''}
                      required={true}
                      className={'ml-[185px] mt-1'}
                      styleCounterLabel={'text-gray-3'}
                      placeholder={t('account_master:edit:reason_remand:placeholder') ?? ''}
                      editorWrapperProps={{ className: '!h-[70px]' }}
                    />
                  </div>
                )}
                <AppSubLabel label={t('account_master:edit:company_name:label')} title={formRejectMethod.watch('organizationName')} />
                <AppSubLabel label={t('account_master:edit:authority:label')} title={formRejectMethod.watch('roleName')} />
              </>
            )}
          </div>
        </FormProvider>
      </div>
      <ConfirmCancelEditing condition={isEdit} />
    </div>
  );
};

export default ApproveReject;
