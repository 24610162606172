import { IFilterForm } from '../models';

export const DEFAULT_FILTER_FORM: IFilterForm = {
  searchValue: '',
  primary: {
    updatedDateFrom: '',
    updatedDateTo: ''
  }
};

export const MOT_COMPANY_ID = process.env.REACT_APP_COMPANY_MOT;
