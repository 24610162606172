import { SCENARIO_TYPES } from '.';
import { FormObserver } from '../models/projectExternalProxy';

export const STEP_CRUMB_STATUS = {
  COMPLETED: 'completed',
  INPROGRESS: 'inprogress',
  TODO: 'todo',
  ERROR: 'error'
} as const;
export type STEP_CRUMB_STATUS = (typeof STEP_CRUMB_STATUS)[keyof typeof STEP_CRUMB_STATUS];

export const DEFAULT_FORM_OBSERVER: FormObserver<'empty'> = {
  scenarioType: SCENARIO_TYPES.EMPTY,
  steps: [],
  selectedStep: 1,
  stepStatus: [
    {
      id: 1,
      value: STEP_CRUMB_STATUS.COMPLETED
    }
  ]
};
