import { Space, SpaceProps } from 'antd';
import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import './WrapLayout.scss';

export type LabelProps = {
  title: React.ReactNode;
  total?: number;
  questions?: number;
  extra?: React.ReactNode;
  className?: string;
};

const WrapLayout = ({ className, children, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={`announcement-layout-wrapper ${className}`} {...props}>
    {children}
  </div>
);
WrapLayout.displayName = 'WrapLayout';

const WrapLayoutHeader = ({ children, className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={classNames('announcement-layout-header header sticky flex justify-between items-center flex-wrap', className)} {...props}>
    {children}
  </div>
);
WrapLayoutHeader.displayName = 'WrapLayoutHeader';

const WrapHeaderAction = ({ children, className, ...props }: SpaceProps) => (
  <Space size={16} className={classNames('announcement-header-action header sticky flex justify-between', className)} {...props}>
    {children}
  </Space>
);
WrapHeaderAction.displayName = 'WrapHeaderAction';

const WrapHeaderTitle = ({ children, className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={classNames('announcement-header-title title-24 whitespace-pre-line', className)} {...props}>
    {children}
  </div>
);
WrapHeaderTitle.displayName = 'WrapHeaderTitle';

const WrapLayoutContent = ({ children, className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={classNames('announcement-layout-content px-5 py-4 rounded-[10px] border-[#DBDBE0] gap-[12px]', className)} {...props}>
    {children}
  </div>
);
WrapLayoutContent.displayName = 'WrapLayoutContent';

const WrapLayoutFooter = ({ children, className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={classNames('announcement-layout-footer', className)} {...props}>
    {children}
  </div>
);
WrapLayoutFooter.displayName = 'WrapLayoutFooter';

const TagLabel = ({ value, className }: { value: React.ReactNode; className?: string }) =>
  value ? <span className={classNames('py-[2px] px-2 rounded tag-label ', className)}>{value}</span> : null;
TagLabel.displayName = 'TagLabel';

const WrapLabelWithContent = ({ title, total, questions, extra, children, className }: LabelProps & PropsWithChildren) => {
  const { t } = useTranslation();

  const renderTotalInfo = () => {
    if (total === undefined) return null;

    return (
      <span className='ml-[8px] pr-[8px] pl-[8px] bg-[#DBDBE0] rounded font-bold text-[14px]'>
        {questions === total ? t('announcement:confirmed') : `${questions}/${total}`}
      </span>
    );
  };
  return (
    <>
      <div className={classNames('flex flex-row text-[14px] py-1', className)}>
        <span className='text-[14px] font-medium'>{title}</span>
        {extra || renderTotalInfo()}
      </div>
      {children}
    </>
  );
};
WrapLabelWithContent.displayName = 'WrapLabelWithContent';

export { WrapLayout, WrapLayoutContent, WrapHeaderTitle, WrapLayoutHeader, WrapHeaderAction, WrapLayoutFooter, TagLabel, WrapLabelWithContent };
