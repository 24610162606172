import dayjs from 'dayjs';

import { IFilterProjectForm } from '../../pages/project-management/project-list/ProjectList';

interface StringMap {
  [key: string]: string;
}
const _filterOperationResolver = (key: string): string => {
  let operation: string;
  switch (key) {
    case 'codeOrigin':
      operation = 'contains';
      break;
    case 'statusId':
    case 'picId':
    case 'categoryId':
    case 'travelTypeId':
    case 'createdDate':
    case 'countryId':
      operation = 'eq';
      break;
    case 'departureDateFrom':
      operation = 'gte';
      break;
    case 'departureDateTo':
      operation = 'lte';
      break;
    default:
      operation = 'sw';
      break;
  }

  return operation;
};

const _filterFieldTitleResolver = (key: string): string => {
  let fieldTitle: string = key;
  switch (key) {
    case 'departureDateFrom':
    case 'departureDateTo':
      fieldTitle = 'departureDate';
      break;
    default:
      break;
  }
  return fieldTitle;
};

const _filterQueryValueResolver = (key: string, values: IFilterProjectForm): any => {
  // @ts-ignore
  let queryValue: string = values[key];
  switch (key) {
    case 'codeOrigin':
      queryValue = values[key]?.toLocaleLowerCase().replace('pj', '') || '';
      break;
    case 'departureDate':
    case 'departureDateFrom':
    case 'departureDateTo':
      queryValue = dayjs(values[key]).format('YYYY-MM-DDTHH:mm:ss:SSS');
      break;
    default:
      break;
  }

  return queryValue;
};

const _filterQueryTypeResolver = (key: string): string => {
  let type: string = 'text';
  switch (key) {
    case 'createdDate':
    case 'departureDateFrom':
    case 'departureDateTo':
      type = 'date';
      break;
    default:
      break;
  }
  return type;
};
const convertDataToOptions = (object: StringMap, keyMap: StringMap) => {
  const convertedData: any = {};
  for (const key in keyMap) {
    if (keyMap[key]) {
      convertedData[keyMap[key]] = object[key];
    } else {
      convertedData[key] = object[key];
    }
  }
  return convertedData;
};
export { _filterOperationResolver, _filterFieldTitleResolver, _filterQueryValueResolver, _filterQueryTypeResolver, convertDataToOptions };
