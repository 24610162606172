export const WIDGET_TYPE = {
  INTERNAL: 'internal',
  EXTERNAL: 'external',
  UNKNOWN: 'unknown'
} as const;
export type WidgetType = (typeof WIDGET_TYPE)[keyof typeof WIDGET_TYPE];

export const COMMENT_MODE = {
  DEFAULT: 'default',
  EDIT: 'edit'
} as const;
export type CommentMode = (typeof COMMENT_MODE)[keyof typeof COMMENT_MODE];
export const MAXIMUM_FILE = 10;
export const MAXIMUM_FILE_UPLOAD = 10;
export const MAXIMUM_SIZE_UPLOAD = 30;
export const MAXIMUM_SIZE_UPLOAD_TO_BYTE = MAXIMUM_SIZE_UPLOAD * 1024 * 1024;
export const MAXIMUM_FILE_UPLOAD_TO_BYTE = MAXIMUM_FILE_UPLOAD * 1024 * 1024;
export const READED = 'readed';
