import { IFormFilter } from '../models';

export const DEFAULT_FILTER_FORM: IFormFilter = {
  primary: {
    countryId: null,
    formId: null,
    categoryId: null,
    patternId: null,
    updatedDateFrom: null,
    updatedDateTo: null
  }
};
