import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import { useEffect, useState } from 'react';

const URL = process.env.REACT_APP_HUB_ADDRESS ?? '/hub/notification'; // or whatever your backend port is

const useSignalr = () => {
  const [connection, setConnection] = useState<HubConnection>();
  const [isConnected, setIsConnected] = useState<boolean>(false);

  const createHub = () => {
    const newHub = new HubConnectionBuilder().withUrl(URL).configureLogging(LogLevel.Information).withAutomaticReconnect().build();

    newHub.onclose(async () => {
      setIsConnected(false);
    });

    newHub.onreconnecting = (error: any) => {
      setIsConnected(false);
    };

    newHub.onreconnected(() => {
      // temporary solution to trigger all useEffect when connection is disconnected then reconnect
      setIsConnected(false);
      setTimeout(() => {
        setIsConnected(true);
      }, 1000);
    });

    return newHub;
  };

  const connect = async () => {
    if (!connection || connection.state !== HubConnectionState.Disconnected) return;
    await connection
      .start()
      .then(() => {
        setIsConnected(true);
      })
      .catch((_error: any) => {
        setIsConnected(false);
        // withAutomaticReconnect won't configure the HubConnection to retry initial start failures, so start failures need to be handled manually
        setTimeout(connect, 10000);
      });
  };

  const disconnect = async () => {
    if (!connection || connection.state === HubConnectionState.Disconnected) return;
    await connection.stop().catch((error: any) => {});
    setIsConnected(false);
  };

  const registerMessage = (registerMethod: string, data: string) => {
    if (!connection || connection.state !== HubConnectionState.Connected) return;
    connection.send(registerMethod, data);
    connection.invoke(registerMethod, data);
  };

  const unRegisterMessage = (unRegisterMethod: string, data: string) => {
    if (!connection || connection.state !== HubConnectionState.Connected) return;
    connection.send(unRegisterMethod, data);
    connection.invoke(unRegisterMethod, data);
  };

  const registerAndReceiveMessage = (
    registerMethod: string,
    registerData: string | string[],
    receiveMethod: string,
    callback: (response: any) => any
  ) => {
    if (!connection || connection.state !== HubConnectionState.Connected) return;

    connection.send(registerMethod, registerData);
    connection.invoke(registerMethod, registerData);

    connection.on(receiveMethod, (message: any) => {
      callback(message);
    });
  };

  const unRegisterAndReceiveMessage = (unRegisterMethod: string, unRegisterData: string | string[], receiveMethod: string) => {
    if (!connection || connection.state !== HubConnectionState.Connected) return;
    connection.send(unRegisterMethod, unRegisterData);
    connection.invoke(unRegisterMethod, unRegisterData);

    connection.off(receiveMethod);
  };

  const onReceiveMessageMultipleActions = (
    registerMethod: string,
    registerData: string[],
    receiveMethod: string,
    callback: (response: any) => any
  ) => {
    if (registerMethod && registerData.length && connection) {
      registerData.forEach((value: any) => {
        setTimeout(() => {
          connection.send(registerMethod, value);
          connection.invoke(registerMethod, value);
        }, 100);
      });
    }

    if (receiveMethod && connection) {
      connection.on(receiveMethod, (message: any, body: any) => {
        callback(message);
      });
    }
  };

  useEffect(() => {
    setConnection(createHub());
  }, []);

  return {
    connection,
    isConnected,
    connect,
    disconnect,
    registerMessage,
    unRegisterMessage,
    registerAndReceiveMessage,
    unRegisterAndReceiveMessage,
    onReceiveMessageMultipleActions
  };
};

export default useSignalr;
