import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseButton, Type } from '@/components/base-button/BaseButton';
import BaseModal from '@/components/base-modal/BaseModal';

import { BLOCKER_STATE, IPendingNavigate, usePendingNavigate } from '@/hooks/usePendingNavigate';

export interface IConfirmCancelEditing {
  show?: boolean;
  labels?: {
    header?: string;
    cancel?: string;
    accept?: string;
    content?: string;
  };
  condition: IPendingNavigate['condition'];
  acceptBtn?: { type: Type };
  cancelBtn?: { type: Type };
  onNavigate?: () => void;
  onBeforeUnload?: () => void;
  onAccept?: () => void;
}

// this function can be used for confirm before go to other page when u editing a form
// used hook usePendingNavigate
const ConfirmCancelEditing = ({
  onNavigate,
  labels,
  condition,
  onAccept,
  acceptBtn = { type: 'danger' },
  cancelBtn = { type: 'tertiary' }
}: IConfirmCancelEditing) => {
  const { t } = useTranslation();
  const { blocker } = usePendingNavigate({ condition });
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    if (blocker.state === BLOCKER_STATE.BLOCKED) {
      setShow(true);
    } else if (blocker.state === BLOCKER_STATE.UN_BLOCKED) {
      onNavigate && onNavigate();
      setShow(false);
    }
  }, [blocker.state]);

  const {
    header: headerLabel = t('dialog:cancel_editing:header'),
    cancel: cancelLabel = t('dialog:cancel_editing:cancel'),
    accept: acceptLabel = t('dialog:cancel_editing:accept'),
    content = t('dialog:cancel_editing:content')
  } = labels ?? {};

  const handleAccept = async () => {
    if (!blocker.proceed) return;
    onAccept && (await onAccept());
    onNavigate && onNavigate();
    blocker.proceed();
  };

  const handleClose = () => {
    if (!blocker.reset) return;
    blocker.reset();
  };

  const handleCancel = () => {
    if (!blocker.reset) return;
    blocker.reset();
  };

  return (
    <BaseModal
      onCancel={handleClose}
      className='pt-[30px]'
      maskClosable={false}
      openModal={show}
      contentElement={
        <div>
          <div className='font-[700] text-[20px] text-lnk'>{headerLabel}</div>
          <div className='font-[500] text-[14px] text-textGray mt-[16px] whitespace-pre-line'>{content}</div>
          <div className='flex justify-end gap-4 mt-[24px]'>
            <BaseButton onClick={handleCancel} size='medium' {...cancelBtn}>
              {cancelLabel}
            </BaseButton>
            <BaseButton onClick={handleAccept} size='medium' {...acceptBtn}>
              {acceptLabel}
            </BaseButton>
          </div>
        </div>
      }
    />
  );
};

export default ConfirmCancelEditing;
