import { QUERY_OPERATION, QUERY_TYPE } from '@/utils/constants';

import { IQueryItem, QueryItem } from './base';

type BaseQueryModuleItem<TContext> = Record<
  'guidWith' | 'toDate' | 'fromDate',
  (value: IQueryItem['queryValue'], configs: Pick<IQueryItem, 'fieldTitle' | 'queryCondition'>) => TContext
>;

/**
 * They help you quickly create a new QueryItem for filter by guid.
 * But u know what? Sometimes, you can choose to declare with simple method by new QueryItem()
 */
export function guidWith(this: QueryItem, value: IQueryItem['queryValue'], configs: Pick<IQueryItem, 'fieldTitle' | 'queryCondition'>) {
  const { fieldTitle, queryCondition = '' } = configs;
  this.setValues({
    fieldTitle,
    queryType: QUERY_TYPE.GUID,
    queryValue: value,
    operation: QUERY_OPERATION.EQUAL,
    queryCondition
  });
  return this;
}

/**
 * They  u query filter from date with fieldTitle which in your arguments.
 * But u know what? Sometimes, you can choose to declare with simple method by new QueryItem().
 */
export function fromDate(this: QueryItem, value: IQueryItem['queryValue'], configs: Pick<IQueryItem, 'fieldTitle' | 'queryCondition'>) {
  const { fieldTitle, queryCondition = '' } = configs;
  this.setValues({
    fieldTitle,
    queryType: QUERY_TYPE.DATETIME,
    queryValue: value,
    operation: QUERY_OPERATION.GREATER_THAN_OR_EQUAL,
    queryCondition
  });
  return this;
}

/**
 * They  u query filter to date with fieldTitle which in your arguments.
 * But u know what? Sometimes, you can choose to declare with simple method by new QueryItem().
 */
export function toDate(this: QueryItem, value: IQueryItem['queryValue'], configs: Pick<IQueryItem, 'fieldTitle' | 'queryCondition'>) {
  const { fieldTitle, queryCondition = '' } = configs;
  this.setValues({
    fieldTitle,
    queryType: QUERY_TYPE.DATETIME,
    queryValue: value,
    operation: QUERY_OPERATION.LESS_THAN_OR_EQUAL,
    queryCondition
  });
  return this;
}

const baseQueryModules = {
  guidWith,
  fromDate,
  toDate
};

export type { BaseQueryModuleItem };
export default baseQueryModules;
