import classNames from 'classnames';
import React from 'react';

import './index.scss';

interface ICountryFlagProps {
  countryCode: string;
}

const CountryFlag: React.FC<ICountryFlagProps> = ({ countryCode, ...props }) => {
  const SvgComponent = React.lazy(() => import(`../../../assets/country-flags/${countryCode}.svg`).catch(() => <></>));
  return (
    <React.Suspense fallback={<></>}>
      <div className={classNames('flex', 'country-flag', 'mr-[11px]')}>
        <SvgComponent style={{ width: '28px', height: '20px' }} {...props} />
      </div>
    </React.Suspense>
  );
};

export default CountryFlag;
