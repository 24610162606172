import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormObserver } from '../../models/projectExternalProxy';
import { ICrumb, STEP_CRUMB_STATUS } from '../components/step-crumb';

/**
 * @description because first step is not included in the step crumb
 * So we need to jump 2 steps to get the correct step
 */
const STEP_JUMP = 2;
type FormData = FormObserver<'scenario-c'>;

type RuleRequired = string | undefined;
export interface IUserStepCrumb<T> {
  mode: 'auto' | 'manual';
}
/**
 * @description This hook is used to manage the step crumb. It's required react-hook-form
 * @param {Object} props - The props for setting up the environment.
 * @param {string} props.mode - The mode of operation. If set to 'auto', `rules` is required.
 * @param {Object} [props.rules] - The rules configuration. Required if `mode` is 'auto'.
 * @property {string[][]} props.rules.required - Array of required rule sets.
 *
 * Note: When `mode` is 'auto', `rules` is a required argument. Please ensure to provide it to avoid runtime errors.
 * @example if u have 3 steps and you want to make the second step required
 * ```tsx
 * const { data: stepCrumbs } = useStepCrumb({ mode: 'auto', rules: { required: [undefined, ['step[1].firstName'], undefined] } });
 * ```
 */
const useStepCrumb = <T extends ICrumb = ICrumb, K extends RuleRequired = RuleRequired>(props?: IUserStepCrumb<K>) => {
  const { mode = 'auto' } = props ?? {};
  const formContext = useFormContext<FormData>();
  const [steps, selectedStep] = formContext.watch(['steps', 'selectedStep']);
  const [data, setData] = useState<T[]>([]);
  const { errors: formErrors } = formContext.formState ?? {};
  const { t } = useTranslation();
  const triggerStep = (key: string, callback: (isValid: boolean) => void) => {
    formContext.trigger(key).then((isValid) => {
      callback(isValid);
    });
  };
  const FIST_STEP_CRUMB: unknown = {
    id: 1,
    status: STEP_CRUMB_STATUS.COMPLETED,
    title: t('project:scenario:request_details'),
    onClick: () => {
      triggerStep(`steps[${selectedStep - STEP_JUMP}]`, (isValid) => {
        if (isValid) {
          formContext.setValue(`stepStatus[${selectedStep - 1}].value`, STEP_CRUMB_STATUS.COMPLETED);
          formContext.setValue('selectedStep', 1);
        }
      });
    }
  };
  const stepStatus = formContext.watch('stepStatus') ?? [];
  const initStepCrumbs = () => {
    const newStepCrumbs = steps.map((step: any, index: number) => {
      const stepIndex = index + STEP_JUMP;
      const isCurrentStep = selectedStep === stepIndex;
      let status: STEP_CRUMB_STATUS = stepStatus[index]?.value;
      if (isCurrentStep) {
        status = STEP_CRUMB_STATUS.INPROGRESS;
      }
      const newItem: any = {
        id: step.id,
        status,
        title: step?.title ?? `Step ${stepIndex}`,
        _formData: step,
        keyErrors: step?.keyErrors,
        requires: step.requires
      };
      newItem.onClick = () => {
        if (newItem.status === STEP_CRUMB_STATUS.TODO) return;
        triggerStep(`steps[${selectedStep - STEP_JUMP}]`, (isValid) => {
          if (isValid) {
            formContext.setValue(`stepStatus[${selectedStep - 1}].value`, STEP_CRUMB_STATUS.COMPLETED);
            formContext.setValue('selectedStep', stepIndex);
          }
        });
      };
      return newItem;
    });
    setData([FIST_STEP_CRUMB as T, ...newStepCrumbs]);
  };
  const checkErrorStep = (step: any) => {
    return !!step?.keyErrors?.some((keyError: string) => {
      const errors = get(formErrors, 'steps') ?? [];
      return errors?.find((item: any) => {
        return !!item?.[keyError];
      });
    });
  };

  const calculateStepStatus = () => {
    if (mode !== 'auto') return;
    const callback = (prev: any[]) => {
      let newStepCrumbs = [...prev];
      newStepCrumbs = newStepCrumbs.map((step, index) => {
        if (index === 0) {
          step.status = STEP_CRUMB_STATUS.COMPLETED;
        } else if (index === selectedStep - 1) {
          step.status = STEP_CRUMB_STATUS.INPROGRESS;
        } else {
          const isError = checkErrorStep(step);
          step.status = isError
            ? STEP_CRUMB_STATUS.ERROR
            : stepStatus[index]?.value === STEP_CRUMB_STATUS.COMPLETED
              ? STEP_CRUMB_STATUS.COMPLETED
              : STEP_CRUMB_STATUS.TODO;
        }
        return step;
      });
      return newStepCrumbs;
    };
    setData(callback);
  };
  useEffect(() => {
    initStepCrumbs();
  }, [steps, selectedStep]);

  useEffect(() => {
    calculateStepStatus();
  }, [formContext.formState.errors, selectedStep]);

  return { data, setData, checkErrorStep, triggerStep };
};

export default useStepCrumb;
