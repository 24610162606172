import React from 'react';
import { useTranslation } from 'react-i18next';

import InfoIcon from '@assets/icons/Info.svg';

import TravelType from '../../../type-a/form/step-two/TravelType';
import ApplicantForm from './Applicant';
import AssignmentInfo from './AssignmentInfo';
import VisaInformation from './VisaInfomation';

import './styles.scss';

export const PARENT_PATH = 'steps[0]';
const ScenarioBStepTwo = () => {
  const { t } = useTranslation();
  return (
    <div className='scenario-b-step-two'>
      <div className='scenario-b-step-two__form-title'>
        <p className='title-20'>{t('project:scenario_b:applicant_title')}</p>
        <div className='flex items-center'>
          <InfoIcon className='block mr-[4px]' width={18} height={18} />
          <span className='body-400'>{t('project:scenario_b:applicant_description')}</span>
        </div>
      </div>
      <div className='form-section'>
        <ApplicantForm parentPath={`${PARENT_PATH}.projectApplicant`} />
      </div>
      <div className='form-section'>
        <AssignmentInfo parentPath={`${PARENT_PATH}.projectAssignmentInfo`} />
      </div>
      <div className='form-section'>
        <VisaInformation parentPath={`${PARENT_PATH}`} />
      </div>
      <div className='form-section mt-[12px]'>
        <TravelType parentPath={`${PARENT_PATH}`} />
      </div>
    </div>
  );
};

export default ScenarioBStepTwo;
