import i18n from '@/i18n';
import { AxiosError } from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import DOMPurify from 'dompurify';

import { IAlertNotificationState } from '@/redux/globalReducer';

import { ERROR_CODE, FORMAT_DATE_EN, MAPPING_ERROR_CODE_KEY } from '@/utils/constants/AppConstants';
import { HTTP_STATUS_CODE } from '@/utils/constants/Http';

import { formatDateTime, stripHtml } from './globalHelper';

/**
 * Auto add target="_blank" to all <a> tag when using DOMPurify
 */
DOMPurify.addHook('afterSanitizeAttributes', (node) => {
  if (node.nodeName !== 'A') return;
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener noreferrer');
  }
  if (!node.hasAttribute('target') && (node.hasAttribute('xlink:href') || node.hasAttribute('href'))) {
    node.setAttribute('xlink:show', 'new');
  }
});

// handle error for BR-COM-002
export const handleCommonError = (
  error: AxiosError<any>,
  defaultMessage?: string,
  notifyPublish?: (mess: IAlertNotificationState) => void,
  paramsMsg?: Record<string, any>
): IAlertNotificationState => {
  const results: IAlertNotificationState = { show: true, message: defaultMessage ?? '', type: 'error', position: 'top' };

  switch (error?.response?.status) {
    case HTTP_STATUS_CODE.INTERNAL_ERROR:
      results.message = i18n.t('common:MSG_C_014') ?? '';
      break;
    case HTTP_STATUS_CODE.BAD_REQUEST:
      // Validate
      const firstError = error?.response?.data?.fields[0];
      if (firstError) {
        const errorCode = firstError?.errorCode === ERROR_CODE.VALIDATION ? firstError?.errorDetail : firstError?.errorCode;
        results.message = i18n.t(MAPPING_ERROR_CODE_KEY[errorCode] ?? 'common:MSG_C_014', paramsMsg ?? {});
      } else {
        results.message = i18n.t('common:MSG_C_014') ?? '';
      }
      break;
    case HTTP_STATUS_CODE.NOT_FOUND:
      results.message = i18n.t('common:MSG_C_015') ?? '';
      break;
    default:
      results.message = i18n.t('common:MSG_C_014') ?? '';
      break;
  }

  if (error.code === AxiosError.ERR_NETWORK) {
    results.message = i18n.t('common:MSG_C_004') ?? '';
  }
  if (notifyPublish) notifyPublish(results);
  return results;
};

export class DataViewer {
  static isEmpty(value: any): boolean {
    return !value && value !== 0 && value !== false;
  }

  /**
   * @description Check value of text editor is empty or not (HTML)
   */
  static isEmptyHTML(value: any): boolean {
    const blankList = ['<p><br></p>', '<p></p>', '<p>&nbsp;</p>', '<p>&ZeroWithSpace;</p>', '<p>&nbsp;&ZeroWithSpace;</p>', '\n'];
    return this.isEmpty(value) || !!blankList.find((item) => item === value);
  }

  static isShallowEmpty(value?: { [key: string]: any } | any[], exceptions?: string[]): boolean {
    if (Array.isArray(value) && value.length > 0) {
      return false;
    }

    if (!value || (Array.isArray(value) && value.length === 0) || Object.keys(value).length === 0) {
      return true;
    }

    return Object.entries(value).every(([key, value]) => (exceptions && exceptions.includes(key)) || this.isEmpty(value) || value === '');
  }

  static display(value: any, blankTitle: string | React.ReactElement = '--') {
    const isEmpty = this.isEmpty(value);
    return isEmpty ? blankTitle : value;
  }

  static displayAsSanitizeHTML(value: any, blankTitle: string = '--'): string {
    const isEmpty = this.isEmptyHTML(value);
    const valueReplaceBlankLine = value?.replaceAll('<p></p>', '<p><br></p>');
    const sanitizeContent = DOMPurify.sanitize(valueReplaceBlankLine);
    return isEmpty || sanitizeContent === '<p><br></p>' ? blankTitle : sanitizeContent;
  }

  static displayTime(value?: string | Date | Dayjs | null, format: string = FORMAT_DATE_EN, blankTitle: string = '--'): string {
    const isEmpty = !value;
    if (isEmpty) return blankTitle;

    const dt = dayjs(value);
    return formatDateTime(dt, format);
  }

  static localTimeBy(utcDate?: string | Date | Dayjs | null, format: string = FORMAT_DATE_EN, blankTitle: string = '--'): string {
    const isEmpty = !utcDate;
    if (isEmpty) return blankTitle;

    const dt = dayjs.utc(utcDate).local();
    return formatDateTime(dt, format);
  }

  static isEmptyAfterTrim(text: string | null): number {
    if (!text) return 0;
    // Remove whitespace
    const withoutWhitespace = stripHtml(text.replace(/\s+/g, ''));
    return withoutWhitespace.length;
  }
}

export class DataInput {
  /** This function handle switch format
   * It's helpful for FormInputDate.
   * @return {dayjs} if your value is string, it return dayjs instance
   * @return {string} if your value is instanceof dayjs, it return string by format. default format is FORMAT_DATE_EN.
   * @return {null} if value is empty, it return null.
   */
  static switchFormatDate(value: any, pattern: string = FORMAT_DATE_EN) {
    if (!value) return null;
    if (typeof value === 'string') return dayjs(value);
    if (value instanceof dayjs) return dayjs(value as dayjs.Dayjs).format(pattern);
    return null;
  }

  static formatTime(value: any, pattern: string = FORMAT_DATE_EN) {
    if (!value) return null;
    return dayjs(value).format(pattern);
  }
}

export const isJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
