import { Col, Row } from 'antd';
import React, { FC, isValidElement } from 'react';
import { useTranslation } from 'react-i18next';

import AppLabelSpace from '@/components/app-label/AppLabelSpace';

import { DataViewer } from '@/utils/helpers/common';

interface ColumnItem {
  items: Item[];
  colSpan?: number;
}

interface Item {
  title: React.ReactNode;
  label: string;
  id?: string;
  formId?: string;
}

interface CommonViewInfoProps {
  columns: ColumnItem[];
  commonInfoProps?: any;
  customTitle?: string;
}
const CardInfo: FC<CommonViewInfoProps> = (props) => {
  const {
    columns,
    commonInfoProps = {
      labelClass: 'body-400',
      titleClass: 'body-700'
    },
    customTitle
  } = props;
  const { t } = useTranslation();

  return (
    <Row gutter={16}>
      {columns.map((col, index) => (
        <Col span={col?.colSpan ?? 12} key={index}>
          {col.items.map((item, i) => (
            <div className='mt-2' key={item?.id ?? item?.formId ?? i}>
              <AppLabelSpace
                {...commonInfoProps}
                titleClass={customTitle ?? 'body-700'}
                title={DataViewer.display(item.title)}
                label={isValidElement(item.label) ? item.label : t(item.label)}
              />
            </div>
          ))}
        </Col>
      ))}
    </Row>
  );
};

export default CardInfo;
