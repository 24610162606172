import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import AppLabel from '@/components/app-label/AppLabel';

import { DataViewer } from '@/utils/helpers/common';

import { ISchedule } from '../../../../../utils/interfaces/template';

import './ViewSchedule.scss';

interface IScheduleProps {
  data?: ISchedule;
}

function ViewSchedule({ data }: IScheduleProps) {
  const { t } = useTranslation();

  return (
    <Row gutter={24} className='justify-between'>
      <Col span={12} className='flex flex-col gap-2'>
        <AppLabel label={t('template_layout:country_name')} title={DataViewer.display(data?.countryName)} hasTooltip />
        <AppLabel label={t('basic_information:category')} title={DataViewer.display(data?.categoryName)} hasTooltip />
        <AppLabel label={t('template:form')} title={DataViewer.display(data?.formName)} hasTooltip />
      </Col>
      <Col span={12} className='flex flex-col gap-2'>
        <AppLabel label={t('template:template_name')} title={DataViewer.display(data?.name)} hasTooltip />
        <AppLabel label={t('template_layout:reference_template')} title={DataViewer.display(data?.referanceTemplateName)} hasTooltip />
      </Col>
    </Row>
  );
}
export default ViewSchedule;
