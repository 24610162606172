import { DatePicker, DatePickerProps } from 'antd';
import locale from 'antd/es/date-picker/locale/ja_JP';
import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/ja';
import { CSSProperties, useEffect, useMemo, useState } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CalendarBlank from '@/assets/icons/CalendarBlank.svg';

import { useMessageYupTranslation } from '../../hooks/useI18n';
import { ALLOW_KEYS_DATE, FORMAT_DATE_EN, FORMAT_DATE_JP, REQUIRED_DOT } from '../../utils/constants/AppConstants';
import { checkValidDate } from '../../utils/helpers/globalHelper';
import { disableMinMaxDate } from '../../utils/helpers/validateHelper';
import { IInputBaseProps } from '../../utils/interfaces/text-field';
import { ColorDefault } from '../../utils/themes/color';
import { ErrorInput } from '../error-input/ErrorInput';

import './FormInputDate.scss';

export type FormInputDateProps = Omit<IInputBaseProps, 'onChange'> &
  DatePickerProps & {
    name: string;
    formatValue?: string;
    onChange?: (e: Dayjs | null) => void;
    preChange?: (val: any) => Promise<any>;
    disabledFeatureDay?: (data: boolean) => void;
    triggerShowModal?: 'icon' | 'both';
    preSuffixIcon?: React.ReactNode;
    customMessage?: () => { status?: string; content: JSX.Element | string };
  };
const FormInputDate = (props: FormInputDateProps) => {
  // props
  const {
    placeholder,
    placeholderTx,
    name,
    labelTx,
    onBlur,
    onSubmit,
    defaultValue,
    label,
    required,
    errorColorTheme,
    disabled,
    heightInput = 38,
    errorBorderColor,
    bordered = true,
    className,
    onChange,
    preChange,
    disabledDate,
    isBaselineError,
    disabledFeatureDay,
    triggerShowModal = 'icon',
    preSuffixIcon,
    customMessage,
    formatValue,
    ...rest
  } = props;

  // state
  const [focused, setFocused] = useState<boolean>(false);
  const [hovered, setHovered] = useState<boolean>(false);
  const [shouldDisplayPopup, setShouldDisplayPopup] = useState<boolean>(false);

  const [t] = useTranslation();
  const {
    field,
    fieldState: { error }
  } = useController({
    name: name as string,
    defaultValue
  });

  const msgError = useMessageYupTranslation(error?.message);
  const labelText = useMemo(() => (labelTx && t(labelTx)) || label || undefined, [labelTx, label, t]);
  const placeHolderText = useMemo(
    () => (placeholderTx && t(placeholderTx)) || placeholder || t('placeholder:date').toLowerCase(),
    [placeholderTx, placeholder, t]
  );

  const borderColor = useMemo(() => {
    switch (true) {
      case !bordered:
        return 'transparent';
      case disabled:
        return ColorDefault.gray2;
      case error !== undefined:
        return errorBorderColor || ColorDefault.negative;
      case focused:
        return ColorDefault.action;
      case hovered:
        return ColorDefault.gray4;
      default:
        return ColorDefault.gray3;
    }
  }, [bordered, disabled, error, errorBorderColor, hovered, focused]);

  // func
  const getInputDate = (value: Dayjs | string) => {
    if (value) {
      if (typeof value === 'string') {
        const date = dayjs(value);
        if (date.isValid() && checkValidDate(value, FORMAT_DATE_EN)) return date;
        return null;
      }
      return value;
    }
    return null;
  };

  const _onFocus = () => {
    setFocused(true);
    setShouldDisplayPopup(true);
  };

  const _onMouseOver = () => {
    setHovered(true);
  };

  const _onMouseLeave = (e: any) => {
    setHovered(false);
  };

  const _onKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      const value = e.target.value;
      if (!checkValidDate(value, FORMAT_DATE_EN)) return;
      if (value && !checkValidDate(value, FORMAT_DATE_EN)) {
        if (field.value) {
          field.onChange(field.value);
          onChange && onChange(field.value);
        } else {
          field.onChange(null);
          onChange && onChange(null);
        }
      }
      if (disabledFeatureDay) {
        if (dayjs(e.target.value).isAfter(dayjs())) {
          field.onChange(field.value || dayjs());
          disabledFeatureDay(false);
        }
      }
    }

    const isCopy = (e.ctrlKey || e.metaKey) && e.key === 'c';
    const isPaste = (e.ctrlKey || e.metaKey) && e.key === 'v';
    if (!(/[0-9/]/.test(e.key) || ALLOW_KEYS_DATE.includes(e.keyCode) || isCopy || isPaste)) {
      e.preventDefault();
    }
  };

  const containerStyle: CSSProperties = {
    borderColor,
    backgroundColor: disabled ? ColorDefault.gray1 : ColorDefault.white,
    height: heightInput
  };
  const selectStyle: CSSProperties = {
    height: heightInput,
    maxHeight: heightInput
  };

  const valueDateInput = useMemo(() => {
    return getInputDate(field.value);
  }, [field.value]);

  const onScroll = (e: any) => {};

  useEffect(() => {
    const elementsScroll = document.querySelectorAll('.container-scroll');
    for (let index = 0; index < elementsScroll.length; index++) {
      const elementScroll = elementsScroll[index];
      elementScroll.removeEventListener('scroll', onScroll);
      elementScroll.addEventListener('scroll', onScroll, { passive: true });
    }

    return () => {
      for (let index = 0; index < elementsScroll.length; index++) {
        const elementScroll = elementsScroll[index];
        elementScroll?.removeEventListener('scroll', onScroll);
      }
    };
  }, []);

  const _handleOnChange = async (val: Dayjs | null) => {
    try {
      if (preChange) await preChange(val);
      let date = val ? dayjs(val).hour(12).minute(1) : null;
      if (disabledDate && date && disabledDate(date)) {
        date = null;
      }
      field.onChange(formatValue && val ? dayjs(val).format(formatValue) : date);
      onChange && onChange(date);
    } catch (error) {}
  };

  const _onBlur = (e: any) => {
    const date = getInputDate(e.target.value);
    setFocused(false);
    if (date?.valueOf() !== field.value?.valueOf()) {
      _handleOnChange(date);
    }
  };

  const renderErrorOrWarning = () => {
    if (!customMessage && (error !== undefined || isBaselineError))
      return (
        <ErrorInput
          classNameLabel={isBaselineError ? 'truncate' : ''}
          className={isBaselineError ? '!mt-0 h-[26px]' : ''}
          error={msgError ?? ''}
          errorColorTheme={errorColorTheme}
        />
      );
    if (customMessage) return customMessage().content;
  };

  // render
  return (
    <div className='date-container'>
      {labelText && (
        <div className='label-container text-ink-dark'>
          <div>{labelText ?? ''}</div>
          {required && (
            <div className='require' color={ColorDefault.negative}>
              {REQUIRED_DOT}
            </div>
          )}
        </div>
      )}
      {isBaselineError && <div className='h-[26px]' />}

      <div className='input-container' onMouseOver={_onMouseOver} onMouseLeave={_onMouseLeave} style={containerStyle}>
        <DatePicker
          ref={field.ref}
          placeholder={placeHolderText?.toLowerCase()}
          bordered={false}
          disabled={disabled}
          value={valueDateInput}
          style={selectStyle}
          onChange={_handleOnChange}
          popupClassName={!shouldDisplayPopup ? 'hidden' : ''}
          onOpenChange={(open) => {
            if (triggerShowModal === 'both') {
              setShouldDisplayPopup(open);
              return;
            }
            !open && setShouldDisplayPopup(open);
          }}
          className={classNames(
            className,
            'input-date',
            hovered && field.value ? 'hover-input' : '',
            error || (!!customMessage && customMessage().status === 'error') ? 'form-input-error' : '',
            !!customMessage && customMessage().status === 'warning' ? 'form-input-warning' : ''
          )}
          placement='bottomLeft'
          size='large'
          locale={locale}
          onBlur={_onBlur}
          onFocus={(e) => {
            setFocused(true);
          }}
          onKeyDown={_onKeyDown}
          format={t('placeholder:date') ?? FORMAT_DATE_JP}
          disabledDate={(e) => {
            return (disabledDate && disabledDate(e)) || disableMinMaxDate(e);
          }}
          suffixIcon={
            <>
              {preSuffixIcon}
              <div onClick={_onFocus}>
                <CalendarBlank />
              </div>
            </>
          }
          {...rest}
        />
      </div>
      {renderErrorOrWarning()}
    </div>
  );
};

export { FormInputDate };
