import Quill from 'quill';
import { isValidElement } from 'react';
import { createRoot } from 'react-dom/client';

import { INPUT_FILE_ALLOWED } from '@/utils/constants/file';
import { clonedFile } from '@/utils/helpers/fileHelper';
import { IProjectAttachments } from '@/utils/interfaces/Project';

const Module = Quill.import('core/module');

export interface IFile extends IProjectAttachments {
  id: string;
  name: string;
  blobPath: string;
}
export interface IFileAttachmentOptions {
  /**
   * @deprecated This method is deprecated, it's not useful for business's logic.
   */
  onUpload: (files: File[]) => Promise<{ errors?: any[]; files: IFile[] }>;
  /**
   * @deprecated This method is deprecated, it's not useful for business's logic.
   */
  onError: (errors?: any) => void;
  /**
   * // onClick prop is required for handle click attachment event although you can declare it as optional
   * @example
   * const UploadFileIcon = ({ color = '#12212E', onClick }: { color?: string; onClick?: React.MouseEventHandler<HTMLButtonElement> }) => {
   * return <button onClick={onClick}>
   *      click me
   * </button>
   * }
   */
  buttonIcon: React.ReactElement<{ onClick?: Function }>;
  className?: string;
  onChangeFile: (file: File[]) => void;
}

export default class FileAttachment extends Module<IFileAttachmentOptions> {
  quill: Quill;
  constructor(quill: Quill, options?: IFileAttachmentOptions) {
    super(quill, options);
    this.quill = quill;
    if (!this.quill) {
      console.warn('Unable to find Quill instance');
      return;
    }
    this.init();
  }

  init() {
    const { buttonIcon, className } = this.options ?? {};
    if (!isValidElement(buttonIcon)) {
      console.warn('U can not use this module without buttonIcon as ReactElement');
      return;
    }
    const fileInput = this._generateHiddenInput();

    // create toolbar button
    const toolbarCustom: any = this.quill.getModule('toolbar');
    const buttonContainer = document.createElement('div');
    buttonContainer.className = 'ql-toolbar-file-attachment inline-block';
    if (className) buttonContainer.className += ` ${className}`;
    const container = toolbarCustom.container?.firstChild ?? toolbarCustom.container;
    if (container) container.appendChild(buttonContainer);

    const originalOnClick = buttonIcon.props.onClick;
    // eslint-disable-next-line
    buttonIcon.props.onClick = function (...args: any[]) {
      fileInput?.click();
      if (originalOnClick) originalOnClick.apply(this, args);
    };

    const root = createRoot(buttonContainer);
    root.render(buttonIcon);
  }

  private _generateHiddenInput() {
    const { onChangeFile } = this.options ?? {};
    if (!onChangeFile) {
      console.warn('U are missing onChangeFile method');
      return;
    }
    const fileInput = document.createElement('input');
    fileInput.setAttribute('type', 'file');
    fileInput.setAttribute('multiple', 'multiple');
    fileInput.style.display = 'none';
    fileInput.setAttribute('accept', INPUT_FILE_ALLOWED);
    document.body.appendChild(fileInput);

    fileInput.addEventListener('change', async (e) => {
      const files = fileInput.files;
      if (!files?.[0]) return;
      const filesClonePromises = Array.from(files).map((file) => clonedFile(file));
      const filesClone = (await Promise.all(filesClonePromises)).filter((file) => file !== null);
      onChangeFile?.(filesClone);
      fileInput.value = '';
    });
    return fileInput;
  }
}
