import { DefaultOptionType } from 'antd/es/select';
import React, { useEffect, useRef } from 'react';

/**
 * This component is used to preview the selected value in the dropdown
 * It will remove the selected value in view if it's not in the options list
 * @param options your dropdown options
 * @param value your current value
 * @param children it's must be a Select component
 */
const SelectionPreview = ({
  children,
  value,
  options,
  label
}: {
  children: JSX.Element;
  value: any;
  options: DefaultOptionType[];
  label?: string;
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const dropdownOptions = options ?? [];
    if (!wrapperRef.current) return;
    const matched = dropdownOptions.find((option) => option.value === value);
    if (matched && label === undefined) return;
    const inputElm = wrapperRef.current.querySelector('.ant-select .ant-select-selector .ant-select-selection-item');

    if (!inputElm) return;

    if (label !== undefined) {
      inputElm.textContent = label;
      inputElm.setAttribute('title', label);
    } else {
      if (inputElm.textContent === '') return;
      inputElm.textContent = '';
      inputElm.setAttribute('title', '');
    }
  });

  return <div ref={wrapperRef}>{children}</div>;
};

export default SelectionPreview;
