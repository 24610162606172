import CaretLeft from '@/layouts/assets/icons/CaretLeft.svg?react';
import { Carousel } from 'antd';
import { CarouselRef } from 'antd/es/carousel';
import { RefObject, createRef, forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseButton } from '@/components/base-button/BaseButton';
import BaseModal from '@/components/base-modal/BaseModal';
import { CaretRightIcon } from '@/components/icon-svg/IconSvg';

import useMutation from '@/hooks/useMutation';

import { API } from '@/utils/constants/Apis';
import { LOCALSTORAGE } from '@/utils/constants/AppConstants';
import { HTTP_STATUS_CODE } from '@/utils/constants/Http';
import { DataViewer } from '@/utils/helpers/common';
import { parseJson } from '@/utils/helpers/globalHelper';
import { Ii18n } from '@/utils/interfaces/i18n';

import { NUMBER_NEXT, NUMBER_START, slidesTutorial } from './constants';
import { ContentTutorial } from './models';

import './Tutorial.scss';

const TutorialContent = forwardRef((props, ref) => {
  const { t }: Ii18n = useTranslation();
  const [currentSlide, setCurrentSlide] = useState<number>(NUMBER_START);
  const [open, setOpen] = useState(false);
  const carouselRef: RefObject<CarouselRef> = createRef<CarouselRef>();

  const { mutate: readTutorial } = useMutation(API.READ_TUTORIAL, {
    method: 'PUT',
    bodyType: 'json',
    showToastError: true,
    showToastSuccess: false
  });

  const goBack = () => {
    carouselRef.current?.prev();
    setCurrentSlide(currentSlide - NUMBER_NEXT);
  };

  const gotToNext = () => {
    carouselRef.current?.next();
    setCurrentSlide(currentSlide + NUMBER_NEXT);
  };

  const closeTutorial = async () => {
    const localStorageUser = parseJson(localStorage.getItem(LOCALSTORAGE.USER) ?? '');
    if (localStorageUser.readedTutorial) {
      carouselRef.current?.goTo(NUMBER_START, false);
      setCurrentSlide(NUMBER_START);
      setOpen(false);
      return;
    }
    try {
      const result = await readTutorial({ id: localStorageUser.id });
      if (result?.status === HTTP_STATUS_CODE.SUCCESS) {
        carouselRef.current?.goTo(NUMBER_START, false);
        setCurrentSlide(NUMBER_START);
        setOpen(false);
      } else {
        setOpen(true);
      }
    } catch (errors) {}
  };

  useImperativeHandle(ref, () => ({
    toggle: (flag?: boolean) => {
      setOpen((prev) => flag ?? !prev);
    }
  }));

  const beforeChangeSlide = (_currentSlide: number, nextSlide: number) => {
    setCurrentSlide(nextSlide);
  };

  return (
    <BaseModal
      title={t('personal_information:tutorial')}
      className='tutorial-modal'
      maskClosable={false}
      openModal={open}
      onCancel={closeTutorial}
      centered={false}
      style={{ left: -200 }}
      destroyOnClose
      contentElement={
        <>
          <Carousel ref={carouselRef} infinite={false} adaptiveHeight={false} beforeChange={beforeChangeSlide}>
            {slidesTutorial.map((slide: ContentTutorial) => {
              return <div key={slide.id}>{slide.img}</div>;
            })}
          </Carousel>
          <div
            className='mt-[12px] min-h-[75px] slide-note'
            dangerouslySetInnerHTML={{ __html: DataViewer.displayAsSanitizeHTML(t(slidesTutorial[currentSlide]?.description)) }}
          />
        </>
      }
      footerElement={
        <div className='flex justify-between items-center'>
          <div className='text-[16px] font-semibold text-lnk'>
            {currentSlide + 1}/{slidesTutorial.length}
          </div>
          <div className='flex gap-[24px] '>
            {currentSlide === NUMBER_START && (
              <BaseButton onClick={closeTutorial} className='p-[8px]' type='tertiary'>
                {t('button:skip')}
              </BaseButton>
            )}
            {currentSlide > NUMBER_START && currentSlide < slidesTutorial.length && (
              <BaseButton className='p-[8px]' onClick={goBack} type='tertiary'>
                <img src={CaretLeft} width={18} height={18}></img> {t('button:back')}
              </BaseButton>
            )}
            {currentSlide < slidesTutorial.length - 1 && (
              <BaseButton className='p-[8px]' onClick={gotToNext}>
                {t('button:next')} <CaretRightIcon width={18} height={18} />
              </BaseButton>
            )}
            {currentSlide === slidesTutorial.length - 1 && (
              <BaseButton className='p-[8px]' onClick={closeTutorial}>
                {t('button:end')}
              </BaseButton>
            )}
          </div>
        </div>
      }
    />
  );
});

type BaseModalTutorial = {
  toggle: () => void;
};
export const popupRef = createRef<BaseModalTutorial>();

export const showTutorialPopup = () => {
  return popupRef?.current?.toggle();
};
const ModalTutorial = () => {
  return <TutorialContent ref={popupRef} />;
};
export default ModalTutorial;
