import { Spin } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { debounce, unionBy } from 'lodash';
import React, { JSX, useEffect, useRef, useState } from 'react';

import useFetch from '@/hooks/useFetch';
import useInfinityScroll from '@/hooks/useInfinityScroll';

import { FormSelect, IFormSelect } from './FormSelect';

type Props = {
  children: JSX.Element;
  missedValues: string[];
  options: DefaultOptionType[];
};
export function PreviewMissedLabel(props: Props) {
  const { children, missedValues, options } = props;
  const wrapperRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!wrapperRef.current || !missedValues?.length || !options?.length) return;
    const tagElements = wrapperRef.current.querySelectorAll('.multiple-item-selection');
    for (let i = 0; i < tagElements.length; i++) {
      const textContent = tagElements[i].textContent;
      if (!textContent || !missedValues.includes(textContent)) continue;
      const label = options.find((option) => option.value === textContent)?.label;
      const customTagElem = tagElements[i].querySelector('.custom-tag');
      if (!customTagElem || !label) continue;
      customTagElem.textContent = `${label}`;
    }
  });

  return <div ref={wrapperRef}>{children}</div>;
}

export interface ICommonResponse<T> {
  totalCount: number;
  totalPage: number;
  pageSize: number;
  pageIndex: number;
  data: T[];
}
export interface InfinityFormSelectProps extends IFormSelect {
  endpoint: string;
  convertData?: (data: any) => any;
  defaultSelected?: string[];
  fullSelectedOptions: DefaultOptionType[];
}

export interface IParams {
  pageIndex: number;
  pageSize: number;
  includeInactive: boolean;
  textSearch?: string;
}
const initialParams = {
  pageIndex: 0,
  pageSize: 10,
  includeInactive: false
};

const InfinityFormSelect = <IData, IOption extends DefaultOptionType>(props: InfinityFormSelectProps) => {
  const { endpoint, convertData = (data) => ({ ...data, value: data?.id }), defaultSelected = [], fullSelectedOptions } = props;
  const [params, setParams] = useState<IParams>(initialParams);
  const { data: responseData, loading, error } = useFetch<ICommonResponse<IData>>(endpoint, 'POST', params);
  const [fetchingOptions, setFetchingOptions] = useState<IOption[]>([]);
  const menuRef = useRef<any>(null);

  const loadMore = () =>
    setParams((prev) => {
      const nexPageIndex = prev.pageIndex + 1;
      if (!responseData?.totalPage || nexPageIndex >= responseData?.totalPage) return prev;
      return { ...prev, pageIndex: nexPageIndex };
    });
  const { latestNodeRef } = useInfinityScroll({ root: menuRef.current, isFetching: loading, loadMore });

  useEffect(() => {
    if (!responseData || error) return;
    const updatedList = responseData?.data?.map(convertData) ?? [];
    setFetchingOptions((prev) => unionBy([...prev, ...updatedList], 'value'));
  }, [responseData, error]);

  const dropdownRender = (menu: React.ReactElement) => {
    return (
      <div ref={menuRef}>
        {menu}
        {loading && (
          <div className='p-5 flex items-center justify-center'>
            <Spin />
          </div>
        )}
      </div>
    );
  };

  const optionRender = (option: DefaultOptionType) => {
    const latestItem = fetchingOptions[fetchingOptions.length - 1];
    const isLatest = option.value === latestItem?.value;
    if (isLatest) return <div ref={latestNodeRef}>{option.label}</div>;
    return <div>{option.label}</div>;
  };

  const handleSearch = debounce((value: string) => {
    if (!value) {
      setParams(initialParams);
    } else {
      setParams({ ...initialParams, textSearch: value });
    }
  }, 500);

  return (
    <PreviewMissedLabel
      missedValues={defaultSelected.filter((id) => !fetchingOptions.find((item) => item?.value === id))}
      options={fullSelectedOptions}
    >
      <FormSelect
        {...props}
        notFoundContent={loading ? <></> : undefined}
        options={fetchingOptions}
        optionRender={optionRender}
        dropdownRender={dropdownRender}
        onSearch={handleSearch}
      />
    </PreviewMissedLabel>
  );
};

export default InfinityFormSelect;
