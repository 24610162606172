import { Tag } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { STATUS_NAME, StatusName } from '@/pages/project-management/detail/constant';
import { renderTaskStatus } from '@/pages/project-management/detail/utils/helper';

import AppTooltip from '@/components/app-tooltip/AppTooltip';
import { TagTaskType } from '@/components/common/task/TagTaskType';

import { DataViewer } from '@/utils/helpers/common';
import { Ii18n } from '@/utils/interfaces/i18n';

import WarningWaitingApprove from '@/assets/icons/WarningWaitingApprove.svg';

import { IDetailTaskData } from '../../models';

import '../index.scss';

interface HeaderExternalRoleProps {
  detailData: IDetailTaskData;
}

const HeaderExternalRole = ({ detailData }: HeaderExternalRoleProps) => {
  const { t }: Ii18n = useTranslation();

  const isTaskDelay = () => {
    return detailData?.statusName !== STATUS_NAME.COMPLETED && dayjs(detailData?.deadline).isBefore(dayjs(), 'day');
  };

  const renderPicName = () => {
    if (!detailData || !detailData.projectTaskPics.length) return;
    if (detailData && detailData.projectTaskPics.length === 1) return detailData.projectTaskPics[0].picName;
    const multiplePicName = detailData.projectTaskPics.map((item: any) => {
      if (!item?.picName) return;
      return item?.picStatus ? item.picName : `(${t('account_list:status_account:inactive')}) ${item.picName}`;
    });
    return multiplePicName.join('、');
  };

  return (
    <div className='view-task-project-header flex items-center justify-between'>
      <div className='flex items-center gap-[8px]'>
        <TagTaskType type={detailData?.taskType ?? ''} />
        <AppTooltip className='truncate inline-block max-w-[800px]' title={DataViewer.display(detailData?.taskName)}>
          <div className='text-lnk text-[24px] font-bold truncate inline-block max-w-[800px]'>{DataViewer.display(detailData?.taskName)}</div>
        </AppTooltip>
      </div>
      <div className='flex items-center gap-[8px]'>
        <div className='text-lnk text-[14px] font-normal'>{t('project:task_of_project:deadline')}: </div>
        <div className='text-lnk text-[14px] font-normal mr-[24px]'>{DataViewer.displayTime(detailData?.deadline)}</div>
        <div className='text-lnk text-[14px] font-normal'>{t('project:task_of_project:mot_pick:label')}: </div>
        <AppTooltip className='truncate inline-block max-w-[150px]' title={DataViewer.display(renderPicName())}>
          <div className='text-lnk text-[14px] font-normal mr-[24px] truncate inline-block max-w-[250px]'>{DataViewer.display(renderPicName())}</div>
        </AppTooltip>
        {isTaskDelay() ? (
          <Tag
            className='flex items-center justify-center !px-[8px] !py-[2px] !gap-[4px] !rounded-[4px] !bg-yellow10 !border-warning !text-yellow '
            color='#F2A227'
            icon={<WarningWaitingApprove />}
          >
            {t('project:task_of_project:delay')}
          </Tag>
        ) : (
          <div>
            {renderTaskStatus(detailData?.statusName as StatusName, 'flex items-center justify-center !px-[8px] !py-[3px] !gap-[4px] !rounded-[4px]')}
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderExternalRole;
