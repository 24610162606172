import { Result } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { BaseButton } from '../../../components/base-button/BaseButton';
import { setLoadingPageGlobal } from '../../../redux/globalReducer';
import { DEFAULT_PAGE } from '../../../utils/constants/AppConstants';
import { Ii18n } from '../../../utils/interfaces/i18n';

const SomeThingWentWrong = () => {
  const { t }: Ii18n = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLoadingPageGlobal(false));
  }, []);

  // function redirect go back to previous
  const goBackHome = () => {
    window.location.href = DEFAULT_PAGE;
  };

  return (
    <div className='flex items-center h-[100vh]'>
      <Result
        status='500'
        title='500'
        subTitle={t('error:something_went_wrong:sub_title')}
        extra={
          <div className='w-full flex items-center justify-center'>
            <BaseButton type='primary' size='large' onClick={goBackHome}>
              Back Home
            </BaseButton>
          </div>
        }
        className='m-auto'
      />
    </div>
  );
};

export default SomeThingWentWrong;
