import { Space } from 'antd';
import { Key } from 'react';
import { useTranslation } from 'react-i18next';

import Trash from '../../../assets/icons/TrashIcon.svg';
import { BaseButton } from '../../base-button/BaseButton';

interface ITableHeader<T> {
  isShowDelete: boolean;
  isShowDownload: boolean;
  selectedRowKeys: Key[];
  selectedRow: T[];
  numberOfSelectedRows: number | null;
  handleDeleteClick?: (keys: Key[], row?: T[]) => void;
  handleDownloadClick?: (keys: React.Key[]) => void;
}

const TableHeader = <T extends {}>(props: ITableHeader<T>) => {
  const { isShowDelete, isShowDownload, selectedRowKeys, numberOfSelectedRows, selectedRow, handleDeleteClick, handleDownloadClick } = props;

  const [t] = useTranslation();

  return (
    <>
      {selectedRowKeys.length > 0 && (
        <div className='flex items-center wrap-delete-table mb-[16px]'>
          <div className='font-[500] mr-2'>{`${numberOfSelectedRows ?? selectedRowKeys.length} ${t('common:selected')}`}</div>
          {(isShowDelete || isShowDownload) && (
            <Space size={16} direction='horizontal'>
              {isShowDownload && (
                <BaseButton
                  onClick={() => {
                    handleDownloadClick && handleDownloadClick(selectedRowKeys);
                  }}
                  className='delete-btn medium-tertiary'
                  type='tertiary'
                >
                  {t('template_layout:download_button')}
                </BaseButton>
              )}
              {isShowDelete && (numberOfSelectedRows ?? selectedRowKeys.length) > 0 && (
                <BaseButton
                  onClick={() => {
                    handleDeleteClick && handleDeleteClick(selectedRowKeys, selectedRow);
                  }}
                  icon={<Trash />}
                  className='delete-btn !min-w-[0] !rounded'
                  type='outline-red'
                  size='small'
                >
                  {t('template_layout:delete_button')}
                </BaseButton>
              )}
            </Space>
          )}
        </div>
      )}
    </>
  );
};

export default TableHeader;
