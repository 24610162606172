import Quill from 'quill';

export const calculateCharacters = (quill: Quill, options: any = {}): number => {
  if (!quill) return 0;
  const length = quill.getLength();
  return length - 1;
};

export const convertHtmlToDelta = (quill: Quill, html: string) => {
  if (!quill) return;
  const delta = quill.clipboard?.convert({ html });
  return delta;
};
