import * as yup from 'yup';

import { UPLOAD_STATUS } from '@/utils/constants/AppConstants';
import { stringifyObjectValidateYup } from '@/utils/method';

const createFileStatusTest = () =>
  yup.string().test(
    'file-invalid',
    stringifyObjectValidateYup({
      keyT: 'common:MSG_FILE_INVALID'
    }),
    (value) => {
      return value !== UPLOAD_STATUS.ERROR;
    }
  );

const createFileObject = () =>
  yup.object().shape({
    status: createFileStatusTest()
  });
export const FormDetailTaskData = yup.object().shape({
  taskRequireDocuments: yup.array().of(
    yup.object().shape({
      projectRequiredDocumentId: yup.string().required(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_001_select',
          optionsTx: { field: 'project:task_of_project:document_name' }
        })
      ),
      taskRequireDocumentAttachmentFiles: yup.array().of(createFileObject())
    })
  )
});
