import { useAppSelector } from '@/hooks';
import lodash from 'lodash';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { AnyObject } from 'yup';

import { GENDER, GO_WITH_FAMILY } from '@/pages/project-management/project-list/constants';
import { ExternalProxyContext } from '@pages/project-management/add-edit/ProjectExternalProxy';
import { getFirstErrorName } from '@pages/project-management/add-edit/scenario/utils';

import { TYPE_ICON, showBasePopup } from '@/components/base-popup/BasePopup';

import { getProjectDetails } from '@/redux/project-v2/projectSelector';

import useAuthorization from '@/hooks/useAuthorization';
import useMutation from '@/hooks/useMutation';

import { API } from '@/utils/constants/Apis';
import { CREATE_PROJECT_SUCCESS, PROJECT_LIST_URL, SAVE_AS_DRAFT_PROJECT_SUCCESS } from '@/utils/constants/RouteContants';
import { DataViewer } from '@/utils/helpers/common';
import { formatDateTime } from '@/utils/helpers/globalHelper';
import { validateWithoutErrorTypes } from '@/utils/helpers/validateHelper';

import { IProjectStakeholder } from '../../models';
import { FormObserver } from '../../models/projectExternalProxy';
import Header, { HEADER_EVENT, STEP } from '../components/header';
import ScenarioLayout from '../components/layout';
import StepCrumb, { STEP_CRUMB_STATUS } from '../components/step-crumb';
import { HAS_OTHER_STAKE_HOLDER } from './constants';
import Step2 from './form/Step2';
import Step3 from './form/Step3';
import Step4 from './form/Step4';
import Step5 from './form/Step5';
import Step6 from './form/Step6';
import Step7 from './form/Step7';
import Step8 from './form/Step8';
import useStepCrumb from './useStepCrumb';

const ScenarioC = () => {
  const { setLoading } = useContext(ExternalProxyContext);
  const formContext = useFormContext<FormObserver<'scenario-c'>>();
  const [selectedStep] = formContext.watch(['selectedStep']);
  const { id } = useParams();
  const { user } = useAuthorization();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: detailProject } = useAppSelector(getProjectDetails);
  const { mutate: createScenarioC } = useMutation(API.CREATE_SCENARIO_C, {
    method: 'POST',
    bodyType: 'json',
    showToastError: true,
    showToastSuccess: false
  });

  const { mutate: updateScenarioC } = useMutation(API.CREATE_SCENARIO_C, {
    method: 'PUT',
    bodyType: 'json',
    showToastError: true,
    showToastSuccess: false
  });
  const onClick = (event: HEADER_EVENT) => {
    switch (event) {
      case HEADER_EVENT.CANCEL:
        navigate(PROJECT_LIST_URL);
        break;
      case HEADER_EVENT.NEXT:
        onNext();
        break;
      case HEADER_EVENT.BACK:
        onBack();
        break;
      case HEADER_EVENT.SAVE:
        handleSave();
        break;
      case HEADER_EVENT.DRAFT:
        onSaveAsDraft();
        break;
      default:
        break;
    }
  };

  const convertStepForHeader = (step: number) => {
    switch (step) {
      case 1:
        return STEP.FIRST;
      case 8:
        return STEP.LAST;
      default:
        return step;
    }
  };
  const renderFormStep = (step: number) => {
    switch (step) {
      case 2:
        return <Step2 />;
      case 3:
        return <Step3 />;
      case 4:
        return <Step4 />;
      case 5:
        return <Step5 />;
      case 6:
        return <Step6 />;
      case 7:
        return <Step7 />;
      case 8:
        return <Step8 />;
      default:
        return null;
    }
  };
  const { data: stepCrumbs, checkErrorStep } = useStepCrumb({ mode: 'auto' });

  const handleSave = () => {
    formContext.handleSubmit(
      async () => {
        const showPopup = await showBasePopup({
          title: String(t('common:MSG_P_001_title')),
          msg: String(t('common:MSG_P_001_content')),
          type: TYPE_ICON.CONFIRM
        });
        if (showPopup !== TYPE_ICON.CONFIRM) return;
        setLoading(true);
        // Call API
        const payload = getPayload(formContext.getValues(), false);
        const result = !id ? await createScenarioC(payload) : await updateScenarioC(payload);
        if (!!result) {
          setTimeout(() => {
            setLoading(false);
            navigate(CREATE_PROJECT_SUCCESS);
          }, 500);
          return;
        }
        setLoading(false);
      },
      (_) => {}
    )();
  };
  const onSaveAsDraft = async () => {
    const hasErrorNotRequired = validateWithoutErrorTypes(formContext.formState.errors, ['required', 'optionality', 'nullable']);
    if (hasErrorNotRequired) return;
    const showPopup = await showBasePopup({
      title: String(t('common:MSG_P_002_title')),
      msg: String(t('common:MSG_P_002_content')),
      type: TYPE_ICON.CONFIRM
    });
    if (showPopup !== TYPE_ICON.CONFIRM) return;
    setLoading(true);
    // Call API
    const payload = getPayload(formContext.getValues());
    const result = !id ? await createScenarioC(payload) : await updateScenarioC(payload);
    if (!!result) {
      formContext.reset((prev) => ({ ...prev, isDirty: false }), { keepValues: true, keepDirty: false });
      setTimeout(() => {
        setLoading(false);
        navigate(SAVE_AS_DRAFT_PROJECT_SUCCESS(result?.data || ''));
      }, 500);
      return;
    }
    setLoading(false);
  };

  const determineGender = (gender: any) => {
    if (gender === GENDER.female) return true;
    if (gender === GENDER.male) return false;
    return null;
  };
  const getProjectApplicant = (projectApplicant: any) => ({
    ...projectApplicant,
    dateOfBirth: projectApplicant?.dateOfBirth ? formatDateTime(projectApplicant?.dateOfBirth) : null,
    gender: determineGender(projectApplicant?.gender),
    departureDate: projectApplicant?.departureDate ? formatDateTime(projectApplicant?.departureDate) : null
  });
  const formatProjectFamilies = (data: any) => {
    if (data?.goWithFamily === GO_WITH_FAMILY.NO || !data?.goWithFamily) return [];
    let projectFamilies =
      data?.projectFamilies?.map((item: any, index: number) => ({
        ...item,
        dateOfBirth: formatDateTime(item.dateOfBirth),
        gender: determineGender(item?.gender),
        displayOrder: index
      })) || [];

    detailProject?.projectFamilies?.forEach((item: any) => {
      if (!projectFamilies.find((i: any) => i.id === item.id) && item.id) {
        projectFamilies.push({
          ...item,
          dateOfBirth: formatDateTime(item.dateOfBirth),
          gender: determineGender(item?.gender),
          deleted: true
        });
      }
    });
    return projectFamilies;
  };
  const formatProjectLocalSupporters = (data: any) => {
    if (data?.localSupportOption !== 1) return [];

    let projectLocalSupporters =
      data?.projectLocalSupporters?.map((item: any, index: number) => ({
        ...item,
        displayOrder: index,
        language: data?.projectLocalSupporters[0]?.language || null,
        deleted: item?.deleted ?? false
      })) || [];

    detailProject?.projectLocalSupporters?.forEach((item) => {
      if (!projectLocalSupporters.find((i: any) => i.id === item.id) && item.id) {
        projectLocalSupporters.push({ ...item, deleted: true });
      }
    });

    return projectLocalSupporters;
  };
  const formatProjectStakeholders = (data: any) => {
    const uniqueStakeholders = lodash.uniqWith(
      data?.projectStakeholders.filter((item: IProjectStakeholder) => item.stakeholderEmail),
      (accountA: IProjectStakeholder, accountB: IProjectStakeholder) =>
        accountA.stakeholderEmail === accountB.stakeholderEmail && !accountA.rejected && !accountB.rejected
    );

    let projectStakeholders = uniqueStakeholders.map((item: any, index: number) => ({
      ...item,
      displayOrder: index + 1,
      deleted: item?.deleted ?? false
    }));

    detailProject?.projectStakeholders?.forEach((item) => {
      if (!projectStakeholders.find((i) => i.id === item.id) && item.stakeholderEmail && item.id && item.stakeholderName !== user?.name) {
        projectStakeholders.push({ ...item, deleted: true });
      }
    });

    return projectStakeholders;
  };
  const formatProjectAttachments = (data: any) => {
    let projectAttachments = data?.projectAttachments
      ?.filter((file: any) => file.status === 'done')
      ?.map((file: any, index: number) => ({
        ...file,
        displayOrder: index,
        deleted: file?.deleted ?? false
      }));

    const defaultAttachments = detailProject?.attachments ?? detailProject?.projectAttachments;
    defaultAttachments?.forEach((item) => {
      if (!projectAttachments.find((i: any) => i.id === item.id) && item.id) {
        projectAttachments.push({ ...item, deleted: true });
      }
    });

    return projectAttachments;
  };
  const getPayload = (values: any, isDraft = true) => {
    const noteValue = values.steps[5]?.note;
    let command: any = {
      step: selectedStep,
      phoneNumber: '',
      requestContent: '',
      note: DataViewer.isEmptyHTML(noteValue) ? '' : noteValue,
      isDraft,
      requestTypeId: '',
      supportTypeId: '',
      visaCategoryId: values.steps[2]?.visaCategoryId,
      travelTypeId: values.steps[2]?.travelTypeId,
      goWithFamily:
        values.steps[1]?.goWithFamily === GO_WITH_FAMILY.YES ? true : values.steps[1]?.goWithFamily === GO_WITH_FAMILY.NO ? false : undefined,
      hasOtherStakeHolder:
        values.steps[4]?.hasOtherStakeHolder === HAS_OTHER_STAKE_HOLDER.YES
          ? true
          : values.steps[4]?.hasOtherStakeHolder === HAS_OTHER_STAKE_HOLDER.NO
            ? false
            : undefined,
      localSupportOption: values.steps[3]?.localSupportOption,
      projectApplicant: getProjectApplicant(values.steps[0]?.projectApplicant),
      projectFamilies: formatProjectFamilies(values.steps[1]),
      projectAssignmentInfo: values.steps[2]?.projectAssignmentInfo,
      projectLocalSupporters: formatProjectLocalSupporters(values.steps[3]),
      projectStakeholders: formatProjectStakeholders(values.steps[4]),
      projectAttachments: formatProjectAttachments(values.steps[5]),
      version: detailProject?.version || '',
      stepStatus: values.stepStatus
    };
    if (id) {
      command['id'] = id;
    }
    return {
      command: JSON.stringify(command)
    };
  };
  const onNext = () => {
    formContext.trigger(`steps[${selectedStep - 2}]`).then((isValid) => {
      const nexStep = selectedStep + 1;
      if (!isValid) {
        const controlFieldsStep = (formContext.control._fields.steps as AnyObject)[selectedStep - 2];
        const errorName = getFirstErrorName(formContext.formState.errors.steps as AnyObject[], {
          projectApplicant: Object.keys(controlFieldsStep?.projectApplicant ?? {}),
          projectFamilies: Object.keys(controlFieldsStep?.projectFamilies?.[0] ?? {})
        });
        formContext.setFocus(errorName);
        // Scroll to select field because setFocus on select not working
        const selectElemById = document.getElementById(errorName);
        selectElemById && selectElemById.scrollIntoView({ block: 'center' });
        return;
      }
      formContext.setValue('selectedStep', nexStep);
      formContext.setValue(`stepStatus[${selectedStep - 1}].value`, STEP_CRUMB_STATUS.COMPLETED);
    });
  };
  const onBack = () => {
    const backStep = selectedStep - 1;
    const isError = checkErrorStep(stepCrumbs[selectedStep - 1]);
    if (!isError) {
      formContext.setValue('selectedStep', backStep);
    }
  };
  return (
    <ScenarioLayout
      header={<Header step={convertStepForHeader(selectedStep)} onClick={onClick} />}
      title={
        id ? (
          <>
            <span className='title-24 text-negative'>{t('basic_information:isDraft')}</span> {detailProject?.code}
          </>
        ) : (
          t('project:add:title')
        )
      }
    >
      <StepCrumb data={stepCrumbs} />
      <div className='scenario-b-form-wrapper mt-[12px]'>{renderFormStep(selectedStep)}</div>
    </ScenarioLayout>
  );
};

export const useFormScenarioCContext = <S extends string>() => useFormContext<FormObserver<'scenario-c', S>>();
export default ScenarioC;
