/* eslint-disable @typescript-eslint/no-unused-vars */
import { Input, TooltipProps } from 'antd';
import React, { CSSProperties, ForwardedRef, forwardRef, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { REQUIRED_DOT } from '../../utils/constants/AppConstants';
import { IInputBaseProps } from '../../utils/interfaces/text-field';
import { ColorDefault } from '../../utils/themes/color';
import AppTooltip from '../app-tooltip/AppTooltip';
import { ErrorInput } from '../error-input/ErrorInput';

import './TextField.scss';

export type TextFieldProps = IInputBaseProps & {
  onBlur?: (value: any) => void;
  isOnChange?: boolean;
  isTrimValue?: boolean;
  tooltipProps?: TooltipProps & React.RefAttributes<unknown>;
  warning?: boolean;
  warningMsg?: string;
};
export const TextField = forwardRef((props: TextFieldProps, ref: ForwardedRef<any>) => {
  // props
  const {
    label,
    labelTx,
    required,
    placeholder,
    defaultValue,
    // labelPresets,
    placeholderTx,
    errorColorTheme,
    placeholderColor,
    errorBorderColor,
    onBlur,
    onFocus,
    onChange,
    onChangeText,
    watchChangeText,
    onSubmit,
    handleValue,
    name = '',
    disabled = false,
    error,
    heightInput = 38,
    bordered = true,
    className,
    multiple,
    fieldPlaceholderI18n,
    isOnChange,
    isTrimValue,
    isBaselineError,
    tooltipProps,
    warning,
    warningMsg,
    ...rest
  } = props;
  // state
  const [t] = useTranslation();
  const [value, setValue] = useState<string>('');
  // Label of text
  const labelText = useMemo(() => (labelTx && t(labelTx)) || label || undefined, [labelTx, label, t]);

  // Placeholder of text
  const placeHolderText = useMemo(
    () =>
      (placeholderTx && t(placeholderTx)) ||
      placeholder ||
      (fieldPlaceholderI18n && t('placeholder:text_box', { field: t(fieldPlaceholderI18n) })) ||
      undefined,
    [placeholderTx, t, placeholder, fieldPlaceholderI18n]
  );

  // Border color of input
  const borderColor = useMemo(() => {
    switch (true) {
      case !bordered:
        return 'transparent';
      case error !== undefined:
        return errorBorderColor || ColorDefault.negative;
      case warning:
        return ColorDefault.yellow70;
    }
  }, [bordered, error, errorBorderColor, warning]);

  // Input style
  const inputStyle: CSSProperties = {
    borderColor,
    height: heightInput
  };

  // Func strim value
  const strimValue = () => {
    const strimValue = value.trim();
    if (value && value !== strimValue) {
      setValue(strimValue);
      onChangeText && onChangeText(strimValue);
    }
  };

  // Func on input blur
  const _onBlur = (e: any) => {
    strimValue();
    onBlur && onBlur(e);
  };

  // effect
  useEffect(() => {
    setValue(defaultValue || '');
  }, [defaultValue]);

  const renderInput = () => {
    return (
      <Input
        ref={ref}
        disabled={disabled}
        className={`${className} input-style ${bordered && error !== undefined ? 'error' : ''} ${warning ? 'warning' : ''}`}
        bordered={false}
        style={inputStyle}
        placeholder={placeHolderText}
        value={value}
        onBlur={_onBlur}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const value = e.target.value;
          setValue(value);
          onChangeText && onChangeText(value);
        }}
        {...rest}
      />
    );
  };

  // render
  return (
    <div className='text-field-container'>
      {labelText && (
        <div className='label-container'>
          <div>{labelText ?? ''}</div>
          {required && (
            <div className='require' color={ColorDefault.negative}>
              {REQUIRED_DOT}
            </div>
          )}
        </div>
      )}
      {isBaselineError && <div className='h-[26px]' />}

      {tooltipProps ? (
        // If u want to used tooltip, do not remove div wrap of input
        // Issue tooltip and popover not work when disabled input existed on antd v5.6.0
        // https://github.com/ant-design/ant-design/issues/30191#issuecomment-821827057
        <AppTooltip trigger={['focus']} title={value} children={<div>{renderInput()}</div>} {...tooltipProps} />
      ) : (
        renderInput()
      )}

      {(error !== undefined || isBaselineError) && (
        <ErrorInput
          classNameLabel={isBaselineError ? 'truncate' : ''}
          className={isBaselineError ? '!mt-0 h-[26px]' : ''}
          error={error ?? ''}
          errorColorTheme={errorColorTheme}
        />
      )}
      {warning && <ErrorInput error={warningMsg ?? ''} errorColorTheme='warning' />}
    </div>
  );
});
