import { useTranslation } from 'react-i18next';

import { StepsProcess } from '@/components/step-process/StepsProcess';

import useFetch from '@/hooks/useFetch';

import { Ii18n } from '@/utils/interfaces/i18n';

import { IStatus } from '../../project-list-v2/models';
import { IDataDetail } from '../utils';

interface ProcessProps {
  data: IDataDetail;
}
const ProcessDetail = ({ data }: ProcessProps) => {
  const { t }: Ii18n = useTranslation();
  const { data: listStatus } = useFetch<IStatus[]>('/prj/status', 'GET');
  return (
    <div className='flex flex-col rounded-[10px] bg-[#FFF] border-gray2 border-[1px] border-solid'>
      <div className='px-4 pt-4 pb-2 text-[#12212E] text-[18px] font-semibold leading-[25px]'>{t('project_detail:procedual_process')}</div>
      <div className='project-detail-process px-4 pt-4 pb-[30px]'>
        <StepsProcess stepData={data.projectProcesses} listStatus={listStatus || []} projectActive={data.active} />
      </div>
    </div>
  );
};

export default ProcessDetail;
