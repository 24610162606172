import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Divider, Select, SelectProps, Spin } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import classNames from 'classnames';
import { isNil } from 'lodash';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { CSSProperties, ReactNode, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Control, FormProvider, useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import useTabIndexAppend from '@/hooks/useTabIndexAppend';

import CloseIcon from '../../assets/icons/CloseIcon.svg';
import { useMessageYupTranslation } from '../../hooks/useI18n';
import { REQUIRED_DOT } from '../../utils/constants/AppConstants';
import { convertFullWidthToHalfWidth } from '../../utils/helpers/globalHelper';
import { IInputBaseProps } from '../../utils/interfaces/text-field';
import { ColorDefault } from '../../utils/themes/color';
import AppTooltip from '../app-tooltip/AppTooltip';
import { BaseButton } from '../base-button/BaseButton';
import { ErrorInput } from '../error-input/ErrorInput';
import { FormInput } from '../form-input/FormInput';
import DropdownIcon from './../../assets/icons/base-filter/filter-suffix-icon.svg';

import './FormInputSelect.scss';

export type FormInputSelectProps = IInputBaseProps &
  SelectProps & {
    name: string;
    control?: Control;
    rightChildren?: ReactNode;
    isAddItem?: boolean;
    searchMaxLength?: number;
    isConfirmPopup?: boolean;
    isColumn?: boolean;
    maxlengthAddItem?: number;
    customOption?: boolean;
    fieldName?: string;
    customAddItemError?: string;
    loadDataOptions?: boolean;
    defaultOption?: DefaultOptionType;
    refreshSearchWhenOpen?: boolean;
    totalCount?: number;
    overflowTooltip?: string;
    handleSearchByApi?: (search: string) => void;
    handleOnChangeAddInput?: (val: string) => void;
    handleSelect?: (item: string | number) => void | Promise<boolean>;
    handleAddItem?: (item: string) => void;
    onPopupScroll?: React.UIEventHandler<HTMLDivElement>;
    selectedItemDisplay?: (val: string | number | null, options: DefaultOptionType | DefaultOptionType[]) => ReactNode;
  };
export const FormInputSelect = forwardRef((props: FormInputSelectProps, ref) => {
  // props
  const {
    placeholder,
    placeholderTx,
    name,
    labelTx,
    defaultValue,
    label,
    required,
    errorColorTheme,
    disabled,
    heightInput = 38,
    errorBorderColor,
    rightChildren,
    options,
    className,
    fieldPlaceholderI18n,
    isAddItem = false,
    allowClear = true,
    searchMaxLength,
    isConfirmPopup = false,
    isColumn = false,
    maxlengthAddItem,
    customOption = false,
    fieldName,
    isBaselineError,
    customAddItemError,
    type,
    loadDataOptions = true,
    loading,
    defaultOption,
    refreshSearchWhenOpen = false,
    totalCount,
    showSearch = true,
    value,
    overflowTooltip,
    onBlur,
    onSubmit,
    onChange,
    handleAddItem,
    handleSelect,
    handleOnChangeAddInput,
    handleSearchByApi,
    onPopupScroll,
    selectedItemDisplay,
    control,
    ...rest
  } = props;

  // update tabindex

  // state
  const [focused, setFocused] = useState<boolean>(false);
  const [hovered, setHovered] = useState<boolean>(false);
  const [loadingOptions, setLoadingOptions] = useState<boolean>(true);
  const [isOpenDropdown, setIsOpenDropdown] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [filteredOptions, setFilteredOptions] = useState<DefaultOptionType[]>(options || []);
  const [isScrollLoadMore, setIsScrollLoadMore] = useState<boolean>(false);
  const targetElement = useRef<any>(null);

  const formSelectRef = useRef<any>(null);
  const containerRef = useRef<any>(null);
  useTabIndexAppend(['.ant-select'], containerRef);

  const [t] = useTranslation();
  const {
    field,
    fieldState: { error }
  } = useController({
    name: name as string,
    defaultValue,
    control
  });

  const methodFormAddItem = useForm({
    mode: 'all',
    shouldUnregister: false,
    resolver: yupResolver(
      yup.object().shape({
        name: yup
          .string()
          .required(t('common:MSG_001_textbox', { field: fieldName }) || '')
          .max(128, t('common:MSG_028', { field: fieldName || '', maxLength: 128 }) || '')
      })
    ),
    defaultValues: {
      name: null
    }
  });

  const methodFormSearch = useForm({
    mode: 'all',
    shouldUnregister: false,
    defaultValues: {
      search: ''
    }
  });

  const msgError = useMessageYupTranslation(error?.message);
  const labelText = useMemo(() => (labelTx && t(labelTx)) || label || undefined, [labelTx, label, t]);
  const placeHolderText = useMemo(() => {
    // TODO: Refactor
    // if (loadingOptions && field.value) {
    //   const option = filteredOptions.find((f: any) => f.value === field.value);
    //   return option?.label;
    // }
    return (
      (placeholderTx && t(placeholderTx)) ||
      placeholder ||
      (fieldPlaceholderI18n && t('placeholder:select', { field: t(fieldPlaceholderI18n) })) ||
      undefined
    );
  }, [placeholderTx, t, placeholder, fieldPlaceholderI18n, loadingOptions, field.value]);

  // style
  const borderColor = useMemo(() => {
    switch (true) {
      case disabled:
        return ColorDefault.gray2;
      case error !== undefined:
        return errorBorderColor || ColorDefault.negative;
      case focused:
        return ColorDefault.action;
      case hovered:
        return ColorDefault.gray4;
      default:
        return ColorDefault.gray3;
    }
  }, [disabled, error, errorBorderColor, hovered, focused]);
  const containerStyle: CSSProperties = {
    borderColor,
    backgroundColor: disabled ? ColorDefault.gray1 : ColorDefault.white
  };
  const selectStyle: CSSProperties = {
    minHeight: heightInput,
    height: heightInput
  };
  const suffixIcon = <DropdownIcon onClick={() => setIsOpenDropdown(!isOpenDropdown)} />;

  /**
   * Handle ItemName selection
   */

  // func
  const _onFocus = () => {
    setFocused(true);
  };
  const _onBlur = () => {
    setFocused(false);
  };
  const _onMouseOver = () => {
    setHovered(true);
  };
  const _onMouseLeave = () => {
    setHovered(false);
  };

  const mergedOptions = useMemo(() => {
    return options ? options.reduce((acc: any, item) => acc.concat(item.options), []) : [];
  }, [options]);

  const addItemClick = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    methodFormAddItem.handleSubmit((values: any) => {
      handleAddItem && handleAddItem(values?.name || '');
    })();
  };

  const renderSelectedLabel = (val: string | number | null, options: DefaultOptionType | DefaultOptionType[]) => {
    if (selectedItemDisplay) {
      const inputElm = containerRef?.current?.querySelector('.ant-select .ant-select-selector .ant-select-selection-item');
      if (!inputElm) return;
      setTimeout(() => {
        inputElm.textContent = selectedItemDisplay(val, options);
      }, 0);
    }
  };

  const onHandleSelect = async (val: any, options: DefaultOptionType | DefaultOptionType[]) => {
    if (props && props.mode && props.mode === 'multiple') return;
    const selectedValue = val;
    if (selectedValue === field.value) {
      return;
    }

    if (handleSelect && isConfirmPopup) {
      const isConfirmChange = await handleSelect(selectedValue);
      if (isConfirmChange) field.onChange(selectedValue);
      return;
    }

    handleSelect?.(selectedValue);
    field.onChange(selectedValue ?? null);
    onChange && onChange(selectedValue ?? null);
  };

  const handleOnChange = (val: string | number | null, options: DefaultOptionType | DefaultOptionType[]) => {
    onHandleSelect(val, options);
  };

  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    renderSelectedLabel(field.value, filteredOptions.find((i) => i.value === field.value) || []);
  }, [field.value]);

  const customOptions = useMemo(() => {
    return filteredOptions.map((option: DefaultOptionType, index: number) => {
      const optionIndex = filteredOptions.findIndex((f: DefaultOptionType) => defaultOption && f.value === defaultOption.value);
      return {
        ...option,
        label: (
          <>
            {customOption && (
              <div className='beauty-select-option'>
                <div className={`select-item ${option.value}`}>{optionIndex === index ? defaultOption?.label : option.label}</div>
              </div>
            )}
            {!customOption && (
              <div className='flex items-center base-input-select-option'>
                <AppTooltip title={optionIndex === index ? defaultOption?.label : option.label} overflow={overflowTooltip}>
                  {option.imgUrl && (
                    <div className='flex items-center'>
                      {option?.FlagIcon && <option.FlagIcon className='mr-[11px]' alt={String(option.label)} />}
                      <span className='w-full'>{optionIndex === index ? defaultOption?.label : option.label}</span>
                    </div>
                  )}
                  {!option.imgUrl && <span className='w-full'>{optionIndex === index ? defaultOption?.label : option.label}</span>}
                </AppTooltip>
              </div>
            )}
          </>
        )
      };
    }) as DefaultOptionType[];
  }, [filteredOptions, customOption, defaultOption]);

  useEffect(() => {
    (async () => {
      setLoadingOptions(true);
      if (options && options.length > 0) {
        if (defaultOption && defaultOption.value) {
          const findOptionIndex = options.findIndex((op: DefaultOptionType) => op.value === defaultOption.value);
          if (findOptionIndex === -1) {
            const newOptions = [defaultOption, ...options];
            setFilteredOptions(newOptions);
          } else {
            const newOptions = [...options];
            newOptions[findOptionIndex] = defaultOption;
            setFilteredOptions(newOptions);
          }
        } else {
          setFilteredOptions(options);
        }
        setTimeout(() => {
          if (targetElement?.current?.scrollHeight && isScrollLoadMore) {
            const scrollHeight = targetElement?.current?.scrollHeight || 1000;
            targetElement?.current?.scrollTo(0, scrollHeight - 1000);
          }
          setLoadingOptions(false);
        }, 1000);
      } else {
        setFilteredOptions([]);
        setTimeout(() => {
          setLoadingOptions(false);
        }, 1000);
      }
    })();
  }, [options, defaultOption]);

  const selectScrollBar = document.querySelector<HTMLElement>('.rc-virtual-list-scrollbar');

  useEffect(() => {
    if (selectScrollBar) {
      if (loadDataOptions) {
        selectScrollBar.style.display = 'none';
      } else {
        selectScrollBar.style.display = 'block';
      }
    }
  }, [loadingOptions, selectScrollBar]);

  const handleClearValue = async () => {
    if (handleSelect && isConfirmPopup) {
      const isConfirmChange = await handleSelect('');
      if (isConfirmChange) field.onChange(null);
      return;
    }
    field.onChange(null);
  };

  // useEffect(() => {
  //   if (props.mode === 'multiple') {
  //     setDefaultSelectedValue([]);
  //   } else {
  //     setDefaultSelectedValue(null);
  //   }
  // }, [props.mode]);

  useImperativeHandle(ref, () => ({
    clearFormAddNew() {
      methodFormAddItem.reset({
        name: null
      });
      methodFormAddItem.clearErrors();
    },
    setErrorFormAddNew(error: string) {
      methodFormAddItem.setError('name', {
        type: 'custom',
        message: error
      });
    },
    setOpenDropdown: (open: boolean) => {
      setIsOpenDropdown(open);
    }
  }));

  // Tag render
  const tagRender = (props: CustomTagProps) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <div className='multiple-item-selection flex items-center justify-between gap-[8px] ' onMouseDown={onPreventMouseDown}>
        <div className='max-w-[200px] truncate w-fit'>{label}</div>

        <CloseIcon
          width={18}
          height={18}
          onClick={() => {
            closable && onClose();
          }}
        />
      </div>
    );
  };

  // Func Dropdown render
  const _dropdownRender = (menu: ReactNode) => {
    return (
      <div>
        {(options && options?.length > 10) || mergedOptions.length > 10 || (totalCount && totalCount > 10) ? (
          <div className='form-input-select-container' style={{ display: 'flex', alignItems: 'center', padding: 8 }}>
            <FormProvider {...methodFormSearch}>
              <FormInput
                name='search'
                className='form-input-select-search'
                placeholder={t('common:input_search_placeholder') as string}
                prefix={<SearchOutlined />}
                style={{ flex: 'auto', height: '40px', borderRadius: '6px' }}
                debounceTime={1000}
                handleOnChange={(keyword: string) => {
                  setIsScrollLoadMore(false);
                  setSearchValue(keyword);
                  handleSearchByApi && setLoadingOptions(true);
                  handleSearchByApi && handleSearchByApi(keyword);
                }}
                defaultValue={methodFormSearch.getValues('search') || undefined}
              />
            </FormProvider>
          </div>
        ) : (
          <></>
        )}

        <>
          {(!loadingOptions || isScrollLoadMore) && (
            <>
              {menu}
              {!!isAddItem && !loadingOptions && (
                <div className='w-full'>
                  <Divider style={{ margin: '8px 0' }} />
                  <div className='grid grid-flow-col grid-cols-[1fr_100px] gap-[16px] p-[8px]'>
                    <div className='flex items-baseline input-add'>
                      <FormProvider {...methodFormAddItem}>
                        <FormInput
                          name='name'
                          defaultValue={methodFormAddItem.getValues('name') || undefined}
                          isOnChange
                          className='w-full font-[400] h-[50px] text-[16px] input-add-new-item min-w-[313px]'
                          placeholder={t('placeholder:text_box', { field: fieldName }) as string}
                          errorText={customAddItemError}
                          handleOnChange={handleOnChangeAddInput}
                        />
                      </FormProvider>
                    </div>
                    <div className='w-[100px] flex items-baseline no-cursor-btn-disabled'>
                      <BaseButton
                        className='add-btn font-[700] text-[16px] !bg-white no-pointer-disabled'
                        type='text'
                        icon={<PlusOutlined />}
                        disabled={!methodFormAddItem.formState.isValid || !!customAddItemError}
                        onClick={addItemClick}
                      >
                        {t('button:add_new')}
                      </BaseButton>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          {loadingOptions && (
            <div className='p-5 flex items-center justify-center'>
              <Spin />
            </div>
          )}
        </>
      </div>
    );
  };

  const onScroll = (e: any) => {
    setIsOpenDropdown(false);
  };

  useEffect(() => {
    const elementsScroll = document.querySelectorAll('.container-scroll');
    for (let index = 0; index < elementsScroll.length; index++) {
      const elementScroll = elementsScroll[index];
      elementScroll.removeEventListener('scroll', onScroll);
      elementScroll.addEventListener('scroll', onScroll, { passive: true });
    }

    return () => {
      for (let index = 0; index < elementsScroll.length; index++) {
        const elementScroll = elementsScroll[index];
        elementScroll?.removeEventListener('scroll', onScroll);
      }
    };
  }, []);

  const notFoundContent = useMemo(() => {
    if (!loadingOptions) {
      return <div className='p-5 text-center'>{t('common:MSG_038')}</div>;
    }
    return <div className='p-5 text-center'></div>;
  }, [loadingOptions, t]);

  const formSelectValue = useMemo(() => {
    if (
      !isNil(field.value) &&
      filteredOptions &&
      filteredOptions.find(
        (op: DefaultOptionType) =>
          op.value === field.value ||
          field.value.includes(op.value) ||
          (op.options && op.options.find((o: any) => o.value === field.value || field.value.includes(o.value)))
      )
    ) {
      return field.value;
    } else {
      return defaultValue ?? null;
    }
  }, [field.value, filteredOptions, defaultValue]);

  function onSearch(val: any) {
    setSearchValue(val);
  }

  // render
  return (
    <div className={classNames('common-input-select-wrapper', isColumn ? 'flex select-container' : 'select-container')}>
      {labelText && (
        <div className={classNames('label-container mr-2', isColumn ? 'flex items-center !mb-0 max-h-[38px]' : '')}>
          <div>{labelText ?? ''}</div>
          {required && (
            <div className='require' color={ColorDefault.negative}>
              {REQUIRED_DOT}
            </div>
          )}
        </div>
      )}
      {isBaselineError && <div className='h-[26px]' />}
      <div>
        <div
          className={`input-container flex flex-nowrap ${disabled ? 'select-cursor-not-allowed' : 'select-cursor-pointer'} ${isOpenDropdown && !disabled ? 'focused' : ''}`}
          onMouseOver={_onMouseOver}
          onMouseLeave={_onMouseLeave}
          style={containerStyle}
          ref={containerRef}
        >
          <Select
            listHeight={250}
            notFoundContent={notFoundContent}
            suffixIcon={suffixIcon}
            ref={(el: any) => {
              formSelectRef.current = el;
            }}
            open={isOpenDropdown}
            tagRender={props?.mode === 'multiple' ? tagRender : undefined}
            showSearch={showSearch}
            placeholder={placeHolderText}
            maxTagPlaceholder
            bordered={false}
            disabled={disabled}
            value={formSelectValue}
            loading={loadingOptions}
            style={selectStyle}
            popupClassName={isAddItem ? '!min-w-[445px]' : ''}
            className={`${className} ${customOption && 'custom-option beauty-select'} select-input ${rightChildren && '!w-[calc(100%-199px)]'}`}
            placement='bottomLeft'
            size='large'
            onDropdownVisibleChange={(open: boolean) => {
              setIsOpenDropdown(open);
              if (open) {
                if (searchValue || refreshSearchWhenOpen) {
                  methodFormSearch.reset({
                    search: ''
                  });
                  setSearchValue('');
                  handleSearchByApi && handleSearchByApi('');
                }
                methodFormAddItem.reset({
                  name: null
                });
                methodFormAddItem.clearErrors();
              } else {
                setIsScrollLoadMore(false);
              }
            }}
            onBlur={_onBlur}
            onFocus={_onFocus}
            onChange={handleOnChange}
            onSearch={onSearch}
            allowClear={allowClear}
            onClear={handleClearValue}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                e.stopPropagation();
                return false;
              }
            }}
            // dropdownRender={(menu) => _dropdownRender(menu)}
            searchValue={searchValue}
            filterOption={(input, option) => {
              const currentOption = filteredOptions.find((f: DefaultOptionType) => option && (f.value === option.value || f.value === option.name));

              if (currentOption && !loadingOptions) {
                const labelToCheck = currentOption?.options?.length > 0 ? (option?.label as string) : (currentOption.label as string);
                const formattedLabel = convertFullWidthToHalfWidth(labelToCheck) ?? '';
                const lowercaseInput = input.toLowerCase();
                const formattedLabelLowercase = formattedLabel.toLowerCase();
                return formattedLabelLowercase.includes(convertFullWidthToHalfWidth(lowercaseInput));
              }
              return true;
            }}
            onPopupScroll={(e: any) => {
              setIsScrollLoadMore(true);
              targetElement.current = e.target;
              if (selectScrollBar && !loadingOptions) {
                selectScrollBar.style.display = 'block';
              }
              if (!loadingOptions) {
                onPopupScroll && onPopupScroll(e);
              }
            }}
            options={customOptions}
            {...rest}
          />
          {rightChildren && <div className={`right-children filter-right-children grow-0 ${disabled && 'cursor-not-allowed'}`}>{rightChildren}</div>}
        </div>
        {(error !== undefined || isBaselineError) && (
          <ErrorInput
            classNameLabel={isBaselineError ? 'truncate' : ''}
            className={isBaselineError ? '!mt-0 h-[26px]' : ''}
            error={msgError ?? ''}
            errorColorTheme={errorColorTheme}
          />
        )}
      </div>
    </div>
  );
});
