import { IProjectMemoRes } from '../model';

export const DEFAULT_DETAIL_PROJECT_MEMO: IProjectMemoRes = {
  id: '',
  projectId: '',
  emoNumber: '',
  supportTypeId: '',
  folder: '',
  memo: '',
  version: '',
  location: ''
};
