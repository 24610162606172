import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BaseButton } from '@/components/base-button/BaseButton';
import { FormInputSelect } from '@/components/form-input-select/FormInputSelect';

import useAuthorization from '@/hooks/useAuthorization';

import { Ii18n } from '@/utils/interfaces/i18n';

import { RESPONSE_STATUS, SUBMISSION_MOT, SUBMISSION_STATUS } from '../constants';

interface IFilterProps {
  onSubmit: (values: FieldValues) => void;
  onReset?: () => void;
  defaultValues?: any;
}

const Filter = ({ onSubmit, onReset, defaultValues }: IFilterProps) => {
  const form = useForm({ defaultValues });
  const { t }: Ii18n = useTranslation();
  const { isInternalRole } = useAuthorization();

  const handleReset = () => {
    form.reset({
      submissionToMot: null,
      submissionStatus: null,
      comunicationStatus: null
    });
  };

  const { submissionToMot, submissionStatus, comunicationStatus } = form.watch();
  const disableResetBtn = () => {
    return !submissionToMot && !submissionStatus && !comunicationStatus;
  };

  const submissionMOTOptions = [
    {
      label: t('required_documents:need'),
      value: SUBMISSION_MOT.need,
      id: SUBMISSION_MOT.need
    },
    {
      label: t('required_documents:unnecessary'),
      value: SUBMISSION_MOT.unnecessary,
      id: SUBMISSION_MOT.unnecessary
    }
  ];

  const submissionStatusOptions = [
    {
      label: t('required_documents:not_submitted'),
      value: SUBMISSION_STATUS.notSubmitted,
      id: SUBMISSION_STATUS.notSubmitted
    },
    {
      label: t('required_documents:submitted'),
      value: SUBMISSION_STATUS.submitted,
      id: SUBMISSION_STATUS.submitted
    }
  ];

  const responseStatusOptions = [
    {
      label: t('required_documents:not_compatible'),
      value: RESPONSE_STATUS.notCompatible,
      id: RESPONSE_STATUS.notCompatible
    },
    {
      label: t('required_documents:compatible'),
      value: RESPONSE_STATUS.compatible,
      id: RESPONSE_STATUS.compatible
    }
  ];

  return (
    <FormProvider {...form}>
      <div className='p-4 w-[440px]'>
        <h3 className='text-xl font-semibold'>{t('project:list:filter_btn_label')}</h3>
        <div className='item mb-2'>
          <FormInputSelect
            control={form.control}
            name='submissionToMot'
            labelTx={t('required_documents:submission_mot')}
            placeholder={t('placeholder:select', { field: t('required_documents:submission_mot') })}
            options={submissionMOTOptions}
          />
        </div>
        <div className='item mb-2'>
          <FormInputSelect
            control={form.control}
            name='submissionStatus'
            labelTx={t('required_documents:submission_status')}
            options={submissionStatusOptions}
            placeholder={t('placeholder:select', { field: t('required_documents:submission_status') })}
          />
        </div>
        {isInternalRole && (
          <div className='item mb-2'>
            <FormInputSelect
              control={form.control}
              name='comunicationStatus'
              labelTx={t('required_documents:response_status')}
              placeholder={t('placeholder:select', { field: t('required_documents:response_status') })}
              options={responseStatusOptions}
            />
          </div>
        )}
        <div className='flex justify-end space-x-2'>
          <div className='w-[120px]'>
            <BaseButton onClick={handleReset} type='tertiary' size='medium' disabled={disableResetBtn()}>
              {t('project:filter:btn_reset')}
            </BaseButton>
          </div>
          <div className='w-[120px]'>
            <BaseButton onClick={form.handleSubmit(onSubmit)} type='primary' size='medium'>
              <span>{t('project:filter:btn_aply')} </span>
            </BaseButton>
          </div>
        </div>
      </div>
    </FormProvider>
  );
};

export default Filter;
