import { useAppDispatch } from '@/hooks';
import CaretDownLine from '@/layouts/assets/icons/CaretDownLine.svg';
import Database from '@/layouts/assets/icons/Database.svg';
import Earphone from '@/layouts/assets/icons/Earphone.svg';
import FileText from '@/layouts/assets/icons/FileText.svg';
import ListChecks from '@/layouts/assets/icons/ListChecks.svg';
import ListDashes from '@/layouts/assets/icons/ListDashes.svg';
import SignOut from '@/layouts/assets/icons/SignOut.svg';
import UserList from '@/layouts/assets/icons/UserList.svg';
import LogoIcon from '@/layouts/assets/icons/logo.svg?react';
import LogoIconNoText from '@/layouts/assets/icons/logoNoText.svg?react';
import Icon from '@ant-design/icons';
import { Button, Layout, Menu } from 'antd';
import { ItemType, MenuItemType } from 'antd/es/menu/hooks/useItems';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';

import { TYPE_ICON as TYPE_POPUP, showBasePopup } from '@/components/base-popup/BasePopup';

import { setLoadingPage } from '@/redux/globalReducer';

import useAuthorization from '@/hooks/useAuthorization';
import useCustomerRequest from '@/hooks/useCustomerRequest';
import useMiniWindow from '@hooks/useMiniWindow';

import { SESSION_STORAGE } from '@/utils/constants';
import {
  ALL_ROLE, // ALL_TASK_PROJECT_LIST_ROLE,
  EXTERNAL_ADMIN,
  EXTERNAL_GENERAL_USER, // EXTERNAL_GENERAL_USER,
  INTERNAL_ADMIN,
  INTERNAL_GENERAL_USER,
  LOCALSTORAGE
} from '@/utils/constants/AppConstants';
import {
  ACCOUNT_MASTER_LIST_URL,
  ADD_TASK_OF_PROJECT_URL,
  ALL_TASK_URL,
  ANNOUNCEMENT_LIST_URL,
  APPROVE_REJECT_ACCOUNT_MASTER_URL,
  CREATE_ACCOUNT_MASTER_URL,
  CREATE_ANNOUNCEMENT_URL,
  CREATE_PROJECT_INQUIRY_URL,
  CREATE_PROJECT_URL,
  CREATE_TEMPLATE_URL,
  DOCUMENT_LIST_URL,
  EDIT_ANNOUNCEMENT_URL,
  EDIT_PROJECT_BASIC_INFO_URL,
  EDIT_PROJECT_URL,
  EDIT_TASK_OF_PROJECT_URL,
  EDIT_TEMPLATE_URL,
  INQUIRY_URL,
  MASTER_LIST_ITEM_DOCUMENT_URL,
  MASTER_LIST_URL,
  PHRASE_LIST_URL,
  PROJECT_BASIC_INFO_URL,
  PROJECT_FILE_LIST_URL,
  PROJECT_LIST_URL,
  PROJECT_MEMO_URL,
  PROJECT_QUESTION_DETAIL_URL,
  PROJECT_QUESTION_LIST_URL,
  STAKEHOLDER_UPDATE_URL,
  STAKEHOLDER_VIEW_URL,
  TASK_LIST_URL,
  TEMPLATE_LIST_URL,
  UPDATE_ACCOUNT_MASTER_URL,
  VIEW_ANNOUNCEMENT_URL,
  VIEW_PROJECT_URL,
  VIEW_TASK_OF_PROJECT_URL,
  VIEW_TEMPLATE_URL
} from '@/utils/constants/RouteContants';
import { cleanLocalCache } from '@/utils/helpers/globalHelper';

import CaretCircleLeft from '../assets/icons/CaretCircleLeft.svg';
import CaretCircleRight from '../assets/icons/CaretCircleRight.svg';

import './Sidebar.scss';

const { Sider } = Layout;

export interface INavItem {
  key: string;
  icon: any;
  label: string;
  role: string[];
  link?: string;
  children?: INavItem[];
  className?: string;
  onTitleClick?: Function;
  onClick?: Function;
  tabIndex?: number;
  onKeyPress?: Function;
}

const PROJECT_ITEM = 'PROJECT_ITEM';
const TEMPLATE_ITEM = 'TEMPLATE_ITEM';
const MASTER_ITEM = 'MASTER_ITEM';
const ACCOUNT_MASTER_ITEM = 'ACCOUNT_MASTER_ITEM';
const MASTER_LIST_ITEM = 'MASTER_LIST_ITEM'; // 企業マスター管理
const MASTER_LIST_ITEM_DOCUMENT = 'MASTER_LIST_ITEM_DOCUMENT'; // 書類マスター管理
const MASTER_LIST_ITEM_PHRASE = 'MASTER_LIST_ITEM_PHRASE'; // 書類マスター管理
const ALL_TASK_PROJECT_ITEM = 'ALL_TASK_PROJECT_ITEM';
const TASK_ITEM = 'TASK_ITEM';
const ANNOUNCEMENT_ITEM = 'ANNOUNCEMENT_ITEM';
const INQUIRY_ITEM = 'INQUIRY_ITEM';

// for project menu
const PROJECT_DETAILS = 'PROJECT_DETAILS';
const QUESTIONNAIRE = 'QUESTIONNAIRE';
const RELATED_PARTY_SETTINGS = 'RELATED_PARTY_SETTINGS';
const FILE_LIST = 'FILE_LIST';
const REQUIRED_DOCUMENT_LIST = 'REQUIRED_DOCUMENT_LIST';
const APPLICATION_INFORMATION = 'APPLICATION_INFORMATION';
const PROJECT_MEMO = 'PROJECT_MEMO';
const CONTACT_US = 'CONTACT_US';

const MAPPING_MENU_ITEM_TO_URL: { [key: string]: string[] } = {
  [PROJECT_ITEM]: [PROJECT_LIST_URL, CREATE_PROJECT_URL, EDIT_PROJECT_URL('')],
  [TEMPLATE_ITEM]: [TEMPLATE_LIST_URL, CREATE_TEMPLATE_URL, VIEW_TEMPLATE_URL(''), EDIT_TEMPLATE_URL('')],
  [ACCOUNT_MASTER_ITEM]: [ACCOUNT_MASTER_LIST_URL, CREATE_ACCOUNT_MASTER_URL, APPROVE_REJECT_ACCOUNT_MASTER_URL(''), UPDATE_ACCOUNT_MASTER_URL('')],
  [MASTER_LIST_ITEM]: [MASTER_LIST_URL],
  [TASK_ITEM]: [TASK_LIST_URL],
  [MASTER_LIST_ITEM_DOCUMENT]: [MASTER_LIST_ITEM_DOCUMENT_URL],
  [MASTER_LIST_ITEM_PHRASE]: [PHRASE_LIST_URL],
  [ANNOUNCEMENT_ITEM]: [ANNOUNCEMENT_LIST_URL(''), VIEW_ANNOUNCEMENT_URL('', '')],
  [QUESTIONNAIRE]: [PROJECT_QUESTION_LIST_URL(''), PROJECT_QUESTION_DETAIL_URL('', '')],
  [ALL_TASK_PROJECT_ITEM]: [ALL_TASK_URL],
  [INQUIRY_ITEM]: [INQUIRY_URL]
};

const Sidebar = ({ toggleSider, setCollapsed }: { toggleSider: () => void; setCollapsed: Function }) => {
  const [t] = useTranslation();
  const statusCollapsed = JSON.parse(localStorage.getItem('statusCollapsed') as string) || false;
  const role = useAuthorization().getUserRole();

  // Filter menu items based on the role
  const navigate = useNavigate();
  const auth = useAuth();
  const { open: openBasicInfo } = useMiniWindow();
  const { open: openDocumentList } = useMiniWindow();

  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const { pathname } = useLocation();
  const { handelTurnOffPopup } = useCustomerRequest();
  const dispatch = useAppDispatch();

  const getConditionalClassName = (keyItem: string) => {
    return `${selectedItems[0] === keyItem && 'active-item'}`;
  };

  const matchEditProject = useMatch(EDIT_PROJECT_URL(':id'));
  const matchViewProject = useMatch(VIEW_PROJECT_URL(':id'));
  const matchListAnnouncement = useMatch(ANNOUNCEMENT_LIST_URL(':id'));
  const matchCreateAnnouncement = useMatch(CREATE_ANNOUNCEMENT_URL(':id'));
  const matchBasicInfo = useMatch(PROJECT_BASIC_INFO_URL(':id'));
  const matchEditBasicInfo = useMatch(EDIT_PROJECT_BASIC_INFO_URL(':id'));
  const matchViewAnnouncement = useMatch(VIEW_ANNOUNCEMENT_URL(':id', ':announcementId'));
  const matchEditAnnouncement = useMatch(EDIT_ANNOUNCEMENT_URL(':id', ':announcementId'));
  const matchFileList = useMatch(PROJECT_FILE_LIST_URL(':id'));
  const matchQuestionList = useMatch(PROJECT_QUESTION_LIST_URL(':id'));
  const matchQuestionDetail = useMatch(PROJECT_QUESTION_DETAIL_URL(':id', ':formId'));
  const matchCreateInquiry = useMatch(CREATE_PROJECT_INQUIRY_URL(':id'));
  const matchStakeholderView = useMatch(STAKEHOLDER_VIEW_URL(':id'));
  const matchStakeholderUpdate = useMatch(STAKEHOLDER_UPDATE_URL(':id'));
  const matchDocumentList = useMatch(PROJECT_BASIC_INFO_URL(':id'));
  const matchEditDocumentList = useMatch(EDIT_PROJECT_BASIC_INFO_URL(':id'));
  const matchTaskAdd = useMatch(ADD_TASK_OF_PROJECT_URL(':id'));
  const matchTaskView = useMatch(`/${VIEW_TASK_OF_PROJECT_URL(':id', ':idTask')}`);
  const matchTaskEdit = useMatch(`/${EDIT_TASK_OF_PROJECT_URL(':id', ':idTask')}`);

  const projectId =
    matchViewProject?.params?.id ??
    matchEditProject?.params?.id ??
    matchListAnnouncement?.params?.id ??
    matchCreateAnnouncement?.params?.id ??
    matchFileList?.params?.id ??
    matchQuestionList?.params?.id ??
    matchQuestionDetail?.params?.id ??
    matchBasicInfo?.params?.id ??
    matchEditBasicInfo?.params?.id ??
    matchCreateInquiry?.params?.id ??
    matchViewAnnouncement?.params?.id ??
    matchEditAnnouncement?.params?.id ??
    matchStakeholderView?.params?.id ??
    matchStakeholderUpdate?.params?.id ??
    matchDocumentList?.params?.id ??
    matchEditDocumentList?.params?.id ??
    matchTaskAdd?.params?.id ??
    matchTaskView?.params?.id ??
    matchTaskEdit?.params?.id ??
    '';
  const redirectToViewProject = () => {
    if (!projectId) return;
    navigate(VIEW_PROJECT_URL(projectId));
  };
  const redirectToInquiry = () => {
    if (!projectId) return;
    navigate(CREATE_PROJECT_INQUIRY_URL(projectId));
  };
  const showSecondaryMenu =
    matchBasicInfo ||
    matchEditBasicInfo ||
    matchViewProject ||
    matchListAnnouncement ||
    matchViewAnnouncement ||
    matchCreateAnnouncement ||
    matchEditAnnouncement ||
    matchFileList ||
    matchQuestionList ||
    matchCreateInquiry ||
    matchStakeholderView ||
    matchStakeholderUpdate ||
    matchDocumentList ||
    matchEditDocumentList ||
    matchTaskAdd ||
    matchTaskView ||
    matchTaskEdit ||
    matchQuestionDetail;
  const projectDetailMenu: INavItem[] = [
    {
      key: PROJECT_DETAILS,
      icon: null,
      label: t('sider:project_details'),
      role: ALL_ROLE,
      onClick: redirectToViewProject,
      className: `project-menu-item ${matchViewProject || matchListAnnouncement || matchCreateAnnouncement || matchViewAnnouncement || matchEditAnnouncement || matchTaskAdd || matchTaskView || matchTaskEdit ? 'active' : ''}`,
      tabIndex: 0
    },
    {
      key: QUESTIONNAIRE,
      icon: null,
      label: t('sider:questionnaire'),
      role: ALL_ROLE,
      onClick: () => {
        navigate(PROJECT_QUESTION_LIST_URL(projectId));
      },
      className: `project-menu-item ${matchQuestionList || matchQuestionDetail ? 'active' : ''}`,
      tabIndex: 0
    },
    {
      key: RELATED_PARTY_SETTINGS,
      icon: null,
      label: t('sider:related_party_settings'),
      role: ALL_ROLE,
      onClick: () => {
        navigate(STAKEHOLDER_VIEW_URL(projectId));
      },
      className: `project-menu-item ${matchStakeholderView || matchStakeholderUpdate ? 'active' : ''}`,
      tabIndex: 0
    },
    {
      key: CONTACT_US,
      icon: null,
      label: t('sider:contact_us'),
      /* TODO: Hiding for all role to release staging 2024-06-06 */
      role: [EXTERNAL_ADMIN, EXTERNAL_GENERAL_USER],
      // role: [],
      onClick: redirectToInquiry,
      className: `project-menu-item ${matchCreateInquiry ? 'active' : ''}`,
      tabIndex: 0
    },
    {
      key: FILE_LIST,
      icon: null,
      label: t('sider:file_list'),
      role: [INTERNAL_ADMIN, INTERNAL_GENERAL_USER, EXTERNAL_ADMIN],
      onClick: () => {
        navigate(PROJECT_FILE_LIST_URL(projectId));
      },
      className: `project-menu-item ${matchFileList ? 'active' : ''}`,
      tabIndex: 0
    },
    {
      key: REQUIRED_DOCUMENT_LIST,
      icon: null,
      label: t('sider:required_document_list'),
      role: ALL_ROLE,
      className: 'project-menu-window-link',
      onClick: () => openDocumentListWindow(projectId),
      tabIndex: 0
    },
    {
      key: APPLICATION_INFORMATION,
      icon: null,
      label: t('sider:application_information'),
      role: ALL_ROLE,
      className: 'project-menu-window-link',
      onClick: () => openBasicInfoWindow(projectId),
      tabIndex: 0
    },
    {
      key: PROJECT_MEMO,
      icon: null,
      className: 'project-menu-window-link',
      label: t('sider:project_memo'),
      role: [INTERNAL_ADMIN, INTERNAL_GENERAL_USER],
      onClick: () => openMemoFunctionWindow(projectId),
      tabIndex: 0
    }
  ];
  const menuItems: INavItem[] = [
    {
      key: PROJECT_ITEM,
      icon: <ListDashes className='sider__icon' />,
      label: t('sider:project_list'),
      role: ALL_ROLE,
      link: PROJECT_LIST_URL,
      className: getConditionalClassName(PROJECT_ITEM),
      children: showSecondaryMenu ? projectDetailMenu : undefined,
      tabIndex: 0
    },
    {
      key: ALL_TASK_PROJECT_ITEM,
      icon: <FileText className='sider__icon' />,
      label: t('sider:all_task_project'),
      role: ALL_ROLE,
      link: ALL_TASK_URL,
      className: getConditionalClassName(ALL_TASK_PROJECT_ITEM),
      tabIndex: 0
    },
    {
      key: TEMPLATE_ITEM,
      icon: <ListChecks className='sider__icon' />,
      label: t('sider:template_list'),
      role: [INTERNAL_ADMIN, INTERNAL_GENERAL_USER],
      link: TEMPLATE_LIST_URL,
      className: getConditionalClassName(TEMPLATE_ITEM),
      tabIndex: 0
    },
    {
      key: ACCOUNT_MASTER_ITEM,
      icon: <UserList className='sider__icon' />,
      label: t('sider:account_master_list'),
      role: [INTERNAL_ADMIN, INTERNAL_GENERAL_USER, EXTERNAL_ADMIN],
      link: ACCOUNT_MASTER_LIST_URL,
      className: getConditionalClassName(ACCOUNT_MASTER_ITEM),
      tabIndex: 0
    },
    {
      key: MASTER_ITEM,
      icon: <Database className='sider__icon' />,
      label: t('sider:master_data_managment'),
      role: [INTERNAL_ADMIN, INTERNAL_GENERAL_USER],
      className: getConditionalClassName(MASTER_ITEM),
      onTitleClick: () => navigate(MASTER_LIST_URL),
      onKeyPress: (e: any) => e?.target?.firstChild?.click(),
      tabIndex: 0,
      children: [
        {
          key: MASTER_LIST_ITEM,
          icon: '',
          label: t('sider:master_data_list'),
          role: [INTERNAL_ADMIN],
          link: MASTER_LIST_URL,
          className: getConditionalClassName(MASTER_LIST_ITEM),
          tabIndex: 0
        },
        {
          key: TASK_ITEM,
          icon: '',
          label: t('sider:master_data_list_task'),
          role: [INTERNAL_ADMIN, INTERNAL_GENERAL_USER],
          link: TASK_LIST_URL,
          className: getConditionalClassName(TASK_ITEM),
          tabIndex: 0
        },
        {
          key: MASTER_LIST_ITEM_DOCUMENT,
          icon: '',
          label: t('sider:master_data_list_document'),
          role: [INTERNAL_ADMIN, INTERNAL_GENERAL_USER],
          link: MASTER_LIST_ITEM_DOCUMENT_URL,
          className: getConditionalClassName(MASTER_LIST_ITEM_DOCUMENT),
          tabIndex: 0
        },
        {
          key: MASTER_LIST_ITEM_PHRASE,
          icon: '',
          label: t('sider:phrase'),
          role: [INTERNAL_ADMIN],
          link: PHRASE_LIST_URL,
          className: getConditionalClassName(MASTER_LIST_ITEM_PHRASE),
          tabIndex: 0
        }
      ]
    },
    {
      key: INQUIRY_ITEM,
      icon: <Earphone className='sider__icon' />,
      label: t('sider:inquiry'),
      role: [EXTERNAL_ADMIN],
      link: INQUIRY_URL,
      className: getConditionalClassName(TEMPLATE_ITEM),
      tabIndex: 0
    }
  ];
  const buildMenu = () => {
    let menus = menuItems;
    menus = menus.filter((item) => {
      if (item.role.includes(role as string)) {
        if (item.children) {
          item.children = item.children.filter((child) => child.role.includes(role as string));
        }
        return true;
      }
      return false;
    });
    return menus as ItemType<MenuItemType>[];
  };

  const bottomMenu = () => {
    const logOutBtn: ItemType<MenuItemType> & { tabIndex: number } = {
      key: '/login',
      tabIndex: 0,
      icon: <SignOut className='sider__icon' />,
      label: t('sider:log_out')
    };
    return [logOutBtn];
  };

  const getDefaultItemMenu = () => {
    const itemMenu: string[] = [];
    let keyItem = '';

    for (const key in MAPPING_MENU_ITEM_TO_URL) {
      if (MAPPING_MENU_ITEM_TO_URL[key].find((item) => pathname.startsWith(item))) {
        keyItem = key;
      }
    }
    menuItems.forEach((item) => {
      const condition = (!item.children && item.key === keyItem) || !!(item.children?.length && item.children.find((el) => pathname === el.link));
      if (condition) itemMenu.push(item.key);
    });
    return itemMenu;
  };

  useEffect(() => {
    const newSelectedItems = [];
    for (const key in MAPPING_MENU_ITEM_TO_URL) {
      if (MAPPING_MENU_ITEM_TO_URL[key].find((item) => pathname.startsWith(item))) {
        newSelectedItems.push(key);
      }
    }
    setSelectedItems(newSelectedItems);
  }, [pathname]);

  useEffect(() => {
    if (!showSecondaryMenu) return;
    setCollapsed(false);
    localStorage.setItem(LOCALSTORAGE.STATUS_COLLAPSED, JSON.stringify(false));
  }, [showSecondaryMenu]);

  const handleMenuClick = (menuItem: any) => {
    if (menuItem?.item?.props?.link) {
      const link = menuItem.item.props.link;
      navigate(link);
    }
    handelTurnOffPopup();
  };

  const openBasicInfoWindow = (projectId?: string) => {
    if (!projectId) return;
    openBasicInfo(PROJECT_BASIC_INFO_URL(projectId), 'mini_widow_basic_info');
  };

  const openDocumentListWindow = (projectId?: string) => {
    if (!projectId) return;
    openDocumentList(DOCUMENT_LIST_URL(projectId), 'mini_widow_document_list');
  };

  const openMemoFunctionWindow = (projectId?: string) => {
    if (!projectId) return;
    openDocumentList(PROJECT_MEMO_URL(projectId), 'mini_widow_project_memo');
  };

  const renderExpandIcon = (props: any) => {
    const { isSubMenu, isOpen } = props;
    if (isSubMenu) {
      const iconClassName = !isOpen ? 'icon-up' : 'icon-down';
      return <CaretDownLine className={iconClassName} />;
    }
    return null;
  };

  const showModal = async () => {
    const signOutConfirm = {
      title: t('logout:confirm_log_out'),
      textConfirm: t('common:yes'),
      textCancel: t('common:cancel'),
      type: TYPE_POPUP.DISCARD,
      msg: ''
    };
    const confirmed = await showBasePopup(signOutConfirm);
    if (confirmed === 'confirm') handleLogout();
  };

  const handleLogout = async () => {
    try {
      dispatch(setLoadingPage(true));
      sessionStorage.removeItem('path');
      sessionStorage.removeItem('email');
      setTimeout(async () => {
        sessionStorage.setItem(SESSION_STORAGE.LOGGING_OUT, 'true');
        await auth.signoutRedirect();
        await auth.removeUser();
        cleanLocalCache();
        dispatch(setLoadingPage(false));
      }, 500);
    } catch (error) {
      dispatch(setLoadingPage(false));
    }
  };

  // Func render iconHeader
  const _renderIconHeader = (statusCollapsed: boolean) => {
    if (statusCollapsed) {
      return <Icon component={() => <CaretCircleRight />} />;
    }
    return <Icon component={() => <CaretCircleLeft />} />;
  };
  return (
    <Sider className='sider-container' trigger={null} collapsible collapsed={statusCollapsed} theme='light' collapsedWidth={108} width={264}>
      <div
        className='logo cursor-pointer'
        onClick={() => {
          navigate(PROJECT_LIST_URL);
        }}
      >
        {statusCollapsed && <img src={LogoIconNoText} alt='logoNoText' className='w-[46.7px] h-[38.5px]' />}
        {!statusCollapsed && <img src={LogoIcon} alt='logoIcon' className='w-[160px] h-[38.5px]' />}
      </div>
      <div className='sider-toggle-wrapper'>
        <Button type='text' icon={_renderIconHeader(statusCollapsed)} onClick={toggleSider} />
      </div>
      <div className='flex-container'>
        <Menu
          tabIndex={-1}
          expandIcon={renderExpandIcon}
          triggerSubMenuAction='hover'
          theme='light'
          mode='inline'
          selectedKeys={selectedItems}
          items={buildMenu()}
          onClick={(menuItem) => handleMenuClick(menuItem)}
          defaultOpenKeys={getDefaultItemMenu()}
        />
        <Menu className='btn-logout' tabIndex={-1} theme='light' mode='inline' selectable={false} items={bottomMenu()} onClick={showModal} />
      </div>
    </Sider>
  );
};

export default Sidebar;
