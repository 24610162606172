import { useFormContext } from 'react-hook-form';

import BaseCheckboxGroups, { IBaseCheckboxProps } from '@/components/common/base-checkbox';
import FormItem from '@/components/common/form-item';

interface ICheckboxProps extends IBaseCheckboxProps {
  name?: string;
}

const Checkbox: React.FC<ICheckboxProps> = ({ name, ...props }) => {
  const form = useFormContext();

  if (name && form) {
    return (
      <FormItem name={name}>
        <BaseCheckboxGroups className='w-full h-full' {...props} />
      </FormItem>
    );
  }
  return <BaseCheckboxGroups className='w-full h-full' {...props} />;
};

export default Checkbox;
