import type { ColumnsType } from 'antd/es/table';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import EmptyTextData from '@/components/EmptyData/EmptyTextData';

import { DataViewer } from '@/utils/helpers/common';

import BaseTable from '../../../../components/table/BaseTable';
import { IRequiredDocument } from '../modules';
import { SUBMITSION } from './constants';

import './Material.scss';

const Material = ({ materials = [] }: { materials?: IRequiredDocument[] }) => {
  const { t } = useTranslation();
  const refTable = useRef<any>(null);

  const columns: ColumnsType<IRequiredDocument> = [
    {
      title: 'NO',
      dataIndex: 'no',
      width: 50,
      className: 'max-w-[500px]',
      render: (_, __, index) => index + 1
    },
    {
      title: t('reference_material:reference_material').toUpperCase(),
      dataIndex: 'typeId',
      key: 'typeId',
      width: 440,
      className: 'max-w-[440px]',
      render: (_: string, record: IRequiredDocument) => <p>{DataViewer.display(record?.documentName)}</p>
    },
    {
      title: t('template_layout:number_of_copies').toUpperCase(),
      dataIndex: 'description',
      key: 'description',
      width: 230,
      className: 'max-w-[230px]',
      render: (_: string, record: IRequiredDocument) => <p>{DataViewer.display(record?.numberOfCopy)}</p>
    },
    {
      title: t('template_layout:notes').toUpperCase(),
      dataIndex: 'description',
      key: 'description',
      sorter: false,
      width: 800,
      className: 'max-w-[500px]',
      render: (_: string, record: IRequiredDocument) => {
        return <p dangerouslySetInnerHTML={{ __html: DataViewer.displayAsSanitizeHTML(record?.note) }}></p>;
      }
    },
    {
      title: t('template_layout:submission').toUpperCase(),
      key: 'media',
      width: 170,
      className: 'max-w-[170px]',
      render: (_: string, record: IRequiredDocument) => {
        const isEmpty = DataViewer.isEmpty(record.submissionToMot);
        if (!isEmpty) return <p>{record.submissionToMot ? SUBMITSION.REQURIED : SUBMITSION.NOT_REQUIRED}</p>;
        return <p>{DataViewer.display(record.submissionToMot)}</p>;
      }
    }
  ];

  return (
    <div className='template-view-material'>
      <BaseTable
        ref={refTable}
        className='w-full'
        rowKey='id'
        dataSource={materials}
        columns={columns}
        pagination={false}
        classEmptyCustom='py-[8px]'
        emptyDataAlert={<EmptyTextData className='text-center' text={t('template_layout:emty_table') ?? ''} />}
      />
    </div>
  );
};

export default Material;
