import classNames from 'classnames';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import EmptyDataWithIcon from '@/components/EmptyData/EmptyDataWithIcon';
import { BaseButton } from '@/components/base-button/BaseButton';
import TableBase, { FetchColumnsType } from '@/components/common/table-base';
import CommonTag from '@/components/common/tag/CommonTag';
import { SelectCustom } from '@/components/form-select/SelectCustom';

import { setAlertNotification } from '@/redux/globalReducer';
import { AppDispatch } from '@/redux/store';

import useAuthorization from '@/hooks/useAuthorization';
import useFetch from '@/hooks/useFetch';
import useMutation from '@/hooks/useMutation';

import { API } from '@/utils/constants/Apis';
import { EXTERNAL_QUESTION_AUTHENTICATE_URL, PROJECT_QUESTION_DETAIL_URL } from '@/utils/constants/RouteContants';
import { DataViewer } from '@/utils/helpers/common';
import { formatDateTime } from '@/utils/helpers/globalHelper';

import IconLock from '@/assets/icons/LockKey.svg';
import IconShare from '@assets/icons/IconShare.svg';

import ShareUrlModal from './ShareUrlModal';

dayjs.extend(utc);

/**
 * @field name - display name as family name
 * @field lock - status of submit questionnaire. If true, display lock icon
 * @field lockQuestionnaire - status of lock questionnaire. If true, there is another user is editing
 */
export interface IProjectQuestion {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  lock: boolean;
  totalQuestion: number;
  totalAnswer: number;
  displayOrder: number;
  lockQuestionnaire: boolean;
  lockedBy?: string;
}

export interface IQuestionTemplate {
  id: string;
  name: string;
  countryId: string;
  countryName: string;
  totalQuestion: number;
}

export default function ProjectQuestionList() {
  const { id: projectId = '' } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const refList = useRef<any>(null);
  const { isInternalRole, user } = useAuthorization();
  const navigate = useNavigate();

  const [selectedFormId, setSelectedFormId] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [sharedUrl, setSharedUrl] = useState({
    value: '',
    expiredTime: ''
  });
  const {
    data: listQuestion,
    loading,
    fetchData
  } = useFetch<{
    formId: string;
    questionnaires: IProjectQuestion[];
  }>(API.GET_PROJECT_QUESTIONNAIRE_LIST(projectId), 'GET');
  const { data: questionTemplates, loading: loadingQuestionTemplates } = useFetch<IQuestionTemplate[]>(
    API.GET_PROJECT_QUESTIONNAIRE_FORM(projectId),
    'GET'
  );

  const endpointUrl = projectId && selectedFormId ? API.CHANGE_QUESTIONNAIRE_TEMPLATE(projectId, selectedFormId) : '';
  const { mutate, loading: loadingChangeTemplate } = useMutation(endpointUrl, {
    method: 'POST',
    bodyType: 'json'
  });

  const { mutate: generateToken } = useMutation(API.GET_GENERATE_QUESTION_TOKEN(projectId ?? ''), {
    method: 'GET',
    showToastError: true
  });

  const changeTemplate = async () => {
    if (!selectedFormId || !projectId) return;
    try {
      await mutate({});
      fetchData();
    } catch (error) {}
  };

  useEffect(() => {
    changeTemplate();
  }, [selectedFormId]);

  useEffect(() => {
    if (selectedFormId || !questionTemplates?.length) {
      return;
    }
    if (listQuestion?.formId) {
      setSelectedFormId(listQuestion.formId);
      return;
    }
    if (questionTemplates?.length === 1) {
      setSelectedFormId(questionTemplates[0].id);
    }
  }, [questionTemplates, listQuestion, selectedFormId]);

  const renderOptions = (data: IQuestionTemplate[]) => {
    if (!data) return [];
    return data.map((i) => ({
      label: i.name,
      value: i.id
    }));
  };

  const handleOpenDetail = async (id: string) => {
    if (!id) return;
    const latestListQuestion = await fetchData();
    const match = latestListQuestion?.questionnaires?.find((item) => item.id === id);
    if (!match?.lock && match?.lockQuestionnaire && match?.lockedBy !== user?.id) {
      dispatch(
        setAlertNotification({
          show: true,
          type: 'warning',
          message: t('common:MSG_P_035')
        })
      );
    } else {
      navigate(PROJECT_QUESTION_DETAIL_URL(projectId, id));
    }
  };

  const openShareModal = async () => {
    if (!projectId) {
      return;
    }
    const {
      data: { token, expires }
    } = await generateToken({});

    if (!token) {
      return;
    }

    setSharedUrl({
      value: `${window.location.origin}${EXTERNAL_QUESTION_AUTHENTICATE_URL}?token=${token}`,
      expiredTime: formatDateTime(dayjs.utc(expires).local(), 'YYYY/MM/DD HH:mm')
    });
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
    setSharedUrl({
      value: '',
      expiredTime: ''
    });
  };

  const columns: FetchColumnsType<IProjectQuestion> = [
    {
      title: t('project:question_list:applicant'),
      key: 'name',
      width: 240,
      className: 'max-w-[240px]',
      render: (_value: any, record: IProjectQuestion) => (
        <div className='flex'>
          {record.lock ? <IconLock className='mr-2' /> : ''}
          {DataViewer.display(record?.name)}
        </div>
      ),
      tooltip: (_value: any, record: IProjectQuestion) => DataViewer.display(record?.name),
      ellipsis: true
    },
    {
      title: t('project:question_list:last_name'),
      dataIndex: 'createdName',
      width: 240,
      className: 'max-w-[240px]',
      key: 'lastName',
      render: (_: any, record: IProjectQuestion) => DataViewer.display(record?.lastName),
      tooltip: (_: any, record: IProjectQuestion) => DataViewer.display(record?.lastName)
    },
    {
      title: t('project:question_list:first_name'),
      key: 'firstName',
      width: 240,
      className: 'max-w-[240px]',
      render: (_: any, record: IProjectQuestion) => DataViewer.display(record?.firstName),
      tooltip: (_: any, record: IProjectQuestion) => DataViewer.display(record?.firstName),
      ellipsis: true
    },
    {
      title: t('project:question_list:answer_status'),
      key: 'answer_status',
      width: 240,
      className: 'max-w-[240px]',
      render: (_: any, record: IProjectQuestion) => (
        <CommonTag type={record.totalAnswer < record.totalQuestion ? 'default' : 'success'} text={`${record.totalAnswer}/${record.totalQuestion}`} />
      )
    },
    {
      title: t('project:question_list:action'),
      key: 'check_answer',
      render: (_: any, record: IProjectQuestion) => (
        <BaseButton type='secondary' size='medium' className='!h-[38px] !w-[100px]' onClick={() => handleOpenDetail(record?.id)}>
          {record.lock ? t('project:question_list:check_answer') : t('project:question_list:input_answer')}
        </BaseButton>
      )
    }
  ];

  return (
    <div ref={refList}>
      <div className='grid grid-cols-2 gap-4 h-[50px]'>
        <div className='title-24'>{t('project:question_list:title')}</div>
        <div className='flex space-x-2 items-center justify-end'>
          {isInternalRole && (
            <div className='w-[428px]'>
              <SelectCustom
                className='w-full'
                options={renderOptions(questionTemplates ?? [])}
                label={t('project:question_list:title') ?? ''}
                labelPosition='left'
                required
                onChange={(id) => {
                  if (!id) fetchData();
                  setSelectedFormId(id);
                }}
                allowClear={false}
                value={selectedFormId}
                placeholder={t('project:question_list:select_questionnaire_template_placeholder')}
                loading={loadingQuestionTemplates || loadingChangeTemplate}
              />
            </div>
          )}
          <BaseButton
            size='medium'
            type='button'
            className='!h-[40px] !w-[100px]'
            icon={<IconShare style={{ verticalAlign: 'sub' }} />}
            onClick={openShareModal}
          >
            {t('project:question_list:share')}
          </BaseButton>
        </div>
      </div>
      <ShareUrlModal
        getContainer={() => refList.current ?? document.body}
        openModal={openModal}
        onClose={closeModal}
        onCancel={closeModal}
        expiredTime={sharedUrl.expiredTime}
        sharedUrl={sharedUrl.value}
      />
      <div>
        <TableBase
          columns={columns}
          scrollTable={{ maxHeight: '100vh - 200px' }}
          dataSource={listQuestion?.questionnaires ?? []}
          rowSelection={null}
          locale={{
            emptyText: (
              <span className={classNames((loading || loadingQuestionTemplates) && 'hidden')}>
                <EmptyDataWithIcon
                  title={null}
                  description={{
                    content: 'common:no_data'
                  }}
                  button={null}
                />
              </span>
            )
          }}
          loading={loading || loadingQuestionTemplates || loadingChangeTemplate}
        />
      </div>
    </div>
  );
}
