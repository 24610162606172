import { Spin } from 'antd';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { setLoadingPage } from '../../redux/globalReducer';

import './LoadingPageContent.scss';

const LoadingPageContent = () => {
  const location = useLocation();
  const loadingPage: boolean = useAppSelector((state) => state.global.loadingPage);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (loadingPage) {
      setTimeout(() => {
        dispatch(setLoadingPage(false));
      }, 1000);
    }
  }, [location]);

  if (!loadingPage) {
    return <></>;
  }

  return (
    <div className='loading-page-content'>
      <div className='loading-spin'>
        <Spin size='large' />
      </div>
    </div>
  );
};

export default LoadingPageContent;
