import { Tag } from 'antd';
import React from 'react';

interface CommonTagProps {
  text: string;
  type: 'default' | 'primary' | 'success' | 'warning' | 'danger';
}

const CommonTag: React.FC<CommonTagProps> = ({ text, type }) => {
  const mappingColor = {
    default: {
      color: '#525A68',
      background: '#F5F5F7',
      border: '#525A68'
    },
    primary: {
      color: '#076CE3',
      background: '#EEF5FD',
      border: '#076CE3'
    },
    success: {
      color: '#36B368',
      background: '#E7F1EF',
      border: '#36B368'
    },
    warning: {
      color: '#F2A227',
      background: '#FEF6E9',
      border: '#F2A227'
    },
    danger: {
      color: '#F03D3E',
      background: '#FFF1F0',
      border: '#F03D3E'
    }
  };

  const item = mappingColor[type] ?? mappingColor.default;
  return (
    <Tag
      style={{
        background: item.background,
        borderColor: item.border,
        color: item.color
      }}
    >
      {text}
    </Tag>
  );
};

export default CommonTag;
