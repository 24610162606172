import DOMPurify from 'dompurify';
import Quill, { Bounds } from 'quill';

const BlockEmbed: any = Quill.import('blots/block/embed');

export const FILE_BLOT_STATUS = {
  UPLOADING: 'uploading',
  UPLOADED: 'uploaded',
  ERROR: 'error'
} as const;
export type FileBlotStatus = (typeof FILE_BLOT_STATUS)[keyof typeof FILE_BLOT_STATUS];
/**
 * @deprecated This class is deprecated, it's not useful for business's logic.
 */
export default class FileBlot extends BlockEmbed {
  static blotName = 'FileBlot';
  static tagName = 'div';

  private static _generatePrefixIcon() {
    const paperClipIcon = document.createElement('div');
    paperClipIcon.className = 'file-attachment-prefix-icon bg-cover w-[18px] h-[18px]';
    return paperClipIcon;
  }

  private static _generateLabel(name: string, node: HTMLElement) {
    const safeName = DOMPurify.sanitize(name);
    const wrapper = document.createElement('div');
    wrapper.className = 'file-attachment-label relative flex items-center';

    const group = document.createElement('div');
    group.className = 'group cursor-pointer';

    const label = document.createElement('div');
    label.className = 'truncate w-[117px] text-[14px] leading-[22.4px]';
    label.textContent = safeName;

    const tooltip = document.createElement('div');
    tooltip.className = 'absolute bottom-full mb-2 hidden z-10 group-hover:block';

    const tooltipContent = document.createElement('div');
    tooltipContent.className = `bg-gray-900 text-white text-sm rounded-md px-4 py-2 whitespace-break-spaces`;
    tooltipContent.textContent = safeName;
    const tooltipArrow = document.createElement('div');
    tooltipArrow.className = 'absolute -bottom-1 left-[50px] transform -translate-x-1/2 w-3 h-3 bg-gray-900 rotate-45';

    tooltip.appendChild(tooltipContent);
    tooltip.appendChild(tooltipArrow);
    group.appendChild(label);
    group.appendChild(tooltip);
    wrapper.appendChild(group);

    group.addEventListener('mouseenter', () => {
      const bounds = this._getBounds(node);
      if (!bounds) return;
      const { top: spaceAbove } = bounds;
      if (spaceAbove < tooltip.offsetHeight) {
        const bot = tooltip.offsetHeight + tooltipArrow.offsetHeight;
        tooltip.style.bottom = `-${bot}px`;
        tooltipArrow.classList.add('rotate-225', 'bottom-auto', '-top-1');
      } else {
        tooltip.style.bottom = '100%';
        tooltipArrow.classList.remove('rotate-225', 'bottom-auto', '-top-1');
      }
    });

    return wrapper;
  }
  private static _getBounds(element: HTMLElement): Bounds | null {
    const elRect = element.getBoundingClientRect();
    const quillDom = this._findClosestEditor(element);
    if (!quillDom) return null;
    const quillRect = quillDom.getBoundingClientRect();
    if (!quillRect || !elRect) return null;
    return {
      bottom: quillRect.bottom - elRect.bottom,
      height: elRect.height,
      left: elRect.left - quillRect.left,
      right: quillRect.right - elRect.right,
      top: elRect.top - quillRect.top,
      width: elRect.width
    };
  }
  private static _findClosestEditor(element: HTMLElement) {
    if (!element) return;
    const ancestor = element.closest('.ql-editor');
    const descendant = element.querySelector('.ql-editor');
    return ancestor || descendant;
  }
  private static _generateActionBtn(node: HTMLElement) {
    const trashButton = document.createElement('div');
    // trash icon must required set background image at your css file
    trashButton.className = 'file-attachment-action w-[18px] h-[18px] bg-cover cursor-pointer hover:scale-95';
    trashButton.addEventListener('click', () => {
      node.remove();
    });
    return trashButton;
  }

  private static _packed(node: HTMLElement, children: HTMLElement[]) {
    // Make the content non-editable
    node.setAttribute('contenteditable', 'false');
    node.className =
      'file-attachment-container select-none' +
      ' w-[185px] h-[30px]' +
      ' bg-white flex items-center gap-[8px] px-[8px] py-[4px] my-[4px]' +
      ' rounded-[8px] border-[1px] border-link border-solid';
    node.append(...children);
    return this;
  }

  static create({ url, status, name }: { url: string; status: FileBlotStatus; name: string }) {
    const node = super.create();
    node.setAttribute('data-url', url);
    node.setAttribute('data-name', name);
    node.setAttribute('data-status', status);

    const paperClipIcon = this._generatePrefixIcon();
    const label = this._generateLabel(name, node);
    const trashButton = this._generateActionBtn(node);

    this._packed(node, [paperClipIcon, label, trashButton]);
    return node;
  }

  static formats(node: HTMLElement) {
    const format = {
      url: node.getAttribute('data-url'),
      name: node.getAttribute('data-name')
    };
    return format;
  }

  static value(node: HTMLElement): { url: string | null; status: FileBlotStatus; name: string | null; node: HTMLElement } {
    return {
      url: node.getAttribute('data-url'),
      name: node.getAttribute('data-name'),
      status: node.getAttribute('data-status') as FileBlotStatus,
      node
    };
  }

  format(name: string, value: any) {
    if (name === 'url' && value) {
      this.domNode.setAttribute('data-url', value);
    } else if (name === 'name' && value) {
      this.domNode.setAttribute('data-name', value);
    } else if (name === 'status') {
      this.domNode.setAttribute('data-status', value);
    } else super.format(name, value);
  }
}

// required init for custom blots
FileBlot.blotName = 'FileBlot';
FileBlot.tagName = 'div';
