import { Button, Tag } from 'antd';
import React from 'react';
import { SubmitHandler, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DefaultOptionType } from 'antd/es/select';

import { IFormFilter, IQueryFilterParams } from './models';
import { DEFAULT_FILTER_FORM, TYPE_CONFIRM } from './constants';

export interface IRenderTagProps {
  onSubmit: SubmitHandler<IFormFilter>
  form: UseFormReturn<IFormFilter>
  options: DefaultOptionType[],
  fields: IQueryFilterParams,
  total: number,
  keys: string[],
}

const RenderTag = ({ onSubmit, form, options, fields, total, keys }: IRenderTagProps) => {
  const { t } = useTranslation();
  if (!total) return <></>;

  return (
    <div className='mt-4 flex flex-wrap'>
      <div className='flex flex-wrap'>
        {keys.map((key: string) => {
          let value: string | string[] | null | undefined = fields[key as keyof IQueryFilterParams];
          if (!value) return;
          if (key === 'targetAudience') {
            return (
              <>
                {typeof value === 'object' &&
                  value?.map((targetAudience) => (
                    <Tag
                      key={'targetAudience' + targetAudience}
                      closable
                      className='bg-gray2 body-400 tag-item flex items-center'
                      onClose={() => {
                        const newData = Array.isArray(value)
                          ? value.filter((item: any) => item !== targetAudience)
                          : [];
                        form.setValue('primary', {
                          ...fields,
                          targetAudience: newData.length > 0 ? newData : undefined
                        });
                        form.handleSubmit(onSubmit)();
                      }}
                    >
                      {options?.find((i: any) => i.value === targetAudience)?.label}
                    </Tag>
                  ))}
              </>
            );
          } else if (key === 'confirmationCheck') {
            value = value === TYPE_CONFIRM.confirm
              ? t('announcement:list:filter:confirmation_check:confirmed')
              : t('announcement:list:filter:confirmation_check:unconfirmed');
          }
          return (
            <Tag
              key={key}
              closable
              className='bg-gray2 body-400 tag-item flex items-center'
              onClose={() => {
                form.setValue('primary', {
                  ...fields,
                  [key]: null
                });
                form.handleSubmit(onSubmit)();
              }}
            >
              {value}
            </Tag>
          );
        })}
      </div>
      <Button
        size='small'
        className='tag-btn__clear-all'
        onClick={() => {
          form.setValue('primary', DEFAULT_FILTER_FORM.primary);
          form.handleSubmit(onSubmit)();
        }}
      >
        {t('button:clear_filter')}
      </Button>
    </div>
  );
};

export default RenderTag;
