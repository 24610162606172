import { Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CounterLabel from '@pages/project-management/add-edit/scenario/components/counter-label';
import { useFormScenarioBContext } from '@pages/project-management/add-edit/scenario/type-b';
import { PARENT_PATH as rootPathStepTwo } from '@pages/project-management/add-edit/scenario/type-b/form/step-two';
import { relativePath } from '@pages/project-management/add-edit/scenario/utils';

import { showBasePopup } from '@/components/base-popup/BasePopup';
import { FormInput } from '@/components/form-input/FormInput';
import { FormSelect } from '@/components/form-select/FormSelect';

import useOptionsGlobal from '@/hooks/useOptionsGlobal';

type Props = {
  parentPath: string;
};
const AssignmentInfo = (props: Props) => {
  const { parentPath } = props;
  const formMethod = useFormScenarioBContext<'step-2'>();
  const { t } = useTranslation();
  const { customCountryOptions, filterOption } = useOptionsGlobal();
  const PATHS = {
    COUNTRY_ID: relativePath('countryId', parentPath),
    COMPANY_NAME: relativePath('companyName', parentPath),
    ADDRESS: relativePath('address', parentPath),
    VISA_CATEGORY: relativePath('visaCategoryId', rootPathStepTwo)
  };
  const formWatching = formMethod.watch([PATHS.COUNTRY_ID, PATHS.COMPANY_NAME, PATHS.ADDRESS]);
  const [companyName, address] = formWatching;

  const preChangeCountry = async (value: any) => {
    const visaCategoryId = formMethod.getValues(`${PATHS.VISA_CATEGORY}`);
    if (!visaCategoryId) return value;
    const showPopup = await showBasePopup({
      type: 'confirm',
      title: t(`common:MSG_P_021:title`) || '',
      msg: t('common:MSG_P_021:content') || ''
    });
    if (showPopup !== 'confirm') throw new Error('');
    return value;
  };

  const afterChangeCountry = () => formMethod.setValue(`${PATHS.VISA_CATEGORY}`, null);

  const counters = () => {
    let current = 0;
    const total = formWatching.length ?? 0;
    for (const item of formWatching) {
      if (item) {
        current++;
      }
    }
    return `${current}/${total}`;
  };

  return (
    <>
      <CounterLabel title={t('project:scenario_b:assignment_information')} count={counters()} />
      <Row gutter={16}>
        <Col span={6}>
          <FormInput
            required
            defaultValue={companyName}
            name={PATHS.COMPANY_NAME}
            labelTx='basic_information:assignment_training_company_name'
            placeholderTx='basic_information:place_holder_company_name_of_assignee'
          />
        </Col>
        <Col span={6}>
          <FormSelect
            required
            id={PATHS.COUNTRY_ID}
            name={PATHS.COUNTRY_ID}
            label={t('basic_information:destination_training_country')}
            options={customCountryOptions}
            filterOption={filterOption}
            placeholder={t('basic_information:place_holder_second_nationality')}
            preChange={preChangeCountry}
            handleChange={afterChangeCountry}
          />
        </Col>
        <Col span={12}>
          <FormInput
            required
            defaultValue={address}
            name={PATHS.ADDRESS}
            label={t('basic_information:assignment_training_city')}
            placeholderTx={String(t('basic_information:place_holder_address_of_the_destination'))}
          />
        </Col>
      </Row>
    </>
  );
};

export default AssignmentInfo;
