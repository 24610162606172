export const SUBMISSION_MOT = {
  unnecessary: 'unnecessary',
  need: 'need'
};

export const SUBMISSION_STATUS = {
  notSubmitted: 'UnConfirm',
  submitted: 'Confirmed'
};

export const RESPONSE_STATUS = {
  notCompatible: 'UnConfirm',
  compatible: 'Confirmed'
};

export const REQUIRED_DOCUMENT_DEFAULT_VALUE = {
  projectRequiredDocuments: [
    {
      documentName: null,
      documentId: null,
      numberOfCopy: '',
      note: '',
      submissionToMot: null,
      isConfirm: null
    }
  ],
  projectInternalDocuments: [
    {
      documentName: null,
      numberOfCopy: '',
      note: '',
      submissionToMot: null,
      isConfirm: null
    }
  ]
};

export const DOCUMENT_TYPE = {
  projectRequiredDocuments: 'projectRequiredDocuments',
  projectInternalDocuments: 'projectInternalDocuments'
};

export const MAX_ITEM = 50;
export const OTHER_COUNTRY_ID = '295dbbe1-e2df-4bf2-8798-82a1eb13ef97';
