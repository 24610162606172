import { useAppSelector } from '@/hooks';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IOptions } from '@/pages/project-management/add-edit/models';

import { FormSelect } from '@/components/form-select/FormSelect';

import { ICategory } from '@/utils/interfaces/template';

import { useFormScenarioAContext } from '../..';
import CounterLabel from '../../../components/counter-label';
import { relativePath } from '../../../utils';

interface IVisaInformationProps {
  parentPath: string;
}

const VisaInformation = ({ parentPath }: IVisaInformationProps) => {
  const formMethod = useFormScenarioAContext();
  const [categoryOptions, setCategoryOptions] = useState<IOptions[]>([]);
  const countries = useAppSelector((state) => state?.global?.countries);
  const PATHS = {
    ASSIGNMENT_COUNTRY: relativePath('steps[0].projectAssignmentInfo.countryId'),
    VISA_CATEGORY: relativePath('visaCategoryId', parentPath)
  };
  const [countryCode, visaCategoryId] = formMethod.watch([PATHS.ASSIGNMENT_COUNTRY, PATHS.VISA_CATEGORY]);

  const { t } = useTranslation();
  useEffect(() => {
    const categories = countries?.find((country) => country.id === countryCode)?.categories;
    if (categories) {
      const newCategoryOptions = categories.map((category: ICategory) => {
        return {
          label: category.name,
          value: category.id
        };
      });
      if (!newCategoryOptions.find((item) => item.value === visaCategoryId)) {
        formMethod.setValue(PATHS.VISA_CATEGORY, null);
      }
      setCategoryOptions(newCategoryOptions);
    } else {
      setCategoryOptions([]);
      formMethod.setValue(PATHS.VISA_CATEGORY, null);
    }
  }, [countryCode]);

  const counters = () => {
    let current = 0;
    const total = 1;
    if (visaCategoryId) {
      current++;
    }
    return `${current}/${total}`;
  };
  return (
    <>
      <CounterLabel title={t('project:scenario_b:visa_information')} count={counters()} />
      <Row gutter={16}>
        <Col span={12}>
          <FormSelect
            name={PATHS.VISA_CATEGORY}
            label={String(t('basic_information:visa_category'))}
            placeholder={String(
              t('placeholder:select', {
                field: t('basic_information:visa_category')
              })
            )}
            options={categoryOptions}
            disabled={!countryCode}
          />
        </Col>
      </Row>
    </>
  );
};

export default VisaInformation;
