import dayjs from 'dayjs';

import { QUERY_CONDITION } from '@/utils/constants';
import { FORMAT_DATE_JP } from '@/utils/constants/AppConstants';
import QueryFactory, { QueryItem } from '@/utils/helpers/filters/base';
import { getEndOfDay } from '@/utils/helpers/globalHelper';

export interface IQueryFilterParams {
  keyword?: string;
  countryId?: string;
  formId?: string;
  categoryId?: string;
  patternId?: string;
  updatedDateFrom?: string;
  updatedDateTo?: string;
}

export class TemplateListQueryFactory extends QueryFactory {
  getById(value: string, field: string) {
    const newQuerySection = this.initNewQuery(this.data?.length ? QUERY_CONDITION.AND : QUERY_CONDITION.EMPTY);
    const idQuery = new QueryItem().modules.guidWith(value, { fieldTitle: field });
    newQuerySection.childrens?.push(idQuery);
    this.append(newQuerySection);
    return this;
  }
  updatedDateFrom(date: string) {
    const newQuerySection = this.initNewQuery(this.data?.length ? QUERY_CONDITION.AND : QUERY_CONDITION.EMPTY);
    newQuerySection?.childrens?.push(new QueryItem().modules.fromDate(date, { fieldTitle: 'updatedDate' }));
    this.append(newQuerySection);
    return this;
  }

  updatedDateTo(date: string) {
    const newQuerySection = this.initNewQuery(this.data?.length ? QUERY_CONDITION.AND : QUERY_CONDITION.EMPTY);
    newQuerySection?.childrens?.push(new QueryItem().modules.toDate(date, { fieldTitle: 'updatedDate' }));
    this.append(newQuerySection);
    return this;
  }
}

export const generateFilter = (params?: IQueryFilterParams): string => {
  const filterFactory = new TemplateListQueryFactory();
  if (params?.keyword) {
    filterFactory.searchingBy(['nameSearch.ToLower()', 'updatedNameSearch.ToLower()'], params.keyword.toLowerCase());
  }
  if (params?.countryId) {
    filterFactory.getById(params.countryId, 'countryId');
  }
  if (params?.formId) {
    filterFactory.getById(params.formId, 'formId');
  }
  if (params?.categoryId) {
    filterFactory.getById(params.categoryId, 'categoryId');
  }
  if (params?.patternId) {
    filterFactory.getById(params.patternId, 'patternId');
  }
  if (params?.updatedDateFrom) {
    filterFactory.updatedDateFrom(dayjs(params?.updatedDateFrom).format(FORMAT_DATE_JP));
  }
  if (params?.updatedDateTo) {
    filterFactory.updatedDateTo(getEndOfDay(params.updatedDateTo));
  }
  return filterFactory.sanitize().stringifyData();
};
