import { useAppSelector } from '@/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IStepTwo } from '@/pages/project-management/add-edit/models/scenarioB';
import { getCategoriesBy } from '@pages/project-management/add-edit/scenario/type-b/helpers';

import { BaseCard } from '@/components/base-card/BaseCard';

import { DataViewer } from '@/utils/helpers/common';

import CardInfo from './CardInfo';

interface IVisaInfoProps {
  data: IStepTwo;
}
const VisaInfo = ({ data }: IVisaInfoProps) => {
  const { visaCategoryId, projectAssignmentInfo } = data ?? {};
  const countries = useAppSelector((state) => state?.global?.countries);
  const { t } = useTranslation();

  const categories = getCategoriesBy(projectAssignmentInfo?.countryId ?? '', countries);
  const matched = categories.find((category) => category.value === visaCategoryId);
  const columns = [
    {
      items: [{ title: DataViewer.display(matched?.label), label: 'basic_information:category' }]
    }
  ];

  return (
    <BaseCard title={t('project:scenario_b:visa_information')} total={1} questions={visaCategoryId ? 1 : 0} className='scenario-b-card'>
      <CardInfo columns={columns} />
    </BaseCard>
  );
};

export default VisaInfo;
