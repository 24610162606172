import AppTooltip from '../app-tooltip/AppTooltip';

interface IAppLabel {
  label?: string | null;
  title?: string | null;
  subLabel?: string | null;
  classSubLabel?: string | null;
  icon?: React.ReactNode | null;
  maxWidthLabel?: string | null;
}

const AppSubLabel = (props: IAppLabel) => {
  const { label, title, subLabel, classSubLabel, icon, maxWidthLabel } = props;

  return (
    <>
      <div className='flex items-center w-full leading-[160%] mb-2'>
        <p className='flex-none text-14 font-normal !m-0 min-w-[180px] text-[#12212E]'>{`${label}`}</p>
        <AppTooltip className={`ml-2 truncate text-[#12212E] font-bold ${maxWidthLabel}`} title={title}>
          {title}
        </AppTooltip>
        <span className='pl-3'>{icon}</span>
      </div>
      <p className={`ml-[185px] ${classSubLabel}`}>{`${subLabel ?? ''}`}</p>
    </>
  );
};

export default AppSubLabel;
