import React from 'react';
import { useTranslation } from 'react-i18next';

import { IStepTwo } from '@/pages/project-management/add-edit/models/scenarioB';

import { BaseCard } from '@/components/base-card/BaseCard';

import { ICountryOption } from '@/hooks/useOptionsGlobal';

import { DataViewer } from '@/utils/helpers/common';
import { Counters } from '@/utils/helpers/counters';

import CardInfo from './CardInfo';

interface IAssignmentInfoProps {
  data: IStepTwo;
  countries: ICountryOption[];
}
const AssignmentInfo = ({ data, countries }: IAssignmentInfoProps) => {
  const { t } = useTranslation();
  const { projectAssignmentInfo } = data ?? {};
  const schema = {
    data: ['companyName', 'countryId', 'address']
  };
  const counters = new Counters(schema, projectAssignmentInfo);
  const country = countries?.find((item) => item.value === projectAssignmentInfo?.countryId);
  const columns = [
    {
      items: [
        { title: DataViewer.display(projectAssignmentInfo?.companyName), label: 'basic_information:assignment_training_company_name' },
        { title: DataViewer.display(country?.label), label: 'basic_information:destination_training_country' },
        { title: DataViewer.display(projectAssignmentInfo?.address), label: 'basic_information:assignment_training_city' }
      ]
    }
  ];
  return (
    <BaseCard
      title={t('project:scenario_b:assignment_information')}
      total={schema.data.length}
      questions={counters.get('data')}
      className='scenario-b-card'
    >
      <CardInfo columns={columns} />
    </BaseCard>
  );
};

export default AssignmentInfo;
