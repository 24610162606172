import { useAppSelector } from '@/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

import { Expired } from '@pages/project-management/project-questionaire/components/external-questions';
import { getPassword, validateToken } from '@pages/project-management/project-questionaire/external-questions/api';

import { BaseButton } from '@components/base-button/BaseButton';
import { FormInput } from '@components/form-input/FormInput';

import { setLoadingPage } from '@redux/globalReducer';
import { AppDispatch } from '@redux/store';

import { EXTERNAL_QUESTION_LIST_URL } from '@utils/constants/RouteContants';
import { stringifyObjectValidateYup } from '@utils/method';

import CardLock from '@assets/images/CardLock.png';

const ExternalQuestionAuthenticationPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const loadingPage = useAppSelector((state) => state.global.loadingPage);

  const [isValid, setIsValid] = useState(true);

  const token = searchParams.get('token');

  const methods = useForm({
    mode: 'all',
    shouldUnregister: false,
    resolver: yupResolver(
      yup.object().shape({
        code: yup.string().required(
          stringifyObjectValidateYup({
            keyT: 'common:MSG_001_textbox',
            optionsTx: { field: 'questionnaire:passcode' }
          })
        )
      })
    ),
    defaultValues: {
      code: ''
    }
  });

  const authenticateToken = async () => {
    if (!token) {
      return;
    }
    dispatch(setLoadingPage(true));
    try {
      const { data: isValid } = await validateToken(token);
      setIsValid(!!isValid);
    } finally {
      dispatch(setLoadingPage(false));
    }
  };

  const submit = async (form: FieldValues) => {
    if (!token) {
      return;
    }
    try {
      const { data: password } = await getPassword({ token, code: form.code });
      localStorage.setItem('password_external', password.toString());
      navigate(EXTERNAL_QUESTION_LIST_URL(token.toString()));
    } catch {
      methods.setError('code', { type: 'custom', message: t('questionnaire:incorrect_passcode') ?? '' });
    }
  };

  useEffect(() => {
    authenticateToken();
  }, []);

  if (loadingPage) {
    return null;
  }

  if (!isValid) {
    return <Expired />;
  }

  return (
    <div className='h-screen flex flex-col items-center justify-center'>
      <div className='px-[24px] py-[32px] space-y-[12px]'>
        <div className='text-center pb-[20px]'>
          <img src={CardLock} width={118} height={115} alt='card-lock' className='mb-[12px]' />
          <div className='text-24 font-bold mb-[16px]'>{t('questionnaire:enter_passcode')}</div>
          <div className='text-textGray text-16 font-bold'>{t('questionnaire:enter_applicant_dob')}</div>
        </div>
        <FormProvider {...methods}>
          <FormInput name='code' labelTx='questionnaire:passcode' required placeholder='yyyymmdd' />
          <BaseButton className='!w-full' onClick={methods.handleSubmit(submit)}>
            {t('verify_code:btn_confirm')}
          </BaseButton>
        </FormProvider>
      </div>
    </div>
  );
};
export default ExternalQuestionAuthenticationPage;
