// Account master list
export const ACCOUNT_MASTER_LIST = 'ACCOUNT_MASTER_LIST';
export const SEARCH_ACCOUNT_MASTER_LIST = 'SEARCH';
export const TABLE_ACCOUNT_MASTER_LIST = 'TABLE';
export const CREATE_NEW_ACCOUNT_MASTER_LIST = 'CREATE_NEW_ACCOUNT_MASTER';

// Account master list => Table
export const PAGING_TABLE_ACCOUNT_MASTER = 'PAGING_TABLE_ACCOUNT_MASTER';

// Account master filter
export const ACCOUNT_MASTER_REQUEST_FILTER_COMPANY = 'ACCOUNT_MASTER_REQUEST_FILTER_COMPANY';

// Account master update
export const APPROVE_REJECT_ACCOUNT_MASTER_LIST = 'APPROVE_REJECT_ACCOUNT_MASTER';
export const UPDATE_ACCOUNT_MASTER_LIST = 'UPDATE_ACCOUNT_MASTER';
