import { UPLOAD_STATUS } from '@/utils/constants/AppConstants';

import { DEFAULT_VALUE_REGISTER_SCENARIO_E, SCENARIO_TYPES } from '../../contants';
import { DEFAULT_FORM_OBSERVER } from '../../contants/projectExternalProxy';

export const convertResponseForEType = (data: any) => {
  const formDefault = { ...DEFAULT_FORM_OBSERVER, scenarioType: SCENARIO_TYPES.SCENARIO_E };
  if (!data) {
    formDefault.steps = [
      {
        ...DEFAULT_VALUE_REGISTER_SCENARIO_E
      }
    ];
  } else {
    const result = parseHtmlString(data?.note);
    const phoneNumber = result?.['value-phone-scenario-e'] || '';
    const requestContent = result?.['value-note-scenario-e'] || '';
    const projectAttachments = data?.attachments.map((item: any) => ({
      ...item,
      status: UPLOAD_STATUS.DONE
    }));
    const detail = {
      ...data,
      phoneNumber,
      requestContent,
      projectAttachments
    };
    formDefault.steps = [detail];
    formDefault.selectedStep = 2;
  }

  return formDefault;
};

export const parseHtmlString = (htmlString: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const phoneNumber = doc.getElementById('value-phone-scenario-e')?.textContent || '';
  const requestContent = doc.getElementById('value-note-scenario-e')?.innerHTML || '';
  return {
    'value-phone-scenario-e': phoneNumber,
    'value-note-scenario-e': requestContent
  };
};
