import { AnyAction, configureStore } from '@reduxjs/toolkit';
import { ThunkDispatch, thunk } from 'redux-thunk';

import globalReducer from './globalReducer';
import masterReduce from './master/masterReducer';
import noticeReducer from './notice/noticeReducer';
import { default as projectReducerV2 } from './project-v2/projectReducer';
import projectReducer from './project/projectReducer';
import questionnaireReducer from './questionnaire/questionnaireReducer';
import templateReducer from './templates/templateReducer';

export const store = configureStore({
  reducer: {
    global: globalReducer,
    template: templateReducer,
    project: projectReducer,
    projectV2: projectReducerV2,
    master: masterReduce,
    questionnaire: questionnaireReducer,
    notice: noticeReducer
  },
  middleware: [thunk]
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch & ThunkDispatch<RootState, void, AnyAction>;
