import Inquiry from '@/pages/inquiry';

import { V } from '@/hooks/useAuthorization';

import { EXTERNAL_ADMIN } from '@/utils/constants/AppConstants';
import { INQUIRY } from '@/utils/constants/AuthorizationProjectManagement';
import { INQUIRY_URL } from '@/utils/constants/RouteContants';
import { IRoute } from '@/utils/interfaces/route';

const InquiryRoutes: IRoute[] = [
  {
    path: INQUIRY_URL,
    name: INQUIRY,
    action: V,
    element: <Inquiry />,
    auth: true,
    role: [EXTERNAL_ADMIN]
  }
];

export default InquiryRoutes;
