import React from 'react';
import { useTranslation } from 'react-i18next';

import { IStepFour } from '@/pages/project-management/add-edit/models/scenarioB';

import { BaseCard } from '@/components/base-card/BaseCard';

import { DataViewer } from '@/utils/helpers/common';

import { LANGUAGE_OPTIONS } from '../../constants';
import CardInfo from './CardInfo';

interface ILocalPresentativeInfoProps {
  data: IStepFour;
}
const LocalPresentativeInfo = ({ data }: ILocalPresentativeInfoProps) => {
  const { t } = useTranslation();
  const { projectLocalSupporters = [] } = data ?? {};
  if (!projectLocalSupporters.length) return <></>;
  return (
    <div className='flex flex-col gap-[8px]'>
      {projectLocalSupporters.map((supporter, i) => {
        const { name, address, email, language } = supporter ?? {};
        let answered = 0;
        if (name) answered++;
        if (address) answered++;
        if (email) answered++;
        if (language && i === 0) answered++;
        let languageLabel = LANGUAGE_OPTIONS.find((item) => item.value === language)?.label;
        languageLabel = languageLabel ? t(languageLabel) : '';
        return (
          <BaseCard
            title={`${t('project:scenario_b:local_supporter')}${i + 1}`}
            total={i === 0 ? 4 : 3}
            questions={answered}
            className='scenario-b-card'
          >
            <CardInfo
              columns={[
                {
                  items: [
                    { title: DataViewer.display(name), label: 'basic_information:local_contact_name' },
                    { title: DataViewer.display(address), label: 'basic_information:email_address' }
                  ]
                },
                {
                  items: [
                    { title: DataViewer.display(email), label: 'basic_information:local_contact_affiliation_company_name' },
                    { title: i === 0 ? DataViewer.display(languageLabel) : ' ', label: i === 0 ? 'basic_information:language' : ' ' }
                  ]
                }
              ]}
            />
          </BaseCard>
        );
      })}
    </div>
  );
};

export default LocalPresentativeInfo;
