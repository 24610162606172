import { ConfigProvider } from 'antd';

import { ColorDefault } from '../utils/themes/color';

type ThemeProviderProps = {
  children: React.ReactNode;
};

const ThemeProvider = ({ children }: ThemeProviderProps) => (
  <ConfigProvider
    theme={{
      hashed: false,
      components: {
        Input: {
          borderRadius: 10,
          colorBorder: ColorDefault.gray3
        },
        Select: {
          colorBorder: ColorDefault.gray3,
          borderRadius: 10,
          borderRadiusLG: 10
        },
        Button: {
          borderRadiusLG: 14,
          controlHeight: 40,
          controlHeightLG: 50,
          controlHeightSM: 32
        },
        Modal: {
          borderRadiusLG: 14
        },
        Upload: {
          borderRadius: 14
        },
        DatePicker: {
          colorBorder: ColorDefault.gray3,
          borderRadius: 10
        },
        Card: {
          borderRadiusLG: 14
        },
        Table: {
          colorBorderSecondary: ColorDefault.gray2,
          colorBorder: ColorDefault.gray2,
          paddingContentVerticalLG: 8,
          paddingContentVerticalSM: 8,
          paddingContentVertical: 8,
          paddingContentHorizontal: 12,
          paddingContentHorizontalLG: 12,
          paddingContentHorizontalSM: 12,
          padding: 12
        },
        Checkbox: {
          colorBorder: ColorDefault.gray3
        },
        Radio: {
          colorBorder: ColorDefault.gray3,
          dotSize: 10
        }
      },
      token: {
        colorPrimaryBg: ColorDefault.gray1,
        colorPrimary: ColorDefault.green,
        colorTextBase: ColorDefault.black,
        controlItemBgActive: ColorDefault.green10,
        fontFamily: 'NotoSansJP, sans-serif'
      }
    }}
  >
    {children}
  </ConfigProvider>
);

export default ThemeProvider;
