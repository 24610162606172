import { Space } from 'antd';
import React from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BaseButton } from '@/components/base-button/BaseButton';
import FormBaseFilter from '@/components/base-filter';
import SearchBox from '@/components/base-filter/SearchBox';

import Filter from './Filter';

interface IHeaderProps {
  onFilter: (values: FieldValues) => void;
  onSearch: (values: FieldValues) => void;
  title?: string;
  form?: any;
  data?: any;
  total: number;
  valueFilter?: {
    formValue: {};
  };
  informationUser: any;
  uiHidden?: boolean;
  buttonHidden?: boolean;
  showUiHidden: (value: boolean) => void;
}

const Header = ({
  onFilter,
  onSearch,
  title,
  form,
  data,
  total,
  valueFilter,
  informationUser,
  uiHidden,
  buttonHidden,
  showUiHidden
}: IHeaderProps) => {
  const formMethod = form;
  const { t } = useTranslation();
  const formFilter = useForm({ defaultValues: valueFilter?.formValue });

  return (
    <FormProvider {...formMethod}>
      <Space size={16} className='form-header w-full justify-space-between mb-4'>
        <span className='title-24'>{title}</span>
        <Space size={16}>
          {buttonHidden && (
            <>
              {uiHidden ? (
                <div className='min-w-[120px]'>
                  <BaseButton block size='medium' onClick={() => showUiHidden(false)} type='tertiary'>
                    {t('comment:cancel_button')}
                  </BaseButton>
                </div>
              ) : (
                <div className='min-w-[120px]'>
                  <BaseButton block size='medium' type='tertiary' onClick={() => showUiHidden(true)} disabled={data.length === 0}>
                    {t('notification:mark_as')}
                  </BaseButton>
                </div>
              )}
            </>
          )}
          <div className='min-w-[182px]'>
            <div className='mb-1'>
              <FormProvider {...formFilter}>
                <FormBaseFilter
                  onSubmit={(values) => {
                    onFilter(values);
                  }}
                  tagSection={null}
                  searchBox={null}
                  primaryAction={{
                    label: 'notification:filter_or_sort',
                    name: 'primary',
                    popoverContent: (
                      <Filter
                        informationUser={informationUser}
                        defaultValues={formFilter.getValues('primary')}
                        onFilter={(values) => {
                          formFilter.reset((previous) => ({ ...previous, primary: { ...values } }));
                          formFilter.handleSubmit((values) => onFilter(values))();
                        }}
                      />
                    ),
                    totalFiltered: total
                  }}
                  secondaryAction={null}
                />
              </FormProvider>
            </div>
          </div>
        </Space>
      </Space>
      <div className='box-search'>
        <SearchBox
          control={formFilter.control}
          name={'searchNotice'}
          maxLength={128}
          onSubmit={formFilter.handleSubmit(onSearch)}
          placeholder={String(t('notification:search'))}
        />
      </div>
    </FormProvider>
  );
};

export default Header;
