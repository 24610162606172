import { DefaultOptionType } from 'antd/es/select';
import { useEffect, useState } from 'react';

import { getAllCompany } from '@/utils/services/ProjectApiService';

const useCompany = () => {
  const [loadingCompanyForAccount, setLoadingCompanyForAccount] = useState<boolean>(false);
  const [companyOptions, setCompanyOptions] = useState<DefaultOptionType[]>([]);

  useEffect(() => {
    getCompanyData();
  }, []);

  const getCompanyData = async () => {
    setLoadingCompanyForAccount(true);
    try {
      const res = await getAllCompany();
      if (res?.data) {
        const dataTemp =
          res.data?.map((item: DefaultOptionType) => {
            return {
              label: item.name,
              value: item.id,
              id: item.id
            };
          }) || [];
        setCompanyOptions([...dataTemp]);
      }
    } catch (error) {
      setCompanyOptions([]);
    } finally {
      setLoadingCompanyForAccount(false);
    }
  };

  return {
    companyOptions,
    loadingCompanyForAccount
  };
};

export default useCompany;
