import type { ColumnsType } from 'antd/es/table';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import EmptyTextData from '@/components/EmptyData/EmptyTextData';

import { DataViewer } from '@/utils/helpers/common';

import BaseTable from '../../../../../components/table/BaseTable';
import { Ii18n } from '../../../../../utils/interfaces/i18n';
import { IInternalDocument } from '../../modules';

import './ViewTemplateTaskList.scss';

interface IDocumentInternalProps {
  internalDocument: IInternalDocument[];
}
const ViewTemplateTaskList: React.FC<IDocumentInternalProps> = ({ internalDocument }) => {
  const { t }: Ii18n = useTranslation(); // declare i18n variable
  /**
   * Declare state
   */
  const [dataSource] = useState<IInternalDocument[]>(internalDocument);

  /**
   * Table config
   */

  const columns: ColumnsType<IInternalDocument> = [
    {
      title: 'NO',
      dataIndex: 'no',
      key: 'no',
      width: 50,
      className: 'max-w-[50px]',
      render: (_, __, index) => index + 1
    },
    {
      title: t('reference_material:reference_material').toUpperCase(),
      dataIndex: 'typeId',
      key: 'typeId',
      width: 440,
      className: 'max-w-[440px]',
      render: (_: string, record: IInternalDocument) => <p>{DataViewer.display(record?.documentName)}</p>
    },
    {
      title: t('template_layout:number_of_copies').toUpperCase(),
      dataIndex: 'description',
      key: 'description',
      sorter: false,
      width: 230,
      className: 'max-w-[230px]',
      render: (_: string, record: IInternalDocument) => <p>{DataViewer.display(record?.numberOfCopy)}</p>
    },
    {
      title: t('template_layout:notes').toUpperCase(),
      dataIndex: 'description',
      key: 'description',
      width: 950,
      className: 'max-w-[650px]',
      sorter: false,
      render: (_: string, record: IInternalDocument) => {
        return <p dangerouslySetInnerHTML={{ __html: DataViewer.displayAsSanitizeHTML(record?.note) }}></p>;
      }
    }
  ];

  return (
    <div id='view-template-task-list'>
      <BaseTable
        rowKey='id'
        columns={columns}
        dataSource={dataSource}
        showAddLine={false}
        pagination={false}
        classEmptyCustom='py-[8px]'
        emptyDataAlert={<EmptyTextData className='text-center' text={t('template_layout:emty_table') ?? ''} />}
      />
    </div>
  );
};

export default ViewTemplateTaskList;
