import { Col, Row, Tooltip } from 'antd';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BaseButton } from '@/components/base-button/BaseButton';
import { BaseCard } from '@/components/base-card/BaseCard';
import { FormInput } from '@/components/form-input/FormInput';

import useAuthorization from '@/hooks/useAuthorization';

import { ERROR_CODE } from '@/utils/constants/AppConstants';

import CheckedIcon from '@/assets/icons/Checked.svg';
import WarningWaitingApprove from '@/assets/icons/WarningWaitingApprove.svg';

import { isEmail } from '../../type-b/helpers';

interface StakeHolderInputProps {
  pathEmail: string;
  field: any;
  index: number;
  errorInput: any;
  showIconRemove: boolean;
  questions: number;
  required?: boolean;
  companyId: string;
  handleRemove: (index: number) => void;
  handleBlur: (index: number) => void;
  openPopupCreateAccount: (index: number) => void;
}

const StakeHolderInput = ({
  pathEmail,
  field,
  index,
  errorInput,
  showIconRemove,
  questions,
  required = true,
  companyId,
  handleRemove,
  handleBlur,
  openPopupCreateAccount
}: StakeHolderInputProps) => {
  const error = errorInput?.stakeholderEmail;
  const { isExternalAdmin, isInternalAdmin } = useAuthorization();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const form = useFormContext();
  return (
    <BaseCard
      className='mt-3'
      key={field.id || field.uid || field.formId}
      showIconRemove={showIconRemove}
      index={index}
      onRemove={handleRemove}
      title={`${t('project:add:project_stakeholders:person_involved_project')}${index + 1}`}
      total={1}
      questions={questions}
    >
      <Row gutter={16}>
        <Col span={12}>
          <FormInput
            required={required}
            disabled={field.stakeHolderStatus === false && !field?.waitCreateAccount && !field?.rejected}
            warning={!error && !!field?.waitCreateAccount}
            warningMsg={t('common:MSG_P_032') || ''}
            defaultValue={form.watch(pathEmail)}
            name={pathEmail}
            labelTx='project:add:project_stakeholders:email_address'
            placeholderTx={String(t('common:MSG_001_textbox', { field: t('project:add:project_stakeholders:email_address') }))}
            onBlur={async () => {
              await handleBlur(index);
              setIsLoading(false);
            }}
            handleOnChange={() => setIsLoading(true)}
          />
        </Col>

        {isEmail(field.stakeholderEmail) && companyId && (
          <Col span={12}>
            {error && error?.type === ERROR_CODE.ENTITY_NOT_FOUND && (
              <div className='mt-[30px]'>
                <BaseButton type='secondary' size='medium' onClick={() => openPopupCreateAccount(index)}>
                  {isExternalAdmin || isInternalAdmin
                    ? t('project:add:project_stakeholders:create_account')
                    : t('project:add:project_stakeholders:request_create_account')}
                </BaseButton>
              </div>
            )}
            {!error &&
              !isLoading &&
              field.rejected !== undefined &&
              !field.rejected &&
              field.waitCreateAccount !== undefined &&
              !field?.waitCreateAccount && (
                <div className='flex flex-col gap-[8px]'>
                  <span className='font-medium leading-[22.4px]'>{t('stakeholder:label:user_name')}</span>
                  <div className='flex items-center h-[38px] overflow-hidden'>
                    <Tooltip placement='top' className='mr-2 font-medium truncate' title={field.stakeholderName}>
                      {field.stakeHolderStatus === false && !field?.waitCreateAccount && !field?.rejected ? `(${t('announcement:invalid')})` : ''}{' '}
                      {field.stakeholderName}
                    </Tooltip>
                    <span className='w-[18px] h-[18px]'>
                      <CheckedIcon width={18} height={18} className='icon-check' />
                    </span>
                  </div>
                </div>
              )}
            {!error && !isLoading && field?.waitCreateAccount && (
              <div className='flex items-center overflow-hidden'>
                <span className='mr-2 font-medium truncate max-w-full whitespace-pre'>
                  <Tooltip placement='top' title={field.stakeholderName}>
                    {field.stakeholderName}
                  </Tooltip>
                </span>
                <span className='w-[18px] h-[18px]'>
                  <WarningWaitingApprove />
                </span>
              </div>
            )}
          </Col>
        )}
      </Row>
    </BaseCard>
  );
};
export default StakeHolderInput;
