import * as yup from 'yup';

import { NOTE_FILED_MAX_LENGTH, NOTE_MAX_LENGTH, TEXT_FIELD_MIN_LENGTH, UPLOAD_STATUS } from '@/utils/constants/AppConstants';
import { stringifyObjectValidateYup } from '@/utils/method';

import { ATTACHMENT_STATUS } from '../constants';

const createFileStatusTest = () =>
  yup.string().test(
    'file-invalid',
    stringifyObjectValidateYup({
      keyT: 'common:MSG_FILE_INVALID'
    }),
    (value) => {
      return value !== UPLOAD_STATUS.ERROR;
    }
  );

const createFileObject = () =>
  yup.object().shape({
    status: createFileStatusTest(),
    rejectReason: yup
      .string()
      .nullable()
      .test({
        name: 'rejectReason',
        exclusive: false,
        params: {},
        message: stringifyObjectValidateYup({
          keyT: 'common:MSG_C_001_input',
          optionsTx: { field: 'announcement:reason_remand:label', limited: TEXT_FIELD_MIN_LENGTH }
        }),
        test: (value, ctx) => {
          if (ctx.parent.status === ATTACHMENT_STATUS.REJECT) {
            if (!value || value.length < TEXT_FIELD_MIN_LENGTH) {
              return false;
            }
            return true;
          }
          return true;
        }
      })
      .htmlStrippedMaxLength(
        NOTE_FILED_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: { field: 'announcement:reason_remand:label', limited: NOTE_FILED_MAX_LENGTH }
        })
      )
    // })
  });
export const FormAddTaskOfProjectValidation = yup.object().shape({
  category: yup.string().required(
    stringifyObjectValidateYup({
      keyT: 'common:MSG_001_select',
      optionsTx: { field: 'project:template_layout:category' }
    })
  ),
  processId: yup.string().required(
    stringifyObjectValidateYup({
      keyT: 'common:MSG_001_select',
      optionsTx: { field: 'project:filter:process:label' }
    })
  ),
  taskMasterId: yup.string().required(
    stringifyObjectValidateYup({
      keyT: 'common:MSG_001_select',
      optionsTx: { field: 'project:template_layout:title' }
    })
  ),
  detail: yup
    .string()
    .nullable()
    .requiredTextEditorChange(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_001_input',
        optionsTx: { field: 'project:template_layout:task_content' }
      })
    )
    .htmlStrippedMaxLength(
      NOTE_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'basic_information:note', limited: NOTE_MAX_LENGTH }
      })
    ),
  projectTaskAttachmentFiles: yup.array().of(createFileObject()),
  taskRequireDocuments: yup.array().of(
    yup.object().shape({
      projectRequiredDocumentId: yup.string().required(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_001_select',
          optionsTx: { field: 'project:task_of_project:document_name' }
        })
      ),
      taskRequireDocumentAttachmentFiles: yup.array().of(createFileObject())
    })
  ),
  taskInternalDocuments: yup.array().of(
    yup.object().shape({
      projectInternalDocumentId: yup.string().required(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_001_select',
          optionsTx: { field: 'project:task_of_project:document_name' }
        })
      )
    })
  )
});
export const FormEditTaskOfProjectValidation = yup.object().shape({
  detail: yup
    .string()
    .nullable()
    .requiredTextEditorChange(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_001_input',
        optionsTx: { field: 'project:template_layout:task_content' }
      })
    )
    .htmlStrippedMaxLength(
      NOTE_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'basic_information:note', limited: NOTE_MAX_LENGTH }
      })
    ),
  projectTaskAttachmentFiles: yup.array().of(createFileObject()),
  taskRequireDocuments: yup.array().of(
    yup.object().shape({
      projectRequiredDocumentId: yup.string().required(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_001_select',
          optionsTx: { field: 'project:task_of_project:document_name' }
        })
      ),
      taskRequireDocumentAttachmentFiles: yup.array().of(createFileObject())
    })
  ),
  taskInternalDocuments: yup.array().of(
    yup.object().shape({
      projectInternalDocumentId: yup.string().required(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_001_select',
          optionsTx: { field: 'project:task_of_project:document_name' }
        })
      )
    })
  )
});
