import { useAppDispatch } from '@/hooks';
import { RcFile } from 'antd/es/upload';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import AppTooltip from '@/components/app-tooltip/AppTooltip';
import { BaseButton } from '@/components/base-button/BaseButton';
import TableBase, { FetchColumnsType } from '@/components/common/table-base';
import { ConfirmCancelEditing } from '@/components/confirm-popup';
import { IconDownload } from '@/components/icon-svg/IconSvg';

import { setAlertNotification } from '@/redux/globalReducer';

import { ERROR_CODE, TYPE } from '@/utils/constants/AppConstants';
import { PROJECT_LIST_URL, VIEW_PROJECT_URL } from '@/utils/constants/RouteContants';
import { DataViewer } from '@/utils/helpers/common';
import { handleDownloadSingleFile } from '@/utils/helpers/fileHelper';
import { batchRegistrationProject } from '@/utils/services/ProjectApiService';

import UploadIcon from '@assets/icons/Upload.svg';

import { FormAttachment } from './components/FormAttachment';
import { BLOB_PATH_FILE, STATUS_FILE, TYPE_DONE, TYPE_NONE, TYPE_SUBMITTED } from './constants';
import { IResponseBatchRegister } from './model';

export default function BatchRegistrationPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [statusUpload, setStatusUpload] = useState<STATUS_FILE>(TYPE_NONE);
  const [uploadedFile, setUploadedFile] = useState<RcFile | null>(null);
  const [projectLists, setProjectLists] = useState<IResponseBatchRegister[]>([]);
  const [isDirty, setIsDirty] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (statusUpload === TYPE_NONE || statusUpload === TYPE_DONE) {
      return setIsDirty(false);
    }
    setIsDirty(true);
  }, [statusUpload]);

  const onDownloadFile = () => {
    return handleDownloadSingleFile(BLOB_PATH_FILE, t('batch_registration:filename'));
  };

  const onCancel = async () => {
    navigate(PROJECT_LIST_URL);
  };

  const onBatchRegistration = async () => {
    if (statusUpload === TYPE_SUBMITTED) {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append('file', uploadedFile as RcFile);
        const { data } = await batchRegistrationProject(formData);
        if (data) {
          setProjectLists([
            ...data.map((el: IResponseBatchRegister, i: number) => {
              return {
                ...el,
                no: i + 1
              };
            })
          ]);
          setStatusUpload(TYPE_DONE);
        }
      } catch (e: any) {
        if (e?.response?.data?.errorCode === ERROR_CODE.VALIDATION) {
          dispatch(
            setAlertNotification({
              show: true,
              message: t('common:MSG_C_015'),
              type: TYPE.ERROR
            })
          );
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const renderTextToHtml = (text: string) => (
    <div dangerouslySetInnerHTML={{ __html: DataViewer.displayAsSanitizeHTML(text.replaceAll('\n', '<br />'), ' ') }} />
  );

  const columns: FetchColumnsType<any> = [
    {
      title: 'NO',
      key: 'no',
      dataIndex: 'no',
      width: 50,
      className: 'max-w-[50px] h-[38px]',
      align: 'center',
      render: (value: number) => {
        return <div className='whitespace-nowrap'>{value}</div>;
      }
    },
    {
      title: t('project:fields:project_id'),
      key: 'projectId',
      dataIndex: 'projectId',
      className: 'truncate',
      render(text, record) {
        return (
          <AppTooltip title={record.projectCode}>
            <Link to={VIEW_PROJECT_URL(text)} style={{ display: 'inline' }} target='_blank'>
              <div className='inline-block truncate text-[14px]'>
                <span className='underline text-[14px]'>{record.projectCode}</span>
              </div>
            </Link>
          </AppTooltip>
        );
      }
    },
    {
      title: t('project:fields:applicant_name'),
      dataIndex: 'applicantName',
      width: 200,
      render: (text) => renderTextToHtml(text),
      tooltip: (text) => renderTextToHtml(text),
      ellipsis: {
        showTitle: false
      }
    },
    {
      title: t('project:fields:company_name'),
      dataIndex: 'company',
      width: 250,
      render: (text) => DataViewer.display(text),
      tooltip: (text) => DataViewer.display(text),
      ellipsis: {
        showTitle: false
      }
    },
    {
      title: t('project:fields:country_of_travel'),
      dataIndex: 'country',
      key: 'country',
      render: (text) => DataViewer.display(text),
      tooltip: (text) => DataViewer.display(text),
      ellipsis: {
        showTitle: false
      },
      width: 200
    }
  ];

  return (
    <>
      <div className='flex items-center justify-between mb-[12px]'>
        <p className='truncate text-24 body-700 capitalize'>{t('batch_registration:title')}</p>
        <div className='flex items-center gap-[16px]'>
          <BaseButton disabled={statusUpload === TYPE_DONE} block size='medium' type='tertiary' className='w-[120px]' onClick={onCancel}>
            {t('button:cancel')}
          </BaseButton>
          <BaseButton
            disabled={statusUpload !== TYPE_SUBMITTED || loading}
            block
            type='secondary'
            size='medium'
            icon={<UploadIcon />}
            className='w-[120px]'
            onClick={onBatchRegistration}
          >
            {t('batch_registration:title')}
          </BaseButton>
        </div>
      </div>
      <div className='bg-white rounded-[10px] border border-gray2 py-[16px] px-[20px]'>
        <div className='mb-[12px]'>
          <p className='mb-[8px] text-16 font-semibold leading-[160%]'>{t('batch_registration:templateInput')}</p>
          <BaseButton type='secondary' size='medium' icon={<IconDownload className='flex' />} onClick={onDownloadFile}>
            {t('batch_registration:downloadFile')}
          </BaseButton>
        </div>
        <div className='form-upload-file'>
          <p className='mb-[8px] text-16 font-semibold leading-[160%]'>{t('batch_registration:registerProject')}</p>
          <FormAttachment
            statusUpload={statusUpload}
            uploadedFile={uploadedFile}
            setStatusUpload={setStatusUpload}
            setUploadedFile={setUploadedFile}
          />
        </div>
        {statusUpload === TYPE_DONE && !!projectLists?.length && (
          <>
            <h3 className='mt-[12px] mb-[8px] text-16 font-semibold leading-[160%]'>{t('batch_registration:applicationResults')}</h3>
            <TableBase className='w-full' rowSelection={null} columns={columns} dataSource={projectLists} />
          </>
        )}
      </div>
      <ConfirmCancelEditing condition={isDirty} />
    </>
  );
}
