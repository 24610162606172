import { useTranslation } from 'react-i18next';

import { IBaseInputProps } from '@/components/common/base-input';

import { createMatrix } from '@/utils/helpers/globalHelper';

import { mappingRenderFunction } from '../../helpers';
import { CONTENT_TYPE } from '../../modals';
import Table, { ITableItem } from './Table';

export interface ITextMultiProps extends Omit<IBaseInputProps, 'value'> {
  items?: ITextMultiItem[];
  maxCol?: number;
  readOnly?: boolean;
  name?: string;
  translateFlg?: boolean;
}

export interface ITextMultiItem {
  type: string;
  title: string;
  value?: string;
}

const TextMulti: React.FC<ITextMultiProps> = ({ items, name, readOnly = false, translateFlg, maxCol = 3 }) => {
  const { t } = useTranslation();
  const matrixItems = createMatrix(items ?? [], maxCol);
  const itemsRender = matrixItems.map((row, rowIndex) => {
    return row.reduce((arr: ITableItem[], item, index) => {
      const func = mappingRenderFunction[item.type];
      const fieldName = [CONTENT_TYPE.RADIO, CONTENT_TYPE.CHECKBOX, CONTENT_TYPE.DROPDOWN].includes(item.type)
        ? name?.replace('answeredEn', 'answered')
        : name;
      arr.push({ className: 'w-[100px]', content: <span className='body-700'>{item.title}</span> });
      if (func) {
        arr.push(
          ...(func({
            question: {
              ...item,
              labelInput:
                CONTENT_TYPE.DATETIMEFROM_TO === item.type
                  ? [t('project:question_detail:type:date_time_from'), t('project:question_detail:type:date_time_to')]
                  : undefined
            },
            readOnly,
            name: name ? `${fieldName}.${rowIndex * maxCol + index}` : '',
            isTranslateData: translateFlg
          })?.map((i) => ({
            content: i
          })) ?? [])
        );
      } else {
        arr.push({ content: <></> });
      }

      return arr;
    }, []);
  }, []);

  return <Table items={itemsRender} titleHeader={undefined} />;
};

export default TextMulti;
