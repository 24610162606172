/* eslint-disable no-useless-escape */
export const REGEX_TEXT = /[^\d]/gi;
export const REGEX_NUMBER_LETTER = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/;
export const REGEX_EMAIL = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/;
export const REGEX_TWELVE_CHARACTERS = /^.{12,}$/;
export const REGEX_NOT_SPACES = /^\S+$/;
export const REGEX_REPETITIVE = /^(.)\1+$/;
export const PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{12,}$/;
export const REGEX_DIGIT = /\D+/g;
export const REGEX_MAX_LENGTH = /^.{0,128}$/;
export const REGEX_EMAIL_RULE = /^[A-Za-z0-9]+(?:[.!#\$%&'*+\/=?^_`{|}~-][A-Za-z0-9]+)*@[A-Za-z0-9-]+\.[A-Za-z]{2,}(?:\.[A-Za-z]{2,})?$/;
export const REGEX_BRACKETS = /\{/;
export const REGEX_FULL_SIZE = /^[\u3040-\u309F\u4E00-\u9FFF\u30A0-\u30FF\uFF10-\uFF19\uFF21-\uFF3A\uFF41-\uFF5A]*$/;
export const REGEX_LATIN_WITH_SPECIAL_CHARACTER = /^[a-zA-Z0-9 !@#$%\^&*()_+-={}\[\]|\\:;"'<>,\.?\/]*$/;
export const REGEX_FULL_SIZE_WITH_SPECIAL_CHARACTER =
  /^[\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FAF\uff10-\uff19\uff21-\uff3a\uff41-\uff5a!@#$%\^&*()_+-={}\[\]|\\:;"'<>,\.?\/]*$/;
export const REGEX_FULL_SIZE_AND_SPECIAL_CHARACTER_FULL_SIZE = /^[\u3040-\u309F\u4E00-\u9FFF\u30A0-\u30FF\uFF00-\uFF65\uFFE0-\uFFE6\u3000-\u303F]*$/;
export const REGEX_HALF_SIZE = /[ｦ-ﾟ ､0-9a-zA-Z]*/;
export const REGEX_HALF_SIZE_AND_SPECIAL_CHARACTER = /^[\uFF66-\uFF9F\uFFE8-\uFFEE\u0020-\u007F]*$/;
export const REGEX_INPUT_CHARACTER = /[\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FAF\uff10-\uff19\uff21-\uff3a\uff41-\uff5aｦ-ﾟ ､0-9a-zA-Z]/;
