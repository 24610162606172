import dayjs from 'dayjs';

import { showBasePopup } from '../components/base-popup/BasePopup';
import { setLoadingPage } from '../redux/globalReducer';
import { setCommentId, setCustomerRequest, setDataCustomerComment } from '../redux/project/projectReducer';
import { sanitizeAndRemoveInsecureLinks } from '../utils/helpers/globalHelper';
import { IDataCustomerRequest, IDataCustomerRequestDetails, IDocuments, IUserCustomer } from '../utils/interfaces/customer-request';
import { getCustomerRequestById } from '../utils/services/ProjectApiService';
import { useAppDispatch, useAppSelector } from './index';
import useAuthorization from './useAuthorization';

const useCustomerRequest = () => {
  const dispatch = useAppDispatch();
  const customerRequest = useAppSelector((state) => state?.project?.customerRequest);
  const projectDetail = useAppSelector((state) => state?.project?.projectDetails);
  const customerRequestComment = useAppSelector((state) => state?.project?.customerRequestComment);
  const { isExternalRole } = useAuthorization();

  const dataMotPick = {
    id: projectDetail?.mot_pic ?? '',
    userName: projectDetail?.motPicName ?? '',
    isTaskPick: true,
    email: projectDetail?.motPicEmail ?? '',
    companyName: projectDetail?.company_name ?? ''
  };

  const getDataCustomerRequestById = async (id: string) => {
    try {
      dispatch(setLoadingPage(true));
      const { data } = await getCustomerRequestById(id);
      return { ...data, content: sanitizeAndRemoveInsecureLinks(data?.content) };
    } catch (error) {
      return {};
    } finally {
      dispatch(setLoadingPage(false));
    }
  };

  const showModalEdit = async (data: IDataCustomerRequest) => {
    if (customerRequest.isMinimized) {
      const customerRequestData = await getDataCustomerRequestById(data.id);
      if (customerRequest?.dataCurrent?.id !== data.id) {
        if (customerRequest.isFormValueChanged) {
          const updatedCustomerRequest = {
            ...customerRequest,
            isModalOpen: false,
            isPublic: true
          };
          const showPopup = await showBasePopup({ type: 'discard' });
          if (showPopup === 'confirm') {
            updatedCustomerRequest.isModalOpen = true;
            updatedCustomerRequest.isMinimized = false;
            updatedCustomerRequest.isFormValueChanged = false;
            updatedCustomerRequest.dataCurrent = customerRequestData;
            updatedCustomerRequest.isEdit = !data.draft;
            updatedCustomerRequest.isDraft = !!data.draft;
            updatedCustomerRequest.isResetActiveKey = true;
            dispatch(setDataCustomerComment({ hasDeleted: false, isKeepValueWhenShowPopup: false, isDirtyComment: false }));
            dispatch(setCommentId(''));
          } else {
            updatedCustomerRequest.isMinimized = true;
          }
          dispatch(setCustomerRequest(updatedCustomerRequest));
        } else {
          dispatch(
            setCustomerRequest({
              ...customerRequest,
              dataCurrent: customerRequestData,
              isModalOpen: true,
              isMinimized: false,
              isEdit: !data.draft,
              isDraft: !!data.draft,
              isResetActiveKey: true
            })
          );
          dispatch(setDataCustomerComment({ hasDeleted: false, isKeepValueWhenShowPopup: false, isDirtyComment: false }));
          dispatch(setCommentId(''));
        }
      } else {
        const customerRequestData = await getDataCustomerRequestById(data.id);
        dispatch(
          setCustomerRequest({
            ...customerRequest,
            isModalOpen: true,
            isMinimized: false,
            isEdit: !data.draft,
            isDraft: !!data.draft,
            dataCurrent: !customerRequest.isFormValueChanged ? customerRequestData : customerRequest.dataCurrent
          })
        );
      }
    } else {
      const customerRequestData = await getDataCustomerRequestById(data.id);
      const updatedCustomerRequest = {
        ...customerRequest,
        isModalOpen: true,
        isMinimized: false,
        dataCurrent: customerRequest.isFormValueChanged && {
          ...customerRequestData,
          content: sanitizeAndRemoveInsecureLinks(customerRequestData?.content)
        },
        isFormValueChanged: false,
        shouldClearAllData: false
      };
      if (data.draft) {
        updatedCustomerRequest.isDraft = true;
        updatedCustomerRequest.isEdit = false;
        updatedCustomerRequest.isPublic = !customerRequestData.private;
        updatedCustomerRequest.dataCurrent = customerRequestData;
      } else {
        updatedCustomerRequest.isDraft = false;
        updatedCustomerRequest.isEdit = true;
        updatedCustomerRequest.dataCurrent = customerRequestData;
      }
      setTimeout(() => {
        dispatch(setCustomerRequest(updatedCustomerRequest));
      }, 300);
    }
  };

  const handleResize = (check: boolean) => {
    dispatch(
      setCustomerRequest({
        ...customerRequest,
        isZoomed: check,
        isMinimized: false,
        isModalOpen: true
      })
    );
  };

  const handleMinimizePopUp = (isEdit: boolean) => {
    dispatch(
      setCustomerRequest({
        ...customerRequest,
        isMinimized: true,
        isModalOpen: false,
        shouldClearAllData: false,
        isEdit,
        isResetActiveKey: false
      })
    );
  };

  const showPopupCancelMinimize = async () => {
    const updatedCustomerRequest = {
      ...customerRequest,
      isMinimized: false
    };
    dispatch(setCustomerRequest({ isMinimized: false }));
    if (customerRequest.isFormValueChanged) {
      const showPopup = await showBasePopup({ type: 'discard' });
      if (showPopup === 'confirm') {
        updatedCustomerRequest.isEdit = false;
        updatedCustomerRequest.isPublic = true;
        updatedCustomerRequest.isCloseMinimized = true;
        updatedCustomerRequest.isFormValueChanged = false;
        updatedCustomerRequest.dataCurrent = undefined;
        dispatch(setDataCustomerComment({ hasDeleted: false, isKeepValueWhenShowPopup: false, isDirtyComment: false }));
        dispatch(setCommentId(''));
      } else {
        updatedCustomerRequest.isMinimized = true;
        const idRecord = localStorage.getItem('idCustomerRequest');
        if (idRecord) {
          const customerRequestData = await getDataCustomerRequestById(idRecord);
          updatedCustomerRequest.dataCurrent = customerRequestData;
          dispatch(setCustomerRequest(updatedCustomerRequest));
        }
        dispatch(setDataCustomerComment({ ...customerRequestComment, isActive: true, isKeepValueWhenShowPopup: true, isDirtyComment: true }));
      }
      dispatch(setCustomerRequest(updatedCustomerRequest));
    } else {
      dispatch(
        setCustomerRequest({
          isMinimized: false,
          isModalOpen: false,
          isZoomed: true,
          shouldClearAllData: true
        })
      );
      dispatch(setDataCustomerComment({ hasDeleted: false, isKeepValueWhenShowPopup: false, isDirtyComment: false }));
      dispatch(setCommentId(''));
    }
  };

  const handleMinimize = () => {
    dispatch(
      setCustomerRequest({
        ...customerRequest,
        isMinimized: false,
        isModalOpen: true
      })
    );
  };

  const handleInquiry = async () => {
    if (customerRequest.isFormValueChanged) {
      const updatedCustomerRequest = {
        ...customerRequest,
        isModalOpen: false,
        isPublic: true
      };
      const showPopup = await showBasePopup({ type: 'discard' });
      if (showPopup === 'confirm') {
        updatedCustomerRequest.isEdit = false;
        updatedCustomerRequest.isZoomed = true;
        updatedCustomerRequest.isDraft = false;
        updatedCustomerRequest.isModalOpen = true;
        updatedCustomerRequest.isMinimized = false;
        updatedCustomerRequest.isFormValueChanged = false;
        updatedCustomerRequest.shouldClearAllData = true;
        updatedCustomerRequest.dataCurrent = undefined;
        dispatch(setCommentId(''));
      } else {
        updatedCustomerRequest.isMinimized = true;
      }

      dispatch(setCustomerRequest(updatedCustomerRequest));
    } else {
      dispatch(
        setCustomerRequest({
          ...customerRequest,
          isModalOpen: true,
          isMinimized: false,
          isEdit: false,
          dataCurrent: undefined,
          isPublic: true,
          shouldClearAllData: customerRequest.isDraft ?? customerRequest.isEdit,
          isFormValueChanged: false
        })
      );
      dispatch(setCommentId(''));
    }
    localStorage.removeItem('idCustomerRequest');
  };

  const handelPublic = (isPublic: boolean) => {
    dispatch(
      setCustomerRequest({
        ...customerRequest,
        isPublic
      })
    );
  };

  const handlingCustomerRequestDataDisplayFormat = (data: IDataCustomerRequestDetails) => {
    let newData = {};
    if (!data) {
      return;
    }
    newData = {
      title: data.title,
      contentOfTask: data.content,
      deadline: data.deadline ? dayjs(data.deadline) : undefined,
      taskPIC: data?.users?.filter((item: IUserCustomer) => item.assigned)?.map((item: IUserCustomer) => item.userId),
      applicationDocuments: data?.documents?.map((item: IDocuments) => item.documentId),
      allowUsers: data?.users?.filter((item: IUserCustomer) => item.allowed)?.map((item: IUserCustomer) => item.userId),
      private: data.private,
      hasAListOfFiles: false,
      isDraft: data.draft
    };
    return newData;
  };

  const handelTurnOffPopup = () => {
    dispatch(
      setCustomerRequest({
        ...customerRequest,
        isModalOpen: false,
        isMinimized: false,
        dataCurrent: {}
      })
    );
  };

  const getDisplayName = (userName: string | undefined) => {
    if (isExternalRole && userName === dataMotPick.userName) {
      return '社内担当者';
    }
    return userName || '';
  };

  return {
    showModalEdit,
    handleResize,
    handleMinimizePopUp,
    showPopupCancelMinimize,
    handleMinimize,
    handleInquiry,
    dataMotPick,
    handelPublic,
    getDataCustomerRequestById,
    handlingCustomerRequestDataDisplayFormat,
    handelTurnOffPopup,
    getDisplayName
  };
};

export default useCustomerRequest;
