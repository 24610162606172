import { useEffect, useRef } from 'react';

import { WindowEvent } from '@/utils/constants';

export const CUSTOM_HOOK_EVENTS = {
  ACCESS_DENIED: 'access-denied'
} as const;
export type CustomHookEvent = (typeof CUSTOM_HOOK_EVENTS)[keyof typeof CUSTOM_HOOK_EVENTS];
function useEventListener(eventName: CustomHookEvent | WindowEvent, handler: (e: any) => void, element: Window | null = window) {
  const savedHandler = useRef<any>();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;
    const eventListener = (event: any) => savedHandler.current?.(event);
    element.addEventListener(eventName, eventListener);

    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
}

export function dispatchCustomEvent(eventName: CustomHookEvent, detail?: any) {
  const event = new CustomEvent(eventName, { detail });
  window.dispatchEvent(event);
}

export default useEventListener;
