import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { QuestionDetail } from '@pages/project-management/project-questionaire/components/question-detail/compononents/QuestionDetail';

import useFetch from '@/hooks/useFetch';
import useMutation from '@/hooks/useMutation';

import { API } from '@/utils/constants/Apis';

import { IQuestionnaire } from './modals';

import './index.scss';

const QuestionDetailPage = () => {
  const { id: projectId, formId } = useParams();
  const [query, setQuery] = useState<any>({});

  const {
    data: question,
    loading,
    fetchData
  } = useFetch<IQuestionnaire>(API.GET_PROJECT_QUESTIONNAIRE_FORM_DETAIL(projectId ?? '', formId ?? ''), 'GET', undefined, query);
  const { loading: loadingSaveQuestionnaire, mutate: saveQuestion } = useMutation(API.PUT_QUESTIONNAIRE_SAVE(projectId ?? '', formId ?? ''), {
    method: 'PUT',
    showToastError: true
  });

  return (
    <QuestionDetail
      question={question}
      loading={loading}
      loadingSaveQuestionnaire={loadingSaveQuestionnaire}
      fetchData={fetchData}
      saveQuestion={saveQuestion}
      setQuery={setQuery}
    />
  );
};

export default QuestionDetailPage;
