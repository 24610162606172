// Project list
export const PROJECT_LIST = 'PROJECT_LIST';
export const CREATE_NEW_PROJECT_LIST = 'CREATE_NEW';
export const EDIT_PROJECT = 'EDIT_PROJECT';
export const VIEW_PROJECT_DETAIL = 'VIEW_PROJECT_DETAIL';
export const VIEW_PROJECT_FILES = 'VIEW_PROJECT_FILES';
export const VIEW_PROJECT_QUESTIONS = 'VIEW_PROJECT_QUESTIONS';
export const SEARCH_PROJECT_LIST = 'SEARCH';
export const FILTER_PROJECT_LIST = 'FILTER';
export const TABLE_PROJECT_LIST = 'TABLE';
export const NO_DATA_PROJECT_LIST = 'NO_DATA';
export const CREATE_NEW_PROJECT = 'CREATE_NEW_PROJECT';
export const ONCE_YOU_START_ADDING_PROJECTS = 'ONCE_YOU_START_ADDING_PROJECTS';
export const NO_DATA_CREATE_NEW_PROJECT = 'NO_DATA_CREATE_NEW_PROJECT';
export const INQUIRY_CREATE_PROJECT = 'INQUIRY_CREATE_PROJECT';
export const REGISTER_PROJECT_SUCCESS = 'REGISTER_PROJECT_SUCCESS';
export const SAVE_AS_DRAFT_PROJECT_SUCCESS_NAME = 'SAVE_AS_DRAFT_PROJECT_SUCCESS';
export const ADD_TASK_OF_PROJECT_NAME = 'ADD_TASK_OF_PROJECT_NAME';
export const EDIT_TASK_OF_PROJECT_NAME = 'ADD_TASK_OF_PROJECT_NAME';
export const VIEW_TASK_OF_PROJECT_NAME = 'VIEW_TASK_OF_PROJECT_NAME';
export const APPROVED_REJECT_TASK_PROJECT_ATTACHMENT = 'APPROVED_REJECT_TASK_PROJECT_ATTACHMENT';
export const BATCH_REGISTRATION_PROJECT_NAME = 'BATCH_REGISTRATION_PROJECT_NAME';
export const COMMENT_CHAT_NAME = 'COMMENT_CHAT_NAME';

// Project list => Table
export const PROJECT_ID_TABLE = 'PROJECT_ID_TABLE';
export const TRAVELER_NAME_TABLE_PROJECT = 'TRAVELER_NAME_TABLE';
export const COMPANY_NAME_TABLE_PROJECT = 'COMPANY_NAME_TABLE';
export const COUNTRY_OF_TRAVEL_TABLE_PROJECT = 'COUNTRY_OF_TRAVEL_TABLE';
export const DESTINATION_BASE_NAME_TABLE_PROJECT = 'DESTINATION_BASE_NAME_TABLE';
export const STATUS_TABLE_PROJECT = 'STATUS_TABLE';
export const SCHEDULED_TRAVEL_DATE_TABLE_PROJECT = 'SCHEDULED_TRAVEL_DATE_TABLE';
export const VISA_CATEGORY_TABLE_PROJECT = 'VISA_CATEGORY_TABLE ';
export const MODE_OF_TRAVEL_TABLE_PROJECT = 'MODE_OF_TRAVEL_TABLE';
export const MOT_PIC_TABLE_PROJECT = 'MOT_PIC_TABLE';
export const CREATED_DATE_TABLE_PROJECT = 'CREATED_DATE_TABLE';
export const PAGING_TABLE_PROJECT = 'PAGING_TABLE_PROJECT';

// Project list => Filter popup
export const PROJECT_ID_FILTER = 'PROJECT_ID_FILTER';
export const TRAVELER_NAME_FILTER = 'TRAVELER_NAME_FILTER';
export const COMPANY_NAME_FILTER = 'COMPANY_NAME_FILTER';
export const COUNTRY_OF_TRAVELER_FILTER = 'COUNTRY_OF_TRAVELER_FILTER';
export const DESTINATION_BASE_NAME_FILTER = 'DESTINATION_BASE_NAME_FILTER';
export const STATUS_FILTER = 'STATUS_FILTER';
export const SCHEDULED_TRAVEL_DATE_FORM_FILTER = 'SCHEDULED_TRAVEL_DATE_FORM_FILTER';
export const SCHEDULED_TRAVEL_DATE_TO_FILTER = 'SCHEDULED_TRAVEL_DATE_TO_FILTER';
export const VISA_CATEGORY_FILTER = 'VISA_CATEGORY_FILTER';
export const MODE_OF_TRAVEL_FILTER = 'MODE_OF_TRAVEL_FILTER';
export const PIC_FILTER = 'PIC';
export const CREATED_DATE_FILTER = 'CREATED_DATE_FILTER';
export const RESET_FILTER = 'RESET_FILER';
export const APPLY_FILTER = 'APPLY_FILTER';
export const FILTER_NAME_OF_CORPORATION = 'FILTER_NAME_OF_CORPORATION';

// Project list => Search & FILTER RESULT
export const ICON_DELETE_PROJECT_LIST = 'ICON_DELETE_PROJECT_LIST';
export const BUTTON_DELETE_PROJECT_LIST = 'BUTTON_DELETE_PROJECT_LIST';
export const NO_DATA_SEARCH_FILTER_PROJECT_LIST = 'NO_DATA_SEARCH_FILTER_PROJECT_LIST';
export const NO_PROJECTS_MATCHED_YOUR_SEARCH = 'NO_PROJECTS_MATCHED_YOUR_SEARCH';

// Create Project Basic Information
export const SHORT_MEMO_PROJECT_BASIC = 'SHORT_MEMO_PROJECT_BASIC';
export const SCHEDULE_PROJECT_BASIC = 'SCHEDULE_PROJECT_BASIC';
export const QUESTIONNAIRE_PROJECT_BASIC = 'QUESTIONNAIRE_PROJECT_BASIC';
export const CUSTOMER_REQUEST_LIST_PROJECT_BASIC = 'CUSTOMER_REQUEST_LIST_PROJECT_BASIC';
export const TASK_PROJECT_BASIC = 'TASK_PROJECT_BASIC';
export const MEMO_PROJECT_BASIC = 'MEMO_PROJECT_BASIC';
export const CANCEL_CREATION_PROJECT_BASIC = 'CANCEL_CREATION_PROJECT_BASIC';
export const SAVE_TEMPORARILY_PROJECT_BASIC = 'SAVE_TEMPORARILY_PROJECT_BASIC';
export const REGISTER_PROJECT_BASIC = 'REGISTER_PROJECT_BASIC';
export const REGISTRATION_INFORMATION_PROJECT_BASIC = 'REGISTRATION_INFORMATION_PROJECT_BASIC';
export const CREATOR_PROJECT_BASIC = 'CREATOR_PROJECT_BASIC';
export const BASIC_INFORMATION_PROJECT_BASIC = 'BASIC_INFORMATION_PROJECT_BASIC';
export const CANCEL_SHORT_MEMO_PROJECT_BASIC = 'CANCEL_SHORT_MEMO_PROJECT_BASIC';
export const SAVE_SHORT_MEMO_PROJECT_BASIC = 'SAVE_SHORT_MEMO_PROJECT_BASIC';
export const BASIC_INFORMATION = 'BASIC_INFORMATION';
export const EDIT_BASIC_INFORMATION = 'EDIT_BASIC_INFORMATION';

// Document list
export const DOCUMENT_LIST = 'DOCUMENT_LIST';
export const EDIT_DOCUMENT_LIST = 'EDIT_DOCUMENT_LIST';

// Create Project Basic Information => Request content
export const REQUEST_CONTENT_PROJECT_BASIC = 'REQUEST_CONTENT_PROJECT_BASIC';

// Create Project Basic Information => Traveler Information
export const TRAVELER_INFORMATION_PROJECT_BASIC = 'TRAVELER_INFORMATION_PROJECT_BASIC';

// Create Project Basic Information => Stakeholder
export const STAKE_HOLDERS_PROJECT_BASIC = 'STAKE_HOLDERS_PROJECT_BASIC';
export const THOSE_WHO_HAVE_BEEN_SET_AS_RELATED_PARTIES_CAN_VIEW_THIS_MASTER = 'THOSE_WHO_HAVE_BEEN_SET_AS_RELATED_PARTIES_CAN_VIEW_THIS_MASTER';
export const MAIL_ADDRESS_STAKE_HOLDER = 'MAIL_ADDRESS_STAKE_HOLDER';
export const ADD_STAKE_HOLDER = 'ADD_STAKE_HOLDER';

// Create Project Basic Information => Attachments
export const FILE_ATTACHMENT_ATTACHMENTS = 'FILE_ATTACHMENT_ATTACHMENTS';
export const DRAG_AND_DROP_FILES_HERE_ATTACHMENTS = 'DRAG_AND_DROP_FILES_HERE_ATTACHMENTS';
export const FILE_FORMATS_THAT_CAN_BE_UPLOAD_ATTACHMENTS = 'FILE_FORMATS_THAT_CAN_BE_UPLOAD_ATTACHMENTS';
export const BROWSER_FILE_ATTACHMENTS = 'BROWSER_FILE_ATTACHMENTS';
export const FILE_LIST_ATTACHMENTS = 'FILE_LIST_ATTACHMENTS';

// Edit Project basic information => Request content
export const SUPPORT_TYPE_PROJECT_BASIC_EDIT = 'SUPPORT_TYPE_PROJECT_BASIC_EDIT';
export const NOTE_FOR_REQUEST_CONTENT_PROJECT_BASIC_EDIT = 'NOTE_FOR_REQUEST_CONTENT_PROJECT_BASIC_EDIT';
export const LOCAL_CONTACT_PROJECT_BASIC_EDIT = 'LOCAL_CONTACT_PROJECT_BASIC_EDIT';

// Edit Project basic information => Traveler Information
export const TRAVELER_INFORMATION_BASIC_EDIT = 'TRAVELER_INFORMATION_BASIC_EDIT';

// Edit Project basic information => Family information
export const FAMILY_INFORMATION_EDIT = 'FAMILY_INFORMATION_EDIT';

// Edit Project basic information => Assignment information
export const ASSIGNMENT_INFORMATION_EDIT = 'ASSIGNMENT_INFORMATION_EDIT';

// Edit Project basic information => Visa information
export const VISA_CATEGORY_INFORMATION_EDIT = 'VISA_CATEGORY_INFORMATION_EDIT';

// Edit Project basic information => Note information
export const NOTE_INFORMATION_EDIT = 'NOTE_INFORMATION_EDIT';

// Edit Project Question Project
export const SUBMISSION_DEADLINE_QUESTION_PROJECT = 'SUBMISSION_DEADLINE_QUESTION_PROJECT';
export const EDIT_RESPONDENT_QUESTION_PROJECT = 'EDIT_RESPONDENT_QUESTION_PROJECT';
export const SELECT_TEMPLATE_QUESTION_PROJECT = 'SELECT_TEMPLATE_QUESTION_PROJECT';
export const ANSWER_QUESTION_PROJECT_AFTER_COMPLETE = 'ANSWER_QUESTION_PROJECT_AFTER_COMPLETE';
export const QUESTION_COMPLETE_BUTTON = 'QUESTION_COMPLETE_BUTTON';
export const QUESTION_SAVE_DRAFT_BUTTON = 'QUESTION_SAVE_DRAFT_BUTTON';
export const ACTION_TAB_QUESTION_PROJECT = 'ACTION_TAB_QUESTION_PROJECT';

// PROJECT MEMO
export const PROJECT_MEMO = 'PROJECT_MEMO';

// Create/Edit Required documents
export const REQUIRED_DOCUMENTS_TEMPLATE_NAME = 'REQUIRED_DOCUMENTS_TEMPLATE_NAME';
export const REQUIRED_DOCUMENTS_EXPORT_PDF = 'REQUIRED_DOCUMENTS_EXPORT_PDF';
export const REQUIRED_DOCUMENTS_CANCEL = 'REQUIRED_DOCUMENTS_CANCEL';
export const REQUIRED_DOCUMENTS_SAVE = 'REQUIRED_DOCUMENTS_SAVE';
export const REQUIRED_DOCUMENTS_TITLE = 'REQUIRED_DOCUMENTS_TITLE';
// Edit Project Required documents tab
export const REFERENCE_TABLE_COLLAPSE = 'REFERENCE_TABLE_COLLAPSE';
export const REFERENCE_TABLE_NAME = 'REFERENCE_TABLE_NAME';
export const REFERENCE_TABLE_CHECKBOX = 'REFERENCE_TABLE_CHECKBOX';
export const REFERENCE_TABLE_LABEL_INDEX = 'REFERENCE_TABLE_LABEL_INDEX';
export const REFERENCE_TABLE_DOCUMENTS_NAME = 'REFERENCE_TABLE_DOCUMENTS_NAME';
export const REFERENCE_TABLE_DESCRIPTION = 'REFERENCE_TABLE_DESCRIPTION';
export const REFERENCE_TABLE_FILE_NAME = 'REFERENCE_TABLE_FILE_NAME';

export const REFERENCE_TABLE_UPLOAD_FILE_BUTTON = 'REFERENCE_TABLE_UPLOAD_FILE_BUTTON';
export const REFERENCE_TABLE_ADD_NEW_ROW_BUTTON = 'REFERENCE_TABLE_ADD_NEW_ROW_BUTTON';
export const REFERENCE_TABLE_DELETE_ROW_BUTTON = 'REFERENCE_TABLE_DELETE_ROW_BUTTON';
export const REFERENCE_TABLE_ADD_NEW_LINE_ICON = 'REFERENCE_TABLE_ADD_NEW_LINE_ICON';

// Edit Project Required documents tab for file upload
export const REQUIRED_DOCUMENT_LABEL_WAITING_APPROVE = 'REQUIRED_DOCUMENT_LABEL_WAITING_APPROVE';
export const REQUIRED_DOCUMENT_DELETE_FILE = 'REQUIRED_DOCUMENT_DELETE_FILE';
export const REQUIRED_DOCUMENT_APPROVE_BUTTON = 'REQUIRED_DOCUMENT_APPROVE_BUTTON';
export const REQUIRED_DOCUMENT_REJECT_BUTTON = 'REQUIRED_DOCUMENT_REJECT_BUTTON';

// Customer request TABLES
export const CUSTOMER_REQUEST_LIST = 'CUSTOMER_REQUEST_LIST';
export const CUSTOMER_REQUEST_LIST_ITEM_ADD = 'CUSTOMER_REQUEST_LIST_ITEM_ADD';
export const CUSTOMER_REQUEST_LIST_CHECKBOX = 'CUSTOMER_REQUEST_LIST_CHECKBOX';
export const CUSTOMER_REQUEST_LIST_NO = 'CUSTOMER_REQUEST_LIST_NO';
export const CUSTOMER_REQUEST_LIST_REQUEST_NAME = 'CUSTOMER_REQUEST_LIST_REQUEST_NAME';
export const CUSTOMER_REQUEST_LIST_APPLICATION_DOCUMENTS = 'CUSTOMER_REQUEST_LIST_APPLICATION_DOCUMENTS';
export const CUSTOMER_REQUEST_LIST_CONTENT_OF_REQUEST = 'CUSTOMER_REQUEST_LIST_CONTENT_OF_REQUEST';
export const CUSTOMER_REQUEST_LIST_DEADLINE = 'CUSTOMER_REQUEST_LIST_DEADLINE';
export const CUSTOMER_REQUEST_LIST_EMAIL = 'CUSTOMER_REQUEST_LIST_EMAIL';
export const CUSTOMER_REQUEST_CONFIRMED = 'CUSTOMER_REQUEST_CONFIRMED';
export const CUSTOMER_REQUEST_SEND = 'CUSTOMER_REQUEST_SEND';
export const CUSTOMER_REQUEST_DISCUSS = 'CUSTOMER_REQUEST_DISCUSS';
export const CUSTOMER_REQUEST_SAVE_AS_DRAFT = 'CUSTOMER_REQUEST_SAVE_AS_DRAFT';
export const CUSTOMER_REQUEST_CANCEL = 'CUSTOMER_REQUEST_CANCEL';

// Task List TABLES
export const TASK_LIST = 'TASK_LIST';
export const TASK_LIST_ITEM_ADD = 'TASK_LIST_ITEM_ADD';
export const TASK_LIST_CHECKBOX = 'TASK_LIST_CHECKBOX';
export const TASK_LIST_NO = 'TASK_LIST_NO';
export const TASK_LIST_NAME = 'TASK_LIST_NAME';
export const TASK_LIST_DEADLINE = 'TASK_LIST_DEADLINE';
export const TASK_LIST_MANAGER = 'TASK_LIST_MANAGER';
export const TASK_LIST_STATUS = 'TASK_LIST_STATUS';
export const TASK_LIST_REMARK = 'TASK_LIST_REMARK';
export const TASK_SAVE = 'TASK_SAVE';
export const TASK_CANCEL = 'TASK_CANCEL';

// Customer request select request name
export const CUSTOMER_REQUEST_NAME_SEARCH = 'CUSTOMER_REQUEST_NAME_SEARCH';
export const CUSTOMER_REQUEST_NAME_LIST_OPTION = 'CUSTOMER_REQUEST_NAME_LIST_OPTION';
export const CUSTOMER_REQUEST_NAME_ADD_ITEM_INPUT = 'CUSTOMER_REQUEST_NAME_ADD_ITEM_INPUT';
export const CUSTOMER_REQUEST_NAME_ADD_ITEM = 'CUSTOMER_REQUEST_NAME_ADD_ITEM';

// Customer request select multiple
export const CUSTOMER_REQUEST_MULTIPLE_SEARCH = 'CUSTOMER_REQUEST_MULTIPLE_SEARCH';
export const CUSTOMER_REQUEST_MULTIPLE_LIST_OPTION = 'CUSTOMER_REQUEST_MULTIPLE_LIST_OPTION';

// Customer request select content of request
export const CUSTOMER_REQUEST_CONTENT_OF_REQUEST_SEARCH = 'CUSTOMER_REQUEST_CONTENT_OF_REQUEST_SEARCH';
export const CUSTOMER_REQUEST_CONTENT_OF_REQUEST_LIST_OPTION = 'CUSTOMER_REQUEST_CONTENT_OF_REQUEST_LIST_OPTION';
export const CUSTOMER_REQUEST_CONTENT_OF_REQUEST_CREATE_TEMPLATE = 'CUSTOMER_REQUEST_CONTENT_OF_REQUEST_CREATE_TEMPLATE';
export const CUSTOMER_REQUEST_CONTENT_OF_REQUEST_EDIT = 'CUSTOMER_REQUEST_CONTENT_OF_REQUEST_EDIT';

// Customer request modal content of request create
export const CUSTOMER_REQUEST_MODAL_CREATE_NEW_TEMPLATE = 'CUSTOMER_REQUEST_MODAL_CREATE_NEW_TEMPLATE';
export const CUSTOMER_REQUEST_MODAL_CREATE_TEMPLATE_NAME = 'CUSTOMER_REQUEST_MODAL_CREATE_TEMPLATE_NAME';
export const CUSTOMER_REQUEST_MODAL_CREATE_CONTENT = 'CUSTOMER_REQUEST_MODAL_CREATE_CONTENT';
export const CUSTOMER_REQUEST_MODAL_CREATE_CANCEL = 'CUSTOMER_REQUEST_MODAL_CREATE_CANCEL';
export const CUSTOMER_REQUEST_MODAL_CREATE_SAVE = 'CUSTOMER_REQUEST_MODAL_CREATE_SAVE';

// Customer request modal content of request edit
export const CUSTOMER_REQUEST_MODAL_EDIT_NEW_TEMPLATE = 'CUSTOMER_REQUEST_MODAL_EDIT_NEW_TEMPLATE';
export const CUSTOMER_REQUEST_MODAL_EDIT_EMAIL_TEMPLATE_NAME = 'CUSTOMER_REQUEST_MODAL_EDIT_EMAIL_TEMPLATE_NAME';
export const CUSTOMER_REQUEST_MODAL_EDIT_CONTENT = 'CUSTOMER_REQUEST_MODAL_EDIT_CONTENT';
export const CUSTOMER_REQUEST_MODAL_EDIT_CANCEL = 'CUSTOMER_REQUEST_MODAL_EDIT_CANCEL';
export const CUSTOMER_REQUEST_MODAL_EDIT_SAVE = 'CUSTOMER_REQUEST_MODAL_EDIT_SAVE';

// Customer request chat
export const CUSTOMER_REQUEST_CHAT_TITLE = 'CUSTOMER_REQUEST_CHAT_TITLE';
export const CUSTOMER_REQUEST_CHAT_ICON_X = 'CUSTOMER_REQUEST_CHAT_ICON_X';
export const CUSTOMER_REQUEST_CHAT_CONTENT_OF_QUEST = 'CUSTOMER_REQUEST_CHAT_CONTENT_OF_QUEST';
export const CUSTOMER_REQUEST_CHAT_NAME = 'CUSTOMER_REQUEST_CHAT_NAME';
export const CUSTOMER_REQUEST_CHAT_AVATAR = 'CUSTOMER_REQUEST_CHAT_AVATAR';
export const CUSTOMER_REQUEST_CHAT_MESSAGE = 'CUSTOMER_REQUEST_CHAT_MESSAGE';
export const CUSTOMER_REQUEST_CHAT_TIME = 'CUSTOMER_REQUEST_CHAT_TIME';
export const CUSTOMER_REQUEST_CHAT_TYPE_A_MESSAGE = 'CUSTOMER_REQUEST_CHAT_TYPE_A_MESSAGE';
export const CUSTOMER_REQUEST_CHAT_SEND = 'CUSTOMER_REQUEST_CHAT_SEND';

// Customer request filter
export const CUSTOMER_REQUEST_FILTER_TITLE = 'CUSTOMER_REQUEST_FILTER_TITLE';
export const CUSTOMER_REQUEST_FILTER_EMAIL_ADDRESS = 'CUSTOMER_REQUEST_FILTER_EMAIL_ADDRESS';
export const CUSTOMER_REQUEST_FILTER_REQUEST_NAME = 'CUSTOMER_REQUEST_FILTER_REQUEST_NAME';
export const CUSTOMER_REQUEST_FILTER_DOCUMENT = 'CUSTOMER_REQUEST_FILTER_DOCUMENT';
export const CUSTOMER_REQUEST_FILTER_STATUS = 'CUSTOMER_REQUEST_FILTER_STATUS';
export const CUSTOMER_REQUEST_FILTER_DEADLINE = 'CUSTOMER_REQUEST_FILTER_DEADLINE';
export const CUSTOMER_REQUEST_FILTER_CLEAR_FILTER = 'CUSTOMER_REQUEST_FILTER_CLEAR_FILTER';
export const CUSTOMER_REQUEST_FILTER_APPLY = 'CUSTOMER_REQUEST_FILTER_APPLY';

export const INQUIRY = 'INQUIRY';

export const TASKS_OF_ALL_PROJECTS = 'TASKS_OF_ALL_PROJECTS';
export const EXTERNAL_QUESTION_AUTHENTICATE = 'EXTERNAL_QUESTION_AUTHENTICATE';
export const EXTERNAL_QUESTION_LIST = 'EXTERNAL_QUESTION_LIST';
export const EXTERNAL_QUESTION_DETAIL = 'EXTERNAL_QUESTION_DETAIL';
