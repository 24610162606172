import { forwardRef } from 'react';

import useAuthorization from '@/hooks/useAuthorization';

import { IDetailTaskData } from '../../models';
import HeaderExternalRole from './HeaderExternalRole';
import HeaderInternalRole from './HeaderInternalRole';

import '../index.scss';

interface HeaderTaskDetailProps {
  detailData: IDetailTaskData;
  fetchData: () => Promise<void>;
}

const HeaderTaskDetail = forwardRef<HTMLDivElement, HeaderTaskDetailProps>(({ detailData, fetchData }: HeaderTaskDetailProps, ref) => {
  const { isExternalRole, isInternalRole } = useAuthorization();
  return (
    <div className='header-view-task-detail-project' ref={ref}>
      {isInternalRole && <HeaderInternalRole detailData={detailData} fetchData={fetchData} />}
      {isExternalRole && <HeaderExternalRole detailData={detailData} />}
    </div>
  );
});

export default HeaderTaskDetail;
