import styled from 'styled-components';

/**
 * @deprecated styled-components must be removed. It's very rarely used
 * Please use scss or tailwind
 */
export const BaseTableWrapper = styled.div<{ heightheader?: number | string }>`
  .base-table-in-list-page {
    .ant-table {
      /* min-height: calc(100vh - 200px - ${(props) => (props.heightheader ? props.heightheader : 0)}px);*/
      .ant-table-body {
        /* min-height: calc(100vh - 232px - ${(props) => (props.heightheader ? props.heightheader : 0)}px);*/
      }
    }
  }
`;
