import { Col, Row } from 'antd';
import { forwardRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CounterLabel from '@pages/project-management/add-edit/scenario/components/counter-label';
import { useFormScenarioBContext } from '@pages/project-management/add-edit/scenario/type-b';
import { GENDER_OPTIONS } from '@pages/project-management/add-edit/scenario/type-b/constants';
import { relativePath } from '@pages/project-management/add-edit/scenario/utils';

import FormPostalCodeInput from '@/components/common/postal-code-input';
import { FormInputDate } from '@/components/form-input-date/FormInputDate';
import { FormInput } from '@/components/form-input/FormInput';
import { FormSelect } from '@/components/form-select/FormSelect';

import useOptionsGlobal from '@/hooks/useOptionsGlobal';

import { POSTAL_CODE_MASK } from '@/utils/constants';

import { APPLICANT_FIELDS, POSTAL_CODE_RELATIVE_FIELDS, REQUIRED_APPLICANT_FIELDS } from './constants';

interface IApplicantFormProps {
  parentPath: string;
}

const ApplicantForm = forwardRef(({ parentPath = '' }: IApplicantFormProps, ref) => {
  const { t } = useTranslation();
  const { customCountryOptions, filterOption } = useOptionsGlobal();
  const gender = GENDER_OPTIONS.map((item) => ({ ...item, label: t(item.label) }));
  const formMethod = useFormScenarioBContext<'step-2'>();

  const watchingFieldPath = useMemo(() => {
    return {
      all: APPLICANT_FIELDS.map((field) => relativePath(field, parentPath)),
      required: REQUIRED_APPLICANT_FIELDS.map((field) => relativePath(field, parentPath))
    };
  }, [parentPath]);

  const watchFields = formMethod.watch(watchingFieldPath.all);

  const counters = () => {
    const watchFieldsValid = watchFields.filter((field: any) => field);
    const results = {
      total: APPLICANT_FIELDS.length,
      questions: watchFieldsValid.length ?? 0
    };
    return `${results.questions}/${results.total}`;
  };

  const relativeFieldByPostalCode = () => {
    return POSTAL_CODE_RELATIVE_FIELDS.map((item) => ({ ...item, name: relativePath(item.name, parentPath) }));
  };

  return (
    <>
      <CounterLabel title={t('project:scenario_b:transferee_information')} count={counters()} />
      <div className='grid gap-4'>
        <Row gutter={16}>
          <Col span={6}>
            <FormInput
              required
              defaultValue={formMethod.getValues(relativePath('lastName', parentPath))}
              name={relativePath('lastName', parentPath)}
              labelTx='project:add:applicant_representative:last_name'
              placeholderTx='project:add:applicant_representative:example_yamada'
            />
          </Col>
          <Col span={6}>
            <FormInput
              defaultValue={formMethod.getValues(relativePath('firstName', parentPath))}
              required
              name={relativePath('firstName', parentPath)}
              labelTx='project:add:applicant_representative:first_name'
              placeholderTx='project:add:applicant_representative:example_taro'
            />
          </Col>
          <Col span={6}>
            <FormInput
              required
              defaultValue={formMethod.getValues(relativePath('lastNameRomaji', parentPath))}
              name={relativePath('lastNameRomaji', parentPath)}
              labelTx='project:add:applicant_representative:last_name_romaji'
              placeholderTx='project:add:applicant_representative:example_yamada_passport_notation'
            />
          </Col>
          <Col span={6}>
            <FormInput
              required
              defaultValue={formMethod.getValues(relativePath('firstNameRomaji', parentPath))}
              name={relativePath('firstNameRomaji', parentPath)}
              labelTx='project:add:applicant_representative:first_name_romaji'
              placeholderTx='project:add:applicant_representative:example_taro_passport'
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={6}>
            <FormSelect
              required
              id={relativePath('primaryNational', parentPath)}
              name={relativePath('primaryNational', parentPath)}
              label={t('project:add:applicant_representative:nationality') ?? ''}
              options={customCountryOptions}
              placeholder={String(t('project:add:applicant_representative:example_japan'))}
              filterOption={filterOption}
            />
          </Col>
          <Col span={6}>
            <FormSelect
              name={relativePath('secondaryNational', parentPath)}
              label={t('project:add:applicant_representative:second_nationality') ?? ''}
              options={customCountryOptions}
              placeholder={String(t('project:add:applicant_representative:example_america'))}
              filterOption={filterOption}
            />
          </Col>
          <Col span={6}>
            <FormInputDate
              required
              name={relativePath('dateOfBirth', parentPath)}
              labelTx='project:add:applicant_representative:date_of_birth'
              placeholderTx={t('placeholder:date') ?? ''}
            />
          </Col>
          <Col span={6}>
            <FormSelect
              required
              id={relativePath('gender', parentPath)}
              name={relativePath('gender', parentPath)}
              label={t('project:add:applicant_representative:sex') ?? ''}
              options={gender}
              placeholder={String(t('project:add:please_select_field', { field: t('project:add:applicant_representative:sex') }))}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={6}>
            <FormPostalCodeInput
              id={relativePath('postalCode', parentPath)}
              defaultValue={formMethod.getValues(relativePath('postalCode', parentPath))}
              name={relativePath('postalCode', parentPath)}
              label={t('basic_information:postal_code_number') ?? ''}
              placeholder={t('postal_code:postal_code_placeholder') ?? ''}
              relativeFields={relativeFieldByPostalCode()}
              mask={POSTAL_CODE_MASK}
              required
            />
          </Col>
          <Col span={6}>
            <FormInput
              defaultValue={formMethod.getValues(relativePath('prefecture', parentPath))}
              required
              name={relativePath('prefecture', parentPath)}
              labelTx='postal_code:prefecture'
              placeholderTx='postal_code:prefecture_placeholder'
            />
          </Col>
          <Col span={6}>
            <FormInput
              defaultValue={formMethod.getValues(relativePath('prefectureRoman', parentPath))}
              required
              name={relativePath('prefectureRoman', parentPath)}
              labelTx='postal_code:prefecture_roman'
              placeholderTx='postal_code:prefecture_roman_placeholder'
            />
          </Col>
          <Col span={6}>
            <FormInput
              defaultValue={formMethod.getValues(relativePath('city', parentPath))}
              required
              name={relativePath('city', parentPath)}
              labelTx='postal_code:city'
              placeholderTx='postal_code:city_placeholder'
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={6}>
            <FormInput
              defaultValue={formMethod.getValues(relativePath('cityRoman', parentPath))}
              required
              name={relativePath('cityRoman', parentPath)}
              labelTx='postal_code:city_roman'
              placeholderTx='postal_code:city_roman_placeholder'
            />
          </Col>
          <Col span={6}>
            <FormInput
              defaultValue={formMethod.getValues(relativePath('suburb', parentPath))}
              required
              name={relativePath('suburb', parentPath)}
              labelTx='postal_code:suburb'
              placeholderTx='postal_code:suburb_placeholder'
            />
          </Col>
          <Col span={6}>
            <FormInput
              defaultValue={formMethod.getValues(relativePath('suburbRoman', parentPath))}
              required
              name={relativePath('suburbRoman', parentPath)}
              labelTx='postal_code:suburb_roman'
              placeholderTx='postal_code:suburb_roman_placeholder'
            />
          </Col>
          <Col span={6}>
            <FormInput
              defaultValue={formMethod.getValues(relativePath('streetAddress', parentPath))}
              required
              name={relativePath('streetAddress', parentPath)}
              labelTx='postal_code:street_address'
              placeholderTx='postal_code:street_address_placeholder'
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <FormInput
              defaultValue={formMethod.getValues(relativePath('apartmentName', parentPath))}
              name={relativePath('apartmentName', parentPath)}
              labelTx='postal_code:office'
              placeholderTx='postal_code:office_placeholder'
            />
          </Col>
          <Col span={12}>
            <FormInput
              defaultValue={formMethod.getValues(relativePath('placeOfResidence', parentPath))}
              name={relativePath('placeOfResidence', parentPath)}
              labelTx='project:add:applicant_representative:resident_registration_place'
              placeholderTx='project:add:applicant_representative:please_enter_registration_location'
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <FormInput
              required
              defaultValue={formMethod.getValues(relativePath('currentCompany', parentPath))}
              name={relativePath('currentCompany', parentPath)}
              labelTx='project:add:applicant_representative:current_company'
              placeholderTx='project:add:applicant_representative:example_company_name'
            />
          </Col>
          <Col span={12}>
            <FormInputDate
              required
              name={relativePath('departureDate', parentPath)}
              labelTx='project:add:applicant_representative:departure_date'
              placeholderTx={t('project:add:applicant_representative:please_select_departure') ?? ''}
            />
          </Col>
        </Row>
      </div>
    </>
  );
});

export default ApplicantForm;
