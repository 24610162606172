export const LOGIN_URL = '/login';
export const VERIFY_CODE_URL = '/verify-code';
export const EXPIRE_URL = '/expire';
export const POLICY_URL = '/policy';
export const FORGOT_PASSWORD_URL = '/forgot-password';
export const PASSWORD_NOTIFICATION_URL = '/password-notification';
export const CHANGE_PASSWORD_URL = '/change-password';
export const PRIVACY_POLICY_URL = '/account/privacyPolicyPublic';
export const CHANGE_PASSWORD_SUCCESS_URL = '/change-password-success';

export const MY_PROFILE_URL = '/my-profile';

export const PROJECT_LIST_URL = '/projects';
export const CREATE_PROJECT_URL = `${PROJECT_LIST_URL}/add`;
export const CREATE_PROJECT_SUCCESS = `${PROJECT_LIST_URL}/success`;
export const SAVE_AS_DRAFT_PROJECT_SUCCESS = (id: string | number) => `${PROJECT_LIST_URL}/save-as-draft-success/${id}`;
export const EDIT_PROJECT_URL = (id: string | number) => `${PROJECT_LIST_URL}/edit/${id}`;
export const VIEW_PROJECT_URL = (id: string | number) => `${PROJECT_LIST_URL}/${id}/view`;
export const ADD_TASK_OF_PROJECT_URL = (idProject: string | number) => `${PROJECT_LIST_URL}/${idProject}/task/add`;
export const EDIT_TASK_OF_PROJECT_URL = (idProject: string | number, idTask: string | number) =>
  `${PROJECT_LIST_URL}/${idProject}/task/edit/${idTask}`;
export const COMMENT_CHAT_OF_TASK_URL = (idProject: string | number, idTask: string | number) =>
  `${PROJECT_LIST_URL}/${idProject}/task/${idTask}/comment`;

export const PROJECT_BASIC_INFO_URL = (id: string | number) => `${PROJECT_LIST_URL}/${id}/info`;
export const EDIT_PROJECT_BASIC_INFO_URL = (id: string | number) => `${PROJECT_LIST_URL}/${id}/edit-basic-info`;
export const PROJECT_FILE_LIST_URL = (id: string | number) => `${PROJECT_LIST_URL}/${id}/files`;
export const PROJECT_QUESTION_LIST_URL = (id: string | number) => `${PROJECT_LIST_URL}/${id}/questions`;
export const PROJECT_QUESTION_DETAIL_URL = (id: string | number, formId: string | number) => `${PROJECT_LIST_URL}/${id}/questions/${formId}`;
export const CREATE_PROJECT_INQUIRY_URL = (id: string | number) => `${PROJECT_LIST_URL}/${id}/inquiry`;
export const DOCUMENT_LIST_URL = (id: string | number) => `${PROJECT_LIST_URL}/${id}/document-list`;
export const EDIT_DOCUMENT_LIST_URL = (id: string | number) => `${PROJECT_LIST_URL}/${id}/edit-document-list`;
export const VIEW_TASK_OF_PROJECT_URL = (idProject: string | number, idTask: string | number) =>
  `${PROJECT_LIST_URL}/${idProject}/task/view/${idTask}`;
export const PROJECT_MEMO_URL = (id: string | number) => `${PROJECT_LIST_URL}/${id}/memo`;
export const BATCH_REGISTRATION_PROJECT_URL = `${PROJECT_LIST_URL}/batch-registration`;

export const TEMPLATE_LIST_URL = '/templates';
export const CREATE_TEMPLATE_URL = `${TEMPLATE_LIST_URL}/add`;
export const VIEW_TEMPLATE_URL = (id: string | number) => `${TEMPLATE_LIST_URL}/view/${id}`;
export const EDIT_TEMPLATE_URL = (id: string | number) => `${TEMPLATE_LIST_URL}/edit/${id}`;

export const ACCOUNT_MASTER_LIST_URL = '/accounts-master';
export const CREATE_ACCOUNT_MASTER_URL = `${ACCOUNT_MASTER_LIST_URL}/add`;
export const APPROVE_REJECT_ACCOUNT_MASTER_URL = (id: string | number) => `${ACCOUNT_MASTER_LIST_URL}/edit-pending/${id}`;
export const UPDATE_ACCOUNT_MASTER_URL = (id: string | number) => `${ACCOUNT_MASTER_LIST_URL}/edit-other/${id}`;

export const MASTER_LIST_URL = '/master-data/companies';
export const MASTER_TASK_ITEM = '/task-list';
export const MASTER_LIST_ITEM_DOCUMENT_URL = '/master-data/documents';

export const ALL_TASK_PROJECT_URL = '/all-task-project';
export const ACCOUNT_LIST_URL = '/accounts';
export const CREATE_ACCOUNT_URL = `${ACCOUNT_LIST_URL}/add`;
export const EDIT_ACCOUNT_URL = (id: string | number) => `${ACCOUNT_LIST_URL}/edit/${id}`;
export const VIEW_ACCOUNT_URL = (id: string | number) => `${ACCOUNT_LIST_URL}/view/${id}`;

export const TASK_LIST_URL = '/master-data/tasks';

export const ANNOUNCEMENT_LIST_URL = (id: string | number) => `${PROJECT_LIST_URL}/${id}/view/announcements`;
export const CREATE_ANNOUNCEMENT_URL = (id: string | number) => `${PROJECT_LIST_URL}/${id}/view/announcements/add`;
export const EDIT_ANNOUNCEMENT_URL = (projectId: string | number, id: string | number) => `${ANNOUNCEMENT_LIST_URL(projectId)}/edit/${id}`;
export const VIEW_ANNOUNCEMENT_URL = (projectId: string | number, id: string | number) => `${ANNOUNCEMENT_LIST_URL(projectId)}/view/${id}`;
export const NOTIFICATION_LIST_URL = '/notifications';

export const STAKEHOLDER_VIEW_URL = (id: string | number) => `${PROJECT_LIST_URL}/${id}/view/stakeholder/view`;
export const STAKEHOLDER_UPDATE_URL = (id: string | number) => `${PROJECT_LIST_URL}/${id}/view/stakeholder/update`;

export const INQUIRY_URL = '/inquiry';

export const ALL_TASK_URL = '/tasks';

export const NOT_EXITS_URL = '/pages/404';
export const NOT_AUTHORIZED_URL = '/pages/403';
export const SOMETHING_WENT_WRONG_URL = '/pages/500';

export const PHRASE_LIST_URL = '/master-data/phrase';
export const VIEW_PHRASE_URL = (id: string | number) => `${PHRASE_LIST_URL}/view/${id}`;
export const EDIT_PHRASE_URL = (id: string | number) => `${PHRASE_LIST_URL}/edit/${id}`;
export const EXTERNAL_QUESTION_AUTHENTICATE_URL = '/external-questions/authenticate';
export const EXTERNAL_QUESTION_LIST_URL = (token: string | number) => `/external-questions/${token}`;
export const EXTERNAL_QUESTION_DETAIL_URL = (token: string | number, id: string | number) => `/external-questions/${token}/question/${id}`;
