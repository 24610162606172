import { DefaultOptionType } from 'antd/es/select';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BaseButton } from '@/components/base-button/BaseButton';
import { FormInputSelect } from '@/components/form-input-select/FormInputSelect';

import { Ii18n } from '@/utils/interfaces/i18n';

import { IFormDetailProject } from '../utils';

interface IFilterProps {
  formMethod?: any;
  onSubmit: (values: any) => void;
  onReset?: () => void;
  defaultValues?: any;
  optionsVisaCategory: DefaultOptionType[];
  formOptions: DefaultOptionType[];
}

export interface IPhraseCategory {
  id: string;
  name: string;
  displayOrder: number;
}
const PrimaryFormFilter = ({ onSubmit, defaultValues, optionsVisaCategory, formOptions }: IFilterProps) => {
  const { t }: Ii18n = useTranslation();
  const formFilter = useForm<IFormDetailProject['primary']>({
    defaultValues: {
      ...defaultValues
    }
  });

  const disabledButtonReset = () => {
    const { category, form } = formFilter.watch();
    return !category && !form;
  };

  const handleSubmit = (values: any) => {
    onSubmit(values);
  };

  const handleReset = () => {
    formFilter.reset({
      category: '',
      form: ''
    });
  };
  return (
    <FormProvider {...formFilter}>
      <div className='p-4 w-[440px]'>
        <h3 className='text-xl font-semibold'>{t('button:filter')}</h3>
        <div className='item mb-5'>
          <FormInputSelect
            name='category'
            labelTx={t('project_detail:category')}
            options={optionsVisaCategory}
            placeholder={t('placeholder:select', { field: t('project_detail:category') })}
          />
        </div>
        <div className='item mb-5'>
          <FormInputSelect
            name='form'
            labelTx={t('project_detail:type_of_assignment')}
            options={formOptions}
            placeholder={t('placeholder:select', { field: t('project_detail:type_of_assignment') })}
          />
        </div>
        <div className='flex justify-end space-x-2'>
          <div className='w-[120px]'>
            <BaseButton disabled={disabledButtonReset()} onClick={handleReset} type='tertiary' size='medium'>
              {t('master_data:document_management:btn_reset')}
            </BaseButton>
          </div>
          <div className='w-[120px]'>
            <BaseButton onClick={formFilter.handleSubmit(handleSubmit)} type='primary' size='medium'>
              <span>{t('master_data:document_management:btn_apply')} </span>
            </BaseButton>
          </div>
        </div>
      </div>
    </FormProvider>
  );
};

export default PrimaryFormFilter;
