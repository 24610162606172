import { axiosInstance } from '../../config/http';
import { API_URL_WITH_PORT } from '../constants/AppConstants';

export const getUserInfo = async (token: string) => {
  const res = await axiosInstance.get(`/usr/users/info`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return res;
};

export const getAllProjectsOfCurrentUser = async () => {
  return axiosInstance.get(`prj/projects/projects`);
};

export const refreshToken = (refreshToken: string) => {
  const params = {
    grant_type: 'refresh_token',
    refresh_token: refreshToken,
    scope: process.env.REACT_APP_API_SCOPE,
    client_id: process.env.REACT_APP_API_CLIENT_ID
  };
  return axiosInstance.post(`connect/token`, params, {
    baseURL: API_URL_WITH_PORT,
    headers: {
      Authorization: ``,
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

export const generatePasswordChangeLink = async () => {
  const res = await axiosInstance.get(`usr/Users/generate-token`);
  return res;
};
