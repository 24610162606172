import { Input, InputRef } from 'antd';
import { InputProps } from 'antd/lib/input';
import classNames from 'classnames';
// import InputMask from 'inputmask';
import React, { forwardRef } from 'react';

import { REQUIRED_DOT } from '@/utils/constants/AppConstants';
import { ColorDefault } from '@/utils/themes/color';

import './index.scss';

interface IInputMargin {
  left?: number;
  right?: number;
  top?: number;
  bottom?: number;
}
export interface IBaseInputProps extends InputProps {
  // Add any additional props you need for your component
  hideBorder?: boolean;
  labelInput?: string;
  labelClassName?: string;
  margin?: IInputMargin;
}

const BaseInput = (
  { required, hideBorder, className, labelInput, labelClassName, onChange, margin = { left: 2 }, ...restProps }: IBaseInputProps,
  ref: React.Ref<InputRef>
) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e);
  };

  return (
    <div className='base-input body-400 flex w-full'>
      {labelInput && (
        <>
          <span className={classNames(labelClassName ?? '', 'mr-2')}>{`${labelInput}:`}</span>
          {required && (
            <span className='require' color={ColorDefault.negative}>
              {REQUIRED_DOT}
            </span>
          )}
        </>
      )}
      <Input
        ref={ref}
        className={classNames(
          'grow',
          // Generate classes for margin
          Object.keys(margin)
            .reduce((classes: string[], key: string) => {
              const marginKey = key as keyof IInputMargin;
              if (margin[marginKey]) {
                classes.push(`m-${key[0]}-${margin[marginKey]}`);
              }
              return classes;
            }, [])
            .join(' '),
          hideBorder ? 'non-border' : '',
          className ?? ''
        )}
        onChange={handleChange}
        {...restProps}
      />
    </div>
  );
};

export default forwardRef(BaseInput);
