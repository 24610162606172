/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { Space } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { debounce, isNil } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { BaseCollapse } from '@/components/base-collapse/BaseCollapse';
import { ConfirmCancelEditing } from '@/components/confirm-popup';

import { handleCommonError } from '@/utils/helpers/common';
import { IForm } from '@/utils/interfaces/masterdata';
import { getForms } from '@/utils/services/master';

import { BaseButton } from '../../../components/base-button/BaseButton';
import { showBasePopup } from '../../../components/base-popup/BasePopup';
import { FormInputSelect } from '../../../components/form-input-select/FormInputSelect';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import useAuthorization, { C, CREATE_TEMPLATE_CANCEL, CREATE_TEMPLATE_SAVE, V } from '../../../hooks/useAuthorization';
import useOptionsGlobal from '../../../hooks/useOptionsGlobal';
import { setAlertNotification, setLoadingPage } from '../../../redux/globalReducer';
import { DEFAULT_PERPAGE_OPTIONS, SORT_LATEST, TYPE } from '../../../utils/constants/AppConstants';
import { TEMPLATE_LIST_URL, VIEW_TEMPLATE_URL } from '../../../utils/constants/RouteContants';
import {
  _generateFilterSearchQuery,
  _guuid,
  arrayToDict,
  intersectArrays,
  sanitizeAndRemoveInsecureLinks
} from '../../../utils/helpers/globalHelper';
import { IParams } from '../../../utils/interfaces/service';
import { ICategory, ICountry, ITask } from '../../../utils/interfaces/template';
import { createTemplate, getDocumentsByCountryId, getTasksByCountryId, getTemplateById, updateTemplate } from '../../../utils/services/template';
import TitleButton from '../add-edit/components/title-button/TitleButton';
import { IInternalDocument, IRequiredDocument, IRequiredDocumentForm, ITemplateProcess, ITemplateProcessTask } from '../view/modules';
import { templateMTD } from './AddEditTemplateMapper';
import { FormTemplate } from './FormValidation';
import ReferenceMaterial from './components/reference-material/ReferenceMaterial';
import RequiredDocuments from './components/required-documents/RequiredDocuments';
import AddEditScheduleTemplate from './components/schedule/AddEditScheduleTemplate';
import TemplateTaskList from './components/template-task-list/TemplateTaskList';

import './AddEditTemplateLayout.scss';

export type DataChange = {
  schedules?: {
    schedulesDeleted: string[];
    indexChange: number | undefined;
    data?: any;
  };
  documents?: {
    documentsDeleted: string[];
    indexChange: number | undefined;
  };
  materials?: {
    materialsDeleted: string[];
    indexChange: number | undefined;
  };
  tasks?: {
    tasksDeleted: string[];
    indexChange: number | undefined;
  };
  version?: string;
};

export enum SUBMISSION_TO_MOT {
  NEED = 'need',
  UNNEED = 'unneed'
}

function AddEditTemplateLayout() {
  /**
   * Define security Props
   */
  const { hasPermission } = useAuthorization();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const formMethod = useForm({
    mode: 'all',
    shouldUnregister: false,
    resolver: yupResolver(FormTemplate)
  });
  const {
    formState: { isSubmitting, isDirty }
  } = formMethod;
  // when create new data call function format data
  const { id } = useParams();
  const [createButton, setCreateButton] = useState(false);
  const [tasks, setTasks] = useState<ITask[]>([]);
  const dispatch = useAppDispatch();
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);
  const { otherCountry } = useOptionsGlobal();
  const [templateCountryId, setTemplateCountryId] = useState<string>('');
  const patterns = useAppSelector((state) => state.global.processPatterns);
  const { countryId, documents, materials } = formMethod.watch();
  const addEditTemplateRef = useRef<any>(null);
  const materialRef = useRef(null);
  const [formOptions, setFormOptions] = useState<DefaultOptionType[]>([]);
  const [optionsVisaCategory, setOptionsVisaCategory] = useState<DefaultOptionType[]>([]);
  const countriesData = useAppSelector((state) => state.global.countries);

  const basicInformationRef = useRef<any>(null);
  const requireDocumentsRef = useRef<any>(null);
  const requireDocumentsDivRef = useRef<any>(null);
  const materialDivRef = useRef<any>(null);
  const taskListDivRef = useRef<any>(null);
  const [totalPagesDocuments] = useState<number>(0);
  const [paramsDocuments, setParamsDocuments] = useState<IParams>({
    pageSize: DEFAULT_PERPAGE_OPTIONS,
    pageIndex: -1,
    sorts: SORT_LATEST
  });
  const [taskOptions, setTaskOptions] = useState<DefaultOptionType[]>([]);
  const [tasksProcess, setTaskProcess] = useState<ITask[]>([]);
  const [loadingDocuments, setLoadingDocuments] = useState<boolean>(false);
  const [searchDocumentsKeywords, setSearchDocumentsKeywords] = useState<string>('');
  const [defaultDataProcess, setDefaultDataProcess] = useState<ITemplateProcess[]>([]);
  const [defaultInternalDocument, setDefaultInternalDocument] = useState<IInternalDocument[]>([]);
  const [defaultRequireDocument, setDefaultRequireDocument] = useState<IRequiredDocument[]>([]);
  // const [isChangeTemplate, setIsChangeTemplate] = useState<boolean>(false);

  const handleCheckErrorField = (field: string[], fieldRequire: string[]) => {
    const watchFieldsRequired = formMethod.watch(fieldRequire);
    const watchFieldsRequiredValid: any[] = watchFieldsRequired.filter((field: any) => field);
    const isEmptyRequireField = watchFieldsRequired?.length !== watchFieldsRequiredValid?.length;
    if (isEmptyRequireField) return true;
    if (fieldRequire?.includes('documents')) {
      const hasNullIdTask = documents?.some((doc: ITemplateProcess) => doc.templateProcessTasks?.some((task: ITemplateProcessTask) => !task.taskId));
      if (hasNullIdTask) {
        return true;
      }
    }
    if (fieldRequire?.includes('materials')) {
      const hasMaterialNullType = materials?.some((item: IRequiredDocumentForm) => !item?.typeId);
      if (hasMaterialNullType) {
        return true;
      }
    }
    if (fieldRequire?.includes('tasks')) {
      const hasTaskNullType = formMethod?.watch('tasks')?.some((item: IInternalDocument) => !item?.typeId);
      if (hasTaskNullType) {
        return true;
      }
    }
    const containsFormStateError = field?.some((item) => !!formMethod?.formState?.errors?.[item]);
    return !!containsFormStateError;
  };

  const fetchAssignmentForm = async () => {
    try {
      const { data } = await getForms();
      if (!data?.data) return;
      const newData = data.data.map((f: IForm) => ({
        ...f,
        label: f.name,
        value: f.id
      }));
      setFormOptions(newData);
    } catch (error) {}
  };

  const setDataCategories = (country: ICountry) => {
    try {
      if (!country || !country.categories) return;
      const categories: DefaultOptionType[] = country?.categories
        .filter((catogory) => catogory.id)
        .map((c: ICategory) => ({
          value: c.id,
          label: c.name
        }));
      setOptionsVisaCategory(categories);
    } catch (error) {}
  };
  // get template detail
  const getTemplateDetail = async (templateId: string = '', isFromChangeTemplate: boolean = false) => {
    // TODO: refactor this function latter
    // used react-hook-form, we can used this solution to get default value https://react-hook-form.com/docs/useform#defaultValues
    // and clear getTemplateDetail useEffect at the first load
    // rewrite prop getTemplateDetail in AddEditScheduleTemplate to handle change template default value
    const tempId = templateId || id;
    try {
      dispatch(setLoadingPage(true));
      const { data } = await getTemplateById(tempId as string);
      if (!data) return;
      const newInternalDocument = data?.internalDocuments?.map((item: IInternalDocument) => {
        return {
          ...item,
          note: sanitizeAndRemoveInsecureLinks(item?.note as string),
          numOfCopy: item?.numberOfCopy,
          typeId: item?.documentId
        };
      });
      const newRequiredDocument = data?.requiredDocuments?.map((item: IRequiredDocument) => {
        return {
          ...item,
          note: sanitizeAndRemoveInsecureLinks(item?.note as string),
          numOfCopy: item?.numberOfCopy,
          typeId: item?.documentId,
          submission: isNil(item?.submissionToMot) ? null : item?.submissionToMot ? SUBMISSION_TO_MOT.NEED : SUBMISSION_TO_MOT.UNNEED
        };
      });
      const newProcessData = (data?.templateProcesses ?? []).map((item: any) => ({
        ...item,
        templateProcessTasks: item.templateProcessTasks
          .filter((task: any) => [countryId, otherCountry?.id].includes(task?.countryId))
          .map((task: any) => ({
            ...task
          }))
      }));
      if (isFromChangeTemplate) {
        newInternalDocument.forEach((item: any) => {
          item.id = _guuid();
        });
        newRequiredDocument.forEach((item: any) => {
          item.id = _guuid();
        });
        newProcessData.forEach((item: any) => {
          item.id = _guuid();
          item.templateProcessTasks = item.templateProcessTasks
            .filter((task: any) => [countryId, otherCountry?.id].includes(task?.countryId))
            .map((task: any) => ({
              ...task,
              id: _guuid()
            }));
        });
        // setIsChangeTemplate(true);
        formMethod.setValue('materials', newRequiredDocument);
        formMethod.setValue('referanceTemplateId', templateId);
        formMethod.setValue('templateId', templateId);
        formMethod.setValue('tasks', newInternalDocument);
        formMethod.setValue('initDocuments', newProcessData);
        formMethod.setValue('patternId', data?.patternId);
      } else {
        await getDocumentOptions(data?.countryId);
        await fetchAssignmentForm();
        setDefaultDataProcess(data.templateProcesses);
        setDefaultRequireDocument(newRequiredDocument);
        setDefaultInternalDocument(newInternalDocument);
        const countrySelected = countriesData?.find((c: any) => c.id === data?.countryId) as ICountry;
        setDataCategories(countrySelected);
        formMethod.reset((pre) => {
          return {
            ...pre,
            ...data,
            countryId: data?.countryId,
            categoryId: data?.categoryId,
            templateName: data?.name,
            tasks: newInternalDocument,
            materials: newRequiredDocument,
            initDocuments: data.templateProcesses?.filter((item: ITemplateProcess) => item?.templateProcessTasks?.length),
            templateId: data?.referanceTemplateId,
            patternId: data?.patternId
          };
        });
      }
      setTemplateCountryId(data?.countryId);
      // trigger delay for run after line 91 useEffect ./RequiredDocuments.tsx done at first load
      setTimeout(() => formMethod.reset((pre) => ({ ...pre, isDirty: false })), 0);
    } catch (error) {
    } finally {
      dispatch(setLoadingPage(false));
    }
  };

  const [documentOptions, setDocumentOptions] = useState<DefaultOptionType[]>([]);

  const handleSubmitForm = async () => {
    formMethod.handleSubmit(prepareSubmit(onSubmit), onError)();
  };

  const onError = async (err: any) => {
    let firstInValidKey: string | undefined = Object.keys(err)?.[0];
    const formCollapseMapping = {
      basicInformation: {
        ref: basicInformationRef,
        fields: ['countryId', 'categoryId', 'formId', 'templateName', 'templateId']
      },
      requireDocumentsDiv: { ref: requireDocumentsDivRef, fields: ['patternId', 'documents'] },
      materialDiv: { ref: materialDivRef, fields: ['materials'] },
      taskListDiv: { ref: taskListDivRef, fields: ['tasks'] }
    };

    // Open all collapse invalid
    for (let key in err) {
      for (const [_, value] of Object.entries(formCollapseMapping)) {
        const formRef = value.ref;
        const idRef = formRef.current?.getIdElement();
        if (value?.fields?.includes(key as string)) {
          formRef.current?.setCollapse(idRef);
        }
        // Scroll to field error
        if (firstInValidKey !== undefined && value?.fields?.includes(firstInValidKey as string)) {
          const element = document.getElementById(`base-collapse-${idRef}`);
          element?.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
  };

  const prepareSubmit = (next: typeof onSubmit) => {
    const dataInternalDocument = formMethod
      .getValues('tasks')
      ?.filter((task: IInternalDocument) => task.countryId)
      ?.map((item: any, index: number) => {
        return {
          id: item?.id,
          countryId: item?.countryId,
          documentId: item?.typeId,
          displayOrder: index + 1,
          numberOfCopy: item?.numOfCopy,
          note: item?.note,
          managementCode: item?.managementCode,
          version: Number(item?.version) || 0,
          changeDocumentFlg: !!item?.changeDocumentFlg
        };
      });
    const dataRequireDocument = formMethod
      .getValues('materials')
      ?.filter((req: IRequiredDocument) => req.countryId)
      ?.map((item: any, index: number) => {
        return {
          id: item?.id,
          deleted: item?.deleted,
          countryId: item?.countryId,
          documentId: item?.typeId,
          displayOrder: index + 1,
          numberOfCopy: item?.numOfCopy,
          note: item?.note,
          managementCode: item?.managementCode,
          submissionToMot: isNil(item?.submission) ? null : item?.submission === 'need',
          version: Number(item?.version) || 0,
          changeDocumentFlg: !!item?.changeDocumentFlg
        };
      });
    const { categoryId, formId, templateName, templateId, patternId } = formMethod.getValues();
    const defaultProcessDict = arrayToDict(defaultDataProcess, 'id');
    const commonProcesses = intersectArrays(defaultDataProcess, documents as ITemplateProcess[], 'id');
    const commonProcessIdsSet = new Set(commonProcesses.map((i) => i.id));
    const dataTemplateProcessesUpdate = (documents as unknown as ITemplateProcess[]).reduce((arr: Partial<ITemplateProcess>[], item) => {
      if (commonProcessIdsSet.has(item.id)) {
        const defProcess = defaultProcessDict[item.id];
        const commonItems = intersectArrays(defProcess.templateProcessTasks, item.templateProcessTasks, 'id');
        const commonIdSet = new Set(commonItems.map((i) => i.id));
        const templateProcessTasks = [
          ...item?.templateProcessTasks?.map((i) => ({
            ...i,
            id: commonIdSet.has(i.id) ? i.id : undefined,
            changeTaskFlg: !!i.changeTaskFlg
          })),
          ...defProcess?.templateProcessTasks
            ?.filter((i) => !commonIdSet.has(i.id))
            .map((i) => ({
              ...i,
              deleted: true
            }))
        ];
        const newItem: ITemplateProcess = {
          ...item,
          templateProcessTasks: templateProcessTasks as unknown as ITemplateProcessTask[]
        };
        arr.push(newItem);
      } else {
        arr.push({
          ...item,
          id: undefined,
          templateProcessTasks: item?.templateProcessTasks?.map((i) => ({ ...i, id: undefined, changeTaskFlg: true }))
        });
      }
      return arr;
    }, []);
    const dataTemplateProcessesDelete = defaultDataProcess
      .filter((i) => !commonProcessIdsSet.has(i.id))
      .map((item) => ({
        ...item,
        deleted: true,
        templateProcessTasks: item?.templateProcessTasks?.map((task) => ({ ...task, deleted: true }))
      }));
    const templateProcesses = [...dataTemplateProcessesUpdate, ...dataTemplateProcessesDelete];
    templateProcesses.forEach((item) => {
      if (!item?.templateProcessTasks) return;
      item.templateProcessTasks = item.templateProcessTasks.map((task, i) => ({ ...task, displayOrder: i + 1 }));
    });

    const dataPayload = {
      name: templateName,
      referanceTemplateId: templateId,
      patternId,
      countryId,
      categoryId,
      formId,
      templateProcesses,
      requiredDocuments: handleDataSubmit(defaultRequireDocument, dataRequireDocument),
      internalDocuments: handleDataSubmit(defaultInternalDocument, dataInternalDocument)
    };

    return () => {
      next(dataPayload);
    };
  };

  const handleDataSubmit = (dataDefault: any[], dataUpdate: any[]) => {
    const commonItems = intersectArrays(dataUpdate, dataDefault, 'id');
    const commonIdSet = new Set(commonItems.map((i) => i.id));
    const dataInsertOrUpdate = dataUpdate.map((item: any) => ({
      ...item,
      id: commonIdSet.has(item.id) ? item.id : undefined,
      changeDocumentFlg: commonIdSet.has(item.id) ? !!item.changeDocumentFlg : true
    }));
    const dataDelete = dataDefault
      .filter((item: any) => !commonIdSet.has(item.id))
      .map((item: any) => ({
        ...item,
        deleted: true
      }));
    return [...dataInsertOrUpdate, ...dataDelete];
  };

  // Func submit template
  const onSubmit = async (values?: any) => {
    try {
      setLoadingBtn(true);
      dispatch(setLoadingPage(true));
      let res: any = {};
      const isEdit = !!id;
      if (id) {
        const dataChange = {
          ...values,
          id,
          version: formMethod?.getValues('version')
        };
        res = await updateTemplate(dataChange);
      } else {
        res = await onCreateTemplate(values);
      }
      let message: string = '';
      message = res && isEdit ? t('questionnaire:actions:save') : t('customer_request:create');
      formMethod.reset(undefined, { keepDirty: false, keepDirtyValues: false, keepValues: true });
      dispatch(
        setAlertNotification({
          show: true,
          type: 'success',
          position: 'top',
          message: t('common:MSG_C_003', { item: message })
        })
      );
      const projectId = isEdit ? id : res?.data;
      setTimeout(() => {
        return navigate(VIEW_TEMPLATE_URL(projectId));
      }, 0);
    } catch (error: any) {
      if (error?.response) {
        handleCommonError(error);
      }
    } finally {
      dispatch(setLoadingPage(false));
      setLoadingBtn(false);
    }
  };

  // Func create template
  const onCreateTemplate = async (values: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        const dataCreate = templateMTD(values);
        const templateResponse = await createTemplate({ ...dataCreate });
        resolve({
          message: t('common:MSG_026'),
          data: templateResponse?.data
        });
      } catch (error: any) {
        reject(error);
      }
    });
  };

  // Func cancel
  const onCancel = () => {
    navigate(id ? VIEW_TEMPLATE_URL(id) : TEMPLATE_LIST_URL);
  };

  const delayedHandleSearch = debounce((keyword: string) => {
    if (keyword) {
      setDocumentOptions([]);
      setTasks([]);
      const querySearch = _generateFilterSearchQuery(['nameSearch'], keyword);
      setParamsDocuments({
        ...paramsDocuments,
        pageIndex: 0,
        filter: JSON.stringify(querySearch)
      });
    } else {
      setParamsDocuments({
        ...paramsDocuments,
        pageIndex: 0,
        filter: undefined
      });
    }
  }, 300);

  const onHandleSearchDocuments = (keyword: string) => {
    setSearchDocumentsKeywords(keyword);
    setLoadingDocuments(true);
    delayedHandleSearch(keyword);
  };

  const getDocumentOptions = async (countryId: string) => {
    try {
      if (!countryId) return;
      const { data } = await getDocumentsByCountryId(countryId as string);
      const task = await getTasksByCountryId(countryId as string);
      if (data?.length) {
        const newDocumentOptions = data?.map((item: any) => {
          return { label: item?.managementCode, value: item?.id, name: item?.name };
        });
        setTasks(data);
        setDocumentOptions(newDocumentOptions);
      }
      if (task?.data?.length) {
        const newTasksOptions = task?.data?.map((item: any) => {
          return { label: item?.managementCode, value: item?.id, type: item?.type, renderValue: item?.name };
        });
        setTaskOptions(newTasksOptions);
        setTaskProcess(task?.data);
      }
    } catch (e) {}
  };

  const handleSelectPattern = async (pattern: string) => {
    let isChangeData: boolean = false;
    if (!documents?.length) return true;
    documents?.forEach((item: any) => {
      item?.templateProcessTasks?.forEach((task: any) => {
        if (task) isChangeData = true;
      });
    });
    if (!isChangeData) {
      // setIsChangeTemplate(true);
      return true;
    }
    const show = await showBasePopup({
      type: TYPE.CONFIRM,
      title: t('common:MSG_C_013.title') || '',
      msg: t('common:MSG_C_013.description') ?? ''
    });
    if (show === TYPE.CONFIRM) {
      // setIsChangeTemplate(true);
      const newDocument = documents?.map((item: any) => {
        return { ...item, templateProcessTasks: [] };
      });
      formMethod.reset((prev) => {
        return { ...prev, documents: pattern ? newDocument : [], initDocuments: [] };
      });
      return true;
    }
    return false;
  };
  const watchFieldsScheduleTemplate = formMethod.watch(['countryId', 'categoryId', 'formId', 'templateName', 'templateId']);
  const watchFieldsValidScheduleTemplate = watchFieldsScheduleTemplate.filter((field: any) => field);

  useEffect(() => {
    if (!countriesData?.length) return;
    dispatch(setLoadingPage(true));
    if (id) {
      getTemplateDetail();
      setCreateButton(false);
    } else {
      otherCountry && getDocumentOptions(otherCountry?.id as string);
      setCreateButton(false);
      fetchAssignmentForm();
      if (window.location.pathname.includes('add')) {
        setCreateButton(true);
      }
    }
    setTimeout(() => {
      dispatch(setLoadingPage(false));
    }, 1000);
  }, [id, countriesData]);

  useEffect(() => {
    if (otherCountry) {
      setParamsDocuments({
        ...paramsDocuments,
        pageIndex: 0
      });
    }
  }, [templateCountryId, formMethod.getValues('refId')]);

  // Catch countryId changes
  useEffect(() => {
    // Filter old data with current country
    if (countryId && formMethod?.formState?.isDirty) {
      getDocumentOptions(countryId);
      fetchAssignmentForm();
      const countrySelected = countriesData?.find((c: any) => c.id === countryId) as ICountry;
      setDataCategories(countrySelected);
      const newTasks = (formMethod.getValues('tasks') ?? [])
        .filter((item: any) => [countryId, otherCountry?.id].includes(item?.countryId))
        .map((item: IInternalDocument) => {
          return {
            ...item,
            note: sanitizeAndRemoveInsecureLinks(item?.note as string),
            numOfCopy: item?.numberOfCopy,
            typeId: item?.documentId
          };
        });
      const newMaterials = (formMethod.getValues('materials') ?? [])
        .filter((item: any) => [countryId, otherCountry?.id].includes(item?.countryId))
        .map((item: IRequiredDocument) => {
          return {
            ...item,
            note: sanitizeAndRemoveInsecureLinks(item?.note as string),
            numOfCopy: item?.numberOfCopy,
            typeId: item?.documentId,
            submission: isNil(item?.submissionToMot) ? null : item?.submissionToMot ? SUBMISSION_TO_MOT.NEED : SUBMISSION_TO_MOT.UNNEED
          };
        });
      const newDocuments = (formMethod?.getValues('documents') ?? []).map((item: any) => ({
        ...item,
        id: _guuid(),
        templateProcessTasks: item.templateProcessTasks
          .filter((task: any) => [countryId, otherCountry?.id].includes(task?.countryId))
          .map((task: any) => ({
            ...task,
            id: _guuid()
          }))
      }));
      formMethod.setValue('materials', newMaterials);
      formMethod.setValue('tasks', newTasks);
      formMethod.setValue('documents', newDocuments);
      formMethod.setValue('categoryId', '');
      formMethod.setValue('templateId', '');
    }
  }, [countryId]);

  const getTitle = (): string => {
    return id ? t('sider:template_edit') : t('template_layout:template_create');
  };
  return (
    <div className='container h-full add-edit-template-layout-wrapper'>
      <div className='template-header'>
        <TitleButton edit={true} create={createButton} title={getTitle()}>
          {hasPermission(CREATE_TEMPLATE_CANCEL, V) && (
            <BaseButton
              type='tertiary'
              size='medium'
              disabled={!hasPermission(CREATE_TEMPLATE_CANCEL, C)}
              onClick={(e) => {
                onCancel();
              }}
            >
              <div> {t('template_layout:cancel_button')} </div>
            </BaseButton>
          )}
          {hasPermission(CREATE_TEMPLATE_SAVE, V) && (
            <BaseButton
              type='primary'
              size='medium'
              disabled={!hasPermission(CREATE_TEMPLATE_SAVE, C) || (!!id && !isDirty) || isSubmitting}
              onClick={handleSubmitForm}
              loading={loadingBtn}
            >
              <div> {t('template_layout:save_button')} </div>
            </BaseButton>
          )}
        </TitleButton>
      </div>

      <FormProvider {...formMethod}>
        <div
          className='template-layout edit-template-content container-scroll px-5 py-4 rounded-[10px] border-[#DBDBE0] gap-3'
          ref={addEditTemplateRef}
        >
          <BaseCollapse
            id='title_basic_information'
            title={t('template_layout:title_basic_information')}
            total={5}
            questions={watchFieldsValidScheduleTemplate?.length}
            isError={handleCheckErrorField(['countryId', 'templateName'], ['countryId', 'templateName'])}
            ref={basicInformationRef}
          >
            <div className='add-schedule'>
              <AddEditScheduleTemplate
                getDocumentOptions={getDocumentOptions}
                name='schedules'
                method={formMethod}
                getTemplateDetail={getTemplateDetail}
                fetchAssignmentForm={fetchAssignmentForm}
                setDataCategories={setDataCategories}
                optionsVisaCategory={optionsVisaCategory}
                formOptions={formOptions}
              />
            </div>
          </BaseCollapse>
          <BaseCollapse
            id='procedual_process'
            title={t('template_layout:procedual_process')}
            total={1}
            questions={formMethod.watch(['patternId']).filter((field: any) => field).length || 0}
            isError={handleCheckErrorField(['patternId', 'documents'], ['patternId', 'documents'])}
            ref={requireDocumentsDivRef}
          >
            <div>
              <div>
                <Space className='mb-2'>
                  <FormInputSelect
                    disabled={!countryId}
                    required
                    name='patternId'
                    labelTx='template_layout:procedual_process'
                    placeholder={t('placeholder:select', { field: t('template_layout:procedual_process') }) as string}
                    isColumn={true}
                    options={patterns?.map((e) => ({ label: e.name, value: e.id }))}
                    className='min-w-[293px]'
                    allowClear={false}
                    isConfirmPopup={true}
                    handleSelect={(value: any) => {
                      return handleSelectPattern(value);
                    }}
                  />
                </Space>
              </div>
              <RequiredDocuments
                ref={requireDocumentsRef}
                name='documents'
                documents={tasksProcess}
                documentOptions={taskOptions}
                control={formMethod.control}
                formMethod={formMethod}
                register={formMethod.register}
                loadingData={loadingDocuments}
                refreshSearchWhenOpen={!!searchDocumentsKeywords}
                onHandleSearchDocuments={(keyword: string) => onHandleSearchDocuments(keyword)}
                onPopupScroll={() => {
                  if (!loadingDocuments && paramsDocuments.pageIndex < totalPagesDocuments - 1) {
                    setParamsDocuments({
                      ...paramsDocuments,
                      pageIndex: ++paramsDocuments.pageIndex
                    });
                  }
                }}
              />
            </div>
          </BaseCollapse>
          <BaseCollapse
            id='preparation_documents'
            title={t('template_layout:preparation_documents')}
            isError={handleCheckErrorField(['materials'], ['materials'])}
            ref={materialDivRef}
          >
            <div>
              <ReferenceMaterial
                name='materials'
                documents={tasks}
                documentOptions={documentOptions}
                control={formMethod.control}
                formMethod={formMethod}
                ref={materialRef}
                loadingData={loadingDocuments}
                refreshSearchWhenOpen={!!searchDocumentsKeywords}
                onHandleSearchDocuments={(keyword: string) => onHandleSearchDocuments(keyword)}
                onPopupScroll={() => {
                  if (!loadingDocuments && paramsDocuments.pageIndex < totalPagesDocuments - 1) {
                    setParamsDocuments({
                      ...paramsDocuments,
                      pageIndex: ++paramsDocuments.pageIndex
                    });
                  }
                }}
                disabled={!countryId}
              />
            </div>
          </BaseCollapse>
          <BaseCollapse
            id='task_list'
            title={t('template_layout:document')}
            isError={handleCheckErrorField(['tasks'], ['tasks'])}
            ref={taskListDivRef}
          >
            <div>
              <TemplateTaskList
                name='tasks'
                task={tasks}
                documentOptions={documentOptions}
                control={formMethod.control}
                formMethod={formMethod}
                loadingData={loadingDocuments}
                refreshSearchWhenOpen={!!searchDocumentsKeywords}
                onHandleSearchDocuments={(keyword: string) => onHandleSearchDocuments(keyword)}
                onPopupScroll={() => {
                  if (!loadingDocuments && paramsDocuments.pageIndex < totalPagesDocuments - 1) {
                    setParamsDocuments({
                      ...paramsDocuments,
                      pageIndex: ++paramsDocuments.pageIndex
                    });
                  }
                }}
                disabled={!countryId}
              />
            </div>
          </BaseCollapse>
        </div>
      </FormProvider>
      <ConfirmCancelEditing condition={formMethod?.formState?.isDirty && !formMethod?.formState?.isSubmitSuccessful} />
    </div>
  );
}

export default AddEditTemplateLayout;
