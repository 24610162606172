import AddAnnouncement from '@/pages/announcement-management/add-edit/Add';
import EditAnnouncement from '@/pages/announcement-management/add-edit/Edit';
import AnnouncementList from '@/pages/announcement-management/list/List';
import ViewAnnouncement from '@/pages/announcement-management/view/ViewAnnouncement';

import { E, EDIT_ANNOUNCEMENT_ROUTER, V, VIEW_ANNOUNCEMENT_ROUTER } from '@/hooks/useAuthorization';

import { ROUTER_IDS } from '@/utils/constants';
import { ALL_ROLE, INTERNAL_ADMIN, INTERNAL_GENERAL_USER } from '@/utils/constants/AppConstants';
import { ANNOUNCEMENT_LIST_URL, CREATE_ANNOUNCEMENT_URL, EDIT_ANNOUNCEMENT_URL, VIEW_ANNOUNCEMENT_URL } from '@/utils/constants/RouteContants';
import { ANNOUNCEMENT_LIST, CREATE_NEW_ANNOUNCEMENT_LIST } from '@/utils/constants/announcement';
import { MAP_PATH_PRE_CHECK_404 } from '@/utils/helpers/routeLoaders';
import { IRoute } from '@/utils/interfaces/route';

const AnnouncementRoutes: IRoute[] = [
  {
    path: ANNOUNCEMENT_LIST_URL(':id'),
    name: ANNOUNCEMENT_LIST,
    action: V,
    element: <AnnouncementList />,
    auth: true,
    role: ALL_ROLE,
    id: ROUTER_IDS.ANNOUNCEMENT_LIST,
    preCheck404: MAP_PATH_PRE_CHECK_404[ANNOUNCEMENT_LIST_URL(':id')]
  },
  {
    path: VIEW_ANNOUNCEMENT_URL(':id', ':announcementId'),
    name: VIEW_ANNOUNCEMENT_ROUTER,
    action: V,
    element: <ViewAnnouncement />,
    auth: true,
    role: ALL_ROLE,
    preCheck404: MAP_PATH_PRE_CHECK_404[VIEW_ANNOUNCEMENT_URL(':id', ':announcementId')]
  },
  {
    path: CREATE_ANNOUNCEMENT_URL(':id'),
    name: CREATE_NEW_ANNOUNCEMENT_LIST,
    action: E,
    element: <AddAnnouncement />,
    auth: true,
    role: [INTERNAL_ADMIN, INTERNAL_GENERAL_USER],
    preCheck404: MAP_PATH_PRE_CHECK_404[CREATE_ANNOUNCEMENT_URL(':id')]
  },
  {
    path: EDIT_ANNOUNCEMENT_URL(':id', ':announcementId'),
    name: EDIT_ANNOUNCEMENT_ROUTER,
    action: E,
    element: <EditAnnouncement />,
    auth: true,
    role: [INTERNAL_ADMIN, INTERNAL_GENERAL_USER],
    preCheck404: MAP_PATH_PRE_CHECK_404[EDIT_ANNOUNCEMENT_URL(':id', ':announcementId')]
  }
];

export default AnnouncementRoutes;
