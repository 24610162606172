import { Collapse } from 'antd';
import { useTranslation } from 'react-i18next';

import AttachmentFiles from '@/pages/announcement-management/view/componnents/AttachmentFiles';
import { AttachmentFile } from '@/pages/announcement-management/view/models';

import { CaretDownBold } from '@/components/icon-svg/IconSvg';

import { Ii18n } from '@/utils/interfaces/i18n';

import { DEFAULT_ACTIVE_COLLAPSE } from '../../constants';
import { IDetailTaskData } from '../../models';
import FileUploadDetail from './FileUploadDetail';
import TaskInternalDocumentsView from './TaskInternalDocuments';
import TaskRequiredDocumentsView from './TaskRequiredDocuments';

import '../index.scss';

interface DetailTaskInternalProps {
  detailData: IDetailTaskData;
}

const DetailTaskInternal = ({ detailData }: DetailTaskInternalProps) => {
  const { t }: Ii18n = useTranslation();
  const renderExpandIcon = (isActive?: boolean) => {
    return <CaretDownBold type='caret-right' className={`${isActive ? 'rotate-0' : '-rotate-90'}`} />;
  };
  return (
    <>
      <Collapse
        ghost
        items={[
          {
            key: '1',
            label: <span className='text-[18px] font-semibold leading-[25px] text-lnk'>{t('project:task_of_project:task_description')}</span>,
            children: <FileUploadDetail description={detailData?.detail || ''} />
          }
        ]}
        expandIcon={({ isActive }) => renderExpandIcon(isActive)}
        defaultActiveKey={DEFAULT_ACTIVE_COLLAPSE}
      />
      {!!detailData?.projectTaskAttachmentFiles.length && (
        <Collapse
          ghost
          items={[
            {
              key: '1',
              label: <span className='text-[18px] font-semibold leading-[25px] text-lnk'>{t('project:task_of_project:attachments')}</span>,
              children: (
                <AttachmentFiles attachments={(detailData?.projectTaskAttachmentFiles || []) as AttachmentFile[]} showInfo isShowTitle={false} />
              )
            }
          ]}
          expandIcon={({ isActive }) => renderExpandIcon(isActive)}
          defaultActiveKey={DEFAULT_ACTIVE_COLLAPSE}
        />
      )}
      <Collapse
        ghost
        items={[
          {
            key: '1',
            label: <span className='text-[18px] font-semibold leading-[25px] text-lnk'>{t('project:task_of_project:required_documents')}</span>,
            children: <TaskRequiredDocumentsView />
          }
        ]}
        expandIcon={({ isActive }) => renderExpandIcon(isActive)}
        defaultActiveKey={DEFAULT_ACTIVE_COLLAPSE}
      />
      <Collapse
        ghost
        items={[
          {
            key: '1',
            label: <span className='text-[18px] font-semibold leading-[25px] text-lnk'>{t('project:task_of_project:internal_documents')}</span>,
            children: <TaskInternalDocumentsView taskInternalDocuments={detailData?.taskInternalDocuments || []} />
          }
        ]}
        expandIcon={({ isActive }) => renderExpandIcon(isActive)}
        defaultActiveKey={DEFAULT_ACTIVE_COLLAPSE}
      />
    </>
  );
};

export default DetailTaskInternal;
