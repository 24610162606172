import { Col, Row } from 'antd';
import { forwardRef, useEffect } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BaseCard } from '@/components/base-card/BaseCard';
import { BaseCollapse } from '@/components/base-collapse/BaseCollapse';
import BaseCheckboxGroups from '@/components/common/base-checkbox';
import FormItem from '@/components/common/form-item';
import { FormAttachment } from '@/components/form-attachment/FormAttachment';
import { EditorWithCounter } from '@/components/form-box-editor';
import { FormInput } from '@/components/form-input/FormInput';
import { FormSelect } from '@/components/form-select/FormSelect';
import { InfoIcon } from '@/components/icon-svg/IconSvg';

import useAuthorization from '@/hooks/useAuthorization';
import useOptionsGlobal from '@/hooks/useOptionsGlobal';

import { NOTE_MAX_LENGTH, TEXT_FIELD_MIN_LENGTH } from '@/utils/constants/AppConstants';

import { useFormScenarioDContext } from '.';
import { REQUEST_DETAIL } from './contants';

const ScenarioD = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { user } = useAuthorization();
  const form = useFormScenarioDContext();
  const {
    field: { value }
  } = useController({
    name: 'steps[0]',
    control: form.control
  });
  const { customCountryOptions, filterOption } = useOptionsGlobal();

  const filterValidFields = (fieldsCheck: any, lengthText: number = 0) => {
    return fieldsCheck.filter((field: any) => {
      if (Array.isArray(field)) {
        return field.length > 0;
      }
      if (field?.length >= lengthText) {
        return !!field;
      }
    });
  };
  const useFormWatch = (fields: string[]) => form.watch(fields);
  const [requestDetail, phoneNumber, noteSpecial, countryId, requestContent] = useFormWatch([
    'steps[0].requestDetail',
    'steps[0].phoneNumber',
    'steps[0].noteSpecial',
    'steps[0].countryId',
    'steps[0].requestContent'
  ]);
  const checkValueNoticeType = requestDetail && requestDetail.includes(REQUEST_DETAIL.TRANSLATION);
  useEffect(() => {
    const subscription = form.watch((value, { name, type }) => {
      if (type === 'change' && name === 'steps[0].requestDetail' && !checkValueNoticeType) {
        form.setValue('steps[0].noteSpecial', '');
        form.clearErrors('steps[0].noteSpecial');
      }
    });
    return () => subscription.unsubscribe();
  }, [checkValueNoticeType]);
  const watchPhoneNumber = filterValidFields([phoneNumber]);
  const watchInformation = filterValidFields([requestDetail, noteSpecial, countryId]);
  const watchRequestContent = filterValidFields([requestContent], TEXT_FIELD_MIN_LENGTH);

  const haveValidRequestDetail = filterValidFields([requestDetail]);
  const haveValidNoteSpecial = filterValidFields([noteSpecial]);
  const isError = () => haveValidRequestDetail.length === 0 || (checkValueNoticeType && haveValidNoteSpecial.length === 0);

  return (
    <div id='form-scenario-D' className='mt-5'>
      <BaseCard title={String(t('basic_information:applicant_information'))} total={1} questions={watchPhoneNumber.length}>
        <Row gutter={16}>
          <Col span={6}>
            <FormInput
              disabled={true}
              defaultValue={user?.name ?? ''}
              name=''
              labelTx={String(t('inquiry:user_name_label'))}
              placeholderTx={String(t('placeholder:text_box', { field: String(t('inquiry:user_name_label')) }))}
              tooltipProps={{
                trigger: 'hover',
                className: 'truncate text-ink font-bold',
                title: user?.name ?? ''
              }}
            />
          </Col>
          <Col span={6}>
            <FormInput
              disabled={true}
              defaultValue={user?.email ?? ''}
              name=''
              labelTx={String(t('basic_information:email_address'))}
              placeholderTx={String(t('placeholder:text_box', { field: String(t('basic_information:email_address')) }))}
              tooltipProps={{
                trigger: 'hover',
                className: 'truncate text-ink font-bold',
                title: user?.email ?? ''
              }}
            />
          </Col>
          <Col span={6}>
            <FormInput
              disabled={true}
              defaultValue={user?.organizationName ?? ''}
              name=''
              labelTx={String(t('inquiry:company_label'))}
              placeholderTx={String(t('placeholder:text_box', { field: String(t('inquiry:company_label')) }))}
              tooltipProps={{
                trigger: 'hover',
                className: 'truncate text-ink font-bold',
                title: user?.organizationName ?? ''
              }}
            />
          </Col>
          <Col span={6}>
            <FormInput
              name={`steps[0].phoneNumber`}
              labelTx={String(t('inquiry:phone_label'))}
              placeholderTx={String(t('placeholder:text_box', { field: String(t('inquiry:phone_label')) }))}
            />
          </Col>
        </Row>
      </BaseCard>
      <BaseCollapse
        className='form-assignment-info mt-4'
        id='projectAssignmentInfo'
        title={t('basic_information:request_information')}
        total={checkValueNoticeType ? 3 : 2}
        questions={watchInformation.length}
        isError={isError()}
        ref={ref}
      >
        <Row gutter={16}>
          <Col span={6}>
            <FormItem name='steps[0].requestDetail' required={true} label={String(t('basic_information:request_content'))}>
              <BaseCheckboxGroups
                ui={{ wrap: 'flex gap-[40px]' }}
                options={[
                  {
                    label: t('verify_code:btn_confirm'),
                    value: REQUEST_DETAIL.CERTIFICATION
                  },
                  {
                    label: t('project:question_detail:buttons:translate'),
                    value: REQUEST_DETAIL.TRANSLATION
                  }
                ]}
              />
            </FormItem>
          </Col>
          {checkValueNoticeType && (
            <Col span={6}>
              <FormInput
                required={true}
                name='steps[0].noteSpecial'
                labelTx={String(t('basic_information:information_language'))}
                placeholderTx={String(t('placeholder:text_box', { field: String(t('basic_information:information_language')) }))}
              />
            </Col>
          )}
          <Col span={6}>
            <FormSelect
              name='steps[0].countryId'
              label={String(t('basic_information:first_country'))}
              placeholder={String(t('placeholder:text_box', { field: String(t('basic_information:first_country')) }))}
              options={customCountryOptions}
              filterOption={filterOption}
            />
          </Col>
        </Row>
      </BaseCollapse>
      <BaseCollapse id='scenarioDAssignmentInfo' className='form-additional-information mt-4' title={t('basic_information:additional_information')}>
        <Row>
          <Col span={24}>
            <BaseCard title={String(t('basic_information:contact_information'))} total={1} questions={watchRequestContent.length}>
              <Row gutter={16}>
                <Col span={24}>
                  <div className='flex py-2 mb-[-25px]'>
                    <div className='py-[2px] mr-[4px]'>
                      <InfoIcon width={14.63} height={14.63} />
                    </div>
                    <div className='font-normal leading-[22px] text-[14px] text-[#525A68]'>
                      <p>{t('basic_information:note_for_notices')}</p>
                    </div>
                  </div>
                  <EditorWithCounter
                    name='steps[0].requestContent'
                    limit={NOTE_MAX_LENGTH}
                    defaultValue={value.requestContent}
                    toolbar={null}
                    placeholder={String(t('project:scenario:place_holder_note'))}
                    editorWrapperProps={{ className: '!h-[148px]' }}
                  />
                </Col>
              </Row>
            </BaseCard>
          </Col>
          <Col span={24}>
            <BaseCard title={t('project:task_of_project:attachments')} className='mt-4'>
              <div className='flex py-2'>
                <div className='py-[2px] mr-[4px]'>
                  <InfoIcon width={14.63} height={14.63} />
                </div>
                <div className='font-normal leading-[22px] text-[14px] text-[#525A68]'>
                  <p>{t('basic_information:note_for_upload_file')}</p>
                </div>
              </div>
              <FormAttachment
                name='steps[0].projectAttachments'
                defaultValue={value.projectAttachments || []}
                documentType='attachments'
              ></FormAttachment>
            </BaseCard>
          </Col>
        </Row>
      </BaseCollapse>
    </div>
  );
});

export default ScenarioD;
