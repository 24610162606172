import CaretDown from '@/layouts/assets/icons/CaretDown.svg';
import { Button, Collapse, CollapseProps, Popover } from 'antd';
import React, { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AppTooltip from '@/components/app-tooltip/AppTooltip';

import { DataViewer } from '@/utils/helpers/common';

import './SelectedPhrase.scss';

type SelectedPhraseProps = {
  pharses: any[];
  msgNoData?: string;
  onClickItem?: (text: string) => void;
};

const renderExpandIcon = (isActive: boolean | undefined) => {
  return <CaretDown className={`${isActive ? 'rotate-360' : 'rotate-[270deg]'}`} rotate={isActive ? 180 : -90} />;
};

const WrapCollapse = forwardRef(({ items, msgNoData }: { items: CollapseProps['items']; msgNoData?: string }, ref) => {
  const [activeKey, setActiveKey] = useState<string[] | string>([]);

  useImperativeHandle(ref, () => ({
    resetActivekey: () => setActiveKey([])
  }));

  return (
    <div className='w-[250px] phrase-popover-content max-h-[290px] overflow-y-auto'>
      <Collapse
        size='small'
        items={items}
        expandIcon={({ isActive }) => renderExpandIcon(isActive)}
        bordered={false}
        accordion
        ghost
        activeKey={activeKey}
        onChange={(key) => setActiveKey(key)}
        destroyInactivePanel
        className='bg-white'
      />
      {!items?.length && msgNoData && <span className='text-center'>{msgNoData}</span>}
    </div>
  );
});

const SelectedPhrase = ({ pharses, msgNoData, onClickItem }: SelectedPhraseProps) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const collapseRef = useRef<any>();

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
    !open && collapseRef?.current?.resetActivekey();
  };

  const onSelectItem = (text: string) => {
    onClickItem?.(text);
    setOpen(false);
  };

  const items: CollapseProps['items'] = useMemo(
    () =>
      pharses.map((pharse: any, index: number) => ({
        key: index,
        collapsible: 'header',
        label: (
          <AppTooltip title={pharse.name}>
            <div className='truncate w-[204px]'>{pharse.name}</div>
          </AppTooltip>
        ),
        children: (
          <>
            {pharse.data.map((item: any) => (
              <p key={item.id} className='ellipsis p-1 phrase-popover-item break-all cursor-pointer' onClick={() => onSelectItem(item?.content)}>
                <AppTooltip title={item?.content && <span dangerouslySetInnerHTML={{ __html: DataViewer.displayAsSanitizeHTML(item?.content) }} />}>
                  {item.title}
                </AppTooltip>
              </p>
            ))}
          </>
        )
      })),
    [pharses, open]
  );

  return (
    <Popover
      trigger='click'
      placement='bottomRight'
      content={<WrapCollapse items={items} msgNoData={msgNoData} ref={collapseRef} />}
      overlayInnerStyle={{ padding: 0 }}
      rootClassName='select-phrase-popover'
      open={open}
      onOpenChange={handleOpenChange}
    >
      <Button className='absolute top-[2px] right-[12px] min-w-[100px] !rounded-[8px] !h-[38px] font-semibold' size='small'>
        {t('announcement:add:content:button')}
      </Button>
    </Popover>
  );
};

export default SelectedPhrase;
