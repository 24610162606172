import classNames from 'classnames';

import { IErrorInputProps } from '../../utils/interfaces/error-input';
import { ColorDefault } from '../../utils/themes/color';

import './ErrorInput.scss';

export const ErrorInput = ({ error, errorColorTheme, className, classNameLabel }: IErrorInputProps) => {
  // render
  return (
    <div className={classNames('error-input', className)}>
      <label className={classNames('error-text', classNameLabel)} style={{ color: ColorDefault[errorColorTheme ?? 'negative'] }}>
        {error ?? ''}
      </label>
    </div>
  );
};
