import { t } from 'i18next';
import * as yup from 'yup';

import { DRAFT_PREFIX_FLAG, QUERY_CONDITION, QUERY_OPERATION, QUERY_TYPE } from '@/utils/constants';
import { MAX_SECTIONS, NOTE_MAX_LENGTH, TEXT_FIELD_MAX_LENGTH } from '@/utils/constants/AppConstants';
import QueryFactory, { QueryItem } from '@/utils/helpers/filters/base';
import { convertFullWidthToHalfWidth } from '@/utils/helpers/globalHelper';
import { stringifyObjectValidateYup } from '@/utils/method';
import { checkDuplicateDocuments } from '@/utils/services/master';

import { IFilterSearchForm, IQueryFilterParams } from '../utils';

const validateDuplicate = async (title: string, ctx: any): Promise<boolean> => {
  const countryId = ctx.parent.countryId;
  const id = ctx.parent.id;
  const factory = new DocumentQueryFactory();
  factory.and({
    fieldTitle: 'countryId',
    queryType: QUERY_TYPE.GUID,
    queryValue: countryId,
    operation: QUERY_OPERATION.EQUAL
  });

  factory.and({
    fieldTitle: 'managementCodeSearch.ToLower()',
    queryType: QUERY_TYPE.TEXT,
    queryValue: convertFullWidthToHalfWidth(title.toLocaleLowerCase()),
    operation: QUERY_OPERATION.EQUAL
  });

  const body = {
    filter: factory.toFilterString()
  };

  const result = await checkDuplicateDocuments(body);
  if (result?.data?.data?.length && result?.data?.data?.some((item: any) => item.id !== id)) {
    return false;
  }

  return true;
};

export const FormDocument = yup.object().shape({
  name: yup
    .string()
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: t('master_data:document_management:document_name') || '' }
      })
    )
    .max(
      TEXT_FIELD_MAX_LENGTH,
      t('common:MSG_028', { field: t('master_data:document_management:document_name') || '', maxLength: TEXT_FIELD_MAX_LENGTH }) || ''
    )
    .debounceValidate(
      validateDuplicate,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_019',
        optionsTx: { field: 'master_data:document_management:document_name' || '' }
      })
    ),
  submitterName: yup
    .string()
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: t('master_data:document_management:submitter') || '' }
      })
    )
    .max(
      TEXT_FIELD_MAX_LENGTH,
      t('common:MSG_028', { field: t('master_data:document_management:submitter') || '', maxLength: TEXT_FIELD_MAX_LENGTH }) || ''
    ),
  countryId: yup.string().required(
    stringifyObjectValidateYup({
      keyT: 'common:MSG_001_select',
      optionsTx: { field: t('master_data:document_management:country') || '' }
    })
  ),
  numOfCopy: yup
    .string()
    .nullable()
    .max(MAX_SECTIONS, t('common:MSG_028', { field: t('master_data:document_management:numberOfCopy') || '', maxLength: MAX_SECTIONS }) || ''),
  note: yup
    .string()
    .nullable()
    .htmlStrippedMaxLength(
      NOTE_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'master_data:document_management:note', limited: NOTE_MAX_LENGTH }
      })
    ),
  memo: yup
    .string()
    .nullable()
    .htmlStrippedMaxLength(
      NOTE_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'master_data:document_management:memo', limited: NOTE_MAX_LENGTH }
      })
    )
});

export const DEFAULT_DOCUMENT_MASTER = {
  id: null,
  name: null,
  numOfCopy: null,
  submitterName: null,
  countryId: null,
  note: null,
  memo: null
};
class DocumentQueryFactory extends QueryFactory {
  searchingBy(fields: string[] | QueryItem[], searchValue?: string) {
    const newQuerySection = this.initNewQuery(QUERY_CONDITION.AND);
    for (const field of fields) {
      let newItem;
      if (field instanceof QueryItem) {
        newItem = field;
      }
      if (typeof searchValue === 'string' && typeof field === 'string') {
        const halfSizeValue = convertFullWidthToHalfWidth(searchValue);
        const queryCondition = newQuerySection?.childrens?.length ? QUERY_CONDITION.OR : QUERY_CONDITION.EMPTY;
        newItem = new QueryItem(field, QUERY_TYPE.TEXT, halfSizeValue, QUERY_OPERATION.CONTAINS, queryCondition);
      }
      if (!newItem) continue;
      newQuerySection.childrens?.push(newItem);
    }
    if (searchValue?.includes(DRAFT_PREFIX_FLAG)) {
      const queryDraftRecord = new QueryItem('isDraft', QUERY_TYPE.BOOLEAN, 'true', QUERY_OPERATION.EQUAL, QUERY_CONDITION.OR);
      newQuerySection.childrens?.push(queryDraftRecord);
    }
    this.append(newQuerySection);
    return this;
  }

  getByCountryId(countryId: string) {
    const newQuerySection = this.initNewQuery(this.data?.length ? QUERY_CONDITION.AND : QUERY_CONDITION.EMPTY);
    const picIdQuery = new QueryItem().modules.guidWith(countryId, { fieldTitle: 'countryId' });
    newQuerySection.childrens?.push(picIdQuery);
    this.append(newQuerySection);
    return this;
  }

  updatedDateFrom(date: string) {
    const newQuerySection = this.initNewQuery(this.data?.length ? QUERY_CONDITION.AND : QUERY_CONDITION.EMPTY);
    newQuerySection?.childrens?.push(new QueryItem().modules.fromDate(date, { fieldTitle: 'updatedDate' }));
    this.append(newQuerySection);
    return this;
  }

  updatedDateTo(date: string) {
    const newQuerySection = this.initNewQuery(this.data?.length ? QUERY_CONDITION.AND : QUERY_CONDITION.EMPTY);
    newQuerySection?.childrens?.push(new QueryItem().modules.toDate(date, { fieldTitle: 'updatedDate' }));
    this.append(newQuerySection);
    return this;
  }

  filterProject() {
    const newItem = new QueryItem().modules.guidWith('', { fieldTitle: 'projectId' });
    this.append(newItem);
    return this;
  }
}

export const generateFilter = (params?: IQueryFilterParams): string => {
  const filterFactory = new DocumentQueryFactory();
  filterFactory.filterProject();
  if (params?.keyword) {
    const fields = [
      'nameSearch.ToLower()',
      'managementCodeSearch.ToLower()',
      'noteSearch.ToLower()',
      'memoSearch.ToLower()',
      'updatedNameSearch.ToLower()'
    ];
    filterFactory.searchingBy(fields, params.keyword.toLowerCase());
  }

  if (params?.countryId) {
    filterFactory.getByCountryId(params.countryId);
  }

  if (params?.updatedDateFrom) {
    filterFactory.updatedDateFrom(params?.updatedDateFrom);
  }

  if (params?.updatedDateTo) {
    filterFactory.updatedDateTo(params?.updatedDateTo);
  }

  return filterFactory.sanitize().stringifyData();
};

export const DEFAULT_FILTER_SEARCH_FORM: IFilterSearchForm = {
  keyword: '',
  primary: {
    countryId: '',
    updatedDateFrom: '',
    updatedDateTo: ''
  }
};
