const COMMON_TYPES = {
  PROJECT: 'PROJECT.ASSIGN',
  TASK: 'TASK.ASSIGN',
  RESULTS: 'DOCUMENT.APPROVAL_RESULT'
};

export const TYPE_NOTICE_INTERNAL_ADMIN: { [key: string]: string } = {
  ...COMMON_TYPES,
  REQUEST: 'DOCUMENT.APPROVAL_REQUEST',
  EXTERNAL: 'EXTERNAL'
};

export const TYPE_NOTICE_INTERNAL_USER: { [key: string]: string } = {
  ...COMMON_TYPES,
  EXTERNAL: 'EXTERNAL'
};

export const TYPE_NOTICE_EXTERNAL: { [key: string]: string } = {
  PROJECT: 'PROJECT_TASK',
  ANNOUNCEMENT: 'ANNOUNCEMENT',
  OTHERS: 'OTHERS'
};

export const TYPE_CHECK_BOX = {
  NOTICE_HIDDEN: 'noticeHidden',
  NOTICE_FLAG: 'noticeFlag',
  SHOW_LIST_ACCOUNT: 'showListAccount'
};

export const FIELD_FILTER_NOT_COUNT = ['order', 'accountSelect'];
