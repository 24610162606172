import * as lodash from 'lodash';
import { MutableRefObject, useEffect, useState } from 'react';

export interface IUseResizeProps {
  elRef: MutableRefObject<any>;
  handlerType?: 'debounce' | 'throttle';
  delay?: number;
}
const useResize = ({ elRef, handlerType = 'debounce', delay = 300 }: IUseResizeProps) => {
  const [size, setSize] = useState<Partial<DOMRectReadOnly>>({});

  const prepare = lodash[handlerType];

  const onResize = prepare?.(setSize, delay);
  useEffect(() => {
    if (!elRef || !elRef.current) return;
    const resizeObserverByWrapper = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const cr = entry.contentRect;
        onResize(cr.toJSON());
      }
    });
    resizeObserverByWrapper.observe(elRef.current);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      resizeObserverByWrapper && resizeObserverByWrapper.disconnect();
    };
  }, []);

  return { ...size };
};

export default useResize;
