import { Col, Row } from 'antd';
import React, { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseCollapse } from '@/components/base-collapse/BaseCollapse';
import { showBasePopup } from '@/components/base-popup/BasePopup';
import { FormInput } from '@/components/form-input/FormInput';
import { FormSelect } from '@/components/form-select/FormSelect';

import useOptionsGlobal from '@/hooks/useOptionsGlobal';

import { getTemplateById } from '@/utils/services/template';

type Props = {
  disabledAssignee: boolean;
  formMethod: any;
};
const TrainingDestinationInformationForm = forwardRef((props: Props, ref) => {
  const { disabledAssignee, formMethod } = props;
  const [countryIdTemplate, setCountryIdTemplate] = useState<string>('');
  const { t } = useTranslation();
  const { otherCountry, customCountryOptions, filterOption } = useOptionsGlobal();
  const watchFields = formMethod.watch([
    'projectAssignmentInfo.name',
    'projectAssignmentInfo.companyName',
    'projectAssignmentInfo.countryId',
    'projectAssignmentInfo.address'
  ]);
  const watchFieldsValid = watchFields.filter((field: any) => field);
  const watchFieldsRequired = formMethod.watch(['projectAssignmentInfo.countryId']);
  const templateId = formMethod.watch('templateId');
  const watchFieldsRequiredValid = watchFieldsRequired.filter((field: any) => field);

  useEffect(() => {
    if (templateId) {
      getTemplateById(templateId).then((res) => {
        setCountryIdTemplate(res?.data?.countryId ?? '');
      });
    }
  }, [templateId]);

  const isError = () => {
    return watchFieldsRequiredValid?.length !== watchFieldsRequired.length || !!formMethod?.formState?.errors?.projectAssignmentInfo;
  };

  useEffect(() => {
    if (disabledAssignee) {
      formMethod.setValue('projectAssignmentInfo.name', '');
      formMethod.clearErrors('projectAssignmentInfo.name');
    }
  }, [disabledAssignee]);

  const onChangeCountry = (val: string) => {
    const countrySelected = customCountryOptions.find((item) => item.value === val);
    if (countrySelected) {
      formMethod.setValue('projectAssignmentInfo.countryName', countrySelected.originLabel ?? '');
    }
  };

  const placeholderName = disabledAssignee
    ? String(t('basic_information:assignee_trainee_name'))
    : String(t('placeholder:text_box', { field: t('basic_information:assignee_trainee_name') }));
  return (
    <BaseCollapse
      ref={ref}
      id='projectAssignmentInfo'
      title={t('basic_information:assignment_training_information_label')}
      total={4}
      questions={watchFieldsValid.length}
      isError={isError()}
    >
      <Row gutter={16}>
        <Col span={6}>
          <FormInput
            defaultValue={formMethod.getValues('projectAssignmentInfo.name')}
            disabled={disabledAssignee}
            name='projectAssignmentInfo.name'
            labelTx='basic_information:assignee_trainee_name'
            placeholderTx={placeholderName}
          />
        </Col>
        <Col span={6}>
          <FormInput
            defaultValue={formMethod.getValues('projectAssignmentInfo.companyName')}
            name='projectAssignmentInfo.companyName'
            labelTx='basic_information:assignment_training_company_name'
            placeholderTx='basic_information:place_holder_company_name_of_assignee'
          />
        </Col>
        <Col span={6}>
          <FormSelect
            required
            name='projectAssignmentInfo.countryId'
            label={t('basic_information:destination_training_country')}
            options={customCountryOptions}
            filterOption={filterOption}
            placeholder={t('basic_information:place_holder_second_nationality')}
            preChange={async (value: any) => {
              const isOtherCountry = otherCountry?.id === countryIdTemplate;
              if (!!formMethod.getValues('templateId') && !isOtherCountry) {
                const showPopup = await showBasePopup({
                  type: 'confirm',
                  title: t(`common:MSG_P_021:title`) || '',
                  msg: t('common:MSG_P_021:content') || ''
                });
                if (showPopup !== 'confirm') throw new Error('');
              }
              return value;
            }}
            handleChange={onChangeCountry}
          />
        </Col>
        <Col span={6}>
          <FormInput
            defaultValue={formMethod.getValues('projectAssignmentInfo.address')}
            name='projectAssignmentInfo.address'
            label={t('basic_information:assignment_training_city')}
            placeholderTx={String(t('basic_information:place_holder_address_of_the_destination'))}
          />
        </Col>
      </Row>
    </BaseCollapse>
  );
});

export default TrainingDestinationInformationForm;
