import { Col, Row } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormInput } from '@/components/form-input/FormInput';
import { FormSelect } from '@/components/form-select/FormSelect';
import SelectionPreview from '@/components/form-select/SelectionPreview';

import useAuthorization from '@/hooks/useAuthorization';

import { EXTERNAL_ADMIN, EXTERNAL_GENERAL_USER, INTERNAL_ADMIN, INTERNAL_GENERAL_USER } from '@/utils/constants/AppConstants';
import { INTERNAL_COMPANY_ID } from '@/utils/constants/account';
import { getRoleList } from '@/utils/services/AccountMasterApiService';
import { getAllCompany } from '@/utils/services/ProjectApiService';

import { OptionTypeStatus } from './models';

import './Add.scss';

type BaseFormProps = {
  formMethod?: any;
  isEdit?: boolean;
};

const BaseFormAccountMater = ({ formMethod, isEdit }: BaseFormProps) => {
  const baseMethod = formMethod;
  const { isDirty } = formMethod?.formState;
  const { user, isExternalAdmin } = useAuthorization();
  const [companyNameOptions, setCompanyNameOptions] = useState<DefaultOptionType[]>([]);
  const [authorityOptions, setAuthorityOptions] = useState<DefaultOptionType[]>([]);
  const [newAuthorityOptions, setNewAuthorityOptions] = useState<DefaultOptionType[]>([]);
  const [statusOptions, setStatusOptions] = useState<OptionTypeStatus[]>([]);
  const [loadings, setLoadings] = useState<{
    company: boolean;
    role: boolean;
  }>({
    company: false,
    role: false
  });

  const [authority, setAuthority] = useState(true);
  const { t } = useTranslation();

  const getDataCompany = async () => {
    try {
      const { data } = await getAllCompany();
      let options = data?.map((company: any) => {
        return {
          label: company.name,
          value: company.id
        };
      });
      setCompanyNameOptions(options);
      if (isExternalAdmin) {
        formMethod.setValue('organizationId', user?.organizationId);
      }
    } catch (error) {
    } finally {
      setLoadings((prev) => ({ ...prev, company: false }));
    }
  };

  const getDataRole = async () => {
    try {
      const { data } = await getRoleList();
      let options = data?.map((role: any) => {
        return {
          label: role.name,
          value: role.id,
          type: role.code
        };
      });
      setAuthorityOptions(options);
    } catch (error) {
    } finally {
      setLoadings((prev) => ({ ...prev, role: false }));
    }
  };

  const handleDataStatus = async () => {
    let options = [
      {
        label: String(t('account_list:status_account:active')),
        value: true
      },
      {
        label: String(t('account_list:status_account:inactive')),
        value: false
      }
    ];
    setStatusOptions(options);
  };

  useEffect(() => {
    handleOnChangeCompany(formMethod.getValues('organizationId'));
  }, [authorityOptions, formMethod.watch('organizationId')]);

  useEffect(() => {
    setLoadings({
      company: true,
      role: true
    });
    getDataRole();
    getDataCompany();
    handleDataStatus();
  }, []);

  const handleOnChangeCompany = (value: any) => {
    const newOptions = authorityOptions.filter((role) => {
      return value === INTERNAL_COMPANY_ID
        ? [INTERNAL_ADMIN, INTERNAL_GENERAL_USER].includes(role.type)
        : [EXTERNAL_ADMIN, EXTERNAL_GENERAL_USER].includes(role.type);
    });

    setNewAuthorityOptions(newOptions);
    setAuthority(!value);
    if (formMethod.getValues('email') && isDirty) {
      formMethod.trigger('email');
    }
    if (value && formMethod.getValues('roleId')) {
      formMethod.trigger('roleId');
    } else if (!value) {
      formMethod.setValue('roleId', null);
      formMethod.clearErrors('roleId');
    }
  };

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <FormInput
            required={true}
            name='name'
            defaultValue={baseMethod.getValues('name')}
            labelTx='account_master:edit:username:label'
            placeholderTx={String(t('account_master:edit:username:placeholder', { field: t('account_master:edit:username:label') }))}
          />
        </Col>
        <Col span={12}>
          <FormInput
            required={true}
            disabled={isEdit}
            name='email'
            defaultValue={baseMethod.getValues('email')}
            labelTx='account_master:edit:email:label'
            placeholderTx={String(t('account_master:edit:email:placeholder', { field: t('account_master:edit:email:label') }))}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <SelectionPreview options={companyNameOptions} value={baseMethod.getValues('organizationId')}>
            <FormSelect
              required={true}
              disabled={isEdit || isExternalAdmin}
              name='organizationId'
              optionLoading={loadings.company}
              label={String(t('account_master:edit:company_name:label'))}
              placeholder={String(t('account_master:edit:company_name:placeholder', { field: t('account_master:edit:company_name:label') }))}
              options={companyNameOptions}
              handleChange={handleOnChangeCompany}
            />
          </SelectionPreview>
        </Col>
        <Col span={12}>
          <Row gutter={16}>
            <Col span={isEdit ? 12 : 24}>
              <FormSelect
                required={true}
                name='roleId'
                optionLoading={loadings.role}
                label={String(t('account_master:edit:authority:label'))}
                placeholder={String(t('account_master:edit:authority:placeholder', { field: t('account_master:edit:authority:label') }))}
                options={newAuthorityOptions}
                disabled={isEdit ? false : authority}
              />
            </Col>
            {isEdit && (
              <Col span={12}>
                <FormSelect
                  required={true}
                  name='status'
                  label={String(t('account_master:edit:status:label'))}
                  placeholder={String(t('account_master:edit:status:placeholder', { field: t('account_master:edit:status:label') }))}
                  options={statusOptions}
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default BaseFormAccountMater;
