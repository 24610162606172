import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import AppTooltip from '@/components/app-tooltip/AppTooltip';

import ArrowLineUp from '@assets/icons/questions/ArrowLineUp.svg';
import QuestionIcon from '@assets/icons/questions/Question.svg';

interface IStickyProps {
  scrollToTop?: () => void;
  moveToNextQuestion?: () => void;
  showScrollTop?: boolean;
  showMoveNextQuestion?: boolean;
}

const StickyButtons = ({ showScrollTop = true, scrollToTop, moveToNextQuestion }: IStickyProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        className='flex flex-col space-y-3'
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '10px',
          width: '70px',
          height: '113px',
          fontSize: '2rem',
          zIndex: 10
        }}
      >
        <AppTooltip title={t('project:question_detail:buttons:move_to_next_question_tooltip')}>
          <Button
            type='default'
            className='body-small-bold !p-0 w-[50px] h-[50px] flex flex-col justify-center items-center'
            onClick={(e) => {
              moveToNextQuestion && moveToNextQuestion();
              e.currentTarget.blur();
            }}
          >
            <QuestionIcon />
            {t('project:question_detail:buttons:move_to_next_question')}
          </Button>
        </AppTooltip>
        {showScrollTop && (
          <AppTooltip title={t('project:question_detail:buttons:scroll_to_top_tooltip')}>
            <Button
              type='default'
              className='body-small-bold !p-0 w-[50px] h-[50px] flex flex-col justify-center items-center'
              onClick={(e) => {
                scrollToTop && scrollToTop();
                e.currentTarget.blur();
              }}
            >
              <ArrowLineUp />
              {t('project:question_detail:buttons:scroll_to_top')}
            </Button>
          </AppTooltip>
        )}
      </div>
    </>
  );
};

export default StickyButtons;
