import { useAppDispatch } from '@/hooks';
import CaretDown from '@/layouts/assets/icons/CaretDown.svg';
import { Collapse, CollapseProps, Space } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import AppLabel from '@/components/app-label/AppLabel';
import { BaseButton } from '@/components/base-button/BaseButton';
import { showBasePopup } from '@/components/base-popup/BasePopup';

import { setAlertNotification, setLoadingPage } from '@/redux/globalReducer';

import useAuthorization, { C, EDIT_TEMPLATE_DELETE, V, VIEW_TEMPLATE_COLLAPSE, VIEW_TEMPLATE_EDIT_BUTTON } from '@/hooks/useAuthorization';

import { TYPE } from '@/utils/constants/AppConstants';
import { EDIT_TEMPLATE_URL, TEMPLATE_LIST_URL } from '@/utils/constants/RouteContants';
import { sanitizeAndRemoveInsecureLinks } from '@/utils/helpers/globalHelper';
import { IDocument, ISchedule } from '@/utils/interfaces/template';
import { deleteTemplates, getTemplateById, getTemplates } from '@/utils/services/template';

import Trash from '@/assets/icons/TrashIcon.svg';

import TitleButton from '../add-edit/components/title-button/TitleButton';
import Material from './components/Material';
import TemplateProcesses from './components/TemplateProcesses';
import ViewSchedule from './components/schedule/ViewSchedule';
import ViewTemplateTaskList from './components/view-template-task-list/ViewTemplateTaskList';
import { ITemplate } from './modules';

import '../TemplateLayout.scss';

const ViewDetail = () => {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const { id } = useParams();
  const [, setEditButton] = useState(false);
  const [createButton, setCreateButton] = useState(false);
  const [template, setTemplate] = useState<ITemplate>();
  const [loading, setLoading] = useState<boolean>(false);
  const [schedules, setSchedules] = useState({});
  const formMethod = useForm<ITemplate>({
    mode: 'all',
    shouldUnregister: false
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [, setReferenceTemplateOptions] = useState<DefaultOptionType[]>([]);

  const viewTemplateRef = useRef<any>(null);

  // permission
  const { hasPermission } = useAuthorization();

  useEffect(() => {
    if (id) {
      setEditButton(true);
      setCreateButton(false);
    }
  }, [id]);

  useEffect(() => {
    getTemplateDetail();
  }, []);
  const [, setTemplateId] = useState('');

  const getTemplateList = async (filter?: any) => {
    try {
      const { data } = await getTemplates({ filter });
      if (data.data) {
        const options = data.data
          .filter((e: any) => e.id !== id)
          .map((c: IDocument) => {
            return {
              label: c.name,
              value: c.id
            };
          });
        setReferenceTemplateOptions(options);
      }
    } catch {}
  };

  const getTemplateDetail = async () => {
    try {
      dispatch(setLoadingPage(true));
      setLoading(true);
      const { data } = await getTemplateById(id as string);
      const newTasks = data?.tasks?.map((item: any) => {
        return { ...item, remark: sanitizeAndRemoveInsecureLinks(item?.remark as string) };
      });
      getTemplateList();
      setTemplate((prev) => {
        if (prev) {
          return {
            ...prev,
            ...data,
            tasks: newTasks,
            templateProcesses: data.templateProcesses
          };
        }
        return data;
      });
      setTemplateId(data?.id);

      setSchedules(data);
      setLoading(false);
      formMethod.reset(data);
    } catch (error) {
      console.error(error);
      setLoading(false);
    } finally {
      dispatch(setLoadingPage(false));
    }
  };
  // Delete template
  const onDeleteTemplate = async () => {
    try {
      await deleteTemplates({ ids: [{ id: id as string, version: template?.version as string }] });
      navigate(TEMPLATE_LIST_URL);
      dispatch(
        setAlertNotification({
          show: true,
          type: TYPE.SUCCESS,
          message: t('common:MSG_C_003', { item: t('questionnaire:actions:delete') })
        })
      );
    } catch (error: any) {
      if (error?.response) {
        let errMessage = t('common:MSG_032');
        if (error?.response?.status === 404) {
          errMessage += t('common:error_asynchronous_data_delete');
          navigate(TEMPLATE_LIST_URL);
        }
        dispatch(
          setAlertNotification({
            show: true,
            type: TYPE.ERROR,
            message: errMessage
          })
        );
      }
    }
  };

  const checkFields = (tmpl: ISchedule) => {
    const { countryName, categoryName, formName, name, referanceTemplateName } = tmpl;
    let count = 0;
    if (countryName) count++;
    if (categoryName) count++;
    if (formName) count++;
    if (name) count++;
    if (referanceTemplateName) count++;
    switch (count) {
      case 1:
        return '1/5';
      case 2:
        return '2/5';
      case 3:
        return '3/5';
      case 4:
        return '4/5';
      case 5:
        return '5/5';
      default:
        return '0/5';
    }
  };

  const expandIconRender = (isActive: boolean | undefined) => {
    return <CaretDown type='caret-right' className={`${isActive ? 'rotate-0' : '-rotate-90'}`} rotate={isActive ? 180 : 0} />;
  };

  const ITEMS: CollapseProps['items'] = [
    {
      key: 'title_basic_information',
      label: (
        <div className='flex items-center gap-2'>
          <span className='text-18 font-semibold'>{t('template_layout:title_basic_information')}</span>
          <p className='text-sm font-bold bg-[#DBDBE0] rounded !p-1'>{checkFields(schedules)}</p>
        </div>
      ),
      children: !loading && <ViewSchedule data={schedules} />,
      style: {
        marginLeft: 20,
        marginRight: 20,
        marginBottom: 12,
        border: '1px solid #DBDBE0',
        borderRadius: '8px',
        boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)'
      }
    },
    {
      key: 'procedual_process',
      label: (
        <div className='flex items-center gap-2'>
          <span className='text-18 font-semibold'>{t('template_layout:procedual_process')}</span>
          <p className='text-sm font-bold bg-[#DBDBE0] rounded !p-1'>1/1</p>
        </div>
      ),
      children: !loading && (
        <div>
          <div>
            <Space className='flex mb-2'>
              <AppLabel label={t('template_layout:procedual_process')} title={template?.patternName} hasTooltip />
            </Space>
          </div>
          <TemplateProcesses data={template?.templateProcesses || []} />
        </div>
      ),
      style: {
        marginLeft: 20,
        marginRight: 20,
        marginBottom: 12,
        border: '1px solid #DBDBE0',
        borderRadius: '8px',
        boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)'
      }
    },
    {
      key: 'preparation_documents',
      label: <span className='text-18 font-semibold'>{t('template_layout:preparation_documents')}</span>,
      children: !loading && <Material materials={template?.requiredDocuments || []} />,
      style: {
        marginLeft: 20,
        marginRight: 20,
        marginBottom: 12,
        border: '1px solid #DBDBE0',
        borderRadius: '8px',
        boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)'
      }
    },
    {
      key: 'task_list',
      label: <span className='text-18 font-semibold'>{t('template_layout:document')}</span>,
      children: !loading && <ViewTemplateTaskList internalDocument={template?.internalDocuments || []} />,
      style: {
        marginLeft: 20,
        marginRight: 20,
        marginBottom: 12,
        border: '1px solid #DBDBE0',
        borderRadius: '8px',
        boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)'
      }
    }
  ];

  return (
    <FormProvider {...formMethod}>
      <div className='container h-full'>
        <div className='template-header'>
          <TitleButton edit={false} create={createButton} title={t('sider:template_details') as string}>
            <>
              {hasPermission(EDIT_TEMPLATE_DELETE, V) && (
                <BaseButton
                  type='outline-red'
                  size='medium'
                  disabled={!hasPermission(EDIT_TEMPLATE_DELETE, C)}
                  className='delete-btn !min-w-[165px]'
                  onClick={async () => {
                    const show = await showBasePopup({
                      type: TYPE.DISCARD,
                      title: t('common:MSG_029.1_title', { item: t('schedule:template') }) ?? '',
                      msg:
                        `${t('common:MSG_029.1_description', { item: t('schedule:template') })} ${t('common:MSG_029_description2', {
                          item: t('schedule:template')
                        })}` ?? ''
                    });
                    if (show === TYPE.CONFIRM) {
                      // call api delete
                      onDeleteTemplate();
                    }
                  }}
                >
                  <Trash />
                  <div>{t('template_layout:delete_template_button')}</div>
                </BaseButton>
              )}
            </>
            {hasPermission(VIEW_TEMPLATE_EDIT_BUTTON, C) && (
              <BaseButton
                type='primary'
                size='medium'
                disabled={!hasPermission(VIEW_TEMPLATE_EDIT_BUTTON, C)}
                className='btn-save medium'
                onClick={() => navigation(EDIT_TEMPLATE_URL(id ?? ''))}
              >
                <div> {t('template_layout:edit_button')} </div>
              </BaseButton>
            )}
          </TitleButton>
        </div>

        <div className='template-layout template-content pb-[8px]' ref={viewTemplateRef}>
          <div className='container-basic pb-[8px]'>
            <div className='pt-4'>
              {hasPermission(VIEW_TEMPLATE_COLLAPSE, V) && (
                <Collapse
                  collapsible='header'
                  items={ITEMS}
                  bordered={false}
                  defaultActiveKey={ITEMS.map((i: any) => i.key)}
                  expandIcon={({ isActive }) => expandIconRender(isActive)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </FormProvider>
  );
};

export default ViewDetail;
