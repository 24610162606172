// Project list
export const COMPANY_LIST = 'COMPANY_LIST';
export const CREATE_NEW_COMPANY = 'CREATE_NEW_COMPANY';
export const EDIT_NEW_COMPANY = 'EDIT_NEW_COMPANY';
export const FILTER_COMPANY_LIST = 'FILTER_COMPANY_LIST';
export const CHECK_COMPANY_LIST = 'CHECK_COMPANY_LIST';
export const CREATE_NEW_COMPANY_LIST = 'CREATE_NEW_COMPANY_LIST';
export const SEARCH_COMPANY_LIST = 'SEARCH_COMPANY_LIST';
export const TABLE_COMPANY_LIST = 'TABLE_COMPANY_LIST';
export const NO_DATA_COMPANY_LIST = 'NO_DATA_COMPANY_LIST';
export const ONCE_YOU_START_ADDING_COMPANIES = 'ONCE_YOU_START_ADDING_COMPANIES';
export const NO_DATA_CREATE_NEW_COMPANY = 'NO_DATA_CREATE_NEW_COMPANY';
export const COMPANY_LIST_ROUTER = 'COMPANY_LIST_ROUTER';
