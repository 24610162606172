import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IQuestionnaireLockConfirmNotification {
  createdName?: string;
  deleted?: boolean;
  isLocked?: boolean;
  lockedBy?: string;
  lockedDate?: string;
  lockedMail?: string;
  objectId?: string;
  sendUnlockRequest?: boolean;
  sendUnlockRequestDate?: string;
  unlockKey?: string;
  updatedName?: string;
}

interface IQuestionnaireStore {
  lockConfirmObj: IQuestionnaireLockConfirmNotification | null;
}

export const questionnaireSpaceName = 'questionnaire';

// Define the initial state using that type
const initialState: IQuestionnaireStore = {
  lockConfirmObj: null
};

export const questionnaireSlice = createSlice({
  name: questionnaireSpaceName,
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setQuestionnaireConfirmLock: (state, action: PayloadAction<any>) => {
      state.lockConfirmObj = action.payload ?? null;
    }
  }
});

export const { setQuestionnaireConfirmLock } = questionnaireSlice.actions;

export default questionnaireSlice.reducer;
