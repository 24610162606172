import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { BaseButton } from '@/components/base-button/BaseButton';

import { PROJECT_LIST_URL } from '@/utils/constants/RouteContants';

import PersonThankYou from '@/assets/images/PersonThankYou.svg';

export default function RegisterSuccess() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="w-full h-screen bg-[url('@/assets/images/BgSuccessScenarioE.png')] bg-center bg-no-repeat flex flex-col items-center justify-center">
      <PersonThankYou width={334} height={364}></PersonThankYou>
      <h1 className='title-24'>{t('project:scenario:register_success_msg_1')}</h1>
      <p className='subtitle-16 text-textGray'>{t('project:scenario:register_success_msg_2')}</p>
      <p className='subtitle-16 text-textGray'>{t('project:scenario:register_success_msg_3')}</p>
      <BaseButton className='mt-4' onClick={() => navigate(PROJECT_LIST_URL)}>
        {t('page_not_found:back_home')}
      </BaseButton>
    </div>
  );
}
