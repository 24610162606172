import { AnyObject } from 'yup';

export const relativePath = <T extends string = string, K extends string = string>(field: T, parentPath?: K) => {
  if (!parentPath) return field;
  return `${parentPath}.${field}`;
};

export const getFirstErrorName = (errors?: AnyObject | AnyObject[], listErrorMappingObj?: Record<string, string[]>, prefix = 'steps'): string => {
  if (!errors) return '';

  const isArray = errors instanceof Array;
  for (let i = 0; i < (isArray ? errors.length : 1); i++) {
    const currentErrors = isArray ? errors[i] : errors;
    if (!currentErrors) {
      continue;
    }
    const parentName = listErrorMappingObj && Object.keys(listErrorMappingObj).find((key) => prefix.split('.').pop()?.includes(key));
    const listErrorName = parentName ? listErrorMappingObj[parentName] : Object.keys(currentErrors);
    for (const key of listErrorName) {
      // return name if found first error
      if (currentErrors[key]?.hasOwnProperty('message')) {
        return isArray ? (prefix ? `${prefix}[${i}].${key}` : key) : prefix ? `${prefix}.${key}` : key;
        // continue find error in deeper level
      } else {
        const nestedName = getFirstErrorName(
          currentErrors[key],
          listErrorMappingObj,
          isArray ? (prefix ? `${prefix}[${i}].${key}` : key) : prefix ? `${prefix}.${key}` : key
        );
        // return name if found first error in deeper level
        if (nestedName) return nestedName;
      }
    }
  }

  // return empty if no error found
  return '';
};
