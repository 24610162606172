import { AuthContextProps } from 'react-oidc-context';
import { LoaderFunction, LoaderFunctionArgs, PathMatch, matchPath } from 'react-router-dom';

import { TASK_TYPE } from '@/pages/project-management/detail/constant';
import { STATUS_NAME } from '@/pages/project-management/project-list-v2/models';

import { axiosInstance } from '@/config/http';

import { ROUTER_IDS } from '../constants';
import { API } from '../constants/Apis';
import { EXTERNAL_ADMIN, EXTERNAL_GENERAL_USER, INTERNAL_ADMIN, INTERNAL_GENERAL_USER, LOCALSTORAGE } from '../constants/AppConstants';
import { HTTP_STATUS_CODE } from '../constants/Http';
import {
  ADD_TASK_OF_PROJECT_URL,
  ANNOUNCEMENT_LIST_URL,
  APPROVE_REJECT_ACCOUNT_MASTER_URL,
  CREATE_ANNOUNCEMENT_URL,
  CREATE_PROJECT_INQUIRY_URL,
  DOCUMENT_LIST_URL,
  EDIT_ANNOUNCEMENT_URL,
  EDIT_DOCUMENT_LIST_URL,
  EDIT_PROJECT_BASIC_INFO_URL,
  EDIT_PROJECT_URL,
  EDIT_TEMPLATE_URL,
  NOT_EXITS_URL,
  PROJECT_BASIC_INFO_URL,
  PROJECT_FILE_LIST_URL,
  PROJECT_MEMO_URL,
  PROJECT_QUESTION_DETAIL_URL,
  PROJECT_QUESTION_LIST_URL,
  SAVE_AS_DRAFT_PROJECT_SUCCESS,
  STAKEHOLDER_UPDATE_URL,
  STAKEHOLDER_VIEW_URL,
  UPDATE_ACCOUNT_MASTER_URL,
  VIEW_ANNOUNCEMENT_URL,
  VIEW_PROJECT_URL,
  VIEW_TASK_OF_PROJECT_URL,
  VIEW_TEMPLATE_URL
} from '../constants/RouteContants';
import { IRoute } from '../interfaces/route';
import { getFilterConfigs } from '../services/UserApiService';
import { isJsonString } from './common';

export const getParamFromUrl = (pattern: string): PathMatch<string> | null => {
  const pathname = window.location.pathname;
  const result = matchPath({ path: pattern }, pathname);
  return result;
};

export const fetchInfo = async (url: string, method: string = 'GET') => {
  if (method === 'GET') return axiosInstance.get(url);
  return axiosInstance.post(url);
};

export const fetchProjectStatus = async (query?: Object) => {
  return axiosInstance.get(API.GET_PROJECT_STATUS_LIST, { params: query });
};

/**
 * @description if project is not drafted (same submitted), this func will redirect to 404.
 */
const mustDraftedProject = async (params: RouterParams) => {
  if (!params?.id) return null;
  const url = API.GET_PROJECT_PUBLIC_INFO(params.id);
  return fetchInfo(url).then((res) => {
    if (!res?.data?.isDraft) {
      throw { response: { status: HTTP_STATUS_CODE.NOT_FOUND } };
    }
    return res;
  });
};

/**
 * @description if project is drafted (same submitted), this func will redirect to 404.
 */
const mustSubmittedProject = async (params: RouterParams) => {
  if (!params?.id) return null;
  const url = API.GET_PROJECT_PUBLIC_INFO(params.id);
  return fetchInfo(url).then((res) => {
    if (res?.data?.isDraft) {
      throw { response: { status: HTTP_STATUS_CODE.NOT_FOUND } };
    }
    return res;
  });
};

const mustPublishedProject = async (params: RouterParams) => {
  if (!params?.id) return null;
  const url = API.GET_PROJECT_PUBLIC_INFO(params.id);
  return fetchInfo(url).then((res) => {
    if (!res?.data?.isPublished) {
      throw { response: { status: HTTP_STATUS_CODE.NOT_FOUND } };
    }
    return res;
  });
};

const onlyAccessInternalAccount = (params: RouterParams) => {
  const user = RouterLoaderObserver.getUserProfile();
  const isExternalRole = [EXTERNAL_ADMIN, EXTERNAL_GENERAL_USER].includes(user?.role);
  return new Promise((resolve, reject) => {
    if (isExternalRole) {
      reject({ response: { status: HTTP_STATUS_CODE.NOT_FOUND } });
    }
    resolve(mustSubmittedProject(params));
  });
};

const validateTaskOfProject = async (params: RouterParams) => {
  const { idProject, idTask } = params || {};
  if (!idProject || !idTask) return null;
  const user = RouterLoaderObserver.getUserProfile();
  const isExternalRole = [EXTERNAL_ADMIN, EXTERNAL_GENERAL_USER].includes(user?.role);
  await mustSubmittedProject({ ...params, id: idProject });
  try {
    const url = API.GET_DETAIL_TASK(idProject, idTask);
    const responses = await fetchInfo(url);
    if (isExternalRole) {
      const taskStatus = await fetchProjectStatus({ type: 'task' });
      const { data } = responses;
      const statusName = taskStatus?.data?.find((item: any) => item.id === data?.prevStatusId)?.name;
      const isNotStart = STATUS_NAME.TODO === statusName;
      const isMotOrCustomerTask = [TASK_TYPE.MOT, TASK_TYPE.OTHER].includes(data?.taskType);
      const accessDenied = isMotOrCustomerTask && isNotStart;
      if (accessDenied) throw { response: { status: HTTP_STATUS_CODE.NOT_FOUND } };
    }
  } catch (error) {
    throw error;
  }
};

const getTemplateInfo = async (params: RouterParams) => {
  if (!params?.id) return null;
  return fetchInfo(API.GET_TEMPLATE_INFO(params.id));
};

const getUserDetail = async (params: RouterParams) => {
  if (!params?.id) return null;
  return fetchInfo(API.GET_USER_DETAIL(params.id));
};

const getAnnouncementDetail = async (params: RouterParams) => {
  const { announcementId, id: projectId } = params || {};
  if (!announcementId || !projectId) return null;
  return fetchInfo(API.GET_ANNOUNCEMENT_DETAIL(announcementId)).then(({ data }) => {
    if (data && data.projectId !== projectId) {
      throw { response: { status: HTTP_STATUS_CODE.NOT_FOUND } };
    }
    return data;
  });
};

const validateAnnouncement = async (params: RouterParams) => {
  const { announcementId, id: projectId } = params || {};
  if (!announcementId || !projectId) return null;
  try {
    const { data } = await fetchInfo(API.GET_ANNOUNCEMENT_DETAIL(announcementId));
    if (data?.projectId !== projectId) {
      throw { response: { status: HTTP_STATUS_CODE.NOT_FOUND } };
    }
    return data;
  } catch (error: any) {
    if (error?.response?.status === HTTP_STATUS_CODE.NOT_FOUND) throw { response: { status: HTTP_STATUS_CODE.NOT_FOUND } };
  }
};

const getQuestionnaireInfo = async (params: RouterParams) => {
  if (!params?.id || !params?.formId) return null;
  return fetchInfo(API.GET_PROJECT_QUESTIONNAIRE_FORM_INFO(params.id, params.formId)).catch((error) => {
    throw { response: { status: HTTP_STATUS_CODE.NOT_FOUND } };
  });
};

const getStakeHolderByProject = async (params: RouterParams) => {
  if (!params?.id) return null;
  const user = RouterLoaderObserver.getUserProfile();
  const isExternalRole = [EXTERNAL_ADMIN, EXTERNAL_GENERAL_USER].includes(user?.role);
  return fetchInfo(API.GET_STAKEHOLDERS_BY_PROJECT(params.id)).then(({ data }) => {
    if (isExternalRole) {
      return mustPublishedProject(params).then(() => data);
    }
    return data;
  });
};

/**
 * @description: if u wanna use this module, u must define key preCheck404 for each route
 */
export const MAP_PATH_PRE_CHECK_404: { [key: string]: IRoute['preCheck404'] } = {
  [EDIT_PROJECT_URL(':id')]: mustDraftedProject,
  [VIEW_PROJECT_URL(':id')]: mustSubmittedProject,
  [PROJECT_FILE_LIST_URL(':id')]: mustSubmittedProject,
  [PROJECT_QUESTION_LIST_URL(':id')]: mustSubmittedProject,
  [CREATE_PROJECT_INQUIRY_URL(':id')]: mustSubmittedProject,
  [SAVE_AS_DRAFT_PROJECT_SUCCESS(':id')]: mustDraftedProject,
  [PROJECT_BASIC_INFO_URL(':id')]: mustSubmittedProject,
  [EDIT_PROJECT_BASIC_INFO_URL(':id')]: onlyAccessInternalAccount,
  [PROJECT_MEMO_URL(':id')]: onlyAccessInternalAccount,

  // Documents of project
  [DOCUMENT_LIST_URL(':id')]: mustSubmittedProject,
  [EDIT_DOCUMENT_LIST_URL(':id')]: onlyAccessInternalAccount,

  // Task of project
  [ADD_TASK_OF_PROJECT_URL(':idProject')]: mustSubmittedProject,
  [VIEW_TASK_OF_PROJECT_URL(':idProject', ':idTask')]: validateTaskOfProject,
  [STAKEHOLDER_UPDATE_URL(':id')]: getStakeHolderByProject,
  [STAKEHOLDER_VIEW_URL(':id')]: getStakeHolderByProject,

  // Announcement
  [ANNOUNCEMENT_LIST_URL(':id')]: mustSubmittedProject,
  [CREATE_ANNOUNCEMENT_URL(':id')]: mustSubmittedProject,
  [VIEW_ANNOUNCEMENT_URL(':id', ':announcementId')]: validateAnnouncement,
  [EDIT_ANNOUNCEMENT_URL(':id', ':announcementId')]: getAnnouncementDetail,

  [VIEW_TEMPLATE_URL(':id')]: getTemplateInfo,
  [EDIT_TEMPLATE_URL(':id')]: getTemplateInfo,
  [APPROVE_REJECT_ACCOUNT_MASTER_URL(':id')]: getUserDetail,
  [UPDATE_ACCOUNT_MASTER_URL(':id')]: getUserDetail,

  // Questionnaire
  [PROJECT_QUESTION_DETAIL_URL(':id', ':formId')]: getQuestionnaireInfo
};

const fetchFilterConfigs = async (id: string) => {
  let results: any = null;
  try {
    const responses = await getFilterConfigs();
    if (responses.status !== HTTP_STATUS_CODE.SUCCESS) return (results = null);
    results = responses.data[id] ?? null;
  } catch (error) {
    results = null;
  }
  return results;
};

export type RouterParams = LoaderFunctionArgs<IRouterLoaderResponse<any, any>>['params'];
export interface IRouterLoaderResponse<T404, TFilters> {
  preCheck404: T404 | null;
  filterConfigs: TFilters | null;
}
export class RouterLoaderObserver<T404 extends Object = any, TFilters extends Object = any> {
  static REQUEST_KEYS = {
    PRE_CHECK_404: 'preCheck404',
    FETCH_FILTER_CONFIG: 'filterConfigs'
  };
  static getUserProfile = () => {
    const user = localStorage.getItem(LOCALSTORAGE.USER);
    if (user && isJsonString(user)) {
      return JSON.parse(user);
    }
    return {};
  };

  private _requests: Map<string, () => Promise<any>> = new Map();
  private _route: IRoute;
  private _auth: AuthContextProps;

  constructor(routeItem: IRoute, auth: AuthContextProps) {
    this._route = routeItem;
    this._auth = auth;
  }

  private _getAccessToken(): string | null {
    return localStorage.getItem(LOCALSTORAGE.ACCESS_TOKEN);
  }

  /**
   * @description must be check auth before fetch because access token can be expired
   * @returns boolean: return true if the route is ignored to handle loader
   */
  private _isIgnore(): boolean {
    const accessToken = this._getAccessToken();
    const { isAuthenticated, isLoading } = this._auth ?? {};
    const ignore = !isAuthenticated || isLoading || !accessToken;
    return ignore;
  }

  private _prepare({ params }: LoaderFunctionArgs<IRouterLoaderResponse<T404, TFilters>>): this {
    const { id, path } = this._route;
    const user = RouterLoaderObserver.getUserProfile();
    const isInternalRole = [INTERNAL_ADMIN, INTERNAL_GENERAL_USER].includes(user?.role);
    const mapRequest = new Map();

    // register check 404
    const preCheck404 = MAP_PATH_PRE_CHECK_404[path];
    if (preCheck404) {
      const request = () => {
        if (this._isIgnore()) return null;
        return preCheck404(params, isInternalRole)?.catch((error: any) => {
          if (error?.response?.status !== HTTP_STATUS_CODE.NOT_FOUND) return error;
          window.location.href = NOT_EXITS_URL;
        });
      };
      mapRequest.set(RouterLoaderObserver.REQUEST_KEYS.PRE_CHECK_404, request);
    }

    // register needKeepFilter
    const needKeepFilter = id && Object.values(ROUTER_IDS).includes(id);
    if (needKeepFilter) {
      mapRequest.set(RouterLoaderObserver.REQUEST_KEYS.FETCH_FILTER_CONFIG, () => {
        return this._isIgnore() ? null : fetchFilterConfigs(id);
      });
    }

    this._requests = mapRequest;
    return this;
  }

  /**
   * @description must return a promise of [preCheck404, fetchFilterConfigs].
   * If u wanna more, u need to modify the method and define more keys in REQUEST_KEYS
   * @returns IRouterLoaderResponse<T404, TFilters>
   */
  private async _generateRequests(): Promise<IRouterLoaderResponse<T404, TFilters>> {
    const preCheck404 = this._requests.get(RouterLoaderObserver.REQUEST_KEYS.PRE_CHECK_404);
    const fetchFilterConfigs = this._requests.get(RouterLoaderObserver.REQUEST_KEYS.FETCH_FILTER_CONFIG);
    if (!preCheck404 && !fetchFilterConfigs)
      return {
        preCheck404: null,
        filterConfigs: null
      };
    const [res1, res2] = await Promise.all([preCheck404?.(), fetchFilterConfigs?.()]);
    return { preCheck404: res1, filterConfigs: res2 };
  }

  watching: LoaderFunction<IRouterLoaderResponse<T404, TFilters>> = async (args) => {
    return this._prepare(args)._generateRequests();
  };

  /**
   * @description: check if the route need handle loader before render page
   * @returns boolean: return true if method watching is calling
   */
  isValid(): boolean {
    const { id, preCheck404 } = this._route ?? {};
    const needFetchFilter = id && Object.values(ROUTER_IDS).includes(id);
    return Boolean(preCheck404 || needFetchFilter);
  }
}
