import { EXTERNAL_ADMIN, EXTERNAL_GENERAL_USER, INTERNAL_ADMIN, INTERNAL_GENERAL_USER } from '@/utils/constants/AppConstants';

import { C, E, V } from './constants';

export const EXPORT_CSV_PERMISSION = 'EXPORT_CSV_PERMISSION';
export const TRANSLATE_PERMISSION = 'TRANSLATE_PERMISSION';
export const UNLOCK_SUBMIT_PERMISSION = 'UNLOCK_SUBMIT_PERMISSION';
export const DISPLAY_SETTING_PERMISSION = 'DISPLAY_SETTING_PERMISSION';

export const QUESTIONNAIRE_AUTHORIZATION_MAPPING = {
  [EXPORT_CSV_PERMISSION]: {
    [INTERNAL_ADMIN]: [C, V],
    [INTERNAL_GENERAL_USER]: [C, V],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [TRANSLATE_PERMISSION]: {
    [INTERNAL_ADMIN]: [E, V, C],
    [INTERNAL_GENERAL_USER]: [E, V, C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [UNLOCK_SUBMIT_PERMISSION]: {
    [INTERNAL_ADMIN]: [C, V],
    [INTERNAL_GENERAL_USER]: [C, V],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [DISPLAY_SETTING_PERMISSION]: {
    [INTERNAL_ADMIN]: [C, V],
    [INTERNAL_GENERAL_USER]: [C, V],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  }
};
