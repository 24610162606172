import DOMPurify from 'dompurify';
import Quill from 'quill';
import { Delta, EmitterSource } from 'quill/core';

import emptyDataSrc from '@assets/icons/table/base-table-empty.svg?react';

import { CUSTOM_MODULES } from '.';
import { MENTION_BLOT_TYPE } from './MentionBlot';

const Empty = `<div class='ant-empty ant-empty-normal mention-empty-container'>
  <div class='ant-empty-image'>
    <img src='${emptyDataSrc}' alt='No Data' />
  </div>
  <p class='ant-empty-description'>No Data</p>
</div>`;
const Loader = `<div class='mention-loader'></div>`;
export const DEFAULT_SUGGEST_KEY = '@';
const NO_BREAK_SPACE = '\u00A0'; // &nbsp;
const ZERO_WIDTH_SPACE = '\u200B'; // zero width space
export interface ISuggestItem {
  label: string;
  value: string;
  [key: string]: any;
}
interface MentionModuleOptions {
  getSuggestions: (query: string) => Promise<ISuggestItem[]>;
  mentioned?: (op: ISuggestItem) => void;
  suggestKey: string;
}
export default class Mention {
  isOpen: boolean;
  options: MentionModuleOptions = { suggestKey: DEFAULT_SUGGEST_KEY, getSuggestions: async () => [] };
  quill: Quill;
  popover?: HTMLElement;
  private _selectedSuggestion: number = -1;

  constructor(quill: Quill, options: MentionModuleOptions) {
    this.quill = quill;
    if (options) this.options = { ...this.options, ...options };
    this.isOpen = false;

    if (!this.isValidOption(options)) return;

    if (!this.quill) return;

    this.onTextChange = this.onTextChange.bind(this);

    this.quill.on(Quill.events.TEXT_CHANGE, this.onTextChange);
    this.setupMentionDropdownNavigation();
  }

  isValidOption(options: MentionModuleOptions) {
    const valid = options?.getSuggestions;
    if (!valid) console.warn('%c Mention module required configured with getSuggestions function', 'font-size:14px;color:red');
    return valid;
  }

  onTextChange(delta: Delta, oldContent: Delta, source: EmitterSource) {
    if (source !== 'user') return;

    const newCharInsert = delta.ops.find((op) => op.insert)?.insert;
    const isNewLine = newCharInsert === '\n';
    if (isNewLine) {
      this.closeSuggestionPopover();
      return;
    }

    const isDeletingMention = this.onDeleteMention(delta);
    if (isDeletingMention) return;
    const mentionData = this.getTextAfterMention(source);

    const pattern = `[ |${NO_BREAK_SPACE}]?${this.options.suggestKey}`; // match space or empty string before mention key. Exp: ' @' or '@'
    const showDropdown = typeof newCharInsert === 'string' && new RegExp(pattern).test(newCharInsert);

    if (showDropdown) this.isOpen = true;
    if (mentionData) {
      const { text, index } = mentionData;
      this.openSuggestionPopover(text, index);
    }
  }

  getTextAfterMention(source: EmitterSource): { text: string; index: number } | null {
    const result = { text: '', index: -1 };
    const { suggestKey } = this.options;
    const cursorPosition = this.quill?.getSelection()?.index;
    const text = this.quill?.getText()?.slice(0, -1);

    // handle case for first character
    if (text === suggestKey && source === 'user') {
      return { text: '', index: 0 };
    }

    if (typeof cursorPosition !== 'number') return null;
    const delta = this.quill.getContents(0, cursorPosition);
    const matchedIndex = delta.ops.findLastIndex((op) => {
      const isMentioned = op?.attributes && Boolean(CUSTOM_MODULES.MENTION_TAG in op.attributes);
      const matched = typeof op?.insert === 'string' && op.insert.includes(suggestKey) && !isMentioned;
      return matched;
    });
    const hasMention = delta.ops[matchedIndex];
    if (typeof hasMention?.insert !== 'string') {
      this.closeSuggestionPopover();
      return null;
    }

    // calculate mention query
    const index = hasMention.insert.lastIndexOf(suggestKey);
    const textBeforeCursor = hasMention.insert?.slice(index + 1)?.replace(new RegExp(NO_BREAK_SPACE), ' ');
    const textBeforeSuggestKey = hasMention.insert.slice(index - 1, index);

    const validCharsBeforeSuggest = [' ', '', '\n', ZERO_WIDTH_SPACE, NO_BREAK_SPACE]; // to prevent mention when typing email
    if (validCharsBeforeSuggest.includes(textBeforeSuggestKey)) {
      result.text = textBeforeCursor;
    } else {
      return null;
    }

    // calculate index of mention query
    result.index = delta.ops.slice(0, matchedIndex + 1).reduce((acc, op, i) => {
      if (matchedIndex === i) return acc + index;
      if (typeof op.insert === 'string') return acc + op.insert.length;
      if (op.insert?.[CUSTOM_MODULES.FILE_BLOT]) return acc + 1;
      return acc;
    }, 0);
    return result;
  }

  onDeleteMention(delta: Delta) {
    const ops = delta.ops;
    const index = ops[0]?.retain;
    if (typeof index !== 'number') return;
    const deletedLength = ops[1]?.delete;
    if (typeof deletedLength !== 'number') return;

    const contentBeforeDeletion = this.quill.getContents(0, index);
    const lastOp = contentBeforeDeletion.ops[contentBeforeDeletion.ops.length - 1];

    const isDeletingMention = lastOp.insert && lastOp.attributes && lastOp.attributes?.[CUSTOM_MODULES.MENTION_TAG];
    if (isDeletingMention) {
      const mentionLength = typeof lastOp.insert === 'string' ? lastOp.insert.length : 1;

      const deleteMentionDelta = new Delta().retain(index - mentionLength).delete(mentionLength + deletedLength);

      this.quill.updateContents(deleteMentionDelta, 'user');
    }
    return isDeletingMention;
  }

  getEditorContainer(): HTMLElement {
    return this.quill.container;
  }

  handleOutsideClick(event: MouseEvent) {
    if (this.popover && !this.popover.contains(event.target as Node)) {
      this.closeSuggestionPopover();
    }
  }

  async openSuggestionPopover(query: string, start: number) {
    if (!this.options?.getSuggestions || !this.isOpen) return;
    const { getSuggestions } = this.options;

    if (!this.popover) {
      this.popover = document.createElement('div');
      this.popover.className = 'mention-dropdown';
      this.getEditorContainer()?.appendChild(this.popover);
      // Improved focus out handling
      this.popover.addEventListener('focusout', (event) => {
        setTimeout(() => {
          // Delay to allow focus to move
          if (!this.popover?.contains(document.activeElement)) {
            this.closeSuggestionPopover();
          }
        }, 0);
      });

      // Handle clicks outside the popover
      document.addEventListener('click', this.handleOutsideClick.bind(this), true);
    }

    const selection = this.quill?.getSelection()?.index;
    if (!selection && selection !== 0) return;
    const cursorPosition = this.quill?.getBounds(selection);
    if (!cursorPosition) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { contentRect: cr } = entry;

        // Check if contentRect and popover exist
        if (cr && this.popover) {
          // Calculate popover's position based on cursor and quill's dimensions
          const popoverBottom = `${this.quill.root.offsetHeight - cursorPosition.top + 10}px`;
          const popoverRight =
            cr.width + cursorPosition.right > this.quill.root.offsetWidth
              ? `${this.quill.root.offsetWidth - cursorPosition.right}px`
              : `${this.quill.root.offsetWidth - cursorPosition.right - cr.width}px`;

          // Update popover's position
          this.popover.style.bottom = popoverBottom;
          this.popover.style.right = popoverRight;
        }
      }
    });
    resizeObserver.observe(this.popover);

    this.popover.innerHTML = DOMPurify.sanitize(`<div class='flex justify-center'>${Loader}</div>`);
    const suggestions = await getSuggestions(query);

    if (!suggestions.length) {
      this.popover.innerHTML = DOMPurify.sanitize(`<div>${Empty}</div>`);
      return;
    }
    this._selectedSuggestion = 0; // init selected when searching

    const content = suggestions
      .map((item, i) => {
        const userId = item.value;
        const userName = `${item.name} (${item.email})`;
        const name = item.name ?? '';
        const email = item.email ?? '';
        return `<div class='mention-item cursor-pointer ${i === 0 ? 'highlight' : ''}' title='${userName}' data-user-id='${userId}' data-user-name='${userName}'>
          <p data-user-id='${userId}' class='truncate font-medium'>${name}</p>
          <p data-user-id='${userId}' class='truncate text-gray4 text-12'>${email}</p>
        </div>`;
      })
      .join('');
    this.popover.innerHTML = DOMPurify.sanitize(content);

    this.popover.querySelectorAll('.mention-item').forEach((item) => {
      item.addEventListener('click', (e) => {
        const target = e.target as HTMLElement;
        const { userId } = target?.dataset ?? {};
        if (!userId) return;
        const user = suggestions.find((s) => s.value === userId);
        if (!user) return;
        this.insertMention(user, start, query.length);
      });
    });
  }

  insertMention(user: ISuggestItem, start: number, range: number) {
    const { suggestKey, mentioned } = this.options;
    const { label: name, value: id } = user;
    this.closeSuggestionPopover();
    this.quill.deleteText(start, range + 1);
    const deltaAtPosition = new Delta()
      .retain(start)
      .insert(`${suggestKey}${name}`, { [CUSTOM_MODULES.MENTION_TAG]: { id, type: MENTION_BLOT_TYPE } })
      .insert(NO_BREAK_SPACE)
      .insert(ZERO_WIDTH_SPACE);
    this.quill.updateContents(deltaAtPosition);
    this.quill.setSelection(start + name.length + 2);
    mentioned?.(user);
  }

  setupMentionDropdownNavigation() {
    this.getEditorContainer()?.addEventListener('keydown', this.handleKeyDown.bind(this), true);
  }

  handleKeyDown(event: KeyboardEvent) {
    if (!this.popover) return;
    const items = this.popover.querySelectorAll('.mention-item');
    if (items.length === 0) return;
    let next = this._selectedSuggestion;
    switch (event.key) {
      case 'ArrowDown':
        next = next + 1;
        this._selectedSuggestion = next > items.length - 1 ? 0 : next;
        event.preventDefault();
        break;
      case 'ArrowUp':
        next = next - 1;
        this._selectedSuggestion = next < 0 ? items.length - 1 : next;
        event.preventDefault();
        break;
      case 'Enter':
        const currentSelectedItem = items[this._selectedSuggestion] as HTMLElement;
        if (!currentSelectedItem) {
          this.closeSuggestionPopover();
          break;
        }
        currentSelectedItem.click();
        event.preventDefault();
        event.stopPropagation();
        break;
      case 'Escape':
        event.preventDefault();
        event.stopPropagation();
        this.closeSuggestionPopover();
        break;
      case 'ArrowRight':
      case 'ArrowLeft':
        this.closeSuggestionPopover();
        event.preventDefault();
        break;
      default:
        break;
    }
    this.highlightSelectedItem(items);
  }

  highlightSelectedItem(items: NodeListOf<Element>) {
    items.forEach((item) => item.classList.remove('highlight'));
    const selectedItem = items[this._selectedSuggestion];
    if (selectedItem) {
      selectedItem.classList.add('highlight');
      selectedItem.scrollIntoView({ block: 'nearest', inline: 'start' });
    }
  }

  closeSuggestionPopover() {
    this.isOpen = false;
    if (this.popover) {
      this.popover.remove();
      this.popover = undefined;
      document.removeEventListener('click', this.handleOutsideClick.bind(this), true);
    }
    this._selectedSuggestion = -1;
    this.getEditorContainer().removeEventListener('keydown', this.handleKeyDown.bind(this));
  }
}
