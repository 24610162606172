import React, { ChangeEvent, useState } from 'react';
import InputMask from 'react-input-mask';

import BaseInput, { IBaseInputProps } from '../base-input';

export interface IMaskInputProps extends Omit<IBaseInputProps, 'onChange'> {
  hideBorder?: boolean;
  labelInput?: string;
  labelClassName?: string;
  /**
   * Mask string. Format characters are:
   * * `9`: `0-9`
   * * `a`: `A-Z, a-z`
   * * `\*`: `A-Z, a-z, 0-9`
   *
   * Any character can be escaped with backslash, which usually will appear as double backslash in JS strings.
   * For example, German phone mask with unremoveable prefix +49 will look like `mask="+4\\9 99 999 99"` or `mask={"+4\\\\9 99 999 99"}`
   */
  mask: string;
  /**
   * Character to cover unfilled editable parts of mask. Default character is "_". If set to null, unfilled parts will be empty, like in ordinary input.
   */
  maskChar?: string | null | undefined;
  maskPlaceholder?: string | null | undefined;
  /**
   * Show mask even in empty input without focus.
   */
  alwaysShowMask?: boolean | undefined;
  /**
   * Use inputRef instead of ref if you need input node to manage focus, selection, etc.
   */
  inputRef?: React.Ref<HTMLInputElement> | undefined;
  onChange?: (value: any) => void;
}

const MaskedInput: React.FC<IMaskInputProps> = ({ maskChar = '_', mask, onChange, size, prefix, ...restProps }) => {
  const [value, setValue] = useState('');

  const maskMapping = {
    '9': /0-9/,
    a: /a-zA-z/,
    '*': /./
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;
    setValue(rawValue);
    const textValue = mask.split('').reduce((txt, i, index) => {
      if (i in maskMapping) {
        txt += rawValue[index] ?? '';
      }
      return txt;
    }, '');
    onChange && onChange(textValue);
  };

  return (
    <InputMask maskChar={maskChar} mask={mask} value={value} onChange={handleChange} {...restProps}>
      {
        ((inputProps: any) => {
          return <BaseInput {...inputProps} size={size} prefix={prefix} />;
        }) as any
      }
    </InputMask>
  );
};

export default MaskedInput;
