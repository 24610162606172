import { t } from 'i18next';
import React, { useMemo } from 'react';

import { _guuid } from '../../utils/helpers/globalHelper';
import { IFilterForm } from '../../utils/interfaces/form';
import CloseIcon from './../../assets/icons/CloseIcon.svg';
import { IBaseFilterLocaleConfig, _getAvailableFormValues } from './BaseFilter';

interface IProps {
  isShowSearch: boolean;
  showButtonResetFilter: boolean;
  disabledFields: string[];
  filteredItems?: IFilterForm;
  locale?: IBaseFilterLocaleConfig;
  _handleRemoveArrayItem: (key: string, index: number) => void;
  _handleRemoveItem: (type: string) => void;
  _handleClearAll: (type: string) => void;
}

const FilteredItems = ({
  filteredItems,
  isShowSearch,
  disabledFields,
  showButtonResetFilter,
  locale,
  _handleRemoveArrayItem,
  _handleRemoveItem,
  _handleClearAll
}: IProps) => {
  const availableFilteredItems = useMemo(() => {
    return _getAvailableFormValues({ ...filteredItems });
  }, [filteredItems]);

  const hasFiltered = useMemo(() => {
    if (!availableFilteredItems) return false;
    const filteredItemsArr = Object.values(availableFilteredItems).filter((i: any) => {
      if (!i.value) return false;
      if (Array.isArray(i.value) && i.value.length === 0) return false;
      return true;
    });
    return filteredItemsArr.length > 0;
  }, [availableFilteredItems]);

  return (
    <>
      {!!availableFilteredItems && !!hasFiltered && (
        <>
          <div className='col-span-2'>
            <div className={`fiter-items ${!isShowSearch && '!pt-0'}`}>
              {Object.keys(availableFilteredItems).map((key) => {
                return (
                  availableFilteredItems[key] && (
                    <React.Fragment key={`fragment-item-${_guuid()}`}>
                      {Array.isArray(availableFilteredItems[key]?.label) &&
                        (availableFilteredItems[key]?.label as string[])?.map((f: any, i: number) => {
                          return (
                            <div key={`items-${f}-${_guuid()}`}>
                              <span className={`item ${disabledFields.includes(key) ? 'bg-[#dbdbe0] cursor-not-allowed' : ''}`}>
                                {f}
                                <CloseIcon
                                  width={18}
                                  height={18}
                                  className={disabledFields.includes(key) ? '!cursor-not-allowed' : ''}
                                  onClick={() => (!disabledFields.includes(key) ? _handleRemoveArrayItem(key, i) : undefined)}
                                />
                              </span>
                            </div>
                          );
                        })}
                      {!Array.isArray(availableFilteredItems[key]?.label) && (
                        <div key={`item-${availableFilteredItems[key]?.label}-${_guuid()}`}>
                          <span className='item'>
                            {availableFilteredItems[key]?.label || ''}
                            <CloseIcon
                              width={18}
                              height={18}
                              className={disabledFields.includes(key) ? '!cursor-not-allowed' : ''}
                              onClick={() => (!disabledFields.includes(key) ? _handleRemoveItem(key) : undefined)}
                            />
                          </span>
                        </div>
                      )}
                    </React.Fragment>
                  )
                );
              })}
              {showButtonResetFilter && (
                <span
                  className='clear-btn'
                  onClick={() => {
                    _handleClearAll('apply');
                  }}
                >
                  {locale?.clear_all_filter_items || t('project:filter:clear_filter')}
                </span>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default FilteredItems;
