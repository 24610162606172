import { yupResolver } from '@hookform/resolvers/yup';
import { Space } from 'antd';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { BaseButton } from '@/components/base-button/BaseButton';
import { ConfirmCancelEditing } from '@/components/confirm-popup';

import useMutation from '@/hooks/useMutation';

import { ERROR_CODE } from '@/utils/constants/AppConstants';
import { HTTP_STATUS_CODE } from '@/utils/constants/Http';
import { ACCOUNT_MASTER_LIST_URL } from '@/utils/constants/RouteContants';

import BaseFormAccountMater from './BaseFormAccountMater';
import { FormCreateAccountMaster } from './FormValidation';
import { IFormMethodRegisterAccountMaster } from './models';

import './Add.scss';

const Add = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const formMethod = useForm<IFormMethodRegisterAccountMaster>({
    mode: 'all',
    resolver: yupResolver(FormCreateAccountMaster)
  });
  const { isDirty, isSubmitSuccessful, isSubmitting } = formMethod.formState;
  const { error: registerError, mutate: postRegisterAccountMaster } = useMutation(`/usr/users`, {
    method: 'POST',
    bodyType: 'json',
    showToastError: false,
    showToastSuccess: true,
    defaultSuccessMsg: t('common:MSG_C_003', { item: t('button:keep') }) ?? ''
  });

  const handleRegister: SubmitHandler<any> = async (val) => {
    setLoading(true);
    const result = await postRegisterAccountMaster(getPayloadRequest(val));
    if (result?.status === HTTP_STATUS_CODE.SUCCESS) {
      setTimeout(() => {
        setLoading(false);
        navigate(ACCOUNT_MASTER_LIST_URL);
      }, 500);
      return;
    }
    setLoading(false);
  };

  useEffect(() => {
    const err = registerError ? (registerError as AxiosError) : undefined;
    if (err && err?.response) {
      const {
        data: { fields }
      } = err.response as any;
      if (fields[0].errorCode && fields[0].name === 'Email') {
        const errorMapping = {
          [ERROR_CODE.USER_RESTRICT]: String(t('common:MSG_A_011'))
        };
        const message = errorMapping[fields[0].errorCode] ?? String(t('common:MSG_C_014'));
        formMethod.setError('email', { type: 'accountInvalid', message });
      }
    }
  }, [registerError]);

  const getPayloadRequest = (formData: any) => {
    return {
      upn: formData?.email,
      email: formData?.email,
      name: formData?.name,
      organizationId: formData?.organizationId,
      roleId: formData?.roleId
    };
  };

  return (
    <div id='account-master-management'>
      <div className='account-master-container h-full'>
        <FormProvider {...formMethod}>
          <div className='header flex justify-between'>
            <div className='title-24 whitespace-pre-line font-bold text-24 leading-7'>{t('account_master:add:title')}</div>
            <Space size={16}>
              <div className='min-w-[120px]'>
                <BaseButton block size='medium' type='tertiary' onClick={() => navigate(ACCOUNT_MASTER_LIST_URL)}>
                  {t('button:cancel')}
                </BaseButton>
              </div>
              <div className='min-w-[120px]'>
                <BaseButton block size='medium' disabled={isSubmitting || loading} onClick={formMethod.handleSubmit(handleRegister)}>
                  <span> {t('button:keep')} </span>
                </BaseButton>
              </div>
            </Space>
          </div>
          <div className='account-master-form account-master-tabs-content px-5 py-4 rounded-[10px] border-[#DBDBE0] gap-3'>
            <BaseFormAccountMater formMethod={formMethod} />
          </div>
        </FormProvider>
      </div>
      <ConfirmCancelEditing condition={isDirty && !isSubmitSuccessful} />
    </div>
  );
};

export default Add;
