// Features name constants
import { useMemo } from 'react';

import * as AccountMaster from '@/utils/constants/AccountMaster';
import * as AuthorizationForAnnouncement from '@/utils/constants/announcement';
import * as Stakeholder from '@utils/constants/stakeholder';

import { useAppSelector } from '.';
import { EXTERNAL_ADMIN, EXTERNAL_GENERAL_USER, INTERNAL_ADMIN, INTERNAL_GENERAL_USER } from '../utils/constants/AppConstants';
import * as AuthorizationForProject from '../utils/constants/AuthorizationProjectManagement';
import * as NecessaryDocumentsAuthoration from '../utils/constants/RequiredDocumentsAuthorazation';
import * as MasterDataCompany from '../utils/constants/master-data/company/company';
// Actions constants
import { QUESTIONNAIRE_AUTHORIZATION_MAPPING } from './auth/questionnairePermission';

export const PHRASE_LIST = 'PHRASE_LIST';
export const PHRASE_LIST_BUTTON_CREATE = 'PHRASE_LIST_BUTTON_CREATE';
export const PHRASE_LIST_BUTTON_EDIT = 'PHRASE_LIST_BUTTON_EDIT';
export const DELETE_PHRASE_LIST = 'DELETE_PHRASE_LIST';
export const DELETE_PROJECT_LIST = 'DELETE_PROJECT_LIST';
export const TEMPLATE_LIST = 'TEMPLATE_LIST';
export const EDIT_TEMPLATE_DELETE = 'EDIT_TEMPLATE_DELETE';
export const CREATE_TEMPLATE_BREADCRUMB_CREATE_NEW = 'CREATE_TEMPLATE_BREADCRUMB_CREATE_NEW';
export const CREATE_TEMPLATE_UNTITLED_TEMPLATE = 'CREATE_TEMPLATE_UNTITLED_TEMPLATE';
export const CREATE_TEMPLATE_SAVE = 'CREATE_TEMPLATE_SAVE';
export const CREATE_TEMPLATE_CANCEL = 'CREATE_TEMPLATE_CANCEL';
export const CREATE_TEMPLATE_BASIC_INFOMATION = 'CREATE_TEMPLATE_BASIC_INFOMATION';
export const CREATE_TEMPLATE_COUNTRY_NAME = 'CREATE_TEMPLATE_COUNTRY_NAME';
export const CREATE_TEMPLATE_VISA_CATEGORY = 'CREATE_TEMPLATE_VISA_CATEGORY';
export const CREATE_TEMPLATE_FORM = 'CREATE_TEMPLATE_FORM';
export const CREATE_TEMPLATE_TEMPLATE_NAME = 'CREATE_TEMPLATE_TEMPLATE_NAME';
export const CREATE_TEMPLATE_FILTER = 'CREATE_TEMPLATE_FILTER';
export const CREATE_TEMPLATE_REFERENCE_TEMPLATE = 'CREATE_TEMPLATE_REFERENCE_TEMPLATE';
export const CREATE_TEMPLATE_DETAIL_INFORMATION = 'CREATE_TEMPLATE_DETAIL_INFORMATION';
export const CREATE_TEMPLATE_FILTER_COUNTRY_NAME = 'CREATE_TEMPLATE_FILTER_COUNTRY_NAME';
export const CREATE_TEMPLATE_FILTER_VISA_CATEGORY = 'CREATE_TEMPLATE_FILTER_VISA_CATEGORY';
export const CREATE_TEMPLATE_FILTER_FORM = 'CREATE_TEMPLATE_FILTER_FORM';
export const CREATE_TEMPLATE_FILTER_RESET = 'CREATE_TEMPLATE_FILTER_RESET';
export const CREATE_TEMPLATE_FILTER_APPLY = 'CREATE_TEMPLATE_FILTER_APPLY';
export const CREATE_TEMPLATE_SCHEDULE_IMAGE = 'CREATE_TEMPLATE_SCHEDULE_IMAGE';
export const CREATE_TEMPLATE_SCHEDULE_CREATE_LABEL = 'CREATE_TEMPLATE_SCHEDULE_CREATE_LABEL';
export const CREATE_TEMPLATE_SCHEDULE_ADD_STEP = 'CREATE_TEMPLATE_SCHEDULE_ADD_STEP';
export const CREATE_TEMPLATE_SCHEDULE = 'CREATE_TEMPLATE_SCHEDULE';
export const CREATE_TEMPLATE_SCHEDULE_STEP_NAME = 'CREATE_TEMPLATE_SCHEDULE_STEP_NAME';
export const CREATE_TEMPLATE_SCHEDULE_STEP_STATUS = 'CREATE_TEMPLATE_SCHEDULE_STEP_STATUS';
export const CREATE_TEMPLATE_SCHEDULE_SCHEDULED_DATE = 'CREATE_TEMPLATE_SCHEDULE_SCHEDULED_DATE';
export const CREATE_TEMPLATE_SCHEDULE_ACTUAL_DATE = 'CREATE_TEMPLATE_SCHEDULE_ACTUAL_DATE';
export const CREATE_TEMPLATE_SCHEDULE_DELETE = 'CREATE_TEMPLATE_SCHEDULE_DELETE';
export const CREATE_TEMPLATE_SCHEDULE_DRAG_DROP = 'CREATE_TEMPLATE_SCHEDULE_DRAG_DROP';
export const CREATE_TEMPLATE_SCHEDULE_LINE_ADD = 'CREATE_TEMPLATE_SCHEDULE_LINE_ADD';
export const CREATE_TEMPLATE_SCHEDULE_STEP_CIRCLE = 'CREATE_TEMPLATE_SCHEDULE_STEP_CIRCLE';
export const TEMPLATE_LIST_BUTTON_CREATE = 'TEMPLATE_LIST_BUTTON_CREATE';
export const TEMPLATE_LIST_DROPDOWN_VIEW = 'TEMPLATE_LIST_DROPDOWN_VIEW';
export const TEMPLATE_LIST_DROPDOWN_SELECT = 'TEMPLATE_LIST_DROPDOWN_SELECT';
export const TEMPLATE_LIST_FILTER_INPUT_TEMPLATE_NAME = 'TEMPLATE_LIST_FILTER_INPUT_TEMPLATE_NAME';
export const TEMPLATE_LIST_DROPDOWN_SELECT_COUNTRY_NAME = 'TEMPLATE_LIST_DROPDOWN_SELECT_COUNTRY_NAME';
export const TEMPLATE_LIST_DROPDOWN_SELECT_FORM = 'TEMPLATE_LIST_DROPDOWN_SELECT_FORM';
export const TEMPLATE_LIST_DROPDOWN_SELECT_CATEGORY = 'TEMPLATE_LIST_DROPDOWN_SELECT_CATEGORY';
export const TEMPLATE_LIST_INPUT_TEMPLATE_ID = 'TEMPLATE_LIST_INPUT_TEMPLATE_ID';
export const TEMPLATE_LIST_CREATE_DATE = 'TEMPLATE_LIST_CREATE_DATE';
export const TEMPLATE_LIST_BUTTON_RESET = 'TEMPLATE_LIST_BUTTON_RESET';
export const TEMPLATE_LIST_BUTTON_APPLY = 'TEMPLATE_LIST_BUTTON_APPLY';
export const TEMPLATE_LIST_INPUT_SERACH = 'TEMPLATE_LIST_INPUT_SERACH';
export const TEMPLATE_LIST_EXPAND = 'TEMPLATE_LIST_EXPAND';
export const VIEW_TEMPLATE_EDIT = 'VIEW_TEMPLATE_EDIT';
export const VIEW_TEMPLATE_COUNTRY_NAME = 'VIEW_TEMPLATE_COUNTRY_NAME';
export const VIEW_TEMPLATE_VISA_CATEGORY = 'VIEW_TEMPLATE_VISA_CATEGORY';
export const VIEW_TEMPLATE_FORM = 'VIEW_TEMPLATE_FORM';
export const VIEW_TEMPLATE_BASIC_INFORMATION = 'VIEW_TEMPLATE_BASIC_INFORMATION';
export const VIEW_TEMPLATE_BREADCRUMB = 'VIEW_TEMPLATE_BREADCRUMB';
export const VIEW_TEMPLATE_COLLAPSE = 'VIEW_TEMPLATE_COLLAPSE';
export const VIEW_TEMPLATE_QUESTIONS = 'VIEW_TEMPLATE_QUESTIONS';
export const DELETE_TEMPLATE_LIST = 'DELETE_TEMPLATE_LIST';
export const CREATE_TEMPLATE_ROUTER = 'CREATE_TEMPLATE_ROUTER';
export const VIEW_TEMPLATE_ROUTER = 'VIEW_TEMPLATE_ROUTER';
export const EDIT_TEMPLATE_ROUTER = 'EDIT_TEMPLATE_ROUTER';
export const VIEW_TEMPLATE_EDIT_BUTTON = 'VIEW_TEMPLATE_EDIT_BUTTON';
export const ACCOUNT_LIST = 'ACCOUNT_LIST';
export const ACCOUNT_LIST_BUTTON_CREATE = 'ACCOUNT_LIST_BUTTON_CREATE';
export const FILTER_ACCOUNT_COMPANY = 'FILTER_ACCOUNT_COMPANY';
export const CREATE_ACCOUNT_ROUTER = 'CREATE_ACCOUNT_ROUTER';
export const EDIT_ACCOUNT_ROUTER = 'EDIT_ACCOUNT_ROUTER';
export const DELETE_ACCOUNT_LIST = 'DELETE_ACCOUNT_LIST';
export const CREATE_ACCOUNT_SAVE = 'CREATE_ACCOUNT_SAVE';
export const CREATE_ACCOUNT_CANCEL = 'CREATE_ACCOUNT_CANCEL';
export const EDIT_ACCOUNT_DELETE = 'EDIT_ACCOUNT_DELETE';
export const VIEW_ACCOUNT_COMPANY = 'VIEW_ACCOUNT_COMPANY';
export const CREATE_PROJECT_SCHEDULES_TEMPLATE_NAME = 'CREATE_PROJECT_SCHEDULES_TEMPLATE_NAME';
export const CREATE_PROJECT_SCHEDULES_EXPORT_PDF = 'CREATE_PROJECT_SCHEDULES_EXPORT_PDF';
export const CREATE_PROJECT_SCHEDULES_CANCEL = 'CREATE_PROJECT_SCHEDULES_CANCEL';
export const CREATE_PROJECT_SCHEDULES_SAVE = 'CREATE_PROJECT_SCHEDULES_SAVE';
export const CREATE_PROJECT_SCHEDULES_FILTER = 'CREATE_PROJECT_SCHEDULES_FILTER';
export const CREATE_PROJECT_SCHEDULES_DRAG = 'CREATE_PROJECT_SCHEDULES_DRAG';
export const CREATE_PROJECT_STEP_CIRCLE = 'CREATE_PROJECT_STEP_CIRCLE';
export const CREATE_PROJECT_STEP_NAME = 'CREATE_PROJECT_STEP_NAME';
export const CREATE_PROJECT_STEP_STATUS = 'CREATE_PROJECT_STEP_STATUS';
export const CREATE_PROJECT_STEP_SCHEDULE_DATE = 'CREATE_PROJECT_STEP_SCHEDULE_DATE';
export const CREATE_PROJECT_STEP_ACTUAL_DATE = 'CREATE_PROJECT_STEP_ACTUAL_DATE';
export const CREATE_PROJECT_STEP_DELETE = 'CREATE_PROJECT_STEP_DELETE';
export const CREATE_PROJECT_STEP_ADD = 'CREATE_PROJECT_STEP_ADD';
export const CREATE_PROJECT_STEP_LINE_ADD = 'CREATE_PROJECT_STEP_LINE_ADD';
export const CREATE_PROJECT_SCHEDULES_SAVE_DRAFT = 'CREATE_PROJECT_SCHEDULES_SAVE_DRAFT';
export const ANNOUNCEMENT_LIST = 'ANNOUNCEMENT_LIST';
export const PIN_ANNOUNCEMENT = 'PIN_ANNOUNCEMENT';
export const ANNOUNCEMENT_LIST_BUTTON_CREATE = 'ANNOUNCEMENT_LIST_BUTTON_CREATE';
export const CREATE_ANNOUNCEMENT_ROUTER = 'CREATE_ANNOUNCEMENT_ROUTER';
export const EDIT_ANNOUNCEMENT_ROUTER = 'EDIT_ANNOUNCEMENT_ROUTER';
export const VIEW_NOTIFICATION_ROUTER = 'VIEW_NOTIFICATION_ROUTER';
export const VIEW_ANNOUNCEMENT_ROUTER = 'VIEW_ANNOUNCEMENT_ROUTER';
export const DELETE_ANNOUNCEMENT_LIST = 'DELETE_ANNOUNCEMENT_LIST';
export const CREATE_ANNOUNCEMENT_SAVE = 'CREATE_ANNOUNCEMENT_SAVE';
export const CREATE_ANNOUNCEMENT_CANCEL = 'CREATE_ANNOUNCEMENT_CANCEL';
export const EDIT_ANNOUNCEMENT_DELETE = 'EDIT_ANNOUNCEMENT_DELETE';
export const VIEW_ANNOUNCEMENT_EDIT = 'VIEW_ANNOUNCEMENT_EDIT';
export const VIEW_ANNOUNCEMENT_COMPANY = 'VIEW_ANNOUNCEMENT_COMPANY';
export const NOTIFICATION_SHOW = 'NOTIFICATION_SHOW';
export const DOCUMENT_MASTER_ROUTER = 'DOCUMENT_MASTER_ROUTER';
export const DOCUMENT_LIST_BUTTON_CREATE = 'DOCUMENT_LIST_BUTTON_CREATE';
export const DELETE_DOCUMENT_LIST = 'DELETE_DOCUMENT_LIST';
export const VIEW_TASK_LIST = 'VIEW_TASK_LIST';
export const TASK_MASTER_ROUTER = 'TASK_MASTER_ROUTER';
export const TASK_LIST_BUTTON_CREATE = 'TASK_LIST_BUTTON_CREATE';
export const DELETE_TASK_LIST = 'DELETE_TASK_LIST';
export const MY_PROFILE = 'MY_PROFILE';
export const ALL_TASK_PROJECT = 'ALL_TASK_PROJECT';
export const MY_PROFILE_BACK = 'MY_PROFILE_BACK';
export const VIEW_PROJECT_FILE_LIST = 'VIEW_PROJECT_FILE_LIST';
export const VIEW_DOCUMENT_LIST = 'VIEW_DOCUMENT_LIST';

// Actions constants
export const E = 'EDITABLE';
export const C = 'CLICKABLE';
export const V = 'VIEWABLE';
export const D = 'DELETABLE';
export const S = 'SELECTABLE';

// Authorization constant
const FEATURE_AUTHORIZATION_MAPPING: any = {
  ...QUESTIONNAIRE_AUTHORIZATION_MAPPING,
  /*Project files*/
  [VIEW_PROJECT_FILE_LIST]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  // Phrases
  [PHRASE_LIST]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [PHRASE_LIST_BUTTON_CREATE]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [DELETE_PHRASE_LIST]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [PHRASE_LIST_BUTTON_EDIT]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },

  // Document master router
  [DOCUMENT_MASTER_ROUTER]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },

  [DOCUMENT_LIST_BUTTON_CREATE]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },

  [DELETE_DOCUMENT_LIST]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },

  // Document list
  [VIEW_DOCUMENT_LIST]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },

  // Task master router
  [TASK_MASTER_ROUTER]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },

  [TASK_LIST_BUTTON_CREATE]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },

  [DELETE_TASK_LIST]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },

  [VIEW_TASK_LIST]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },

  // Notification
  [NOTIFICATION_SHOW]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [V, C],
    [EXTERNAL_ADMIN]: [V, C],
    [EXTERNAL_GENERAL_USER]: [V, C]
  },

  // Project Schedule Steps Form
  [CREATE_PROJECT_SCHEDULES_DRAG]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [V, C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [CREATE_PROJECT_STEP_CIRCLE]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [CREATE_PROJECT_STEP_NAME]: {
    [INTERNAL_ADMIN]: [V, E],
    [INTERNAL_GENERAL_USER]: [V, E],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [CREATE_PROJECT_STEP_STATUS]: {
    [INTERNAL_ADMIN]: [V, S],
    [INTERNAL_GENERAL_USER]: [V, S],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [CREATE_PROJECT_STEP_SCHEDULE_DATE]: {
    [INTERNAL_ADMIN]: [V, S],
    [INTERNAL_GENERAL_USER]: [V, S],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [CREATE_PROJECT_STEP_ACTUAL_DATE]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [CREATE_PROJECT_STEP_DELETE]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [V, C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [CREATE_PROJECT_STEP_ADD]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [V, C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [CREATE_PROJECT_STEP_LINE_ADD]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [V, C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },

  // Project Schedule Add Edit
  [CREATE_PROJECT_SCHEDULES_TEMPLATE_NAME]: {
    [INTERNAL_ADMIN]: [V, S],
    [INTERNAL_GENERAL_USER]: [V, S],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [CREATE_PROJECT_SCHEDULES_EXPORT_PDF]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [V, C],
    [EXTERNAL_ADMIN]: [V, C],
    [EXTERNAL_GENERAL_USER]: [V, C]
  },
  [CREATE_PROJECT_SCHEDULES_CANCEL]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [V, C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [CREATE_PROJECT_SCHEDULES_SAVE_DRAFT]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [V, C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [CREATE_PROJECT_SCHEDULES_SAVE]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [V, C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [CREATE_PROJECT_SCHEDULES_FILTER]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [V, C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  // Project List
  [DELETE_PROJECT_LIST]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [V, C],
    [EXTERNAL_ADMIN]: [V, C],
    [EXTERNAL_GENERAL_USER]: [V, C]
  },
  // Templates router
  [CREATE_TEMPLATE_ROUTER]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [VIEW_TEMPLATE_ROUTER]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [EDIT_TEMPLATE_ROUTER]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },

  // Templates list
  [TEMPLATE_LIST]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [CREATE_TEMPLATE_SCHEDULE_LINE_ADD]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [CREATE_TEMPLATE_SCHEDULE_DRAG_DROP]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [CREATE_TEMPLATE_SCHEDULE_DELETE]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [CREATE_TEMPLATE_SCHEDULE_ACTUAL_DATE]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [CREATE_TEMPLATE_SCHEDULE_SCHEDULED_DATE]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [CREATE_TEMPLATE_SCHEDULE_STEP_STATUS]: {
    [INTERNAL_ADMIN]: [V, E],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [CREATE_TEMPLATE_SCHEDULE_STEP_NAME]: {
    [INTERNAL_ADMIN]: [V, E],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [CREATE_TEMPLATE_SCHEDULE_STEP_CIRCLE]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [CREATE_TEMPLATE_SCHEDULE_ADD_STEP]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [CREATE_TEMPLATE_SCHEDULE_CREATE_LABEL]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [CREATE_TEMPLATE_SCHEDULE_IMAGE]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [CREATE_TEMPLATE_SCHEDULE]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [CREATE_TEMPLATE_FILTER_RESET]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [CREATE_TEMPLATE_FILTER_APPLY]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [CREATE_TEMPLATE_FILTER_FORM]: {
    [INTERNAL_ADMIN]: [V, S],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [CREATE_TEMPLATE_FILTER_VISA_CATEGORY]: {
    [INTERNAL_ADMIN]: [V, S],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [CREATE_TEMPLATE_FILTER_COUNTRY_NAME]: {
    [INTERNAL_ADMIN]: [V, S],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [CREATE_TEMPLATE_FILTER]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [CREATE_TEMPLATE_TEMPLATE_NAME]: {
    [INTERNAL_ADMIN]: [V, E],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [CREATE_TEMPLATE_REFERENCE_TEMPLATE]: {
    [INTERNAL_ADMIN]: [V, S],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [CREATE_TEMPLATE_DETAIL_INFORMATION]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [CREATE_TEMPLATE_BREADCRUMB_CREATE_NEW]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [CREATE_TEMPLATE_UNTITLED_TEMPLATE]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [CREATE_TEMPLATE_SAVE]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [CREATE_TEMPLATE_CANCEL]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [CREATE_TEMPLATE_BASIC_INFOMATION]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [CREATE_TEMPLATE_COUNTRY_NAME]: {
    [INTERNAL_ADMIN]: [V, S],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [CREATE_TEMPLATE_VISA_CATEGORY]: {
    [INTERNAL_ADMIN]: [V, S],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [CREATE_TEMPLATE_FORM]: {
    [INTERNAL_ADMIN]: [V, S],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  // 国名
  [TEMPLATE_LIST_DROPDOWN_SELECT_COUNTRY_NAME]: {
    [INTERNAL_ADMIN]: [V, S],
    [INTERNAL_GENERAL_USER]: [V, S],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  // カテゴリ
  [TEMPLATE_LIST_DROPDOWN_SELECT_CATEGORY]: {
    [INTERNAL_ADMIN]: [V, S],
    [INTERNAL_GENERAL_USER]: [V, S],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  // 形態
  [TEMPLATE_LIST_DROPDOWN_SELECT_FORM]: {
    [INTERNAL_ADMIN]: [V, S],
    [INTERNAL_GENERAL_USER]: [V, S],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  // テンプレートID
  [TEMPLATE_LIST_INPUT_TEMPLATE_ID]: {
    [INTERNAL_ADMIN]: [V, E],
    [INTERNAL_GENERAL_USER]: [V, E],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  // create date
  [TEMPLATE_LIST_CREATE_DATE]: {
    [INTERNAL_ADMIN]: [V, E],
    [INTERNAL_GENERAL_USER]: [V, E],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  // reset button
  [TEMPLATE_LIST_BUTTON_RESET]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [V, C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  // apply button
  [TEMPLATE_LIST_BUTTON_APPLY]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [V, C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [TEMPLATE_LIST_EXPAND]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [V, C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [VIEW_TEMPLATE_EDIT]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [V, C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [VIEW_TEMPLATE_COUNTRY_NAME]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [VIEW_TEMPLATE_VISA_CATEGORY]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [VIEW_TEMPLATE_FORM]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [VIEW_TEMPLATE_BASIC_INFORMATION]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [VIEW_TEMPLATE_BREADCRUMB]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [V, C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [VIEW_TEMPLATE_COLLAPSE]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [V, C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [VIEW_TEMPLATE_QUESTIONS]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [V, C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [VIEW_TEMPLATE_EDIT_BUTTON]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },

  [DELETE_TEMPLATE_LIST]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  /**
   * Edit template security
   */
  [EDIT_TEMPLATE_DELETE]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },

  // テンプレート名

  [TEMPLATE_LIST_BUTTON_CREATE]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [TEMPLATE_LIST_INPUT_SERACH]: {
    [INTERNAL_ADMIN]: [V, E],
    [INTERNAL_GENERAL_USER]: [V, E],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [TEMPLATE_LIST_DROPDOWN_VIEW]: {
    [INTERNAL_ADMIN]: [V, S],
    [INTERNAL_GENERAL_USER]: [V, S],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [TEMPLATE_LIST_DROPDOWN_SELECT]: {
    [INTERNAL_ADMIN]: [V, S],
    [INTERNAL_GENERAL_USER]: [V, S],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },

  [TEMPLATE_LIST_FILTER_INPUT_TEMPLATE_NAME]: {
    [INTERNAL_ADMIN]: [V, E],
    [INTERNAL_GENERAL_USER]: [V, E],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },

  /**
   * Account list
   */

  [ACCOUNT_LIST]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: []
  },

  [FILTER_ACCOUNT_COMPANY]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },

  [CREATE_ACCOUNT_ROUTER]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },

  [EDIT_ACCOUNT_ROUTER]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [V, C],
    [EXTERNAL_GENERAL_USER]: []
  },

  [ACCOUNT_LIST_BUTTON_CREATE]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [V, C],
    [EXTERNAL_GENERAL_USER]: []
  },

  [DELETE_ACCOUNT_LIST]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [V, C],
    [EXTERNAL_GENERAL_USER]: []
  },

  [CREATE_ACCOUNT_CANCEL]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [V, C],
    [EXTERNAL_GENERAL_USER]: []
  },

  [CREATE_ACCOUNT_SAVE]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [V, C],
    [EXTERNAL_GENERAL_USER]: []
  },

  [EDIT_ACCOUNT_DELETE]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [V, C],
    [EXTERNAL_GENERAL_USER]: []
  },

  [VIEW_ACCOUNT_COMPANY]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },

  /**
   * Master data security
   */

  [MasterDataCompany.COMPANY_LIST_ROUTER]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [MasterDataCompany.COMPANY_LIST]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [MasterDataCompany.CREATE_NEW_COMPANY]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [MasterDataCompany.EDIT_NEW_COMPANY]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [MasterDataCompany.CHECK_COMPANY_LIST]: {
    [INTERNAL_ADMIN]: [V, C, S],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [MasterDataCompany.TABLE_COMPANY_LIST]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [MasterDataCompany.FILTER_COMPANY_LIST]: {
    [INTERNAL_ADMIN]: [V, C, E],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [MasterDataCompany.CREATE_NEW_COMPANY_LIST]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [MasterDataCompany.SEARCH_COMPANY_LIST]: {
    [INTERNAL_ADMIN]: [E],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [MasterDataCompany.NO_DATA_COMPANY_LIST]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [MasterDataCompany.ONCE_YOU_START_ADDING_COMPANIES]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [MasterDataCompany.NO_DATA_CREATE_NEW_COMPANY]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },

  /**
   * Project list security
   */

  [AuthorizationForProject.PROJECT_LIST]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },

  [AuthorizationForProject.CREATE_NEW_PROJECT_LIST]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [AuthorizationForProject.SEARCH_PROJECT_LIST]: {
    [INTERNAL_ADMIN]: [E],
    [INTERNAL_GENERAL_USER]: [E],
    [EXTERNAL_ADMIN]: [E],
    [EXTERNAL_GENERAL_USER]: [E]
  },
  [AuthorizationForProject.FILTER_PROJECT_LIST]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [C],
    [EXTERNAL_GENERAL_USER]: [C]
  },
  [AuthorizationForProject.FILTER_NAME_OF_CORPORATION]: {
    [INTERNAL_ADMIN]: [E],
    [INTERNAL_GENERAL_USER]: [E],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [AuthorizationForProject.TABLE_PROJECT_LIST]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.NO_DATA_PROJECT_LIST]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.CREATE_NEW_PROJECT]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.ONCE_YOU_START_ADDING_PROJECTS]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.NO_DATA_CREATE_NEW_PROJECT]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [C],
    [EXTERNAL_GENERAL_USER]: [C]
  },

  // Project list => Table
  [AuthorizationForProject.PROJECT_ID_TABLE]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.TRAVELER_NAME_TABLE_PROJECT]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.COMPANY_NAME_TABLE_PROJECT]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.COUNTRY_OF_TRAVEL_TABLE_PROJECT]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.DESTINATION_BASE_NAME_TABLE_PROJECT]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.STATUS_TABLE_PROJECT]: {
    [INTERNAL_ADMIN]: [E],
    [INTERNAL_GENERAL_USER]: [E],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.SCHEDULED_TRAVEL_DATE_TABLE_PROJECT]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.VISA_CATEGORY_TABLE_PROJECT]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.MODE_OF_TRAVEL_TABLE_PROJECT]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.MOT_PIC_TABLE_PROJECT]: {
    [INTERNAL_ADMIN]: [E],
    [INTERNAL_GENERAL_USER]: [E],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.CREATED_DATE_TABLE_PROJECT]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.PAGING_TABLE_PROJECT]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [C],
    [EXTERNAL_GENERAL_USER]: [C]
  },

  // Project list => Filter popup
  [AuthorizationForProject.PROJECT_ID_FILTER]: {
    [INTERNAL_ADMIN]: [E],
    [INTERNAL_GENERAL_USER]: [E],
    [EXTERNAL_ADMIN]: [E],
    [EXTERNAL_GENERAL_USER]: [E]
  },
  [AuthorizationForProject.TRAVELER_NAME_FILTER]: {
    [INTERNAL_ADMIN]: [E],
    [INTERNAL_GENERAL_USER]: [E],
    [EXTERNAL_ADMIN]: [E],
    [EXTERNAL_GENERAL_USER]: [E]
  },
  [AuthorizationForProject.COMPANY_NAME_FILTER]: {
    [INTERNAL_ADMIN]: [E],
    [INTERNAL_GENERAL_USER]: [E],
    [EXTERNAL_ADMIN]: [E],
    [EXTERNAL_GENERAL_USER]: [E]
  },
  [AuthorizationForProject.COUNTRY_OF_TRAVELER_FILTER]: {
    [INTERNAL_ADMIN]: [S],
    [INTERNAL_GENERAL_USER]: [S],
    [EXTERNAL_ADMIN]: [S],
    [EXTERNAL_GENERAL_USER]: [S]
  },
  [AuthorizationForProject.DESTINATION_BASE_NAME_FILTER]: {
    [INTERNAL_ADMIN]: [E],
    [INTERNAL_GENERAL_USER]: [E],
    [EXTERNAL_ADMIN]: [E],
    [EXTERNAL_GENERAL_USER]: [E]
  },
  [AuthorizationForProject.STATUS_FILTER]: {
    [INTERNAL_ADMIN]: [S],
    [INTERNAL_GENERAL_USER]: [S],
    [EXTERNAL_ADMIN]: [S],
    [EXTERNAL_GENERAL_USER]: [S]
  },
  [AuthorizationForProject.SCHEDULED_TRAVEL_DATE_FORM_FILTER]: {
    [INTERNAL_ADMIN]: [E, S],
    [INTERNAL_GENERAL_USER]: [E, S],
    [EXTERNAL_ADMIN]: [E, S],
    [EXTERNAL_GENERAL_USER]: [E, S]
  },
  [AuthorizationForProject.SCHEDULED_TRAVEL_DATE_TO_FILTER]: {
    [INTERNAL_ADMIN]: [E, S],
    [INTERNAL_GENERAL_USER]: [E, S],
    [EXTERNAL_ADMIN]: [E, S],
    [EXTERNAL_GENERAL_USER]: [E, S]
  },
  [AuthorizationForProject.VISA_CATEGORY_FILTER]: {
    [INTERNAL_ADMIN]: [S],
    [INTERNAL_GENERAL_USER]: [S],
    [EXTERNAL_ADMIN]: [S],
    [EXTERNAL_GENERAL_USER]: [S]
  },
  [AuthorizationForProject.MODE_OF_TRAVEL_FILTER]: {
    [INTERNAL_ADMIN]: [S],
    [INTERNAL_GENERAL_USER]: [S],
    [EXTERNAL_ADMIN]: [S],
    [EXTERNAL_GENERAL_USER]: [S]
  },
  [AuthorizationForProject.PIC_FILTER]: {
    [INTERNAL_ADMIN]: [S],
    [INTERNAL_GENERAL_USER]: [S],
    [EXTERNAL_ADMIN]: [S],
    [EXTERNAL_GENERAL_USER]: [S]
  },
  [AuthorizationForProject.CREATED_DATE_FILTER]: {
    [INTERNAL_ADMIN]: [E, S],
    [INTERNAL_GENERAL_USER]: [E, S],
    [EXTERNAL_ADMIN]: [E, S],
    [EXTERNAL_GENERAL_USER]: [E, S]
  },
  [AuthorizationForProject.RESET_FILTER]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [C],
    [EXTERNAL_GENERAL_USER]: [C]
  },
  [AuthorizationForProject.APPLY_FILTER]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [C],
    [EXTERNAL_GENERAL_USER]: [C]
  },

  // Project list => Search & Filter
  [AuthorizationForProject.ICON_DELETE_PROJECT_LIST]: {
    [INTERNAL_ADMIN]: [V, D],
    [INTERNAL_GENERAL_USER]: [V, D],
    [EXTERNAL_ADMIN]: [V, D],
    [EXTERNAL_GENERAL_USER]: [V, D]
  },
  [AuthorizationForProject.BUTTON_DELETE_PROJECT_LIST]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [C],
    [EXTERNAL_GENERAL_USER]: [C]
  },
  [AuthorizationForProject.NO_DATA_SEARCH_FILTER_PROJECT_LIST]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.NO_PROJECTS_MATCHED_YOUR_SEARCH]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },

  // Create Project Basic Information
  [AuthorizationForProject.SHORT_MEMO_PROJECT_BASIC]: {
    [INTERNAL_ADMIN]: [E],
    [INTERNAL_GENERAL_USER]: [E],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [AuthorizationForProject.SCHEDULE_PROJECT_BASIC]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [C],
    [EXTERNAL_GENERAL_USER]: [C]
  },
  [AuthorizationForProject.QUESTIONNAIRE_PROJECT_BASIC]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [C],
    [EXTERNAL_GENERAL_USER]: [C]
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_LIST_PROJECT_BASIC]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [C],
    [EXTERNAL_GENERAL_USER]: [C]
  },
  [AuthorizationForProject.TASK_PROJECT_BASIC]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [AuthorizationForProject.MEMO_PROJECT_BASIC]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [AuthorizationForProject.CANCEL_CREATION_PROJECT_BASIC]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [C],
    [EXTERNAL_GENERAL_USER]: [C]
  },
  [AuthorizationForProject.SAVE_TEMPORARILY_PROJECT_BASIC]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [C],
    [EXTERNAL_GENERAL_USER]: [C]
  },
  [AuthorizationForProject.REGISTER_PROJECT_BASIC]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [C],
    [EXTERNAL_GENERAL_USER]: [C]
  },
  [AuthorizationForProject.REGISTRATION_INFORMATION_PROJECT_BASIC]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.CREATOR_PROJECT_BASIC]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.BASIC_INFORMATION_PROJECT_BASIC]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [C],
    [EXTERNAL_GENERAL_USER]: [C]
  },
  [AuthorizationForProject.CANCEL_SHORT_MEMO_PROJECT_BASIC]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [AuthorizationForProject.SAVE_SHORT_MEMO_PROJECT_BASIC]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },

  // Create Project Basic Information => Request content
  [AuthorizationForProject.REQUEST_CONTENT_PROJECT_BASIC]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },

  // Create Project Basic Information => Traveler Information
  [AuthorizationForProject.TRAVELER_INFORMATION_PROJECT_BASIC]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },

  // Create Project Basic Information => Stakeholder
  [AuthorizationForProject.STAKE_HOLDERS_PROJECT_BASIC]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.THOSE_WHO_HAVE_BEEN_SET_AS_RELATED_PARTIES_CAN_VIEW_THIS_MASTER]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.MAIL_ADDRESS_STAKE_HOLDER]: {
    [INTERNAL_ADMIN]: [E],
    [INTERNAL_GENERAL_USER]: [E],
    [EXTERNAL_ADMIN]: [E],
    [EXTERNAL_GENERAL_USER]: [E]
  },
  [AuthorizationForProject.ADD_STAKE_HOLDER]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [C],
    [EXTERNAL_GENERAL_USER]: [C]
  },

  // Create Project Basic Information => Attachments
  [AuthorizationForProject.FILE_ATTACHMENT_ATTACHMENTS]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.DRAG_AND_DROP_FILES_HERE_ATTACHMENTS]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.FILE_FORMATS_THAT_CAN_BE_UPLOAD_ATTACHMENTS]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.BROWSER_FILE_ATTACHMENTS]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [C],
    [EXTERNAL_GENERAL_USER]: [C]
  },
  [AuthorizationForProject.FILE_LIST_ATTACHMENTS]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [C],
    [EXTERNAL_GENERAL_USER]: [C]
  },

  // Edit Project basic information => Request content
  [AuthorizationForProject.SUPPORT_TYPE_PROJECT_BASIC_EDIT]: {
    [INTERNAL_ADMIN]: [S],
    [INTERNAL_GENERAL_USER]: [S],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.NOTE_FOR_REQUEST_CONTENT_PROJECT_BASIC_EDIT]: {
    [INTERNAL_ADMIN]: [E],
    [INTERNAL_GENERAL_USER]: [E],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.LOCAL_CONTACT_PROJECT_BASIC_EDIT]: {
    [INTERNAL_ADMIN]: [E, S, C],
    [INTERNAL_GENERAL_USER]: [E, S, C],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },

  // Edit Project basic information => Traveler Information
  [AuthorizationForProject.TRAVELER_INFORMATION_BASIC_EDIT]: {
    [INTERNAL_ADMIN]: [E, S],
    [INTERNAL_GENERAL_USER]: [E, S],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },

  // Edit Project basic information => Family information
  [AuthorizationForProject.FAMILY_INFORMATION_EDIT]: {
    [INTERNAL_ADMIN]: [E, C],
    [INTERNAL_GENERAL_USER]: [E, C],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },

  // Edit Project basic information => Assignment information
  [AuthorizationForProject.ASSIGNMENT_INFORMATION_EDIT]: {
    [INTERNAL_ADMIN]: [E],
    [INTERNAL_GENERAL_USER]: [E],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },

  // Edit Project basic information => Visa information
  [AuthorizationForProject.VISA_CATEGORY_INFORMATION_EDIT]: {
    [INTERNAL_ADMIN]: [S],
    [INTERNAL_GENERAL_USER]: [S],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },

  // Edit Project basic information => Note information
  [AuthorizationForProject.NOTE_INFORMATION_EDIT]: {
    [INTERNAL_ADMIN]: [E],
    [INTERNAL_GENERAL_USER]: [E],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },

  // question project
  [AuthorizationForProject.SUBMISSION_DEADLINE_QUESTION_PROJECT]: {
    [INTERNAL_ADMIN]: [E],
    [INTERNAL_GENERAL_USER]: [E],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.EDIT_RESPONDENT_QUESTION_PROJECT]: {
    [INTERNAL_ADMIN]: [E],
    [INTERNAL_GENERAL_USER]: [E],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.SELECT_TEMPLATE_QUESTION_PROJECT]: {
    [INTERNAL_ADMIN]: [E],
    [INTERNAL_GENERAL_USER]: [E],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [AuthorizationForProject.ANSWER_QUESTION_PROJECT_AFTER_COMPLETE]: {
    [INTERNAL_ADMIN]: [E],
    [INTERNAL_GENERAL_USER]: [E],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [AuthorizationForProject.QUESTION_COMPLETE_BUTTON]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [AuthorizationForProject.QUESTION_SAVE_DRAFT_BUTTON]: {
    [INTERNAL_ADMIN]: [],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [C],
    [EXTERNAL_GENERAL_USER]: [C]
  },
  [AuthorizationForProject.ACTION_TAB_QUESTION_PROJECT]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  // required documents
  [AuthorizationForProject.REFERENCE_TABLE_COLLAPSE]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [C],
    [EXTERNAL_GENERAL_USER]: [C]
  },
  [AuthorizationForProject.REFERENCE_TABLE_NAME]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.REFERENCE_TABLE_CHECKBOX]: {
    [INTERNAL_ADMIN]: [S],
    [INTERNAL_GENERAL_USER]: [S],
    [EXTERNAL_ADMIN]: [S],
    [EXTERNAL_GENERAL_USER]: [S]
  },
  [AuthorizationForProject.REFERENCE_TABLE_LABEL_INDEX]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.REFERENCE_TABLE_DOCUMENTS_NAME]: {
    [INTERNAL_ADMIN]: [E],
    [INTERNAL_GENERAL_USER]: [E],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.REFERENCE_TABLE_DESCRIPTION]: {
    [INTERNAL_ADMIN]: [E],
    [INTERNAL_GENERAL_USER]: [E],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.REFERENCE_TABLE_FILE_NAME]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [C],
    [EXTERNAL_GENERAL_USER]: [C]
  },
  [AuthorizationForProject.REQUIRED_DOCUMENT_LABEL_WAITING_APPROVE]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [AuthorizationForProject.REQUIRED_DOCUMENT_DELETE_FILE]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [C],
    [EXTERNAL_GENERAL_USER]: [C]
  },
  [AuthorizationForProject.REQUIRED_DOCUMENT_APPROVE_BUTTON]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [AuthorizationForProject.REQUIRED_DOCUMENT_REJECT_BUTTON]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [AuthorizationForProject.REFERENCE_TABLE_UPLOAD_FILE_BUTTON]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [AuthorizationForProject.REFERENCE_TABLE_ADD_NEW_ROW_BUTTON]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [AuthorizationForProject.REFERENCE_TABLE_DELETE_ROW_BUTTON]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [AuthorizationForProject.REFERENCE_TABLE_ADD_NEW_LINE_ICON]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [AuthorizationForProject.REQUIRED_DOCUMENTS_TEMPLATE_NAME]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.REQUIRED_DOCUMENTS_EXPORT_PDF]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [C],
    [EXTERNAL_GENERAL_USER]: [C]
  },
  [AuthorizationForProject.REQUIRED_DOCUMENTS_CANCEL]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [AuthorizationForProject.REQUIRED_DOCUMENTS_SAVE]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [C],
    [EXTERNAL_GENERAL_USER]: [C]
  },
  [AuthorizationForProject.REQUIRED_DOCUMENTS_TITLE]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  // Required Documents Table
  [NecessaryDocumentsAuthoration.REQUIRED_DOCUMENT_SELECT_ROW]: {
    [INTERNAL_ADMIN]: [E],
    [INTERNAL_GENERAL_USER]: [E],
    [EXTERNAL_ADMIN]: [E],
    [EXTERNAL_GENERAL_USER]: [E]
  },
  [NecessaryDocumentsAuthoration.REQUIRED_DOCUMENT_NO_LABEL]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [NecessaryDocumentsAuthoration.REQUIRED_DOCUMENT_SELECT_NAME]: {
    [INTERNAL_ADMIN]: [S],
    [INTERNAL_GENERAL_USER]: [S],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [NecessaryDocumentsAuthoration.REQUIRED_DOCUMENT_EDIT_NUMBER_OF_COPY]: {
    [INTERNAL_ADMIN]: [E],
    [INTERNAL_GENERAL_USER]: [E],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [NecessaryDocumentsAuthoration.REQUIRED_DOCUMENT_PICK_SUBMISSION_DATELINE]: {
    [INTERNAL_ADMIN]: [S],
    [INTERNAL_GENERAL_USER]: [S],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [NecessaryDocumentsAuthoration.REQUIRED_DOCUMENT_SELECT_SUBMISSION_METHOD]: {
    [INTERNAL_ADMIN]: [S],
    [INTERNAL_GENERAL_USER]: [S],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [NecessaryDocumentsAuthoration.REQUIRED_DOCUMENT_EDIT_NOTE]: {
    [INTERNAL_ADMIN]: [E],
    [INTERNAL_GENERAL_USER]: [E],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  // FIX me when handled logig permission for src/pages/project-management/add-edit/components/required-documents/common/InputUpload.tsx
  // [NecessaryDocumentsAuthoration.REQUIRED_DOCUMENT_CLICKABLE_TO_UPLOADED_FILE]: {
  //     [INTERNAL_ADMIN]: [C],
  //     [INTERNAL_GENERAL_USER]: [C],
  //     [EXTERNAL_ADMIN]: [C],
  //     [EXTERNAL_GENERAL_USER]: [C]
  // },
  [NecessaryDocumentsAuthoration.REQUIRED_DOCUMENT_SELECT_SUBMITTER]: {
    [INTERNAL_ADMIN]: [S],
    [INTERNAL_GENERAL_USER]: [S],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [NecessaryDocumentsAuthoration.REQUIRED_DOCUMENT_CHECKED_DOCUMENT_CONFIRMED]: {
    [INTERNAL_ADMIN]: [S],
    [INTERNAL_GENERAL_USER]: [S],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [NecessaryDocumentsAuthoration.REQUIRED_DOCUMENT_ADD_LINE]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [NecessaryDocumentsAuthoration.REQUIRED_DOCUMENT_DELETE_LINE]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [NecessaryDocumentsAuthoration.REQUIRED_DOCUMENT_INSERT_LINE]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  // Additional Documents Table
  [NecessaryDocumentsAuthoration.ADDITIONAL_DOCUMENT_SELECT_ROW]: {
    [INTERNAL_ADMIN]: [S],
    [INTERNAL_GENERAL_USER]: [S],
    [EXTERNAL_ADMIN]: [S],
    [EXTERNAL_GENERAL_USER]: [S]
  },
  [NecessaryDocumentsAuthoration.ADDITIONAL_DOCUMENT_SELECT_NAME]: {
    [INTERNAL_ADMIN]: [S],
    [INTERNAL_GENERAL_USER]: [S],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [NecessaryDocumentsAuthoration.ADDITIONAL_DOCUMENT_EDIT_NUMBER_OF_COPY]: {
    [INTERNAL_ADMIN]: [E],
    [INTERNAL_GENERAL_USER]: [E],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [NecessaryDocumentsAuthoration.ADDITIONAL_DOCUMENT_PICK_SUBMISSION_DATELINE]: {
    [INTERNAL_ADMIN]: [E],
    [INTERNAL_GENERAL_USER]: [E],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [NecessaryDocumentsAuthoration.ADDITIONAL_DOCUMENT_SELECT_SUBMISSION_METHOD]: {
    [INTERNAL_ADMIN]: [S],
    [INTERNAL_GENERAL_USER]: [S],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [NecessaryDocumentsAuthoration.ADDITIONAL_DOCUMENT_EDIT_NOTE]: {
    [INTERNAL_ADMIN]: [E],
    [INTERNAL_GENERAL_USER]: [E],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [NecessaryDocumentsAuthoration.ADDITIONAL_DOCUMENT_SELECT_SUBMITTER]: {
    [INTERNAL_ADMIN]: [S],
    [INTERNAL_GENERAL_USER]: [S],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [NecessaryDocumentsAuthoration.ADDITIONAL_DOCUMENT_CHECKED_DOCUMENT_CONFIRMED]: {
    [INTERNAL_ADMIN]: [S],
    [INTERNAL_GENERAL_USER]: [S],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [NecessaryDocumentsAuthoration.ADDITIONAL_DOCUMENT_ADD_LINE]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [NecessaryDocumentsAuthoration.ADDITIONAL_DOCUMENT_DELETE_LINE]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [NecessaryDocumentsAuthoration.ADDITIONAL_DOCUMENT_INSERT_LINE]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },

  /**
   * Announcement
   */
  [VIEW_ANNOUNCEMENT_ROUTER]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [ANNOUNCEMENT_LIST]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },

  [PIN_ANNOUNCEMENT]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: []
  },

  [CREATE_ANNOUNCEMENT_ROUTER]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: []
  },

  [EDIT_ANNOUNCEMENT_ROUTER]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [V, C],
    [EXTERNAL_ADMIN]: [V, C],
    [EXTERNAL_GENERAL_USER]: [V, C]
  },

  [VIEW_NOTIFICATION_ROUTER]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },

  [ANNOUNCEMENT_LIST_BUTTON_CREATE]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [V, C],
    [EXTERNAL_GENERAL_USER]: []
  },

  [DELETE_ANNOUNCEMENT_LIST]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [V, C]
  },

  [CREATE_ANNOUNCEMENT_CANCEL]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [V, C],
    [EXTERNAL_GENERAL_USER]: []
  },

  [CREATE_ANNOUNCEMENT_SAVE]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [V, C],
    [EXTERNAL_GENERAL_USER]: []
  },

  [EDIT_ANNOUNCEMENT_DELETE]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [V, C],
    [EXTERNAL_GENERAL_USER]: []
  },

  [VIEW_ANNOUNCEMENT_EDIT]: {
    [INTERNAL_ADMIN]: [V, C],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [V, C],
    [EXTERNAL_GENERAL_USER]: []
  },

  [VIEW_ANNOUNCEMENT_COMPANY]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },

  // Customer request TABLES
  [AuthorizationForProject.CUSTOMER_REQUEST_LIST]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_LIST_ITEM_ADD]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_LIST_CHECKBOX]: {
    [INTERNAL_ADMIN]: [S],
    [INTERNAL_GENERAL_USER]: [S],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_LIST_NO]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_LIST_REQUEST_NAME]: {
    [INTERNAL_ADMIN]: [S],
    [INTERNAL_GENERAL_USER]: [S],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_LIST_APPLICATION_DOCUMENTS]: {
    [INTERNAL_ADMIN]: [S],
    [INTERNAL_GENERAL_USER]: [S],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_LIST_CONTENT_OF_REQUEST]: {
    [INTERNAL_ADMIN]: [S],
    [INTERNAL_GENERAL_USER]: [S],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_LIST_DEADLINE]: {
    [INTERNAL_ADMIN]: [S],
    [INTERNAL_GENERAL_USER]: [S],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_LIST_EMAIL]: {
    [INTERNAL_ADMIN]: [S],
    [INTERNAL_GENERAL_USER]: [S],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_CONFIRMED]: {
    [INTERNAL_ADMIN]: [S],
    [INTERNAL_GENERAL_USER]: [S],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_SEND]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_DISCUSS]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [C],
    [EXTERNAL_GENERAL_USER]: [C]
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_SAVE_AS_DRAFT]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_CANCEL]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  // Customer request select request name
  [AuthorizationForProject.CUSTOMER_REQUEST_NAME_SEARCH]: {
    [INTERNAL_ADMIN]: [E],
    [INTERNAL_GENERAL_USER]: [E],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_NAME_LIST_OPTION]: {
    [INTERNAL_ADMIN]: [S],
    [INTERNAL_GENERAL_USER]: [S],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_NAME_ADD_ITEM_INPUT]: {
    [INTERNAL_ADMIN]: [E],
    [INTERNAL_GENERAL_USER]: [E],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_NAME_ADD_ITEM]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  // Customer request select multiple
  [AuthorizationForProject.CUSTOMER_REQUEST_MULTIPLE_SEARCH]: {
    [INTERNAL_ADMIN]: [E],
    [INTERNAL_GENERAL_USER]: [E],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_MULTIPLE_LIST_OPTION]: {
    [INTERNAL_ADMIN]: [S],
    [INTERNAL_GENERAL_USER]: [S],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  // Customer request select content of request
  [AuthorizationForProject.CUSTOMER_REQUEST_CONTENT_OF_REQUEST_SEARCH]: {
    [INTERNAL_ADMIN]: [E],
    [INTERNAL_GENERAL_USER]: [E],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_CONTENT_OF_REQUEST_LIST_OPTION]: {
    [INTERNAL_ADMIN]: [S],
    [INTERNAL_GENERAL_USER]: [S],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_CONTENT_OF_REQUEST_CREATE_TEMPLATE]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_CONTENT_OF_REQUEST_EDIT]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  // Customer request modal content of request create
  [AuthorizationForProject.CUSTOMER_REQUEST_MODAL_CREATE_NEW_TEMPLATE]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_MODAL_CREATE_TEMPLATE_NAME]: {
    [INTERNAL_ADMIN]: [E],
    [INTERNAL_GENERAL_USER]: [E],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_MODAL_CREATE_CONTENT]: {
    [INTERNAL_ADMIN]: [E],
    [INTERNAL_GENERAL_USER]: [E],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_MODAL_CREATE_CANCEL]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_MODAL_CREATE_SAVE]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  // Customer request modal content of request edit
  [AuthorizationForProject.CUSTOMER_REQUEST_MODAL_EDIT_NEW_TEMPLATE]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_MODAL_EDIT_EMAIL_TEMPLATE_NAME]: {
    [INTERNAL_ADMIN]: [E],
    [INTERNAL_GENERAL_USER]: [E],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_MODAL_EDIT_CONTENT]: {
    [INTERNAL_ADMIN]: [E],
    [INTERNAL_GENERAL_USER]: [E],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_MODAL_EDIT_CANCEL]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_MODAL_EDIT_SAVE]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  // Customer request chat
  [AuthorizationForProject.CUSTOMER_REQUEST_CHAT_TITLE]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_CHAT_ICON_X]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [C],
    [EXTERNAL_GENERAL_USER]: [C]
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_CHAT_CONTENT_OF_QUEST]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_CHAT_NAME]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_CHAT_AVATAR]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_CHAT_MESSAGE]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_CHAT_TIME]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_CHAT_TYPE_A_MESSAGE]: {
    [INTERNAL_ADMIN]: [E],
    [INTERNAL_GENERAL_USER]: [E],
    [EXTERNAL_ADMIN]: [E],
    [EXTERNAL_GENERAL_USER]: [E]
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_CHAT_SEND]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [C],
    [EXTERNAL_GENERAL_USER]: [C]
  },
  // Customer request chat
  [AuthorizationForProject.CUSTOMER_REQUEST_FILTER_TITLE]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_FILTER_EMAIL_ADDRESS]: {
    [INTERNAL_ADMIN]: [E],
    [INTERNAL_GENERAL_USER]: [E],
    [EXTERNAL_ADMIN]: [E],
    [EXTERNAL_GENERAL_USER]: [E]
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_FILTER_REQUEST_NAME]: {
    [INTERNAL_ADMIN]: [E],
    [INTERNAL_GENERAL_USER]: [E],
    [EXTERNAL_ADMIN]: [E],
    [EXTERNAL_GENERAL_USER]: [E]
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_FILTER_DOCUMENT]: {
    [INTERNAL_ADMIN]: [E],
    [INTERNAL_GENERAL_USER]: [E],
    [EXTERNAL_ADMIN]: [E],
    [EXTERNAL_GENERAL_USER]: [E]
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_FILTER_STATUS]: {
    [INTERNAL_ADMIN]: [S],
    [INTERNAL_GENERAL_USER]: [S],
    [EXTERNAL_ADMIN]: [S],
    [EXTERNAL_GENERAL_USER]: [S]
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_FILTER_DEADLINE]: {
    [INTERNAL_ADMIN]: [E],
    [INTERNAL_GENERAL_USER]: [E],
    [EXTERNAL_ADMIN]: [E],
    [EXTERNAL_GENERAL_USER]: [E]
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_FILTER_CLEAR_FILTER]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [C],
    [EXTERNAL_GENERAL_USER]: [C]
  },
  [AuthorizationForProject.CUSTOMER_REQUEST_FILTER_APPLY]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [C],
    [EXTERNAL_GENERAL_USER]: [C]
  },
  [AuthorizationForProject.APPROVED_REJECT_TASK_PROJECT_ATTACHMENT]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  // Task List TABLES
  [AuthorizationForProject.TASK_LIST]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [AuthorizationForProject.TASK_LIST_ITEM_ADD]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [AuthorizationForProject.TASK_LIST_CHECKBOX]: {
    [INTERNAL_ADMIN]: [S],
    [INTERNAL_GENERAL_USER]: [S],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [AuthorizationForProject.TASK_LIST_NO]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.TASK_LIST_NAME]: {
    [INTERNAL_ADMIN]: [S],
    [INTERNAL_GENERAL_USER]: [S],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.TASK_LIST_DEADLINE]: {
    [INTERNAL_ADMIN]: [S],
    [INTERNAL_GENERAL_USER]: [S],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.TASK_LIST_MANAGER]: {
    [INTERNAL_ADMIN]: [S],
    [INTERNAL_GENERAL_USER]: [S],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.TASK_LIST_STATUS]: {
    [INTERNAL_ADMIN]: [S],
    [INTERNAL_GENERAL_USER]: [S],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.TASK_LIST_REMARK]: {
    [INTERNAL_ADMIN]: [S],
    [INTERNAL_GENERAL_USER]: [S],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForProject.TASK_SAVE]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [AuthorizationForProject.TASK_CANCEL]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  // My profile
  [MY_PROFILE]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [ALL_TASK_PROJECT]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [MY_PROFILE_BACK]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [C],
    [EXTERNAL_GENERAL_USER]: [C]
  },
  // Account master
  [AccountMaster.PAGING_TABLE_ACCOUNT_MASTER]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [C]
  },
  [AccountMaster.CREATE_NEW_ACCOUNT_MASTER_LIST]: {
    [INTERNAL_ADMIN]: [V],
    [EXTERNAL_ADMIN]: [V]
  },
  [AccountMaster.APPROVE_REJECT_ACCOUNT_MASTER_LIST]: {
    [INTERNAL_ADMIN]: [V],
    [EXTERNAL_ADMIN]: [V]
  },
  [AccountMaster.UPDATE_ACCOUNT_MASTER_LIST]: {
    [INTERNAL_ADMIN]: [V],
    [EXTERNAL_ADMIN]: [V]
  },
  [AccountMaster.ACCOUNT_MASTER_REQUEST_FILTER_COMPANY]: {
    [INTERNAL_ADMIN]: [E],
    [INTERNAL_GENERAL_USER]: [E]
  },
  // Announcement
  [AuthorizationForAnnouncement.PAGING_TABLE_ANNOUNCEMENT]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [C],
    [EXTERNAL_GENERAL_USER]: [C]
  },
  [AuthorizationForAnnouncement.CREATE_NEW_ANNOUNCEMENT_LIST]: {
    [INTERNAL_ADMIN]: [V],
    [INTERNAL_GENERAL_USER]: [V]
  },
  [AuthorizationForAnnouncement.DELETE_ANNOUNCEMENT_ATTACHMENT]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [C],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  [AuthorizationForAnnouncement.APPOVED_REJECT_ANNOUNCEMENT_ATTACHMENT]: {
    [INTERNAL_ADMIN]: [C],
    [INTERNAL_GENERAL_USER]: [],
    [EXTERNAL_ADMIN]: [],
    [EXTERNAL_GENERAL_USER]: []
  },
  // Stakeholder
  [Stakeholder.STAKEHOLDER_ACTION_VIEW]: {
    [EXTERNAL_ADMIN]: [V]
  },
  [Stakeholder.STAKEHOLDER_VIEW]: {
    [INTERNAL_ADMIN]: [V],
    [EXTERNAL_ADMIN]: [V],
    [EXTERNAL_GENERAL_USER]: [V]
  },
  [Stakeholder.STAKEHOLDER_UPDATE]: {
    [INTERNAL_ADMIN]: [V],
    [EXTERNAL_ADMIN]: [V]
  }
};

const useAuthorization = () => {
  const user = useAppSelector((x) => x.global.userProfile);
  const accessToken = localStorage.getItem('access_token') ?? '';

  const getUserRole = (): string | undefined => {
    return user?.role;
  };

  // Check user has permission to do a action on feature
  const hasPermission = (featureName: string, action: string): boolean => {
    if (!user) {
      return false;
    }
    const permissions = FEATURE_AUTHORIZATION_MAPPING?.[featureName]?.[user?.role || ''];
    return permissions ? permissions.includes(action) : false;
  };

  const isInternalAdmin = useMemo(() => {
    if (user) {
      return user.role === INTERNAL_ADMIN;
    }
    return false;
  }, [user]);

  const isInternalUser = useMemo(() => {
    if (user) {
      return user.role === INTERNAL_GENERAL_USER;
    }
    return false;
  }, [user]);

  const isInternalRole = useMemo(() => {
    if (user) {
      return [INTERNAL_ADMIN, INTERNAL_GENERAL_USER].includes(user.role);
    }
    return false;
  }, [user]);

  const isExternalAdmin = useMemo(() => {
    if (user) {
      return user.role === EXTERNAL_ADMIN;
    }
    return false;
  }, [user]);

  const isExternalUser = useMemo(() => {
    if (user) {
      return user.role === EXTERNAL_GENERAL_USER;
    }
    return false;
  }, [user]);

  const isExternalRole = useMemo(() => {
    if (user) {
      return [EXTERNAL_ADMIN, EXTERNAL_GENERAL_USER].includes(user.role);
    }
    return false;
  }, [user]);

  const isAdminRole = useMemo(() => {
    if (user) {
      return [INTERNAL_ADMIN, EXTERNAL_ADMIN].includes(user.role);
    }
    return false;
  }, [user]);

  const isUserRole = useMemo(() => {
    if (user) {
      return [INTERNAL_GENERAL_USER, EXTERNAL_GENERAL_USER].includes(user.role);
    }
    return false;
  }, [user]);

  return {
    user,
    accessToken,
    getUserRole,
    hasPermission,
    isInternalAdmin,
    isInternalUser,
    isInternalRole,
    isExternalAdmin,
    isExternalUser,
    isExternalRole,
    isAdminRole,
    isUserRole,
    roles: user?.role || ''
  };
};

export default useAuthorization;
