import { useAppDispatch, useAppSelector } from '@/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { ConfirmCancelEditing } from '@/components/confirm-popup';
import { calculateCharacters } from '@/components/form-box-editor';

import { IAlertNotificationState, setAlertNotification } from '@/redux/globalReducer';

import { TEXT_FIELD_PHONE_NUMBER_MAX_LENGTH } from '@/utils/constants/AppConstants';
import { PROJECT_LIST_URL } from '@/utils/constants/RouteContants';
import { REGEX_EMAIL_RULE } from '@/utils/constants/regex';
import { handleCommonError } from '@/utils/helpers/common';
import { stringifyObjectValidateYup } from '@/utils/method';
import { submitInquiry } from '@/utils/services/inquiry';

import Headers from './components/Headers';
import InquiryForm from './components/InquiryForm';
import Introduction from './components/Introduction';
import { EDITOR_CHARACTER_LIMITED, FIELD } from './constants';
import { IForm } from './models';

import './Styles.scss';

const Inquiry = () => {
  const user = useAppSelector((x) => x.global.userProfile);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const textEditorRef = useRef<any>();
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false);

  const schema = () => {
    return yup.object().shape({
      [FIELD.EMAILS]: yup
        .array()
        .min(
          1,
          stringifyObjectValidateYup({
            keyT: 'common:MSG_C_001_input',
            optionsTx: { field: 'inquiry:email_address_label' }
          })
        )
        .test({
          name: 'valid-mail_address',
          exclusive: false,
          params: {},
          message: stringifyObjectValidateYup({
            keyT: 'common:MSG_A_016'
          }),
          test: (values) => {
            const isError = values?.find((str) => !REGEX_EMAIL_RULE.test(str));
            return !isError;
          }
        }),
      [FIELD.CONTENT]: yup
        .string()
        .test({
          name: 'valid_content_required',
          exclusive: false,
          params: {},
          message: stringifyObjectValidateYup({
            keyT: 'common:MSG_C_001_input',
            optionsTx: { field: 'inquiry:content_label' }
          }),
          test: () => {
            const length = calculateCharacters(textEditorRef.current);
            return length !== 0;
          }
        })
        .test({
          name: 'valid_content_limited',
          exclusive: false,
          params: {},
          message: stringifyObjectValidateYup({
            keyT: 'common:MSG_C_011',
            optionsTx: { field: 'inquiry:content_label', limited: EDITOR_CHARACTER_LIMITED }
          }),
          test: () => {
            const length = calculateCharacters(textEditorRef.current);
            return length <= EDITOR_CHARACTER_LIMITED;
          }
        }),
      [FIELD.PHONE]: yup
        .string()
        .max(
          TEXT_FIELD_PHONE_NUMBER_MAX_LENGTH,
          stringifyObjectValidateYup({
            keyT: 'common:MSG_C_011',
            optionsTx: { field: 'inquiry:phone_label', limited: TEXT_FIELD_PHONE_NUMBER_MAX_LENGTH }
          })
        )
        .halfSize(
          stringifyObjectValidateYup({
            keyT: 'common:MSG_C_028'
          })
        )
    });
  };

  const form = useForm<IForm, any>({
    mode: 'all',
    shouldUnregister: false,
    resolver: yupResolver(schema()),
    defaultValues: {
      [FIELD.EMAILS]: user?.email ? [user.email] : [],
      [FIELD.NAME]: user?.name,
      [FIELD.COMPANY]: user?.organizationName,
      [FIELD.PHONE]: '',
      [FIELD.CONTENT]: ''
    }
  });

  const prepareForm = (values: IForm) => {
    const newValues = { ...values };
    for (const key in newValues) {
      if (key === FIELD.EMAILS && newValues.emails.length) {
        newValues.emails = newValues.emails.join(';');
      }
    }
    return { values: newValues };
  };

  const handleSendInquiry = async (values: IForm) =>
    new Promise<boolean>(async (resolve) => {
      try {
        const { values: convertedValues } = prepareForm(values);

        const responses = await submitInquiry(convertedValues);
        if (!responses?.data?.isSuccess) throw new Error();
        dispatch(
          setAlertNotification({
            show: true,
            type: 'success',
            position: 'top',
            message: t('inquiry:notify_dialog_success')
          })
        );
        setIsEdit(false);
        setTimeout(() => {
          resolve(true);
          navigate(`${PROJECT_LIST_URL}`);
        }, 500);
      } catch (error: any) {
        const callback = (mess: IAlertNotificationState) => dispatch(setAlertNotification(mess));
        handleCommonError(error, t('inquiry:notify_dialog_fail') ?? '', callback);
        resolve(true);
      }
    });

  const handleCancelInquiry = () => {
    navigate(`/${PROJECT_LIST_URL}`);
  };

  useEffect(() => {
    const subscription = form.watch(() => {
      setIsEdit(true);
    });
    return () => subscription.unsubscribe();
  }, [form.watch]);

  return (
    <div className='inquiry-wrapper'>
      <FormProvider {...form}>
        <Headers onAccept={form.handleSubmit(handleSendInquiry)} onCancel={handleCancelInquiry} />
        <div className='bg-white py-[16px] px-[20px] rounded-[10px]'>
          <Introduction />
          <InquiryForm defaultValues={form.formState.defaultValues} textEditorRef={textEditorRef} />
        </div>
      </FormProvider>

      <ConfirmCancelEditing condition={isEdit} />
    </div>
  );
};

export default Inquiry;
