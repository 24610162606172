import { QUERY_CONDITION, QUERY_OPERATION, QUERY_TYPE } from '@/utils/constants';

import { convertFullWidthToHalfWidth } from '../globalHelper';
import { BaseQueryModuleItem, fromDate, guidWith, toDate } from './modules';

interface IQueryItem extends ICondition {
  queryCondition?: QUERY_CONDITION;
  childrens?: IQueryItem[];
}

interface ICondition {
  fieldTitle?: string;
  queryType?: QUERY_TYPE;
  queryValue?: string;
  operation?: QUERY_OPERATION;
}

/**
 * @param {fieldTitle} fieldTitle - your field's key matching database
 * @param {type} type - what is field's type (number, string, boolean,...). Please follow enum QUERY_TYPE
 * @param {value} value - what is field's value which wanna filter by.
 * @param {operation} operation - what u wanna filter equal, contains,... Please follow QUERY_OPERATION
 * @param {condition} condition - what u wanna filter and, or,... Please follow QUERY_CONDITION
 * @param {childrens} children - this item's children (optional)
 */
type ConstructorParams = Parameters<
  (
    fieldTitle?: string | null,
    type?: QUERY_TYPE | null,
    value?: any,
    operation?: IQueryItem['operation'],
    condition?: IQueryItem['queryCondition'],
    children?: QueryItem[]
  ) => void
>;

/**
 * Common class define what u wanna attach to filter payload's item.
 * @param {arguments} ConstructorParams - your constructor's arguments
 */
class QueryItem implements IQueryItem {
  fieldTitle;
  queryType;
  queryValue;
  operation;
  queryCondition;
  childrens;

  /** modules for quickly create a QueryItem by template. */
  get modules(): BaseQueryModuleItem<QueryItem> {
    return {
      guidWith: guidWith.bind(this),
      fromDate: fromDate.bind(this),
      toDate: toDate.bind(this)
    };
  }

  constructor(...args: ConstructorParams) {
    const [fieldTitle, type, value, operation, condition = QUERY_CONDITION.EMPTY, children] = args;
    if (fieldTitle !== null) this.fieldTitle = fieldTitle;
    if (type !== null) this.queryType = type;
    this.queryValue = value;
    if (operation !== null) this.operation = operation;
    this.queryCondition = condition;
    if (children) this.childrens = children;
  }

  change(key: keyof IQueryItem, value: typeof QueryItem.arguments) {
    this[key] = value;
    return this;
  }

  setValues(values: Partial<IQueryItem>) {
    for (const key in values) {
      const property = key as keyof IQueryItem;
      this.change(property, values[property]);
    }
  }
}

/**
 * Common class follow Factory Pattern to produce a new query filter for list
 * It have some common method for quickly generate a new filter
 *
 * @constructs IQuerySection[]
 *
 * U wanna create logic for only your filter, u can extends this class and define four method to do this
 */
class QueryFactory {
  protected _data: QueryItem[] = [];

  constructor(defaultValues?: QueryItem[]) {
    if (defaultValues) this.data = defaultValues;
  }

  get data(): QueryItem[] {
    return this._data;
  }

  set data(values: QueryItem[]) {
    this._data = values;
  }

  stringifyData() {
    if (!this._data.length) return '';
    return JSON.stringify(this._data);
  }

  append(value: QueryItem) {
    this._data.push(value);
    return this;
  }

  /**
   * QueryItem on the list must be implement this rules:
   * 1. The first item must be had queryCondition is empty
   * If u not sure your list is valid when submit, this method can help u to do this.
   */
  sanitize() {
    const loopToDeep = (list: QueryItem[]) => {
      for (const item of list) {
        const children = item?.childrens;
        if (!(children instanceof Array)) continue;
        if (children.length === 0) {
          delete item.childrens;
          continue;
        }
        children[0].change('queryCondition', QUERY_CONDITION.EMPTY);
        loopToDeep(children);
      }
    };
    loopToDeep(this._data);
    return this;
  }

  initNewQuery(queryCondition: IQueryItem['queryCondition']): QueryItem {
    return new QueryItem(null, null, undefined, undefined, queryCondition, []);
  }

  /**
   * quickly generate a new searching section
   * @param {fields} fields - It can be a list field name string or list queryItem when u wanna deep customize.
   *
   * @param {searchValue} searchValue - your search value from input.
   *
   * @example
   * const filterFactory = new QueryFactory();
   * const fields = ['code', 'applicantName.ToLower()', new QueryItem()];
   * filterFactory.searchingBy(fields, searchValue.toLowerCase());
   * const filters = QueryFactory.data;
   */
  searchingBy(fields: string[] | QueryItem[], searchValue?: string) {
    const newQuerySection = this.initNewQuery(QUERY_CONDITION.EMPTY);
    for (const field of fields) {
      let newItem;
      if (field instanceof QueryItem) {
        newItem = field;
      }
      if (typeof searchValue === 'string' && typeof field === 'string') {
        const halfSizeValue = convertFullWidthToHalfWidth(searchValue);
        const queryCondition = newQuerySection?.childrens?.length ? QUERY_CONDITION.OR : QUERY_CONDITION.EMPTY;
        newItem = new QueryItem(field, QUERY_TYPE.TEXT, halfSizeValue, QUERY_OPERATION.CONTAINS, queryCondition);
      }
      if (!newItem) continue;
      newQuerySection.childrens?.push(newItem);
    }

    this.append(newQuerySection);
    return this;
  }

  toFilterString() {
    return this.sanitize().stringifyData();
  }

  and(condition: ICondition) {
    return this.append(
      new QueryItem(
        condition.fieldTitle,
        condition.queryType,
        condition.queryValue,
        condition.operation,
        this._data.length ? QUERY_CONDITION.AND : QUERY_CONDITION.EMPTY,
        []
      )
    );
  }
}

export type { IQueryItem };
export { QueryItem };
export default QueryFactory;
