import { Popover, PopoverProps } from 'antd';
import classNames from 'classnames';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BaseButton } from '@/components/base-button/BaseButton';

import { ISelectOption } from '@/utils/interfaces/form';

import DropdownIcon from '@/assets/icons/base-filter/filter-suffix-icon.svg';

import { RefActionControl } from '.';

import './BaseAction.scss';

export interface IBaseActionProps {
  label: string;
  popoverContent: React.ReactNode | React.ReactElement<any>;
  totalFiltered?: number;
  refControl?: React.MutableRefObject<RefActionControl>;
  getPopupContainer?: PopoverProps['getPopupContainer'];
}

const BaseAction = ({ label, popoverContent, totalFiltered, refControl, getPopupContainer }: IBaseActionProps) => {
  const { t } = useTranslation();
  const btnWrapper = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const formMethods = useFormContext();

  useEffect(() => {
    if (formMethods.formState.submitCount === 0) return;
    setOpen(false);
  }, [formMethods.formState.submitCount]);

  useImperativeHandle(refControl, () => ({
    toggle: (value) => {
      setOpen(value);
    }
  }));
  return (
    <div className='base-filter__primary-action'>
      <Popover
        open={open}
        placement='bottomRight'
        arrow={false}
        overlayInnerStyle={{ padding: '0' }}
        trigger='click'
        getPopupContainer={getPopupContainer ? getPopupContainer : () => btnWrapper.current ?? document.body}
        content={() => popoverContent}
        onOpenChange={(value) => setOpen(value)}
        destroyTooltipOnHide
      >
        <div className='popover-action-btn' ref={btnWrapper}>
          <BaseButton type='tertiary' className={open ? 'popover-action-btn--open-filter' : ''} onClick={() => setOpen(!open)}>
            <div className='flex w-[100%] gap-x-2'>
              <span className='w-[100%] text-center'>{t(label)}</span>
              <div className='flex flex-col justify-center'>
                <DropdownIcon className='icon-down popover-action__dropdown-icon' />
              </div>
            </div>
            {totalFiltered && <span className='number-items'>{totalFiltered}</span>}
          </BaseButton>
        </div>
      </Popover>
    </div>
  );
};

export const SecondaryActionDefault = ({ options, name, onSubmit }: { options: ISelectOption[]; name: string; onSubmit: () => void }) => {
  const { field } = useController({ name });
  if (!options.length) return <></>;
  return (
    <div className='py-[24px] flex flex-col rounded-[14px]'>
      {options.map((item) => (
        <div
          key={item?.id ?? item.value}
          className={classNames(
            `px-[24px] py-[8px] cursor-pointer hover:bg-green10 whitespace-nowrap`,
            item.value === field.value ? 'bg-green10' : ''
          )}
          onClick={() => {
            field.onChange(item.value);
            onSubmit();
          }}
        >
          {item.label}
        </div>
      ))}
    </div>
  );
};

export default BaseAction;
