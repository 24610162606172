import { useAppSelector } from '@/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Row, Space } from 'antd';
import { get } from 'lodash';
import { useRef, useState } from 'react';
import { FormProvider, SubmitHandler, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { BaseButton } from '@/components/base-button/BaseButton';
import BaseModal from '@/components/base-modal/BaseModal';
import { TYPE_ICON, showBasePopup } from '@/components/base-popup/BasePopup';
import BaseRadioGroups from '@/components/common/base-radio';
import FormItem from '@/components/common/form-item';
import { FormInput } from '@/components/form-input/FormInput';
import { FormSelect } from '@/components/form-select/FormSelect';
import { InfoIcon } from '@/components/icon-svg/IconSvg';

import { getUserRoleCompany } from '@/redux/project-v2/projectSelector';

import useAuthorization from '@/hooks/useAuthorization';
import useCompany from '@/hooks/useCompany';

import { EMAIL_REGEX, ERROR_CODE, ERROR_EMAIL_DUPLICATE, EXTERNAL_GENERAL_USER, REQUIRED_DOT } from '@/utils/constants/AppConstants';
import { validationEmail } from '@/utils/services/ProjectApiService';
import { createUser, requestUser } from '@/utils/services/UserApiService';

import PlusIcon from '@/assets/icons/PlusIcon.svg';

import { MAX_FIELD_STAKE_HOLDER } from '../../../contants';
import { IProjectStakeholder } from '../../../models';
import { FormCreateAccount } from '../../../validation/FormValidation';
import StakeHolderCard from '../../components/stakeholder/StakeHolderCard';
import StakeHolderInput from '../../components/stakeholder/StakeHolderInput';
import { relativePath } from '../../utils';
import { HAS_OTHER_STAKE_HOLDER } from '../constants';

const PARENT_PATH = 'steps[4]';
export interface IStep6Props {
  paths?: {
    parentPath: string;
    stakeHolderPath: string;
    hasOtherStakeHolderPath: string;
  };
}
export default function Step6(props: IStep6Props) {
  const { paths = { parentPath: PARENT_PATH, stakeHolderPath: 'projectStakeholders', hasOtherStakeHolderPath: 'hasOtherStakeHolder' } } = props;
  const { t } = useTranslation();
  const { id } = useParams();
  const form = useFormContext();
  const PATHS = {
    PROJECT_STAKEHOLDERS: relativePath(paths.stakeHolderPath, paths.parentPath),
    HAS_OTHER_STAKE_HOLDER: relativePath(paths.hasOtherStakeHolderPath, paths.parentPath)
  };
  const { append, remove, update } = useFieldArray({
    name: PATHS.PROJECT_STAKEHOLDERS,
    control: form.control
  });
  const [projectStakeholders] = form.watch([PATHS.PROJECT_STAKEHOLDERS]);
  const wrapperRef = useRef<any>(null);
  const { companyOptions } = useCompany();
  const { user, isExternalAdmin } = useAuthorization();
  const [showPopupCreateAccount, setShowPopupCreateAccount] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [disabledRole, setDisabledRole] = useState<boolean>(false);
  const [indexAccount, setIndexAccount] = useState<number>(0);
  const formCreateAccountMethod = useForm<IProjectStakeholder>({
    mode: 'all',
    resolver: yupResolver(FormCreateAccount)
  });
  const { data: roleCompany } = useAppSelector(getUserRoleCompany);
  const { isDirty } = formCreateAccountMethod.formState;
  const getChildrenPath = (index: number, field: string) => `${PATHS.PROJECT_STAKEHOLDERS}[${index}].${field}`;

  const addNewProjectStakeholders = () => {
    if (projectStakeholders.length >= MAX_FIELD_STAKE_HOLDER) return;
    const guid = uuid();
    append({ stakeholderEmail: '', canDelete: true, uid: guid, formId: guid });
  };
  const watchFields = (fields: any[]) => {
    const watchFields = form.watch(fields);
    const watchFieldsValid = watchFields.filter((field: any) => field);
    return watchFieldsValid.length;
  };

  const handleRemove = (index: number) => {
    if (projectStakeholders.length > 1) {
      remove(index);
    } else {
      const guid = uuid();
      form.setValue(PATHS.PROJECT_STAKEHOLDERS, [
        {
          stakeholderEmail: '',
          canDelete: true,
          uid: guid,
          formId: guid
        }
      ]);
    }
  };

  const handleBlur = async (index: number) => {
    const account = form.getValues(`${PATHS.PROJECT_STAKEHOLDERS}.${index}`);
    const email = account.stakeholderEmail;
    form.trigger(`${PATHS.PROJECT_STAKEHOLDERS}.${index}.stakeholderEmail`);
    if (isEmail(email)) {
      await validateAccount(email, index);
    }
  };
  const validateAccount = async (email: string, index: number) => {
    const params = {
      emails: [email],
      companyId: user?.organizationId || ''
    };
    try {
      const res = await validationEmail(params);
      if (res?.data?.length) {
        const account = res?.data[0];
        form.setValue(getChildrenPath(index, 'stakeholderName'), account.name);
        form.setValue(getChildrenPath(index, 'rejected'), account.rejected);
        form.setValue(getChildrenPath(index, 'waitCreateAccount'), account.waitingCreate);
        form.setValue(getChildrenPath(index, 'stakeholderCompanyName'), account.companyName);
        form.setValue(getChildrenPath(index, 'roleName'), account.role);
      }
    } catch (error) {}
  };
  const isEmail = (email: string) => {
    return email && EMAIL_REGEX.test(email);
  };
  const handleCloseCreateModal = async () => {
    if (isDirty) {
      const showPopup = await showBasePopup({
        title: String(t('dialog:cancel_editing:header')),
        msg: String(t('dialog:cancel_editing:content')),
        type: TYPE_ICON.DISCARD
      });
      if (showPopup !== TYPE_ICON.CONFIRM) return;
      formCreateAccountMethod.reset();
      setShowPopupCreateAccount(false);
    } else {
      setShowPopupCreateAccount(false);
    }
  };
  const openPopupCreateAccount = (index: number) => {
    const emailCreate = form.getValues(getChildrenPath(index, 'stakeholderEmail'));
    formCreateAccountMethod.setValue('stakeholderName', '');
    formCreateAccountMethod.setValue('stakeholderEmail', emailCreate);
    const roleExternalUser = roleCompany.find((item: any) => item.type === EXTERNAL_GENERAL_USER);
    formCreateAccountMethod.setValue('role', isExternalAdmin ? null : roleExternalUser);
    setDisabledRole(!isExternalAdmin);
    setTimeout(() => formCreateAccountMethod.reset((pre) => ({ ...pre, isDirty: false })), 0);
    setShowPopupCreateAccount(true);
    setIndexAccount(index);
  };
  const handleSubmitAccount = () => {
    formCreateAccountMethod.handleSubmit(isExternalAdmin ? createAccount : requestAccount)();
  };
  const createAccount: SubmitHandler<IProjectStakeholder> = async (formData) => {
    const payload = {
      email: formData.stakeholderEmail,
      upn: formData.stakeholderEmail,
      name: formData.stakeholderName,
      organizationId: user?.organizationId,
      roleId: formData.role?.value,
      status: true
    };
    try {
      setLoading(true);
      const { data } = await createUser(payload);
      setShowPopupCreateAccount(false);
      const guid = uuid();
      update(indexAccount, {
        ...form.getValues(`${PATHS.PROJECT_STAKEHOLDERS}.${indexAccount}`),
        stakeholderEmail: data.email,
        stakeholderName: data.name,
        stakeholderCompanyId: user?.organizationId,
        stakeholderCompanyName: user?.organizationName,
        roleName: data.roleName,
        rejected: false,
        waitCreateAccount: false,
        uid: guid,
        formId: guid
      });
      form.clearErrors(getChildrenPath(indexAccount, 'stakeholderEmail'));
    } catch (error: any) {
      if (error?.response) {
        const {
          data: { fields }
        } = error.response;
        if (fields[0].errorCode) {
          const errorMapping = {
            [ERROR_CODE.USER_WAITING_CREATE]: String(t('common:MSG_P_032')),
            [ERROR_CODE.USER_RESTRICT]: String(t('common:MSG_P_033')),
            [ERROR_CODE.FIELD_NOT_FOUND]: String(t('common:MSG_P_033')),
            [ERROR_CODE.USER_INACTIVE]: String(t('common:MSG_P_034')),
            [ERROR_EMAIL_DUPLICATE]: String(t('common:MSG_P_022'))
          };
          const message = errorMapping[fields[0].errorCode] ?? String(t('common:MSG_C_014'));
          formCreateAccountMethod.setError('stakeholderEmail', { type: 'accountInvalid', message });
        }
      }
    } finally {
      setLoading(false);
    }
  };
  const requestAccount: SubmitHandler<IProjectStakeholder> = async (formData) => {
    const payload = {
      projectId: id,
      email: formData.stakeholderEmail,
      name: formData.stakeholderName,
      organizationId: user?.organizationId,
      roleId: formData.role?.value,
      urlRedirect: '/accounts-master/edit-pending'
    };
    try {
      setLoading(true);
      const { data } = await requestUser(payload);
      setShowPopupCreateAccount(false);
      const guid = uuid();
      update(indexAccount, {
        ...form.getValues(`${PATHS.PROJECT_STAKEHOLDERS}.${indexAccount}`),
        stakeholderEmail: data.email,
        stakeholderName: data.name,
        stakeholderCompanyId: user?.organizationId,
        stakeholderCompanyName: user?.organizationName,
        roleName: data.roleName,
        rejected: false,
        waitCreateAccount: true,
        canDelete: false,
        uid: guid,
        formId: guid
      });
      form.clearErrors(getChildrenPath(indexAccount, 'stakeholderEmail'));
    } catch (error: any) {
      if (error?.response) {
        const {
          data: { fields }
        } = error.response;
        if (fields[0].errorCode) {
          const errorMapping = {
            [ERROR_CODE.USER_WAITING_CREATE]: 'common:MSG_P_032',
            [ERROR_CODE.USER_RESTRICT]: String(t('common:MSG_P_033')),
            [ERROR_CODE.FIELD_NOT_FOUND]: String(t('common:MSG_P_033')),
            [ERROR_CODE.USER_INACTIVE]: String(t('common:MSG_P_034')),
            [ERROR_EMAIL_DUPLICATE]: String(t('common:MSG_P_022'))
          };
          const message = errorMapping[fields[0].errorCode] ?? String(t('common:MSG_C_014'));
          formCreateAccountMethod.setError('stakeholderEmail', { type: 'accountInvalid', message });
        }
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className='mt-5'>
      <p className='title-20 text-black'>{t('stakeholder:title')}</p>
      <p className='subtitle-18 text-black mt-3'>
        {t('stakeholder:question')}
        <span className='text-textError'>{REQUIRED_DOT}</span>
      </p>
      <FormItem name={PATHS.HAS_OTHER_STAKE_HOLDER}>
        <BaseRadioGroups
          className='mt-3'
          isVertical
          options={[
            {
              label: t('project:add:family_information:yes'),
              value: HAS_OTHER_STAKE_HOLDER.YES
            },
            {
              label: t('project:add:family_information:no'),
              value: HAS_OTHER_STAKE_HOLDER.NO
            }
          ]}
          onChange={(value: any) => {
            if (value === HAS_OTHER_STAKE_HOLDER.NO) {
              form.setValue(PATHS.PROJECT_STAKEHOLDERS, []);
              form.clearErrors(PATHS.PROJECT_STAKEHOLDERS);
            } else if (value === HAS_OTHER_STAKE_HOLDER.YES && !projectStakeholders?.length) addNewProjectStakeholders();
          }}
        />
      </FormItem>
      {form.watch(PATHS.HAS_OTHER_STAKE_HOLDER) === HAS_OTHER_STAKE_HOLDER.YES && (
        <>
          <p className='subtitle-18 text-black my-3'>{t('project:scenario:involved_members_guide')}</p>
          <div className='flex items-center mt-[8px] mb-[12px]'>
            <InfoIcon className='block mr-[4px]' width={18} height={18} />
            <span className='body-400'>{t('project:scenario:note_members_guide')}</span>
          </div>

          {projectStakeholders.map((field: any, index: number) => {
            if ((!field.canDelete && field?.waitCreateAccount) || (field.id && field.rejected))
              return <StakeHolderCard key={field.id || field.uid || field.formId} field={field} index={index} handleRemove={handleRemove} />;
            const errorInput = get(form.formState.errors, `${paths.parentPath}.${paths.stakeHolderPath}.${index}`) as any;
            return (
              <StakeHolderInput
                companyId={user?.organizationId || ''}
                pathEmail={`${PATHS.PROJECT_STAKEHOLDERS}.${index}.stakeholderEmail`}
                key={field.id || field.uid || field.formId}
                field={field}
                index={index}
                errorInput={errorInput}
                questions={watchFields([`${PATHS.PROJECT_STAKEHOLDERS}.${index}.stakeholderEmail`])}
                showIconRemove={projectStakeholders.length > 1}
                handleRemove={handleRemove}
                handleBlur={handleBlur}
                openPopupCreateAccount={openPopupCreateAccount}
              />
            );
          })}
          <Space size={16}>
            <div className='min-w-[140px] mt-3'>
              <BaseButton
                icon={<PlusIcon />}
                disabled={projectStakeholders.length === MAX_FIELD_STAKE_HOLDER}
                type='secondary'
                block
                size='medium'
                onClick={addNewProjectStakeholders}
              >
                {t('project:add:project_stakeholders:add_stakeholders')}
              </BaseButton>
            </div>
          </Space>
        </>
      )}
      <div ref={wrapperRef}>
        <BaseModal
          getContainer={wrapperRef.current}
          title={
            <span className='title-24'>
              {isExternalAdmin ? t('template_layout:template_create') : t('project:add:project_stakeholders:request_create_title')}
            </span>
          }
          onCancel={handleCloseCreateModal}
          maskClosable={false}
          openModal={showPopupCreateAccount}
          destroyOnClose
          contentElement={
            <FormProvider {...formCreateAccountMethod}>
              <Row gutter={16} className='gap-4'>
                <Col span={24}>
                  <FormInput
                    required={true}
                    defaultValue={formCreateAccountMethod.getValues('stakeholderName')}
                    name='stakeholderName'
                    labelTx='basic_information:username'
                    placeholderTx={String(t('placeholder:text_box', { field: t('basic_information:username') }))}
                  />
                </Col>
                <Col span={24}>
                  <FormInput
                    required={true}
                    defaultValue={formCreateAccountMethod.getValues('stakeholderEmail')}
                    name='stakeholderEmail'
                    labelTx='basic_information:email_address'
                    placeholderTx={String(t('placeholder:text_box', { field: t('basic_information:email_address') }))}
                  />
                </Col>
                <Col span={24}>
                  <FormSelect
                    disabled
                    required={true}
                    name=''
                    value={user?.organizationId || ''}
                    label={String(t('basic_information:company_name'))}
                    placeholder={String(t('placeholder:select', { field: t('basic_information:company_name') }))}
                    options={companyOptions}
                  />
                </Col>
                <Col span={24}>
                  <FormSelect
                    labelInValue
                    required={true}
                    disabled={disabledRole}
                    name='role'
                    label={String(t('account_list:role'))}
                    placeholder={String(t('placeholder:select', { field: t('account_list:role') }))}
                    options={roleCompany}
                  />
                </Col>
                <Col span={24} className='flex justify-end gap-4'>
                  <BaseButton type='tertiary' size='medium' className='min-w-[120px]' onClick={handleCloseCreateModal}>
                    <span> {t('button:cancel')} </span>
                  </BaseButton>
                  <BaseButton disabled={loading} size='medium' onClick={handleSubmitAccount}>
                    <span> {isExternalAdmin ? t('button:create') : t('common:button:send')} </span>
                  </BaseButton>
                </Col>
              </Row>
            </FormProvider>
          }
        ></BaseModal>
      </div>
    </div>
  );
}
