import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import AppTooltip from '../../../../../components/app-tooltip/AppTooltip';
import { ITitleButtonSecurityProps } from '../../../../../utils/interfaces/security';

import './TitleButton.scss';

interface ITitleButtonProps {
  edit?: boolean;
  create?: boolean;
  children: React.ReactNode;
  title?: string;
  // callback: () => void;
  titleButtonSecurityProps?: ITitleButtonSecurityProps;
}

const TitleButton = ({ edit = true, create = true, children, title, titleButtonSecurityProps = { caretLeftClickAble: true } }: ITitleButtonProps) => {
  const { t } = useTranslation();

  const titleClassNames = () => {
    if (create) return 'h1-create-new max-w-[calc(100%-340px)]';
    return edit ? 'max-w-[calc(100%-510px)] ' : ' max-w-[calc(100%-340px)]';
  };
  return (
    <div className={classNames('flex justify-between', create ? 'container-title' : 'title-button container-title')}>
      <AppTooltip title={title === t('template_layout:template_default') ? '' : title}>
        <h1 className={classNames(titleClassNames(), 'title-24 truncate')}>{title}</h1>
      </AppTooltip>

      <div className='flex gap-4'>{children}</div>
    </div>
  );
};

export default TitleButton;
