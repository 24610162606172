import { Progress, Tag } from 'antd';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { STATUS_NAME, TASK_TYPE } from '@/pages/project-management/detail/constant';
import { IProcess, ITask, ITaskFormFilter } from '@/pages/project-management/detail/utils';
import { getStatusTask, isComplete, isPending, renderDataTask, renderTaskStatus } from '@/pages/project-management/detail/utils/helper';
import { PROCESS_TRAVEL_NAME } from '@pages/project-management/project-list-v2/models';

import { BaseButton } from '@/components/base-button/BaseButton';
import BaseTable from '@/components/table/BaseTable';

import useAuthorization from '@/hooks/useAuthorization';
import useFetch from '@/hooks/useFetch';

import { ADD_TASK_OF_PROJECT_URL } from '@/utils/constants/RouteContants';
import { DataInput, DataViewer } from '@/utils/helpers/common';
import { Ii18n } from '@/utils/interfaces/i18n';

import InforIcon from '@/assets/icons/ic_info.svg';

import RenderTagTaskList from './RenderTagTaskFilter';
import TaskFilter from './TaskFilter';
import TaskTable from './TaskTable';

interface ITaskListProps {
  listProjectProcess: IProcess[];
  fetchData?: () => void;
}

const TaskList = ({ listProjectProcess, fetchData }: ITaskListProps) => {
  const { t }: Ii18n = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { isInternalRole } = useAuthorization();
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const { data } = useFetch<any>(`/prj/projectTask/${id}/tasks/assigned`, 'GET');

  const formFilter = useForm<ITaskFormFilter>({
    defaultValues: {
      category: null,
      status: null,
      deadlineDateFrom: null,
      deadlineDateTo: null,
      picIds: null
    }
  });

  const formValues = formFilter.watch();

  const primaryValues = formFilter.watch(['category', 'status', 'deadlineDateFrom', 'deadlineDateTo', 'picIds']);

  const isTravelProcess = (item: IProcess) => item.processName === PROCESS_TRAVEL_NAME;

  const totalFiltered = () => {
    let counter = 0;
    primaryValues.forEach((value, index) => {
      if (index === primaryValues.length - 1 && !value?.length) return;
      if (!!value) counter++;
    });
    return counter || 0;
  };

  const filterTask = (tasks: ITask[], filter: ITaskFormFilter) => {
    return tasks.filter((task) => {
      const convertDeadlineDate = DataInput.formatTime(task.deadline);
      if (!isInternalRole && task.taskType === TASK_TYPE.TODO) {
        return false;
      }
      if (filter.category && filter.category !== task.taskType) {
        return false;
      }
      if (filter.status && filter.status !== task.statusName) {
        return false;
      }
      if (filter.deadlineDateFrom && convertDeadlineDate && convertDeadlineDate < filter.deadlineDateFrom) {
        return false;
      }
      if (filter.deadlineDateTo && convertDeadlineDate && convertDeadlineDate > filter.deadlineDateTo) {
        return false;
      }
      if ((filter.deadlineDateFrom || filter.deadlineDateTo) && !convertDeadlineDate) {
        return false;
      }
      if (filter?.picIds?.length) {
        if (task?.picIds?.length) {
          const picIdsFilter = filter.picIds;
          const picIdsTask = task.picIds;
          return picIdsTask.some((item) => picIdsFilter.includes(item));
        } else return false;
      }
      return true;
    });
  };

  const convertListTask = (listTask: ITask[]) => {
    for (const item of listTask) {
      if (!item?.pics?.length) continue;
      let picName = '';
      let picIds = [];
      for (const key in item.pics) {
        const pic = item.pics[key];
        if (isNil(pic?.picStatus)) continue;
        picIds.push(pic.picId);
        if (pic.picStatus) picName += pic.picName;
        else picName += `(${t('account_list:status_account:inactive')}) ${pic.picName}`;

        if (Number(key) !== item.pics.length - 1) picName += ', ';
      }

      item.picName = picName;
      item.picIds = picIds;
    }

    return listTask;
  };

  const listProcess = useMemo(() => {
    const newList = listProjectProcess.map((item: IProcess) => {
      const filteredTasks = filterTask(convertListTask(item.projectTasks), formValues);
      // Sort task: task has no deadline at the end, task has deadline sort asc
      const filteredTasksSort = filteredTasks.sort((a, b) => {
        if (!a.deadline || !dayjs(a.deadline).isValid()) {
          return 1;
        }
        if (!b.deadline || !dayjs(b.deadline).isValid()) {
          return -1;
        }
        return dayjs(a.deadline).diff(b.deadline);
      });
      return {
        ...item,
        rowKey: item.id,
        projectTasks: filteredTasksSort,
        summaryData: renderDataTask(filteredTasks)
      };
    });

    return !!totalFiltered() ? newList.filter((item) => item.projectTasks.length) : newList;
  }, [formValues]);

  const overviewAllTask = useMemo(() => {
    const listAllTask = listProcess.map((item: IProcess) => item.projectTasks).flat();
    const listTaskCompleted = listAllTask.filter(isComplete);
    const includeTaskDelay = !!listAllTask.find(isPending);
    return {
      totalTaskCompleted: listTaskCompleted.length,
      totalTask: listAllTask.length,
      includeTaskDelay
    };
  }, [listProcess]);

  const picOptions = useMemo(() => {
    return data?.map((item: { picId: string; name: string; status: boolean }) => ({
      label: item.status ? item.name : `(${t('account_list:status_account:inactive')}) ${item.name}`,
      value: item.picId
    }));
  }, [data]);

  const TagDate = (props: { date?: string }) => {
    const { date } = props;
    return (
      <div className='px-[16px] py-[8px] bg-gray1 border-[1px] border-solid border-gray2 rounded-[8px] text-14 text-textGray min-w-[109px] text-center'>
        {DataViewer.displayTime(date)}
      </div>
    );
  };

  const setFilterTaskDelay = () => {
    formFilter.setValue('status', STATUS_NAME.PENDING);
  };

  const handleOnExpand = (expanded: boolean, record: IProcess) => {
    if (!record.projectTasks.length && expanded) return;
    const newExpanded = [...expandedKeys];
    if (expanded && record.rowKey) {
      newExpanded.push(record.rowKey);
    } else {
      const matchIndex = newExpanded.findIndex((key) => key === record.rowKey);
      if (matchIndex !== -1) newExpanded.splice(matchIndex, 1);
    }
    setExpandedKeys(newExpanded);
  };

  const handleRowClass = (record: IProcess, index: number) => {
    let className: string = `process-row-${index}`;

    if (!record.projectTasks.length || isTravelProcess(record)) {
      className += ' process-row-disable';
    }
    return className;
  };

  const toggleProcessByName = (record: IProcess) => {
    if (!record.rowKey) {
      return;
    }
    if (expandedKeys.includes(record.rowKey)) {
      setExpandedKeys(expandedKeys.filter((key) => key !== record.rowKey));
      return;
    }
    setExpandedKeys([...expandedKeys, record.rowKey]);
  };

  const generateProcessColumns = () => {
    return [
      {
        width: 'calc(100%)',
        render: (_: string, record: IProcess) => {
          const summaryData = record.summaryData;
          const listTaskTodo = summaryData.groups[TASK_TYPE.TODO] ?? [];
          const listTaskMOT = summaryData.groups[TASK_TYPE.MOT] ?? [];
          const listTaskOther = summaryData.groups[TASK_TYPE.OTHER] ?? [];
          const listTaskTodoCompleted = listTaskTodo.filter(isComplete);
          const listTaskMOTCompleted = listTaskMOT.filter(isComplete);
          const listTaskOtherCompleted = listTaskOther.filter(isComplete);
          const listTask = record.projectTasks;

          const isTravel = isTravelProcess(record);
          const taskTags = () => {
            if (isTravel) {
              return null;
            }
            return isInternalRole ? (
              <>
                {!!listTaskTodo.length && (
                  <Tag color='#DBDBE0' className='text-[14px]' style={{ color: '#12212E', fontWeight: 700 }}>
                    TODO: {listTaskTodoCompleted.length}/{listTaskTodo.length}
                  </Tag>
                )}
                {!!(listTaskMOT.length + listTaskOther.length) && (
                  <Tag color='#DBDBE0' className='text-[14px] ' style={{ color: '#12212E', fontWeight: 700 }}>
                    {t('template_layout:other_category')}, MOT: {listTaskMOTCompleted.length + listTaskOtherCompleted.length}/
                    {listTaskMOT.length + listTaskOther.length}
                  </Tag>
                )}
              </>
            ) : (
              !!listTask.length && (
                <Tag color='#DBDBE0' className='text-[14px]' style={{ color: '#12212E', fontWeight: 700 }}>
                  {listTaskTodoCompleted.length + listTaskMOTCompleted.length + listTaskOtherCompleted.length}/{listTask.length}
                </Tag>
              )
            );
          };

          return (
            <div className='flex justify-between py-[2px]'>
              <div className='flex items-center'>
                <button
                  className='mr-[8px] !p-0 border-none bg-[transparent] cursor-pointer text-lnk font-bold'
                  onClick={() => toggleProcessByName(record)}
                >
                  {record?.processName ?? ''}
                </button>
                {taskTags()}
              </div>
              {!isTravel && (
                <div className='flex items-center gap-[32px] font-medium'>
                  <div className='flex items-center gap-[8px]'>
                    <span>{t('project_detail:task_list:completed_date_plan')}</span>
                    <span>
                      <TagDate date={summaryData.maxDeadlineDate} />
                    </span>
                  </div>
                  <div className='flex items-center gap-[8px]'>
                    <span>{t('project_detail:task_list:start_date')}</span>
                    <span>
                      <TagDate date={summaryData.minStartDate} />
                    </span>
                  </div>
                  <div className='flex items-center gap-[8px]'>
                    <span>{t('project_detail:task_list:completed_date_actual')}</span>
                    <span>
                      <TagDate date={summaryData.maxCompletedDate} />
                    </span>
                  </div>
                  {record.statusName ? (
                    <div>{renderTaskStatus(record.statusName)}</div>
                  ) : (
                    <div className='w-[80px] flex justify-center items-center text-textGray border-gray2 bg-gray1 text-[14px] font-medium border-[1px] border-solid rounded-[4px]'>
                      --
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        }
      }
    ];
  };

  useEffect(() => {
    const newExpanded = [...expandedKeys];
    const isCheckAllTaskType = true;
    // Filter remove process '渡航' from expandedKeys
    listProjectProcess
      .filter((process) => !isTravelProcess(process))
      .forEach((item: IProcess) => {
        const filteredTasksByRole = isInternalRole ? item.projectTasks : item.projectTasks.filter((item) => item.taskType !== TASK_TYPE.TODO);
        const status = getStatusTask(filteredTasksByRole, isCheckAllTaskType);
        if ([STATUS_NAME.PENDING.toString(), STATUS_NAME.IN_PROGRESS.toString()].includes(status)) {
          newExpanded.push(item.id);
        }
      });
    setExpandedKeys(newExpanded);
  }, [listProjectProcess]);

  useEffect(() => {
    if (!!totalFiltered()) {
      const listAllKey = listProcess.map((item) => item.id);
      setExpandedKeys(listAllKey);
    }
  }, [formValues]);

  return (
    <FormProvider {...formFilter}>
      <div className='task-list'>
        <div className='flex items-center justify-between'>
          <div className='flex items-center'>
            <div className='text-20 font-bold mr-[12px]'>{t('project_detail:task_list:title')}</div>
            {!!overviewAllTask.totalTask && !totalFiltered() && (
              <>
                <div className='text-16 font-semibold mr-[8px]'>
                  {overviewAllTask.totalTaskCompleted}/{overviewAllTask.totalTask} {t('project_detail:task_list:completion')}
                </div>
                <Progress
                  className='m-0 w-[208px]'
                  percent={(overviewAllTask.totalTaskCompleted / overviewAllTask.totalTask) * 100}
                  strokeColor='#36B368'
                  showInfo={false}
                />
              </>
            )}
          </div>
          {!!listProjectProcess.length && (
            <div className='flex items-center gap-[16px]'>
              <TaskFilter formFilter={formFilter} totalFiltered={totalFiltered()} picOptions={picOptions} />
              {isInternalRole && (
                <BaseButton type='primary' size='medium' onClick={() => navigate(ADD_TASK_OF_PROJECT_URL(id ?? ''))}>
                  <span> {t('project_detail:buttons:add_task')} </span>
                </BaseButton>
              )}
            </div>
          )}
        </div>
        {overviewAllTask.includeTaskDelay && !totalFiltered() && (
          <div className='warning-delay'>
            <span className='icon-infor'>
              <InforIcon />
            </span>
            <span className='task-overdue'>
              {t('project_detail:task_list:task_overdue')}{' '}
              <span className='list-task-delay' onClick={setFilterTaskDelay}>
                {t('project_detail:task_list:list_task_delay')}
              </span>
            </span>
          </div>
        )}
        <RenderTagTaskList form={formFilter} picOptions={picOptions} formValues={formValues} totalFiltered={totalFiltered()} />
        <BaseTable
          rowKey={'rowKey'}
          columns={generateProcessColumns()}
          dataSource={listProcess}
          className='text-[#12212E] font-semibold task-list-table'
          pagination={false}
          showHeader={false}
          rowClassName={(record, index) => handleRowClass(record, index)}
          expandable={{
            expandedRowKeys: expandedKeys,
            onExpand: handleOnExpand,
            expandedRowRender: (record) => {
              return <TaskTable listTask={record?.projectTasks} fetchData={fetchData} />;
            }
          }}
        />
      </div>
    </FormProvider>
  );
};

export default TaskList;
