import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AppTooltip from '@/components/app-tooltip/AppTooltip';
import FileAttachment, { FILE_ATTACHMENT_STATUS } from '@components/form-attachment/FileAttachment';

import { FORMAT_DATE_EN_HH_MM } from '@/utils/constants/AppConstants';
import { DataViewer } from '@/utils/helpers/common';
import { handleDownloadSingleFile } from '@/utils/helpers/fileHelper';

import { IComment, IFile } from '../models';
import EditButton from './EditButton';

import '../styles/CommentBox.scss';

export const COMMENT_TYPE = {
  OTHER: 'other',
  ME: 'me'
} as const;
export type ICommentType = (typeof COMMENT_TYPE)[keyof typeof COMMENT_TYPE];

export interface ICommentProps {
  comment: IComment;
  onClickEditBtn: (comment: IComment) => void;
  msgErr?: string;
}
const CommentBox = (props: ICommentProps) => {
  const { comment, msgErr, onClickEditBtn } = props;
  const { t } = useTranslation();
  const [embedImage, setEmbedImage] = useState(false);
  const files = comment?.attachments ?? [];

  useEffect(() => {
    if (!comment.content) return;
    setEmbedImage(comment.content.includes('embed-attachment-container'));
  }, [comment]);

  const contentLength = DataViewer.isEmptyAfterTrim(comment?.content);
  const createdName = comment.updatedStatus ? comment?.createdName : `(${t('account_list:status_account:inactive')}) ${comment?.createdName}`;

  const commentNoFile = (!!contentLength || embedImage) && files.length;
  const commentOnlyText = (!!contentLength || embedImage) && !files.length;

  const onDownloadFile = (file: Partial<IFile>) => {
    return handleDownloadSingleFile(file?.blobPath ?? '', file?.name ?? '');
  };

  return (
    <div
      id={comment.id ?? ''}
      className={classNames('comment-box group', comment?.type === COMMENT_TYPE.ME ? 'comment-box--right' : 'comment-box--left')}
    >
      <div className='comment-box__header'>
        <div className='comment-box__user-name text-textGray text-12'>
          {comment?.createdName.length > 5 ? (
            <AppTooltip className='text-textGray text-12' title={createdName}>
              {!comment.updatedStatus && <span className='text-12'>({t('account_list:status_account:inactive')}) </span>}
              {DataViewer.display(comment?.createdName.slice(0, 5))}...
            </AppTooltip>
          ) : (
            <>
              {!comment.updatedStatus && <span className='text-12'>({t('account_list:status_account:inactive')}) </span>}
              {DataViewer.display(comment?.createdName)}
            </>
          )}
        </div>
        {comment?.edited && (
          <div className='comment-box__edited text-12 body-400 text-textGray mr-[8px] shrink-0'>{t('widgets:comment_function:comment_edited')}</div>
        )}
        <div className='comment-box__created-date'>{DataViewer.localTimeBy(comment?.updatedDate, FORMAT_DATE_EN_HH_MM)}</div>
      </div>
      <div className={classNames('comment-box__content')}>
        {comment?.type === COMMENT_TYPE.ME && (
          <div className='comment-box__edit-button mt-auto h-[29px] w-[40px]'>
            <EditButton onClick={() => onClickEditBtn(comment)} />
          </div>
        )}
        <div
          className={classNames(
            'rounded-[8px]',
            commentNoFile && (comment?.type === COMMENT_TYPE.ME ? 'bg-green10' : 'bg-gray1'),
            commentNoFile && 'p-[8px]'
          )}
        >
          {(!!contentLength || embedImage) && (
            <div
              className={classNames(
                commentOnlyText && 'p-[8px]',
                commentOnlyText && (comment?.type === COMMENT_TYPE.ME ? 'bg-green10' : 'bg-gray1'),
                'rounded-[8px] break-all w-fit text'
              )}
              dangerouslySetInnerHTML={{ __html: DataViewer.displayAsSanitizeHTML(comment?.content, ' ') }}
            />
          )}

          {files.map((file: Partial<IFile>) => (
            <div key={file.id} className={classNames('comment-box__attachment mb-[4px] [&:last-child]:mb-0', commentNoFile && 'mt-[10px]')}>
              <FileAttachment
                className=' bg-white'
                label={file?.name ?? ''}
                status={FILE_ATTACHMENT_STATUS.VIEW}
                onDownload={() => onDownloadFile(file)}
                msgErr={msgErr}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CommentBox;
