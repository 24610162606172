import CaretDown from '@/layouts/assets/icons/CaretDown.svg';
import { Collapse, CollapseProps, Popover } from 'antd';
import { CollapsibleType } from 'antd/es/collapse/CollapsePanel';
import React, { forwardRef, isValidElement, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AppTooltip from '@/components/app-tooltip/AppTooltip';
import { BaseButton } from '@/components/base-button/BaseButton';

import { DataViewer } from '@/utils/helpers/common';

import './SelectBox.scss';

export interface IModuleSelectionProps {
  label?: string;
  options?: IModuleToolbarOption[];
  msgNoData?: React.ReactElement | string;
  onSelect?: (item: IModuleOptionChild) => void;
}

export interface IModuleOptionChild {
  id: string;
  title: string;
  content: string;
}
export interface IModuleToolbarOption {
  key: string | number;
  collapsible: CollapsibleType | undefined;
  name: JSX.Element | string;
  children: IModuleOptionChild[] | React.ReactElement;
}
interface IOptRender extends Omit<IModuleToolbarOption, 'children' | 'name'> {
  children: JSX.Element;
  label: JSX.Element;
}

const renderExpandIcon = (isActive: boolean | undefined) => {
  return <CaretDown className={`${isActive ? 'rotate-360' : 'rotate-[270deg]'}`} rotate={isActive ? 180 : -90} />;
};

interface IWrapCollapseProps {
  items: CollapseProps['items'];
  msgNoData?: React.ReactElement | string;
}
const WrapCollapse = forwardRef(({ items, msgNoData = <p className='text-center w-full'>NoData</p> }: IWrapCollapseProps, ref) => {
  const [activeKey, setActiveKey] = useState<string[] | string>([]);

  useImperativeHandle(ref, () => ({
    setActiveKey: () => setActiveKey([])
  }));

  const renderNodata = () => {
    if (items?.length) return null;
    if (isValidElement(msgNoData)) return msgNoData;
    return <p className='text-center '>{msgNoData}</p>;
  };
  return (
    <div className='w-[250px] quill-toolbar-module-selection__popover-content max-h-[290px] overflow-y-auto'>
      <Collapse
        size='small'
        items={items}
        expandIcon={({ isActive }) => renderExpandIcon(isActive)}
        bordered={false}
        accordion
        ghost
        activeKey={activeKey}
        onChange={(key) => setActiveKey(key)}
        destroyInactivePanel
        className='bg-white'
      />
      {renderNodata()}
    </div>
  );
});

/**
 * @required wrapper with position relative | absolute | fixed
 */
const QuillToolbarModuleSelection = ({ options, msgNoData, label = 'announcement:add:content:button', onSelect }: IModuleSelectionProps) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const collapseRef = useRef<any>();

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
    !open && collapseRef?.current?.setActiveKey();
  };

  const onSelectItem = (item: IModuleOptionChild) => {
    onSelect?.(item);
    setOpen(false);
  };

  const generateItems = () => {
    if (!options) return [];
    return options.map((item: any, index: number) => {
      const render: IOptRender = {
        key: index,
        collapsible: 'header',
        label: (
          <AppTooltip title={item.name}>
            <div className='truncate'>{item.name}</div>
          </AppTooltip>
        ),
        children: <></>
      };
      if (isValidElement(item.children)) {
        render.children = item.children;
      } else if (item.children) {
        render.children = (
          <>
            {item.children.map((item: any) => (
              <p
                key={item.id}
                className='ellipsis p-1 quill-toolbar-module-selection__popover-item break-all cursor-pointer'
                onClick={() => onSelectItem(item)}
              >
                <AppTooltip title={item?.content && <span dangerouslySetInnerHTML={{ __html: DataViewer.displayAsSanitizeHTML(item?.content) }} />}>
                  {item.title}
                </AppTooltip>
              </p>
            ))}
          </>
        );
      }
      return render;
    });
  };

  return (
    <div className='absolute top-[2px] right-[12px]'>
      <Popover
        trigger='click'
        placement='bottomRight'
        content={<WrapCollapse items={generateItems()} msgNoData={msgNoData} ref={collapseRef} />}
        overlayInnerStyle={{ padding: 0 }}
        rootClassName='quill-toolbar-module-selection'
        open={open}
        onOpenChange={handleOpenChange}
      >
        <BaseButton
          type='secondary'
          className='border !w-[100px] !h-[38px] !border-gray3 !text-lnk font-semibold text-base leading-relaxed box-border !min-w-[100px]'
        >
          {t(label)}
        </BaseButton>
      </Popover>
    </div>
  );
};

export default QuillToolbarModuleSelection;
