export const MAXIMUM_FILE_UPLOAD = 10;
export const MAXIMUM_SIZE_UPLOAD = 30;
export const MAXIMUM_SIZE_UPLOAD_TO_BYTE = MAXIMUM_SIZE_UPLOAD * 1024 * 1024;

// Account master list
export const ANNOUNCEMENT_LIST = 'ANNOUNCEMENT_LIST';
export const SEARCH_ANNOUNCEMENT_LIST = 'SEARCH';
export const TABLE_ANNOUNCEMENT_LIST = 'TABLE';
export const CREATE_NEW_ANNOUNCEMENT_LIST = 'CREATE_NEW_ANNOUNCEMENT';
export const EDIT_ANNOUNCEMENT = 'EDIT_ANNOUNCEMENT';
export const VIEW_ANNOUNCEMENT = 'VIEW_ANNOUNCEMENT';
export const DELETE_ANNOUNCEMENT_ATTACHMENT = 'DELETE_ANNOUNCEMENT_ATTACHMENT';
export const APPOVED_REJECT_ANNOUNCEMENT_ATTACHMENT = 'APPOVED_REJECT_ANNOUNCEMENT_ATTACHMENT';

// Account master list => Table
export const PAGING_TABLE_ANNOUNCEMENT = 'PAGING_TABLE_ANNOUNCEMENT';

// Account master filter
export const ANNOUNCEMENT_REQUEST_FILTER_COMPANY = 'ANNOUNCEMENT_REQUEST_FILTER_COMPANY';
