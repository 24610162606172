import { axiosInstance } from '../../config/http';
import { RouterIdType } from '../constants';
import { API } from '../constants/Apis';
import { IFilterConfigs } from '../interfaces';

export const getUsers = async (
  params: any = {
    PageSize: 100,
    PageIndex: 0,
    Sorts: 'CREATEDDATE=DESC',
    filter: ''
  }
) => {
  const res = await axiosInstance.post(`/usr/users/search`, params);
  return res;
};

export const getRoles = async () => {
  const res = await axiosInstance.get(`/usr/roles`);
  return res;
};
export const getTravelTypes = async () => {
  const res = await axiosInstance.get(API.GET_TRAVEL_TYPE_OPTIONS);
  return res;
};
export const getUsersById = async (id: any) => {
  const res = await axiosInstance.get(`/usr/users/${id}`);
  return res;
};

export const getUsersByEmail = async (email: string, companyId?: string) => {
  const res = await axiosInstance.get(`/usr/users/email/${email}?companyId=${companyId}`);
  return res;
};

export const createUser = async (body: any) => {
  const res = await axiosInstance.post(`/usr/users`, body);
  return res;
};
export const requestUser = async (body: any) => {
  const res = await axiosInstance.post(`/usr/users/request`, body);
  return res;
};

export const updateUser = async (body: any) => {
  const res = await axiosInstance.put(`/usr/users`, body);
  return res;
};

export const getUsersMotPic = async (isShowInactive: boolean = false) => {
  const res = await axiosInstance.get(`/usr/Users/mot-pic?includeInactive=${isShowInactive}`);
  return res;
};

export const getCompanies = async () => {
  const res = await axiosInstance.get(`/usr/organizations`);
  return res;
};

// Announcements
export const getAnnouncementsById = async (id: string) => {
  const res = await axiosInstance.get(`/usr/Announcements/${id}`);
  return res;
};

export const getAnnouncements = async (params: any) => {
  const res = await axiosInstance.post(`/usr/Announcements/search`, params);
  return res;
};

export const createAnnouncements = async (params: any) => {
  const res = await axiosInstance.post(`/usr/Announcements`, params);
  return res;
};

export const updateAnnouncements = async (id: string, params: any) => {
  const res = await axiosInstance.put(`/usr/Announcements/${id}`, params);
  return res;
};

export const updateAnnouncementsPin = async (id: string, isPin: boolean, version: string) => {
  const res = await axiosInstance.put(`/usr/Announcements/${id}/pin/${isPin}?version=${version}`);
  return res;
};

export const deleteAnnouncements = async (params: { ids?: { id: string; version: string }[] }) => {
  const res = await axiosInstance.delete(`/usr/Announcements`, {
    data: params
  });
  return res;
};

export const getAnnouncementsCreator = async () => {
  const res = await axiosInstance.get(`/usr/users/creator`);
  return res;
};

export const getExternalUserById = async (params: any) => {
  const res = await axiosInstance.post(`/usr/users/external-user`, params);
  return res;
};

export const getFilterConfigs = async () => {
  const res = await axiosInstance.get('/usr/userSettings/filters-config');
  return res;
};
export const updateFilterConfigs = async <T>(data: IFilterConfigs<T>) => {
  const res = await axiosInstance.put('/usr/userSettings/filters-config', data);
  return res;
};
export const deleteFilterConfigs = async (ids: RouterIdType[]) => {
  const res = await axiosInstance.delete('/usr/userSettings/filters-config', { data: { filterTypes: ids } });
  return res;
};
