import { useAppDispatch } from '@/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Row, Space } from 'antd';
import React, { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import EditHistory from '@/pages/master-data/components/edit-history/EditHistory';

import { BaseButton } from '@/components/base-button/BaseButton';
import { ConfirmCancelEditing } from '@/components/confirm-popup';

import { fetchUserInfo, setLoadingPage } from '@/redux/globalReducer';

import useMutation from '@/hooks/useMutation';

import { ERROR_CODE } from '@/utils/constants/AppConstants';
import { HTTP_STATUS_CODE } from '@/utils/constants/Http';
import { ACCOUNT_MASTER_LIST_URL, NOT_EXITS_URL } from '@/utils/constants/RouteContants';
import { getAccountMasterDetail } from '@/utils/services/AccountMasterApiService';

import BaseFormAccountMater from './BaseFormAccountMater';
import { FormUpdateAccountMaster } from './FormValidation';
import { ACCOUNT_MASTER_DEFAULT_VALUE } from './contants';
import { IFormMethodUpdateAccountMaster } from './models';

import './Add.scss';

const UpdateAccountMaster = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const formMethod = useForm<IFormMethodUpdateAccountMaster>({
    mode: 'all',
    resolver: yupResolver(FormUpdateAccountMaster),
    defaultValues: () => accountMasterDetailData()
  });
  const { isDirty, isSubmitSuccessful, isSubmitting } = formMethod.formState;

  const accountMasterDetailData = async () => {
    try {
      dispatch(setLoadingPage(true));
      const data = await getAccountMasterDetail(id as string);
      dispatch(setLoadingPage(false));
      return data || ACCOUNT_MASTER_DEFAULT_VALUE;
    } catch (error: any) {
      switch (error?.code) {
        case ERROR_CODE.ERR_BAD_REQUEST:
          navigate(NOT_EXITS_URL);
          break;
        default:
          break;
      }
      return ACCOUNT_MASTER_DEFAULT_VALUE;
    }
  };

  const { mutate: postUpdateAccountMaster } = useMutation(`/usr/users`, {
    method: 'PUT',
    bodyType: 'json',
    showToastError: true,
    showToastSuccess: true,
    defaultSuccessMsg: t('common:MSG_C_003', { item: t('button:keep') }) ?? ''
  });
  const handleUpdate: SubmitHandler<any> = async (val) => {
    setLoading(true);
    const result = await postUpdateAccountMaster(getPayloadRequest(val));
    if (result?.status === HTTP_STATUS_CODE.SUCCESS) {
      setTimeout(() => {
        setLoading(false);
        navigate(ACCOUNT_MASTER_LIST_URL);
        setTimeout(() => dispatch(fetchUserInfo()));
      }, 500);
      return;
    }
    setLoading(false);
  };
  const getPayloadRequest = (formData: any) => {
    return {
      id: formData.id,
      name: formData.name,
      email: formData.email,
      upn: formData.upn,
      roleId: formData.roleId,
      organizationId: formData.organizationId,
      status: formData.status,
      version: formData.version
    };
  };

  return (
    <div id='account-master-management'>
      <div className='account-master-container h-full'>
        <FormProvider {...formMethod}>
          <div className='header flex justify-between'>
            <div className='title-24 whitespace-pre-line font-bold text-24 leading-7'>{t('account_master:edit:title')}</div>
            <Space size={16}>
              <div className='min-w-[120px]'>
                <BaseButton disabled={!isDirty} block size='medium' type='tertiary' onClick={() => navigate(ACCOUNT_MASTER_LIST_URL)}>
                  {t('button:cancel')}
                </BaseButton>
              </div>
              <div className='min-w-[120px]'>
                <BaseButton disabled={!isDirty || isSubmitting || loading} block size='medium' onClick={formMethod.handleSubmit(handleUpdate)}>
                  <span> {t('button:keep')} </span>
                </BaseButton>
              </div>
            </Space>
          </div>
          <div className='account-master-form account-master-tabs-content px-5 py-4 rounded-[10px] border-[#DBDBE0] gap-3'>
            <BaseFormAccountMater formMethod={formMethod} isEdit={true} />
            <Row className='justify-end'>
              <Col span={23}>
                <EditHistory data={formMethod.getValues()} />
              </Col>
            </Row>
          </div>
        </FormProvider>
      </div>
      <ConfirmCancelEditing condition={isDirty && !isSubmitSuccessful} />
    </div>
  );
};

export default UpdateAccountMaster;
