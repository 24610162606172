import TaskPage from '@/pages/task';

import { V } from '@/hooks/useAuthorization';

import { ALL_ROLE } from '@/utils/constants/AppConstants';
import { TASKS_OF_ALL_PROJECTS } from '@/utils/constants/AuthorizationProjectManagement';
import { ALL_TASK_URL } from '@/utils/constants/RouteContants';
import { IRoute } from '@/utils/interfaces/route';
import { ROUTER_IDS } from '@utils/constants';

const TaskRoutes: IRoute[] = [
  {
    path: ALL_TASK_URL,
    name: TASKS_OF_ALL_PROJECTS,
    action: V,
    element: <TaskPage />,
    auth: true,
    role: ALL_ROLE,
    id: ROUTER_IDS.TASK_LIST
  }
];

export default TaskRoutes;
