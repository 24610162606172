import { useEffect, useState } from 'react';

import { IProjectQuestion } from '@pages/project-management/project-questionaire/components/question-list';
import { getListExternalQuestion } from '@pages/project-management/project-questionaire/external-questions/api';

export const useGetExternalQuestions = (token: string) => {
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState<IProjectQuestion[]>([]);

  const getExternalQuestions = async () => {
    if (!token) {
      return;
    }
    try {
      const { data: questions } = await getListExternalQuestion(token);
      setQuestions(questions);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getExternalQuestions();
  }, [token]);

  return { loading, questions };
};
