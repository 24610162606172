import React, { useRef } from 'react'

interface IUseInfinityScroll {
    root?: React.MutableRefObject<React.ReactNode>['current'] | Window;
    isFetching: boolean;
    configs?: IntersectionObserverInit;
    loadMore: (entry: IntersectionObserverEntry) => void;
}
const useInfinityScroll = (props: IUseInfinityScroll) => {
    const { root = window, isFetching, configs, loadMore } = props;
    const watcher = useRef<IntersectionObserver>();
    const latestNodeRef = (node: any) => {
        if (isFetching) return;
        if (watcher.current) watcher.current.disconnect();
        const options = {
            root: root as Element | Document | null,
            rootMargin: "0px",
            threshold: 1,
            ...configs
        };
        watcher.current = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) loadMore(entry);
        }, options);
        if (node) watcher.current.observe(node);
    };

    return { latestNodeRef };
}

export default useInfinityScroll