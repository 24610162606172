import { useAppSelector } from '@/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { showBasePopup } from '@/components/base-popup/BasePopup';
import { BaseButton } from '@components/base-button/BaseButton';
import { ConfirmCancelEditing } from '@components/confirm-popup';

import useMutation from '@/hooks/useMutation';

import { HTTP_STATUS_CODE } from '@/utils/constants/Http';
import { VIEW_PROJECT_URL } from '@/utils/constants/RouteContants';
import { Ii18n } from '@utils/interfaces/i18n';
import { getProjectInfo } from '@utils/services/ProjectApiService';

import InquiryForm from './components/InquiryForm';
import Introduce from './components/Introduce';
import { DEFAULT_INQUIRY_PROJECT, FormInquiry } from './constants';
import { IProjectInquiryForm } from './utils';

const InquiryProject = () => {
  const { id } = useParams();
  const { t }: Ii18n = useTranslation();
  const user = useAppSelector((x) => x.global.userProfile);
  const [isEditForm, setIsEditForm] = useState(false);
  const navigate = useNavigate();
  const formMethod = useForm<IProjectInquiryForm>({
    mode: 'all',
    shouldUnregister: false,
    resolver: yupResolver(FormInquiry),
    defaultValues: () => getDetailProject()
  });
  const { isDirty, isLoading } = formMethod?.formState;

  useEffect(() => {
    if (isDirty) {
      setIsEditForm(isDirty);
      return;
    }
  }, [isDirty]);

  const getDetailProject = async () => {
    if (!id) return DEFAULT_INQUIRY_PROJECT;
    try {
      const { data } = await getProjectInfo(String(id));
      const { code } = data;
      return {
        projectId: code,
        name: user?.name || null,
        company: user?.organizationName || '',
        emails: user?.email ? [user.email] : [],
        phoneNumber: null,
        content: null,
        attachmentsInquiry: []
      };
    } catch (error) {
      return DEFAULT_INQUIRY_PROJECT;
    }
  };

  const handleCancelInquiry = useCallback(async () => {
    setIsEditForm(false);
    if (isDirty) {
      const title = t('common:MSG_C_002:title');
      const msg = t('common:MSG_C_002:description');
      const showPopup = await showBasePopup({
        title,
        msg,
        type: 'discard'
      });
      if (showPopup === 'confirm') {
        setIsEditForm(false);
        navigate(VIEW_PROJECT_URL(id ?? ''));
      }
    } else navigate(VIEW_PROJECT_URL(id ?? ''));
  }, [isDirty]);

  const { mutate: sendInquiry } = useMutation(`/prj/projects/${id}/inquiry`, {
    method: 'POST',
    bodyType: 'json',
    showToastError: true,
    showToastSuccess: true,
    defaultSuccessMsg: t('common:MSG_C_003', { item: t('button:send') }) ?? ''
  });
  const handleSendInquiry = () => {
    if (formMethod.formState.isSubmitting) return;
    formMethod.handleSubmit(
      async (formValues: IProjectInquiryForm) =>
        new Promise(async (resolve) => {
          const payload = {
            emails: formValues.emails?.join(';'),
            phoneNumber: formValues.phoneNumber,
            content: formValues.content,
            attachments: formValues?.attachmentsInquiry
              ? formValues?.attachmentsInquiry.map((file) => {
                  return {
                    blobPath: file.blobPath,
                    fileSize: file.fileSize,
                    fileName: file.name
                  };
                })
              : []
          };
          const result = await sendInquiry(payload);
          if (result?.status === HTTP_STATUS_CODE.SUCCESS) {
            setIsEditForm(false);
            setTimeout(() => {
              navigate(VIEW_PROJECT_URL(String(id)));
            }, 500);
          } else {
            resolve(true);
          }
        })
    )();
  };

  return (
    <>
      <FormProvider {...formMethod}>
        {!isLoading ? (
          <div className='project-inquiry-container'>
            <div className='project-inquiry'>
              <div className='grid grid-cols-2 gap-4  h-[50px] items-center'>
                <div className='title-24'>{t('inquiry:header')}</div>
                <div className='flex justify-end gap-4'>
                  <BaseButton type='tertiary' size='medium' onClick={handleCancelInquiry}>
                    {t('button:cancel')}
                  </BaseButton>
                  <BaseButton size='medium' onClick={handleSendInquiry} disabled={formMethod.formState.isSubmitting}>
                    {t('button:send')}
                  </BaseButton>
                </div>
              </div>
              <div className='page-content mt-[12px] border border-[#DBDBE0] bg-[#FFF] rounded-[10px] py-4 px-5'>
                <Introduce />
                <InquiryForm formMethod={formMethod} defaultValues={formMethod.formState.defaultValues} />
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </FormProvider>
      <ConfirmCancelEditing condition={isEditForm} />
    </>
  );
};

export default InquiryProject;
