import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import AppLabelSpace from '@/components/app-label/AppLabelSpace';
import AppTooltip from '@/components/app-tooltip/AppTooltip';
import { BaseCard } from '@/components/base-card/BaseCard';

import { DataViewer } from '@/utils/helpers/common';

import WarningWaitingApprove from '@/assets/icons/WarningWaitingApprove.svg';
import CloseIcon from '@/assets/icons/close.svg';

const commonInfoProps = {
  labelClass: 'body-400',
  titleClass: 'body-700'
};
interface IStakeHolderCardProps {
  field: any;
  index: number;
  handleRemove: (index: number) => void;
}
const StakeHolderCard = ({ field, index, handleRemove }: IStakeHolderCardProps) => {
  const { t } = useTranslation();
  return (
    <BaseCard
      className='mt-3'
      key={field.id || field.uid || field.formId}
      showIconRemove={field.rejected}
      index={index}
      title={`${t('project:add:project_stakeholders:person_involved_project')}${index + 1}`}
      onRemove={handleRemove}
    >
      <Row gutter={16}>
        <Col span={24}>
          <AppLabelSpace
            {...commonInfoProps}
            title={
              <div className='flex items-center w-full'>
                <AppTooltip className='mr-4 truncate' title={field.stakeholderEmail}>
                  {DataViewer.display(field.stakeholderEmail)}
                </AppTooltip>{' '}
                <span className='w-[18px] h-[18px]'>
                  {field.rejected ? <CloseIcon width={18} height={18} /> : <WarningWaitingApprove width={18} height={18} />}
                </span>
              </div>
            }
            label={t('basic_information:email_address')}
          />
          <AppLabelSpace
            {...commonInfoProps}
            title={
              <div className={`${field.rejected ? 'text-negative' : 'text-warning'} font-medium`}>
                {t(`${field.rejected ? 'basic_information:account_rejected' : 'project_management:required_documents_tab:waiting_approve'}`)}
              </div>
            }
            label={' '}
          />
          {field.rejected && (
            <AppLabelSpace
              {...commonInfoProps}
              title={
                <div className='flex'>
                  <span className='mr-4 !font-normal min-w-[93px]'>{t('basic_information:account_reason')}</span>
                  <span dangerouslySetInnerHTML={{ __html: DataViewer.displayAsSanitizeHTML(field.rejectReason) }}></span>
                </div>
              }
              label={' '}
            />
          )}
          <AppLabelSpace
            {...commonInfoProps}
            title={<div className='whitespace-pre'>{DataViewer.display(field.stakeholderName)}</div>}
            label={t('basic_information:username')}
          />
          <AppLabelSpace
            {...commonInfoProps}
            title={<div className=''>{DataViewer.display(field.stakeholderCompanyName)}</div>}
            label={t('basic_information:company_name')}
          />
          <AppLabelSpace {...commonInfoProps} title={<div className=''>{DataViewer.display(field.roleName)}</div>} label={t('account_list:role')} />
        </Col>
      </Row>
    </BaseCard>
  );
};

export default StakeHolderCard;
