import { useLoaderData } from 'react-router-dom';

import { RouterIdType } from '@/utils/constants';
import { IRouterLoaderResponse } from '@/utils/helpers/routeLoaders';
import { deleteFilterConfigs, updateFilterConfigs } from '@/utils/services/UserApiService';

/**
 * You wanna using this hook, please define:
 * @see {@link routerId ../utils/constants} - This is id of routeItem & routerLoader. It's must be uniq & lowercase.
 * @param {id} routerId - The id which is define at enum above.
 *
 * What do you get in return?
 * @returns {data} - values of filterConfigs received from responses.
 * @returns {update} - update filterConfigs when u saved.
 * @returns {remove} - remove a config by filterId.
 */
export const useFilterConfigs = <T>(
  id: RouterIdType
): {
  data: T | null;
  update: (data: T) => Promise<any>;
  remove: () => Promise<any>;
} => {
  const { filterConfigs } = (useLoaderData() as IRouterLoaderResponse<any, T>) ?? {};

  const update = async (data: T) => {
    try {
      await updateFilterConfigs({
        filterValue: data,
        filterType: id
      });
    } catch (error) {}
  };

  const remove = async () => {
    try {
      await deleteFilterConfigs([id]);
    } catch (error) {}
  };

  return {
    data: filterConfigs,
    update,
    remove
  };
};
