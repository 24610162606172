import { DefaultOptionType } from 'antd/es/select';

export const INTERNAL_COMPANY_NAME = 'MOT';
export const INTERNAL_COMPANY_ID = process.env.REACT_APP_COMPANY_MOT;

export const PAGE_SIZE_OPTIONS: number[] = [50, 100, 150];

export const PAGE_SIZE: number = 50;

export const STATUS_TYPE_ACCOUNT = {
  ACTIVE: 'active',
  INACTIVE: 'inactive'
};

export const VALUE_STATUS_ACCOUNT = {
  ACTIVE: 'true',
  INACTIVE: 'false'
};
export const STATUS_ACCOUNT: DefaultOptionType[] = [
  {
    label: STATUS_TYPE_ACCOUNT.ACTIVE,
    value: VALUE_STATUS_ACCOUNT.ACTIVE
  },
  {
    label: STATUS_TYPE_ACCOUNT.INACTIVE,
    value: VALUE_STATUS_ACCOUNT.INACTIVE
  }
];

export interface IAccount {
  name?: string;
  organizationId?: string;
  organizationName?: string;
  email?: string;
  roleId?: string;
  roleName?: string;
  status?: string;
}
