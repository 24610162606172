import i18n from '@/i18n';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { setAlertNotification } from '@redux/globalReducer';
import { store } from '@redux/store';

import { API_PUBLIC_URL, AXIOS_TIMEOUT, ERROR_CODE } from '@utils/constants/AppConstants';
import { EXTERNAL_QUESTION_AUTHENTICATE_URL } from '@utils/constants/RouteContants';

const { t } = i18n;
const password = localStorage.getItem('password_external') ?? '';

export class SetupAxios {
  public instance: AxiosInstance;

  constructor(contentType = 'application/json') {
    this.instance = axios.create({
      baseURL: API_PUBLIC_URL,
      timeout: AXIOS_TIMEOUT,
      headers: {
        'Content-Type': contentType || 'application/json',
        Authorization: password
      }
    });

    this.instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (!window.navigator.onLine && error.code?.includes(ERROR_CODE.ERR_NETWORK)) {
          // Show message when error network
          store.dispatch(
            setAlertNotification({
              show: true,
              type: 'error',
              position: 'top',
              message: t(`common:error_network`)
            })
          );
        }
        if (error?.response?.data?.fields?.[0]?.errorCode === 'ERROR.VALIDATION.QUESTIONNAIRE.PASSWORD.EMPTY') {
          window.location.href = EXTERNAL_QUESTION_AUTHENTICATE_URL;
        }
        return Promise.reject(error);
      }
    );
  }

  private _getConfig(config: AxiosRequestConfig = {}) {
    const password = localStorage.getItem('password_external') ?? '';
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: password
      }
    };
  }

  public get = async (url = '', config: AxiosRequestConfig = {}): Promise<AxiosResponse> => {
    const configAPI = this._getConfig(config);

    return this.instance
      .get(url, {
        ...configAPI,
        ...config
      })
      .then((response) => response);
  };

  public post = async (url = '', body = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse> => {
    const configAPI = this._getConfig(config);

    return this.instance
      .post(url, body, {
        ...configAPI,
        ...config
      })
      .then((response) => response);
  };

  public patch = async (url = '', body = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse> => {
    const configAPI = await this._getConfig(config);

    return this.instance
      .patch(url, body, {
        ...configAPI,
        ...config
      })
      .then((response) => response);
  };

  public put = async (url = '', body = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse> => {
    const configAPI = await this._getConfig(config);

    return this.instance
      .put(url, body, {
        ...configAPI,
        ...config
      })
      .then((response) => response);
  };

  public delete = async (url = '', config: AxiosRequestConfig = {}): Promise<AxiosResponse> => {
    const configAPI = await this._getConfig(config);

    return this.instance
      .delete(url, {
        ...configAPI,
        ...config
      })
      .then((response) => response);
  };
}

export const axiosExternal = new SetupAxios();
