import { Space } from 'antd';
import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ExternalProxyContext } from '@pages/project-management/add-edit/ProjectExternalProxy';

import { BaseButton, IBaseButton } from '@/components/base-button/BaseButton';

import CaretLeft from '@/assets/icons/CaretLeft.svg?react';
import CaretRight from '@/assets/icons/CaretRight.svg?react';

import { FormObserver } from '../../../models/projectExternalProxy';

export const HEADER_EVENT = {
  CANCEL: 'cancel',
  NEXT: 'next',
  BACK: 'back',
  SAVE: 'save',
  DRAFT: 'draft'
} as const;
export type HEADER_EVENT = (typeof HEADER_EVENT)[keyof typeof HEADER_EVENT];
export const STEP = {
  FIRST: 'first',
  LAST: 'last'
} as const;
export type STEP = (typeof STEP)[keyof typeof STEP];

type ButtonProp = IBaseButton | undefined;

export interface IHeaderProps {
  step: STEP | number;
  onClick: (event: HEADER_EVENT) => void;
  /**
   * @argument buttonProps - Array of button props with order: [backBtn, cancelBtn, nextBtn, draftBtn, saveBtn]
   * @example
   * <Header step={1}
   *   ...
   *   buttonProps={[undefined, undefined, undefined, { disabled: true}, {disabled: true}]}
   * />
   * @explain save button and draft button will be disabled
   */
  buttonProps?: ButtonProp[];
}

const Header = ({ step, buttonProps, onClick }: IHeaderProps) => {
  const { t } = useTranslation();
  const { loading } = useContext(ExternalProxyContext);
  const formContext = useFormContext<FormObserver<'scenario-c'>>();
  const { id } = useParams();
  const { isDirty } = formContext.formState;
  const [backBtn, cancelBtn, nextBtn, draftBtn, saveBtn] = buttonProps ?? [];
  return (
    <Space size={16}>
      {step !== STEP.FIRST && (
        <BaseButton
          className='min-w-[120px]'
          block
          size='medium'
          type='tertiary'
          disabled={loading}
          onClick={() => onClick(HEADER_EVENT.BACK)}
          {...backBtn}
        >
          <img src={CaretLeft}></img>
          {t('button:back')}
        </BaseButton>
      )}

      <BaseButton
        className='min-w-[120px]'
        block
        size='medium'
        type='tertiary'
        disabled={loading}
        onClick={() => onClick(HEADER_EVENT.CANCEL)}
        {...cancelBtn}
      >
        {t('button:cancel')}
      </BaseButton>

      {step !== STEP.FIRST && (
        <BaseButton
          className='min-w-[120px]'
          disabled={(!isDirty && !!id) || loading}
          block
          size='medium'
          type='secondary'
          onClick={() => onClick(HEADER_EVENT.DRAFT)}
          {...draftBtn}
        >
          {t('common:button:save_daft')}
        </BaseButton>
      )}

      {step === STEP.LAST ? (
        <BaseButton
          className='min-w-[120px]'
          block
          size='medium'
          onClick={() => onClick(HEADER_EVENT.SAVE)}
          {...saveBtn}
          disabled={saveBtn?.disabled || loading}
        >
          <span> {t('button:register')} </span>
        </BaseButton>
      ) : (
        <BaseButton
          className='min-w-[120px]'
          size='medium'
          onClick={() => onClick(HEADER_EVENT.NEXT)}
          {...nextBtn}
          disabled={nextBtn?.disabled || loading}
        >
          <span> {t('button:next')} </span>
          <img src={CaretRight}></img>
        </BaseButton>
      )}
    </Space>
  );
};

export default Header;
