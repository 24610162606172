import { List, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import AppTooltip from '@/components/app-tooltip/AppTooltip';
import { BaseButton } from '@/components/base-button/BaseButton';
import SearchBox from '@/components/base-filter/SearchBox';
import BaseCheckboxGroups from '@/components/common/base-checkbox';
import BaseRadioGroups from '@/components/common/base-radio';
import FormItem from '@/components/common/form-item';

import useAuthorization from '@/hooks/useAuthorization';
import useFetch from '@/hooks/useFetch';

import { QUERY_SORT } from '@/utils/constants';
import { API } from '@/utils/constants/Apis';
import { convertFullWidthToHalfWidth } from '@/utils/helpers/globalHelper';
import { Ii18n } from '@/utils/interfaces/i18n';
import { IParamFilterNoticeProps } from '@/utils/interfaces/notificationInterface';

import Check from '@/assets/icons/notification/Check.svg';

import { TYPE_CHECK_BOX } from '../constants';
import { IAccountData } from '../models';

interface IFilterProps {
  onFilter: (values: FieldValues) => void;
  defaultValues?: any;
  informationUser: any;
}

const Filter = ({ informationUser, onFilter, defaultValues }: IFilterProps) => {
  const { isInternalRole } = useAuthorization();
  const formMethod = useForm({ defaultValues });
  const [loadData, setLoadData] = useState<boolean>(true);
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [countAccountSelected, setCountAccountSelected] = useState(0);
  const [accountSelectReport, setAccountSelectReport] = useState<string[]>([]);
  const [accountList, setAccountList] = useState<IAccountData[]>([]);
  const [accountListShow, setAccountListShow] = useState<IAccountData[]>([]);
  const { t }: Ii18n = useTranslation();
  const { data: userPics, loading: loadingUser } = useFetch<IAccountData[]>(API.GET_USERS_MOT_PIC(true), 'GET');

  const countAccountSelect = (data: IAccountData[]) => {
    setCountAccountSelected(data.filter((item) => item.checked).length);
  };

  const handleListAccount = (userPics: IAccountData[]) => {
    setAccountSelectReport(formMethod.getValues('accountSelect') ?? []);
    // Change your account and remove inactive accounts
    const updatedData = userPics.reduce((acc: IAccountData[], item) => {
      const updatedItem = {
        ...item,
        checked: formMethod.getValues('accountSelect')?.includes(item.id) ?? false,
        name: item.id === informationUser?.id ? t('notification:myself') : item.name,
        email: item.id === informationUser?.id ? '' : item.email
      };

      // Only add items with checked = true or status = true
      if (updatedItem.checked === true || updatedItem.status === true) {
        acc.push(updatedItem);
      }

      return acc;
    }, []);

    // Sort the data in order: my account, accounts that are checked, and the rest
    updatedData.sort((a, b) => {
      if (a.id === informationUser?.id) return -1;
      if (b.id === informationUser?.id) return 1;
      if (a.checked && !b.checked) return -1;
      if (!a.checked && b.checked) return 1;
      return 0;
    });

    setAccountList(updatedData);
  };

  useEffect(() => {
    formMethod.setValue('searchAccount', '');
    if (userPics) {
      handleListAccount(userPics);
    }
  }, [userPics]);

  useEffect(() => {
    if (accountList && loadData) {
      setAccountListShow(accountList);
      countAccountSelect(accountList);
    }
  }, [accountList]);

  const searchAccount = (values?: IParamFilterNoticeProps) => {
    setLoadingSearch(true);
    // Convert key search to full width and half width
    const newValueSearch = convertFullWidthToHalfWidth(values?.searchAccount?.toLowerCase() ?? '');

    // Filter account with key search
    const filteredResults = accountList?.filter((item) => {
      return convertFullWidthToHalfWidth(`${item.name} (${item.email})`.toLowerCase())?.includes(newValueSearch);
    });
    setAccountListShow(filteredResults || []);
    setLoadingSearch(false);
  };

  const toggleChecked = (data: IAccountData[], id: string) => {
    return data.map((item) => (item.id === id ? { ...item, checked: !item.checked } : item));
  };

  const selectAccount = (id: string) => {
    setLoadData(false);
    const newData = toggleChecked(accountList, id);
    const newDataShow = toggleChecked(accountListShow, id);
    countAccountSelect(newData);
    setAccountList(newData);
    setAccountListShow(newDataShow);

    // Set id if it exists, delete it, if it doesn't, add it
    const reportAccount = accountSelectReport?.includes(id) ? accountSelectReport.filter((item) => item !== id) : [...accountSelectReport, id];
    setAccountSelectReport(reportAccount ?? []);
    formMethod.setValue('accountSelect', reportAccount);
  };

  const handleResetAccount = (status: boolean) => {
    if (!status) return;

    // Reset account select report, except your own account
    const resetData = accountList.map((item, index) => {
      if (index === 0) {
        return { ...item, checked: true };
      }
      const { checked, ...rest } = item;
      return rest;
    });
    setLoadData(true);
    setAccountList(resetData);
    formMethod.setValue('searchAccount', '');
    formMethod.setValue('accountSelect', [informationUser?.id]);
  };

  const handleResetFilter = () => {
    formMethod.reset({
      order: QUERY_SORT.DESC,
      searchAccount: null,
      noticeType: [],
      showListAccount: [],
      accountSelect: [informationUser?.id]
    });
    setLoadData(true);
    handleResetAccount(true);
  };

  const { order, showListAccount, noticeType } = formMethod.watch();
  const disableResetBtn = () => {
    return order === QUERY_SORT.DESC && showListAccount.length === 0 && noticeType.length === 0;
  };

  return (
    <FormProvider {...formMethod}>
      <div className='w-[363px] pt-5'>
        <div className='item-filter px-5 py-2'>
          <FormItem name='order'>
            <BaseRadioGroups
              options={[
                {
                  label: t('notification:filter_date_desc'),
                  value: QUERY_SORT.DESC
                },
                {
                  label: t('notification:filter_date_asc'),
                  value: QUERY_SORT.ASC
                }
              ]}
              isVertical={true}
            />
          </FormItem>
        </div>
        <div className='item-filter px-5 py-2'>
          <FormItem name='noticeType'>
            <BaseCheckboxGroups
              defaultValue={formMethod.getValues('noticeType')}
              options={[
                {
                  label: t('notification:hidden'),
                  value: TYPE_CHECK_BOX.NOTICE_HIDDEN
                },
                {
                  label: t('notification:flagged'),
                  value: TYPE_CHECK_BOX.NOTICE_FLAG
                }
              ]}
              isVertical={true}
            />
          </FormItem>
        </div>
        {isInternalRole && (
          <div className='item-filter'>
            <div className='px-5 pt-2'>
              <div className='justify-space-between'>
                <FormItem name='showListAccount'>
                  <BaseCheckboxGroups
                    defaultValue={formMethod.getValues('showListAccount')}
                    onChange={(values: any[]) => {
                      handleResetAccount(values.length === 0);
                    }}
                    options={[
                      {
                        label: t('notification:other'),
                        value: TYPE_CHECK_BOX.SHOW_LIST_ACCOUNT
                      }
                    ]}
                  />
                </FormItem>
              </div>
            </div>
            {formMethod.getValues('showListAccount') && formMethod.getValues('showListAccount').length > 0 && (
              <>
                {countAccountSelected > 0 && (
                  <div className='select-filter w-[38px] h-[22px] text-center mt-[-29px]'>
                    {countAccountSelected}
                    {t('notification:items')}
                  </div>
                )}
                <div className='px-5'>
                  {accountList.length >= 10 && (
                    <div className='box-filter my-3'>
                      <SearchBox
                        name={'searchAccount'}
                        maxLength={128}
                        onSubmit={formMethod.handleSubmit(searchAccount)}
                        placeholder={String(t('notification:search'))}
                      />
                    </div>
                  )}
                </div>
                <div className='max-h-[307px] overflow-auto'>
                  {loadingSearch || loadingUser ? (
                    <div className='text-center mb-5'>
                      <Spin size='large' />
                    </div>
                  ) : (
                    <>
                      {accountListShow.length === 0 ? (
                        <div className='text-center py-2'>
                          <span>{t('common:MSG_038')}</span>
                        </div>
                      ) : (
                        <List
                          className='filter-data'
                          dataSource={accountListShow}
                          renderItem={(item) => {
                            const statusText = item.status ? '' : `(${t('account_list:status_account:inactive')})`;
                            const emailText = item.email ? `(${item.email})` : '';
                            const itemText = `${statusText}${item.name} ${emailText}`;
                            return (
                              <List.Item
                                className={`${item.checked ? 'is-select' : ''} ${item.status ? '' : 'is-inactive'}`}
                                onClick={() => selectAccount(item.id)}
                              >
                                <div className='max-w-[292px]'>
                                  <AppTooltip className='block truncate' title={itemText}>
                                    <span className='body-400'>{itemText}</span>
                                  </AppTooltip>
                                </div>
                                {item.checked && <Check />}
                              </List.Item>
                            );
                          }}
                        />
                      )}
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        )}
        <div className='justify-space-between p-5'>
          <div className='min-w-[157px]'>
            <BaseButton onClick={handleResetFilter} block size='medium' type='tertiary' disabled={disableResetBtn()}>
              {t('project:filter:btn_reset')}
            </BaseButton>
          </div>
          <div className='min-w-[157px]'>
            <BaseButton onClick={formMethod.handleSubmit(onFilter)} block size='medium' type='primary'>
              <span>{t('project:filter:btn_aply')} </span>
            </BaseButton>
          </div>
        </div>
      </div>
    </FormProvider>
  );
};

export default Filter;
