import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BaseButton } from '@/components/base-button/BaseButton';
import { FormInputDate } from '@/components/form-input-date/FormInputDate';
import { FormInputSelect } from '@/components/form-input-select/FormInputSelect';

import useOptionsGlobal from '@/hooks/useOptionsGlobal';

import { Ii18n } from '@/utils/interfaces/i18n';

import Minus from '@/assets/icons/Minus.svg';

import { IFilterSearchForm } from '../utils';

interface IFilterProps {
  formMethod?: any;
  onSubmit: (values: IFilterSearchForm['primary']) => void;
  onReset?: () => void;
  defaultValues?: any;
}

export interface IPhraseCategory {
  id: string;
  name: string;
  displayOrder: number;
}
const PrimaryFormFilter = ({ onSubmit, defaultValues }: IFilterProps) => {
  const form = useForm<IFilterSearchForm['primary']>({
    defaultValues: {
      ...defaultValues,
      updatedDateFrom: !defaultValues.updatedDateFrom ? undefined : dayjs(defaultValues.updatedDateFrom),
      updatedDateTo: !defaultValues.updatedDateTo ? undefined : dayjs(defaultValues.updatedDateTo)
    }
  });
  const { t }: Ii18n = useTranslation();

  const { countryOptions } = useOptionsGlobal();

  const disabledButtonReset = () => {
    const { countryId, updatedDateFrom, updatedDateTo } = form.watch();
    return !countryId && !updatedDateFrom && !updatedDateTo;
  };

  const handleSubmit = (values: any) => {
    const { updatedDateFrom, updatedDateTo } = values ?? {};
    const convertValue = {
      ...values,
      updatedDateFrom: updatedDateFrom ? updatedDateFrom.format() : undefined,
      updatedDateTo: updatedDateTo ? updatedDateTo.format() : undefined
    };
    onSubmit(convertValue);
  };

  const handleReset = () => {
    form.reset({
      countryId: '',
      updatedDateFrom: '',
      updatedDateTo: ''
    });
  };
  return (
    <>
      <FormProvider {...form}>
        <div className='p-4 w-[500px]'>
          <h3 className='text-xl font-semibold'>{t('button:filter')}</h3>
          <div className='item mb-5'>
            <FormInputSelect
              name='countryId'
              labelTx='master_data:document_management:country'
              options={countryOptions}
              placeholder={t('placeholder:select', { field: t('master_data:document_management:country') })}
            />
          </div>
          <div className='row mb-8'>
            <Row gutter={16}>
              <Col span={11}>
                <FormInputDate
                  name='updatedDateFrom'
                  labelTx='master_data:phrase:edited_date'
                  placeholder={t('placeholder:date')}
                  disabledDate={(d) => {
                    return Boolean(form.getValues('updatedDateTo') && d.startOf('day').isAfter(form.getValues('updatedDateTo')));
                  }}
                />
              </Col>
              <Col span={2}>
                <div className='mt-10'>
                  <Minus />
                </div>
              </Col>
              <Col span={11}>
                <FormInputDate
                  name='updatedDateTo'
                  label='&nbsp;'
                  placeholder={t('placeholder:date')}
                  disabledDate={(d) => {
                    return Boolean(form.getValues('updatedDateFrom') && d.endOf('day').isBefore(form.getValues('updatedDateFrom')));
                  }}
                />
              </Col>
            </Row>
          </div>
          <div className='flex justify-end space-x-2'>
            <div className='w-[120px]'>
              <BaseButton disabled={disabledButtonReset()} onClick={handleReset} type='tertiary' size='medium'>
                {t('master_data:document_management:btn_reset')}
              </BaseButton>
            </div>
            <div className='w-[120px]'>
              <BaseButton onClick={form.handleSubmit(handleSubmit)} type='primary' size='medium'>
                <span>{t('master_data:document_management:btn_apply')} </span>
              </BaseButton>
            </div>
          </div>
        </div>
      </FormProvider>
    </>
  );
};

export default PrimaryFormFilter;
