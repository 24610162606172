import { QUERY_CONDITION } from '@/utils/constants';
import QueryFactory, { QueryItem } from '@/utils/helpers/filters/base';
import { formatDateTime, getEndOfDay } from '@/utils/helpers/globalHelper';

import { IFilterForm } from '../models';

export class CompanyQueryFactory extends QueryFactory {
  updatedDateFrom(date: string) {
    const newQuerySection = this.initNewQuery(this.data?.length ? QUERY_CONDITION.AND : QUERY_CONDITION.EMPTY);
    newQuerySection?.childrens?.push(new QueryItem().modules.fromDate(date, { fieldTitle: 'updatedDate' }));
    this.append(newQuerySection);
    return this;
  }

  updatedDateTo(date: string) {
    const newQuerySection = this.initNewQuery(this.data?.length ? QUERY_CONDITION.AND : QUERY_CONDITION.EMPTY);
    newQuerySection?.childrens?.push(new QueryItem().modules.toDate(date, { fieldTitle: 'updatedDate' }));
    this.append(newQuerySection);
    return this;
  }
}

export const generateFilter = ({ searchValue, primary }: IFilterForm): string => {
  const filterFactory = new CompanyQueryFactory();

  if (searchValue) {
    const fields = ['nameSearch.ToLower()', 'updatedNameSearch.ToLower()'];
    filterFactory.searchingBy(fields, searchValue.toLowerCase());
  }

  if (primary?.updatedDateFrom) {
    const updatedDateFrom = formatDateTime(primary.updatedDateFrom);
    filterFactory.updatedDateFrom(updatedDateFrom);
  }

  if (primary?.updatedDateTo) {
    const updatedDateTo = getEndOfDay(primary.updatedDateTo);
    filterFactory.updatedDateTo(updatedDateTo);
  }

  return filterFactory.sanitize().stringifyData();
};
