import { useAppSelector } from '@/hooks';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormBaseFilter from '@/components/base-filter';

import useFetch from '@/hooks/useFetch';
import useOptionsGlobal from '@/hooks/useOptionsGlobal';

import { DataViewer } from '@/utils/helpers/common';
import { formatDateTime } from '@/utils/helpers/globalHelper';

import PrimaryFormFilter from './PrimaryFormFilter';
import { IFormFilter } from './models';

export interface ITableHeaderProps {
  onSubmit: SubmitHandler<IFormFilter>;
}
const TableHeader = ({ onSubmit }: ITableHeaderProps) => {
  const formFilter = useFormContext<IFormFilter>();
  const { t } = useTranslation();
  const { countryOptions } = useOptionsGlobal();
  const countries = useAppSelector((state) => state?.global?.countries);
  const { data: assignmentFormResponse } = useFetch<any>('/mst/forms', 'GET');
  const processPatterns = useAppSelector((state) => state.global.processPatterns);

  const getVisaCategories = () => {
    let categories: any[] = [];
    countries.forEach((item) => {
      categories = categories.concat(item.categories);
    });
    return categories;
  };

  return (
    <div className='mb-4'>
      <FormBaseFilter
        onSubmit={onSubmit as any}
        searchBox={{ placeholder: DataViewer.display(t('common:TEMPLATE_FIELDS_SEARCH_PLACEHOLDER')) }}
        tagSection={{
          renderTag: (fieldKey, fieldValue) => {
            if (fieldKey === 'countryId') {
              return <>{countryOptions?.find((i) => i.id === fieldValue)?.label}</>;
            }
            if (fieldKey === 'categoryId') {
              const visaCategories = getVisaCategories();
              return <>{visaCategories?.find((i) => i.id === fieldValue)?.code?.toUpperCase()}</>;
            }
            if (fieldKey === 'formId') {
              return <>{assignmentFormResponse?.data?.find((i: any) => i.id === fieldValue)?.name}</>;
            }
            if (fieldKey === 'patternId') {
              return <>{processPatterns?.find((i: any) => i.id === fieldValue)?.name}</>;
            }
            return <>{fieldValue}</>;
          }
        }}
        primaryAction={{
          label: 'button:filter',
          name: 'primary',
          popoverContent: (
            <PrimaryFormFilter
              defaultValues={formFilter.watch('primary')}
              onSubmit={(values) => {
                const previous = formFilter.getValues();
                formFilter.reset({
                  ...previous,
                  primary: {
                    ...values,
                    updatedDateFrom: values?.updatedDateFrom ? formatDateTime(values?.updatedDateFrom) : null,
                    updatedDateTo: values?.updatedDateTo ? formatDateTime(values?.updatedDateTo) : null
                  }
                });
                formFilter.handleSubmit(onSubmit)();
              }}
              onReset={() => {}}
            />
          )
        }}
        secondaryAction={null}
      />
    </div>
  );
};

export default TableHeader;
