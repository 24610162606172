import CompanyMaster from '@/pages/master-data/company';
import PhraseList from '@/pages/master-data/phrase-management/phrase-list/components/PhraseList';

import { ROUTER_IDS } from '@/utils/constants';

import { DOCUMENT_MASTER_ROUTER, PHRASE_LIST, TASK_MASTER_ROUTER, V } from '../hooks/useAuthorization';
import DocumentMasterManagement from '../pages/master-data/document-master/DocumentMaster';
import TaskManagement from '../pages/master-data/task/TaskMaster';
import { INTERNAL_ADMIN, INTERNAL_GENERAL_USER } from '../utils/constants/AppConstants';
import { MASTER_LIST_ITEM_DOCUMENT_URL, MASTER_LIST_URL, PHRASE_LIST_URL, TASK_LIST_URL } from '../utils/constants/RouteContants';
import { COMPANY_LIST_ROUTER } from '../utils/constants/master-data/company/company';
import { IRoute } from '../utils/interfaces/route';

const MasterRoutes: IRoute[] = [
  {
    path: MASTER_LIST_URL,
    name: COMPANY_LIST_ROUTER,
    action: V,
    element: <CompanyMaster />,
    auth: true,
    role: [INTERNAL_ADMIN],
    id: ROUTER_IDS.COMPANY_MASTER_LIST
  },
  {
    path: MASTER_LIST_ITEM_DOCUMENT_URL,
    name: DOCUMENT_MASTER_ROUTER,
    action: V,
    element: <DocumentMasterManagement />,
    auth: true,
    role: [INTERNAL_ADMIN, INTERNAL_GENERAL_USER],
    id: ROUTER_IDS.DOCUMENT_MASTER_LIST
  },
  {
    path: TASK_LIST_URL,
    name: TASK_MASTER_ROUTER,
    action: V,
    element: <TaskManagement />,
    auth: true,
    role: [INTERNAL_ADMIN, INTERNAL_GENERAL_USER],
    id: ROUTER_IDS.TASK_MASTER_LIST
  },
  {
    path: PHRASE_LIST_URL,
    name: PHRASE_LIST,
    action: V,
    element: <PhraseList />,
    auth: true,
    role: [INTERNAL_ADMIN, INTERNAL_GENERAL_USER],
    id: ROUTER_IDS.PHRASE_MASTER_LIST
  }
];

export default MasterRoutes;
