import { v4 as uuid } from 'uuid';

export const HAS_OTHER_STAKE_HOLDER = {
  YES: 'YES',
  NO: 'NO'
};
export type HAS_OTHER_STAKE_HOLDER = (typeof HAS_OTHER_STAKE_HOLDER)[keyof typeof HAS_OTHER_STAKE_HOLDER];
export const MAX_FIELD_PERSON_NUMBER = 9;
export const STAKEHOLDER_PATH = 'projectStakeholders';
export const STAKEHOLDER_FIELDS = ['email'];
export const DEFAULT_STAKEHOLDER = {
  formId: uuid(),
  email: ''
};
