import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import DOMPurify from 'dompurify';

import { CONFIG_DOM_PURIFY } from '../../utils/constants/AppConstants';
import { ICustomerRequestComment, IProjectCustomerRequest } from '../../utils/interfaces/Project';
import { IComment } from '../../utils/interfaces/customer-request';
import { ITemplate } from '../../utils/interfaces/template';
import { IUser } from '../../utils/interfaces/user';

interface IProjectStore {
  projectDetails: any;
  selectedTemplateId: string | undefined;
  selectedTemplate: ITemplate | null;
  templates: ITemplate[];
  users: IUser[];
  customerRequest: IProjectCustomerRequest;
  customerRequestComment: ICustomerRequestComment;
  commentId: string;
  dataComment: IComment;
  filterProject?: string;
}

export const projectSpaceName = 'project';

// Define the initial state using that type
const initialState: IProjectStore = {
  projectDetails: null,
  selectedTemplateId: '',
  selectedTemplate: null,
  templates: [],
  users: [],
  customerRequest: {
    isCollapsed: false,
    isMinimized: false,
    isEdit: false,
    isZoomed: true,
    isPublic: true,
    dataCurrent: {},
    isChangeTab: false,
    isDraft: false,
    isActionDone: false,
    isModalOpen: false,
    isCloseMinimized: false,
    totalRecord: 0,
    isFormValueChanged: false,
    shouldClearAllData: false,
    isResetActiveKey: false
  },
  customerRequestComment: {
    hasDeleted: false,
    isActive: false,
    isDirtyComment: false,
    isKeepValueWhenShowPopup: false
  },
  commentId: '',
  dataComment: {
    selectionDestination: [],
    content: '',
    attachments: []
  }
};

export const projectSlice = createSlice({
  name: projectSpaceName,
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setProjectDetails: (state, action: PayloadAction<any>) => {
      state.projectDetails = {
        ...action.payload,
        shortMemo: DOMPurify.sanitize(action.payload?.shortMemo as string, CONFIG_DOM_PURIFY),
        memo: DOMPurify.sanitize(action.payload?.memo as string, CONFIG_DOM_PURIFY)
      };
    },
    setSelectedTemplateId: (state, action: PayloadAction<string>) => {
      state.selectedTemplateId = action.payload || undefined;
    },
    setSelectedTemplate: (state, action: PayloadAction<ITemplate>) => {
      state.selectedTemplate = action.payload;
    },
    setTemplatesList: (state, action: PayloadAction<ITemplate[]>) => {
      state.templates = action.payload;
    },
    setUsers: (state, action: PayloadAction<IUser[]>) => {
      state.users = action.payload;
    },
    setCustomerRequest: (state, action: PayloadAction<IProjectCustomerRequest>) => {
      state.customerRequest = action.payload;
    },
    setChangeTab: (state, action: PayloadAction<boolean>) => {
      state.customerRequest.isChangeTab = action.payload;
    },
    setActionDone: (state, action: PayloadAction<boolean>) => {
      state.customerRequest.isActionDone = action.payload;
    },
    setIsModalOpen: (state, action: PayloadAction<boolean>) => {
      state.customerRequest.isModalOpen = action.payload;
    },
    setDataCustomerComment: (state, action: PayloadAction<ICustomerRequestComment>) => {
      state.customerRequestComment = action.payload;
    },
    setCommentId: (state, action: PayloadAction<string>) => {
      state.commentId = action.payload;
    },
    setDataComment: (state, action: PayloadAction<IComment>) => {
      state.dataComment = action.payload;
    }
  }
});

export const {
  setProjectDetails,
  setChangeTab,
  setActionDone,
  setIsModalOpen,
  setSelectedTemplateId,
  setSelectedTemplate,
  setTemplatesList,
  setUsers,
  setCustomerRequest,
  setDataCustomerComment,
  setCommentId,
  setDataComment
} = projectSlice.actions;

/**
 * @deprecated this store will be removed in the future.
 * If u wanna use this store, please use the store in the src/redux/project-v2/projectReducer.ts
 */
export default projectSlice.reducer;
