import { useAppDispatch } from '@/hooks';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { FetchColumnsType } from '@/components/common/table-base';
import EmptyDataWithIcon from '@components/EmptyData/EmptyDataWithIcon';
import AppTooltip from '@components/app-tooltip/AppTooltip';
import { BaseButton } from '@components/base-button/BaseButton';
import FormBaseFilter, { RefActionControl } from '@components/base-filter';
import TableFetch from '@components/common/table-fetch';

import { setAlertNotification } from '@redux/globalReducer';

import useAuthorization, * as s from '@hooks/useAuthorization';
import useFetch from '@hooks/useFetch';
import { useFilterConfigs } from '@hooks/useFilterConfigs';
import useMutation from '@hooks/useMutation';

import { ROUTER_IDS } from '@utils/constants';
import { TYPE } from '@utils/constants/AppConstants';
import { DataViewer } from '@utils/helpers/common';
import { formatDateTime, getEndOfDay } from '@utils/helpers/globalHelper';

import IconAddNew from '@assets/icons/base-filter/icon-add-new.svg';

import PhrasePopup, { IPhaseCreateEdit } from '../../phrase-create-edit/components/CreateEditPopup';
import { IQueryFilterParams, generateFilter } from '../ultis';
import PrimaryFormFilter, { IPhraseCategory } from './PrimaryFormFilter';

export interface IPhrase {
  id: string;
  categoryId: string;
  categoryName: string;
  content: string;
  createdBy: string;
  createdDate: string;
  createdName: string;
  title: string;
  titleSearch: string;
  updatedBy: string;
  updatedDate: string;
  updatedName: string;
  updatedNameSearch: string;
}

export default function PhraseList() {
  const { t } = useTranslation();
  const mounted = useRef<any>(false);
  const refList = useRef<any>(null);
  const dispatch = useAppDispatch();
  const filterTableRef = useRef<RefActionControl>({ toggle: () => {} });
  const { data: defaultFilterConfigs, update: updateFilterConfigs } = useFilterConfigs<FieldValues>(ROUTER_IDS.PHRASE_MASTER_LIST);

  const { hasPermission } = useAuthorization();
  const { data: phraseCategories, loading: loadingPhraseCategory } = useFetch<IPhraseCategory[]>(`/prj/phrases/category`, 'GET');
  const { mutate: deletePhrases } = useMutation(`/prj/phrases`, {
    method: 'DELETE',
    showToastError: true
  });

  const generateDefaultFilterForm = () => {
    const results = {
      form: {
        primary: {}
      },
      query: {}
    };
    if (!defaultFilterConfigs?.primary) return results;
    const { updatedDateFrom, updatedDateTo } = defaultFilterConfigs.primary;
    results.form = {
      primary: {
        ...defaultFilterConfigs.primary,
        updatedDateFrom: updatedDateFrom ? dayjs(updatedDateFrom) : undefined,
        updatedDateTo: updatedDateTo ? dayjs(updatedDateTo) : undefined
      }
    };
    results.query = {
      filter: generateFilter({
        keyword: '',
        categoryId: defaultFilterConfigs?.primary?.categoryId,
        updatedDateFrom: defaultFilterConfigs?.primary?.updatedDateFrom ?? undefined,
        updatedDateTo: defaultFilterConfigs?.primary?.updatedDateTo ?? undefined
      })
    };
    return results;
  };

  const formFilter = useForm({ defaultValues: generateDefaultFilterForm().form });
  const [filter, setFilter] = useState(generateDefaultFilterForm().query);

  // Popup create/edit
  const [editData, setEditData] = useState<IPhaseCreateEdit>({});
  const [openEditPopup, setOpenEditPopup] = useState<boolean>(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const columns: FetchColumnsType<IPhrase> = [
    {
      title: 'NO',
      key: 'no',
      width: 50,
      className: 'max-w-[50px] h-[38px]',
      render: (_value: any, record: IPhrase, index: number) => {
        return <div className='text-center whitespace-nowrap align-top'>{index + 1}</div>;
      }
    },
    {
      title: t('master_data:phrase:name'),
      key: 'name',
      width: 700,
      className: 'max-w-[700px] h-[38px]',
      render: (_value: any, record: IPhrase) => {
        return (
          <AppTooltip title={record.title}>
            {hasPermission(s.PHRASE_LIST_BUTTON_EDIT, s.C) ? (
              <Link
                className='link-custom'
                onClick={() => {
                  setEditData({ ...record });
                  setOpenEditPopup(true);
                }}
                to={''}
              >
                {record.title}
              </Link>
            ) : (
              <span>{record.title}</span>
            )}
          </AppTooltip>
        );
      },
      ellipsis: true
    },
    {
      title: t('master_data:phrase:category'),
      dataIndex: 'categoryName',
      key: 'categoryName',
      width: 400,
      className: 'max-w-[400px] h-[38px]',
      render: (_value, record: IPhrase) => {
        return DataViewer.display(record.categoryName);
      },
      tooltip(_value, record: IPhrase) {
        return DataViewer.display(record.categoryName);
      },
      ellipsis: true
    },
    {
      title: t('master_data:phrase:editor'),
      dataIndex: 'updatedName',
      width: 150,
      className: 'max-w-[150px] h-[38px]',
      key: 'updater',
      render: (_: any, record: IPhrase) => DataViewer.display(record?.updatedName ?? record?.createdName),
      tooltip: (_: any, record: IPhrase) => DataViewer.display(record?.updatedName ?? record?.createdName),
      ellipsis: true
    },
    {
      title: t('master_data:phrase:edited_date'),
      dataIndex: 'updatedDate',
      key: 'updatedDate',
      width: 140,
      className: 'max-w-[140px]',
      render: (_: any, record: IPhrase) => DataViewer.displayTime(record?.updatedDate || record?.createdDate),
      tooltip: (_: any, record: IPhrase) => DataViewer.displayTime(record?.updatedDate || record?.createdDate)
    }
  ];

  const generateFilterHeader = () => {
    return (
      <div className='mb-[12px]'>
        <FormProvider {...formFilter}>
          <FormBaseFilter
            onSubmit={(values) => {
              updateFilter(values);
            }}
            loading={loadingPhraseCategory}
            searchBox={{
              placeholder: DataViewer.display(
                `${t('placeholder:search')}: ${[t('master_data:phrase:name'), t('master_data:phrase:editor')].join(t('placeholder:seperate') ?? ',')}`
              )
            }}
            tagSection={{
              renderTag: (fieldKey, fieldValue) => {
                if (fieldKey === 'categoryId') {
                  const categoryName = phraseCategories?.find((i) => i.id === fieldValue)?.name ?? null;
                  return categoryName;
                }

                if (['updatedDateFrom', 'updatedDateTo'].includes(fieldKey)) {
                  return <>{formatDateTime(fieldValue)}</>;
                }

                return <>{fieldValue}</>;
              }
            }}
            primaryAction={{
              label: 'button:filter',
              name: 'primary',
              popoverContent: (
                <PrimaryFormFilter
                  onSubmit={(values) => {
                    formFilter.reset((previous) => ({ ...previous, primary: { ...values } }));
                    formFilter.handleSubmit((values) => updateFilter(values))();
                  }}
                  defaultValues={formFilter.getValues('primary') ?? {}}
                />
              ),
              refControl: filterTableRef
            }}
            secondaryAction={null}
          />
        </FormProvider>
      </div>
    );
  };

  const updateFilter = (values: FieldValues) => {
    const updatedDateFrom = values?.primary?.updatedDateFrom ? formatDateTime(values?.primary?.updatedDateFrom) : undefined;
    const updatedDateTo = values?.primary?.updatedDateTo ? getEndOfDay(values?.primary?.updatedDateTo) : undefined;
    const formValues: IQueryFilterParams = {
      keyword: values?.search,
      categoryId: values?.primary?.categoryId,
      updatedDateFrom,
      updatedDateTo
    };
    setFilter({ filter: generateFilter(formValues) });
    updateFilterConfigs({
      ...values,
      primary: {
        ...values.primary,
        updatedDateFrom,
        updatedDateTo
      }
    });
  };

  const handleDelete = async (keys: React.Key[], records: any[]) => {
    const result = await deletePhrases({
      ids: records
        .filter((i) => i)
        .map((i) => ({
          id: i.id,
          version: i.version
        }))
    });
    if (!result) return 0;

    const { data } = result;
    if (data.successCount === keys.length) {
      dispatch(
        setAlertNotification({
          show: true,
          type: TYPE.SUCCESS,
          message: t('common:MSG_C_003', { item: t('master_data:phrase:delete') })
        })
      );
    }

    if (data.successCount < keys.length) {
      dispatch(
        setAlertNotification({
          show: true,
          type: TYPE.SUCCESS,
          message: t('common:MSG_C_020', { deletable: data.successCount, total: keys.length })
        })
      );
    }
    return data.successCount;
  };

  const addNewPhrase = () => {
    setEditData({});
    setOpenEditPopup(true);
    setFilter({ ...filter });
  };

  return (
    <div ref={refList}>
      <div className='grid grid-cols-2 gap-4 page-list-header mb-[12px]'>
        <div className='title-24'>{t('sider:phrase')}</div>
        <div className='text-end'>
          {hasPermission(s.PHRASE_LIST_BUTTON_CREATE, s.V) && (
            <BaseButton size='medium' icon={<IconAddNew style={{ verticalAlign: 'sub' }} />} onClick={addNewPhrase} className='float-right'>
              {t('template_layout:create_button')}
            </BaseButton>
          )}
        </div>
      </div>
      {(hasPermission(s.PHRASE_LIST_BUTTON_CREATE, s.C) || hasPermission(s.PHRASE_LIST_BUTTON_EDIT, s.C)) && (
        <PhrasePopup
          getContainer={() => refList.current ?? document.body}
          data={editData}
          openModal={openEditPopup}
          onSubmit={() => {
            setOpenEditPopup(false);
            setFilter({ ...filter });
          }}
          onCancel={() => {
            setOpenEditPopup(false);
          }}
        />
      )}
      <div className='data-table'>
        <TableFetch
          tableHeader={generateFilterHeader()}
          apiEndpoint='/prj/phrases/search'
          apiMethod='POST'
          columns={columns}
          filterDefault={filter}
          scroll={{ y: 'calc(100vh - 284px)' }}
          showDelete={hasPermission(s.DELETE_PHRASE_LIST, s.V) ? true : false}
          showDownload={false}
          groupBy='categoryId'
          groupRowRender={(record) => {
            const label = record.children.length ? record.children[0]?.categoryName : '';
            return (
              <>
                {label}
                <span className=''>({record.children.length})</span>
              </>
            );
          }}
          handleDeleteClick={hasPermission(s.DELETE_PHRASE_LIST, s.C) ? handleDelete : undefined}
          deleteConfirmPopup={{
            type: 'discard',
            title: t('common:MSG_C_012:title', { field: t('master_data:phrase:label') }) ?? '',
            msg: t('common:MSG_C_012:description', { field: t('master_data:phrase:label') }) ?? ''
          }}
          emptyDataWithoutFilter={
            <EmptyDataWithIcon
              title={{
                content: 'master_data:phrase:create_new_label'
              }}
              description={{
                content: 'master_data:phrase:no_data_description'
              }}
              button={{
                label: 'master_data:phrase:create',
                props: {
                  onClick: addNewPhrase
                }
              }}
            />
          }
          rowSelection={!hasPermission(s.DELETE_PHRASE_LIST, s.C) ? null : {}}
        />
      </div>
    </div>
  );
}
