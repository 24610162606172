import dayjs from 'dayjs';
import { camelCase, isNil } from 'lodash';
import { FieldPath, UseFormReturn } from 'react-hook-form';

import { MAX_LENGTH_POSTAL_CODE, additionalFieldPostal } from '@pages/project-management/project-questionaire/components/question-detail/constants';

import FormPostalCodeInput from '@/components/common/postal-code-input';

import { PHONE_INPUT_MASK } from '@/utils/constants';
import { REGEX_FULL_SIZE, REGEX_LATIN_WITH_SPECIAL_CHARACTER } from '@/utils/constants/regex';
import { parseJson } from '@/utils/helpers/globalHelper';

import Checkbox from '../compononents/types/Checkbox';
import Datetime from '../compononents/types/DateTime';
import Dropdown from '../compononents/types/Dropdown';
import Radio from '../compononents/types/Radio';
import Table from '../compononents/types/Table';
import Text from '../compononents/types/Text';
import TextArea from '../compononents/types/TextArea';
import TextMulti, { ITextMultiItem } from '../compononents/types/TextMulti';
import { CONTENT_TYPE, IQuestion, IQuestionOption, IQuestionnaire } from '../modals';

export interface IMappingRenderParams {
  question: IQuestion;
  readOnly: boolean;
  name: string;
  isTranslateData?: boolean;
  onChange?: (value: any, item: any) => void;
  disabledDateFrom?: (current: any) => boolean;
  disabledDateTo?: (current: any) => boolean;
  showTranslateData?: boolean; // Flag to seperate content into 2 columns (origin and translated)
}

export const jsonObjToString = (obj: any) => {
  if (isNil(obj)) {
    return null;
  }
  if (typeof obj === 'object') {
    return JSON.stringify(obj);
  }
  return obj.toString();
};

export const isAnswered = (answered: any): boolean => {
  if (isNil(answered)) {
    return false;
  }
  const answeredObj = parseJson(answered) ?? answered;
  if (typeof answeredObj === 'string') {
    return !!answeredObj;
  }
  if (Array.isArray(answeredObj)) {
    return !!answeredObj.some((i: any) => isAnswered(i));
  }
  if (typeof answeredObj === 'object') {
    return !!Object.values(answeredObj).some((i: any) => isAnswered(i));
  }

  return true;
};

export const mappingRenderFunction: {
  [x: string]: (params: IMappingRenderParams) => React.JSX.Element[];
} = {
  [CONTENT_TYPE.TEXT]: ({ question, readOnly, name, isTranslateData }: IMappingRenderParams) => {
    return [
      <Text
        type={isTranslateData ? undefined : question.answerLanguage}
        key={question.id}
        className='h-[26px]'
        name={name}
        placeholder={question.placeHolder}
        readOnly={readOnly}
      />
    ];
  },
  [CONTENT_TYPE.TEXTPAIR_LEFT]: ({ question, readOnly, name, isTranslateData }: IMappingRenderParams) => {
    return [
      <Text
        type={isTranslateData ? undefined : question.answerLanguage}
        key={question.id}
        className='h-[26px]'
        name={name}
        placeholder={question.placeHolder}
        readOnly={readOnly}
      />
    ];
  },
  [CONTENT_TYPE.TEXTPAIR_RIGHT]: ({ question, readOnly, name, isTranslateData }: IMappingRenderParams) => {
    return [
      <Text
        type={isTranslateData ? undefined : question.answerLanguage}
        key={question.id}
        className='h-[26px]'
        name={name}
        placeholder={question.placeHolder}
        readOnly={readOnly}
      />
    ];
  },
  [CONTENT_TYPE.PHONE_NUMBER]: ({ question, readOnly, name, isTranslateData }: IMappingRenderParams) => {
    return [
      <Text
        mask={PHONE_INPUT_MASK}
        key={question.id}
        className='h-[26px]'
        name={name}
        placeholder={question.placeHolder}
        readOnly={readOnly}
        type={isTranslateData ? undefined : question.answerLanguage}
      />
    ];
  },
  [CONTENT_TYPE.POSTAL_CODE]: ({ question, readOnly, name, isTranslateData }: IMappingRenderParams) => {
    return [
      <FormPostalCodeInput
        hideBorder
        relativeFields={[
          {
            name: `${name}.prefecture`,
            key: 'prefecture'
          },
          {
            name: `${name}.prefectureRoman`,
            key: 'prefecture_roman'
          },
          {
            name: `${name}.city`,
            key: 'city'
          },
          {
            name: `${name}.cityRoman`,
            key: 'city_roman'
          },
          {
            name: `${name}.suburb`,
            key: 'suburb'
          },
          {
            name: `${name}.suburbRoman`,
            key: 'suburb_roman'
          },
          {
            name: `${name}.streetAddress`,
            key: 'street_address'
          },
          {
            name: `${name}.office`,
            key: 'office'
          }
        ]}
        key={question.id}
        name={`${name}.postalCode`}
        placeholder={readOnly ? '' : question.placeHolder}
        readOnly={readOnly}
        className='w-full max-w-[200px]'
        margin={{ left: 0 }}
        rules={{ maxLength: MAX_LENGTH_POSTAL_CODE }}
        errorType='tooltip'
      />
    ];
  },
  [CONTENT_TYPE.TEXTAREA]: ({ question, readOnly, name, isTranslateData }: IMappingRenderParams) => {
    return [
      <TextArea
        type={isTranslateData ? undefined : question.answerLanguage}
        key={question.id}
        placeholder={question.placeHolder}
        name={name}
        readOnly={readOnly}
      />
    ];
  },
  [CONTENT_TYPE.DROPDOWN]: ({ question, readOnly, name, isTranslateData }: IMappingRenderParams) => {
    const config = question.config as {
      key1: string;
      key2: string;
      getFromMaster?: boolean;
      masterApi?: string;
      options?: {
        id: string;
        title: string;
      }[];
    };
    const keyValue = config?.getFromMaster ? String(config?.key1 ?? 'id') : 'id';
    const keyLabel = config?.getFromMaster ? String(config?.key2 ?? '') : isTranslateData && question.translateFlag ? 'titleEn' : 'title';
    return [
      <Dropdown
        key={question.id}
        placeholder={question.placeHolder}
        value={question.answer?.answered}
        options={(question.options ?? (config?.options as IQuestionOption[]) ?? []).map((i) => ({
          label: i[keyLabel as keyof IQuestionOption] ?? i['title'],
          value: i[keyValue as keyof IQuestionOption]
        }))}
        readOnly={readOnly}
        name={`${name}`}
        keyLabel={keyLabel}
        keyValue={keyValue}
        endPoint={question?.masterApi || config?.masterApi || undefined}
      />
    ];
  },
  [CONTENT_TYPE.RADIO]: ({ question, readOnly, name, isTranslateData, onChange }: IMappingRenderParams) => {
    const fieldNameOrigin = isTranslateData ? name.replace('answeredEn', 'answered') : name;
    return [
      <Radio
        key={question.id}
        options={
          question.options?.map((i) => {
            const allowEditLabel = i.config?.axis === 'X' && question.answer?.answered?.id === i.id;
            const label = isTranslateData && question.translateFlag ? i.titleEn ?? i.title : i.title;
            return {
              label,
              value: i.id,
              couldChangeLabel: allowEditLabel,
              labelInputConfig: {
                placeholder: '____',
                value: i.id === question.answer?.answered?.id ? question.answer?.answered?.label : ''
              }
            };
          }) ?? []
        }
        readOnly={readOnly}
        onChange={onChange}
        name={`${fieldNameOrigin}.id`}
        optionLabelName={`${name}.title`}
        optionContentName={`${name}.label`}
      />
    ];
  },
  [CONTENT_TYPE.CHECKBOX]: ({ question, readOnly, name, isTranslateData }: IMappingRenderParams) => {
    const fieldNameOrigin = isTranslateData ? name.replace('answeredEn', 'answered') : name;
    return [
      <Checkbox
        key={question.id}
        options={
          question.options?.map((i) => ({
            label: isTranslateData && question.translateFlag ? i.titleEn ?? i.title : i.title,
            value: i.id
          })) ?? []
        }
        readOnly={readOnly}
        defaultValue={question.answer?.answered as string[]}
        name={fieldNameOrigin}
      />
    ];
  },
  [CONTENT_TYPE.DATETIME]: ({ question, readOnly, name }: IMappingRenderParams) => {
    return [
      <Datetime
        key={question.id}
        placeholder={question.placeHolder}
        readOnly={readOnly}
        value={question.answer?.answered ? dayjs(question.answer?.answered as string) : null}
        padding={{ left: 0 }}
        name={name}
      />
    ];
  },
  [CONTENT_TYPE.DATETIMEFROM_TO]: ({ question, readOnly, name, disabledDateFrom, disabledDateTo }: IMappingRenderParams) => {
    return [
      <Datetime
        key={question.id}
        placeholder={question.placeHolder}
        readOnly={readOnly}
        value={question.answer?.answered ? dayjs(question.answer?.answered as string) : null}
        labelInput={question.labelInput?.[0] ?? ''}
        name={`${name}.from`}
        disabledDate={disabledDateFrom}
      />,
      <Datetime
        key={question.id}
        placeholder={question.placeHolder}
        readOnly={readOnly}
        value={question.answer?.answered ? dayjs(question.answer?.answered as string) : null}
        labelInput={question.labelInput?.[1] ?? ''}
        name={`${name}.to`}
        disabledDate={disabledDateTo}
      />
    ];
  },
  [CONTENT_TYPE.TEXT_MULTI]: ({ question, readOnly, name, isTranslateData, showTranslateData }: IMappingRenderParams) => {
    const items = question.config as ITextMultiItem[];
    return [
      <TextMulti
        translateFlg={isTranslateData}
        key={question.id}
        items={items}
        name={name}
        readOnly={readOnly}
        maxCol={showTranslateData ? 1 : undefined}
      />
    ];
  },
  [CONTENT_TYPE.NONE]: ({ question }: IMappingRenderParams) => {
    return [<span className='font-bold'>{question.title}</span>];
  },
  [CONTENT_TYPE.TABLE]: ({ question, readOnly, name, isTranslateData }: IMappingRenderParams) => {
    const tableConfig = question.config as string[];
    const rows = +tableConfig?.[0]?.split(',')[1] || 0;
    const cols = +tableConfig?.[0]?.split(',')[0] || 0;
    const titles: string[] = (tableConfig?.[1]?.split(',') || []) as string[];
    const tableItems: any[][] = [];
    if (titles.length) {
      tableItems.push([...titles.map((i) => ({ content: <span className='body-700'>{i}</span>, className: 'bg-gray2' }))]);
    }
    for (let i = 0; i < rows; i++) {
      const row: any[] = [];
      for (let j = 0; j < cols; j++) {
        row.push({
          content: (
            <Text
              key={question.id}
              name={`${name}.${i}.${j}`}
              className='h-[38px]'
              placeholder={question.placeHolder}
              readOnly={readOnly}
              type={isTranslateData ? undefined : question.answerLanguage}
            />
          )
        });
      }
      tableItems.push(row);
    }
    return [<Table key={question.id} className='sub-table' items={tableItems} />];
  }
};

export const ValidateRules = {
  fullsize: (v: string) => !v || REGEX_FULL_SIZE.test(v),
  halfsize: (v: string) => !v || REGEX_LATIN_WITH_SPECIAL_CHARACTER.test(v),
  number: (v: string) => !v || /^[0-9]*$/.test(v)
} as const;

export const clearAnswer = (formMethod: UseFormReturn<IQuestionnaire, any>, fieldName: string) => {
  const item = formMethod.getValues(fieldName as FieldPath<IQuestionnaire>);
  const formSetValueOptions = { shouldDirty: true, shouldValidate: true, shouldTouch: true };
  if (item?.type === CONTENT_TYPE.TEXT_MULTI) {
    formMethod.setValue(
      `${fieldName}.answer.answered` as FieldPath<IQuestionnaire>,
      item.config.map((c) => {
        if (c.type === CONTENT_TYPE.DATETIMEFROM_TO) {
          return {
            from: null,
            to: null
          };
        }
        if (c.type === CONTENT_TYPE.RADIO || c.type === CONTENT_TYPE.DROPDOWN) {
          return {
            id: null
          };
        }
        if (c.type === CONTENT_TYPE.CHECKBOX) {
          return [];
        }
        return null;
      }),
      formSetValueOptions
    );
    formMethod.setValue(
      `${fieldName}.answer.answeredEn` as FieldPath<IQuestionnaire>,
      item.config.map((c) => {
        if (c.type === CONTENT_TYPE.DATETIMEFROM_TO) {
          return {
            from: null,
            to: null
          };
        }
        if (c.type === CONTENT_TYPE.RADIO || c.type === CONTENT_TYPE.DROPDOWN) {
          return {
            id: null
          };
        }
        if (c.type === CONTENT_TYPE.CHECKBOX) {
          return [];
        }
        return null;
      }),
      formSetValueOptions
    );
    return;
  }

  if (item?.type === CONTENT_TYPE.TABLE) {
    formMethod.setValue(
      `${fieldName}.answer.answered` as FieldPath<IQuestionnaire>,
      item.answer.answered.map((row: any) => row.map(() => null)),
      formSetValueOptions
    );
    formMethod.setValue(
      `${fieldName}.answer.answeredEn` as FieldPath<IQuestionnaire>,
      item.answer.answeredEn.map((row: any) => row.map(() => null)),
      formSetValueOptions
    );
    return;
  }

  if ([CONTENT_TYPE.RADIO, CONTENT_TYPE.DROPDOWN].includes(item?.type)) {
    formMethod.setValue(
      `${fieldName}.answer.answered` as FieldPath<IQuestionnaire>,
      {
        id: null,
        title: null
      },
      formSetValueOptions
    );
    formMethod.setValue(
      `${fieldName}.answer.answeredEn` as FieldPath<IQuestionnaire>,
      {
        id: null,
        title: null
      },
      formSetValueOptions
    );
    return;
  }

  if (item?.type === CONTENT_TYPE.POSTAL_CODE) {
    const objNull = additionalFieldPostal.reduce((obj: any, cur) => {
      obj[camelCase(cur.key)] = null;
      return obj;
    }, {});
    objNull['postalCode'] = null;
    formMethod.setValue(`${fieldName}.answer.answered` as FieldPath<IQuestionnaire>, { ...objNull }, formSetValueOptions);
    formMethod.setValue(`${fieldName}.answer.answeredEn` as FieldPath<IQuestionnaire>, { ...objNull }, formSetValueOptions);
    return;
  }

  if (item?.type === CONTENT_TYPE.DATETIMEFROM_TO) {
    formMethod.setValue(`${fieldName}.answer.answered` as FieldPath<IQuestionnaire>, { from: null, to: null }, formSetValueOptions);
    formMethod.setValue(`${fieldName}.answer.answeredEn` as FieldPath<IQuestionnaire>, { from: null, to: null }, formSetValueOptions);
  }

  formMethod.setValue(`${fieldName}.answer.answered` as FieldPath<IQuestionnaire>, null, formSetValueOptions);
  formMethod.setValue(`${fieldName}.answer.answeredEn` as FieldPath<IQuestionnaire>, null, formSetValueOptions);
};
