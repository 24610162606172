import { Col, Row } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BaseButton } from '@/components/base-button/BaseButton';
import BaseModal from '@/components/base-modal/BaseModal';
import { EditorWithCounter } from '@/components/form-box-editor';
import { FormInputSelect } from '@/components/form-input-select/FormInputSelect';
import { FormInput } from '@/components/form-input/FormInput';

import useOptionsGlobal from '@/hooks/useOptionsGlobal';

import { NOTE_MAX_LENGTH } from '@/utils/constants/AppConstants';
import { IDocumentMasterForm } from '@/utils/interfaces/form';
import { Ii18n } from '@/utils/interfaces/i18n';

import EditHistory from '../../components/edit-history/EditHistory';

interface CreateEditDocumentProps {
  formMethod?: any;
  openCreateModal?: boolean;
  handleCloseCreateModal?: () => Promise<void>;
  document?: IDocumentMasterForm;
  handleCreateMasterData: () => void;
  loading?: boolean;
  wrapperRef: React.MutableRefObject<any>;
}

export default function CreateEditDocument({
  formMethod,
  openCreateModal,
  handleCloseCreateModal,
  document,
  handleCreateMasterData,
  loading,
  wrapperRef
}: CreateEditDocumentProps) {
  const { t }: Ii18n = useTranslation();
  const [optionsCountry, setOptionsCountry] = useState<DefaultOptionType[]>([]);
  const { countryOptions } = useOptionsGlobal();
  useEffect(() => {
    if (countryOptions) {
      setOptionsCountry(countryOptions);
    }
  }, [countryOptions]);

  const { isDirty, isSubmitting } = formMethod.formState;

  const disableSaveBtn = () => {
    const id = formMethod.getValues('id');
    return (!isDirty && id) || isSubmitting || loading;
  };

  return (
    <>
      {openCreateModal && (
        <BaseModal
          openModal={openCreateModal}
          maskClosable={false}
          onCancel={handleCloseCreateModal}
          className='!w-[912px] document-master-modal'
          destroyOnClose
          contentElement={
            <FormProvider {...formMethod}>
              <div ref={wrapperRef}>
                <div className='title-20 text-start mb-3'>
                  {formMethod.getValues('id')
                    ? t('master_data:document_management:edit_document')
                    : t('master_data:document_management:no_data_title') || ''}
                </div>
                <Row gutter={16}>
                  <Col span={12} className='mb-4'>
                    <FormInput
                      placeholder={`${t('common:MSG_001_textbox', {
                        field: t('master_data:document_management:document_name')
                      })}`}
                      name='name'
                      defaultValue={document?.name || undefined}
                      labelTx={t('master_data:document_management:document_name') || ''}
                      required
                    />
                  </Col>

                  <Col span={12} className='mb-4'>
                    <FormInput
                      placeholder={`${t('common:MSG_001_textbox', {
                        field: t('master_data:document_management:submitter')
                      })}`}
                      name='submitterName'
                      defaultValue={document?.submitterName || undefined}
                      labelTx={t('master_data:document_management:submitter') || ''}
                      required
                    />
                  </Col>

                  <Col span={12} className='mb-4'>
                    <FormInputSelect
                      required
                      disabled={!!document?.id}
                      name='countryId'
                      options={optionsCountry}
                      labelTx={t('master_data:document_management:country') || ''}
                      placeholderTx={`${t('common:MSG_001_select', { field: t('master_data:document_management:country') })}`}
                      searchMaxLength={128}
                    />
                  </Col>

                  <Col span={12} className='mb-4'>
                    <FormInput
                      placeholder={`${t('common:MSG_001_textbox', { field: t('master_data:document_management:numOfCopy') })}`}
                      name='numOfCopy'
                      defaultValue={document?.numOfCopy || undefined}
                      labelTx={t('master_data:document_management:numOfCopy') || ''}
                    />
                  </Col>

                  <Col span={24} className='mb-4'>
                    <EditorWithCounter
                      name='note'
                      limit={NOTE_MAX_LENGTH}
                      label={'master_data:document_management:note'}
                      defaultValue={document?.note || undefined}
                      placeholder={`${t('common:MSG_001_textbox', { field: t('master_data:document_management:note') })}`}
                      editorWrapperProps={{ className: '!h-[220px]' }}
                      styleCounterLabel='text-gray3'
                    />
                  </Col>

                  <Col span={24} className='mb-4'>
                    <EditorWithCounter
                      name='memo'
                      limit={NOTE_MAX_LENGTH}
                      label={'master_data:document_management:memo'}
                      defaultValue={document?.memo || undefined}
                      placeholder={`${t('common:MSG_001_textbox', { field: t('master_data:document_management:memo') })}`}
                      editorWrapperProps={{ className: '!h-[220px]' }}
                      styleCounterLabel='text-gray3'
                    />
                  </Col>
                </Row>
                {formMethod.getValues('id') && <EditHistory data={document ?? {}} />}
                <Row className='doccument-button-form justify-end gap-4'>
                  <Col>
                    <BaseButton type='tertiary' size='medium' className='!py-2 !px-3 ' onClick={handleCloseCreateModal}>
                      {t('button:cancel')}
                    </BaseButton>
                  </Col>
                  <Col>
                    <BaseButton
                      disabled={disableSaveBtn()}
                      type='primary'
                      size='medium'
                      htmlType='submit'
                      className='!py-2 !px-3'
                      onClick={handleCreateMasterData}
                    >
                      <span> {t('button:keep')} </span>
                    </BaseButton>
                  </Col>
                </Row>
              </div>
            </FormProvider>
          }
        />
      )}
    </>
  );
}
