import { t } from 'i18next';
import * as yup from 'yup';

import { CONTENT_MAX_LENGTH, TEXT_FIELD_PHONE_NUMBER_MAX_LENGTH, UPLOAD_STATUS } from '@/utils/constants/AppConstants';
import { REGEX_EMAIL_RULE } from '@/utils/constants/regex';
import { stringifyObjectValidateYup } from '@/utils/method';

export const DEFAULT_INQUIRY_PROJECT = {
  projectId: null,
  name: null,
  company: null,
  emails: [],
  phoneNumber: null,
  content: null,
  attachmentsInquiry: []
};

export const FormInquiry = yup.object().shape({
  emails: yup
    .array()
    .min(
      1,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_001_input',
        optionsTx: { field: t('inquiry:email_address_label') || '' }
      })
    )
    .test({
      name: 'valid-mail_address',
      exclusive: false,
      params: {},
      message: stringifyObjectValidateYup({
        keyT: 'common:MSG_A_016'
      }),
      test: (values) => {
        const isError = values?.find((str) => !REGEX_EMAIL_RULE.test(str));
        return !isError;
      }
    }),
  content: yup
    .string()
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: t('inquiry:content_label') || '' }
      })
    )
    .requiredTextEditorChange(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: t('inquiry:content_label') || '' }
      })
    )
    .htmlStrippedMaxLength(
      CONTENT_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'inquiry:content_label', limited: CONTENT_MAX_LENGTH }
      })
    ),
  attachmentsInquiry: yup.array().of(
    yup.object().shape({
      status: yup.string().test(
        'file-invalid',
        stringifyObjectValidateYup({
          keyT: 'common:MSG_FILE_INVALID'
        }),
        (value) => {
          return value === UPLOAD_STATUS.DONE;
        }
      )
    })
  ),
  phoneNumber: yup
    .string()
    .max(
      TEXT_FIELD_PHONE_NUMBER_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'inquiry:phone_label', limited: TEXT_FIELD_PHONE_NUMBER_MAX_LENGTH }
      })
    )
    .halfSize(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_028'
      })
    )
    .notRequired()
});

export enum FIELD_PROJECT {
  PROJECT_ID = 'projectId',
  NAME = 'name',
  COMPANY = 'company',
  PHONE = 'phoneNumber',
  CONTENT = 'content',
  EMAILS = 'emails',
  ATTACHMENTS_INQUIRY = 'attachmentsInquiry'
}

export const MAX_TAG = 30;
