import { IProjectInfo } from '@//pages/project-management/add-edit/models';
import CaretDown from '@/layouts/assets/icons/CaretDown.svg';
import { Collapse, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { BaseButton } from '@/components/base-button/BaseButton';

import { INTERNAL_ADMIN, INTERNAL_GENERAL_USER, LOCALSTORAGE } from '@/utils/constants/AppConstants';
import { HTTP_STATUS_CODE } from '@/utils/constants/Http';
import { EDIT_PROJECT_BASIC_INFO_URL } from '@/utils/constants/RouteContants';
import { handleCommonError } from '@/utils/helpers/common';
import { renderProjectCode } from '@/utils/helpers/project';
import { getProjectInfo } from '@/utils/services/ProjectApiService';

import PencilSimpleLine from '@/assets/icons/PencilSimpleLine.svg';

import { BASIC_INFO_SECTION_KEYS } from '../../contants';
import { SectionBuilder } from './components/BasicInfoBuilder';

import './BasicInformation.scss';

const BasicInformation = () => {
  // state
  const [projectData, setProjectData] = useState<IProjectInfo>();
  const [showButtonEdit, setShowButtonEdit] = useState<boolean>(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { id } = useParams();

  const [activeKeys, setActiveKeys] = useState<string | string[]>(Object.values(BASIC_INFO_SECTION_KEYS));

  const getProjectData = async (projectId: string) => {
    try {
      const responses = await getProjectInfo(projectId);
      if (responses.status !== HTTP_STATUS_CODE.SUCCESS) return;
      setProjectData(responses.data);
    } catch (error: any) {
      handleCommonError(error);
    }
  };

  useEffect(() => {
    const localStorageUser = localStorage.getItem(LOCALSTORAGE.USER);
    const parseLocalStorageUser = localStorageUser ? JSON.parse(localStorageUser) : {};
    if ([INTERNAL_ADMIN, INTERNAL_GENERAL_USER].includes(parseLocalStorageUser.role)) {
      setShowButtonEdit(true);
    }
  }, []);

  useEffect(() => {
    if (!id) return;
    getProjectData(id);
  }, [id]);

  const generateBasicInfoView = () => {
    const sections = new SectionBuilder({ projectData, translate: t })
      .drawApplicantSection()
      .drawRequestSection()
      .drawApplicantDetailSection()
      .drawFamiliesSection()
      .drawAssignmentSection()
      .drawSupporterSection()
      .drawVisaInfoSection()
      .drawTravelTypeInfoSection()
      .drawMoreInfoSection()
      .getViews();

    return sections;
  };

  const renderExpandIcon = (isActive: boolean | undefined) => {
    return <CaretDown type='caret-right' className={`${isActive ? 'rotate-0' : '-rotate-90'}`} />;
  };
  const title = renderProjectCode(projectData, t('basic_information:proposal_number'));
  // render
  return (
    <div className={`form-basic-information`}>
      <div className='project-tabs-content container-scroll flex flex-col'>
        <header className='header-basic-information flex flex-col sticky top-0'>
          <div className='flex flex-row justify-between'>
            <div className='flex flex-col'>
              <div className='title-24'>{t('basic_information:basic_information')}</div>
              <div className='title-14'>
                {title}
                <span className='proposal-number'>{projectData?.code ? `(${projectData.code})` : ''}</span>
              </div>
            </div>
            {showButtonEdit && (
              <BaseButton
                type='secondary'
                size='medium'
                disabled={false}
                icon={<PencilSimpleLine />}
                onClick={() => {
                  navigate(EDIT_PROJECT_BASIC_INFO_URL(id || ''));
                }}
              >
                <div>{t('template_layout:edit_button')}</div>
              </BaseButton>
            )}
          </div>
        </header>
        <div className='flex flex-col h-[calc(100vh-100px)] overflow-y-scroll pb-4'>
          {generateBasicInfoView().map((items) => {
            return (
              <Space
                key={items?.key}
                size={24}
                direction={'vertical'}
                className='bg-white mt-[12px] box-shadow rounded-[14px] border-[1px] border-solid border-[#DBDBE0]'
              >
                <Collapse
                  collapsible='header'
                  items={[items]}
                  bordered={false}
                  onChange={setActiveKeys}
                  activeKey={activeKeys}
                  expandIcon={({ isActive }) => renderExpandIcon(isActive)}
                />
              </Space>
            );
          })}
        </div>
        <div className='mt-[24px]'></div>
      </div>
    </div>
  );
};

export default BasicInformation;
