import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OptionTypeMotPic } from '@/pages/project-management/add-edit/models';

import { Ii18n } from '@/utils/interfaces/i18n';
import { getUsersMotPic } from '@/utils/services/UserApiService';

type Params = {
  showInactive?: boolean;
};
const useMotPic = (params: Params = {}) => {
  const { showInactive } = params;
  const { t }: Ii18n = useTranslation();
  const [userMotPicOptions, setUserMotPicOptions] = useState<OptionTypeMotPic[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getDataUsersMotPic();
  }, []);

  const getDataUsersMotPic = async () => {
    try {
      setLoading(true);
      const { data } = await getUsersMotPic(showInactive);
      let options = data?.map((account: any) => {
        const { name, id, email, status } = account || {};
        let accountName = name;
        if (!status) accountName = `(${t('account_list:status_account:inactive')})${name}`;
        return {
          label: accountName,
          value: id,
          email,
          status
        };
      });
      setUserMotPicOptions(options);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    userMotPicOptions
  };
};

export default useMotPic;
