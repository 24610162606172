import { Col, Row } from 'antd';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseCollapse } from '@/components/base-collapse/BaseCollapse';
import FormPostalCodeInput from '@/components/common/postal-code-input';
import { FormInputDate } from '@/components/form-input-date/FormInputDate';
import { FormInputSelect } from '@/components/form-input-select/FormInputSelect';
import { FormInput } from '@/components/form-input/FormInput';

import useOptionsGlobal from '@/hooks/useOptionsGlobal';

import { POSTAL_CODE_MASK } from '@/utils/constants';

import { GENDER } from '../../project-list/constants';
import { POSTAL_CODE_RELATIVE_FIELDS, WATCH_FIELDS_PROJECT_APPLICANT, WATCH_FIELDS_PROJECT_APPLICANT_REQUIRED } from '../contants';

type Props = {
  formMethod: any;
};

const ApplicantRepresentativeComponent = forwardRef((props: Props, ref) => {
  const { formMethod } = props;
  const { t } = useTranslation();
  const { countryOptions } = useOptionsGlobal();
  const gender = [
    {
      label: t('common:male'),
      value: GENDER.male,
      id: GENDER.male
    },
    {
      label: t('common:female'),
      value: GENDER.female,
      id: GENDER.female
    }
  ];

  const watchFields = formMethod.watch(WATCH_FIELDS_PROJECT_APPLICANT);
  const watchFieldsValid = watchFields.filter((field: any) => field);
  const watchFieldsRequired = formMethod.watch(WATCH_FIELDS_PROJECT_APPLICANT_REQUIRED);
  const watchFieldsRequiredValid = watchFieldsRequired.filter((field: any) => field);

  const isError = () => {
    return watchFieldsRequiredValid.length !== watchFieldsRequired.length || !!formMethod?.formState?.errors?.projectApplicant;
  };

  return (
    <BaseCollapse
      ref={ref}
      id='projectApplicant'
      title={t('project:add:applicant_representative:applicant_representative_information')}
      total={20}
      questions={watchFieldsValid.length}
      isError={isError()}
    >
      <div className='grid gap-4'>
        <Row gutter={16}>
          <Col span={6}>
            <FormInput
              required={true}
              defaultValue={formMethod.getValues('projectApplicant.lastName')}
              name='projectApplicant.lastName'
              labelTx='project:add:applicant_representative:last_name'
              placeholderTx='project:add:applicant_representative:example_yamada'
            />
          </Col>
          <Col span={6}>
            <FormInput
              defaultValue={formMethod.getValues('projectApplicant.firstName')}
              required={true}
              name='projectApplicant.firstName'
              labelTx='project:add:applicant_representative:first_name'
              placeholderTx='project:add:applicant_representative:example_taro'
            />
          </Col>
          <Col span={6}>
            <FormInput
              required={true}
              defaultValue={formMethod.getValues('projectApplicant.lastNameRomaji')}
              name='projectApplicant.lastNameRomaji'
              labelTx='project:add:applicant_representative:last_name_romaji'
              placeholderTx='project:add:applicant_representative:example_yamada_passport_notation'
            />
          </Col>
          <Col span={6}>
            <FormInput
              required={true}
              defaultValue={formMethod.getValues('projectApplicant.firstNameRomaji')}
              name='projectApplicant.firstNameRomaji'
              labelTx='project:add:applicant_representative:first_name_romaji'
              placeholderTx='project:add:applicant_representative:example_taro_passport'
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={6}>
            <FormInputSelect
              name='projectApplicant.primaryNational'
              label={t('project:add:applicant_representative:nationality')}
              options={countryOptions}
              placeholderTx={String(t('project:add:applicant_representative:example_japan'))}
              width={'50%'}
            />
          </Col>
          <Col span={6}>
            <FormInputSelect
              name='projectApplicant.secondaryNational'
              label={t('project:add:applicant_representative:second_nationality')}
              options={countryOptions}
              placeholderTx={String(t('project:add:applicant_representative:example_america'))}
              width={'50%'}
            />
          </Col>
          <Col span={6}>
            <FormInputDate
              name='projectApplicant.dateOfBirth'
              labelTx='project:add:applicant_representative:date_of_birth'
              placeholderTx={t('placeholder:date') ?? ''}
            />
          </Col>
          <Col span={6}>
            <FormInputSelect
              name='projectApplicant.gender'
              label={t('project:add:applicant_representative:sex')}
              options={gender}
              placeholderTx={String(t('project:add:please_select_field', { field: t('project:add:applicant_representative:sex') }))}
              width={'50%'}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={6}>
            <FormPostalCodeInput
              defaultValue={formMethod.getValues('projectApplicant.postalCode')}
              name='projectApplicant.postalCode'
              label={t('basic_information:postal_code_number') ?? ''}
              placeholder={t('postal_code:postal_code_placeholder') ?? ''}
              relativeFields={POSTAL_CODE_RELATIVE_FIELDS}
              mask={POSTAL_CODE_MASK}
              required
            />
          </Col>
          <Col span={6}>
            <FormInput
              defaultValue={formMethod.getValues('projectApplicant.prefecture')}
              required={true}
              name='projectApplicant.prefecture'
              labelTx='postal_code:prefecture'
              placeholderTx='postal_code:prefecture_placeholder'
            />
          </Col>
          <Col span={6}>
            <FormInput
              defaultValue={formMethod.getValues('projectApplicant.prefectureRoman')}
              required={true}
              name='projectApplicant.prefectureRoman'
              labelTx='postal_code:prefecture_roman'
              placeholderTx='postal_code:prefecture_roman_placeholder'
            />
          </Col>
          <Col span={6}>
            <FormInput
              defaultValue={formMethod.getValues('projectApplicant.city')}
              required={true}
              name='projectApplicant.city'
              labelTx='postal_code:city'
              placeholderTx='postal_code:city_placeholder'
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={6}>
            <FormInput
              defaultValue={formMethod.getValues('projectApplicant.cityRoman')}
              required={true}
              name='projectApplicant.cityRoman'
              labelTx='postal_code:city_roman'
              placeholderTx='postal_code:city_roman_placeholder'
            />
          </Col>
          <Col span={6}>
            <FormInput
              defaultValue={formMethod.getValues('projectApplicant.suburb')}
              required={true}
              name='projectApplicant.suburb'
              labelTx='postal_code:suburb'
              placeholderTx='postal_code:suburb_placeholder'
            />
          </Col>
          <Col span={6}>
            <FormInput
              defaultValue={formMethod.getValues('projectApplicant.suburbRoman')}
              required={true}
              name='projectApplicant.suburbRoman'
              labelTx='postal_code:suburb_roman'
              placeholderTx='postal_code:suburb_roman_placeholder'
            />
          </Col>
          <Col span={6}>
            <FormInput
              defaultValue={formMethod.getValues('projectApplicant.streetAddress')}
              required={true}
              name='projectApplicant.streetAddress'
              labelTx='postal_code:street_address'
              placeholderTx='postal_code:street_address_placeholder'
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <FormInput
              defaultValue={formMethod.getValues('projectApplicant.apartmentName')}
              name='projectApplicant.apartmentName'
              labelTx='postal_code:office'
              placeholderTx='postal_code:office_placeholder'
            />
          </Col>
          <Col span={12}>
            <FormInput
              defaultValue={formMethod.getValues('projectApplicant.placeOfResidence')}
              name='projectApplicant.placeOfResidence'
              labelTx='project:add:applicant_representative:resident_registration_place'
              placeholderTx='project:add:applicant_representative:please_enter_registration_location'
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <FormInput
              required={true}
              defaultValue={formMethod.getValues('projectApplicant.currentCompany')}
              name='projectApplicant.currentCompany'
              labelTx='project:add:applicant_representative:current_company'
              placeholderTx='project:add:applicant_representative:example_company_name'
            />
          </Col>
          <Col span={12}>
            <FormInputDate
              name='projectApplicant.departureDate'
              labelTx='project:add:applicant_representative:departure_date'
              placeholderTx={t('project:add:applicant_representative:please_select_departure') ?? ''}
            />
          </Col>
        </Row>
      </div>
    </BaseCollapse>
  );
});

export default ApplicantRepresentativeComponent;
