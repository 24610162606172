import { QUERY_CONDITION } from '@/utils/constants';
import QueryFactory, { QueryItem } from '@/utils/helpers/filters/base';
import { convertFullWidthToHalfWidth } from '@/utils/helpers/globalHelper';

export interface IQueryFilterParams {
  keyword?: string;
  categoryId?: string;
  updatedDateFrom?: string;
  updatedDateTo?: string;
}

export class PhrasesQueryFactory extends QueryFactory {
  getByCategoryId(categoryId: string) {
    const newQuerySection = this.initNewQuery(this.data?.length ? QUERY_CONDITION.AND : QUERY_CONDITION.EMPTY);
    const picIdQuery = new QueryItem().modules.guidWith(categoryId, { fieldTitle: 'categoryId' });
    newQuerySection.childrens?.push(picIdQuery);
    this.append(newQuerySection);
    return this;
  }

  updatedDateFrom(date: string) {
    const newQuerySection = this.initNewQuery(this.data?.length ? QUERY_CONDITION.AND : QUERY_CONDITION.EMPTY);
    newQuerySection?.childrens?.push(new QueryItem().modules.fromDate(date, { fieldTitle: 'updatedDate' }));
    this.append(newQuerySection);
    return this;
  }

  updatedDateTo(date: string) {
    const newQuerySection = this.initNewQuery(this.data?.length ? QUERY_CONDITION.AND : QUERY_CONDITION.EMPTY);
    newQuerySection?.childrens?.push(new QueryItem().modules.toDate(date, { fieldTitle: 'updatedDate' }));
    this.append(newQuerySection);
    return this;
  }
}

export const generateFilter = (params?: IQueryFilterParams): string => {
  const filterFactory = new PhrasesQueryFactory();

  if (params?.keyword) {
    const fields = ['titleSearch.ToLower()', 'updatedNameSearch.ToLower()'];
    filterFactory.searchingBy(fields, convertFullWidthToHalfWidth(params.keyword).toLowerCase());
  }

  if (params?.categoryId) {
    filterFactory.getByCategoryId(params.categoryId);
  }

  if (params?.updatedDateFrom) {
    filterFactory.updatedDateFrom(params?.updatedDateFrom);
  }

  if (params?.updatedDateTo) {
    filterFactory.updatedDateTo(params?.updatedDateTo);
  }

  return filterFactory.sanitize().stringifyData();
};
