import * as yup from 'yup';

import { NOTE_BASIC_INFO_MAX_LENGTH, TEXT_FIELD_MAX_LENGTH, TEXT_FIELD_SHORT_MAX_LENGTH, UPLOAD_STATUS } from '@/utils/constants/AppConstants';
import { stringifyObjectValidateYup } from '@/utils/method';

interface ICustomError {
  type: string;
  message: string;
}

export class CustomError extends Error {
  type: string;

  constructor({ type, message }: ICustomError) {
    super(message);
    this.type = type;

    Object.setPrototypeOf(this, CustomError.prototype);
  }
}

export const FormProject = yup.object().shape({
  applicantName: yup
    .string()
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: 'project:add:applicant:applicant_name' }
      })
    )
    .max(
      TEXT_FIELD_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'project:add:applicant:applicant_name', limited: TEXT_FIELD_MAX_LENGTH }
      })
    ),
  applicantCompany: yup.object().required(
    stringifyObjectValidateYup({
      keyT: 'common:MSG_001_select',
      optionsTx: { field: 'project:add:applicant:applicant_company' }
    })
  ),
  projectApplicant: yup.object().shape({
    lastName: yup
      .string()
      .required(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_001_textbox',
          optionsTx: { field: 'project:add:applicant_representative:last_name' }
        })
      )
      .max(
        TEXT_FIELD_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: { field: 'project:add:applicant_representative:last_name', limited: TEXT_FIELD_MAX_LENGTH }
        })
      ),
    firstName: yup
      .string()
      .required(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_001_textbox',
          optionsTx: { field: 'project:add:applicant_representative:first_name' }
        })
      )
      .max(
        TEXT_FIELD_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: { field: 'project:add:applicant_representative:first_name', limited: TEXT_FIELD_MAX_LENGTH }
        })
      ),
    lastNameRomaji: yup
      .string()
      .halfSize(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_028'
        })
      )
      .required(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_001_textbox',
          optionsTx: { field: 'project:add:applicant_representative:last_name_romaji' }
        })
      )
      .max(
        TEXT_FIELD_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: { field: 'project:add:applicant_representative:last_name_romaji', limited: TEXT_FIELD_MAX_LENGTH }
        })
      ),
    firstNameRomaji: yup
      .string()
      .halfSize(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_028'
        })
      )
      .required(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_001_textbox',
          optionsTx: { field: 'project:add:applicant_representative:first_name_romaji' }
        })
      )
      .max(
        TEXT_FIELD_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: { field: 'project:add:applicant_representative:first_name_romaji', limited: TEXT_FIELD_MAX_LENGTH }
        })
      ),
    postalCode: yup.string().required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: 'basic_information:postal_code_number' }
      })
    ),
    prefecture: yup
      .string()
      .required(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_001_textbox',
          optionsTx: { field: 'postal_code:prefecture' }
        })
      )
      .max(
        TEXT_FIELD_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: { field: 'postal_code:prefecture', limited: TEXT_FIELD_MAX_LENGTH }
        })
      ),
    prefectureRoman: yup
      .string()
      .halfSize(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_028'
        })
      )
      .required(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_001_textbox',
          optionsTx: { field: 'postal_code:prefecture_roman' }
        })
      )
      .max(
        TEXT_FIELD_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: { field: 'postal_code:prefecture_roman', limited: TEXT_FIELD_MAX_LENGTH }
        })
      ),
    city: yup
      .string()
      .required(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_001_textbox',
          optionsTx: { field: 'postal_code:city' }
        })
      )
      .max(
        TEXT_FIELD_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: { field: 'postal_code:city', limited: TEXT_FIELD_MAX_LENGTH }
        })
      ),
    cityRoman: yup
      .string()
      .halfSize(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_028'
        })
      )
      .required(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_001_textbox',
          optionsTx: { field: 'postal_code:city_roman' }
        })
      )
      .max(
        TEXT_FIELD_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: { field: 'postal_code:city_roman', limited: TEXT_FIELD_MAX_LENGTH }
        })
      ),
    suburb: yup
      .string()
      .required(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_001_textbox',
          optionsTx: { field: 'postal_code:suburb' }
        })
      )
      .max(
        TEXT_FIELD_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: { field: 'postal_code:suburb', limited: TEXT_FIELD_MAX_LENGTH }
        })
      ),
    suburbRoman: yup
      .string()
      .halfSize(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_028'
        })
      )
      .required(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_001_textbox',
          optionsTx: { field: 'postal_code:suburb_roman' }
        })
      )
      .max(
        TEXT_FIELD_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: { field: 'postal_code:suburb_roman', limited: TEXT_FIELD_MAX_LENGTH }
        })
      ),
    streetAddress: yup
      .string()
      .required(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_001_textbox',
          optionsTx: { field: 'postal_code:street_address' }
        })
      )
      .max(
        TEXT_FIELD_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: { field: 'postal_code:street_address', limited: TEXT_FIELD_MAX_LENGTH }
        })
      ),
    apartmentName: yup.string().max(
      TEXT_FIELD_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'postal_code:office', limited: TEXT_FIELD_MAX_LENGTH }
      })
    ),
    placeOfResidence: yup
      .string()
      .max(
        TEXT_FIELD_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: {
            field: 'project:add:applicant_representative:resident_registration_place',
            limited: TEXT_FIELD_MAX_LENGTH
          }
        })
      )
      .max(
        TEXT_FIELD_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: {
            field: 'project:add:applicant_representative:resident_registration_place',
            limited: TEXT_FIELD_MAX_LENGTH
          }
        })
      ),
    currentCompany: yup
      .string()
      .required(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_001_textbox',
          optionsTx: { field: 'project:add:applicant_representative:current_company' }
        })
      )
      .max(
        TEXT_FIELD_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: { field: 'project:add:applicant_representative:current_company', limited: TEXT_FIELD_MAX_LENGTH }
        })
      )
  }),
  projectFamilies: yup.array().of(
    yup.object().shape({
      lastName: yup.string().max(
        TEXT_FIELD_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: { field: 'project:add:applicant_representative:last_name', limited: TEXT_FIELD_MAX_LENGTH }
        })
      ),
      firstName: yup.string().max(
        TEXT_FIELD_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: { field: 'project:add:applicant_representative:first_name', limited: TEXT_FIELD_MAX_LENGTH }
        })
      ),
      lastNameRomaji: yup
        .string()
        .halfSize(
          stringifyObjectValidateYup({
            keyT: 'common:MSG_C_028'
          })
        )
        .max(
          TEXT_FIELD_MAX_LENGTH,
          stringifyObjectValidateYup({
            keyT: 'common:MSG_C_011',
            optionsTx: {
              field: 'project:add:applicant_representative:last_name_romaji',
              limited: TEXT_FIELD_MAX_LENGTH
            }
          })
        ),
      firstNameRomaji: yup
        .string()
        .halfSize(
          stringifyObjectValidateYup({
            keyT: 'common:MSG_C_028'
          })
        )
        .max(
          TEXT_FIELD_MAX_LENGTH,
          stringifyObjectValidateYup({
            keyT: 'common:MSG_C_011',
            optionsTx: {
              field: 'project:add:applicant_representative:first_name_romaji',
              limited: TEXT_FIELD_MAX_LENGTH
            }
          })
        ),
      attendanceTime: yup.string().max(
        TEXT_FIELD_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: { field: 'project:add:family_information:attendance_time', limited: TEXT_FIELD_MAX_LENGTH }
        })
      )
    })
  ),
  projectLocalSupporters: yup.array().of(
    yup.object().shape({
      name: yup.string().max(
        TEXT_FIELD_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: { field: 'project:add:local_representative:name', limited: TEXT_FIELD_MAX_LENGTH }
        })
      ),
      address: yup.string().max(
        TEXT_FIELD_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: { field: 'project:add:local_representative:address', limited: TEXT_FIELD_MAX_LENGTH }
        })
      ),
      email: yup
        .string()
        .fullEmail(
          stringifyObjectValidateYup({
            keyT: 'common:MSG_A_016',
            optionsTx: { field: 'project:add:local_representative:email' }
          })
        )
        .max(
          TEXT_FIELD_MAX_LENGTH,
          stringifyObjectValidateYup({
            keyT: 'common:MSG_C_011',
            optionsTx: { field: 'project:add:local_representative:email', limited: TEXT_FIELD_MAX_LENGTH }
          })
        )
    })
  ),
  projectAssignmentInfo: yup.object().shape({
    name: yup.string().max(
      TEXT_FIELD_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'basic_information:assignee_trainee_name', limited: TEXT_FIELD_MAX_LENGTH }
      })
    ),
    companyName: yup.string().max(
      TEXT_FIELD_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'basic_information:assignment_training_company_name', limited: TEXT_FIELD_MAX_LENGTH }
      })
    ),
    countryId: yup.string().required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_select',
        optionsTx: { field: 'basic_information:destination_training_country' }
      })
    ),
    address: yup.string().max(
      TEXT_FIELD_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'basic_information:assignment_training_city', limited: TEXT_FIELD_MAX_LENGTH }
      })
    )
  }),
  note: yup.string().htmlStrippedMaxLength(
    NOTE_BASIC_INFO_MAX_LENGTH,
    stringifyObjectValidateYup({
      keyT: 'common:MSG_C_011',
      optionsTx: { field: 'basic_information:note', limited: NOTE_BASIC_INFO_MAX_LENGTH }
    })
  ),
  projectAttachments: yup.array().of(
    yup.object().shape({
      status: yup.string().test(
        'file-invalid',
        stringifyObjectValidateYup({
          keyT: 'common:MSG_FILE_INVALID'
        }),
        (value) => {
          return value === UPLOAD_STATUS.DONE;
        }
      )
    })
  )
});

export const FormCreateAccount = yup.object().shape({
  stakeholderName: yup
    .string()
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: 'basic_information:username' }
      })
    )
    .notUrl(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_092',
        optionsTx: { field: 'basic_information:username' }
      })
    )
    .max(
      TEXT_FIELD_SHORT_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'basic_information:username', limited: TEXT_FIELD_SHORT_MAX_LENGTH }
      })
    ),
  stakeholderEmail: yup
    .string()
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: 'basic_information:email_address' }
      })
    )
    .fullEmail(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_A_016',
        optionsTx: { field: 'basic_information:email_address' }
      })
    )
    .max(
      TEXT_FIELD_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'basic_information:email_address', limited: TEXT_FIELD_MAX_LENGTH }
      })
    ),
  role: yup.object().required(
    stringifyObjectValidateYup({
      keyT: 'common:MSG_001_select',
      optionsTx: { field: 'account_list:role' }
    })
  )
});

export const FormRegisterProject = FormProject.concat(
  yup.object().shape({
    projectStakeholders: yup.array().of(
      yup.object().shape({
        stakeholderEmail: yup
          .string()
          .fullEmail(
            stringifyObjectValidateYup({
              keyT: 'common:MSG_A_016',
              optionsTx: { field: 'project:add:project_stakeholders:email_address' }
            })
          )
          .max(
            TEXT_FIELD_MAX_LENGTH,
            stringifyObjectValidateYup({
              keyT: 'common:MSG_C_011',
              optionsTx: { field: 'project:add:project_stakeholders:email_address', limited: TEXT_FIELD_MAX_LENGTH }
            })
          )
          .stakeHolderEmailInternalValidate()
      })
    )
  })
);
