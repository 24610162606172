import { useTranslation } from 'react-i18next';

import { DataViewer } from '@/utils/helpers/common';

import ScenarioA from '@/assets/icons/scenario/ScenarioA.png';
import ScenarioB from '@/assets/icons/scenario/ScenarioB.png';
import ScenarioC from '@/assets/icons/scenario/ScenarioC.png';
import ScenarioD from '@/assets/icons/scenario/ScenarioD.png';
import ScenarioE from '@/assets/icons/scenario/ScenarioE.png';

import { SCENARIO_TYPES } from '../../../contants';

export interface ICategories {
  selected: SCENARIO_TYPES;
  onChange: (value: SCENARIO_TYPES) => void;
}
export default function Categories({ selected, onChange }: ICategories) {
  const { t } = useTranslation();
  const SCENARIO = [
    {
      value: SCENARIO_TYPES.SCENARIO_A,
      title: t('project:scenario:request_type_a'),
      icon: ScenarioA
    },
    {
      value: SCENARIO_TYPES.SCENARIO_B,
      title: t('project:scenario:request_type_b'),
      icon: ScenarioB
    },
    {
      value: SCENARIO_TYPES.SCENARIO_C,
      title: t('project:scenario:request_type_c'),
      icon: ScenarioC
    },
    {
      value: SCENARIO_TYPES.SCENARIO_D,
      title: t('project:scenario:request_type_d'),
      icon: ScenarioD
    },
    {
      value: SCENARIO_TYPES.SCENARIO_E,
      title: t('project:scenario:request_type_e'),
      icon: ScenarioE
    }
  ];
  return (
    <div className='flex gap-4 mt-3'>
      {SCENARIO.map((item, index) => (
        <div
          key={index}
          className={`flex flex-col bg-green10 h-[569px] w-[304px] pt-[172px] items-center rounded-xl px-6 hover:bg-greenGray cursor-pointer ${selected === item.value ? 'border-solid border-[7px] border-green bg-white' : ''}`}
          onClick={() => onChange(item.value)}
        >
          <img src={item.icon} alt='' />
          <p
            className={`title-20 text-center mt-6 ${selected === item.value ? 'text-green' : ''}`}
            dangerouslySetInnerHTML={{ __html: DataViewer.displayAsSanitizeHTML(item.title) }}
          ></p>
        </div>
      ))}
    </div>
  );
}
