import { Col, Row } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { useEffect, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BaseButton } from '@/components/base-button/BaseButton';
import { FormInputDate } from '@/components/form-input-date/FormInputDate';
import { FormInputSelect } from '@/components/form-input-select/FormInputSelect';

import useFetch from '@/hooks/useFetch';
import useAuthorization from '@hooks/useAuthorization';

import { Ii18n } from '@/utils/interfaces/i18n';

interface IFilterProps {
  onSubmit: (values: FieldValues) => void;
  onReset?: () => void;
  defaultValues?: any;
}

const Filter = ({ onSubmit, defaultValues }: IFilterProps) => {
  const { isInternalRole } = useAuthorization();
  const form = useForm({ defaultValues });
  const [companyNameOptions, setCompanyNameOptions] = useState<DefaultOptionType[]>([]);
  const [roleOptions, setRoleOptions] = useState<DefaultOptionType[]>([]);
  const [statusOptions, setStatusOptions] = useState<DefaultOptionType[]>([]);
  const { t }: Ii18n = useTranslation();

  const { data: companyName } = useFetch<DefaultOptionType[]>(`/usr/organizations`, 'GET', undefined, undefined, { autoFetch: isInternalRole });
  const { data: role } = useFetch<DefaultOptionType[]>(`/usr/roles`, 'GET');

  useEffect(() => {
    if (companyName) {
      setCompanyNameOptions(
        companyName.map((i) => ({
          label: i.name,
          value: i.id
        }))
      );
    }
    if (role) {
      setRoleOptions(
        role.map((i) => ({
          label: i.name,
          value: i.id
        }))
      );
    }
  }, [companyName, role]);

  const handleReset = () => {
    form.reset({
      organizationId: null,
      roleId: null,
      status: null,
      updatedDateFrom: null,
      updatedDateTo: null
    });
  };

  const { organizationId, roleId, status, updatedDateFrom, updatedDateTo } = form.watch();
  const disableResetBtn = () => {
    return !organizationId && !roleId && !status && !updatedDateFrom && !updatedDateTo;
  };

  const getDataStatus = async () => {
    let options = [
      {
        label: String(t('account_master:list:pending')),
        value: String(t('account_master:list:pending'))
      },
      {
        label: String(t('account_master:list:valid')),
        value: String(t('account_master:list:valid'))
      },
      {
        label: String(t('account_master:list:invalid')),
        value: String(t('account_master:list:invalid'))
      }
    ];
    setStatusOptions(options);
  };

  useEffect(() => {
    getDataStatus();
  }, []);

  return (
    <FormProvider {...form}>
      <div className='p-4 w-[620px]'>
        <h3 className='text-xl font-semibold'>{t('project:list:filter_btn_label')}</h3>
        {isInternalRole && (
          <div className='item mb-2'>
            <FormInputSelect
              control={form.control}
              name='organizationId'
              labelTx={t('account_master:filter:company_name:label')}
              placeholder={t('placeholder:select', { field: t('account_master:filter:company_name:label') })}
              options={companyNameOptions}
            />
          </div>
        )}
        <div className='item mb-2'>
          <FormInputSelect
            control={form.control}
            name='roleId'
            labelTx={t('account_master:filter:authority:label')}
            placeholder={t('placeholder:select', { field: t('account_master:filter:authority:label') })}
            options={roleOptions}
          />
        </div>
        <div className='item mb-2'>
          <FormInputSelect
            control={form.control}
            name='status'
            labelTx={t('account_master:filter:status:label')}
            options={statusOptions}
            placeholder={t('placeholder:select', { field: t('account_master:filter:status:label') })}
          />
        </div>
        <div className='row mb-8'>
          <Row gutter={16}>
            <Col span={12}>
              <FormInputDate
                name='updatedDateFrom'
                labelTx='account_master:filter:update_date'
                placeholder={t('placeholder:date')}
                disabledDate={(d) => {
                  return form.getValues('updatedDateTo') && d.startOf('day').isAfter(form.getValues('updatedDateTo'));
                }}
              />
            </Col>
            <Col span={12}>
              <FormInputDate
                name='updatedDateTo'
                label='&nbsp;'
                placeholder={t('placeholder:date')}
                disabledDate={(d) => {
                  return form.getValues('updatedDateFrom') && d.endOf('day').isBefore(form.getValues('updatedDateFrom'));
                }}
              />
            </Col>
          </Row>
        </div>
        <div className='flex justify-end space-x-2'>
          <div className='w-[120px]'>
            <BaseButton onClick={handleReset} type='tertiary' size='medium' disabled={disableResetBtn()}>
              {t('project:filter:btn_reset')}
            </BaseButton>
          </div>
          <div className='w-[120px]'>
            <BaseButton onClick={form.handleSubmit(onSubmit)} type='primary' size='medium'>
              <span>{t('project:filter:btn_aply')} </span>
            </BaseButton>
          </div>
        </div>
      </div>
    </FormProvider>
  );
};

export default Filter;
