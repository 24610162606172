import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseButton } from '@/components/base-button/BaseButton';
import BaseModal, { IBaseModalType } from '@/components/base-modal/BaseModal';

import IconBellRinging from '@/assets/icons/BellRinging.svg';
import IconCheckCircle from '@/assets/icons/CheckCircle.svg';

import './ShareUrlModal.scss';

interface IShareUrlModalProps extends IBaseModalType {
  // Add any props specific to ShareUrlModal here
  expiredTime: string;
  sharedUrl: string;
  onClose?: () => void;
}

const ShareUrlModal: React.FC<IShareUrlModalProps> = ({ expiredTime, sharedUrl, onClose, ...restProps }: IShareUrlModalProps) => {
  const { t } = useTranslation();
  const [showCopySuccess, setShowCopySuccess] = useState(false);

  return (
    <BaseModal
      maskClosable={true}
      width={565}
      title={t('project:question_list:share_modal:title')}
      className='questionnaire-shared-url-modal'
      contentElement={
        <>
          <span className='body-400'>{t('project:question_list:share_modal:description')}</span>
          {/* Notice */}
          <div className='rounded-lg shadow-lg px-[16px] py-[8px] text-link bg-blue0 flex align-middle items-center space-x-2 mt-[16px]'>
            <IconBellRinging className='!w-[24px] !h-[24px] fill-link' />
            <div className='whitespace-pre-line body-700'>{t('project:question_list:share_modal:notice', { time: expiredTime })}</div>
          </div>
          {showCopySuccess && (
            <div className='rounded-lg shadow-lg px-[16px] py-[8px] bg-green0 flex align-middle items-center space-x-2 mt-5'>
              <IconCheckCircle className='!w-[24px] !h-[24px] fill-positive' />
              <div className='whitespace-pre-line text-[12px]'>{t('project:question_list:share_modal:copy_url_successfully')}</div>
            </div>
          )}

          {/* Modal's button */}
          <div className='flex mt-[24px] justify-end space-x-4'>
            <div>
              <BaseButton
                type='tertiary'
                size='medium'
                className='!w-[140px] !py-[12px] mr-[10px]'
                onClick={(e) => {
                  onClose && onClose();
                }}
              >
                {t('button:cancel')}
              </BaseButton>
            </div>
            <div>
              <BaseButton
                className='!w-[140px] !py-[12px] mr-[10px]'
                type='primary'
                size='medium'
                onClick={() => {
                  navigator.clipboard.writeText(
                    t('project:question_list:share_modal:copy_content', {
                      time: expiredTime,
                      url: sharedUrl
                    })
                  );
                  setShowCopySuccess(true);
                  setTimeout(() => {
                    setShowCopySuccess(false);
                  }, 1000);
                }}
              >
                <span className='text-white'>{t('project:question_list:share_modal:copy_url')}</span>
              </BaseButton>
            </div>
          </div>
        </>
      }
      {...restProps}
    />
  );
};

export default ShareUrlModal;
