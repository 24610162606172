import RegisterProject from '@/pages/project-management/add-edit';
import BatchRegistrationPage from '@/pages/project-management/batch-registration';
import ViewProjectDetail from '@/pages/project-management/detail';
import InquiryProject from '@/pages/project-management/inquiry';
import QuestionDetailPage from '@/pages/project-management/project-questionaire/components/question-detail';
import ProjectQuestionList from '@/pages/project-management/project-questionaire/components/question-list';
import ProjectFilesList from '@/pages/project-management/projet-files/components/FilesList';
import RegisterSuccess from '@/pages/project-management/register-success';
import SaveAsDraftSuccess from '@/pages/project-management/register-success/SaveAsDraftSuccess';
import AddEditTaskOfProject from '@/pages/project-management/task/add-edit';
import ViewTaskDetail from '@/pages/project-management/task/view';
import ExternalQuestionAuthenticationPage from '@pages/project-management/project-questionaire/external-questions/Authenticate';
import { ExternalQuestionDetailPage } from '@pages/project-management/project-questionaire/external-questions/detail';
import ExternalQuestionListPage from '@pages/project-management/project-questionaire/external-questions/list';

import { ROUTER_IDS } from '@/utils/constants';
import { MAP_PATH_PRE_CHECK_404 } from '@/utils/helpers/routeLoaders';

import { E, V } from '../hooks/useAuthorization';
import ProjectListV2 from '../pages/project-management/project-list-v2/ProjectList';
import { ALL_ROLE, EXTERNAL_ADMIN, EXTERNAL_GENERAL_USER, INTERNAL_ADMIN, INTERNAL_GENERAL_USER } from '../utils/constants/AppConstants';
import {
  ADD_TASK_OF_PROJECT_NAME,
  BATCH_REGISTRATION_PROJECT_NAME,
  CREATE_NEW_PROJECT_LIST,
  EDIT_PROJECT,
  EDIT_TASK_OF_PROJECT_NAME,
  EXTERNAL_QUESTION_AUTHENTICATE,
  EXTERNAL_QUESTION_DETAIL,
  EXTERNAL_QUESTION_LIST,
  INQUIRY_CREATE_PROJECT,
  PROJECT_LIST,
  REGISTER_PROJECT_SUCCESS,
  SAVE_AS_DRAFT_PROJECT_SUCCESS_NAME,
  VIEW_PROJECT_DETAIL,
  VIEW_PROJECT_FILES,
  VIEW_PROJECT_QUESTIONS,
  VIEW_TASK_OF_PROJECT_NAME
} from '../utils/constants/AuthorizationProjectManagement';
import {
  ADD_TASK_OF_PROJECT_URL,
  BATCH_REGISTRATION_PROJECT_URL,
  CREATE_PROJECT_INQUIRY_URL,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_URL,
  EDIT_PROJECT_URL,
  EDIT_TASK_OF_PROJECT_URL,
  EXTERNAL_QUESTION_AUTHENTICATE_URL,
  EXTERNAL_QUESTION_DETAIL_URL,
  EXTERNAL_QUESTION_LIST_URL,
  PROJECT_FILE_LIST_URL,
  PROJECT_LIST_URL,
  PROJECT_QUESTION_DETAIL_URL,
  PROJECT_QUESTION_LIST_URL,
  SAVE_AS_DRAFT_PROJECT_SUCCESS,
  VIEW_PROJECT_URL,
  VIEW_TASK_OF_PROJECT_URL
} from '../utils/constants/RouteContants';
import { IRoute } from '../utils/interfaces/route';

const ProjectRoutes: IRoute[] = [
  {
    path: PROJECT_LIST_URL,
    name: PROJECT_LIST,
    action: V,
    element: <ProjectListV2 />,
    auth: true,
    role: ALL_ROLE,
    id: ROUTER_IDS.PROJECT_LIST
  },
  {
    path: CREATE_PROJECT_URL,
    name: CREATE_NEW_PROJECT_LIST,
    action: E,
    element: <RegisterProject />,
    auth: true,
    role: ALL_ROLE
  },
  {
    path: EDIT_PROJECT_URL(':id'),
    name: EDIT_PROJECT,
    action: E,
    element: <RegisterProject />,
    auth: true,
    role: ALL_ROLE,
    preCheck404: MAP_PATH_PRE_CHECK_404[EDIT_PROJECT_URL(':id')]
  },
  {
    path: VIEW_PROJECT_URL(':id'),
    name: VIEW_PROJECT_DETAIL,
    action: E,
    element: <ViewProjectDetail />,
    auth: true,
    role: ALL_ROLE,
    preCheck404: MAP_PATH_PRE_CHECK_404[VIEW_PROJECT_URL(':id')]
  },
  {
    path: PROJECT_FILE_LIST_URL(':id'),
    name: VIEW_PROJECT_FILES,
    action: V,
    element: <ProjectFilesList />,
    auth: true,
    role: [INTERNAL_ADMIN, INTERNAL_GENERAL_USER, EXTERNAL_ADMIN],
    preCheck404: MAP_PATH_PRE_CHECK_404[PROJECT_FILE_LIST_URL(':id')]
  },
  {
    path: PROJECT_QUESTION_LIST_URL(':id'),
    name: VIEW_PROJECT_QUESTIONS,
    action: V,
    element: <ProjectQuestionList />,
    auth: true,
    role: ALL_ROLE,
    preCheck404: MAP_PATH_PRE_CHECK_404[PROJECT_QUESTION_LIST_URL(':id')]
  },
  {
    path: PROJECT_QUESTION_DETAIL_URL(':id', ':formId'),
    name: VIEW_PROJECT_QUESTIONS,
    action: V,
    element: <QuestionDetailPage />,
    auth: true,
    role: ALL_ROLE,
    preCheck404: MAP_PATH_PRE_CHECK_404[PROJECT_QUESTION_DETAIL_URL(':id', ':formId')]
  },
  {
    path: CREATE_PROJECT_INQUIRY_URL(':id'),
    name: INQUIRY_CREATE_PROJECT,
    action: V,
    element: <InquiryProject />,
    auth: true,
    /* TODO: Hiding for all role to release staging 2024-06-06 */
    role: [EXTERNAL_ADMIN, EXTERNAL_GENERAL_USER],
    preCheck404: MAP_PATH_PRE_CHECK_404[CREATE_PROJECT_INQUIRY_URL(':id')]
    // role: []
  },
  {
    path: CREATE_PROJECT_SUCCESS,
    name: REGISTER_PROJECT_SUCCESS,
    action: E,
    element: <RegisterSuccess />,
    auth: false,
    role: [EXTERNAL_ADMIN, EXTERNAL_GENERAL_USER]
  },
  {
    path: SAVE_AS_DRAFT_PROJECT_SUCCESS(':id'),
    name: SAVE_AS_DRAFT_PROJECT_SUCCESS_NAME,
    action: E,
    element: <SaveAsDraftSuccess />,
    auth: false,
    role: [EXTERNAL_ADMIN, EXTERNAL_GENERAL_USER],
    preCheck404: MAP_PATH_PRE_CHECK_404[SAVE_AS_DRAFT_PROJECT_SUCCESS(':id')]
  },
  {
    path: ADD_TASK_OF_PROJECT_URL(':idProject'),
    name: ADD_TASK_OF_PROJECT_NAME,
    action: E,
    element: <AddEditTaskOfProject />,
    auth: true,
    role: [INTERNAL_ADMIN, INTERNAL_GENERAL_USER],
    preCheck404: MAP_PATH_PRE_CHECK_404[ADD_TASK_OF_PROJECT_URL(':id')]
  },
  {
    path: EDIT_TASK_OF_PROJECT_URL(':idProject', ':idTask'),
    name: EDIT_TASK_OF_PROJECT_NAME,
    action: E,
    element: <AddEditTaskOfProject />,
    auth: true,
    role: [INTERNAL_ADMIN, INTERNAL_GENERAL_USER]
  },
  {
    path: VIEW_TASK_OF_PROJECT_URL(':idProject', ':idTask'),
    name: VIEW_TASK_OF_PROJECT_NAME,
    action: E,
    element: <ViewTaskDetail />,
    auth: true,
    role: ALL_ROLE,
    preCheck404: MAP_PATH_PRE_CHECK_404[VIEW_TASK_OF_PROJECT_URL(':idProject', ':idTask')]
  },
  {
    path: BATCH_REGISTRATION_PROJECT_URL,
    name: BATCH_REGISTRATION_PROJECT_NAME,
    action: E,
    element: <BatchRegistrationPage />,
    auth: true,
    role: [INTERNAL_ADMIN, INTERNAL_GENERAL_USER]
  },
  {
    path: EXTERNAL_QUESTION_AUTHENTICATE_URL,
    name: EXTERNAL_QUESTION_AUTHENTICATE,
    element: <ExternalQuestionAuthenticationPage />,
    auth: false,
    role: ALL_ROLE
  },
  {
    path: EXTERNAL_QUESTION_LIST_URL(':token'),
    name: EXTERNAL_QUESTION_LIST,
    element: <ExternalQuestionListPage />,
    auth: false,
    role: ALL_ROLE
  },
  {
    path: EXTERNAL_QUESTION_DETAIL_URL(':token', ':questionId'),
    name: EXTERNAL_QUESTION_DETAIL,
    element: <ExternalQuestionDetailPage />,
    auth: false,
    role: ALL_ROLE
  }
];

export default ProjectRoutes;
