import { QUERY_CONDITION } from '@/utils/constants';
import QueryFactory, { QueryItem } from '@/utils/helpers/filters/base';

import { IQueryFilterParams } from '../models';

class PhrasesQueryFactory extends QueryFactory {
  getById(value: string, field: string) {
    const newQuerySection = this.initNewQuery(this.data?.length ? QUERY_CONDITION.AND : QUERY_CONDITION.EMPTY);
    const picIdQuery = new QueryItem().modules.guidWith(value, { fieldTitle: field });
    newQuerySection.childrens?.push(picIdQuery);
    this.append(newQuerySection);
    return this;
  }

  updatedDateFrom(date: string) {
    const newQuerySection = this.initNewQuery(this.data?.length ? QUERY_CONDITION.AND : QUERY_CONDITION.EMPTY);
    newQuerySection?.childrens?.push(new QueryItem().modules.fromDate(date, { fieldTitle: 'publishedDate' }));
    this.append(newQuerySection);
    return this;
  }

  updatedDateTo(date: string) {
    const newQuerySection = this.initNewQuery(this.data?.length ? QUERY_CONDITION.AND : QUERY_CONDITION.EMPTY);
    newQuerySection?.childrens?.push(new QueryItem().modules.toDate(date, { fieldTitle: 'publishedDate' }));
    this.append(newQuerySection);
    return this;
  }
}

export const generateFilter = (params?: IQueryFilterParams): string => {
  const filterFactory = new PhrasesQueryFactory();

  if (params?.keyword) {
    const fields = ['titleSearch.ToLower()', 'publishedNameSearch.ToLower()'];
    filterFactory.searchingBy(fields, params.keyword.toLowerCase());
  }

  if (params?.confirmationCheck) {
    filterFactory.getById(params.confirmationCheck, 'confirmedStatusSearch');
  }

  if (params?.publishedDateFrom) {
    filterFactory.updatedDateFrom(params?.publishedDateFrom);
  }

  if (params?.publishedDateTo) {
    filterFactory.updatedDateTo(params?.publishedDateTo);
  }

  return filterFactory.sanitize().stringifyData();
};
