import { MY_PROFILE, V } from '../hooks/useAuthorization';
import NotAuthorized from '../pages/error-page/403-page/NotAuthorized';
import NotExits from '../pages/error-page/404-page/NotExits';
import SomeThingWentWrong from '../pages/error-page/500-page/SomeThingWentWrong';
import MyProfile from '../pages/my-profile/MyProfile';
import { ALL_ROLE } from '../utils/constants/AppConstants';
import { MY_PROFILE_URL, NOT_AUTHORIZED_URL, NOT_EXITS_URL, SOMETHING_WENT_WRONG_URL } from '../utils/constants/RouteContants';
import { IRoute } from '../utils/interfaces/route';
import AccountMasterRoutes from './accountMaster';
import AnnouncementRoutes from './announcement';
import InquiryRoutes from './inquiry';
import MasterRoutes from './master';
import ProjectRoutes from './project';
import StakeholderRoutes from './stakeholder';
import TaskRoutes from './task';
import TemplateRoutes from './template';

const ROUTES: IRoute[] = [
  {
    path: MY_PROFILE_URL,
    name: MY_PROFILE,
    action: V,
    element: <MyProfile />,
    auth: true,
    role: ALL_ROLE
  },
  {
    path: SOMETHING_WENT_WRONG_URL,
    name: 'SOMETHING_WENT_WRONG',
    action: V,
    element: <SomeThingWentWrong />,
    auth: false,
    role: ALL_ROLE
  },
  {
    path: NOT_AUTHORIZED_URL,
    name: 'NOT_AUTHORIZED_',
    action: V,
    element: <NotAuthorized />,
    auth: false,
    role: ALL_ROLE
  },
  {
    path: NOT_EXITS_URL,
    name: 'NOT_EXITS',
    action: V,
    element: <NotExits />,
    auth: false,
    role: ALL_ROLE
  },
  ...ProjectRoutes,
  ...TaskRoutes,
  ...TemplateRoutes,
  ...AccountMasterRoutes,
  ...MasterRoutes,
  ...AnnouncementRoutes,
  ...InquiryRoutes,
  ...StakeholderRoutes
];

export default ROUTES;
