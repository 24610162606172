import { PaginationProps } from 'antd';
import type { TableProps } from 'antd/es/table';
import { SortOrder, SorterResult } from 'antd/es/table/interface';
import { isNil } from 'lodash';
import React, { Key, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import EmptyDataWithIcon from '@/components/EmptyData/EmptyDataWithIcon';
import { showBasePopup } from '@/components/base-popup/BasePopup';

import useFetch, { FetchMethod, IApiResponseDataList } from '@/hooks/useFetch';

import { ANTD_TO_QUERY_SORT, DEFAULT_PAGES, DEFAULT_PAGE_SIZE_OPTIONS } from '@/utils/constants';
import { DEFAULT_PERPAGE } from '@/utils/constants/AppConstants';
import { DataViewer } from '@/utils/helpers/common';
import { arrayToDict, getPageIndexWhenDeleted } from '@/utils/helpers/globalHelper';

import TableBase, { FetchColumnsType, ITableCustomProps } from '../table-base';
import TableFooter from './Footer';

import './index.scss';

export interface IDefaultSort<T> {
  field: keyof T;
  order: keyof typeof ANTD_TO_QUERY_SORT;
}

export interface IObject extends Record<string, any> {}
export interface ITableFetchProps<T> extends Omit<ITableCustomProps<T>, 'handleDeleteClick'> {
  apiEndpoint?: string;
  apiMethod?: FetchMethod;
  filterDefault?: IObject;
  defaultPageSize?: number;
  defaultPageSizeOptions?: number[];
  showPagination?: boolean;
  emptyDataWithoutFilter?: React.ReactNode;
  handleDeleteClick?: (keys: React.Key[], records: T[]) => void | Promise<void> | Promise<boolean | number | undefined>;
  defaultSort?: IDefaultSort<T>[];
  customParamSort?: (sortField: React.Key | null | undefined, sortOrder: SortOrder | null, defaultSort?: IDefaultSort<T>[]) => string | undefined;
}

type OnChange<T> = NonNullable<TableProps<T>['onChange']>;
type GetSingle<T> = T extends (infer U)[] ? U : never;
type Sorts<T> = GetSingle<Parameters<OnChange<T>>[2]>;

const mappingSort = {
  ascend: ANTD_TO_QUERY_SORT.ascend,
  descend: ANTD_TO_QUERY_SORT.descend
};

const BlankTableLoading = () => <div className='h-[80px]'></div>;

const fixedForwardRef = <T, P = {}>(
  render: (props: P, ref: React.Ref<T>) => React.ReactNode
): ((props: P & React.RefAttributes<T>) => React.ReactNode) => {
  return React.forwardRef(render) as any;
};

const TableFetch = fixedForwardRef(
  <T extends IObject>(
    {
      apiEndpoint,
      apiMethod = 'POST',
      columns,
      locale,
      filterDefault = {},
      handleDeleteClick,
      deleteConfirmPopup,
      emptyDataWithoutFilter,
      defaultPageSize = DEFAULT_PERPAGE,
      defaultPageSizeOptions = DEFAULT_PAGE_SIZE_OPTIONS,
      showPagination = true,
      defaultSort = [
        {
          field: 'updatedDate',
          order: 'descend'
        }
      ],
      customParamSort,
      ...restProps
    }: ITableFetchProps<T>,
    ref: any
  ) => {
    const { t } = useTranslation();
    const [sortField, setSortField] = useState<Key | null | undefined>(null);
    const [sortOrder, setSortOrder] = useState<SortOrder | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(defaultPageSize ?? DEFAULT_PAGES.pageSize);
    const [pageSizeOptions, _setPageSizeOptions] = useState<number[]>(defaultPageSizeOptions ?? DEFAULT_PAGE_SIZE_OPTIONS);
    const [filter, setFilter] = useState<IObject>({
      sorts: sortField ? `${sortField}=desc` : undefined,
      pageSize,
      pageIndex: 0,
      ...filterDefault
    });
    const columnOptions = useMemo(() => {
      const newOptions: FetchColumnsType<T> = columns.map((op) => ({
        ...op,
        sortOrder: sortField === op.key ? sortOrder : undefined
      }));

      return newOptions;
    }, [sortField, sortOrder, columns]);
    const [firstLoad, setFirstLoad] = useState(true); // for handle duplicated call api at the first time

    const { data, loading, fetchData } = useFetch<IApiResponseDataList<any[]>>(firstLoad ? '' : apiEndpoint ?? '', apiMethod, filter);
    const refreshData = () => {
      fetchData();
    };

    useImperativeHandle(ref, () => ({
      refreshData
    }));

    const handleParamSort = (defaultSort: IDefaultSort<T>[] | undefined, sortField: React.Key | null | undefined) => {
      if (customParamSort) {
        return customParamSort(sortField, sortOrder, defaultSort);
      }
      const tableSort = sortField ? `${sortField}=${mappingSort[sortOrder ?? 'descend']}` : undefined;
      const sort = (defaultSort ?? []).filter((i) => sortField !== i.field).map((sort) => `${String(sort.field)}=${ANTD_TO_QUERY_SORT[sort.order]}`);
      const sortArr = [...sort, tableSort].filter((i) => i);
      return (!sortArr.length ? undefined : sortArr.join(',')) || undefined;
    };

    useEffect(() => {
      setCurrentPage(1);
      setFirstLoad(false);
      const filteredParams = {
        pageIndex: 0,
        pageSize,
        sorts: handleParamSort(defaultSort, sortField)
      };
      setFilter({ ...filterDefault, ...filteredParams });
    }, [filterDefault]);

    useEffect(() => {
      const filteredParams = {
        pageIndex: currentPage - 1,
        pageSize,
        sorts: handleParamSort(defaultSort, sortField)
      };
      // Generate sort and filter
      setFilter({ ...filterDefault, ...filteredParams });
    }, [sortField, sortOrder, currentPage, pageSize]);

    const onChange: OnChange<T> = (_pagination, _filters, sorter: SorterResult<T> | SorterResult<T>[]) => {
      let sort: Sorts<T> = sorter instanceof Array ? sorter[0] : sorter;
      const sortField = sort?.order ? sort?.columnKey : null;
      const sortOrder = sort?.order ?? null;
      setSortField(sortField);
      setSortOrder(sortOrder);
    };

    const handlePageChange = (page: number) => {
      setCurrentPage(page);
    };

    const onShowSizeChange: PaginationProps['onShowSizeChange'] = (current, pageSize) => {
      setCurrentPage(current);
      setPageSize(pageSize);
    };

    const paginationSettings = {
      current: currentPage,
      pageSize,
      total: data?.totalCount || 0,
      showSizeChanger: true,
      pageSizeOptions,
      onChange: handlePageChange,
      onShowSizeChange,
      locale: {
        items_per_page: t('locale:pagination:items_per_page')
      },
      hasFilter: !!Object.keys(filterDefault).filter((i) => !(!filterDefault[i] && filterDefault[i] !== 0)).length
    };

    const handleDeleteItems = useCallback(
      async (keys: React.Key[]) => {
        const dict = arrayToDict(data?.data as any[], 'id');
        if (deleteConfirmPopup) {
          const showDel = await showBasePopup({ ...deleteConfirmPopup });
          if (showDel !== 'confirm') {
            return;
          }
        }

        const result =
          handleDeleteClick &&
          (await handleDeleteClick(
            keys,
            keys.map((key) => dict[key.toString()])
          ));

        if (!isNil(result) && Number(result) === 0) {
          return;
        }

        const newIndex = getPageIndexWhenDeleted({
          deleted: isNil(result) ? keys.length : Number(result),
          params: { pageIndex: paginationSettings.current - 1, pageSize: paginationSettings.pageSize },
          total: paginationSettings.total,
          totalPage: Math.floor(paginationSettings.total / paginationSettings.pageSize) + 1
        });
        setCurrentPage(newIndex + 1);
        setFilter({ ...filter });
      },
      [data]
    );

    const hasFilter = Object.keys(filterDefault).find((key) => (Array.isArray(filter[key]) ? filter[key].length : !DataViewer.isEmpty(filter[key])));
    const customLocale = useMemo(() => {
      if (loading || firstLoad) return { ...locale, emptyText: () => <BlankTableLoading /> };
      return {
        ...locale,
        emptyText:
          hasFilter || !emptyDataWithoutFilter ? (
            <EmptyDataWithIcon
              title={null}
              description={{
                content: 'common:no_data'
              }}
              button={null}
            />
          ) : (
            emptyDataWithoutFilter
          )
      };
    }, [loading, locale]);

    return (
      <TableBase
        rowClassName={(record: T) => {
          return [!('children' in record) ? `bg-white` : `table-toggle-header`].join(' ');
        }}
        locale={customLocale}
        loading={loading}
        columns={columnOptions}
        showSorterTooltip={false}
        dataSource={data?.data ?? []}
        onChange={onChange}
        handleDeleteClick={handleDeleteItems}
        pagination={showPagination && paginationSettings.total ? paginationSettings : false}
        footer={() => (showPagination && paginationSettings.total ? <TableFooter {...paginationSettings} /> : <></>)}
        {...restProps}
      />
    );
  }
);

export default TableFetch;
