import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseCard } from '@/components/base-card/BaseCard';
import { FormAttachment } from '@/components/form-attachment/FormAttachment';
import { EditorWithCounter } from '@/components/form-box-editor';
import { InfoIcon } from '@/components/icon-svg/IconSvg';

import { NOTE_MAX_LENGTH } from '@/utils/constants/AppConstants';

import { useFormScenarioCContext } from '..';
import { relativePath } from '../../utils';

const PARENT_PATH = 'steps[5]';

export default function Step7() {
  const { t } = useTranslation();
  const [noteRaw, setNoteRaw] = useState<string>('');
  const form = useFormScenarioCContext<'step-7'>();
  const PATHS = {
    NOTE: relativePath('note', PARENT_PATH),
    PROJECT_ATTACHMENTS: relativePath('projectAttachments', PARENT_PATH)
  };
  const editRef = useRef(null);
  const countDict = {
    projectAttachments: form.watch(PATHS.PROJECT_ATTACHMENTS).length ? 1 : 0,
    requestContent: Math.max(noteRaw.length - 1, 0) ? 1 : 0
  };
  useEffect(() => {
    setNoteRaw(editRef.current?.getText());
  }, [editRef]);
  return (
    <div className='mt-5'>
      <p className='title-20 text-black'>{t('basic_information:additional_information')}</p>

      <BaseCard className='mt-3' title={String(t('basic_information:note'))} total={1} questions={countDict.requestContent}>
        <EditorWithCounter
          ref={editRef}
          label={
            <div className='flex'>
              <div className='py-[2px] mr-[4px]'>
                <InfoIcon width={14.63} height={14.63} />
              </div>
              <div className='font-normal leading-[22px] text-[14px] text-[#525A68]'>
                <p>{t('project:scenario_b:additional_information_guide1')}</p>
              </div>
            </div>
          }
          className='mt-3'
          name={PATHS.NOTE}
          limit={NOTE_MAX_LENGTH}
          defaultValue={form.getValues(PATHS.NOTE) || ''}
          toolbar={null}
          placeholder={t('basic_information:place_holder_note') || ''}
          editorWrapperProps={{ className: '!h-[148px]' }}
          onChange={(_content: string, text: string) => {
            setNoteRaw(text);
          }}
        />
      </BaseCard>
      <BaseCard className='mt-3' title={String(t('basic_information:attachment_file'))}>
        <div className='flex py-2'>
          <div className='py-[2px] mr-[4px]'>
            <InfoIcon width={14.63} height={14.63} />
          </div>
          <div className='font-normal leading-[22px] text-[14px] text-[#525A68]'>
            <p>{t('basic_information:note_for_upload_file')}</p>
          </div>
        </div>
        <FormAttachment
          name={PATHS.PROJECT_ATTACHMENTS}
          defaultValue={form.getValues(PATHS.PROJECT_ATTACHMENTS) || []}
          documentType='attachments'
        ></FormAttachment>
      </BaseCard>
    </div>
  );
}
