import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';

import { FORMAT_DATE_EN } from '../../../utils/constants/AppConstants';
import { IDocument, IMaterial, ITask, ITemplate } from '../../../utils/interfaces/template';
import { checkFormNotHasData } from '../../../utils/method';
import { DataChange } from './AddEditTemplateLayout';

const templateMTO = (data: ITemplate) => {
  let newData;
  if (!data) {
    return;
  }
  newData = data;
  newData.tasks = data.tasks.map((e) => ({
    ...e,
    deadline: e?.deadline ? dayjs(e.deadline) : undefined,
    defaultId: e.id,
    version: e.version ?? undefined
  }));

  newData.schedules = data.schedules.map((e) => ({
    id: e.id,
    key: uuidv4(),
    name: e.name,
    status: e.status,
    actualCompleteDate: e?.actualCompleteDate ? dayjs(e.actualCompleteDate) : undefined,
    scheduledCompleteDate: e?.scheduledCompleteDate ? dayjs(e.scheduledCompleteDate) : undefined,
    version: e.version ?? undefined
  }));
  return newData;
};
const templateMTD = (data: any, templateRef?: string) => {
  // let { refId, refName, ...newData } = data;
  // TODO -> media is Array -> edit interface when completed feature upload/download multiple file
  if (!data) {
    return;
  }
  const newData = data;

  newData.documents = data?.documents?.filter((e: any) => !!e.typeId)
    ? data.documents
        .filter((e: any) => !!e.typeId)
        ?.map((e: any, index: number) => ({
          ...e,
          id: e?.id?.includes('new-document') ? undefined : e.id,
          order: index + 1,
          media:
            e?.media?.map((f: any) => {
              if (f?.id?.includes('new-file')) {
                delete f['id'];
              }
              return f;
            }) || [],
          isConfirmed: false,
          version: e.version ?? undefined
        }))
    : undefined;
  newData.tasks = data?.tasks
    ?.filter((e: any) => !!e.typeId)
    ?.map((e: any, index: number) => ({
      id: e?.id?.includes('new-document') ? undefined : e.id,
      order: index + 1,
      typeId: e.typeId,
      typeName: e.typeName,
      remark: e.remark,
      name: e?.name,
      countryId: e?.countryId,
      version: e.version ?? undefined
    }));

  newData.materials = data?.materials?.map((e: any, index: number) => {
    return {
      ...e,
      order: index + 1,
      id: e?.id?.includes('new-document') ? undefined : e.id,
      media:
        e?.media?.map((f: any) => {
          if (f?.id?.includes('new-file')) {
            delete f['id'];
          }
          return f;
        }) || [],
      version: e.version ?? undefined
    };
  });
  newData.schedules = data?.schedules?.map((e: any, index: number) => ({
    id: e?.id?.includes('new-document') ? undefined : e.id,
    order: index + 1,
    name: e.name,
    status: 'notStarted',
    actualCompleteDate: e.actualCompleteDate ? dayjs(e.actualCompleteDate).format(FORMAT_DATE_EN) : undefined,
    scheduledCompleteDate: e.scheduledCompleteDate ? dayjs(e.scheduledCompleteDate).format(FORMAT_DATE_EN) : undefined,
    version: e.version ?? undefined
  }));
  return newData;
};

const _getDeletedMedia = (oldMedias: any[] = [], newMedias: any[] = []) => {
  const deletedMedias = oldMedias
    .filter((media) => !newMedias.find((newMedia) => newMedia?.id === media?.id))
    .map((changedMedia) => ({ ...changedMedia, deleted: true }));

  return [...newMedias, ...deletedMedias];
};

const templateUpdateMTD = (data: any, dataChange: DataChange, formMethod: any, listRef: any) => {
  // TODO -> media is Array -> edit interface when completed feature upload/download multiple file

  if (!data) {
    return;
  }
  let { schedules, ...newData } = data;

  newData.schedules = [];
  newData.documents = [];
  const defaultDocument = formMethod.formState.defaultValues?.documents;

  data.documents
    .filter((e: any) => !!e.typeId)
    ?.map((e: any, index: number) => {
      const defaultElement = defaultDocument.find((ite: IDocument) => ite.id === e.id);

      if (e?.id?.includes('new-document')) {
        return newData.documents.push({
          ...e,
          key: undefined,
          id: undefined,
          order: index + 1,
          isConfirmed: false,
          templateId: undefined,
          media:
            e?.media?.map((f: any) => {
              delete f['id'];
              return f;
            }) || []
        });
      } else {
        const defaultMedias = formMethod.formState.defaultValues['documents'][index]?.media;
        if (index >= (dataChange.documents?.indexChange ?? 0)) {
          const changedMedia = _getDeletedMedia(defaultMedias, e?.media);
          return newData.documents.push({
            ...e,
            key: undefined,
            order: index + 1,
            isConfirmed: false,
            templateId: undefined,
            media: changedMedia
          });
        } else {
          if (e.isEdit) {
            return newData?.documents.push({
              ...e,
              key: undefined,
              order: index + 1,
              isConfirmed: false,
              templateId: undefined,
              media: handleMedia(defaultElement?.media, e?.media)
            });
          } else return undefined;
        }
      }
    });

  newData.documentsDeleted = listRef?.templateRef
    ? formMethod.formState.defaultValues?.documents.map((doc: any) => ({ id: doc.id, version: doc?.version }))
    : dataChange.documents?.documentsDeleted;
  newData.tasks = handleTaskUpdate(formMethod, listRef?.taskRef?.deletedTasks?.indexChange);
  newData.tasksDeleted = listRef?.templateRef
    ? formMethod.formState.defaultValues?.tasks.map((task: any) => ({ id: task.id, version: task?.version }))
    : listRef?.taskRef?.deletedTasks?.items;
  newData.materials = handleMaterialUpdate(formMethod, listRef?.materialRef?.current?.deletedMaterials?.indexChange);
  newData.materialsDeleted = listRef?.templateRef
    ? formMethod.formState.defaultValues?.materials.map((mat: any) => ({ id: mat.id, version: mat?.version }))
    : listRef?.materialRef?.current?.deletedMaterials?.items;
  data?.schedules
    ?.filter((e: any) => !!e.name)
    ?.map((e: any, index: number) => {
      if (e?.id?.includes('new-document')) {
        return newData.schedules.push({
          order: index,
          name: e.name,
          status: 'notStarted',
          actualCompleteDate: e.actualCompleteDate ? dayjs(e.actualCompleteDate).format(FORMAT_DATE_EN) : undefined,
          scheduledCompleteDate: e.scheduledCompleteDate ? dayjs(e.scheduledCompleteDate).format(FORMAT_DATE_EN) : undefined,
          version: e?.version ?? undefined
        });
      } else {
        if (index >= (dataChange.schedules?.indexChange ?? 0) || e.isEdit) {
          return newData.schedules.push({
            id: e.id,
            order: index,
            name: e.name,
            status: 'notStarted',
            actualCompleteDate: e.actualCompleteDate ? dayjs(e.actualCompleteDate).format(FORMAT_DATE_EN) : undefined,
            scheduledCompleteDate: e.scheduledCompleteDate ? dayjs(e.scheduledCompleteDate).format(FORMAT_DATE_EN) : undefined,
            version: e?.version ?? undefined
          });
        } else {
          return undefined;
        }
      }
    });
  newData.SchedulesDeleted = listRef?.templateRef
    ? formMethod.formState.defaultValues?.schedules.map((sche: any) => ({ id: sche.id, version: sche?.version }))
    : dataChange.schedules?.schedulesDeleted;
  return newData;
};
const handleTaskUpdate = (formMethod: any, deleteIndex: number) => {
  const currentTask = formMethod.getValues('tasks');
  const { sliceIndex, arrNotEmpty } = getIndexChange(deleteIndex, currentTask);
  const newData: any[] = [];

  for (let i = 0; i < arrNotEmpty.length; i++) {
    const task = arrNotEmpty[i];
    let newTask: any = {
      id: task?.id,
      countryId: task?.countryId,
      name: task?.name,
      typeId: task?.typeId,
      typeName: task?.typeName,
      order: i + 1,
      managerId: task?.managerId,
      remark: task?.remark,
      deadline: task?.deadline ? dayjs(task.deadline).format(FORMAT_DATE_EN) : undefined,
      version: task?.version ?? undefined
    };
    if (sliceIndex !== null) {
      if (i < sliceIndex) {
        newData.push(newTask);
      }
      if (i >= sliceIndex) {
        if (task.id?.includes('new-document')) {
          newTask = {
            typeId: task?.typeId,
            typeName: task?.typeName,
            order: i + 1,
            name: task?.name,
            countryId: task?.countryId,
            remark: task?.remark,
            version: task?.version ?? undefined
          };
        }
        newData.push(newTask);
      }
    } else {
      newData.push(newTask);
    }
  }
  return newData;
};

const getIndexChange = (indexDeleted: number, currentValues: any[]) => {
  const addNewIndex = currentValues.findIndex((item: IMaterial | ITask) => item?.id?.includes('new-document'));
  let sliceIndex = null;
  if (addNewIndex >= 0 && indexDeleted !== null) {
    sliceIndex = addNewIndex > indexDeleted ? indexDeleted : addNewIndex;
  } else {
    sliceIndex = addNewIndex >= 0 ? addNewIndex : indexDeleted;
  }
  const arrNotEmpty = currentValues.filter((item: IMaterial | ITask) => {
    const { id, key, order, ...cloneElement } = item;
    return !checkFormNotHasData(cloneElement);
  });

  return {
    sliceIndex,
    arrNotEmpty
  };
};
const handleMedia = (defaultMedia: any[] = [], newMedia: any[] = []) => {
  const ids: any[] = [];
  const mediaList =
    newMedia?.map((e) => {
      ids.push(e?.id);
      if (e?.id?.includes('new-file')) {
        delete e['id'];
      }
      return e;
    }) || [];
  const listDeleted =
    defaultMedia?.filter((f) => {
      if (!ids?.includes(f?.id)) {
        f.deleted = true;
        return f;
      }
    }) || [];
  return [...mediaList, ...listDeleted];
};

const handleMaterialUpdate = (formMethod: any, deleteIndex: number) => {
  const currentMaterials = formMethod.getValues().materials;
  const defaultMaterials = formMethod.formState.defaultValues?.materials;
  const newData: any[] = [];

  const { sliceIndex, arrNotEmpty } = getIndexChange(deleteIndex, currentMaterials);
  for (let i = 0; i < arrNotEmpty.length; i++) {
    const material = arrNotEmpty[i];
    const defaultElement = defaultMaterials.find((ite: IMaterial) => ite.id === material.id);
    let newMaterial: any = {
      id: material?.id,
      typeName: material?.typeName,
      typeId: material?.typeId,
      description: material?.description,
      countryId: material?.countryId,
      order: i + 1,
      media: handleMedia(defaultElement?.media, material?.media),
      version: material?.version ?? undefined
    };
    if (sliceIndex !== null) {
      if (i < sliceIndex) {
        newData.push(newMaterial);
      }
      if (i >= sliceIndex) {
        if (material.id?.includes('new-document')) {
          newMaterial = {
            typeName: material?.typeName,
            typeId: material?.typeId,
            description: material?.description,
            media: material?.media || [],
            countryId: material?.countryId,
            order: i + 1,
            version: material?.version ?? undefined
          };
        }
        newData.push(newMaterial);
      }
    } else {
      newData.push(newMaterial);
    }
  }
  return newData;
};

export { templateMTD, templateMTO, templateUpdateMTD };
