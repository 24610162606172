import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';

import { LOCALSTORAGE } from '../utils/constants/AppConstants';
import useAuthorization from './useAuthorization';

const useFilter = () => {
  const { user } = useAuthorization();

  const setDataFilter = (name: string, values: any) => {
    const filteredValuesClone = cloneDeep(filteredValues);
    filteredValuesClone[name] = values;
    localStorage.setItem(LOCALSTORAGE.FILTERED_VALUES, JSON.stringify(filteredValuesClone));
  };

  const filteredValues = useMemo(() => {
    const localStorageValues = localStorage.getItem(LOCALSTORAGE.FILTERED_VALUES);
    const parseLocalStorageValues = localStorageValues ? JSON.parse(localStorageValues) : {};
    if (user && parseLocalStorageValues.userId && parseLocalStorageValues.userId !== user.id) {
      return {
        userId: user.id
      };
    }
    return localStorageValues
      ? JSON.parse(localStorageValues)
      : {
          userId: user?.id || ''
        };
  }, [localStorage.getItem(LOCALSTORAGE.FILTERED_VALUES)]);

  const getCurrentFilter = useCallback(
    (name: string) => {
      return filteredValues[name] || null;
    },
    [filteredValues]
  );

  useEffect(() => {
    const localStorageValues = localStorage.getItem(LOCALSTORAGE.FILTERED_VALUES);
    const parseLocalStorageValues = localStorageValues ? JSON.parse(localStorageValues) : {};
    if (user && parseLocalStorageValues.userId !== user.id) {
      localStorage.setItem(
        LOCALSTORAGE.FILTERED_VALUES,
        JSON.stringify({
          userId: user.id
        })
      );
    }
  }, [localStorage.getItem(LOCALSTORAGE.FILTERED_VALUES), user]);

  return {
    filteredValues,
    setDataFilter,
    getCurrentFilter
  };
};

export default useFilter;
