import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CompleteIcon from '@assets/icons/CompletedIcon.svg';
import SendIcon from '@assets/icons/Send.svg';
import ErrorIcon from '@assets/icons/WarningCircle.svg';

import './styles.scss';

export const STEP_CRUMB_STATUS = {
  COMPLETED: 'completed',
  INPROGRESS: 'inprogress',
  TODO: 'todo',
  ERROR: 'error'
} as const;
const ICON: { [key in STEP_CRUMB_STATUS]: React.ReactNode } = {
  [STEP_CRUMB_STATUS.COMPLETED]: (
    <div className='icon complete-icon'>
      <CompleteIcon className='block' width={24} height={24} />
    </div>
  ),
  [STEP_CRUMB_STATUS.INPROGRESS]: (
    <div className='icon rounded flex justify-center items-center w-[24px] h-[24px] rounded inprogress-icon'>
      <SendIcon width={10} height={12} />
    </div>
  ),
  [STEP_CRUMB_STATUS.TODO]: <div className='icon todo-icon' />,
  [STEP_CRUMB_STATUS.ERROR]: (
    <div className='icon error-icon'>
      <ErrorIcon className='block' width={24} height={24} />
    </div>
  )
};
export type STEP_CRUMB_STATUS = (typeof STEP_CRUMB_STATUS)[keyof typeof STEP_CRUMB_STATUS];
export interface ICrumb {
  id: string | number;
  status: STEP_CRUMB_STATUS;
  title: string;
  onClick?: (item: this) => void;

  /**
   * @description form data for each step
   */
  _formData?: any;
}

export interface IStepCrumbProps<T> {
  data: T[];
}
const StepCrumb = <T extends ICrumb = ICrumb>({ data }: IStepCrumbProps<T>) => {
  const { t } = useTranslation();
  return (
    <div className='scenario-step-crumb'>
      {data.map((item, index) => {
        return (
          <div key={item.id ?? index} className={classNames('step-crumb-item', item.status)}>
            {ICON[item.status]}
            <p
              className='step-crumb-item__hyperlink'
              tabIndex={item.status === STEP_CRUMB_STATUS.COMPLETED ? 1 : -1}
              onClick={() => item.onClick && item.onClick(item)}
            >
              {t(item.title)}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default StepCrumb;
