import DOMPurify from 'dompurify';
import Quill from 'quill';

const Inline: any = Quill.import('blots/inline');

export default class LinkBlot extends Inline {
  static blotName = 'link';
  static tagName = 'a';

  static create(value: string) {
    const node = super.create();
    const sanitizeValue = DOMPurify.sanitize(value);
    node.setAttribute('href', sanitizeValue);
    node.setAttribute('target', '_blank');
    return node;
  }

  static formats(node: HTMLElement) {
    const href = node.getAttribute('href');
    const target = node.getAttribute('target');
    return { href, target };
  }
}
