import { DefaultOptionType } from 'antd/es/select';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import useFetch from './useFetch';

const useTargetAudience = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [targetAudienceOptions, setTargetAudienceOptions] = useState<DefaultOptionType[]>([]);
  const { data: targetAudienceData } = useFetch<DefaultOptionType[]>(`/prj/projects/${id}/stakeholders?query=includeInActive`, 'GET');

  useEffect(() => {
    if (targetAudienceData?.length) {
      setTargetAudienceOptions(
        targetAudienceData.map((i) => ({
          label: (i.stakeHolderStatus ? '' : `(${t('announcement:invalid')})`) + i.stakeHolderName,
          value: i.stakeHolderId
        }))
      );
    }
  }, [targetAudienceData]);

  return {
    targetAudienceOptions
  };
};

export default useTargetAudience;
