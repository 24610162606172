export const CONTENT_TYPE = {
  TEXT: 'TEXT',
  TEXTAREA: 'TEXTAREA',
  TEXTPAIR_LEFT: 'TEXTPAIR-LEFT',
  TEXTPAIR_RIGHT: 'TEXTPAIR-RIGHT',
  DROPDOWN: 'DROPDOWN',
  RADIO: 'RADIO',
  PHONE_NUMBER: 'PHONE_NUMBER',
  DATETIME: 'DATETIME',
  DATETIMEFROM_TO: 'DATETIMEFROM-TO',
  QUESTION_ONLY: 'QUESTION-ONLY',
  CHECKBOX: 'CHECKBOX',
  TABLE: 'TABLE',
  TEXT_MULTI: 'TEXT-MULTI',
  POSTAL_CODE: 'POSTAL-CODE',
  NONE: '-'
};

export const INPUT_TYPE = {
  TEXT: 'TEXT',
  TEXTAREA: 'TEXTAREA',
  RADIO: 'RADIO',
  DATETIME: 'DATETIME',
  LABEL_ONLY: 'LABEL',
  DROPDOWN: 'DROPDOWN',
  TABLE: 'TABLE'
};

export type AnswerType = 'fullsize' | 'halfsize' | 'number';

export interface IAnswer {
  type?: string;
  answered?: any;
  answeredEn?: any;
  isCopyAnswer?: boolean;
}

export interface IQuestion {
  id?: string;
  no?: number;
  title: string;
  titleEn?: string;
  placeHolder?: string;
  file?: File;
  level?: number;
  type: string;
  getFromMaster?: boolean;
  masterApi?: string;
  needToAnswer?: boolean;
  visibleExternalUser?: boolean;
  translateFlag?: boolean;
  questions?: IQuestion[]; // You can replace `any` with the appropriate type
  options?: IQuestionOption[]; // You can replace `any` with the appropriate type
  answer?: IAnswer;
  categoryName?: string;
  pairId?: string;
  remark?: string;
  remarkEn?: string;
  config?: any; // JSON object;
  labelInput?: string[];
  relationshipTo?: string;
  categoryIndex?: number; // Field FE create to check index of category
  updatedTime?: Date;
  answerLanguage?: AnswerType;
  templateQuestionnaireId?: string;
}

export interface IQuestionOption {
  id: string;
  title: string;
  titleEn?: string;
  label?: string;
  config?: any;
  questionId: string;
  displayOrder: number;
  questions: IQuestion[];
}

export interface IQuestionCategory {
  name: string;
  displayOrder: number;
  totalQuestion: number;
  questions: IQuestion[];
  children?: IQuestion[];
}

export interface IQuestionnaireBrief {
  id: string;
  objectName: string;
  projectId: string;
  firstName: string;
  lastName: string;
  lock: boolean;
  lockBy: string;
  isTranslated: boolean;
  lockQuestionnaire: boolean;
  unlockKey: string;
  version: number;
}

export interface IQuestionnaire {
  id: string;
  familyId?: string;
  projectId: string;
  firstName: string;
  lastName: string;
  lock: boolean; // lock submit questionnaire (lock edit for external)
  lockQuestionnaire: boolean; // using to check user could access the questionnaire detail
  submittedBy?: string;
  submittedAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  questionnaireVersions: string[];
  questionnaireVersion: string;
  version?: number;
  questionCategories: IQuestionCategory[];
  isTranslated?: boolean;
  unlockKey?: string;
  objectName?: string;
  projectCode?: string;
}

export interface IQuestionnaireAnswer {
  isSubmit: boolean;
  totalQuestion: number;
  totalAnswer: number;
  questionnaireSettingChanges: IQuestionnaireSettingChange[];
  questionnaireAnswerUpserts: IQuestionnaireAnswerUpsert[];
  version?: number;
}

export interface IQuestionnaireSettingChange {
  id: string;
  visibleExternalUser: boolean;
}

export interface IQuestionnaireAnswerUpsert {
  projectQuestionnaireId: string;
  answered: string | null;
  answeredEn?: string | null;
  isCopyAnswer?: boolean | null;
}

export interface IQuestionRecord {
  id: string;
  no?: number;
  title?: string;
  translateFlag: boolean;
  visibleExternalUser: boolean;
  updatedTime: Date;
  categoryIndex: number;
  type?: string;
}

export interface IQuestionCategoryRecord {
  name: string;
  index: number;
  children?: IQuestionRecord[];
}
