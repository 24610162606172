import { Col, Row } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { useEffect, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BaseButton } from '@/components/base-button/BaseButton';
import { FormInputDate } from '@/components/form-input-date/FormInputDate';
import { FormInputSelect } from '@/components/form-input-select/FormInputSelect';

import useFetch from '@/hooks/useFetch';

import { Ii18n } from '@/utils/interfaces/i18n';

import Minus from '@/assets/icons/Minus.svg';

interface IFilterProps {
  formMethod?: any;
  onSubmit: (values: FieldValues) => void;
  defaultValues: { [key: string]: any };
}

export interface IPhraseCategory {
  id: string;
  name: string;
  displayOrder: number;
}

const PrimaryFormFilter = ({ defaultValues, onSubmit }: IFilterProps) => {
  const form = useForm({ defaultValues });
  const [categoryOptions, setCategoryOptions] = useState<DefaultOptionType[]>([]);
  const { t }: Ii18n = useTranslation();

  const { data } = useFetch<IPhraseCategory[]>(`/prj/phrases/category`, 'GET');

  useEffect(() => {
    if (data && data.length) {
      setCategoryOptions(
        data.map((i) => ({
          label: i.name,
          value: i.id
        }))
      );
    }
  }, [data]);

  const handleReset = () => {
    form.reset({
      categoryId: null,
      updatedDateFrom: null,
      updatedDateTo: null
    });
  };

  const { categoryId, updatedDateFrom, updatedDateTo } = form.watch();
  const disableResetBtn = () => {
    return !categoryId && !updatedDateFrom && !updatedDateTo;
  };

  return (
    <>
      <FormProvider {...form}>
        <div className='p-4 w-[500px]'>
          <h3 className='text-xl font-semibold'>{t('project:list:filter_btn_label')}</h3>
          <div className='item mb-5'>
            <FormInputSelect
              control={form.control}
              name='categoryId'
              labelTx='master_data:phrase:category'
              options={categoryOptions}
              placeholder={t('placeholder:select', { field: t('master_data:phrase:category') })}
            />
          </div>
          <div className='row mb-8'>
            <Row gutter={16}>
              <Col span={11}>
                <FormInputDate
                  name='updatedDateFrom'
                  labelTx='master_data:phrase:edited_date'
                  placeholder={t('placeholder:date')}
                  disabledDate={(d) => {
                    return form.getValues('updatedDateTo') && d.startOf('day').isAfter(form.getValues('updatedDateTo'));
                  }}
                />
              </Col>
              <Col span={2}>
                <div className='mt-10'>
                  <Minus />
                </div>
              </Col>
              <Col span={11}>
                <FormInputDate
                  name='updatedDateTo'
                  label='&nbsp;'
                  placeholder={t('placeholder:date')}
                  disabledDate={(d) => {
                    return form.getValues('updatedDateFrom') && d.endOf('day').isBefore(form.getValues('updatedDateFrom'));
                  }}
                />
              </Col>
            </Row>
          </div>
          <div className='flex justify-end space-x-2'>
            <div className='w-[120px]'>
              <BaseButton onClick={handleReset} type='tertiary' size='medium' disabled={disableResetBtn()}>
                {t('project:filter:btn_reset')}
              </BaseButton>
            </div>
            <div className='w-[120px]'>
              <BaseButton onClick={form.handleSubmit(onSubmit)} type='primary' size='medium'>
                <span>{t('project:filter:btn_aply')} </span>
              </BaseButton>
            </div>
          </div>
        </div>
      </FormProvider>
    </>
  );
};

export default PrimaryFormFilter;
