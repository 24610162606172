import { AxiosResponse } from 'axios';

import { axiosInstance } from '../../config/http';
import { AXIOS_TIMEOUT_UPLOAD } from '../constants/AppConstants';
import { IProcessPatterns } from '../interfaces/masterdata';

export const getTemplates = async (params: any) => {
  const res = await axiosInstance.post(`/tmpl/templates/search`, params);

  return res;
};

export const getProcessPatterns = async (params?: any) => {
  const res = await axiosInstance.get(`/tmpl/patterns`, params);

  return res as AxiosResponse<IProcessPatterns[]>;
};

export const getDocuments = async (projectId: string = '') => {
  const res = await axiosInstance.post(`/mst/masterdocuments/search?projectId=${projectId}`);

  return res;
};

export const getTasks = async (params?: { name: string }) => {
  const res = await axiosInstance.post(`/mst/tasks/search`, params);

  return res;
};

export const getTemplateById = async (id: string) => {
  const res = await axiosInstance.get(`/tmpl/templates/${id}`);

  return res;
};

export const getDocumentByTemplateId = async (id: string) => {
  const res = await axiosInstance.get(`/tmpl/templates/${id}/documents`);

  return res;
};

export const getQuestionByTemplateId = async (id: string, params?: any) => {
  const res = await axiosInstance.get(`/tmpl/templates/${id}/questionnaires`, { params });

  return res;
};

export const createTemplate = async (params: any) => {
  const res = await axiosInstance.post(`/tmpl/templates`, params);

  return res;
};
export const updateTemplate = async (params: any) => {
  const res = await axiosInstance.put(`/tmpl/Templates`, params);

  return res;
};
export const deleteTemplates = async (params?: { ids: { id: string; version: string }[] }) => {
  const res = await axiosInstance.delete(`/tmpl/templates`, {
    data: params
  });
  return res;
};

export const upsertQuestionnaire = async (params: any) => {
  const res = await axiosInstance.put(`/tmpl/templates/questionnaire`, params, {
    timeout: AXIOS_TIMEOUT_UPLOAD
  });
  return res;
};

export const getSectionDetails = async (params: { templateId: string; sectionId: string; objectId: string }) => {
  const { templateId, sectionId, objectId } = params;
  const res = await axiosInstance.get(`/tmpl/templates/${templateId}/questionnaire/${sectionId}/objects/${objectId}`, {
    timeout: AXIOS_TIMEOUT_UPLOAD
  });
  return res;
};
export const getDocumentsByCountryId = async (countryId: string) => {
  const res = await axiosInstance.get(`mst/documents?countryId=${countryId}`);
  return res;
};
export const getTasksByCountryId = async (countryId: string) => {
  const res = await axiosInstance.get(`mst/tasks?countryId=${countryId}`);
  return res;
};
