import { DefaultOptionType } from 'antd/es/select';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

import AppTooltip from '@/components/app-tooltip/AppTooltip';
import { BaseButton } from '@/components/base-button/BaseButton';
import EmptyData from '@/components/table/components/EmptyData';

import useAuthorization from '@/hooks/useAuthorization';

import { ANNOUNCEMENT_LIST_URL, CREATE_ANNOUNCEMENT_URL, VIEW_ANNOUNCEMENT_URL } from '@/utils/constants/RouteContants';
import { DataViewer } from '@/utils/helpers/common';
import { formatDayjsToString } from '@/utils/helpers/globalHelper';
import { Ii18n } from '@/utils/interfaces/i18n';

import EllipseGreen from '@assets/icons/EllipseGreen.svg';
import Warning from '@assets/icons/Warning.svg';

interface AnnouncementProps {
  announcements: DefaultOptionType[];
}
const Announcement = ({ announcements }: AnnouncementProps) => {
  const { t }: Ii18n = useTranslation();
  const { id } = useParams();
  const { isInternalRole, isExternalRole } = useAuthorization();
  const navigate = useNavigate();

  const countUnRead = announcements.filter((item) => !item.userReaded).length;

  const handleViewAnnouncement = (notice: any) => {
    if (!id || !notice) return;
    navigate(VIEW_ANNOUNCEMENT_URL(id ?? '', notice.id ?? ''));
  };

  return (
    <div className='project-detail-announcement col-span-1 rounded-[10px] bg-white border-gray2 py-[16px] gap-[8px] flex flex-col justify-between border-[1px] border-solid'>
      <div>
        <div className='flex justify-between px-[16px]'>
          <div className='text-[18px] text-lnk font-semibold leading-[25px]'>{t('project_detail:notification')}</div>
          {isInternalRole && (
            <BaseButton onClick={() => navigate(CREATE_ANNOUNCEMENT_URL(id ?? ''))} type='primary' size='medium' className='!py-2 !px-3 '>
              {t('project_detail:buttons:create_announcement')}
            </BaseButton>
          )}
        </div>
        {announcements.length ? (
          <>
            {isExternalRole && (
              <div className={classNames('py-[8px] px-[16px] flex gap-[8px] mt-[8px]', !!countUnRead ? 'bg-pink' : 'bg-gray2')}>
                {!!countUnRead && <Warning />}
                <span className={classNames('text-[14px] font-bold leading-[22px]', !!countUnRead ? 'text-negative' : 'text-gray5')}>
                  {!!countUnRead ? t('project_detail:inform_noti', { total: countUnRead }) : t('project_detail:no_noti')}
                </span>
              </div>
            )}
            {announcements.map((item) => (
              <div key={`${item.id}`} className='flex items-center justify-between py-[4px] pr-[16px] gap-[8px]'>
                <div className={`max-width-form-title flex items-center gap-[8px] ${item.userReaded ? 'pl-[24px]' : 'pl-[10px]'}`}>
                  {!item.userReaded && <EllipseGreen className='min-w-[6px]' />}
                  <AppTooltip title={DataViewer.display(item.title)}>
                    <div onClick={() => handleViewAnnouncement(item)} className='truncate inline-block max-width-title cursor-pointer link-custom'>
                      {DataViewer.display(item.title)}
                    </div>
                  </AppTooltip>
                </div>
                <div className='d-flex items-center justify-start gap-[8px]'>
                  <div className='col-span-1 text-end text-[13px] font-normal w-[70px] text-[#525A68] leading-[20px]'>
                    {DataViewer.display(formatDayjsToString(item.publishedDate))}
                  </div>
                  {isInternalRole && (
                    <AppTooltip className='truncate inline-block w-[70px] body-small-bold' title={DataViewer.display(item.publishedName)}>
                      {DataViewer.display((item.publishedStatus ? '' : `(${t('account_list:status_account:inactive')})`) + item.publishedName)}
                    </AppTooltip>
                  )}
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className='flex items-center justify-center text-center'>
            <EmptyData />
          </div>
        )}
      </div>

      <div className='link-announcement text-center subtitle-15'>
        <Link to={ANNOUNCEMENT_LIST_URL(id ?? '')} className='inline'>
          {t('project_detail:announcement_list')}
        </Link>
      </div>
    </div>
  );
};

export default Announcement;
