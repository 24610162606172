import { RuleObject } from 'antd/es/form';
import dayjs, { Dayjs } from 'dayjs';
import { FieldErrors, FieldValues, LiteralUnion, RegisterOptions } from 'react-hook-form';

import { REGEX_FULL_SIZE_AND_SPECIAL_CHARACTER_FULL_SIZE, REGEX_HALF_SIZE_AND_SPECIAL_CHARACTER } from '@utils/constants/regex';

import i18n from '../../i18n';
import { MAX_DATE, MIN_DATE } from '../constants/AppConstants';
import { Ii18n } from '../interfaces/i18n';

const { t }: Ii18n = i18n;

const valiDatePastDate =
  ({ t }: Ii18n) =>
  (_: RuleObject, value: Dayjs | null) => {
    if (value && dayjs(value) < dayjs().startOf('day')) {
      return Promise.reject(new Error(t('common:MSG_044')));
    }

    return Promise.resolve();
  };

const disabledPastDate = (current: dayjs.Dayjs) => {
  return current && current < dayjs().startOf('day');
};
const disabledFutureDate = (current: dayjs.Dayjs) => {
  return current && current > dayjs().endOf('day');
};
const disableMinMaxDate = (d: Dayjs) => {
  return d.isBefore(MIN_DATE) || d.isAfter(MAX_DATE);
};

const valiDateRequiredValue = (field: string) => (_: RuleObject, value: any) => {
  if (!value?.trim()) {
    return Promise.reject(new Error(t('common:MSG_001_textbox', { field })));
  }

  return Promise.resolve();
};

const isFullSize = (value: any) => {
  return REGEX_FULL_SIZE_AND_SPECIAL_CHARACTER_FULL_SIZE.test(value ? String(value) : '');
};

const isHalfSize = (value: any) => {
  return REGEX_HALF_SIZE_AND_SPECIAL_CHARACTER.test(value ? String(value) : '');
};

const isError = (error: any, types: LiteralUnion<keyof RegisterOptions, string>[]): boolean => {
  // if error 's type is FieldError
  if (error.type && error.message) {
    return !types.includes(error.type);
  }
  if (typeof error === 'object') {
    if (error instanceof Array) {
      return error.some((e) => isError(e, types));
    }
    return Object.keys(error).some((key) => {
      const item = error[key];
      return isError(item, types);
    });
  }

  return false;
};

const validateWithoutErrorTypes = <T extends FieldValues>(errors: FieldErrors<T>, types: LiteralUnion<keyof RegisterOptions, string>[]) => {
  for (const key in errors) {
    const item = errors[key];
    if (isError(item as any, types)) {
      return true;
    }
  }
  return false;
};

export {
  disabledPastDate,
  valiDatePastDate,
  disableMinMaxDate,
  valiDateRequiredValue,
  disabledFutureDate,
  isFullSize,
  isHalfSize,
  validateWithoutErrorTypes
};
