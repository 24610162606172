import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Row, Space } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { get } from 'lodash';
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { FieldValues, FormProvider, SubmitHandler, UseFormReturn, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { BaseButton } from '@/components/base-button/BaseButton';
import { BaseCollapse } from '@/components/base-collapse/BaseCollapse';
import BaseModal from '@/components/base-modal/BaseModal';
import { TYPE_ICON, showBasePopup } from '@/components/base-popup/BasePopup';
import { FormInput } from '@/components/form-input/FormInput';
import { FormSelect } from '@/components/form-select/FormSelect';

import useAuthorization from '@/hooks/useAuthorization';

import {
  EMAIL_REGEX,
  ERROR_CODE,
  ERROR_EMAIL_DUPLICATE,
  EXTERNAL_ADMIN,
  EXTERNAL_GENERAL_USER,
  INTERNAL_ADMIN,
  INTERNAL_GENERAL_USER
} from '@/utils/constants/AppConstants';
import { INTERNAL_COMPANY_ID } from '@/utils/constants/account';
import { validationEmail } from '@/utils/services/ProjectApiService';
import { createUser, getRoles, requestUser } from '@/utils/services/UserApiService';

import PlusIcon from '@/assets/icons/PlusIcon.svg';

import { MAX_FIELD, PROJECT_DEFAULT_VALUE } from '../contants';
import { IFormMethodRegisterProject, IProjectStakeholder } from '../models';
import StakeHolderCard from '../scenario/components/stakeholder/StakeHolderCard';
import StakeHolderInput from '../scenario/components/stakeholder/StakeHolderInput';
import { FormCreateAccount } from '../validation/FormValidation';

type Props = {
  formMethod: UseFormReturn<IFormMethodRegisterProject, any>;
  companyOptions: DefaultOptionType[];
};

const ProjectStakeholdersComponent = forwardRef((props: Props, ref) => {
  const { formMethod, companyOptions } = props;
  const { id } = useParams();
  const { t } = useTranslation();
  const wrapperRef = useRef<any>(null);
  const { user, isInternalAdmin } = useAuthorization();
  const [showPopupCreateAccount, setShowPopupCreateAccount] = useState<boolean>(false);
  const [roleOptions, setRoleOptions] = useState<DefaultOptionType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [disabledRole, setDisabledRole] = useState<boolean>(false);
  const [indexAccount, setIndexAccount] = useState<number>(0);
  const projectStakeholders = formMethod.watch('projectStakeholders') || [];
  const applicantCompany = formMethod.watch('applicantCompany');
  const formCreateAccountMethod = useForm<IProjectStakeholder>({
    mode: 'all',
    resolver: yupResolver(FormCreateAccount)
  });
  const { isDirty } = formCreateAccountMethod.formState;
  const { append, remove, update } = useFieldArray({
    name: 'projectStakeholders',
    control: formMethod.control
  });
  useEffect(() => {
    if (user) {
      getRoleData();
    }
  }, [user]);
  const validateAccount = async (email: string, index: number) => {
    const params = {
      emails: [email],
      companyId: user?.organizationId || ''
    };
    try {
      const res = await validationEmail(params);
      if (res?.data?.length) {
        const account = res?.data[0];
        formMethod.setValue(`projectStakeholders.${index}.stakeholderName`, account.name);
        formMethod.setValue(`projectStakeholders.${index}.rejected`, account.rejected);
        formMethod.setValue(`projectStakeholders.${index}.waitCreateAccount`, account.waitingCreate);
        formMethod.setValue(`projectStakeholders.${index}.stakeholderCompanyName`, account.companyName);
        formMethod.setValue(`projectStakeholders.${index}.roleName`, account.role);
      }
    } catch (error) {}
  };

  const rolesByCompany = useMemo(() => {
    const companyId = applicantCompany?.value;
    if (!companyId) return roleOptions;
    return roleOptions.filter((role) => {
      return companyId === INTERNAL_COMPANY_ID
        ? [INTERNAL_ADMIN, INTERNAL_GENERAL_USER].includes(role.type)
        : [EXTERNAL_ADMIN, EXTERNAL_GENERAL_USER].includes(role.type);
    });
  }, [roleOptions, applicantCompany]);

  const addNewProjectStakeholders = () => {
    if (projectStakeholders?.length >= MAX_FIELD) return;
    const guid = uuid();
    append({ stakeholderEmail: '', canDelete: true, uid: guid });
  };

  const handleRemove = (index: number) => {
    if (projectStakeholders?.length > 1) {
      remove(index);
    } else {
      formMethod.setValue(`projectStakeholders`, PROJECT_DEFAULT_VALUE.projectStakeholders);
    }
  };

  const handleBlur = async (index: number) => {
    const account = formMethod.getValues(`projectStakeholders.${index}`);
    const email = account.stakeholderEmail;
    formMethod.trigger(`projectStakeholders.${index}.stakeholderEmail`);
    if (isEmail(email)) {
      await validateAccount(email, index);
    }
  };
  const getRoleData = async () => {
    try {
      const { data: responseRoles } = await getRoles();
      const mapRolesByUser = (responseRoles || []).filter((role: { code: string }) =>
        [EXTERNAL_ADMIN, EXTERNAL_GENERAL_USER, INTERNAL_ADMIN, INTERNAL_GENERAL_USER].includes(role?.code)
      );
      const roles = mapRolesByUser?.map((role: any) => {
        return {
          value: role?.id,
          label: t(`account_list:filter:roles:${String(role.code).toLowerCase()}`),
          type: role?.code
        };
      });
      setRoleOptions(roles);
    } catch (error) {}
  };
  const isEmail = (email: string) => {
    return email && EMAIL_REGEX.test(email);
  };
  const handleCloseCreateModal = async () => {
    if (isDirty) {
      const showPopup = await showBasePopup({
        title: String(t('dialog:cancel_editing:header')),
        msg: String(t('dialog:cancel_editing:content')),
        type: TYPE_ICON.DISCARD
      });
      if (showPopup !== TYPE_ICON.CONFIRM) return;
      formCreateAccountMethod.reset();
      setShowPopupCreateAccount(false);
    } else {
      setShowPopupCreateAccount(false);
    }
  };
  const openPopupCreateAccount = (index: number) => {
    const emailCreate = formMethod.getValues(`projectStakeholders.${index}.stakeholderEmail`);
    formCreateAccountMethod.setValue('stakeholderName', '');
    formCreateAccountMethod.setValue('stakeholderCompany', applicantCompany);
    formCreateAccountMethod.setValue('stakeholderEmail', emailCreate);
    if (!isInternalAdmin && applicantCompany?.value === INTERNAL_COMPANY_ID) {
      const roleInteral = rolesByCompany.find((item: any) => item.type === INTERNAL_GENERAL_USER);
      const roleVal = roleInteral
        ? {
            label: String(roleInteral.label),
            value: String(roleInteral.value)
          }
        : null;
      formCreateAccountMethod.setValue('role', roleVal);
      setDisabledRole(true);
    } else {
      formCreateAccountMethod.setValue('role', null);
      setDisabledRole(false);
    }
    setTimeout(() => formCreateAccountMethod.reset((pre) => ({ ...pre, isDirty: false })), 0);
    setShowPopupCreateAccount(true);
    setIndexAccount(index);
  };
  const handleSubmitAccount = () => {
    formCreateAccountMethod.handleSubmit(isInternalAdmin ? createAccount : requestAccount, onError)();
  };
  const createAccount: SubmitHandler<IProjectStakeholder> = async (formData) => {
    const payload = {
      email: formData.stakeholderEmail,
      upn: formData.stakeholderEmail,
      name: formData.stakeholderName,
      organizationId: formData.stakeholderCompany?.value,
      roleId: formData.role?.value,
      status: true
    };
    try {
      setLoading(true);
      const { data } = await createUser(payload);
      setShowPopupCreateAccount(false);
      const guid = uuid();
      update(indexAccount, {
        ...formMethod.getValues(`projectStakeholders.${indexAccount}`),
        stakeholderEmail: data.email,
        stakeholderName: data.name,
        stakeholderCompanyId: formData.stakeholderCompany?.value,
        stakeholderCompanyName: formData.stakeholderCompany?.label,
        roleName: data.roleName,
        rejected: false,
        waitCreateAccount: false,
        uid: guid
      });
      formMethod.clearErrors(`projectStakeholders.${indexAccount}.stakeholderEmail`);
    } catch (error: any) {
      if (error?.response) {
        const {
          data: { fields }
        } = error.response;
        if (fields[0].errorCode) {
          const errorMapping = {
            [ERROR_CODE.USER_WAITING_CREATE]: String(t('common:MSG_P_032')),
            [ERROR_CODE.USER_RESTRICT]: String(t('common:MSG_P_033')),
            [ERROR_CODE.FIELD_NOT_FOUND]: String(t('common:MSG_P_033')),
            [ERROR_CODE.USER_INACTIVE]: String(t('common:MSG_P_034')),
            [ERROR_EMAIL_DUPLICATE]: String(t('common:MSG_P_022'))
          };
          const message = errorMapping[fields[0].errorCode] ?? String(t('common:MSG_C_014'));
          formCreateAccountMethod.setError('stakeholderEmail', { type: 'accountInvalid', message });
        }
      }
    } finally {
      setLoading(false);
    }
  };
  const requestAccount: SubmitHandler<IProjectStakeholder> = async (formData) => {
    const payload = {
      projectId: id,
      email: formData.stakeholderEmail,
      name: formData.stakeholderName,
      organizationId: formData.stakeholderCompany?.value,
      roleId: formData.role?.value,
      urlRedirect: '/accounts-master/edit-pending'
    };
    try {
      setLoading(true);
      const { data } = await requestUser(payload);
      setShowPopupCreateAccount(false);
      const guid = uuid();
      update(indexAccount, {
        ...formMethod.getValues(`projectStakeholders.${indexAccount}`),
        stakeholderEmail: data.email,
        stakeholderName: data.name,
        stakeholderCompanyId: formData.stakeholderCompany?.value,
        stakeholderCompanyName: formData.stakeholderCompany?.label,
        roleName: data.roleName,
        rejected: false,
        waitCreateAccount: true,
        canDelete: false,
        uid: guid
      });
      formMethod.clearErrors(`projectStakeholders.${indexAccount}.stakeholderEmail`);
    } catch (error: any) {
      if (error?.response) {
        const {
          data: { fields }
        } = error.response;
        if (fields[0].errorCode) {
          const errorMapping = {
            [ERROR_CODE.USER_WAITING_CREATE]: 'common:MSG_P_032',
            [ERROR_CODE.USER_RESTRICT]: String(t('common:MSG_P_033')),
            [ERROR_CODE.FIELD_NOT_FOUND]: String(t('common:MSG_P_033')),
            [ERROR_CODE.USER_INACTIVE]: String(t('common:MSG_P_034')),
            [ERROR_EMAIL_DUPLICATE]: String(t('common:MSG_P_022'))
          };
          const message = errorMapping[fields[0].errorCode] ?? String(t('common:MSG_C_014'));
          formCreateAccountMethod.setError('stakeholderEmail', { type: 'accountInvalid', message });
        }
      }
    } finally {
      setLoading(false);
    }
  };
  const onError = (errors: FieldValues) => {};
  return (
    <BaseCollapse
      ref={ref}
      className='gap-2'
      id='projectStakeholders'
      title={t('project:add:project_stakeholders:title')}
      isError={!!formMethod?.formState?.errors?.projectStakeholders}
    >
      {projectStakeholders?.map((field, index) => {
        if ((!field.canDelete && field?.waitCreateAccount) || (field.id && field.rejected))
          return <StakeHolderCard key={field.id || field.uid} field={field} index={index} handleRemove={handleRemove} />;
        const errorInput = get(formMethod.formState.errors, `projectStakeholders.${index}`) as any;
        return (
          <StakeHolderInput
            companyId={applicantCompany?.value || ''}
            pathEmail={`projectStakeholders.${index}.stakeholderEmail`}
            key={field.id ?? field.uid}
            required={false}
            field={field}
            index={index}
            errorInput={errorInput}
            questions={formMethod.watch(`projectStakeholders.${index}.stakeholderEmail`) ? 1 : 0}
            showIconRemove={projectStakeholders.length > 1}
            handleRemove={handleRemove}
            handleBlur={handleBlur}
            openPopupCreateAccount={openPopupCreateAccount}
          />
        );
      })}
      <Space size={16}>
        <div className='min-w-[140px]'>
          <BaseButton
            icon={<PlusIcon />}
            disabled={projectStakeholders?.length === MAX_FIELD}
            type='secondary'
            block
            size='medium'
            onClick={addNewProjectStakeholders}
          >
            {t('project:add:project_stakeholders:add_stakeholders')}
          </BaseButton>
        </div>
      </Space>
      <div ref={wrapperRef}>
        <BaseModal
          getContainer={wrapperRef.current}
          title={
            <span className='title-24'>
              {isInternalAdmin ? t('template_layout:template_create') : t('project:add:project_stakeholders:request_create_title')}
            </span>
          }
          onCancel={handleCloseCreateModal}
          maskClosable={false}
          openModal={showPopupCreateAccount}
          destroyOnClose
          contentElement={
            <FormProvider {...formCreateAccountMethod}>
              <Row gutter={16} className='gap-4'>
                <Col span={24}>
                  <FormInput
                    required={true}
                    defaultValue={formCreateAccountMethod.getValues('stakeholderName')}
                    name='stakeholderName'
                    labelTx='basic_information:username'
                    placeholderTx={String(t('placeholder:text_box', { field: t('basic_information:username') }))}
                  />
                </Col>
                <Col span={24}>
                  <FormInput
                    required={true}
                    defaultValue={formCreateAccountMethod.getValues('stakeholderEmail')}
                    name='stakeholderEmail'
                    labelTx='basic_information:email_address'
                    placeholderTx={String(t('placeholder:text_box', { field: t('basic_information:email_address') }))}
                  />
                </Col>
                <Col span={24}>
                  <FormSelect
                    disabled
                    required={true}
                    labelInValue
                    name='stakeholderCompany'
                    label={String(t('basic_information:company_name'))}
                    placeholder={String(t('placeholder:select', { field: t('basic_information:company_name') }))}
                    options={companyOptions}
                  />
                </Col>
                <Col span={24}>
                  <FormSelect
                    labelInValue
                    required={true}
                    disabled={disabledRole}
                    name='role'
                    label={String(t('account_list:role'))}
                    placeholder={String(t('placeholder:select', { field: t('account_list:role') }))}
                    options={rolesByCompany}
                  />
                </Col>
                <Col span={24} className='flex justify-end gap-4'>
                  <BaseButton type='tertiary' size='medium' className='min-w-[120px]' onClick={handleCloseCreateModal}>
                    <span> {t('button:cancel')} </span>
                  </BaseButton>
                  <BaseButton disabled={loading} size='medium' onClick={handleSubmitAccount}>
                    <span> {isInternalAdmin ? t('button:create') : t('common:button:send')} </span>
                  </BaseButton>
                </Col>
              </Row>
            </FormProvider>
          }
        ></BaseModal>
      </div>
    </BaseCollapse>
  );
});

export default ProjectStakeholdersComponent;
