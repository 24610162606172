import React from 'react';
import { useTranslation } from 'react-i18next';

import InfoIcon from '@/assets/icons/Info.svg';

const Introduction = () => {
  const { t } = useTranslation();

  return (
    <div className='mb-[12px]'>
      <p className='mb-[12px] font-semibold text-[18px] leading-[25px]'>{t('inquiry:introduction')}</p>
      <div className='flex'>
        <div className='py-[2px] mr-[4px]'>
          <InfoIcon width={14.63} height={14.63} />
        </div>
        <div>
          <p>{t('inquiry:introduction_detail_1')}</p>
          <p>{t('inquiry:introduction_detail_2')}</p>
        </div>
      </div>
    </div>
  );
};

export default Introduction;
