import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { BaseButton } from '@/components/base-button/BaseButton';

import { EDIT_PROJECT_URL, PROJECT_LIST_URL } from '@/utils/constants/RouteContants';

import PersonThankYouSaveAsDraft from '@/assets/images/PersonThankYouSaveAsDraft.svg';

export default function SaveAsDraftSuccess() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  return (
    <div className='w-full h-screen flex flex-col items-center justify-center'>
      <PersonThankYouSaveAsDraft width={145} height={228}></PersonThankYouSaveAsDraft>
      <h1 className='title-24'>{t('common:MSG_031')}</h1>
      <p className='subtitle-16 text-textGray'>{t('project:scenario:save_as_draft_msg_1')}</p>
      <p className='subtitle-16 text-textGray'>{t('project:scenario:save_as_draft_msg_2')}</p>
      <div className='flex gap-4'>
        <BaseButton className='mt-4' onClick={() => navigate(EDIT_PROJECT_URL(id || ''))} type='secondary'>
          {t('button:continue_editing')}
        </BaseButton>
        <BaseButton className='mt-4' onClick={() => navigate(PROJECT_LIST_URL)}>
          {t('page_not_found:back_home')}
        </BaseButton>
      </div>
    </div>
  );
}
