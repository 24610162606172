import { ChangeEvent, RefObject, forwardRef, useImperativeHandle, useState } from 'react';

import IconSearch from './../../assets/icons/base-filter/icon-search.svg';

import './SearchInput.scss';

interface IFormInputProps {
  disabled?: boolean;
  refValue?: RefObject<HTMLInputElement>;
  placeholder?: string;
  maxLength?: number;
  name?: string;
  handleSearch?: (keyword: string) => void;
}

const SearchInput = forwardRef(({ disabled, refValue, handleSearch, placeholder, maxLength, name }: IFormInputProps, ref) => {
  const [value, setValue] = useState('');
  const [submitSearch, setSubmitSearch] = useState(false);
  const onSubmit = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const value = event.target.value;
    if (handleSearch) {
      setValue(value.trim() || '');
      handleSearch(value.trim() || '');
    }
  };

  useImperativeHandle(ref, () => ({
    resetInput: () => {
      setValue('');
    },
    setInputValue: (value: string) => {
      setValue(value);
    },
    submitSearch
  }));

  return (
    <div className='search-input'>
      <IconSearch width={20} height={20} />
      <input
        name={name}
        disabled={disabled}
        type='text'
        ref={refValue}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          submitSearch && setSubmitSearch(false);
        }}
        placeholder={placeholder}
        maxLength={maxLength}
        onKeyDown={(e: any) => {
          if (e.keyCode === 13) {
            setSubmitSearch(true);
            onSubmit(e);
          }
        }}
      />
    </div>
  );
});

export default SearchInput;
