import { Col, Row } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormScenarioBContext } from '@pages/project-management/add-edit/scenario/type-b';
import { relativePath } from '@pages/project-management/add-edit/scenario/utils';

import { BaseCard } from '@/components/base-card/BaseCard';
import { FormAttachment } from '@/components/form-attachment/FormAttachment';
import { EditorWithCounter } from '@/components/form-box-editor';

import { NOTE_MAX_LENGTH } from '@/utils/constants/AppConstants';

import InfoIcon from '@assets/icons/Info.svg';

export const PARENT_PATH = 'steps[4]';
const ScenarioBStepSix = () => {
  const { watch } = useFormScenarioBContext<'step-6'>();
  const { t } = useTranslation();
  const PATHS = {
    NOTE: relativePath('note', PARENT_PATH),
    ATTACHMENTS: relativePath('projectAttachments', PARENT_PATH)
  };
  const [note, fileAttachments] = watch([PATHS.NOTE, PATHS.ATTACHMENTS]);
  const [noteLength, setNoteLength] = useState<number>(0);

  return (
    <div className='scenario-b-step-six flex flex-col gap-[12px]'>
      <p className='title-20'>{t('project:scenario_b:additional_information')}</p>
      <Row>
        <Col span={24}>
          <BaseCard title={t('basic_information:note')} total={1} questions={noteLength > 0 ? 1 : 0}>
            <EditorWithCounter
              name={PATHS.NOTE}
              onCharacterChange={setNoteLength}
              label={
                <div className='flex items-center fit-content gap-[6px]'>
                  <InfoIcon width={15} height={15} className='mb-[8px]' />
                  <p className='body-400 mb-[8px]'>{t('project:scenario_b:additional_information_guide1')}</p>
                </div>
              }
              styleCounterLabel='mb-[14px]'
              limit={NOTE_MAX_LENGTH}
              defaultValue={note}
              toolbar={null}
              placeholder={t('basic_information:place_holder_note') || ''}
              editorWrapperProps={{ className: '!h-[148px]' }}
            />
          </BaseCard>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <BaseCard title={t('basic_information:attachment_file')}>
            <div className='flex items-center fit-content gap-[6px]'>
              <InfoIcon width={15} height={15} className='mb-[8px]' />
              <p className='body-400 mb-[8px]'>{t('project:scenario_b:additional_information_guide2')}</p>
            </div>
            <FormAttachment name={PATHS.ATTACHMENTS} defaultValue={fileAttachments || []} documentType='attachments' />
          </BaseCard>
        </Col>
      </Row>
    </div>
  );
};

export default ScenarioBStepSix;
