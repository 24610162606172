import BasicInformation from '@/pages/project-management/add-edit/components/basic-information/BasicInformation';
import EditBasicInformation from '@/pages/project-management/add-edit/components/basic-information/EditBasicInformation';
import DocumentList from '@/pages/project-management/document-list';
import EditDocumentList from '@/pages/project-management/document-list/EditDocumentList';
import ProjectMemo from '@/pages/project-management/memo';
import { CommentChat } from '@/pages/project-management/task/comment';

import { ROUTER_IDS } from '@/utils/constants';
import { ALL_ROLE, INTERNAL_ADMIN, INTERNAL_GENERAL_USER } from '@/utils/constants/AppConstants';
import {
  BASIC_INFORMATION,
  COMMENT_CHAT_NAME,
  DOCUMENT_LIST,
  EDIT_BASIC_INFORMATION,
  EDIT_DOCUMENT_LIST,
  PROJECT_MEMO
} from '@/utils/constants/AuthorizationProjectManagement';
import {
  COMMENT_CHAT_OF_TASK_URL,
  DOCUMENT_LIST_URL,
  EDIT_DOCUMENT_LIST_URL,
  EDIT_PROJECT_BASIC_INFO_URL,
  PROJECT_BASIC_INFO_URL,
  PROJECT_MEMO_URL
} from '@/utils/constants/RouteContants';
import { MAP_PATH_PRE_CHECK_404 } from '@/utils/helpers/routeLoaders';
import { IRoute } from '@/utils/interfaces/route';

import { E } from '../hooks/useAuthorization';

const WithoutLayout: IRoute[] = [
  {
    path: PROJECT_BASIC_INFO_URL(':id'),
    name: BASIC_INFORMATION,
    action: E,
    element: <BasicInformation />,
    auth: true,
    role: ALL_ROLE,
    preCheck404: MAP_PATH_PRE_CHECK_404[PROJECT_BASIC_INFO_URL(':id')]
  },
  {
    path: EDIT_PROJECT_BASIC_INFO_URL(':id'),
    name: EDIT_BASIC_INFORMATION,
    action: E,
    element: <EditBasicInformation />,
    auth: true,
    role: [INTERNAL_ADMIN, INTERNAL_GENERAL_USER],
    preCheck404: MAP_PATH_PRE_CHECK_404[EDIT_PROJECT_BASIC_INFO_URL(':id')]
  },
  {
    path: DOCUMENT_LIST_URL(':id'),
    name: DOCUMENT_LIST,
    action: E,
    element: <DocumentList />,
    auth: true,
    role: ALL_ROLE,
    id: ROUTER_IDS.PROJECT_DOCUMENT_LIST,
    preCheck404: MAP_PATH_PRE_CHECK_404[DOCUMENT_LIST_URL(':id')]
  },
  {
    path: EDIT_DOCUMENT_LIST_URL(':id'),
    name: EDIT_DOCUMENT_LIST,
    action: E,
    element: <EditDocumentList />,
    auth: true,
    role: ALL_ROLE,
    preCheck404: MAP_PATH_PRE_CHECK_404[EDIT_DOCUMENT_LIST_URL(':id')]
  },
  {
    path: PROJECT_MEMO_URL(':id'),
    name: PROJECT_MEMO,
    action: E,
    element: <ProjectMemo />,
    auth: true,
    role: [INTERNAL_ADMIN, INTERNAL_GENERAL_USER],
    preCheck404: MAP_PATH_PRE_CHECK_404[PROJECT_MEMO_URL(':id')]
  },
  {
    path: COMMENT_CHAT_OF_TASK_URL(':id', ':taskId'),
    name: COMMENT_CHAT_NAME,
    action: E,
    element: <CommentChat />,
    auth: true,
    role: ALL_ROLE,
    preCheck404: MAP_PATH_PRE_CHECK_404[COMMENT_CHAT_OF_TASK_URL(':id', ':taskId')]
  }
];

export default WithoutLayout;
