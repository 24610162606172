import { axiosInstance } from '@/config/http';

export const getAccountMasterList = async (params: any) => {
  return axiosInstance.post(`/usr/users/search`, params);
};

export const getAccountMasterDetail = async (id: string) => {
  const res = await axiosInstance.get(`/usr/users/${id}`);
  return res.data;
};

export const getOrganizationList = async () => {
  return axiosInstance.get(`/usr/organizations`);
};

export const getRoleList = async () => {
  return axiosInstance.get(`/usr/roles`);
};

export const getStatus = async () => {
  return axiosInstance.get('prj/status');
};

export const postRegisterAccountMaster = async (data: any) => {
  return axiosInstance.post('/usr/users', data);
};

export const postUpdateAccountMaster = async (data: any) => {
  return axiosInstance.put('/usr/users', data);
};

export const putApproveRejectAccountMaster = async (data: any) => {
  return axiosInstance.put('/usr/users/process-request', data);
};
