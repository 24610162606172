import * as yup from 'yup';

import {
  CONTENT_MAX_LENGTH,
  TEXT_FIELD_MAX_LENGTH,
  TEXT_FIELD_PHONE_NUMBER_MAX_LENGTH,
  TEXT_FIELD_SHORT_MAX_LENGTH,
  UPLOAD_STATUS
} from '@/utils/constants/AppConstants';
import { stringifyObjectValidateYup } from '@/utils/method';

export const FormValidationScenarioE = yup.object().shape({
  steps: yup.array().of(
    yup.object().shape({
      userName: yup.string().max(
        TEXT_FIELD_SHORT_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: { field: 'inquiry:user_name_label', limited: TEXT_FIELD_SHORT_MAX_LENGTH }
        })
      ),
      email: yup
        .string()
        .fullEmail(
          stringifyObjectValidateYup({
            keyT: 'common:MSG_A_016',
            optionsTx: { field: 'basic_information:email_address' }
          })
        )
        .max(
          TEXT_FIELD_MAX_LENGTH,
          stringifyObjectValidateYup({
            keyT: 'common:MSG_C_011',
            optionsTx: { field: 'project:add:local_representative:email', limited: TEXT_FIELD_MAX_LENGTH }
          })
        ),
      companyName: yup.string().max(
        TEXT_FIELD_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: { field: 'inquiry:company_label', limited: TEXT_FIELD_MAX_LENGTH }
        })
      ),
      phoneNumber: yup
        .string()
        .max(
          TEXT_FIELD_PHONE_NUMBER_MAX_LENGTH,
          stringifyObjectValidateYup({
            keyT: 'common:MSG_C_011',
            optionsTx: { field: 'inquiry:phone_label', limited: TEXT_FIELD_PHONE_NUMBER_MAX_LENGTH }
          })
        )
        .halfSize(
          stringifyObjectValidateYup({
            keyT: 'common:MSG_C_028'
          })
        ),
      requestContent: yup
        .string()
        .nullable()
        .requiredTextEditorChange(
          stringifyObjectValidateYup({
            keyT: 'common:MSG_C_001_input',
            optionsTx: { field: 'basic_information:request_content' }
          })
        )
        .htmlStrippedMaxLength(
          CONTENT_MAX_LENGTH,
          stringifyObjectValidateYup({
            keyT: 'common:MSG_C_011',
            optionsTx: { field: 'basic_information:request_content', limited: CONTENT_MAX_LENGTH }
          })
        ),
      projectAttachments: yup.array().of(
        yup.object().shape({
          status: yup.string().test(
            'file-invalid',
            stringifyObjectValidateYup({
              keyT: 'common:MSG_FILE_INVALID'
            }),
            (value) => {
              return value === UPLOAD_STATUS.DONE;
            }
          )
        })
      )
    })
  )
});
export const FormValidationRegisterStep1 = yup.object().shape({});
