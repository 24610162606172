import CaretDown from '@/layouts/assets/icons/CaretDown.svg';
import { Collapse, CollapseProps, Space, Tooltip } from 'antd';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';

import '@/components/base-collapse/BaseCollapse.scss';

import { Ii18n } from '@/utils/interfaces/i18n';

import WarningCircle from '@/assets/icons/WarningCircle.svg';

interface IBaseCollapse {
  className?: string;
  id: string;
  title: string;
  isError?: boolean;
  children: React.ReactNode;
  questions?: number;
  total?: number;
  defaultOpen?: boolean;
}

export const BaseCollapse = forwardRef((props: IBaseCollapse, ref) => {
  const { className = '', id, title, isError = false, children, questions = 0, total = 0, defaultOpen = true } = props;
  const { t }: Ii18n = useTranslation();
  const [activeKey, setActiveKey] = useState<string[]>(defaultOpen ? [String(id)] : []);
  const [isFirst, setIsFirst] = useState(true);
  useEffect(() => {
    setIsFirst(false);
  }, []);

  const handleChange = (key: string | string[]) => {
    setActiveKey(Array.isArray(key) ? key : [key]);
  };

  const labelCollapse = () => {
    return (
      <div className='flex flex-row'>
        <span className='ant-collapse-header-text'>{title}</span>
        {!!total && (
          <div className='ml-[8px] pr-[8px] pl-[8px] bg-[#DBDBE0] rounded text-[14px] font-bold'>
            {questions}/{total}
          </div>
        )}
      </div>
    );
  };

  const renderExpandIcon = (isActive: boolean | undefined) => {
    return <CaretDown type='caret-right' className={`${isActive ? 'rotate-0' : '-rotate-90'}`} />;
  };

  const genExtra = () =>
    isError &&
    !isFirst &&
    activeKey?.length === 0 && (
      <Tooltip placement='left' title={t('collapse:collapse_tooltip')}>
        <WarningCircle className='text-gray3' />
      </Tooltip>
    );
  // TODO: refactor it for support multiple items
  const items: CollapseProps['items'] = [
    {
      key: id,
      label: labelCollapse(),
      extra: genExtra(),
      children
    }
  ];
  // TODO: refactor it for support multiple items
  useImperativeHandle(ref, () => ({
    setCollapse: (key: string | string[]) => {
      setActiveKey(Array.isArray(key) ? key : [key]);
    },
    getIdElement: () => {
      return id;
    }
  }));
  return (
    <div id={`base-collapse-${id}`} className={`base-collapse-container flex flex-col ${className}`}>
      <Space
        key={id}
        size={24}
        direction={'vertical'}
        className={`box-shadow p-4 gap-2 rounded-[8px] border-[1px] border-solid ${isError && !isFirst && activeKey?.length === 0 ? 'border-negative' : 'border-[#DBDBE0]'}`}
      >
        <Collapse
          // defaultActiveKey={[String(id)]}
          activeKey={activeKey}
          onChange={handleChange}
          className='gap-2'
          collapsible='header'
          items={items}
          bordered={false}
          expandIcon={({ isActive }) => renderExpandIcon(isActive)}
        />
      </Space>
    </div>
  );
});
