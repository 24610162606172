import { ValidateMessageObject } from '../interfaces/i18n';

type TypesBase = 'bigint' | 'boolean' | 'function' | 'number' | 'object' | 'string' | 'symbol' | 'undefined';

const onCheckType = (source: any, type: TypesBase): source is TypesBase => {
  return typeof source === type;
};
/**
 * @param keyT key of i18n
 * @param options object translate parameter
 * @param optionsTx object translate parameter will translate before set to option base. see detail bellow
 * ex: json file : {"field":{"email":"Email"},"msg":{"msg1":"{{fieldName}} is required"}}
 * => optionsTx = {fieldName:"field:email"}
 * fieldName must translate with i18n
 * so fieldName option will be push on optionsTx
 * This will support translate Option on translate
 * Read hook useMessageYupTranslation
 */
const stringifyObjectValidateYup = ({ keyT, options, optionsTx }: ValidateMessageObject) => {
  return JSON.stringify({
    keyT,
    options,
    optionsTx
  });
};

/**
 * Check 2 objects have 1 level data is the same or not
 */
const compareTwoObject = (object1: { [key: string]: any }, object2: { [key: string]: any }) => {
  for (let key in object1) {
    if (object1[key] !== object2[key] && (object1[key] || object2[key])) {
      return false;
    }
  }
  return true;
};

/**
 * Check object had data or not
 */
const checkFormNotHasData = (obj: any) => {
  for (let key in obj) {
    if (obj[key] !== undefined && obj[key] !== '' && obj[key] !== null) return false;
  }
  return true;
};

/**
 * Check object had data or not with special value is boolean
 */
const checkEmptyObj = (obj: any) => {
  let result = true;
  for (let key in obj) {
    if (obj['isConfirmed'] && obj[key] !== undefined && obj[key] !== '' && obj[key] === null) {
      result = false;
      break;
    }
    if (obj[key] !== undefined && obj[key] !== '' && obj[key] !== null && key !== 'isConfirmed') {
      result = false;
      break;
    }
  }
  return result;
};

const handleClassList = () => {
  const elementCustom = document.querySelector('.custom-row-filter');
  elementCustom?.classList.remove('custom-row-filter');
};

const handleClassListLastRow = (source: any[], className: string = '', loopAll: boolean = false) => {
  for (let i = source.length - 1; i >= 0; i--) {
    const lastElement = document.querySelector(`.${className}-${i}`);
    if (lastElement && !lastElement.classList.contains('hidden-row-filtered') && !loopAll) {
      lastElement.classList.add('custom-row-filter');
      break;
    }

    if (loopAll && lastElement?.classList.contains('hidden-row-filtered')) {
      lastElement.classList.remove('hidden-row-filtered');
    }
  }
};

const handleOrder = (record: Record<string, any>) => {
  if (record?.order !== null && record?.order !== undefined) {
    return record?.order;
  }
  return '';
};

const filterHaveData = (obj: any) => {
  let result = {};
  for (let key in obj) {
    if (obj[key] !== undefined && obj[key] !== '' && obj[key] !== null) {
      result = { ...result, [key]: obj[key] };
    }
  }
  return result;
};

export {
  handleOrder,
  onCheckType,
  checkEmptyObj,
  filterHaveData,
  compareTwoObject,
  handleClassListLastRow,
  stringifyObjectValidateYup,
  checkFormNotHasData,
  handleClassList
};
