import { TaskMasterQueryFactory } from '@/pages/master-data/task/utils';

import { QUERY_CONDITION, QUERY_OPERATION, QUERY_TYPE } from '@/utils/constants';
import { QueryItem } from '@/utils/helpers/filters/base';

import { IDocument } from '../models';

export interface ITitleQueryFilterParams {
  category?: string;
  countryIds?: string[];
  projectIds?: string[];
}

export class TitleQueryFactory extends TaskMasterQueryFactory {
  getByIds(value: string[], field: string) {
    const newQuerySection = this.initNewQuery(this.data?.length ? QUERY_CONDITION.AND : QUERY_CONDITION.EMPTY);
    value.forEach((id) => {
      const idQuery = new QueryItem(field, QUERY_TYPE.GUID, id, QUERY_OPERATION.EQUAL, QUERY_CONDITION.OR);
      newQuerySection.childrens?.push(idQuery);
    });
    this.append(newQuerySection);
    return this;
  }
}

export const generateFilter = (params?: ITitleQueryFilterParams): string => {
  const filterFactory = new TitleQueryFactory();

  if (params?.category) {
    filterFactory.getByCategory(params.category);
  }

  if (params?.countryIds) {
    filterFactory.getByIds(params.countryIds, 'countryId');
  }
  if (params?.projectIds) {
    filterFactory.getByIds(params.projectIds, 'projectId');
  }

  return filterFactory.sanitize().stringifyData();
};

export const getDocuments = (documents: IDocument[]) => {
  const list: IDocument[] = [];
  documents.forEach((item: IDocument) => {
    list.push({
      ...item,
      description: item?.note,
      id: item?.projectRequiredDocumentId || item?.projectInternalDocumentId
    });
  });
  return list;
};
