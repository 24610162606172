import { axiosInstance } from '@/config/http';

export const getAnnouncementList = async (params: any) => {
  const res = await axiosInstance.post(`/prj/projects/announcements/search`, params);
  return res;
};

export const pinAnnouncements = async (params: any, id: string) => {
  const res = await axiosInstance.post(`prj/projects/${id}/announcements/pin`, params);
  return res;
};

export const deleteAnnouncements = async (params: { ids?: { id: string; version: string }[] }, id: string) => {
  const res = await axiosInstance.delete(`/prj/projects/${id}/announcements`, {
    data: params
  });
  return res;
};

export const getStakeholdersList = async (idProject: string, params?: any) => {
  return axiosInstance.get(`/prj/projects/${idProject}/stakeholders`, { params });
};

export const postRegisterAnnouncement = async (params: any, idProject: string) => {
  const res = await axiosInstance.post(`prj/projects/${idProject}/announcements`, params);
  return res;
};
