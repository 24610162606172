import { Button, Space } from 'antd';
import { RcFile } from 'antd/es/upload';
import Dragger from 'antd/es/upload/Dragger';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import AppTooltip from '@/components/app-tooltip/AppTooltip';
import { BaseButton } from '@/components/base-button/BaseButton';
import BaseModal from '@/components/base-modal/BaseModal';
import TableBase, { FetchColumnsType } from '@/components/common/table-base';
import { InfoIcon } from '@/components/icon-svg/IconSvg';

import { MAPPING_ERROR_CODE_KEY, MAX_CAPACITY_FILE } from '@/utils/constants/AppConstants';
import { MAXIMUM_SIZE_UPLOAD_TO_BYTE } from '@/utils/constants/announcement';
import { FILE_EXCEL_ALLOWED } from '@/utils/constants/file';
import { decodeFileName } from '@/utils/helpers/globalHelper';
import { validateDataExcel } from '@/utils/services/ProjectApiService';

import IconUploadSuccess from '@/assets/icons/IconUploadSuccess.svg';
import TrashBlack from '@/assets/icons/TrashBlack.svg?react';
import UploadImage from '@/assets/icons/UploadCompleted.svg?react';
import UploadSimpleActive from '@/assets/icons/UploadSimpleActive.svg?react';

import { DEFAULT_FILE_ERROR_RES, STATUS_FILE, TYPE_DONE, TYPE_NONE, TYPE_PROCESS, TYPE_SUBMITTED, TYPE_VALIDATE } from '../constants';
import { FileError, IResponseFileError, RowFileErrorRes } from '../model';

import './FormAttachment.scss';

const VALUE_SHOW_MORE = 4;

type IProps = {
  setStatusUpload: React.Dispatch<React.SetStateAction<STATUS_FILE>>;
  statusUpload: STATUS_FILE;
  uploadedFile: RcFile | null;
  setUploadedFile: React.Dispatch<React.SetStateAction<RcFile | null>>;
};

export const FormAttachment = (props: IProps) => {
  const { statusUpload, setStatusUpload, uploadedFile, setUploadedFile } = props;
  const { t } = useTranslation();
  const [fileErrors, setFileErrors] = useState<FileError[]>([]);
  const [detailErrors, setDetailErrors] = useState<IResponseFileError>(DEFAULT_FILE_ERROR_RES);
  const [msgError, setMsgError] = useState<string>('');
  const [loadingValidate, setLoadingValidate] = useState(false);

  const validateFileUploaded = (files: RcFile[]) => {
    const file = files?.[0];
    const validFileTypes = FILE_EXCEL_ALLOWED?.split(',') || [];
    const fileExtension = file?.name.split('.').pop()?.toLowerCase();
    if (!file) return;
    if (file.size > MAXIMUM_SIZE_UPLOAD_TO_BYTE) {
      setMsgError(`${t('common:MSG_C_006')}`);
    }
    if (!fileExtension || !validFileTypes?.includes(`.${fileExtension}`)) {
      setMsgError(`${t('common:MSG_V_011')}`);
    }
    setUploadedFile(file);
    setStatusUpload(TYPE_PROCESS);
  };

  const beforeUpload = debounce(async (_, fileList: RcFile[]) => {
    validateFileUploaded(fileList);
  }, 300);

  const showFileName = (name: string) => {
    if (!name) return '';
    if (name.length <= MAX_CAPACITY_FILE) return name;
    return name.substring(0, MAX_CAPACITY_FILE).concat('...');
  };

  const onRemoveFile = () => {
    setUploadedFile(null);
    setFileErrors([]);
    setStatusUpload(TYPE_NONE);
    setMsgError('');
  };

  const renderErrorUI = (list: RowFileErrorRes[], slice: number) => {
    return (
      <ul className='pl-[16px] m-0 list-none'>
        {list?.slice(0, slice).map((e, i) => (
          <li
            key={`${e?.messageCode}-${i}-error`}
            className={`body-400 text-negative relative before:absolute before:content-[''] before:top-[10px] before:left-[-12px] before:h-[4px] before:w-[4px] before:rounded-lg before:bg-negative`}
          >
            {t(`common:${e?.messageCode}`, { field: e.header, maxLength: e?.maxLength })}
          </li>
        ))}
      </ul>
    );
  };

  const onValidateFile = async () => {
    try {
      setLoadingValidate(true);
      const formData = new FormData();
      formData.append('file', uploadedFile as RcFile);
      const { data } = await validateDataExcel(formData);

      if (data) {
        const dataConverted = data.map((el: IResponseFileError, index: number) => {
          let errorName;
          if (!el.errorDataRegisterProjects.length) {
            errorName = t('batch_registration:noProblem');
          } else {
            errorName = (
              <>
                {renderErrorUI(el.errorDataRegisterProjects, VALUE_SHOW_MORE)}
                {el.errorDataRegisterProjects?.length > VALUE_SHOW_MORE && (
                  <Button type='default' className='p-0 m-0 h-auto bg-[transparent] border-none' onClick={() => setDetailErrors(el)}>
                    <span className='text-link underline body-400'>{t('common:view_more_files')}</span>
                  </Button>
                )}
              </>
            );
          }

          return {
            no: index + 1,
            errorName,
            status: !!el.errorDataRegisterProjects.length
          };
        });
        setFileErrors(dataConverted);
        setStatusUpload(TYPE_VALIDATE);
      }
      if (data.every((el: IResponseFileError) => !el.errorDataRegisterProjects.length)) {
        setStatusUpload(TYPE_SUBMITTED);
        setMsgError('');
      }
    } catch (e: any) {
      const firstError = e?.response?.data?.fields[0]?.errorCode ?? '';
      const messageError = MAPPING_ERROR_CODE_KEY[firstError];
      setMsgError(t(messageError));
    } finally {
      setLoadingValidate(false);
    }
  };

  const columns: FetchColumnsType<any> = [
    {
      title: 'NO',
      key: 'no',
      dataIndex: 'no',
      width: 50,
      className: 'max-w-[50px] h-[38px]',
      align: 'center',
      render: (value: number) => {
        return <div className='whitespace-nowrap'>{value}</div>;
      }
    },
    {
      title: t('batch_registration:fileValidation'),
      key: 'errorName',
      dataIndex: 'errorName',
      render(value: string) {
        return <div className='whitespace-normal'>{value}</div>;
      }
    }
  ];

  return (
    <>
      {!uploadedFile && (
        <Dragger name='file' height={174} beforeUpload={beforeUpload} showUploadList={false} accept={FILE_EXCEL_ALLOWED}>
          <Space direction='horizontal'>
            <img src={UploadImage} alt='upload' className='h-[150px] pointer-events-none' />
            <div className='flex flex-col items-start'>
              <div className='text-black text-[18px] font-semibold'>{t('basic_information:attachment_file_title')}</div>
              <div className='text-textGray my-4'>{t('batch_registration:excelFile')}</div>
              <BaseButton type='secondary' className='flex items-center upload-button'>
                <img src={UploadSimpleActive} alt='upload-simple' className='h-[18px] w-[18px] mr-2' />
                {t('basic_information:browser_file')}
              </BaseButton>
            </div>
          </Space>
        </Dragger>
      )}
      {uploadedFile && (
        <>
          <div className='flex items-center gap-[16px]'>
            <div className='file-item w-[500px] p-[8px] h-[39px] truncate flex items-center justify-between'>
              <div className='flex items-center'>
                <IconUploadSuccess className={classNames('icon', !!msgError.length && '[&>path]:stroke-negative')} />
                <AppTooltip title={decodeFileName(uploadedFile.name)}>
                  <span className={classNames('max-w-[330px] truncate w-fit ml-4 font-normal body-400', !!msgError.length && 'text-negative')}>
                    {showFileName(decodeFileName(uploadedFile.name))}
                  </span>
                </AppTooltip>
              </div>
              <div className='flex items-center gap-[8px]'>
                {!!msgError.length && (
                  <AppTooltip title={msgError ?? ''}>
                    <InfoIcon width={16} height={16} className={classNames(!!msgError.length && '[&>path]:fill-negative')} />
                  </AppTooltip>
                )}
                <Button
                  type='default'
                  icon={<img src={TrashBlack} className='h-[20px] w-[20px] hover:cursor-pointer' alt='upload-simple' />}
                  onClick={onRemoveFile}
                  className='border-0 p-0 !h-auto !w-auto leading-none'
                />
              </div>
            </div>
            <BaseButton
              className={classNames(([TYPE_VALIDATE, TYPE_SUBMITTED, TYPE_DONE].includes(statusUpload) || !!msgError.length) && '!hidden')}
              type='secondary'
              disabled={loadingValidate}
              onClick={onValidateFile}
            >
              {t('batch_registration:verifyData')}
            </BaseButton>
          </div>
          {!!fileErrors.length && statusUpload !== TYPE_DONE && (
            <>
              <h3 className='mt-[12px] mb-[8px] text-16 font-semibold leading-[160%]'>{t('batch_registration:fileValidation')}</h3>
              <TableBase className='w-full' rowSelection={null} columns={columns} dataSource={fileErrors} />
            </>
          )}
        </>
      )}
      <BaseModal
        title={t('batch_registration:errorList')}
        openModal={!!detailErrors?.errorDataRegisterProjects?.length}
        onCancel={() => {
          setDetailErrors(DEFAULT_FILE_ERROR_RES);
        }}
        contentElement={
          <div className='max-h-[374px] overflow-auto'>
            {renderErrorUI(detailErrors?.errorDataRegisterProjects, detailErrors?.errorDataRegisterProjects.length)}
          </div>
        }
      />
    </>
  );
};
