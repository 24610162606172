import { Col, Row } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { useEffect, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BaseButton } from '@/components/base-button/BaseButton';
import { FormInputDate } from '@/components/form-input-date/FormInputDate';
import { FormSelect } from '@/components/form-select/FormSelect';

import useTargetAudience from '@/hooks/useTargetAudience';

import { Ii18n } from '@/utils/interfaces/i18n';

import { TYPE_CONFIRM } from '../constants';

interface IFilterProps {
  onSubmit: (values: FieldValues) => void;
  onReset?: () => void;
  defaultValues?: any;
}

const Filter = ({ onSubmit, onReset, defaultValues }: IFilterProps) => {
  const form = useForm({ defaultValues });
  const { targetAudienceOptions } = useTargetAudience();
  const [confirmationCheckOptions, setConfirmationCheckOptions] = useState<DefaultOptionType[]>([]);
  const { t }: Ii18n = useTranslation();

  const getDataConfirmationCheck = async () => {
    let options = [
      {
        label: String(t('announcement:list:filter:confirmation_check:unconfirmed')),
        value: TYPE_CONFIRM.unConfirm
      },
      {
        label: String(t('announcement:list:filter:confirmation_check:confirmed')),
        value: TYPE_CONFIRM.confirm
      }
    ];
    setConfirmationCheckOptions(options);
  };

  useEffect(() => {
    getDataConfirmationCheck();
  }, []);

  const handleReset = () => {
    form.reset({
      targetAudience: null,
      confirmationCheck: null,
      publishedDateFrom: null,
      publishedDateTo: null
    });
  };

  const { targetAudience, confirmationCheck, publishedDateFrom, publishedDateTo } = form.watch();
  const disableResetBtn = () => {
    return !targetAudience && !confirmationCheck && !publishedDateFrom && !publishedDateTo;
  };

  return (
    <FormProvider {...form}>
      <div className='p-4 w-[620px]'>
        <h3 className='text-xl font-semibold'>{t('project:list:filter_btn_label')}</h3>
        <div className='item mb-2 justify-space-between'>
          <div className='w-[282px]'>
            <FormSelect
              mode='multiple'
              control={form.control}
              name='targetAudience'
              label={t('announcement:list:filter:target_audience:label')}
              placeholder={t('placeholder:select', { field: t('announcement:list:filter:target_audience:label') })}
              options={targetAudienceOptions}
            />
          </div>
          <div className='w-[282px]'>
            <FormSelect
              control={form.control}
              name='confirmationCheck'
              label={t('announcement:list:filter:confirmation_check:label')}
              options={confirmationCheckOptions}
              placeholder={String(t('placeholder:select', { field: t('announcement:list:filter:confirmation_check:label') }))}
            />
          </div>
        </div>
        <div className='row mb-8'>
          <Row gutter={16}>
            <Col span={12}>
              <FormInputDate
                name='publishedDateFrom'
                labelTx='announcement:list:filter:send_date'
                placeholder={t('placeholder:date')}
                disabledDate={(d) => {
                  return form.getValues('publishedDateTo') && d.startOf('day').isAfter(form.getValues('publishedDateTo'));
                }}
              />
            </Col>
            <Col span={12}>
              <FormInputDate
                name='publishedDateTo'
                label='&nbsp;'
                placeholder={t('placeholder:date')}
                disabledDate={(d) => {
                  return form.getValues('publishedDateFrom') && d.endOf('day').isBefore(form.getValues('publishedDateFrom'));
                }}
              />
            </Col>
          </Row>
        </div>
        <div className='flex justify-end space-x-2'>
          <div className='w-[120px]'>
            <BaseButton onClick={handleReset} type='tertiary' size='medium' disabled={disableResetBtn()}>
              {t('project:filter:btn_reset')}
            </BaseButton>
          </div>
          <div className='w-[120px]'>
            <BaseButton onClick={form.handleSubmit(onSubmit)} type='primary' size='medium'>
              <span>{t('project:filter:btn_aply')} </span>
            </BaseButton>
          </div>
        </div>
      </div>
    </FormProvider>
  );
};

export default Filter;
