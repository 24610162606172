import { yupResolver } from '@hookform/resolvers/yup';
import { Popover } from 'antd';
import { t } from 'i18next';
import { cloneDeep } from 'lodash';
import React, { ReactNode, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useAppSelector } from '../../hooks';
import useFilter from '../../hooks/useFilter';
import { DEFAULT_VALUES_FILTER } from '../../utils/constants/AppConstants';
import { IFilterForm } from '../../utils/interfaces/form';
import { ICategory, ICountry } from '../../utils/interfaces/masterdata';
import { BaseFilterSecurity } from '../../utils/interfaces/security';
import { BaseButton } from '../base-button/BaseButton';
import SearchInput from '../search-input/SearchInput';
import DropdownIcon from './../../assets/icons/base-filter/filter-suffix-icon.svg';
import FilteredItems from './FilteredItems';

import './BaseFilter.scss';

export interface IBaseFilterLocaleConfig {
  input_search_placeholder?: string;
  dropdown_button_label?: string;
  clear_all_filter_items?: string;
  reset_button_label?: string;
  aply_button_label?: string;
}

export interface ISecondFilter {
  content: () => ReactNode;
}

export interface IBaseFilterProps {
  baseFilterSecurityProps?: BaseFilterSecurity;
  locale?: IBaseFilterLocaleConfig;
  className?: string;
  options?: { [key: string]: any };
  showFilterNumber?: boolean;
  searchKeyword?: string;
  searchMaxLength?: number;
  isShowFilter?: boolean;
  isShowSearch?: boolean;
  inputSearchSecurityProps?: boolean;
  buttonFilterSecurityProps?: boolean;
  defaultValues?: IFilterForm;
  validateForm?: any;
  secondFilter?: ISecondFilter;
  placeholder?: string;
  name?: string;
  onClearAllFilter?: () => void;
  onSearch?: (keyword: string) => void;
  onChange?: (value: string) => void;
  onClear?: (values: IFilterForm) => void;
  renderFormContent?: (formMethod: any) => JSX.Element | string;
  onApply?: (values?: IFilterForm) => void;
}

// Func return available form values
export const _getAvailableFormValues = (values: IFilterForm) => {
  const items = values || null;
  if (!items) {
    return;
  }
  Object.keys(items).forEach((k: string) => {
    const currentValue = items[k];
    if (!currentValue?.value || (currentValue && currentValue.value && Array.isArray(currentValue.value) && currentValue.value.length === 0)) {
      delete items[k];
    }
  });
  return items;
};

/**
 * @deprecated This component will be remove in the feature.
 *
 * Please use base-filter instead (src/components/base-filter)
 */
const BaseFilter = forwardRef((props: IBaseFilterProps, ref) => {
  const {
    baseFilterSecurityProps = {
      btnCreateViewable: true,
      btcCreateClickable: true,
      dropDownFilterViewable: true,
      dropDownFilterClickable: true,
      inputTemplateNameFilterViewable: true,
      inputTemplateNameFilterEditable: true,
      dropDownCountryNameFilterViewable: true,
      dropDownCountryNameFilterSelectable: true,
      dropDownCategoryFilterViewable: true,
      dropDownCategoryFilterSelectable: true,
      dropDownPatternFilterViewable: true,
      dropDownPatternFilterSelectable: true,
      dropDownFormFilterViewable: true,
      dropDownFormFilterSelectable: true,
      inputTemplateIdFilterViewable: true,
      inputTemplateIdFilterEditable: true,
      createDateFilterViewable: true,
      createDateFilterEditable: true,
      btnResetFilterViewable: true,
      btnResetFilterClickable: true,
      btnApplyFilterViewable: true,
      btnApplyFilterClickable: true,
      inputSearchViewable: true,
      inputSearchEditable: true
    },
    locale,
    className,
    searchKeyword = '',
    searchMaxLength,
    inputSearchSecurityProps = false,
    isShowFilter = true,
    isShowSearch = true,
    defaultValues,
    validateForm,
    secondFilter,
    placeholder,
    name,
    onClearAllFilter,
    onSearch,
    onApply,
    renderFormContent
  } = props;

  const formMethod = useForm<IFilterForm>({
    mode: 'onBlur',
    shouldUnregister: false,
    resolver: validateForm ? yupResolver(validateForm) : undefined
  });

  const [filteredItems, setFilteredItems] = useState<IFilterForm | undefined>(undefined);
  const [disabledReset, setDisabledReset] = useState<boolean>(true);
  const [showFilterForm, setShowFilterForm] = useState<boolean>(false);
  const [valueInputSearch, setValueInputSearch] = useState<string>('');
  const [numberFiltered, setNumberFiltered] = useState<number>(0);
  const inputSearchRef = useRef<any>(null);
  const searchRef = useRef<any>(null);
  const headerPageRef = useRef<HTMLDivElement>(null);
  const [searchedValue, setSelectedValue] = useState<string>('');
  const [disabledFields, setDisabledFields] = useState<string[]>([]);
  const { setDataFilter } = useFilter();
  const countries = useAppSelector((state) => state.global.countries);
  const [filterDraft, setFilterDraft] = useState<any>({});
  const [atleastFilterShowReset, setAtleastFilterShowReset] = useState<number>(0);

  // available items
  const availableFilteredItems = useMemo(() => {
    return _getAvailableFormValues({ ...filteredItems });
  }, [filteredItems]);

  // Show button reset filter
  const showButtonResetFilter = useMemo(() => {
    const filteredKeys = Object.keys(availableFilteredItems || {});
    const sortDisabledFields = cloneDeep(disabledFields).sort();
    const sortFilteredKeys = cloneDeep(filteredKeys).sort();
    return JSON.stringify(sortDisabledFields) !== JSON.stringify(sortFilteredKeys);
  }, [availableFilteredItems, disabledFields]);

  // Func clear data field
  const clearField = (key: string) => {
    const defaultValuesClone = name ? cloneDeep(DEFAULT_VALUES_FILTER[name || '']) : cloneDeep(defaultValues);
    defaultValuesClone && formMethod.setValue(key, defaultValuesClone[key]);
  };

  // Func remove item
  const _handleRemoveItem = (key: string) => {
    const defaultValuesClone = name ? cloneDeep(DEFAULT_VALUES_FILTER[name || '']) : cloneDeep(defaultValues);
    const newForm = cloneDeep(filteredItems) || {};
    if (key === 'countryId' && newForm.hasOwnProperty('categoryId')) {
      delete newForm['categoryId'];
      defaultValuesClone && formMethod.setValue('categoryId', defaultValuesClone['categoryId']);
    }
    delete newForm[key];
    setFilteredItems(newForm);
    defaultValuesClone && formMethod.setValue(key, defaultValuesClone[key]);
    const availableItems = newForm && _getAvailableFormValues(newForm);
    if (name) {
      setDataFilter(name, availableItems);
    }
    onApply && onApply(availableItems);
  };

  // Func remove item from array filter
  const _handleRemoveArrayItem = (key: string, index: number): void => {
    try {
      const newForm = cloneDeep(filteredItems) || {};
      const label: string[] = newForm[key]?.label as string[];
      const value = newForm[key]?.value as string[];
      if (key === 'assigneeDestinationCountryId' && newForm.hasOwnProperty('categoryId')) {
        const countryIdDelete = value[index];
        const country = countries.find((c: ICountry) => c.id === countryIdDelete);
        const categories = country?.categories || [];
        if (categories.map((c: ICategory) => c.id).includes((newForm?.categoryId?.value as string) || '')) {
          delete newForm['categoryId'];
          defaultValues && formMethod.setValue('categoryId', defaultValues['categoryId']);
        }
      }
      label?.splice(index, 1);
      value.splice(index, 1);
      if (!newForm[key].value || (value && value.length === 0)) {
        _handleRemoveItem(key);
      } else {
        setFilteredItems(newForm);
        formMethod.setValue(key, newForm[key]);
        if (name) {
          setDataFilter(name, _getAvailableFormValues(newForm));
        }
        onApply && onApply(_getAvailableFormValues(newForm));
      }
    } catch (error) {}
  };
  // Func remove all filters
  const _handleClearAll = (type: string = '') => {
    const defaultValuesClone = cloneDeep(initValues || defaultValues) || {};
    const filteredValuesClone = cloneDeep(filteredItems);
    const filteredValuesCloneKeys = Object.keys({ ...(filteredValuesClone || {}) });
    for (const key of filteredValuesCloneKeys) {
      if (!disabledFields.includes(key)) {
        if (filteredValuesClone) {
          filteredValuesClone[key].value = defaultValuesClone[key].value;
        }
      }
    }
    for (let index = 0; index < disabledFields.length; index++) {
      const element = disabledFields[index];
      const filteredEle = formMethod.getValues(element);
      defaultValuesClone[element] = filteredEle;
    }

    formMethod.reset(defaultValuesClone);

    setDisabledReset(true);
    if (type === 'apply') {
      const availableItems = filteredValuesClone && _getAvailableFormValues(filteredValuesClone);
      setFilteredItems(filteredValuesClone);
      if (name) {
        setDataFilter(name, availableItems);
      }
      onApply && onApply(availableItems);
    }
    onClearAllFilter && onClearAllFilter();
  };
  // Func apply filter
  const _handleAply = (values: IFilterForm) => {
    searchRef?.current?.setInputValue(searchedValue);
    const filteredItems = _getAvailableFormValues(values);
    if (name) {
      setDataFilter(name, filteredItems);
    }
    setFilteredItems(filteredItems);
    setShowFilterForm(false);
    onApply && onApply(_getAvailableFormValues(values));
  };

  const onScroll = (e: any) => {
    setShowFilterForm(false);
  };

  // Func render filter
  const renderFilterContent = () => {
    return (
      <FormProvider {...formMethod}>
        <div className={className}>
          {renderFormContent && renderFormContent(formMethod)}
          <div className='row !justify-end filter-footer'>
            <div>
              {baseFilterSecurityProps.btnResetFilterViewable && (
                <BaseButton
                  disabled={
                    !baseFilterSecurityProps.btnResetFilterClickable || disabledReset || Object.keys(filterDraft)?.length <= atleastFilterShowReset
                  }
                  onClick={() => !disabledReset && _handleClearAll()}
                  type='tertiary'
                  size='medium'
                  className='min-w-[120px]'
                >
                  {locale?.reset_button_label || t('project:filter:btn_reset')}
                </BaseButton>
              )}
            </div>
            <div>
              {baseFilterSecurityProps.btnApplyFilterViewable && (
                <BaseButton
                  disabled={!baseFilterSecurityProps.btnApplyFilterClickable}
                  onClick={() => {
                    formMethod.handleSubmit(_handleAply)();
                  }}
                  type='primary'
                  size='medium'
                  className='min-w-[120px]'
                >
                  <span>{locale?.aply_button_label || t('project:filter:btn_aply')} </span>
                </BaseButton>
              )}
            </div>
          </div>
        </div>
      </FormProvider>
    );
  };

  // init values
  const initValues = useMemo(() => {
    return name ? DEFAULT_VALUES_FILTER[name] : null;
  }, [name]);

  // Check disable button reset
  useEffect(() => {
    const subscription = formMethod.watch((value: any, { name, type }: any) => {
      const emptyValues = Object.values(value).every((v: any) => v && (!v.value || v.value.length === 0));
      const filterValue = Object.values(value)?.filter((v: any) => v && v?.value && v?.label?.length);
      setFilterDraft(filterValue);
      setDisabledReset(emptyValues);
    });
    return () => subscription.unsubscribe();
  }, [formMethod.watch]);

  useEffect(() => {
    searchRef?.current?.setInputValue(searchKeyword);
    setValueInputSearch(searchKeyword);
  }, [searchKeyword]);

  // Set data form default
  useEffect(() => {
    if (showFilterForm) {
      const defaultValuesClone = initValues ? cloneDeep(initValues) : cloneDeep(defaultValues);
      if (filteredItems && Object.keys(filteredItems).length > 0) {
        formMethod.reset({
          ...defaultValuesClone,
          ...filteredItems
        });
      } else if (defaultValuesClone) {
        formMethod.reset(defaultValuesClone);
      }
    }
    if (filteredItems) {
      let filteredValues: string[] = [];
      for (let index = 0; index < Object.values(filteredItems).length; index++) {
        const element = Object.values(filteredItems)[index];
        if (Array.isArray(element.value)) {
          filteredValues = [...filteredValues, ...element.value];
        } else {
          if (element.value) {
            filteredValues = [...filteredValues, ...[element.value]];
          }
        }
      }
      setNumberFiltered(filteredValues.length);
    } else {
      setNumberFiltered(0);
    }
  }, [showFilterForm, filteredItems]);

  useEffect(() => {
    setFilteredItems(defaultValues || DEFAULT_VALUES_FILTER[name || '']);
  }, [defaultValues]);

  useEffect(() => {
    const elementsScroll = document.querySelectorAll('.container-scroll');
    for (let index = 0; index < elementsScroll.length; index++) {
      const elementScroll = elementsScroll[index];
      elementScroll.removeEventListener('scroll', onScroll);
      elementScroll.addEventListener('scroll', onScroll, { passive: true });
    }

    return () => {
      for (let index = 0; index < elementsScroll.length; index++) {
        const elementScroll = elementsScroll[index];
        elementScroll?.removeEventListener('scroll', onScroll);
      }
    };
  }, []);

  useImperativeHandle(ref, () => ({
    setFieldValue({ field, value, values }: { field: string; value: any; values: any }) {
      if (value) {
        const filteredItemsClone = values || cloneDeep(filteredItems) || {};
        filteredItemsClone[field] = value;
        formMethod.setValue(field, value);
        const availableItems = _getAvailableFormValues(filteredItemsClone);
        if (name) {
          setDataFilter(name, availableItems);
        }
        setFilteredItems(filteredItemsClone);
      }
    },
    setFilterTag: (params: any, countNumber: number) => {
      setAtleastFilterShowReset(countNumber);
      setFilteredItems(params);
    },
    clearField(key: string) {
      clearField(key);
    },
    clearAndSetField(key: string) {
      const defaultValuesClone = name ? cloneDeep(DEFAULT_VALUES_FILTER[name || '']) : cloneDeep(defaultValues);
      const filteredItemsClone = { ...filteredItems };
      delete filteredItemsClone[key];
      setFilteredItems(filteredItemsClone);
      defaultValuesClone && formMethod.setValue(key, defaultValuesClone[key]);
    },
    clearAllFilter() {
      const defaultValuesClone = name ? cloneDeep(DEFAULT_VALUES_FILTER[name || '']) : cloneDeep(defaultValues);
      formMethod.reset(defaultValuesClone);
      setFilteredItems(undefined);
      setDisabledReset(true);
      searchRef.current && searchRef.current.resetInput();
    },
    setValueInputSearch(newValue: string) {
      setValueInputSearch(newValue);
    },
    getFilteredItems: () => {
      return filteredItems;
    },
    setDisabledFields: (disabledFields: string[]) => {
      setDisabledFields(disabledFields);
    },
    searchRef,
    filteredItems,
    searchKeyword: valueInputSearch,
    innerHeight: headerPageRef.current?.clientHeight
  }));

  return (
    <div className='base-filter' ref={headerPageRef}>
      <div className='filter-data'>
        <div>
          {isShowSearch && (
            <SearchInput
              ref={searchRef}
              refValue={inputSearchRef}
              handleSearch={(value: string) => {
                setSelectedValue(value);
                onSearch && onSearch(value);
              }}
              placeholder={placeholder || locale?.input_search_placeholder || (t('template:filter:input_search_placeholder') ?? '')}
              disabled={!inputSearchSecurityProps}
              maxLength={searchMaxLength}
            />
          )}
          <FilteredItems
            isShowSearch={isShowSearch}
            showButtonResetFilter={showButtonResetFilter}
            disabledFields={disabledFields}
            filteredItems={filteredItems}
            locale={locale}
            _handleRemoveArrayItem={_handleRemoveArrayItem}
            _handleRemoveItem={_handleRemoveItem}
            _handleClearAll={_handleClearAll}
          />
        </div>
        <div className='filter-group flex gap-[16px] mb-[7px]'>
          {secondFilter && secondFilter.content()}
          {baseFilterSecurityProps?.dropDownFilterViewable && isShowFilter && (
            <Popover
              open={showFilterForm}
              onOpenChange={setShowFilterForm}
              placement='bottomRight'
              arrow={false}
              overlayInnerStyle={{ padding: '0' }}
              content={() => renderFilterContent()}
              trigger='click'
            >
              <div className='dropdown'>
                <BaseButton onClick={() => setShowFilterForm(!showFilterForm)} type='secondary'>
                  <div className='flex w-[100%] gap-x-2'>
                    <span className='w-[100%] text-center'>{locale?.dropdown_button_label || t('template:filter:filter_button_label')}</span>
                    <div className='flex flex-col justify-center'>
                      <DropdownIcon className='icon-down' />
                    </div>
                  </div>
                  {numberFiltered > 0 && <span className='number-items'>{numberFiltered}</span>}
                </BaseButton>
              </div>
            </Popover>
          )}
        </div>
      </div>
    </div>
  );
});

export default BaseFilter;
