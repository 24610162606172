import { v4 as uuid } from 'uuid';

export const LOCAL_REPRESENTATIVE = {
  NEEDED: 'needed',
  NOT_NEEDED: 'not_needed',
  UNKNOWN: 'unknown'
} as const;
export type LOCAL_REPRESENTATIVE = (typeof LOCAL_REPRESENTATIVE)[keyof typeof LOCAL_REPRESENTATIVE];

export const MAX_FIELD_PERSON_NUMBER = 5;
export const NEED_SUPPORTER_PATH = 'needSupporter';
export const LOCAL_REPRESENTATIVE_PATH = 'projectLocalSupporters';
export const LOCAL_REPRESENTATIVE_FIELDS = ['name', 'email', 'address', 'language'];
export const LOCAL_REPRESENTATIVE_NOT_LANGUAGE_FIELDS = ['name', 'email', 'address'];
export const DEFAULT_LOCAL_REPRESENTATIVE = {
  formId: uuid(),
  name: '',
  email: '',
  address: '',
  language: null
};
