import { useEffect } from 'react';

const defaultSelectors = ['.ant-select', '.ant-someone'];

const useTabIndexAppend = (selectors: string[] = [], ref?: any) => {
  const cns = [...defaultSelectors, ...selectors];
  useEffect(() => {
    const container = ref?.current ?? document;
    const elements = container.querySelectorAll(cns.join());
    elements?.forEach((e: any) => {
      const isDisabled = e?.className.includes('disabled');
      if (isDisabled && e.getAttribute('tabindex') !== '-1') e.setAttribute('tabindex', isDisabled ? '-1' : '0');
    });
  });
};

export default useTabIndexAppend;
