import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { setAlertNotification } from '@/redux/globalReducer';
import { AppDispatch } from '@redux/store';

import { IPostalCode } from '@/utils/interfaces';

/**
 * Fetch postal code data from postcode.teraren.com
 * @link https://postcode.teraren.com
 */
const endpoint = 'https://postcode.teraren.com/postcodes';
const usePostalCode = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [data, setData] = useState<IPostalCode | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const searching = async (code: string) => {
    if (!code) {
      return;
    }
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${endpoint}/${code.replaceAll('-', '')}.json`);
      if (!response.ok) throw new Error('Failed to fetch');
      const json = await response.json();
      setData(json);
    } catch (error) {
      setError(error as Error);
      dispatch(
        setAlertNotification({
          show: true,
          type: 'error',
          message: t('common:MSG_C_031')
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, searching };
};

export default usePostalCode;
