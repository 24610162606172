import { TFunction } from 'i18next';

import { Ii18n } from '@/utils/interfaces/i18n';

export type AnnouncementItem = {
  id: string;
  title: string;
  content: string;
  createdDate?: string;
  updatedDate?: string;
  version?: number;
  status: AnnouncementStatus;
  users: AccouncementPic[];
  attachments: AttachmentFile[];
  userReaded: boolean;
};

/*
  P- Publish
  W- Waiting
  S- Save
*/

export const ANNOUNCEMENT_STATUS = {
  PUBLISH: 'P',
  WAITING: 'W',
  SAVE: 'S'
} as const;

export type AnnouncementStatus = (typeof ANNOUNCEMENT_STATUS)[keyof typeof ANNOUNCEMENT_STATUS];

/*
  A- Approval
  R- Reject
  W- Waiting

  // file status upload local
  DONE: 'done',
  UPLOADING: 'uploading',
  ERROR: 'error'
*/
export const ATTACHMENT_STATUS = {
  APPROVAL: 'A',
  REJECT: 'R',
  WAITING: 'W',
  DONE: 'done',
  UPLOADING: 'uploading',
  ERROR: 'error'
} as const;

export type AttachmentStatus = (typeof ATTACHMENT_STATUS)[keyof typeof ATTACHMENT_STATUS];

export type LabelProps = {
  title: string;
  total?: number;
  questions?: number;
  extra?: React.ReactNode;
};

export type AccouncementPic = {
  id?: string;
  userId?: string;
  userName?: string;
  userEmail: string;
  userStatus?: boolean;
  confirmed?: boolean;
  confirmedDate?: string;
};

export type AttachmentFile = {
  id?: string;
  name: string;
  blobPath: string;
  contentType: string;
  fileSize: number;
  displayOrder: number;
  createdName?: string | number;
  createdBy?: string;
  requestApproval: boolean;
  createdDate?: string;
  updatedDate?: string;
  status: AttachmentStatus;
  msgErr?: string;
  rejectReason?: string;
  deleted?: boolean;
  updatedStatus?: boolean;
  statusAccountUpload?: boolean;
};

export type DeleteDataReq = { ids: DeleteItem[] };

export type DeleteItem = {
  id: string;
  version: number;
};

export type RenderAttachment = {
  attachment: AttachmentFile;
  i: number;
  t: TFunction | Ii18n['t'];
  showInfor?: boolean;
};

export type AttachmentFileType = {
  id: string;
  name: string;
  requestApproval: boolean;
};
