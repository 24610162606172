import { ExternalQuestionList } from '@pages/project-management/project-questionaire/components/external-questions';

const ExternalQuestionListPage = () => {
  return (
    <div className='px-[20px] py-[16px]'>
      <ExternalQuestionList />
    </div>
  );
};

export default ExternalQuestionListPage;
