export const FILE_GROUP_KEY = {
  ANNOUNCEMENT_ATTACHMENT: 'announcement_attachments',
  PROJECT_ATTACHMENT: 'project_attachments',
  INQUIRY_ATTACHMENT: 'inquiry_attachments',
  PROCESS_A_1: 'process_a_1',
  PROCESS_A_2: 'process_a_2',
  PROCESS_A_3: 'process_a_3',
  PROCESS_A_4: 'process_a_4',
  PROCESS_A_5: 'process_a_5',
  PROCESS_B_1: 'process_b_1',
  PROCESS_B_2: 'process_b_2',
  PROCESS_B_3: 'process_b_3',
  PROCESS_B_4: 'process_b_4',
  PROCESS_B_5: 'process_b_5',
  PROCESS_C_1: 'process_c_1',
  PROCESS_C_2: 'process_c_2',
  PROCESS_C_3: 'process_c_3',
  PROCESS_D_1: 'process_d_1',
  PROCESS_D_2: 'process_d_2',
  PROCESS_D_3: 'process_d_3',
  PROCESS_E_1: 'process_e_1',
  PROCESS_E_2: 'process_e_2',
  PROCESS_E_3: 'process_e_3'
};
