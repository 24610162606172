import { v4 as uuid } from 'uuid';

export const MAX_FIELD_PERSON_NUMBER = 5;
export const PROJECT_FAMILY_PATH = 'projectFamilies';

export const FAMILY_MEMBER_FIELDS = [
  'lastName',
  'firstName',
  'lastNameRomaji',
  'firstNameRomaji',
  'primaryNational',
  'secondaryNational',
  'dateOfBirth',
  'gender',
  'attendanceTime'
];

export const DEFAULT_FAMILY_MEMBER = {
  formId: uuid(),
  lastName: '',
  firstName: '',
  lastNameRomaji: '',
  firstNameRomaji: '',
  primaryNational: null,
  primaryNationalName: null,
  secondaryNational: null,
  secondaryNationalName: null,
  dateOfBirth: '',
  gender: null,
  attendanceTime: ''
};

export const REQUIRED_FAMILY_FIELDS = [
  'lastName',
  'firstName',
  'lastNameRomaji',
  'firstNameRomaji',
  'primaryNational',
  'dateOfBirth',
  'gender',
  'attendanceTime'
];
