import { useAppSelector } from '@/hooks';
import { Col, Row } from 'antd';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BaseCollapse } from '@/components/base-collapse/BaseCollapse';
import { FormSelect } from '@/components/form-select/FormSelect';

interface ITravelTypeProps {
  travelTypePath: string;
}
const TravelTypeComponent = (props: ITravelTypeProps) => {
  const { travelTypePath } = props;
  const travelTypes = useAppSelector((state) => state.projectV2.travelTypes.data);
  const form = useFormContext();
  const { t } = useTranslation();

  const watchTravelType = form.watch(travelTypePath);

  return (
    <BaseCollapse id='travel_type' title={t('basic_information:travel_type')} total={1} questions={watchTravelType ? 1 : 0}>
      <Row gutter={16}>
        <Col span={12}>
          <FormSelect
            name={travelTypePath}
            label={String(t('basic_information:travel_type'))}
            placeholder={String(
              t('placeholder:select', {
                field: t('basic_information:travel_type')
              })
            )}
            options={travelTypes}
          />
        </Col>
      </Row>
    </BaseCollapse>
  );
};

export default TravelTypeComponent;
