import * as yup from 'yup';

import { QUERY_OPERATION, QUERY_TYPE } from '@/utils/constants';
import { convertFullWidthToHalfWidth } from '@/utils/helpers/globalHelper';
import { stringifyObjectValidateYup } from '@/utils/method';
import { searchCompanies } from '@/utils/services/master';

import { IAddEditForm } from '../models';
import { CompanyQueryFactory } from '../utils';

export const validateDuplicate = async (formValue: IAddEditForm): Promise<boolean> => {
  if (!formValue.name) return false;
  const factory = new CompanyQueryFactory();
  factory.and({
    fieldTitle: 'nameSearch.ToLower()',
    queryType: QUERY_TYPE.TEXT,
    queryValue: convertFullWidthToHalfWidth(formValue.name).toLowerCase(),
    operation: QUERY_OPERATION.EQUAL
  });

  const body = {
    filter: factory.toFilterString()
  };

  const result = await searchCompanies(body);
  const listDuplicate = result?.data?.data || [];
  return !listDuplicate.some((item: any) => item.id !== formValue.id);
};

export const schemaAddEdit = yup.object().shape({
  name: yup
    .string()
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: 'master_data:company:add_edit:company_field_label' || '' }
      })
    )
    .max(
      128,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_028',
        optionsTx: { field: 'master_data:company:add_edit:company_field_label' || '', maxLength: 128 }
      })
    )
  // TODO: remove it after confirm again
  // .debounceValidate(
  //   validateDuplicate,
  //   stringifyObjectValidateYup({
  //     keyT: 'common:MSG_C_019',
  //     optionsTx: { field: 'master_data:company:add_edit:company_field_label' || '' }
  //   })
  // )
});
