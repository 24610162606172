import { useEffect, useRef } from 'react';

const usePrevious = <T>(value: T, triggered: boolean = true) => {
  const ref = useRef<T>();

  useEffect(() => {
    if (!triggered) return;
    ref.current = value;
  }, [value, triggered]);

  return ref.current;
};

export default usePrevious;
