import { AxiosRequestConfig } from 'axios';
import saveAs from 'file-saver';
import { t } from 'i18next';

import { setAlertNotification } from '@/redux/globalReducer';
import { store } from '@/redux/store';

import { AXIOS_TIMEOUT_UPLOAD } from '../constants/AppConstants';
import { HTTP_STATUS_CODE } from '../constants/Http';
import { IFile } from '../interfaces/Project';
import { downloadMultipleFiles, downloadSingleFileWithType, getTokenDownLoad } from '../services/FileService';

export const downloadMultiFileHelper = async (fields: any, name: string, keys: any, keyField?: string) => {
  const documents: any[] = fields.filter((f: any) => keys.includes(keyField ? f[keyField] : f.key) && f?.defaultMedia?.length);
  if (!documents.length) {
    return;
  }
  let fileName: string = ``;
  if (documents.length === 1) {
    const documentName = documents[0]?.typeName;
    fileName = documentName.split('.')[0];
  } else if (documents.length > 1) {
    fileName = name;
  }
  const { data: token } = await getTokenDownLoad();
  let documentsMapper: any[] = [];
  for (let index = 0; index < documents.length; index++) {
    const d = documents[index];
    if (!d?.id.includes('new-document')) {
      documentsMapper = [
        ...documentsMapper,
        {
          documentName: d?.typeName || '',
          filePaths: d?.defaultMedia?.map((media: any) => media.blobPath) || []
        }
      ];
    }
  }
  const { data } = await downloadMultipleFiles({
    token,
    documents: documentsMapper || []
  });
  saveAs(data, `${fileName}.zip`);
};

export const downloadMultiFileHelperInView = async (fields: any, name: string, keys: any) => {
  const documents: any[] = fields.filter((f: any) => keys.includes(f?.id) && f.media?.length > 0);
  let fileName: string = ``;
  if (documents.length === 1) {
    const documentName = documents[0]?.typeName;
    fileName = documentName.split('.')[0];
  } else if (documents.length > 1) {
    fileName = name;
  }
  const { data: token } = await getTokenDownLoad();
  let documentsMapper: any[] = [];
  for (let index = 0; index < documents.length; index++) {
    const d = documents[index];
    if (!d?.id.includes('new-document')) {
      documentsMapper = [
        ...documentsMapper,
        {
          documentName: d?.typeName || '',
          filePaths: d?.media?.map((e: any) => e.blobPath) || []
        }
      ];
    }
  }
  const { data } = await downloadMultipleFiles({
    token,
    documents: documentsMapper || []
  });
  saveAs(data, `${fileName}.zip`);
};

export const downloadFileFromURI = (uri: string, name: string) => {
  const link = document.createElement('a');
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadSingleFileFromURI = (data: Blob, name: string, type?: string) => {
  const options = type ? { type } : {};
  const url = window.URL.createObjectURL(new Blob([data], options));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${name}`);
  document.body.appendChild(link);
  link.click();
};

export const downloadMultiFileFromURI = async (documents: any, name: string) => {
  const { data: token } = await getTokenDownLoad();
  const { data } = await downloadMultipleFiles({
    token,
    documents:
      documents?.map((document: any) => {
        return {
          documentName: document?.documentName,
          filePaths: document?.media?.map((file: any) => file?.blobPath) || []
        };
      }) || []
  });
  saveAs(data, `${name}.zip`);
};

export const formatBytes = (bytes: number, decimals: number = 2) => {
  if (!+bytes) return 0;
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;

  return parseFloat((bytes / Math.pow(k, 2)).toFixed(dm));
};

export const formatBytesBinary = (bytes: number) => {
  if (!+bytes) return 0;
  const k = 1024;
  return bytes / Math.pow(k, 2);
};

export const detectExtensionFile = (filename: string): string => {
  return filename.split('.').pop()!;
};

export const splitAccept = (accept: string | undefined): string[] => {
  return accept?.split(', ') || [];
};

export const splitFileName = (fileName: string): string | undefined => {
  return fileName.split('.').pop()?.toLowerCase();
};

export const validateFileType = (file: IFile, isOnlyPdf = false) => {
  const extension = detectExtensionFile(file.name);

  if (isOnlyPdf) {
    return extension && (ALLOW_PDF.includes(extension) || ALLOW_PDF.toLocaleUpperCase().includes(extension));
  }

  return extension && (ALLOW_EXTENSION.includes(extension) || ALLOW_EXTENSION.toLocaleUpperCase().includes(extension));
};

export const ALLOW_EXTENSION = '.txt, .doc, .docx, .xls, .xlsx, .pdf, .ppt, .pptx, .png, .jpg, .jpeg';
export const ALLOW_PDF = '.pdf';
export const CONFIG_HEADER_UPLOAD: AxiosRequestConfig = {
  headers: { 'Content-Type': 'multipart/form-data' }
};
export const CONFIG_HEADER_DOWNLOAD: AxiosRequestConfig = {
  responseType: 'blob',
  timeout: AXIOS_TIMEOUT_UPLOAD
};

export const handleDownloadSingleFile = async (blobPath: string, fileName: string) => {
  try {
    const { data: token } = await getTokenDownLoad();
    if (token && blobPath) {
      const { data, headers } = await downloadSingleFileWithType(blobPath, token, CONFIG_HEADER_DOWNLOAD);
      downloadSingleFileFromURI(data, decodeURI(fileName), headers['content-type']);
    }
  } catch (error) {
    if (error?.response?.status === HTTP_STATUS_CODE.NOT_FOUND) {
      store.dispatch(
        setAlertNotification({
          show: true,
          type: 'error',
          position: 'top',
          message: t('common:MSG_C_015')
        })
      );
    }
  }
};

export const downloadMultiFiles = async (files: any, name: string) => {
  const { data: token } = await getTokenDownLoad();
  const filePaths = files.map((file: any) => file?.blobPath);
  try {
    const { data } = await downloadMultipleFiles({
      token,
      documents: [
        {
          documentName: name,
          filePaths
        }
      ]
    });
    saveAs(data, `${name}.zip`);
  } catch (error) {
    if (error?.response?.status === HTTP_STATUS_CODE.NOT_FOUND) {
      store.dispatch(
        setAlertNotification({
          show: true,
          type: 'error',
          position: 'top',
          message: t('common:MSG_C_015')
        })
      );
    }
  }
};

export const readFileContent = (file: File): Promise<BlobPart> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => (reader.result ? resolve(reader.result) : null);
    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file); // Read the file as ArrayBuffer to create a new File/Blob
  });
};

export const clonedFile = async (file: File) => {
  const fileContent = await readFileContent(file);
  if (!fileContent) return null;
  const clonedFile = new File([fileContent], file.name, { type: file.type });
  return clonedFile;
};
