import { useAppSelector } from '@/hooks';
import { Col, Row } from 'antd';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormSelect } from '@/components/form-select/FormSelect';

import CounterLabel from '../../../components/counter-label';
import { relativePath } from '../../../utils';

interface ITravelTypeProps {
  parentPath: string;
}

const TravelType = ({ parentPath }: ITravelTypeProps) => {
  const form = useFormContext();
  const travelTypes = useAppSelector((state) => state.projectV2.travelTypes.data);
  const PATHS = {
    TRAVEL_TYPE: relativePath('travelTypeId', parentPath)
  };
  const travelType = form.watch(PATHS.TRAVEL_TYPE);
  const { t } = useTranslation();

  const counters = () => {
    let current = 0;
    const total = 1;
    if (travelType) {
      current++;
    }
    return `${current}/${total}`;
  };

  return (
    <>
      <CounterLabel title={t('basic_information:travel_type')} count={counters()} />
      <Row gutter={16}>
        <Col span={12}>
          <FormSelect
            name={PATHS.TRAVEL_TYPE}
            label={String(t('basic_information:travel_type'))}
            placeholder={String(
              t('placeholder:select', {
                field: t('basic_information:travel_type')
              })
            )}
            options={travelTypes}
          />
        </Col>
      </Row>
    </>
  );
};

export default TravelType;
