import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Row, Space } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { GENDER, GO_WITH_FAMILY } from '@/pages/project-management/project-list/constants';

import { BaseButton } from '@/components/base-button/BaseButton';
import { BaseCollapse } from '@/components/base-collapse/BaseCollapse';
import { ConfirmCancelEditing } from '@/components/confirm-popup';
import { FormInput } from '@/components/form-input/FormInput';
import { FormSelect } from '@/components/form-select/FormSelect';

import { IAlertNotificationState, setAlertNotification } from '@/redux/globalReducer';
import { cleanupTravelTypes, fetchTravelTypes } from '@/redux/project-v2/projectReducer';

import useCompany from '@/hooks/useCompany';
import useEventListener from '@hooks/useEventListener';

import { WINDOW_EVENTS } from '@/utils/constants';
import { ERROR_CODE, TYPE } from '@/utils/constants/AppConstants';
import { PROJECT_BASIC_INFO_URL } from '@/utils/constants/RouteContants';
import { handleCommonError } from '@/utils/helpers/common';
import { formatDateTime } from '@/utils/helpers/globalHelper';
import { renderProjectCode } from '@/utils/helpers/project';
import { getProjectInfo, putProjectInternal } from '@/utils/services/ProjectApiService';

import { PROJECT_DEFAULT_VALUE } from '../../contants';
import { IFormMethodRegisterProject, IProjectFamily } from '../../models';
import { FormProject } from '../../validation/FormValidation';
import ApplicantRepresentativeComponent from '../ApplicantRepresentativeComponent';
import FamilyInformationComponent from '../FamilyInformationComponent';
import LocalRepresentativeComponent from '../LocalRepresentativeComponent';
import RequestDetailComponent from '../RequestDetailComponent';
import TrainingDestinationInformationComponent from '../TrainingDestinationInformationComponent';
import TravelTypeComponent from '../TravelTypeComponent';
import UploadFilesComponent from '../UploadFilesComponent';
import VisaInformationComponent from '../VisaInformationComponent';

import './EditBasicInformation.scss';

const EditBasicInformation = () => {
  const navigate = useNavigate();
  const { companyOptions } = useCompany();
  const [showLocalRepresentative, setShowLocalRepresentative] = useState<boolean>(false);
  const [disabledAssignee, setDisabledAssignee] = useState<boolean>(false);
  const [defaultValues, setDefaultValues] = useState<IFormMethodRegisterProject>(PROJECT_DEFAULT_VALUE);
  const projectApplicantRef = useRef<any>(null);
  const projectFamiliesRef = useRef<any>(null);
  const projectLocalSupportersRef = useRef<any>(null);
  const projectAssignmentInfoRef = useRef<any>(null);
  const projectAttachmentsRef = useRef<any>(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const isEdit = !!id;
  const formMethod = useForm<IFormMethodRegisterProject>({
    mode: 'all',
    resolver: yupResolver(FormProject),
    defaultValues: () => getDetailProject()
  });
  const { isDirty, isSubmitSuccessful, isSubmitting } = formMethod.formState;

  useEventListener(WINDOW_EVENTS.BEFORE_UNLOAD, (e) => {
    if (isDirty && !isSubmitSuccessful) {
      e.preventDefault();
      e.focus();
    }
  });

  useEffect(() => {
    dispatch(fetchTravelTypes() as any);
    return () => {
      dispatch(cleanupTravelTypes() as any);
    };
  }, []);

  const getDetailProject = async () => {
    if (!isEdit) return PROJECT_DEFAULT_VALUE;
    try {
      const { data } = await getProjectInfo(String(id));
      const {
        code,
        isPublished,
        applicantName,
        requestTypeId,
        supportTypeId,
        goWithFamily,
        projectLocalSupporters,
        note,
        version,
        statusId,
        templateId,
        travelTypeId
      } = data || {};
      const motPic = data.picId
        ? {
            value: data.picId,
            label: data.picName
          }
        : undefined;
      const applicantCompany = data.applicantCompanyId
        ? {
            value: data.applicantCompanyId,
            label: data.applicantCompanyName
          }
        : undefined;
      const projectApplicant = {
        ...data.projectApplicant,
        dateOfBirth: data?.projectApplicant?.dateOfBirth ? dayjs(data.projectApplicant.dateOfBirth).format('YYYY/MM/DD') : undefined,
        departureDate: data?.projectApplicant?.departureDate ? dayjs(data.projectApplicant.departureDate).format('YYYY/MM/DD') : undefined,
        gender: data?.projectApplicant?.gender ? GENDER.female : data?.projectApplicant?.gender === false ? GENDER.male : null
      };
      const projectFamilies = data.projectFamilies.map((item: any) => ({
        ...item,
        dateOfBirth: item.dateOfBirth ? dayjs(item.dateOfBirth).format('YYYY/MM/DD') : undefined,
        gender: item.gender ? GENDER.female : item.gender === false ? GENDER.male : null
      }));
      const visaCategory = data.visaCategoryId
        ? {
            value: data.visaCategoryId,
            label: data.visaCategoryName
          }
        : undefined;
      const projectStakeholders = data.projectStakeholders?.length ? data.projectStakeholders : [];
      const projectAttachments = data.projectAttachments.map((item: any) => ({
        ...item,
        status: 'done'
      }));
      const projectAssignmentInfo = data.projectAssignmentInfo;
      const detailValues = {
        code,
        isPublished,
        motPic,
        applicantName,
        applicantCompany,
        requestTypeId,
        supportTypeId,
        projectApplicant,
        goWithFamily: goWithFamily ? GO_WITH_FAMILY.YES : goWithFamily === false ? GO_WITH_FAMILY.NO : '',
        projectFamilies,
        projectAssignmentInfo,
        projectLocalSupporters,
        visaCategory,
        travelTypeId,
        note,
        projectAttachments,
        projectStakeholders,
        statusId,
        templateId,
        version
      };
      setDefaultValues(detailValues);
      return detailValues;
    } catch (error) {
      return PROJECT_DEFAULT_VALUE;
    }
  };

  const validFields = (fields: any[]) => {
    const watchFields = formMethod.watch(fields);
    const watchFieldsValid = watchFields.filter((field: any) => field);
    return watchFieldsValid.length;
  };

  const handleProjectException = (err: any, callback: (mess: IAlertNotificationState) => void) => {
    let errorMessage = t('common:MSG_C_014');
    const responseError = err?.request?.response;

    if (responseError) {
      const dataError = JSON.parse(responseError);

      switch (dataError?.errorCode) {
        case ERROR_CODE.VALIDATION:
          if (dataError?.fields[0]?.name === 'PicId') {
            errorMessage = t('common:MSG_C_015');
          }
          break;

        default:
          break;
      }
    }

    handleCommonError(err, errorMessage, callback);
  };

  const onError = async (err: any) => {
    let firstInValidKey: string | undefined = Object.keys(err)?.[0];
    const formCollapseMapping = {
      projectApplicant: projectApplicantRef,
      projectLocalSupporters: projectLocalSupportersRef,
      projectAssignmentInfo: projectAssignmentInfoRef,
      projectFamilies: projectFamiliesRef,
      projectAttachments: projectAttachmentsRef,
      note: projectAttachmentsRef
    };
    // Open all collapse invalid
    for (let key in err) {
      if (formCollapseMapping.hasOwnProperty(key) && key in formCollapseMapping) {
        const formRef = formCollapseMapping[key as keyof typeof formCollapseMapping];
        const idRef = formRef.current?.getIdElement();
        formRef.current?.setCollapse(idRef);
      }
    }
    // Scroll to first collapse invalid
    if (firstInValidKey !== undefined && formCollapseMapping.hasOwnProperty(firstInValidKey) && firstInValidKey in formCollapseMapping) {
      const formRef = formCollapseMapping[firstInValidKey as keyof typeof formCollapseMapping];
      const idRef = formRef.current?.getIdElement();
      const element = document.getElementById(`base-collapse-${idRef}`);
      element?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const determineGender = (gender: any) => {
    if (gender === GENDER.female) return true;
    if (gender === GENDER.male) return false;
    return null;
  };

  const getDateTime = (date?: string) => {
    return date ? formatDateTime(date) : '';
  };

  const convertToDeletedFamily = (member: IProjectFamily) => ({
    ...member,
    dateOfBirth: getDateTime(member.dateOfBirth),
    gender: determineGender(member?.gender),
    deleted: true
  });

  const formatProjectFamilies = (formData: IFormMethodRegisterProject) => {
    if (formData?.goWithFamily === GO_WITH_FAMILY.NO || !formData?.goWithFamily) {
      return defaultValues?.projectFamilies?.length ? defaultValues.projectFamilies.map(convertToDeletedFamily) : [];
    }

    let projectFamilies: IProjectFamily[] =
      formData?.projectFamilies?.map((item: IProjectFamily, index: number) => ({
        ...item,
        dateOfBirth: getDateTime(item.dateOfBirth),
        gender: determineGender(item?.gender),
        displayOrder: index
      })) || [];

    defaultValues?.projectFamilies?.forEach((item) => {
      if (!projectFamilies.find((i: IProjectFamily) => i.id === item.id) && item.id) {
        projectFamilies.push(convertToDeletedFamily(item));
      }
    });

    return projectFamilies;
  };

  const formatProjectLocalSupporters = (formData: any) => {
    if (!showLocalRepresentative) return [];

    let projectLocalSupporters =
      formData?.projectLocalSupporters?.map((item: any, index: number) => ({
        ...item,
        displayOrder: index,
        language: formData?.projectLocalSupporters[0]?.language || null,
        deleted: item?.deleted ?? false
      })) || [];

    defaultValues?.projectLocalSupporters?.forEach((item) => {
      if (!projectLocalSupporters.find((i: any) => i.id === item.id) && item.id) {
        projectLocalSupporters.push({ ...item, deleted: true });
      }
    });

    return projectLocalSupporters;
  };

  const formatProjectAttachments = (formData: any) => {
    let projectAttachments = formData?.projectAttachments
      ?.filter((file: any) => file.status === 'done')
      ?.map((file: any, index: number) => ({
        ...file,
        displayOrder: index,
        deleted: file?.deleted ?? false
      }));

    defaultValues?.projectAttachments?.forEach((item) => {
      if (!projectAttachments.find((i: any) => i.id === item.id) && item.id) {
        projectAttachments.push({ ...item, deleted: true });
      }
    });

    return projectAttachments;
  };

  const getProjectApplicant = (formData: IFormMethodRegisterProject) => ({
    ...formData?.projectApplicant,
    dateOfBirth: getDateTime(formData?.projectApplicant?.dateOfBirth),
    gender: determineGender(formData?.projectApplicant?.gender),
    departureDate: getDateTime(formData?.projectApplicant?.departureDate)
  });

  const getProjectAssignmentInfo = (formData: IFormMethodRegisterProject) => ({
    ...formData?.projectAssignmentInfo
  });

  const getPayloadRequest = (formData: IFormMethodRegisterProject, isDraft: boolean) => ({
    picId: formData?.motPic?.value,
    picName: formData?.motPic?.label,
    picEmail: formData?.motPic?.email,
    picStatus: formData?.motPic?.status,
    applicantName: formData?.applicantName,
    applicantCompanyId: formData?.applicantCompany?.value,
    applicantCompanyName: formData?.applicantCompany?.label,
    requestTypeId: formData?.requestTypeId,
    supportTypeId: formData?.supportTypeId,
    goWithFamily: formData?.goWithFamily === GO_WITH_FAMILY.YES ? true : formData?.goWithFamily === GO_WITH_FAMILY.NO ? false : undefined,
    visaCategoryId: formData?.visaCategory?.value,
    visaCategoryName: formData?.visaCategory?.label,
    travelTypeId: formData?.travelTypeId,
    note: formData?.note,
    isDraft,
    statusId: defaultValues.statusId,
    projectFamilies: formatProjectFamilies(formData),
    projectApplicant: getProjectApplicant(formData),
    projectAssignmentInfo: getProjectAssignmentInfo(formData),
    projectLocalSupporters: formatProjectLocalSupporters(formData),
    projectStakeholders: formData?.projectStakeholders,
    projectAttachments: formatProjectAttachments(formData),
    version: defaultValues?.version
  });
  const handleUpdate = async () => {
    if (isSubmitting) {
      return;
    }
    formMethod.handleSubmit(onSubmitUpdate, onError)();
  };
  const onSubmitUpdate = async (val: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await putProjectInternal(id || '', getPayloadRequest(val, false));
        formMethod.reset((pre) => ({ ...pre, isDirty: false }), { keepValues: true, keepDirty: false });
        if (res.status === 200) {
          setTimeout(() => {
            navigate(PROJECT_BASIC_INFO_URL(id || ''));
            dispatch(
              setAlertNotification({
                show: true,
                type: TYPE.SUCCESS,
                message: t('common:MSG_C_003', { item: t('basic_information:save') })
              })
            );
            resolve(res);
          }, 500);
        }
      } catch (error: any) {
        const callback = (mess: IAlertNotificationState) => dispatch(setAlertNotification(mess));
        handleProjectException(error, callback);
        reject(error);
      }
    });
  };
  const title = renderProjectCode(defaultValues, t('basic_information:project_id', { id: defaultValues?.code }));

  return (
    <div className='edit-basic-information p-5'>
      <FormProvider {...formMethod}>
        <div className='header flex justify-between sticky top-0 z-50 bg-[#f5f5f5]'>
          <div>
            <p className='title-24 whitespace-pre-line font-bold text-24'>{t('basic_information:title_edit')}</p>
            <p className='body-400 text-textGray'>{title}</p>
          </div>
          <Space size={16}>
            <div className='min-w-[120px]'>
              <BaseButton block size='medium' type='tertiary' onClick={() => navigate(PROJECT_BASIC_INFO_URL(id || ''))}>
                {t('basic_information:cancel')}
              </BaseButton>
            </div>
            <div className='min-w-[120px]'>
              <BaseButton block size='medium' disabled={!isDirty || isSubmitting} onClick={handleUpdate}>
                <span> {t('basic_information:save')} </span>
              </BaseButton>
            </div>
          </Space>
        </div>
        <div className='h-[calc(100vh-100px)] flex flex-col gap-4 mt-4 pb-8 overflow-y-scroll'>
          <BaseCollapse
            id='request_detail'
            gap-2
            title={t('project:add:applicant:applicant_information')}
            total={2}
            questions={validFields(['applicantName', 'applicantCompany'])}
            isError={
              validFields(['applicantName', 'applicantCompany']) !== 2 ||
              !!formMethod?.formState?.errors?.applicantName ||
              !!formMethod?.formState?.errors?.applicantCompany
            }
          >
            <Row gutter={16}>
              <Col span={12}>
                <FormInput
                  required={true}
                  defaultValue={formMethod.getValues('applicantName')}
                  name='applicantName'
                  labelTx='project:add:applicant:applicant_name'
                  placeholderTx={String(t('project:add:please_select_field', { field: t('project:add:applicant:applicant_name') }))}
                />
              </Col>
              <Col span={12}>
                <FormSelect
                  labelInValue
                  disabled={defaultValues?.isPublished}
                  name='applicantCompany'
                  required={true}
                  label={String(t('project:add:applicant:applicant_company'))}
                  placeholder={String(t('project:add:please_select_field', { field: t('project:add:applicant:applicant_company') }))}
                  options={companyOptions}
                />
              </Col>
            </Row>
          </BaseCollapse>

          <RequestDetailComponent
            formMethod={formMethod}
            setShowLocalRepresentative={setShowLocalRepresentative}
            setDisabledAssignee={setDisabledAssignee}
          />
          <ApplicantRepresentativeComponent ref={projectApplicantRef} formMethod={formMethod} />
          <FamilyInformationComponent ref={projectFamiliesRef} formMethod={formMethod} />
          <TrainingDestinationInformationComponent ref={projectAssignmentInfoRef} formMethod={formMethod} disabledAssignee={disabledAssignee} />
          {showLocalRepresentative && <LocalRepresentativeComponent ref={projectLocalSupportersRef} formMethod={formMethod} />}
          <VisaInformationComponent formMethod={formMethod} />
          <TravelTypeComponent travelTypePath='travelTypeId' />
          <UploadFilesComponent ref={projectAttachmentsRef} formMethod={formMethod} />
        </div>
      </FormProvider>
      <ConfirmCancelEditing condition={isDirty && !isSubmitSuccessful} />
    </div>
  );
};

export default EditBasicInformation;
