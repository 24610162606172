import { v4 as uuid } from 'uuid';

export const DEFAULT_STAKEHOLDER = {
  stakeholderEmail: '',
  canDelete: true,
  formId: uuid()
};
export const MAX_FIELD = 9;
export const MAX_FIELD_PERSON_NUMBER = 5;
export const INVOLVED_MEMBERS = {
  YES: 'YES',
  NO: 'NO'
} as const;
export type INVOLVED_MEMBERS = (typeof INVOLVED_MEMBERS)[keyof typeof INVOLVED_MEMBERS];
export const REQUIRED_STAKEHOLDER_FIELDS = ['stakeholderEmail'];
