import { useAppSelector } from '@/hooks';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IOptions } from '@/pages/project-management/add-edit/models';

import { BaseCollapse } from '@/components/base-collapse/BaseCollapse';
import { FormSelect } from '@/components/form-select/FormSelect';

import { ICategory } from '@/utils/interfaces/template';

type Props = {
  formMethod: any;
};

const VisaInformationComponent = (props: Props) => {
  const { formMethod } = props;
  const [categoryOptions, setCategoryOptions] = useState<IOptions[]>([]);
  const watchCountryCode = formMethod.watch('projectAssignmentInfo.countryId');
  const countries = useAppSelector((state) => state?.global?.countries);

  const { t } = useTranslation();
  useEffect(() => {
    const categories = countries?.find((country) => country.id === watchCountryCode)?.categories;
    if (categories) {
      const newCategoryOptions = categories.map((category: ICategory) => {
        return {
          label: category.name,
          value: category.id
        };
      });
      if (!newCategoryOptions.find((item) => item.value === formMethod.getValues('visaCategory')?.value)) {
        formMethod.setValue('visaCategory', undefined);
      }
      setCategoryOptions(newCategoryOptions);
    } else {
      setCategoryOptions([]);
      formMethod.setValue('visaCategory', undefined);
    }
  }, [watchCountryCode]);

  const watchFields = formMethod.watch(['visaCategory']);
  const watchFieldsValid = watchFields.filter((field: any) => field);

  return (
    <BaseCollapse id='request_detail' title={t('basic_information:visa_information_label')} total={1} questions={watchFieldsValid.length}>
      <Row gutter={16}>
        <Col span={12}>
          <FormSelect
            labelInValue
            name='visaCategory'
            label={String(t('basic_information:visa_category'))}
            placeholder={String(
              t('placeholder:select', {
                field: t('basic_information:visa_category')
              })
            )}
            options={categoryOptions}
            disabled={!watchCountryCode}
          />
        </Col>
      </Row>
    </BaseCollapse>
  );
};

export default VisaInformationComponent;
