import { Space } from 'antd';
import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { useFormScenarioBContext } from '@pages/project-management/add-edit/scenario/type-b';
import { relativePath } from '@pages/project-management/add-edit/scenario/utils';

import { BaseButton } from '@/components/base-button/BaseButton';

import PlusIcon from '@/assets/icons/PlusIcon.svg';
import InfoIcon from '@assets/icons/Info.svg';

import FamilyMember from './FamilyMember';
import { DEFAULT_FAMILY_MEMBER, MAX_FIELD_PERSON_NUMBER, PROJECT_FAMILY_PATH } from './constants';

import './styles.scss';

export const PARENT_PATH = 'steps[1]';
const ScenarioBStepThree = () => {
  const formMethod = useFormScenarioBContext<'step-3'>();
  const { t } = useTranslation();

  const PATHS = {
    PROJECT_FAMILIES: relativePath(PROJECT_FAMILY_PATH, PARENT_PATH)
  };
  const [families] = formMethod.watch([PATHS.PROJECT_FAMILIES]);
  const { append, remove } = useFieldArray({
    name: PATHS.PROJECT_FAMILIES,
    control: formMethod.control
  });

  const addNewMember = () => {
    if (families?.length >= MAX_FIELD_PERSON_NUMBER) return;
    append({ ...DEFAULT_FAMILY_MEMBER, formId: uuid() });
  };

  const handleRemove = (index: number) => {
    remove(index);
  };

  return (
    <div className='scenario-b-step-three'>
      <div className='scenario-b-step-three__form-title'>
        <p className='title-20 mb-[12px]'>{t('project:scenario_b:family_information')}</p>
        <div className='flex items-center'>
          <InfoIcon className='block mr-[4px]' width={18} height={18} />
          <span className='body-400'>{t('project:scenario_b:family_form_guide')}</span>
        </div>
      </div>
      {families?.map((field: any, index: number) => (
        <FamilyMember
          key={field?.id ?? field?.formId}
          index={index}
          parentPath={PATHS.PROJECT_FAMILIES}
          showTrashIcon={families?.length > 1}
          onRemove={handleRemove}
        />
      ))}
      <Space size={16}>
        <div className='min-w-[140px]'>
          <BaseButton
            icon={<PlusIcon />}
            disabled={families?.length === MAX_FIELD_PERSON_NUMBER}
            type='secondary'
            block
            size='medium'
            onClick={addNewMember}
          >
            {t('project:add:family_information:add_family_member_button')}
          </BaseButton>
        </div>
      </Space>
    </div>
  );
};

export default ScenarioBStepThree;
