import { createContext } from 'react';

import { IUserProfile } from '../utils/interfaces/user';

interface IAuthContext {
  user?: IUserProfile | null;
  isAuthenticated: boolean;
  filteredValues?: any;
}

export const AuthContext = createContext<IAuthContext>({
  user: null,
  isAuthenticated: false,
  filteredValues: null
});
