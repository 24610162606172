export const RESPONSE_TYPE = {
  // Authentication
  CHANGE_PASSWORD: 'ChangePassword',
  FORCE_LOGOUT: 'force_logout',
  // Question
  UNLOCKQUESTIONNAIRE: 'UNLOCKQUESTIONNAIRE',
  // Notice internal
  PROJECT_INTERNAL: 'PROJECT.ASSIGN',
  TASK: 'TASK.ASSIGN',
  RESULTS: 'DOCUMENT.APPROVAL_RESULT',
  REQUEST: 'DOCUMENT.APPROVAL_REQUEST',
  EXTERNAL: 'EXTERNAL',
  // Notice external
  PROJECT_EXTERNAL: 'PROJECT_TASK',
  ANNOUNCEMENT: 'ANNOUNCEMENT',
  QUESTIONAIRE: 'QUESTIONAIRE',
  OTHERS: 'OTHERS'
};
