import { Button, Space } from 'antd';

import '@/components/base-card/BaseCard.scss';

import { TrashIcon } from '../icon-svg/IconSvg';

interface IBaseCard {
  index?: number;
  className?: string;
  title: string;
  children?: React.ReactNode;
  questions?: number;
  total?: number;
  showIconRemove?: boolean;
  onRemove?: Function;
  classTitle?: string;
}

export function BaseCard(props: IBaseCard) {
  const {
    className = '',
    title,
    children,
    questions = 0,
    total = 0,
    showIconRemove = false,
    onRemove,
    index,
    classTitle = 'card-header-text'
  } = props;
  const titleCard = () => {
    return (
      <div className='flex flex-row align-middle justify-between'>
        <div className='flex flex-row'>
          <span className={classTitle}>{title}</span>
          {!!total && (
            <div className='ml-[8px] pr-[8px] pl-[8px] bg-[#DBDBE0] rounded text-[14px] font-bold flex items-center'>
              {questions}/{total}
            </div>
          )}
        </div>
        {showIconRemove && (
          <Button
            type='text'
            onClick={() => onRemove && onRemove(index)}
            className='p-0 m-0 h-[20px] cursor-pointer border-none bg-transparent'
            icon={<TrashIcon className='align-sub icon-remove' style={{ cursor: 'pointer', width: 18, height: 18 }} />}
          />
        )}
      </div>
    );
  };

  return (
    <div className={`base-card-container flex flex-col ${className}`}>
      <Space direction={'vertical'} className='shadow-base-card p-4 gap-2 rounded-[8px] border-[1px] border-solid border-gray2'>
        {titleCard()}
        <div className='content'>{children}</div>
      </Space>
    </div>
  );
}
