import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { API } from '@/utils/constants/Apis';
import { API_URL, LOCALSTORAGE } from '@/utils/constants/AppConstants';
import { isJsonString } from '@/utils/helpers/common';

import { IForm, IProcessPatterns } from '../utils/interfaces/masterdata';
import { ICountry } from '../utils/interfaces/template';
import { IUserProfile } from '../utils/interfaces/user';

// Define a type for the slice state

export type IPosition = 'top' | 'bottom' | undefined;
export interface IAlertNotificationState {
  show: boolean;
  message: string;
  type?: string; // success | error
  position?: IPosition;
}

interface ICountNoticeUnRead {
  'PROJECT.ASSIGN'?: number;
  'TASK.ASSIGN'?: number;
  'DOCUMENT.APPROVAL_RESULT'?: number;
  'DOCUMENT.APPROVAL_REQUEST'?: number;
  EXTERNAL?: number;
  [key: string]: any;
}
interface IGlobalState {
  countNoticeUnRead: ICountNoticeUnRead;
  alertNotification: IAlertNotificationState;
  loadingPage: boolean;
  countries: ICountry[];
  forms?: IForm[];
  processPatterns?: IProcessPatterns[];
  userProfile?: IUserProfile | null;
  isBlocking: boolean;
  isForceLogout: boolean;
  loadingPageGlobal: boolean;
  loadingPageNotification: boolean;
}

// Define the initial state using that type
const initialState: IGlobalState = {
  alertNotification: {
    show: false,
    message: '',
    type: 'success'
  },
  loadingPage: false,
  countNoticeUnRead: {},
  countries: [],
  forms: [],
  processPatterns: [],
  userProfile: null,
  isBlocking: false,
  isForceLogout: false,
  loadingPageGlobal: false,
  loadingPageNotification: false
};

export const globalSlice = createSlice({
  name: 'counter',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setAlertNotification: (state, action: PayloadAction<IAlertNotificationState>) => {
      state.alertNotification = action.payload;
    },
    setLoadingPage: (state, action: PayloadAction<boolean>) => {
      state.loadingPage = action.payload;
    },
    setCountNoticeUnRead: (state, action: PayloadAction<ICountNoticeUnRead>) => {
      state.countNoticeUnRead = action.payload;
    },
    setCountries: (state, action: PayloadAction<ICountry[]>) => {
      state.countries = action.payload;
    },
    setForms: (state, action: PayloadAction<IForm[]>) => {
      state.forms = action.payload;
    },
    setProcessPatterns: (state, action: PayloadAction<IProcessPatterns[]>) => {
      state.processPatterns = action.payload;
    },
    setUserProfile: (state, action: PayloadAction<IUserProfile>) => {
      state.userProfile = action.payload;
    },
    setIsBlocking: (state, action: PayloadAction<boolean>) => {
      state.isBlocking = action.payload;
    },
    setIsForceLogout: (state, action: PayloadAction<boolean>) => {
      state.isForceLogout = action.payload;
    },
    setLoadingPageGlobal: (state, action: PayloadAction<boolean>) => {
      state.loadingPageGlobal = action.payload;
    },
    setLoadingNotification: (state, action: PayloadAction<boolean>) => {
      state.loadingPageNotification = action.payload;
    }
  },
  extraReducers: async (builder) => {
    builder.addCase(fetchUserInfo.fulfilled, (state, action) => {
      state.userProfile = action.payload;
    });
  }
});

export const fetchUserInfo = createAsyncThunk('global/fetchUserInfo', async () => {
  try {
    const accessToken = localStorage.getItem(LOCALSTORAGE.ACCESS_TOKEN);
    const userProfiles = localStorage.getItem(LOCALSTORAGE.USER);
    if (!accessToken) throw new Error('Access token not found');
    if (!userProfiles) throw new Error('User profile not found');
    const params = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    };
    const sanitizeUrl = API_URL?.replace(/\/$/, '');
    const response = await fetch(API.GET_USER_INFO(sanitizeUrl), params);
    if (!response.ok) {
      throw new Error('Fetch user info failed');
    }
    const data = await response.json();
    if (!data) throw new Error('Fetch user info failed');
    const profiles = isJsonString(userProfiles) ? JSON.parse(userProfiles) : {};
    const updatedProfiles = {
      ...profiles,
      ...data
    };
    localStorage.setItem(LOCALSTORAGE.USER, JSON.stringify(updatedProfiles));
    return updatedProfiles;
  } catch (error) {}
});

export const {
  setAlertNotification,
  setLoadingPage,
  setCountries,
  setForms,
  setProcessPatterns,
  setUserProfile,
  setIsBlocking,
  setCountNoticeUnRead,
  setIsForceLogout,
  setLoadingPageGlobal,
  setLoadingNotification
} = globalSlice.actions;

export default globalSlice.reducer;
