export const REQUEST_DETAIL = {
  CERTIFICATION: '認証',
  TRANSLATION: '翻訳'
};

export const DEFAULT_SCENARIO_D_STEP_2 = {
  stepId: 2,
  userName: '',
  email: '',
  companyName: '',
  phoneNumber: '',
  requestDetail: [],
  language: '',
  countryId: undefined,
  notices: '',
  projectAttachments: []
};
