import { DefaultOptionType } from 'antd/es/select';
import { JSX, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  children: JSX.Element;
  value: DefaultOptionType[] | string[];
  options: DefaultOptionType[];
};
export default function SelectionMultiplePreview(props: Props) {
  const { children, value, options } = props;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  useEffect(() => {
    if (!wrapperRef.current || !value?.length || !options?.length) return;
    const inactiveAccounts = value?.filter(
      (account) =>
        !options.some((option) => {
          const value = typeof account === 'string' ? account : account.value;
          return value === option.value;
        })
    );
    if (!inactiveAccounts.length) return;
    const tagElements = wrapperRef.current.querySelectorAll('.multiple-item-selection');
    for (let i = 0; i < tagElements.length; i++) {
      const textContent = tagElements[i].textContent;
      if (
        inactiveAccounts.some((account) => {
          const label = typeof account === 'string' ? account : account.label;
          return label !== textContent;
        })
      )
        continue;
      const customTagElem = tagElements[i].querySelector('.custom-tag');
      if (!customTagElem) continue;
      customTagElem.textContent = `(${t('account_list:status_account:inactive')})${textContent}`;
    }
  });

  return <div ref={wrapperRef}>{children}</div>;
}
