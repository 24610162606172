import React from 'react';

export interface IScenarioLayoutProps {
  children: React.ReactNode;
  header: React.ReactNode;
  title: React.ReactNode;
}

const ScenarioLayout = ({ children, header, title }: IScenarioLayoutProps) => {
  return (
    <div className='scenario-step-layout flex flex-col gap-4 h-full'>
      <div className='sticky flex justify-between top-0 z-50 bg-gray1'>
        <div className='title-24 whitespace-pre-line font-bold text-24'>{title}</div>
        {header}
      </div>
      <div className='h-[calc(-136px + 100vh)] overflow-y-scroll w-full border border-solid border-gray2 rounded-lg p-4 bg-white'>{children}</div>
    </div>
  );
};

export default ScenarioLayout;
