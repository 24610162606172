import { QUESTION_VERSIONS } from '../../constanst';
import { AnswerType } from './modals';

export const TRANSLATE_CELL_WIDTH = 80;
export const QUESTION_TITLE_CELL_WIDTH = 200;
export const QUESTION_ACTION_CELL_WIDTH = 90;
export const COPY_ACTION_CELL_WIDTH = 150;

// Max length validate
export const MAX_LENGTH_TEXT = 255;
export const MAX_LENGTH_TEXT_AREA = 500;
export const MAX_LENGTH_POSTAL_CODE = 10;

export const DISPLAY_BUTTON_BY_VERSION = {
  [QUESTION_VERSIONS.NONE]: { cancel: true, save: true, translate: false, exportCsv: true, exportPdf: true, submit: true, unlock: false },
  [QUESTION_VERSIONS.ORIGINAL]: { cancel: true, exportCsv: true, translate: true, exportPdf: true, save: true, unlock: true },
  [QUESTION_VERSIONS.UPDATED]: { cancel: true, save: true, translate: true, exportCsv: true, exportPdf: true, submit: true, unlock: true }
};

export const additionalFieldPostal: { key: string; type?: AnswerType }[] = [
  {
    key: 'prefecture',
    type: undefined
  },
  {
    key: 'prefecture_roman',
    type: 'halfsize'
  },
  {
    key: 'city',
    type: undefined
  },
  {
    key: 'city_roman',
    type: 'halfsize'
  },
  {
    key: 'suburb',
    type: undefined
  },
  {
    key: 'suburb_roman',
    type: 'halfsize'
  },
  {
    key: 'street_address',
    type: undefined
  },
  {
    key: 'office',
    type: undefined
  }
];
