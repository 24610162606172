import useAuthorization from '@/hooks/useAuthorization';

import AddEditProjectInternal from './AddEditProjectInternal';
import ProjectExternalProxy from './ProjectExternalProxy';

export default function RegisterProject() {
  const { isExternalRole, isInternalRole } = useAuthorization();

  return (
    <>
      {isInternalRole && <AddEditProjectInternal />}
      {isExternalRole && <ProjectExternalProxy />}
    </>
  );
}
