import * as yup from 'yup';

import { TEXT_FIELD_MAX_LENGTH } from '@/utils/constants/AppConstants';
import { stringifyObjectValidateYup } from '@/utils/method';

import { LOCAL_REPRESENTATIVE } from './constants';

const localSupporterSchema = yup.object().shape({
  name: yup
    .string()
    .max(
      TEXT_FIELD_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'project:add:local_representative:name', limited: TEXT_FIELD_MAX_LENGTH }
      })
    )
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: 'project:add:local_representative:name' }
      })
    ),
  address: yup
    .string()
    .max(
      TEXT_FIELD_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'project:add:local_representative:address', limited: TEXT_FIELD_MAX_LENGTH }
      })
    )
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: 'project:add:local_representative:address' }
      })
    ),
  email: yup
    .string()
    .fullEmail(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_A_016',
        optionsTx: { field: 'project:add:local_representative:email' }
      })
    )
    .max(
      TEXT_FIELD_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'project:add:local_representative:email', limited: TEXT_FIELD_MAX_LENGTH }
      })
    )
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: 'project:add:local_representative:email' }
      })
    ),
  language: yup
    .string()
    .test(
      'language-required-first-item',
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_select',
        optionsTx: { field: 'project:add:local_representative:language' }
      }),
      function (value) {
        const index = (this?.options as { index?: number })?.index;
        if (index === 0) {
          return !!value;
        }
        return true;
      }
    )
    .notRequired()
});

export const stepFourSchema = yup.object().shape({
  needSupporter: yup
    .string()
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_select',
        optionsTx: { field: 'project:scenario_b:answer' }
      })
    )
    .oneOf(Object.values(LOCAL_REPRESENTATIVE)),
  projectLocalSupporters: yup.array().when('needSupporter', ([needSupporter], schema) => {
    return needSupporter === LOCAL_REPRESENTATIVE.NEEDED ? schema.of(localSupporterSchema) : schema.notRequired();
  })
});
