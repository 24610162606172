import { DefaultOptionType } from 'antd/es/select';
import { useRef } from 'react';
import { UseFormReturn } from 'react-hook-form';

import FormBaseFilter, { RefActionControl } from '@/components/base-filter';

import { ITaskFormFilter } from '../../utils';
import TaskFormFilter from './TaskFormFilter';

interface IFilterProps {
  formFilter: UseFormReturn<ITaskFormFilter>;
  totalFiltered: number;
  picOptions: DefaultOptionType[];
}

const TaskFilter = ({ formFilter, totalFiltered, picOptions }: IFilterProps) => {
  const filterTableRef = useRef<RefActionControl>({ toggle: () => {} });

  const handleFilterTaskList = (val: any) => {};

  return (
    <>
      <FormBaseFilter
        searchBox={null}
        tagSection={null}
        primaryAction={{
          label: 'button:filter',
          name: 'primary',
          popoverContent: (
            <TaskFormFilter
              picOptions={picOptions}
              defaultValues={formFilter.getValues() ?? {}}
              onSubmit={(val) => {
                formFilter.reset((previous) => ({ ...previous, ...val }));
                formFilter.handleSubmit((values) => handleFilterTaskList(values))();
              }}
            />
          ),
          refControl: filterTableRef,
          totalFiltered
        }}
        secondaryAction={null}
      />
    </>
  );
};

export default TaskFilter;
