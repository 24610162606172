import * as yup from 'yup';

import { TEXT_FIELD_MAX_LENGTH, TEXT_FIELD_SHORT_MAX_LENGTH } from '@/utils/constants/AppConstants';
import { stringifyObjectValidateYup } from '@/utils/method';

import { INVOLVED_MEMBERS } from './constants';

export const createStakeholderSchema = yup.object().shape({
  stakeholderName: yup
    .string()
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: 'basic_information:username' }
      })
    )
    .notUrl(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_092',
        optionsTx: { field: 'basic_information:username' }
      })
    )
    .max(
      TEXT_FIELD_SHORT_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'basic_information:username', limited: TEXT_FIELD_SHORT_MAX_LENGTH }
      })
    ),
  stakeholderEmail: yup
    .string()
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: 'basic_information:email_address' }
      })
    )
    .fullEmail(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_A_016',
        optionsTx: { field: 'basic_information:email_address' }
      })
    )
    .max(
      TEXT_FIELD_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'basic_information:email_address', limited: TEXT_FIELD_MAX_LENGTH }
      })
    ),
  role: yup.object().required(
    stringifyObjectValidateYup({
      keyT: 'common:MSG_001_select',
      optionsTx: { field: 'account_list:role' }
    })
  )
});

const stakeHolderSchema = yup.object().shape({
  stakeholderEmail: yup
    .string()
    .fullEmail(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_A_016',
        optionsTx: { field: 'project:add:project_stakeholders:email_address' }
      })
    )
    .max(
      TEXT_FIELD_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'project:add:project_stakeholders:email_address', limited: TEXT_FIELD_MAX_LENGTH }
      })
    )
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: 'project:add:project_stakeholders:email_address' }
      })
    )
    .stakeHolderEmailValidate()
});

export const stepFiveSchema = yup.object().shape({
  involvedMembers: yup
    .string()
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_select',
        optionsTx: { field: 'project:scenario_b:answer' }
      })
    )
    .oneOf(Object.values(INVOLVED_MEMBERS)),
  projectStakeholders: yup.array().when('involvedMembers', ([involvedMembers], schema) => {
    return involvedMembers === INVOLVED_MEMBERS.YES ? schema.of(stakeHolderSchema) : schema.notRequired();
  })
});
