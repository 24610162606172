import CommentWidget from '@/widgets/Comment';
import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import AppTooltip from '@/components/app-tooltip/AppTooltip';
import { TagTaskType } from '@/components/common/task/TagTaskType';

import useFetch from '@/hooks/useFetch';
import useMutation from '@/hooks/useMutation';

import { API } from '@/utils/constants/Apis';
import { DataViewer } from '@/utils/helpers/common';

import { STATUS_NAME } from '../../detail/constant';
import { IDataDetail } from '../../detail/utils';
import { renderTaskStatus } from '../../detail/utils/helper';
import { DEFAULT_DETAIL_TASK_OF_PROJECT, TASK_TYPE } from '../constants';
import { IDetailTaskData } from '../models';

export const CommentChat = () => {
  const { id, taskId } = useParams();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [taskDetail, setTaskDetail] = useState<IDetailTaskData>(DEFAULT_DETAIL_TASK_OF_PROJECT);
  const { data: projectDetail } = useFetch<IDataDetail>(API.GET_PROJECT_DETAIL(id ?? ''));
  const { mutate: getTaskDetails } = useMutation(API.GET_DETAIL_TASK(id ?? '', taskId ?? ''), {
    method: 'GET',
    bodyType: 'json',
    showToastError: true,
    showToastSuccess: false
  });

  useEffect(() => {
    onGetTaskDetails();
  }, []);

  const onGetTaskDetails = async () => {
    if (!id || !taskId) return;
    try {
      setLoading(true);
      const { data } = await getTaskDetails({});
      if (data) setTaskDetail(data);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  if (!id || !taskId) return <></>;

  const renderStatus = () => {
    const status = taskDetail?.statusName === STATUS_NAME.PENDING ? STATUS_NAME.PENDING : taskDetail?.statusName;
    if (!status) return;
    return renderTaskStatus(status, 'h-[22px]');
  };

  const getPicName = () => {
    let taskPicks = '';
    for (const key in taskDetail?.projectTaskPics) {
      const pic = taskDetail?.projectTaskPics[key];

      if (pic.picStatus) taskPicks += pic.picName;
      else taskPicks += `(${t('account_list:status_account:inactive')}) ${pic.picName}`;

      if (Number(key) !== taskDetail?.projectTaskPics?.length - 1) taskPicks += '、';
    }
    return taskPicks;
  };

  const stylesTooltip = {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '160%'
  };

  return loading ? (
    <div className='text-center'>
      <Spin size='large' />
    </div>
  ) : (
    <div className='flex flex-col gap-[12px] p-[20px] bg-gray1 h-[calc(100vh-40px)] text-lnk overflow-hidden'>
      <div className='flex items-center justify-between gap-[8px]'>
        <div className='flex items-center'>
          <TagTaskType type={taskDetail?.taskType ?? ''} />
          <AppTooltip className='truncate inline-block w-full' title={DataViewer.display(taskDetail?.taskName)} styles={stylesTooltip}>
            <div className='text-lnk text-[24px] font-bold truncate inline-block w-full'>{DataViewer.display(taskDetail?.taskName)}</div>
          </AppTooltip>
        </div>
        <div className='flex items-center gap-[32px]'>
          <p className='m-0 body-400'>
            {t('widgets:comment_function:deadline')} : {DataViewer.displayTime(taskDetail?.deadline)}
          </p>
          <AppTooltip className='block max-w-full w-fit truncate' title={getPicName()} styles={stylesTooltip}>
            <p className='font-normal m-0 body-400'>
              <span className='mr-[1px]'>{t('widgets:comment_function:mot_pick')} : </span>
              {getPicName()?.length > 25 ? DataViewer.display(`${getPicName().slice(0, 25)}...`) : DataViewer.display(getPicName())}
            </p>
          </AppTooltip>
          {renderStatus()}
        </div>
      </div>
      <p className='text-gray4 body-400'>{t('basic_information:project_id', { id: projectDetail?.code })}</p>
      <div className='grow flex overflow-hidden'>
        <CommentWidget.Container
          projectId={String(id)}
          projectProcessId={taskDetail?.projectProcessId ?? ''}
          taskId={String(taskId)}
          taskType={taskDetail?.taskType ?? TASK_TYPE.TODO}
        />
      </div>
    </div>
  );
};
