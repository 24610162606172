import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

import { SESSION_STORAGE } from '@/utils/constants';

import { PROJECT_LIST_URL } from '../../utils/constants/RouteContants';

const Callback = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    const returnUrl = sessionStorage.getItem(SESSION_STORAGE.RETURN_URL);
    if (returnUrl) {
      sessionStorage.removeItem(SESSION_STORAGE.RETURN_URL);
      window.location.href = returnUrl;
    } else if (sessionStorage.getItem('path') || auth?.user?.profile?.upn === sessionStorage.getItem('email')) {
      navigate(sessionStorage.getItem('path') as string);
      sessionStorage.removeItem('path');
    } else {
      navigate(PROJECT_LIST_URL);
    }
  }, [auth, navigate]);

  return <></>;
};

export default Callback;
