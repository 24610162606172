import { Steps } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { IStatus, PROCESS_TRAVEL_NAME, STATUS_NAME } from '@/pages/project-management/project-list-v2/models';

import AppTooltip from '@/components/app-tooltip/AppTooltip';

import { FORMAT_DATE_JP } from '@/utils/constants/AppConstants';

import AirplaneTakeoff from '@/assets/icons/AirplaneTakeoff.svg';
import DisableRow from '@/assets/icons/DisableRow.svg';
import DisabledStep from '@/assets/icons/DisabledStep.svg';
import DotStep from '@/assets/icons/DotStep.svg';
import FinishStep from '@/assets/icons/FinishStep.svg';
import ProcessStep from '@/assets/icons/ProcessStep.svg';
import WarningStep from '@/assets/icons/WarningStep.svg';

import './Steps.scss';

const { Step } = Steps;

interface IStepsProcessProps {
  stepData?: DefaultOptionType[] | null;
  listStatus: IStatus[];
  projectActive?: boolean | null;
}

export const StepsProcess = ({ stepData, listStatus, projectActive }: IStepsProcessProps) => {
  const { t } = useTranslation();

  const isTravelStep = (item: DefaultOptionType) => (item.processName ?? item.name) === PROCESS_TRAVEL_NAME;
  const renderCustomIcon = (item: DefaultOptionType, statusName?: STATUS_NAME) => {
    const mappingIcon = {
      [STATUS_NAME.TODO]: <DisabledStep />,
      [STATUS_NAME.PENDING]: <WarningStep />,
      [STATUS_NAME.IN_PROGRESS]: <ProcessStep />,
      [STATUS_NAME.COMPLETED]: <FinishStep />
    };
    const icon = isTravelStep(item) ? <AirplaneTakeoff /> : statusName && mappingIcon[statusName] ? mappingIcon[statusName] : <DotStep />;
    const title = statusName && mappingIcon[statusName] ? statusName : t('project_management:status:not_execute');
    return <AppTooltip title={title}>{icon}</AppTooltip>;
  };
  const disabledRow = !projectActive;

  return (
    <div className={`form-step-wrapper min-h-[76px] ${disabledRow ? 'disabled-process' : ''}`}>
      {stepData && stepData?.length !== 0 ? (
        <div className='mot-pick relative'>
          {disabledRow && <DisableRow className='absolute step-disable-icon left-[40%]' width={146} height={66} />}
          <Steps className={disabledRow ? 'opacity-40' : ''} labelPlacement='vertical'>
            {stepData.map((item: DefaultOptionType, index: number) => {
              const matchedStatus = listStatus.find((status) => status.id === item.statusId);
              return (
                <Step
                  key={index + 1}
                  title={item?.name ?? item?.processName}
                  className={`
                    ${!matchedStatus && !isTravelStep(item) ? 'small-dot' : ''}
                    ${!matchedStatus || matchedStatus?.name === STATUS_NAME.TODO ? 'line-dotted' : 'line-dotted-active'}
                    `}
                  subTitle={
                    matchedStatus?.name === STATUS_NAME.COMPLETED && item?.completedDate
                      ? dayjs(item.completedDate).format(FORMAT_DATE_JP)
                      : undefined
                  }
                  icon={renderCustomIcon(item, matchedStatus?.name)}
                />
              );
            })}
          </Steps>
        </div>
      ) : (
        <div className='relative'>
          {disabledRow && <DisableRow className='absolute step-disable-icon left-[40%]' width={146} height={66} />}
          <div className={`h-[76px] flex items-center justify-center ${disabledRow ? 'opacity-40' : ''}`}>{t('common:no_data')}</div>
        </div>
      )}
    </div>
  );
};
