import i18n from '@/i18n';
import { Button, Col, CollapseProps, Row, Space } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { get, isArray } from 'lodash';

import { IProjectInfo } from '@/pages/project-management/add-edit/models';
import { LANGUAGE } from '@/pages/project-management/project-list/constants';

import AppLabelSpace from '@/components/app-label/AppLabelSpace';
import AppTooltip from '@/components/app-tooltip/AppTooltip';
import { IconDownload } from '@/components/icon-svg/IconSvg';

import { FORMAT_DATE_JP } from '@/utils/constants/AppConstants';
import { SUPPORT_TYPE_NAME } from '@/utils/constants/project';
import { DataViewer } from '@/utils/helpers/common';
import { Counters } from '@/utils/helpers/counters';
import { handleDownloadSingleFile } from '@/utils/helpers/fileHelper';
import { decodeFileName } from '@/utils/helpers/globalHelper';

import IconPaperclip from '@/assets/icons/Paperclip.svg';

import { BASIC_INFO_SECTION_KEYS, FIRST_FIELD } from '../../../contants';

interface ILabelWithCountProps {
  title: string;
  subTitle: string;
  titleClass?: string;
  subTitleClass?: string;
}
const LabelWithCount = ({ title, subTitle, titleClass, subTitleClass }: ILabelWithCountProps) => {
  return (
    <div className='flex flex-row'>
      <span className={classNames('ant-collapse-header-text', titleClass)}>{title}</span>
      <div className={classNames('ml-[8px] pr-[8px] pl-[8px] bg-gray2 rounded text-[14px] font-bold', subTitleClass)}>{subTitle}</div>
    </div>
  );
};

const getGender = (id: boolean | undefined, t: Function) => {
  if (id === undefined) return id;
  return id ? t('common:female') : t('common:male');
};

const getTimeByFormat = (localTime?: string, pattern: string = FORMAT_DATE_JP) => {
  const time = dayjs(localTime);
  return time.isValid() ? time.format(pattern) : localTime;
};

interface IFamilySectionItemProps {
  familyMember: IProjectInfo['projectFamilies'][0];
  blankTitle: string;
  t: Function;
  completed: number;
  total: number;
  index: number;
}
const FamilySectionItem = ({ familyMember, blankTitle, t, completed, total, index }: IFamilySectionItemProps) => {
  const { lastName, secondaryNationalName, firstName, dateOfBirth, lastNameRomaji, gender, firstNameRomaji, primaryNationalName, attendanceTime } =
    familyMember || {};

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <LabelWithCount
            titleClass='subtitle-16'
            title={`${t('basic_information:family_information')} ${index}`}
            subTitle={`${completed}/${total}`}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <AppLabelSpace {...commonInfoProps} title={DataViewer.display(lastName)} label={t('basic_information:last')} />
        </Col>
        <Col span={12}>
          <AppLabelSpace {...commonInfoProps} title={DataViewer.display(secondaryNationalName)} label={t('basic_information:second_nationality')} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <AppLabelSpace {...commonInfoProps} title={DataViewer.display(firstName)} label={t('basic_information:first')} />
        </Col>
        <Col span={12}>
          <AppLabelSpace
            {...commonInfoProps}
            title={dateOfBirth ? getTimeByFormat(dateOfBirth) : DataViewer.display(dateOfBirth)}
            label={t('basic_information:date_of_birth')}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <AppLabelSpace {...commonInfoProps} title={DataViewer.display(lastNameRomaji)} label={t('basic_information:last_name_romaji')} />
        </Col>
        <Col span={12}>
          <AppLabelSpace {...commonInfoProps} title={DataViewer.display(getGender(gender, t))} label={t('basic_information:gender')} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <AppLabelSpace {...commonInfoProps} title={DataViewer.display(firstNameRomaji)} label={t('basic_information:first_name_romaji')} />
          <AppLabelSpace {...commonInfoProps} title={DataViewer.display(primaryNationalName)} label={t('basic_information:nationality')} />
        </Col>
        <Col span={12}>
          <AppLabelSpace {...commonInfoProps} title={DataViewer.display(attendanceTime)} label={t('basic_information:accompanying_period')} />
        </Col>
      </Row>
    </>
  );
};

interface ISupporterItemProps {
  item: IProjectInfo['projectLocalSupporters'][0];
  blankTitle: string;
  t: Function;
  completed: number;
  total: number;
  indexItem: number;
}
const SupporterItem = (props: ISupporterItemProps) => {
  const { item, t, completed, total, indexItem } = props;

  const getLanguage = (lag: string | undefined) => {
    if (lag === undefined) return lag;
    return LANGUAGE.japan === lag ? t('common:language_jp') : t('common:language_en');
  };
  const totalField = indexItem === FIRST_FIELD ? total : total - 1;
  const completedField = indexItem !== FIRST_FIELD && item.language !== undefined ? completed - 1 : completed;

  return (
    <>
      <Space
        size={24}
        direction={'vertical'}
        className='w-full first:mt-0 mt-[12px] box-shadow p-[16px] rounded-[14px] border-[1px] border-solid border-[#DBDBE0]'
      >
        <Row gutter={16}>
          <Col span={12}>
            <LabelWithCount
              titleClass='subtitle-16'
              title={`${t('basic_information:local_contact')} ${indexItem + 1}`}
              subTitle={`${completedField}/${totalField}`}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <AppLabelSpace {...commonInfoProps} title={DataViewer.display(item?.name)} label={t('basic_information:local_contact_name')} />
          </Col>
          <Col span={12}>
            <AppLabelSpace
              {...commonInfoProps}
              title={DataViewer.display(item?.address)}
              label={t('basic_information:local_contact_person_address')}
            />
          </Col>
          <Col span={12}>
            <AppLabelSpace {...commonInfoProps} title={DataViewer.display(item?.email)} label={t('basic_information:email')} />
          </Col>
          {indexItem === FIRST_FIELD && (
            <Col span={12}>
              <AppLabelSpace {...commonInfoProps} title={DataViewer.display(getLanguage(item?.language))} label={t('basic_information:language')} />
            </Col>
          )}
        </Row>
      </Space>
    </>
  );
};

const commonCountedSchema = {
  applicant: ['applicantName', 'applicantCompanyName'],
  request: ['requestTypeName', 'supportTypeName'],
  applicantDetail: [
    // left column
    'projectApplicant.lastName',
    'projectApplicant.firstName',
    'projectApplicant.lastNameRomaji',
    'projectApplicant.firstNameRomaji',
    'projectApplicant.primaryNationalName',
    'projectApplicant.secondaryNationalName',
    'projectApplicant.dateOfBirth',
    'projectApplicant.gender',
    'projectApplicant.postalCode',
    'projectApplicant.prefecture',
    'projectApplicant.prefectureRoman',
    // right column
    'projectApplicant.city',
    'projectApplicant.cityRoman',
    'projectApplicant.suburb',
    'projectApplicant.suburbRoman',
    'projectApplicant.streetAddress',
    'projectApplicant.apartmentName',
    'projectApplicant.placeOfResidence',
    'projectApplicant.currentCompany',
    'projectApplicant.departureDate'
  ]
};

const commonInfoProps = {
  labelClass: 'body-400',
  titleClass: 'body-700'
};

export class SectionBuilder {
  private views: CollapseProps['items'] = [];
  private projectData?: IProjectInfo;
  private t: Function = i18n.t;
  private commonCounters: Counters<string> = new Counters(commonCountedSchema, {});
  private blankTitle: string = '--';
  completed = 0;
  constructor({ projectData: prjData, translate }: { projectData?: IProjectInfo; translate?: Function }) {
    this.projectData = prjData;
    if (translate instanceof Function) {
      this.t = translate;
    }
    if (prjData) {
      this.commonCounters = new Counters(commonCountedSchema, prjData);
    }
  }

  drawApplicantSection() {
    const projectData = this.projectData;
    const section = {
      key: BASIC_INFO_SECTION_KEYS.INFO,
      label: (
        <LabelWithCount
          title={this.t('basic_information:applicant_information')}
          subTitle={`${this.commonCounters.get('applicant')}/${commonCountedSchema.applicant.length}`}
        />
      ),
      children: (
        <Row gutter={16}>
          <Col span={12}>
            <AppLabelSpace
              {...commonInfoProps}
              title={DataViewer.display(projectData?.applicantName)}
              label={this.t('basic_information:applicant_name')}
            />
          </Col>
          <Col span={12}>
            <AppLabelSpace
              {...commonInfoProps}
              title={DataViewer.display(projectData?.applicantCompanyName)}
              label={this.t('basic_information:applicant_company')}
            />
          </Col>
        </Row>
      )
    };
    this.views?.push(section);
    return this;
  }

  drawRequestSection() {
    const projectData = this.projectData;
    const t = this.t;
    const counters = this.commonCounters;
    const section = {
      key: BASIC_INFO_SECTION_KEYS.REQUEST_CONTENT,
      label: (
        <LabelWithCount
          title={t('basic_information:request_content')}
          subTitle={`${counters.get('request')}/${commonCountedSchema.request.length}`}
        />
      ),
      children: (
        <>
          <Row gutter={16}>
            <Col span={12}>
              <AppLabelSpace
                {...commonInfoProps}
                title={DataViewer.display(projectData?.requestTypeName)}
                label={t('basic_information:request_type')}
              />
            </Col>
            <Col span={12}>
              <AppLabelSpace
                {...commonInfoProps}
                title={DataViewer.display(projectData?.supportTypeName)}
                label={t('basic_information:support_type')}
              />
            </Col>
          </Row>
        </>
      )
    };
    this.views?.push(section);
    return this;
  }

  drawApplicantDetailSection() {
    const t = this.t;
    const counters = this.commonCounters;
    const projectData = this.projectData;
    const section: any = {
      key: BASIC_INFO_SECTION_KEYS.APPLICANT_DETAIL,
      label: (
        <LabelWithCount
          title={t('basic_information:applicant_infomation')}
          subTitle={`${counters.get('applicantDetail')}/${commonCountedSchema.applicantDetail.length}`}
        />
      )
    };
    const childrenSchema = [
      [
        { label: t('basic_information:last'), keyValue: 'projectApplicant.lastName' },
        { label: t('postal_code:city'), keyValue: 'projectApplicant.city' }
      ],
      [
        { label: t('basic_information:first'), keyValue: 'projectApplicant.firstName' },
        { label: t('postal_code:city_roman'), keyValue: 'projectApplicant.cityRoman' }
      ],
      [
        { label: t('basic_information:last_name_romaji'), keyValue: 'projectApplicant.lastNameRomaji' },
        { label: t('postal_code:suburb'), keyValue: 'projectApplicant.suburb' }
      ],
      [
        { label: t('basic_information:first_name_romaji'), keyValue: 'projectApplicant.firstNameRomaji' },
        { label: t('postal_code:suburb_roman'), keyValue: 'projectApplicant.suburbRoman' }
      ],
      [
        { label: t('basic_information:nationality'), keyValue: 'projectApplicant.primaryNationalName' },
        { label: t('postal_code:street_address'), keyValue: 'projectApplicant.streetAddress' }
      ],
      [
        { label: t('basic_information:second_nationality'), keyValue: 'projectApplicant.secondaryNationalName' },
        { label: t('postal_code:office'), keyValue: 'projectApplicant.apartmentName' }
      ],
      [
        [
          { label: t('basic_information:date_of_birth'), keyValue: 'projectApplicant.dateOfBirth' },
          { label: t('basic_information:gender'), keyValue: 'projectApplicant.gender' }
        ],
        { label: t('basic_information:resident_registration_place'), keyValue: 'projectApplicant.placeOfResidence' }
      ],
      [
        { label: t('basic_information:postal_code'), keyValue: 'projectApplicant.postalCode' },
        { label: t('basic_information:affiliation_company_name'), keyValue: 'projectApplicant.currentCompany' }
      ],
      [
        { label: t('postal_code:prefecture'), keyValue: 'projectApplicant.prefecture' },
        { label: t('basic_information:scheduled_travel_date'), keyValue: 'projectApplicant.departureDate' }
      ],
      [{ label: t('postal_code:prefecture_roman'), keyValue: 'projectApplicant.prefectureRoman' }]
    ];

    section.children = childrenSchema.map((rows: any, i: number) => {
      const columns = rows.map((col: any, index: number) => {
        const isMergedColumn = isArray(col);

        const getTitle = (colItem: any) => {
          let title = get(projectData, colItem.keyValue);
          if (colItem.keyValue === 'projectApplicant.gender') {
            title = getGender(title, t);
          }
          if (['projectApplicant.dateOfBirth', 'projectApplicant.departureDate'].includes(colItem.keyValue)) {
            title = title && getTimeByFormat(title);
          }
          return title;
        };

        if (isMergedColumn) {
          return (
            <Col span={12} key={index}>
              {col.map((colItem) => {
                return (
                  <AppLabelSpace {...commonInfoProps} key={colItem.keyValue} title={DataViewer.display(getTitle(colItem))} label={colItem.label} />
                );
              })}
            </Col>
          );
        }
        return (
          <Col span={12} key={index}>
            <AppLabelSpace {...commonInfoProps} title={DataViewer.display(getTitle(col))} label={col.label} />
          </Col>
        );
      });

      return (
        <Row gutter={16} key={i}>
          {columns}
        </Row>
      );
    });

    this.views?.push(section);
    return this;
  }

  drawFamiliesSection() {
    const blankTitle = this.blankTitle;
    const projectData = this.projectData;

    const schema = {
      familyMember: [
        'lastName',
        'secondaryNationalName',
        'firstName',
        'dateOfBirth',
        'lastNameRomaji',
        'gender',
        'firstNameRomaji',
        'primaryNationalName',
        'attendanceTime'
      ]
    };
    const completedLogs = projectData?.projectFamilies?.reduce<{ [key: string]: { comp: number; total: number } }>((acc, mem) => {
      const memId = mem.id;
      if (!memId) return acc;
      if (acc[memId] === undefined) {
        acc[memId] = { comp: 0, total: schema.familyMember.length };
      }
      const counters = new Counters(schema, mem);
      acc[memId].comp = counters.get('familyMember');
      return acc;
    }, {});

    const goWithFamily =
      projectData?.goWithFamily !== undefined
        ? projectData?.goWithFamily
          ? this.t('project:add:family_information:hasFamily')
          : this.t('project:add:family_information:noFamily')
        : blankTitle;
    const totalInfo = goWithFamily !== undefined ? 1 : 0;

    const sectionChildren = (
      <Row>
        <Col span={12}>
          <AppLabelSpace title={goWithFamily} label={this.t('basic_information:go_with_family')} />
        </Col>
        {projectData?.goWithFamily &&
          projectData?.projectFamilies
            ?.sort((a, b) => {
              if (a.displayOrder === undefined || b.displayOrder === undefined) return 0;
              return a.displayOrder - b.displayOrder;
            })
            ?.map((item, i) => {
              return (
                <Space
                  key={i}
                  size={1}
                  direction={'vertical'}
                  className='w-full first:mt-0 mt-[12px] box-shadow p-[16px] rounded-[14px] border-[1px] border-solid border-[#DBDBE0]'
                >
                  <FamilySectionItem
                    familyMember={item}
                    t={this.t}
                    blankTitle={blankTitle}
                    completed={completedLogs?.[item.id ?? '']?.comp ?? 0}
                    total={completedLogs?.[item.id ?? '']?.total ?? 0}
                    index={i + 1}
                  />
                </Space>
              );
            })}
      </Row>
    );
    const section = {
      key: BASIC_INFO_SECTION_KEYS.ACCOMPANYING_FAMILY,
      label: (
        <LabelWithCount title={this.t('basic_information:accompanying_infomation')} subTitle={`${goWithFamily !== undefined ? 1 : 0}/${totalInfo}`} />
      ),
      children: sectionChildren
    };

    this.views?.push(section);
    return this;
  }

  drawAssignmentSection() {
    const t = this.t;
    const projectData = this.projectData;
    const schema = {
      projectAssignmentInfo: ['name', 'countryName', 'companyName', 'address']
    };
    const counters = new Counters(schema, projectData?.projectAssignmentInfo ?? {});
    const completed = counters.get('projectAssignmentInfo') ?? 0;
    const total = schema.projectAssignmentInfo.length;
    const { projectAssignmentInfo } = projectData || {};
    const section = {
      key: BASIC_INFO_SECTION_KEYS.ASSIGNMENT_INFO,
      label: <LabelWithCount title={t('basic_information:assignment_training_information_label')} subTitle={`${completed}/${total}`} />,
      children: (
        <>
          <Row gutter={16}>
            <Col span={12}>
              <AppLabelSpace
                {...commonInfoProps}
                title={DataViewer.display(projectAssignmentInfo?.name)}
                label={t('basic_information:assignee_trainee_name')}
              />
            </Col>
            <Col span={12}>
              <AppLabelSpace
                {...commonInfoProps}
                title={DataViewer.display(projectAssignmentInfo?.countryName)}
                label={t('basic_information:destination_training_country')}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <AppLabelSpace
                {...commonInfoProps}
                title={DataViewer.display(projectAssignmentInfo?.companyName)}
                label={t('basic_information:assignment_training_company_name')}
              />
            </Col>
            <Col span={12}>
              <AppLabelSpace
                {...commonInfoProps}
                title={DataViewer.display(projectAssignmentInfo?.address)}
                label={t('basic_information:assignment_training_city')}
              />
            </Col>
          </Row>
        </>
      )
    };

    this.views?.push(section);
    return this;
  }

  drawSupporterSection() {
    const t = this.t;
    const projectData = this.projectData;
    const blankTitle = this.blankTitle;
    if (projectData?.supportTypeName !== SUPPORT_TYPE_NAME.FULL) return this;

    const schema = {
      supporter: ['name', 'email', 'address', 'language']
    };

    const completedLogs = projectData?.projectLocalSupporters?.reduce<{ [key: string]: { comp: number; total: number } }>((acc, supporter) => {
      const memId = supporter.id;
      if (!memId) return acc;
      if (acc[memId] === undefined) {
        acc[memId] = { comp: 0, total: schema.supporter.length };
      }
      const counters = new Counters(schema, supporter);
      acc[memId].comp = counters.get('supporter');
      return acc;
    }, {});
    const section = {
      key: BASIC_INFO_SECTION_KEYS.SUPPORT_INFO,
      label: (
        <div className='flex flex-row'>
          <span className='ant-collapse-header-text'>{t('basic_information:local_contact')}</span>
        </div>
      ),
      children: (
        <Row gutter={16}>
          {projectData?.projectLocalSupporters
            ?.sort((a, b) => a.displayOrder - b.displayOrder)
            ?.map((item, i) => {
              return (
                <SupporterItem
                  key={i}
                  indexItem={i}
                  item={item}
                  t={t}
                  blankTitle={blankTitle}
                  completed={completedLogs?.[item.id ?? '']?.comp ?? 0}
                  total={completedLogs?.[item.id ?? '']?.total ?? 0}
                />
              );
            })}
        </Row>
      )
    };

    this.views?.push(section);
    return this;
  }

  drawVisaInfoSection() {
    const t = this.t;
    const projectData = this.projectData;
    const schema = {
      visaInfo: ['visaCategoryName']
    };
    const counters = new Counters(schema, projectData);
    const completed = counters.get('visaInfo');
    const section = {
      key: BASIC_INFO_SECTION_KEYS.VISA_INFO,
      label: (
        <div className='flex flex-row'>
          <span className='ant-collapse-header-text'>{t('basic_information:visa_information_label')}</span>
          <div className='ml-[8px] pr-[8px] pl-[8px] bg-[#DBDBE0] rounded text-[14px]'>{`${completed}/${schema.visaInfo.length}`}</div>
        </div>
      ),
      children: (
        <>
          <Row>
            <Col span={12}>
              <AppLabelSpace {...commonInfoProps} title={DataViewer.display(projectData?.visaCategoryName)} label={t('basic_information:category')} />
            </Col>
          </Row>
        </>
      )
    };

    this.views?.push(section);
    return this;
  }
  drawTravelTypeInfoSection() {
    const t = this.t;
    const projectData = this.projectData;
    const schema = {
      travelInfo: ['travelTypeName']
    };
    const counters = new Counters(schema, projectData);
    const completed = counters.get('travelInfo');
    const section = {
      key: BASIC_INFO_SECTION_KEYS.VISA_INFO,
      label: (
        <div className='flex flex-row'>
          <span className='ant-collapse-header-text'>{t('basic_information:travel_type')}</span>
          <div className='ml-[8px] pr-[8px] pl-[8px] bg-[#DBDBE0] rounded text-[14px]'>{`${completed}/${schema.travelInfo.length}`}</div>
        </div>
      ),
      children: (
        <Row>
          <Col span={12}>
            <AppLabelSpace {...commonInfoProps} title={DataViewer.display(projectData?.travelTypeName)} label={t('basic_information:travel_type')} />
          </Col>
        </Row>
      )
    };

    this.views?.push(section);
    return this;
  }
  drawMoreInfoSection() {
    const t = this.t;
    const projectData = this.projectData;
    const countedPerTotal = `${DataViewer.isEmptyHTML(projectData?.note) ? 0 : 1}/1`;
    const attachmentSection = {
      key: BASIC_INFO_SECTION_KEYS.ATTACHMENT_INFO,
      label: <span className='ant-collapse-header-text subtitle-18'>{t('basic_information:additional_information')}</span>,
      children: (
        <Row>
          <Space size={1} direction={'vertical'} className='w-full box-shadow p-[16px] rounded-[14px] border-[1px] border-solid border-[#DBDBE0]'>
            <Row gutter={16}>
              <Col span={12}>
                <div className='flex flex-row'>
                  <span className='ant-collapse-header-text subtitle-16'>{t('basic_information:contact_information')}</span>{' '}
                  <LabelWithCount title='' subTitle={countedPerTotal} />
                </div>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <div className={`text-14 !m-0 leading-[160%] text-ink flex font-medium text-html`}>
                  <span className='min-w-[180px] max-w-[180px] text-ink body-400'>{t('basic_information:special_note')}</span>
                  <div dangerouslySetInnerHTML={{ __html: DataViewer.displayAsSanitizeHTML(projectData?.note) }} />
                </div>
              </Col>
            </Row>
          </Space>
          <Space
            size={1}
            direction={'vertical'}
            className='w-full mt-[8px] box-shadow p-[16px] rounded-[14px] border-[1px] border-solid border-[#DBDBE0]'
          >
            <Row gutter={16}>
              <Col span={12}>
                <div className='flex flex-row'>
                  <span className='ant-collapse-header-text subtitle-16'>{t('basic_information:attachment_file')}</span>
                </div>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col>
                {projectData?.projectAttachments?.length ? (
                  projectData?.projectAttachments.map((file: any, i: number) => (
                    <div key={i} className='flex justify-start items-center'>
                      <p className='mr-[8px] w-[18px]'>{i + 1}</p>
                      <div className='w-[500px] h-[38px] grow flex justify-start items-center border border-solid border-t-0	border-r-0	border-l-0 border-b-gray2'>
                        <div className='flex justify-center items-center'>
                          <IconPaperclip width={18} height={18} />
                        </div>
                        <AppTooltip className='grow px-[10px] truncate' title={decodeFileName(file.name)}>
                          <span
                            className='cursor-pointer'
                            onClick={() => {
                              return handleDownloadSingleFile(file.blobPath as string, file.name);
                            }}
                          >
                            {decodeFileName(file.name)}
                          </span>
                        </AppTooltip>
                        <div className='flex justify-center items-center'>
                          <Button
                            type='text'
                            icon={<IconDownload className='h-[20px] w-[20px] download hover:cursor-pointer' />}
                            onClick={() => {
                              return handleDownloadSingleFile(file.blobPath as string, file.name);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className={`text-14 !m-0 leading-[160%] text-ink flex font-medium text-html`}>
                    <span className='min-w-[180px] max-w-[180px] text-ink body-400'>{t('basic_information:attachment_file')}</span>
                    <span>--</span>
                  </div>
                )}
              </Col>
            </Row>
          </Space>
        </Row>
      )
    };

    this.views?.push(attachmentSection);
    return this;
  }

  getViews() {
    return this.views ?? [];
  }
}
