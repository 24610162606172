import { yupResolver } from '@hookform/resolvers/yup';
import { ResolverOptions } from 'react-hook-form';
import * as yup from 'yup';

import { SCENARIO_TYPES } from '../contants';
import { FormObserver, ScenarioStepMapping } from '../models/projectExternalProxy';
import { schemaScenarioB } from '../scenario/type-b/validations';
import { FormValidationScenarioA } from './FormValidationScenarioA';
import { FormValidationScenarioC } from './FormValidationScenarioC';
import { FormValidationScenarioD } from './FormValidationScenarioD';
import { FormValidationScenarioE } from './FormValidationScenarioE';

export const schemaProxy = (
  data: FormObserver<keyof ScenarioStepMapping>,
  context: any,
  options: ResolverOptions<FormObserver<keyof ScenarioStepMapping>>
) => {
  let schema = noneValidateSchema;
  const { scenarioType } = data;
  switch (scenarioType) {
    case SCENARIO_TYPES.SCENARIO_A:
      schema = FormValidationScenarioA;
      break;
    case SCENARIO_TYPES.SCENARIO_B:
      schema = schemaScenarioB;
      break;
    case SCENARIO_TYPES.SCENARIO_C:
      schema = FormValidationScenarioC;
      break;
    case SCENARIO_TYPES.SCENARIO_D:
      schema = FormValidationScenarioD;
      break;
    case SCENARIO_TYPES.SCENARIO_E:
      schema = FormValidationScenarioE;
      break;
    default:
      schema = noneValidateSchema;
      break;
  }
  return yupResolver(schema)(data, context, options);
};
export const noneValidateSchema = yup.object().shape({});
