import { WebStorageStateStore } from 'oidc-client-ts';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { AuthProvider } from 'react-oidc-context';
import { Provider } from 'react-redux';

import '@/utils/helpers/yup-extensition';

import App from './App';
import LoadingPage from './components/loading-page/LoadingPage';
import i18n from './i18n/index';
import { store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import ThemeProvider from './theme/ThemeProvider';
import { SESSION_STORAGE } from './utils/constants';

import './index.scss';

const oidcConfiguration = {
  authority: process.env.REACT_APP_API_AUTHORITY || '',
  client_id: process.env.REACT_APP_API_CLIENT_ID || '',
  redirect_uri: process.env.REACT_APP_API_REDIRECT_URL || '/callback',
  silent_redirect_uri: `${process.env.REACT_APP_API_SILENT_REDIRECT_URL}` || '',
  post_logout_redirect_uri: process.env.REACT_APP_API_LOGOUT_REDIRECT_URL || '',
  scope: process.env.REACT_APP_API_SCOPE,
  response_type: 'code',
  // monitorSession: true,
  automaticSilentRenew: true
};

const onSigninCallback = (): void => {
  window.history.replaceState({}, window.document.title, window.location.origin + window.location.pathname);
  sessionStorage.removeItem(SESSION_STORAGE.LOGGING_OUT);
  const returnUrl = sessionStorage.getItem(SESSION_STORAGE.RETURN_URL);
  if (returnUrl) {
    sessionStorage.removeItem(SESSION_STORAGE.RETURN_URL);
    window.location.href = returnUrl;
  }
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <ThemeProvider>
        <AuthProvider {...oidcConfiguration} onSigninCallback={onSigninCallback} userStore={new WebStorageStateStore({ store: window.localStorage })}>
          <App />
          <LoadingPage />
        </AuthProvider>
      </ThemeProvider>
    </I18nextProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
