import i18n from '@/i18n';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IProjectExternal, ITravelType } from '@/pages/project-management/add-edit/models';

import { EXTERNAL_ADMIN, EXTERNAL_GENERAL_USER, INTERNAL_ADMIN, INTERNAL_GENERAL_USER, LOCALSTORAGE } from '@/utils/constants/AppConstants';
import { INTERNAL_COMPANY_ID } from '@/utils/constants/account';
import { parseJson } from '@/utils/helpers/globalHelper';
import { getRoles, getTravelTypes } from '@/utils/services/UserApiService';

interface IProjectStore {
  projectDetails: { data: IProjectExternal | null };
  userRoles: {
    loading: boolean;
    data: any[];
  };
  roleCompany: {
    data: any[];
  };
  travelTypes: {
    data: ITravelType[];
  };
}

const nameSpace = 'projectV2';

// Define the initial state using that type
const initialState: IProjectStore = {
  projectDetails: { data: null },
  /**
   * @description assets for project form
   */
  userRoles: {
    loading: false,
    data: []
  },
  roleCompany: {
    data: []
  },
  travelTypes: {
    data: []
  }
};

export const projectSlice = createSlice({
  name: nameSpace,
  initialState,
  reducers: {
    setProjectDetails: (state, action) => {
      state.projectDetails = action.payload;
    },
    cleanupProjectDetails: (state) => {
      state.projectDetails = initialState.projectDetails;
    },
    setTravelTypes: (state, action) => {
      state.travelTypes.data = action.payload;
    },
    cleanupTravelTypes: (state) => {
      state.travelTypes = initialState.travelTypes;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserRoles.pending, (state) => {
      state.userRoles.loading = true;
    });
    builder.addCase(fetchUserRoles.rejected, (state, action) => {
      state.userRoles.data = [];
      state.userRoles.loading = false;
      state.roleCompany.data = [];
    });
    builder.addCase(fetchUserRoles.fulfilled, (state, action) => {
      state.userRoles.data = action.payload;
      state.userRoles.loading = false;
      const user = parseJson(localStorage.getItem(LOCALSTORAGE.USER) ?? '');
      if (user?.organizationId) {
        const roles = action.payload.filter((role: any) =>
          user?.organizationId === INTERNAL_COMPANY_ID
            ? [INTERNAL_ADMIN, INTERNAL_GENERAL_USER].includes(role.type)
            : [EXTERNAL_ADMIN, EXTERNAL_GENERAL_USER].includes(role.type)
        );
        state.roleCompany.data = roles;
      } else {
        state.roleCompany.data = action.payload;
      }
    });

    // fetch travel types
    builder.addCase(fetchTravelTypes.fulfilled, (state, action) => {
      state.travelTypes.data = action.payload?.map((item: any) => ({ ...item, label: item.name, value: item.id }));
    });
  }
});

export const fetchUserRoles = createAsyncThunk(`${nameSpace}/user-roles`, async () => {
  try {
    const { data: responseRoles } = await getRoles();
    const mapRolesByUser = (responseRoles || []).filter((role: { code: string }) =>
      [EXTERNAL_ADMIN, EXTERNAL_GENERAL_USER, INTERNAL_ADMIN, INTERNAL_GENERAL_USER].includes(role?.code)
    );
    const roles = mapRolesByUser?.map((role: any) => {
      return {
        value: role?.id,
        label: i18n.t(`account_list:filter:roles:${String(role.code).toLowerCase()}`),
        type: role?.code
      };
    });
    return roles;
  } catch (error) {}
});

export const fetchTravelTypes = createAsyncThunk(`${nameSpace}/travel-types`, async () => {
  try {
    const { data: responseTravelTypes } = await getTravelTypes();
    return responseTravelTypes;
  } catch (error) {}
});
export const { setProjectDetails, cleanupProjectDetails, setTravelTypes, cleanupTravelTypes } = projectSlice.actions;
export { nameSpace };

export default projectSlice.reducer;
