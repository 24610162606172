import { AxiosResponse } from 'axios';

import { axiosInstance } from '@/config/http';

const endpoint = '/prj/inquiries';

interface IInquiryResponses {
  isSuccess: boolean;
  message: string;
  errorCode: string;
  detailCode: string;
  traceId: string;
  fields: IField[];
}
interface IField {
  name: string;
  errorCode: string;
}
interface ISubmitInqParams {
  emails: string;
  phoneNumber: string;
  content: string;
}
export const submitInquiry = async (data: ISubmitInqParams): Promise<AxiosResponse<IInquiryResponses, any>> => {
  return axiosInstance.post(endpoint, data);
};
