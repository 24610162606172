import AppTooltip from '../app-tooltip/AppTooltip';

interface IAppLabel {
  label: string | null;
  title: string | undefined;
  hasTooltip?: boolean;
  isBreakWork?: boolean;
  isHeaderProject?: boolean;
}

const AppLabel = (props: IAppLabel) => {
  const { label, title, hasTooltip, isBreakWork } = props;

  return (
    <>
      {hasTooltip ? (
        <div className='flex items-center w-full leading-[160%]'>
          <p className={`flex-none text-14 font-normal !m-0 min-w-[180px] text-[#12212E]`}>{`${label} :`}</p>
          <AppTooltip className='ml-2 truncate text-[#12212E] font-bold' title={title}>
            {title}
          </AppTooltip>
        </div>
      ) : (
        <p className={`text-14 font-normal !m-0 break-words leading-[160%] text-[#12212E]`} style={{ fontWeight: '500' }}>
          {`${label} : `} <span className={`text-[#12212E] font-bold ${isBreakWork ? 'block' : ''}`}>{title}</span>
        </p>
      )}
    </>
  );
};

export default AppLabel;
