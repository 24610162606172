import { useAppSelector } from '@/hooks';
import { DefaultOptionType } from 'antd/es/select';
import { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BaseButton } from '@/components/base-button/BaseButton';
import { FormInputDate } from '@/components/form-input-date/FormInputDate';
import { FormSelect } from '@/components/form-select/FormSelect';

import useFetch from '@/hooks/useFetch';
import useOptionsGlobal from '@/hooks/useOptionsGlobal';

import { DataInput } from '@/utils/helpers/common';
import { convertDataToOptions } from '@/utils/helpers/projectList';
import { Ii18n } from '@/utils/interfaces/i18n';
import { ICategory } from '@/utils/interfaces/template';

import { DEFAULT_FILTER_FORM } from './constant';

interface IFilterProps {
  defaultValues: any;
  onSubmit: (values: FieldValues) => void;
  onReset?: () => void;
}

const PrimaryFormFilter = ({ defaultValues, onSubmit, onReset }: IFilterProps) => {
  const form = useForm({
    defaultValues: {
      ...defaultValues,
      updatedDateFrom: DataInput.switchFormatDate(defaultValues.updatedDateFrom) as Dayjs,
      updatedDateTo: DataInput.switchFormatDate(defaultValues.updatedDateTo) as Dayjs
    }
  });
  const { t }: Ii18n = useTranslation();
  const { customCountryOptions, filterOption } = useOptionsGlobal();
  const [categoryOptions, setCategoryOptions] = useState<DefaultOptionType[]>([]);
  const watchCountryId = form.watch('countryId');
  const updatedDateTo = form.watch('updatedDateTo');
  const updatedDateFrom = form.watch('updatedDateFrom');
  const countries = useAppSelector((state) => state?.global?.countries);
  const processPatterns = useAppSelector((state) => state.global.processPatterns);
  const { data: asignmentFormRespone } = useFetch<any>('/mst/forms', 'GET');

  useEffect(() => {
    const countriesFilter = countries.filter((country) => watchCountryId === country.id);
    let categories: any[] = [];
    categories = countriesFilter.map((item: any) => {
      return {
        ...item,
        title: item.countryName,
        label: <span className='font-bold text-black'>{item.countryName}</span>,
        options: item?.categories?.map((category: ICategory) => ({
          label: category.code.toUpperCase(),
          value: category.id
        }))
      };
    });
    setCategoryOptions(categories);
  }, [watchCountryId]);
  const handleReset = () => {
    form.reset(DEFAULT_FILTER_FORM.primary);
  };
  const disableResetBtn = () => {
    return !Object.values(form.getValues()).find(Boolean);
  };
  return (
    <>
      <FormProvider {...form}>
        <div className='p-4 w-[600px]'>
          <h3 className='text-xl font-semibold'>{t('project:list:filter_btn_label')}</h3>
          <div className='item mb-5'>
            <FormSelect
              name='countryId'
              label={t('template_layout:country_name')}
              options={customCountryOptions}
              placeholder={String(t('project:add:please_select_field', { field: t('template_layout:country_name') }))}
              filterOption={filterOption}
              handleChange={() => {
                form.setValue('categoryId', null);
              }}
            />
          </div>
          <div className='item mb-5'>
            <FormSelect
              name='formId'
              label={t('template_layout:form')}
              options={(asignmentFormRespone?.data ?? []).map((item: any) => convertDataToOptions(item, { id: 'value', name: 'label' }))}
              placeholder={String(t('project:add:please_select_field', { field: t('template_layout:form') }))}
            />
          </div>
          <div className='item mb-5'>
            <FormSelect
              name='categoryId'
              label={t('project:filter:visa_category:label')}
              options={categoryOptions}
              disabled={!watchCountryId}
              placeholder={String(t('project:add:please_select_field', { field: t('project:filter:visa_category:label') }))}
            />
          </div>
          <div className='item mb-5'>
            <FormSelect
              name='patternId'
              label={t('template_layout:procedual_process')}
              options={(processPatterns ?? []).map((item: any) => convertDataToOptions(item, { id: 'value', name: 'label' }))}
              placeholder={String(t('project:add:please_select_field', { field: t('template_layout:procedual_process') }))}
            />
          </div>
          <div className='mb-3 flex items-center justify-between gap-4'>
            <div className='w-[262px]'>
              <FormInputDate
                name='updatedDateFrom'
                labelTx='template:update_date'
                placeholder={t('placeholder:date')}
                disabledDate={(d) => {
                  return Boolean(updatedDateTo && d.startOf('day').isAfter(updatedDateTo));
                }}
              />
            </div>
            <span className='w-[18px] h-[2px] mt-[35px] bg-[#525a68]'></span>
            <div className='w-[262px]'>
              <FormInputDate
                name='updatedDateTo'
                label='&nbsp;'
                placeholder={t('placeholder:date')}
                disabledDate={(d) => {
                  return Boolean(updatedDateFrom && d.endOf('day').isBefore(updatedDateFrom));
                }}
              />
            </div>
          </div>
          <div className='flex justify-end space-x-2'>
            <div className='w-[120px]'>
              <BaseButton onClick={handleReset} type='tertiary' size='medium' disabled={disableResetBtn()}>
                {t('project:filter:btn_reset')}
              </BaseButton>
            </div>
            <div className='w-[120px]'>
              <BaseButton onClick={form.handleSubmit(onSubmit)} type='primary' size='medium'>
                <span>{t('project:filter:btn_aply')} </span>
              </BaseButton>
            </div>
          </div>
        </div>
      </FormProvider>
    </>
  );
};

export default PrimaryFormFilter;
