import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BaseButton } from '@/components/base-button/BaseButton';

interface IHeaderProps {
  onAccept: () => void;
  onCancel: () => void;
}

const Headers = ({ onAccept, onCancel }: IHeaderProps) => {
  const { t } = useTranslation();
  const form = useFormContext();
  return (
    <div className='flex items-center justify-between mb-[12px]'>
      <h1 className='my-0 font-bold title-24'>{t('inquiry:header')}</h1>
      <div className='flex gap-4'>
        <BaseButton onClick={onCancel} type='tertiary' size='medium'>
          {t('common:button:cancel')}
        </BaseButton>
        <BaseButton onClick={onAccept} disabled={form.formState.isSubmitting} type='primary' size='medium'>
          {t('common:button:send')}
        </BaseButton>
      </div>
    </div>
  );
};

export default Headers;
