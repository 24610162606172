import { Select, SelectProps } from 'antd';
import classNames from 'classnames';
import React, { ReactComponentElement, useState } from 'react';
import { useController } from 'react-hook-form';

import { ErrorInput } from '@/components/error-input/ErrorInput';

import { useMessageYupTranslation } from '@/hooks/useI18n';

import './AutoCompleteSelection.scss';

export type IValue = string | number;
export interface ISelectionProps {
  defaultValue?: IValue[];
  onChange: (values: IValue[]) => void;
  value?: IValue[];
  placeholder?: string;
  tagRender?: SelectProps['tagRender'];
}

export const Selection = (props: ISelectionProps) => {
  const { value, onChange, defaultValue, placeholder, tagRender } = props;
  return (
    <Select
      defaultValue={defaultValue}
      value={value}
      mode='tags'
      tagRender={tagRender}
      placeholder={placeholder}
      showSearch={false}
      open={false}
      suffixIcon={null}
      onChange={onChange}
    />
  );
};

export interface IProps extends Omit<ISelectionProps, 'onChange'> {
  Label?: ReactComponentElement<any, any>;
  name?: string;
  showError?: boolean;
  onChange?: (values: IValue[]) => boolean;
}
const AutoCompleteSelection = (props: IProps) => {
  const { defaultValue, placeholder, Label, name, showError, tagRender, onChange } = props;
  const [selected, setSelected] = useState<IValue[]>(defaultValue ?? []);
  const {
    field,
    fieldState: { error }
  } = useController({
    name: name ?? '',
    defaultValue
  });
  const msgError = useMessageYupTranslation(error?.message);

  const handleOnChange = (values: IValue[]) => {
    const next = onChange ? onChange(values) : true;
    if (!next) return;
    setSelected(values);
    field.onChange(values);
  };

  const isError = error !== undefined && showError;
  return (
    <div className={classNames('auto-complete-selection-wrapper w-full', isError && 'error')}>
      <div className='mb-2 flex gap-1 items-center'>{Label}</div>
      <Selection value={selected} placeholder={placeholder} onChange={handleOnChange} tagRender={tagRender} />
      {isError && <ErrorInput error={msgError} />}
    </div>
  );
};

export default AutoCompleteSelection;
