import { Tooltip } from 'antd';
import React, { useState } from 'react';
import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import usePrevious from '@/hooks/usePrevious';

import { ISelectOption } from '@/utils/interfaces/form';

import { FormSelect, IFormSelect } from './FormSelect';

import './FormMultipleSelect.scss';

export interface IConfirmCallbackParams<T> {
  removed: T[];
  added: T[];
  original: T[];
}
type TagRenderFunctionType = IFormSelect['tagRender'] extends (...args: any[]) => any ? IFormSelect['tagRender'] : any;
export interface IFormMultipleSelectProps<T> extends IFormSelect {
  placeholder?: string;
  confirmations?: {
    condition: (params: IConfirmCallbackParams<T>) => Promise<'confirm' | 'cancel' | 'close' | string | undefined>;
    onConfirm?: (params: IConfirmCallbackParams<T>) => void;
    onCancel?: (props?: TagRenderFunctionType['arguments']) => void;
  };
  onChange?: (value: T[]) => void;
}

const FormMultipleSelect = <T extends ISelectOption>({ placeholder, name, confirmations, onChange, ...resProps }: IFormMultipleSelectProps<T>) => {
  const { t } = useTranslation();
  const { field } = useController<Record<string, T[]>, string>({
    name,
    control: resProps.control as Control<Record<string, T[]>> | undefined
  });
  const [isOpen, setIsOpen] = useState(false);
  const previousValue = usePrevious<T[]>(field.value as T[], !isOpen);

  const addRecord = (records: T[]) => {
    const originValues = field.value ?? [];
    const newValues = [...originValues, ...records];
    field.onChange(newValues);
  };

  const removedRecord = (id: string) => {
    const originValues = field.value ?? [];
    const newValues = originValues.filter(({ value }) => value !== id);
    field.onChange(newValues);
  };

  const handleCloseTag: IFormSelect['preTagClose'] = async (props) => {
    if (!confirmations) {
      props.onClose();
      return;
    }
    const { condition, onConfirm, onCancel } = confirmations;
    const originValues = (field.value as T[]) ?? [];
    const removed = originValues.filter(({ value }) => value === props.value);
    const args = { removed: removed ? removed : [], added: [], original: originValues };
    const response = await condition(args);
    if (response === 'confirm') {
      onConfirm ? onConfirm(args) : removedRecord(props.value as string);
      props.onClose();
    } else {
      onCancel ? onCancel(props) : field.onChange(originValues);
      !onCancel && props.onClose();
    }
  };

  const handleOnBlur: React.FocusEventHandler<HTMLElement> = async (e) => {
    if (!confirmations) {
      resProps?.onBlur?.(e);
      return;
    }
    const { condition, onConfirm, onCancel } = confirmations;
    const currentValues = (field.value as T[]) ?? [];
    const originValues = previousValue ?? [];
    const omittedValues = originValues.filter(({ value }) => !currentValues.find((v) => v.value === value));
    const newValues = currentValues.filter(({ value }) => !originValues.find((v) => v.value === value));
    const args = { removed: omittedValues, added: newValues, original: originValues };
    if (!omittedValues.length && !newValues.length) return; // ignore if no change
    const response = await condition(args);
    if (response === 'confirm') {
      onConfirm ? onConfirm(args) : addRecord(newValues);
    } else {
      onCancel ? onCancel() : field.onChange(originValues);
    }
  };
  return (
    <div className='base-form-multiple-select'>
      <FormSelect
        allowClear={false}
        name={name}
        mode='multiple'
        labelInValue
        placeholder={placeholder ? t(placeholder) : ''}
        onChange={onChange}
        showCloseItem
        maxTagCount={'responsive'}
        maxTagPlaceholder={(omittedValues) => (
          <Tooltip overlayStyle={{ pointerEvents: 'none' }} title={omittedValues.map(({ label }) => label).join(', ')}>
            <div className='custom-tooltip'>...</div>
          </Tooltip>
        )}
        preTagClose={handleCloseTag}
        onBlur={handleOnBlur}
        onDropdownVisibleChange={(open) => {
          setIsOpen(open);
          resProps?.onDropdownVisibleChange?.(open);
        }}
        {...resProps}
      />
    </div>
  );
};

export default FormMultipleSelect;
