import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { QuestionType } from '../../utils/interfaces/template';

// Define the initial state using that type
const initialState: any = {
  questionTypes: [],
  defaultQuestionType: '',
  questionRespondents: []
};

export const masterSpaceName = 'master';

export const masterSlice = createSlice({
  name: masterSpaceName,
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setQuestionTypes: (state, action: PayloadAction<any>) => {
      state.questionTypes = action.payload;
    },
    setQuestionRespondents: (state, action: PayloadAction<any>) => {
      state.questionRespondents = action.payload;
    },
    setDefaultQuestionType: (state) => {
      state.defaultQuestionType = state.questionTypes.find((item: any) => item.code === QuestionType.DROPDOWN)?.id || '';
    }
  }
});

export const { setQuestionTypes, setQuestionRespondents, setDefaultQuestionType } = masterSlice.actions;

export default masterSlice.reducer;
