import { UPLOAD_STATUS } from '@/utils/constants/AppConstants';
import { IUserProfile } from '@/utils/interfaces/user';

import { SCENARIO_TYPES } from '../../contants';
import { DEFAULT_FORM_OBSERVER } from '../../contants/projectExternalProxy';
import { DEFAULT_SCENARIO_D_STEP_2 } from './contants';

export const convertResponseForDType = (data: any, user?: IUserProfile | null) => {
  const formDefault = { ...DEFAULT_FORM_OBSERVER, scenarioType: SCENARIO_TYPES.SCENARIO_D };
  if (!data) {
    formDefault.steps = [
      {
        ...DEFAULT_SCENARIO_D_STEP_2,
        userName: user?.name,
        email: user?.email,
        companyName: user?.organizationName
      }
    ];
  } else {
    const result = parseHtmlString(data?.note);
    const phoneNumber = result?.['value-phone-scenario-d'] || '';
    const requestContent = result?.['value-note-scenario-d'] || '';
    const requestDetail = (result?.['value-request-detail-scenario-d'] || '')
      .split('、')
      .map((item) => item.trim())
      .filter(Boolean);
    const noteSpecial = result?.['value-note-special-scenario-d'] || '';
    const projectAttachments = data?.attachments.map((item: any) => ({
      ...item,
      status: UPLOAD_STATUS.DONE
    }));
    const detail = {
      ...data,
      phoneNumber,
      requestContent,
      projectAttachments,
      requestDetail,
      noteSpecial,
      countryId: data?.projectAssignmentInfo.countryId,
      userName: user?.name,
      email: user?.email,
      companyName: user?.organizationName
    };
    formDefault.steps = [detail];
    formDefault.selectedStep = 2;
  }

  return formDefault;
};

export const parseHtmlString = (htmlString: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const phoneNumber = doc.getElementById('value-phone-scenario-d')?.textContent ?? '';
  const requestContent = doc.getElementById('value-note-scenario-d')?.innerHTML ?? '';
  const requestDetail = doc.getElementById('value-request-detail-scenario-d')?.innerHTML ?? '';
  const noteSpecial = doc.getElementById('value-note-special-scenario-d')?.innerHTML ?? '';
  return {
    'value-phone-scenario-d': phoneNumber,
    'value-note-scenario-d': requestContent,
    'value-request-detail-scenario-d': requestDetail,
    'value-note-special-scenario-d': noteSpecial
  };
};
