import { useAppSelector } from '@/hooks';
import { useTranslation } from 'react-i18next';

import { IStepTwo } from '@/pages/project-management/add-edit/models/scenarioB';

import { BaseCard } from '@/components/base-card/BaseCard';

import { DataViewer } from '@/utils/helpers/common';

import CardInfo from './CardInfo';

interface ITravelInfoProps {
  data: IStepTwo;
}
const TravelInfo = ({ data }: ITravelInfoProps) => {
  const { travelTypeId } = data ?? {};
  const { t } = useTranslation();
  const travelTypes = useAppSelector((state) => state.projectV2.travelTypes.data);
  const matched = travelTypes.find((travel) => travel.value === travelTypeId);
  const columns = [
    {
      items: [{ title: DataViewer.display(matched?.label), label: 'basic_information:travel_type' }]
    }
  ];

  return (
    <BaseCard title={t('basic_information:travel_type')} total={1} questions={travelTypeId ? 1 : 0} className='scenario-b-card'>
      <CardInfo columns={columns} />
    </BaseCard>
  );
};

export default TravelInfo;
