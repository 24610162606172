import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { IOptions, ISupportType } from '@/pages/project-management/add-edit/models';

import { BaseCollapse } from '@/components/base-collapse/BaseCollapse';
import { FormInputSelect } from '@/components/form-input-select/FormInputSelect';

import { DISABLE_ASSIGNEE, SUPPORT_TYPE_NAME } from '@/utils/constants/project';
import { getRequestTypeList, getSupportTypeList } from '@/utils/services/ProjectApiService';

import { PROJECT_DEFAULT_VALUE } from '../contants';

type Props = {
  formMethod: any;
  setShowLocalRepresentative: Function;
  setDisabledAssignee: Function;
};

const RequestDetailComponent = (props: Props) => {
  const { formMethod, setShowLocalRepresentative, setDisabledAssignee } = props;
  const [supportTypeOptions, setSupportTypeOptions] = useState<IOptions[]>([]);
  const [requestTypeOptions, setRequestTypeOptions] = useState<IOptions[]>([]);
  const watchFields = formMethod.watch(['requestTypeId', 'supportTypeId']);
  const watchFieldsValid = watchFields.filter((field: any) => field);
  const { t } = useTranslation();
  useEffect(() => {
    getRequestTypeData();
    getSupportTypeData();
  }, []);

  useEffect(() => {
    const objectRequestType = requestTypeOptions?.find((req) => req.value === formMethod.getValues('requestTypeId'));
    const isDisabledAssignee = DISABLE_ASSIGNEE.includes(String(objectRequestType?.label));
    setDisabledAssignee(isDisabledAssignee);
  }, [formMethod.getValues('requestTypeId')]);

  useEffect(() => {
    if (!supportTypeOptions?.length) {
      return;
    }
    const objectSupportType = supportTypeOptions?.find((sp) => sp.value === formMethod.getValues('supportTypeId'));
    const isSupportTypeNameFull = objectSupportType?.label === SUPPORT_TYPE_NAME.FULL;
    if (!isSupportTypeNameFull) {
      formMethod.setValue('projectLocalSupporters', PROJECT_DEFAULT_VALUE.projectLocalSupporters);
      formMethod.clearErrors('projectLocalSupporters');
    }
    setShowLocalRepresentative(isSupportTypeNameFull);
  }, [supportTypeOptions, formMethod.getValues('supportTypeId')]);

  const getSupportTypeData = async () => {
    try {
      const res = await getSupportTypeList();
      if (res?.data) {
        const dataTemp = res.data.map((item: ISupportType) => {
          return {
            label: item.name,
            value: item.id,
            id: item.id
          };
        });
        setSupportTypeOptions(dataTemp);
      }
    } catch (error) {}
  };

  const getRequestTypeData = async () => {
    try {
      const res = await getRequestTypeList();
      if (res?.data) {
        const dataTemp = res.data.map((item: IOptions) => {
          return {
            label: item.name,
            value: item.id,
            id: item.id
          };
        });
        setRequestTypeOptions(dataTemp);
      }
    } catch (error) {}
  };

  return (
    <BaseCollapse id='request_detail' title={t('project:add:request_detail:request_detail')} total={2} questions={watchFieldsValid.length}>
      <Row gutter={16}>
        <Col span={12}>
          <FormInputSelect
            name='requestTypeId'
            label={t('project:add:request_detail:request_type')}
            placeholder={String(t('project:add:please_select_field', { field: t('project:add:request_detail:request_type') }))}
            options={requestTypeOptions}
          />
        </Col>
        <Col span={12}>
          <FormInputSelect
            name='supportTypeId'
            label={String(t('project:add:request_detail:support_type'))}
            placeholder={String(t('project:add:please_select_field', { field: t('project:add:request_detail:support_type') }))}
            options={supportTypeOptions}
          />
        </Col>
      </Row>
    </BaseCollapse>
  );
};

export default RequestDetailComponent;
