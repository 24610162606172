import Quill from 'quill';

import { CUSTOM_EVENTS } from '../constants';
import { calculateCharacters } from '../utils';

const Module = Quill.import('core/module');

export default class Counter extends Module {
  quill: Quill;
  options: any;
  container?: React.MutableRefObject<HTMLElement>['current'] | HTMLElement;
  limit?: string;
  callback: Function = () => {};

  constructor(quill: Quill, options: any) {
    super(quill, options);
    this.quill = quill;
    this.options = options;

    if (!options.container) {
      console.warn('Counter module must has container ref');
      return;
    }
    this.container = typeof options.container === 'string' ? document.querySelector(options.container) : options.container.current;
    if (typeof options.limit === 'number') this.limit = String(options.limit);
    if (typeof options.callback === 'function') this.callback = options.callback;
    quill.on('text-change', this.update.bind(this));
    if (quill.root) {
      quill.root.addEventListener('paste', this.update.bind(this));
      quill.root.addEventListener(CUSTOM_EVENTS.DEFAULT_TEXT_CHANGE, this.update.bind(this));
    }
    this.update();
  }

  calculate() {
    return calculateCharacters(this.quill, this.options);
  }

  update() {
    const length = this.calculate();
    const content = this.limit !== undefined ? `${length}/${this.limit}` : length.toString();
    if (this.container) this.container.textContent = content;
    this.callback(length);
  }
}
