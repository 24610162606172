import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../hooks';
import useAuthorization from '../../hooks/useAuthorization';
import { setLoadingPage, setUserProfile } from '../../redux/globalReducer';
import { LOCALSTORAGE } from '../../utils/constants/AppConstants';
import { getUserInfo } from '../../utils/services/AuthenticationApiService';
import FormMyProfile from './components/FormMyProfile';

import './MyProfile.scss';

const MyProfile = () => {
  const { t } = useTranslation();
  const user = useAppSelector((x) => x.global.userProfile);
  const { accessToken } = useAuthorization();
  const dispatch = useAppDispatch();

  const formMethod = useForm({
    mode: 'all',
    shouldUnregister: false
  });

  useEffect(() => {
    getDataUserProfile();
  }, []);

  // Get data user info
  const getDataUserProfile = async () => {
    try {
      dispatch(setLoadingPage(true));
      const { data } = await getUserInfo(accessToken);
      const userProfile = {
        role: data?.rights[0],
        ...data
      };
      localStorage.setItem(LOCALSTORAGE.USER, JSON.stringify(userProfile));
      dispatch(setUserProfile(userProfile));
      formMethod.setValue('userName', userProfile?.name || '');
      formMethod.setValue('companyName', userProfile?.organizationName || '');
      formMethod.setValue('emailAddress', userProfile?.email || '');
      formMethod.setValue('role', userProfile?.role ? t(`my_profile:roles:${userProfile?.role.toLowerCase()}`) : '');
      dispatch(setLoadingPage(false));
    } catch (error) {
      dispatch(setLoadingPage(false));
    }
  };

  return (
    <div className='my-profile'>
      <div>
        <div className='flex gap-2 items-center mb-5'>
          <h1 className='text-32 font-bold text-[#12212E] leading-[120%] !my-0'>{t('my_profile:title_header')}</h1>
          <div />
        </div>
        <div className='container'>
          <FormProvider {...formMethod}>
            <FormMyProfile isEdit={false} user={user || null} />
          </FormProvider>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
