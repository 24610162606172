import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IStepSix } from '@/pages/project-management/add-edit/models/scenarioB';

import { BaseCard } from '@/components/base-card/BaseCard';
import AppTooltip from '@components/app-tooltip/AppTooltip';
import { IconDownload } from '@components/icon-svg/IconSvg';

import { DataViewer } from '@/utils/helpers/common';
import { handleDownloadSingleFile } from '@/utils/helpers/fileHelper';
import { decodeFileName } from '@/utils/helpers/globalHelper';
import { IProjectAttachment } from '@/utils/interfaces/Project';

import IconPaperclip from '@assets/icons/Paperclip.svg';

import CardInfo from './CardInfo';

const AttachmentFiles = ({ data }: { data: Partial<IProjectAttachment>[] }) => {
  if (!data?.length) return '--';
  return (
    <>
      {data.map((file: any, i: number) => (
        <div key={i} className='flex justify-start items-center'>
          <p className='mr-[8px] w-[18px]'>{i + 1}</p>
          <div className='w-[500px] h-[38px] grow flex justify-start items-center border border-solid border-t-0	border-r-0	border-l-0 border-b-gray2'>
            <div className='flex justify-center items-center'>
              <IconPaperclip width={18} height={18} />
            </div>
            <AppTooltip className='grow px-[10px] truncate' title={decodeFileName(file.name)}>
              <span
                className='cursor-pointer'
                onClick={() => {
                  return handleDownloadSingleFile(file.blobPath as string, file.name);
                }}
              >
                {decodeFileName(file.name)}
              </span>
            </AppTooltip>
            <div className='flex justify-center items-center'>
              <Button
                type='text'
                icon={<IconDownload className='h-[20px] w-[20px] download hover:cursor-pointer' />}
                onClick={() => {
                  return handleDownloadSingleFile(file.blobPath as string, file.name);
                }}
              />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

interface IAdditionalInfoProps {
  data: IStepSix;
}
const AdditionalInfo = ({ data }: IAdditionalInfoProps) => {
  const { t } = useTranslation();
  const { note, projectAttachments } = data ?? {};

  const memoInfo = [
    {
      items: [
        {
          title: <div dangerouslySetInnerHTML={{ __html: DataViewer.displayAsSanitizeHTML(note) }} />,
          label: 'basic_information:special_note'
        }
      ],
      colSpan: 24
    }
  ];

  const attachmentInfo = [
    {
      items: [
        {
          title: <AttachmentFiles data={projectAttachments} />,
          label: projectAttachments?.length ? '' : 'basic_information:attachment_file'
        }
      ]
    }
  ];
  return (
    <>
      <BaseCard title={t('project:scenario_b:special_notes')} total={1} questions={DataViewer.isEmptyHTML(note) ? 0 : 1} className='scenario-b-card'>
        <CardInfo columns={memoInfo} />
      </BaseCard>
      <BaseCard className='mt-3 scenario-b-card' title={t('basic_information:attachment_file')}>
        <CardInfo columns={attachmentInfo} customTitle={projectAttachments?.length ? 'body-400' : 'body-700'} />
      </BaseCard>
    </>
  );
};

export default AdditionalInfo;
