import { get } from 'lodash';
import * as yup from 'yup';

import {
  ERROR_CODE,
  NOTE_FILED_MAX_LENGTH,
  TEXT_FIELD_MAX_LENGTH,
  TEXT_FIELD_MIN_LENGTH,
  TEXT_FIELD_SHORT_MAX_LENGTH
} from '@/utils/constants/AppConstants';
import { stringifyObjectValidateYup } from '@/utils/method';
import { validationEmail } from '@/utils/services/ProjectApiService';

interface ICustomError {
  type: string;
  message: string;
}

export class CustomError extends Error {
  type: string;

  constructor({ type, message }: ICustomError) {
    super(message);
    this.type = type;

    Object.setPrototypeOf(this, CustomError.prototype);
  }
}

const companyHaveRole = async (value: string, ctx: any): Promise<boolean> => {
  const fieldRejected = get(ctx, `parent.rejected`);
  if (fieldRejected && !value) {
    return false;
  }

  return true;
};

export const FormStakeholder = yup.object().shape({
  projectStakeholders: yup.array().of(
    yup.object().shape({
      stakeholderEmail: yup
        .string()
        .required(
          stringifyObjectValidateYup({
            keyT: 'common:MSG_001_textbox',
            optionsTx: { field: 'stakeholder:label:email_address' }
          })
        )
        .max(
          TEXT_FIELD_MAX_LENGTH,
          stringifyObjectValidateYup({
            keyT: 'common:MSG_C_011',
            optionsTx: { field: 'project:add:project_stakeholders:email_address', limited: TEXT_FIELD_MAX_LENGTH }
          })
        )
        .fullEmail(
          stringifyObjectValidateYup({
            keyT: 'common:MSG_A_016',
            optionsTx: { field: 'project:add:project_stakeholders:email_address' }
          })
        )
        .test('email-validation', 'email invalid', async (value, ctx: any) => {
          const valueAccount = ctx.from[0]?.value;
          const allStatusFalse =
            !valueAccount.isNewAccount && !valueAccount.stakeholderStatus && !valueAccount.rejected && !valueAccount.waitCreateAccount;
          if (allStatusFalse) return true;

          const companyId = ctx.from[1]?.value?.applicantCompany?.value;
          const params = {
            emails: [value || ''],
            companyId
          };
          try {
            const res = await validationEmail(params);
            const errorCode = res?.data[0].errorCode;

            if (
              (errorCode === ERROR_CODE.ENTITY_REJECTED || errorCode === ERROR_CODE.USER_RESTRICT || errorCode === ERROR_CODE.USER_INACTIVE) &&
              ((ctx.from[0]?.value?.isNewAccount && ctx.from[0]?.value?.isPending && !ctx.from[0]?.value?.stakeholderStatus) ||
                (ctx.from[0]?.value?.isWorked && ctx.from[0]?.value?.rejected))
            )
              return true;
            if (errorCode === ERROR_CODE.ENTITY_REJECTED || errorCode === ERROR_CODE.ENTITY_NOT_FOUND) {
              throw new CustomError({ type: ERROR_CODE.ENTITY_NOT_FOUND, message: 'common:MSG_P_006' });
            }
            if (errorCode === ERROR_CODE.USER_RESTRICT) {
              throw new CustomError({ type: ERROR_CODE.USER_RESTRICT, message: 'common:MSG_P_033' });
            }
            if (errorCode === ERROR_CODE.USER_INACTIVE && !res?.data?.waitingCreate) {
              throw new CustomError({ type: ERROR_CODE.USER_INACTIVE, message: 'common:MSG_P_034' });
            }
            return true;
          } catch (error: any) {
            return ctx.createError({ type: error.type, message: error.message });
          }
        }),
      rejectReason: yup
        .string()
        .min(
          TEXT_FIELD_MIN_LENGTH,
          stringifyObjectValidateYup({
            keyT: 'common:MSG_001_textbox',
            optionsTx: { field: 'stakeholder:label:reason_remand:label', limited: TEXT_FIELD_MIN_LENGTH }
          })
        )
        .htmlStrippedMaxLength(
          NOTE_FILED_MAX_LENGTH,
          stringifyObjectValidateYup({
            keyT: 'common:MSG_C_011',
            optionsTx: { field: 'stakeholder:label:reason_remand:label', limited: NOTE_FILED_MAX_LENGTH }
          })
        )
        .test(
          'check-company',
          stringifyObjectValidateYup({
            keyT: 'common:MSG_001_textbox',
            optionsTx: { field: 'stakeholder:label:reason_remand:label' }
          }),
          (value, ctx) => companyHaveRole(String(value), ctx)
        )
    })
  )
});

export const FormCreateModal = yup.object().shape({
  stakeholderName: yup
    .string()
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: 'basic_information:username' }
      })
    )
    .notUrl(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_092',
        optionsTx: { field: 'basic_information:username' }
      })
    )
    .max(
      TEXT_FIELD_SHORT_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'basic_information:username', limited: TEXT_FIELD_SHORT_MAX_LENGTH }
      })
    ),
  stakeholderEmail: yup
    .string()
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: 'basic_information:email_address' }
      })
    )
    .max(
      TEXT_FIELD_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'basic_information:email_address', limited: TEXT_FIELD_MAX_LENGTH }
      })
    )
    .fullEmail(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_A_016',
        optionsTx: { field: 'basic_information:email_address' }
      })
    ),
  role: yup.object().required(
    stringifyObjectValidateYup({
      keyT: 'common:MSG_001_select',
      optionsTx: { field: 'account_list:role' }
    })
  )
});
