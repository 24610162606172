import { Checkbox } from 'antd';
import { CheckboxOptionType, CheckboxValueType, CheckboxGroupProps as ICheckboxGroupProps } from 'antd/es/checkbox/Group';
import classNames from 'classnames';
// Rest of the code
import React from 'react';

import './index.scss';

interface IUI {
  wrap?: string;
  item?: string;
}
// Rest of the code
export interface IBaseCheckboxProps extends Omit<ICheckboxGroupProps, 'onChange'> {
  readOnly?: boolean;
  options?: CheckboxOptionType[];
  isVertical?: boolean;
  onChange?: (checkedValues: CheckboxValueType[], options: CheckboxOptionType[]) => void;
  ui?: IUI;
}
const defaultUI: IUI = {
  wrap: 'flex',
  item: ''
};

const BaseCheckboxGroups: React.FC<IBaseCheckboxProps> = ({ readOnly, options, isVertical, ui = defaultUI, onChange, ...restProps }) => {
  const handleChange = (checkedValues: CheckboxValueType[]) => {
    onChange &&
      onChange(
        checkedValues,
        (options ?? []).filter((op) => checkedValues.includes(op.value))
      );
  };
  return (
    <Checkbox.Group className={classNames(ui.wrap, isVertical ? 'flex-col' : 'flex-row')} disabled={readOnly} onChange={handleChange} {...restProps}>
      {options?.map((op, index) => (
        <Checkbox key={index + 1} className={classNames(ui.item)} value={op.value}>
          {op.label}
        </Checkbox>
      ))}
    </Checkbox.Group>
  );
};

export default BaseCheckboxGroups;
