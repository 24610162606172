import { Radio, RadioChangeEvent, RadioGroupProps, Space } from 'antd';
// Rest of the code
import classNames from 'classnames';
import React, { useEffect } from 'react';

import BaseInput from '../base-input';
import MaskedInput, { IMaskInputProps } from '../mask-input';

// import styles from './index.module.scss';
import './index.scss';

// Rest of the code

export interface IRadioOption {
  label: string;
  value: string | number | boolean;
  couldChangeLabel?: boolean;
  labelInputConfig?: {
    maskConfigs?: IMaskInputProps;
    placeholder?: string;
    maxLength?: number;
    value?: string;
  };
}

export interface IBaseRadioProps extends Omit<RadioGroupProps, 'onChange'> {
  value?: string | number | boolean;
  options: IRadioOption[];
  radioClassName?: string;
  defaultValue?: string | number | boolean;
  readOnly?: boolean;
  onChange?: (value: string | number | boolean, option: IRadioOption) => void;
  preChange?: (val: any) => Promise<any>;
  itemMaxWidth?: number;
  isVertical?: boolean;
}

const BaseRadioGroups: React.FC<IBaseRadioProps> = ({
  options,
  className,
  readOnly,
  defaultValue,
  onChange,
  preChange,
  radioClassName,
  itemMaxWidth,
  isVertical,
  ...restProps
}) => {
  const [selectedValue, setSelectedValue] = React.useState(defaultValue);
  const [customOptions, setCustomOptions] = React.useState<IRadioOption[]>(options);

  const handleChange = async (e: RadioChangeEvent) => {
    try {
      if (readOnly) return;
      let newValue = e.target.value;
      if (preChange) newValue = await preChange(e.target.value);
      setSelectedValue(newValue);
      onChange && onChange(newValue, customOptions.find((option) => option.value === newValue) as IRadioOption);
    } catch (error) {}
  };

  useEffect(() => {
    setCustomOptions(options);
  }, [options]);

  const onChangeContentOfOption = (key: any, contentChanged: string) => {
    const newOptions = [...options];
    const changedIndex = newOptions.findIndex((option) => option.value === key);
    if (newOptions[changedIndex]?.labelInputConfig) {
      newOptions[changedIndex].labelInputConfig.value = contentChanged;
    }
    setCustomOptions(newOptions);
    onChange && onChange(newOptions[changedIndex].value, newOptions[changedIndex]);
  };

  const renderInput = (option: IRadioOption) => {
    if (option.couldChangeLabel) {
      return option?.labelInputConfig?.maskConfigs ? (
        <MaskedInput
          defaultValue={option.labelInputConfig?.value}
          hideBorder
          {...option?.labelInputConfig?.maskConfigs}
          labelInput={option?.label}
          placeholder={option?.labelInputConfig?.placeholder ?? '_____'}
          onChange={(val) => onChangeContentOfOption(option.value, val)}
        />
      ) : (
        <BaseInput
          defaultValue={option.labelInputConfig?.value}
          hideBorder
          labelInput={option?.label}
          placeholder={option?.labelInputConfig?.placeholder ?? '_____'}
          maxLength={option?.labelInputConfig?.maxLength ?? 128}
          onChange={(e) => onChangeContentOfOption(option.value, e.currentTarget.value ?? '')}
        />
      );
    }

    return option.label;
  };

  return (
    <Radio.Group className={classNames(className)} value={selectedValue} {...restProps} onChange={handleChange} buttonStyle='solid'>
      <Space direction={isVertical ? 'vertical' : 'horizontal'} className={classNames('flex', '!flex-wrap', 'gap-x-3')}>
        {customOptions.map((option) => (
          <Radio
            className={classNames('baseRadio', 'truncate', 'text-eclipse', radioClassName ?? '', itemMaxWidth ? `max-w-[${itemMaxWidth}px]` : '')}
            value={option.value}
            key={option.value.toString()}
          >
            {renderInput(option)}
          </Radio>
        ))}
      </Space>
    </Radio.Group>
  );
};

export default BaseRadioGroups;
