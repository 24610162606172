import { useTranslation } from 'react-i18next';

import { Ii18n } from '../../../utils/interfaces/i18n';
import { SearchEmtyData } from '../../icon-svg/IconSvg';

type EmptyDataProps = {
  emptyDataAlert?: React.ReactNode;
  showImgEmpty?: boolean;
  classEmptyCustom?: string;
};

enum EmptyDataMessage {
  NO_DATA = 'common:no_data'
}

const EmptyData = ({ emptyDataAlert, showImgEmpty = true, classEmptyCustom }: EmptyDataProps) => {
  const { t }: Ii18n = useTranslation();
  let className = `flex justify-center flex-col items-center py-[30px]`;

  return (
    <div className={[className, classEmptyCustom].join(' ')}>
      {emptyDataAlert ? (
        emptyDataAlert
      ) : (
        <div className='text-center'>
          {showImgEmpty && <SearchEmtyData />}
          <p className='body-2 text-gray4'>{t(EmptyDataMessage.NO_DATA)}</p>
        </div>
      )}
    </div>
  );
};

export default EmptyData;
