import { isNil } from 'lodash';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import EmptyTextData from '@/components/EmptyData/EmptyTextData';
import TableBase, { FetchColumnsType } from '@/components/common/table-base';
import CommonTag from '@/components/common/tag/CommonTag';
import { FormAttachment } from '@/components/form-attachment/FormAttachment';

import useAuthorization from '@/hooks/useAuthorization';

import { TYPE } from '@/utils/constants/AppConstants';
import { DataViewer } from '@/utils/helpers/common';

export default function TaskRequiredDocumentsView() {
  const { t } = useTranslation();
  const form = useFormContext();
  const { isExternalRole } = useAuthorization();
  const { fields: taskRequireDocuments } = useFieldArray({
    name: 'taskRequireDocuments',
    control: form.control
  });

  const attachmentColumn = (value: string, record: any, index: number) => {
    const taskRequireDocumentAttachmentFiles = record?.taskRequireDocumentAttachmentFiles || [];

    if (isExternalRole)
      return (
        <FormAttachment
          name={`taskRequireDocuments.${index}.taskRequireDocumentAttachmentFiles`}
          defaultValue={form.getValues(`taskRequireDocuments.${index}.taskRequireDocumentAttachmentFiles`)}
          className='!gap-[0]'
          type='button'
          mode='edit'
          labelButton='reference_material:upload'
        ></FormAttachment>
      );
    if (!taskRequireDocumentAttachmentFiles.length) return '--';

    return (
      <FormAttachment
        name={`taskRequireDocuments.${index}.taskRequireDocumentAttachmentFiles`}
        defaultValue={form.getValues(`taskRequireDocuments.${index}.taskRequireDocumentAttachmentFiles`)}
        className='!gap-[0]'
        type='button'
        mode='view'
        labelButton='reference_material:upload'
      ></FormAttachment>
    );
  };

  const columnsDocumentList: FetchColumnsType<any> = [
    {
      title: t('project:task_of_project:document_code'),
      key: 'displayOrder',
      width: 80,
      render: (_value: any, _record: any, index: number) => {
        return <div>{DataViewer.display(_record.displayOrder)}</div>;
      }
    },
    {
      title: t('project:task_of_project:document_name'),
      dataIndex: 'managementCode',
      width: 290,
      className: 'min-w-[290px]',
      render(_value: string, _record: any, index: number) {
        return <div className='block max-w-full w-fit whitespace-pre-wrap break-all'>{DataViewer.display(_record.managementCode)}</div>;
      }
    },
    {
      title: t('project:task_of_project:number_of_copies'),
      dataIndex: 'numberOfCopy',
      width: 60,
      render(_value: any, record: any, index: number) {
        return <span className='block max-w-full w-fit whitespace-pre-wrap break-all'>{DataViewer.display(record?.numberOfCopy)}</span>;
      }
    },
    {
      title: t('project:task_of_project:description'),
      dataIndex: 'notes',
      className: 'max-w-[290px]',
      ellipsis: false,
      render(_value: any, record: any) {
        return <div className='whitespace-pre-wrap' dangerouslySetInnerHTML={{ __html: DataViewer.displayAsSanitizeHTML(record?.note) }}></div>;
      }
    },
    {
      title: t('project:task_of_project:attachment_file'),
      dataIndex: 'attachment_file',
      key: 'attachment_file',
      render: attachmentColumn,
      width: 220
    },
    {
      title: t('project:task_of_project:file_status'),
      dataIndex: 'status',
      render(_value: any, record: any, index: number) {
        if (isExternalRole && !record.taskRequireDocumentAttachmentFiles.length && !record.isConfirm)
          return <CommonTag type={'default'} text={t('project:task_of_project:not_submitted')} />;
        if (record.taskRequireDocumentAttachmentFiles.length && !record.isConfirm)
          return <CommonTag type={'warning'} text={t('project:task_of_project:pending_verification')} />;
        if (isExternalRole && record.isConfirm) return <CommonTag type={'success'} text={t('project:task_of_project:submitted')} />;
        return <div>{record.isConfirm ? t('project:task_of_project:submitted') : t('project:task_of_project:not_submitted')}</div>;
      },
      width: 100
    },
    {
      title: t('project:task_of_project:confirmedBy'),
      dataIndex: 'confirmedBy',
      render(_value: any, record: any, index: number) {
        const nameConfirm = () => {
          if (isNil(record?.statusConfirm)) return null;
          return record?.statusConfirm ? record.nameConfirm : `(${t('account_list:status_account:inactive')}) ${record.nameConfirm}`;
        };
        return (
          <div className='block max-w-full w-fit whitespace-pre-wrap break-all'>{DataViewer.display(record.isConfirm ? nameConfirm() : null)}</div>
        );
      },
      width: 100
    },
    {
      title: t('project:task_of_project:confirmDate'),
      dataIndex: 'dateConfirm',
      render(_value: any, record: any, index: number) {
        return <div>{DataViewer.displayTime(record.isConfirm ? record.dateConfirm : null)}</div>;
      },
      width: 100
    }
  ];
  return (
    <div>
      <TableBase
        className='w-full mt-[8px] required-task-detail border-no-data'
        showAddLine={false}
        columns={columnsDocumentList}
        showDownload={false}
        pagination={false}
        deleteConfirmPopup={{
          type: TYPE.DISCARD,
          title: t('common:MSG_029.1_title', { item: t('basic_information:project') }) ?? '',
          msg:
            `${t('common:MSG_029.1_description', { item: t('basic_information:project') })} ${t('common:MSG_029_description2', {
              item: t('basic_information:project')
            })}` ?? ''
        }}
        dataSource={taskRequireDocuments}
        rowSelection={null}
        locale={{ emptyText: <EmptyTextData className='text-center mt-4' text={'common:MSG_093'} /> }}
      />
    </div>
  );
}
