import { Col, Row } from 'antd';
import { Dayjs } from 'dayjs';
import React from 'react';
import { FormProvider, SubmitHandler, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BaseButton } from '@/components/base-button/BaseButton';
import FormBaseFilter from '@/components/base-filter';
import { FormInputDate } from '@/components/form-input-date/FormInputDate';

import { DataInput, DataViewer } from '@/utils/helpers/common';
import { formatDateTime } from '@/utils/helpers/globalHelper';

import { DEFAULT_FILTER_FORM } from '../constants';
import { IFilterForm } from '../models';

interface IFormPopoverFilterProps {
  defaultValues: IFilterForm['primary'];
  onSubmit: SubmitHandler<IFilterForm['primary']>;
}

interface IFormConvertedValues {
  updatedDateFrom?: Dayjs;
  updatedDateTo?: Dayjs;
}
const FormPopoverFilter = ({ defaultValues, onSubmit }: IFormPopoverFilterProps) => {
  const { t } = useTranslation();
  const form = useForm<IFormConvertedValues>({
    defaultValues: {
      ...defaultValues,
      updatedDateFrom: DataInput.switchFormatDate(defaultValues.updatedDateFrom) as Dayjs,
      updatedDateTo: DataInput.switchFormatDate(defaultValues.updatedDateTo) as Dayjs
    }
  });
  const updatedDateTo = form.watch('updatedDateTo');
  const updatedDateFrom = form.watch('updatedDateFrom');

  const handleOnSubmit = (values: IFormConvertedValues) => {
    const { updatedDateFrom, updatedDateTo } = values ?? {};
    const convertedValues = {
      ...values,
      updatedDateFrom: DataInput.switchFormatDate(updatedDateFrom),
      updatedDateTo: DataInput.switchFormatDate(updatedDateTo)
    };
    return onSubmit(convertedValues as any);
  };

  const onReset = () => {
    form.reset(DEFAULT_FILTER_FORM.primary);
  };

  const disableResetBtn = () => {
    return !Object.values(form.getValues()).find(Boolean);
  };

  return (
    <FormProvider {...form}>
      <div className='p-[20px] w-[680px]'>
        <h3 className='title-20 mt-0 mb-[12px] font-semibold'>{t('master_data:company:filter_popover_title')}</h3>
        <div className='row mb-[12px]'>
          <Row className='flex justify-between'>
            <Col className='w-[292px]'>
              <FormInputDate
                name='updatedDateFrom'
                labelTx='master_data:phrase:edited_date'
                placeholder={t('placeholder:date') ?? ''}
                disabledDate={(d) => {
                  return Boolean(updatedDateTo && d.startOf('day').isAfter(updatedDateTo));
                }}
              />
            </Col>
            <Col className='flex justify-center'>
              <div className='bg-textGray w-[18px] h-[1.5px] mt-[45px]' />
            </Col>
            <Col className='w-[292px]'>
              <FormInputDate
                name='updatedDateTo'
                label='&nbsp;'
                placeholder={t('placeholder:date') ?? ''}
                disabledDate={(d) => {
                  return Boolean(updatedDateFrom && d.endOf('day').isBefore(updatedDateFrom));
                }}
              />
            </Col>
          </Row>
        </div>
        <div className='flex justify-end space-x-2'>
          <div className='w-[120px]'>
            <BaseButton onClick={onReset} type='tertiary' size='medium' disabled={disableResetBtn()}>
              {t('table:filter:btn_reset')}
            </BaseButton>
          </div>
          <div className='w-[120px]'>
            <BaseButton onClick={form.handleSubmit(handleOnSubmit)} type='primary' size='medium'>
              <span>{t('table:filter:btn_apply')} </span>
            </BaseButton>
          </div>
        </div>
      </div>
    </FormProvider>
  );
};

export interface ITableHeaderProps {
  onSubmit: SubmitHandler<IFilterForm>;
}
const TableHeader = ({ onSubmit }: ITableHeaderProps) => {
  const formFilter = useFormContext<IFilterForm>();
  const { t } = useTranslation();
  return (
    <>
      <FormBaseFilter
        onSubmit={onSubmit as any}
        searchBox={{
          name: 'searchValue',
          placeholder: DataViewer.display(t('master_data:company:search_placeholder'))
        }}
        tagSection={{
          renderTag: (fieldKey, fieldValue) => {
            if (['updatedDateFrom', 'updatedDateTo'].includes(fieldKey)) {
              return <>{formatDateTime(fieldValue)}</>;
            }

            return <>{fieldValue}</>;
          }
        }}
        primaryAction={{
          label: 'button:filter',
          name: 'primary',
          popoverContent: (
            <FormPopoverFilter
              onSubmit={(values) => {
                formFilter.reset((previous) => ({ ...previous, primary: { ...values } }));
                formFilter.handleSubmit(onSubmit)();
              }}
              defaultValues={formFilter.watch('primary')}
            />
          )
        }}
        secondaryAction={null}
      />
    </>
  );
};

export default TableHeader;
