import { FormListFieldData } from 'antd/lib/form';
import { Dayjs } from 'dayjs';

import { BasicInformationTemplateSecurityProps } from './security';

export interface IDocument {
  id?: string;
  name?: string | null;
  code?: string | null;
  isConfirmed: boolean | undefined;
  note?: string | undefined | null;
  remarks?: string | undefined | null;
  numberOfCopy: number | string | undefined | null;
  order?: number | null;
  submissionDeadline?: string | Dayjs | null;
  submissionMethod?: string | null;
  submitterCode?: string | null;
  submitterId?: number | string | null;
  typeId?: string | number | null;
  documentId?: string | null;
  documentName?: string;
  documentPath?: string | null;
  fileStatus?: string | null;
  ownerFile?: string | number;
  uuid?: string;
  defaultOrder?: number;
  media?: IFileTemplate[] | null;
  defaultMedia?: IFileTemplate[] | null;
  submitterName?: string;
  key?: string;
  isEdit?: boolean;
  typeName?: string;
  submissionMethodName?: string;
  submissionMethodId?: string | null;
  submissionMethodCode?: string;
  tempDeadline?: string;
  countryId?: string;
  version?: number;
  totalMedia?: number;
}
export interface IMaterial {
  id?: string;
  description?: string | null;
  order?: number | null;
  typeId?: string | null;
  name?: string | null;
  documentId?: string | null;
  documentName?: string | null;
  documentPath?: string | null;
  media?: IFileTemplate[] | null;
  defaultMedia?: IFileTemplate[] | null;
  fileStatus?: string | null;
  ownerFile?: string | number;
  typeName?: string | null;
  uuid?: string;
  key?: string;
  isEdit?: boolean;
  version?: string;
  changeDocumentFlg?: boolean;
}
export interface IQuestionnaire {}
export interface IFileTemplate {
  id?: string;
  templateDocumentId?: string;
  documentId?: string;
  name: string;
  blobPath: string;
  deleted?: boolean;
  status?: FileStatusType;
  createdBy?: string | number;
  isNewUpload?: boolean;
}
export type FileStatusType = 'waiting_for_approve' | 'approved' | 'rejected' | 'public';
export interface ISchedule {
  id?: string;
  name?: string;
  categoryId?: string;
  patternId?: string;
  patternName?: string;
  categoryName?: string;
  createdName?: string;
  countryId?: string;
  countryName?: string;
  referanceTemplateName?: string;
  createdBy?: number;
  createdDate?: number;
  formId?: string | Dayjs;
  formName?: string;
  deleted?: boolean;
  key?: string;
  isEdit?: boolean;
  version?: boolean;
}
export interface ITask {
  id?: string;
  taskName?: string | null;
  deadline?: string | Dayjs | null;
  managerId?: string | null;
  managerName?: string | null;
  status?: string | null;
  remark?: string | null;
  taskId?: string | null;
  order?: number;
  key?: string;
  name?: string;
  typeName?: string;
  typeId?: string;
  documentId?: string;
  documentName?: string;
  updatedAt?: string;
  completedDate?: string;
  countryId?: string;
  version?: string;
  changeDocumentFlg?: boolean;
}

export interface IReferenceDocument {
  countryId: string;
  countryName: string;
  createdBy: string;
  createdDate: string;
  createdName: string;
  displayOrder: number;
  documentId: string;
  documentName: string;
  id: string;
  managementCode: string;
  note: string;
  numberOfCopy: number;
  submissionToMot: boolean;
  templateId: string;
  updatedBy: string;
  updatedDate: string;
  updatedName: string;
  version: number;
  changeDocumentFlg?: boolean;
}

export interface IDocumentInternal {
  id: string;
  version: 1;
  createdBy: string;
  createdDate: string;
  createdName: string;
  updatedBy: string;
  updatedName: string;
  templateId: string;
  documentId: string;
  countryId: string;
  countryName: string;
  managementCode: string;
  documentName: string;
  displayOrder: number;
  numberOfCopy: number;
  note: string;
}

export interface ITemplateProcesses {
  id: string;
  processName: string;
  templateProcessTasks: ITask[];
  templateId: string;
  processId: string;
  displayOrder: number;
}
export interface ITemplate {
  id: string;
  categoryCode: string;
  categoryId: string;
  countryCode: string;
  countryId: string;
  formCode: string;
  formId: string;
  name: string;
  documents: IDocument[];
  requiredDocuments: IReferenceDocument[];
  internalDocuments: IDocumentInternal[];
  questionnaire: ISectionCard[];
  refId?: string;
  refName?: string;
  schedules: ISchedule[];
  tasks: ITask[];
  createdAt?: string;
  updatedAt?: string;
  author?: string;
  updater?: string;
  version?: string;
  templateProcesses: ITemplateProcesses[];
  patternId?: string;
}
export interface ICountry {
  id: string;
  code: string;
  name: string;
  countryName?: string;
  categories?: ICategory[];
  url?: string;
  order?: number;
}
export interface ICategory {
  code: string;
  name: string;
  id: string;
}
export interface IBasicInformationTemplateProps {
  disabled: boolean;
  create?: boolean;
  defaultValue?: IDefaultValue;
  basicInformationTemplateSecurityProps?: BasicInformationTemplateSecurityProps;
  countries?: ICountry[];
  form?: any;
  clearDataQuestions?: () => void;
}

export interface IDefaultValue {
  country?: string;
  category?: string;
  form?: string;
}

export enum QuestionType {
  TEXT = 'text',
  PARAGRAPH = 'paragraph',
  MULTIPLE_CHOICE = 'multi_choice',
  CHECK_BOX = 'check_box',
  DROPDOWN = 'dd_list',
  FILE = 'file_upload',
  DATE = 'date_time',
  DURATION = 'duration'
}

export interface IQuestionType {
  typeID: string;
  typeName: string;
}

export interface IQuestion {
  id?: string;
  name: string;
  typeId: string;
  parentQuestionId?: string | null;
  parentOptionId?: string | null;
  respondentId?: string;
  respondentName?: string;
  tooltip: string | null;
  order: number;
  isOnlyPdf: boolean;
  maxNumFile: number;
  maxFileSize: number;
  options?: IOption[];
  startDate?: Date;
  endDate?: Date;
  value?: any;
}
export interface IOption {
  id?: string;
  value?: string;
  name: string;
  order: number;
  hasQuestion?: boolean;
  children?: IQuestion[];
  questions?: IQuestion[];
}

export interface ISectionCard {
  id?: string;
  title: string;
  name: string;
  order?: number;
  sectionNumber?: number;
  description: string;
  questions: IQuestion[];
}

export interface IFieldList extends FormListFieldData {
  root_tree?: string;
}

export interface IQuestionOptions {
  name: string;
  hasQuestion: boolean;
  questions: any[];
  id: string;
  order?: number;
}

// response interface
export interface ITemplateResponse {
  name: string;
  code: number;
  countryId: string;
  categoryId: string;
  formId: string;
  createdName: string;
  updatedName: string;
  schedules: IScheduleResponse[];
  documents: IDocumentResponse[];
  materials: IMaterialResponse[];
  questionnaire?: any[];
  tasks: ITaskResponse[];
  id: string;
  createdBy: string;
  updatedBy: string;
}

export interface IScheduleResponse {
  id: string;
  name: string;
  status: string;
  order: number;
  scheduledCompleteDate: string;
  actualComplateDate: string;
  deleted: boolean;
}

export interface IDocumentResponse {
  id: string;
  typeId: string;
  isConfirmed: boolean;
  order: number;
  numberOfCopy: string;
  submissionMethod: string;
  submitterId: string;
  deleted?: boolean;
}

export interface IMaterialResponse {
  id: string;
  order: number;
  typeId: string;
  deleted?: boolean;
}

export interface ITaskResponse {
  id: string;
  typeId: string;
  managerId?: string;
  remark: string;
  order: number;
  deleted?: boolean;
}

export interface IApplicationDocuments {
  label: string;
  type: string;
  options: IApplicationDocumentsOptions[];
}

export interface IApplicationDocumentsOptions {
  label: string;
  value: string;
}
