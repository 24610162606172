import classNames from 'classnames';
import React from 'react';

import { BaseButton } from '@/components/base-button/BaseButton';

export const SEND_BUTTON_STATUS = {
  ACTIVE: 'active',
  DISABLED: 'disabled'
} as const;
export type SendButtonStatus = (typeof SEND_BUTTON_STATUS)[keyof typeof SEND_BUTTON_STATUS];

export const SEND_BUTTON_MODE = {
  SEND: 'send',
  EDIT: 'edit'
} as const;
export type SendButtonMode = (typeof SEND_BUTTON_MODE)[keyof typeof SEND_BUTTON_MODE];
interface ISendButtonProps {
  className?: string;
  onClick: () => void;
  status: SendButtonStatus;
  mode: SendButtonMode;
}

const SendButton = ({ mode, status, className, onClick }: ISendButtonProps) => {
  const rectClass = status === SEND_BUTTON_STATUS.ACTIVE ? 'fill-green' : 'fill-gray2';
  const cursor = status === SEND_BUTTON_STATUS.ACTIVE ? 'pointer' : 'not-allowed';

  if (mode === SEND_BUTTON_MODE.EDIT) {
    return (
      <BaseButton
        type='text'
        className={classNames('active:scale-95 cursor-auto !p-0 !min-w-[30px] !max-w-[30px] !h-[30px] focus-visible:!rounded-full', className)}
        onClick={onClick}
      >
        <svg width='30' height='31' viewBox='0 0 30 31' fill='none' xmlns='http://www.w3.org/2000/svg' style={{ cursor }}>
          <rect y='0.945312' width='30' height='30' rx='15' className={rectClass} />
          <path
            d='M20.9062 12.0078L13.0312 19.8828L9.09375 15.9453'
            stroke='#F5F5F7'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </BaseButton>
    );
  }
  return (
    <BaseButton
      type='text'
      className={classNames('active:scale-95 cursor-auto !p-0 !min-w-[30px] !max-w-[30px] !h-[30px] focus-visible:!rounded-full', className)}
      onClick={onClick}
    >
      <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg' style={{ cursor }}>
        <rect className={rectClass} width='30' height='30' rx='15' fill='#DBDBE0' />
        <path
          d='M22.4903 14.0131L10.6427 7.38261C10.4453 7.26749 10.218 7.21401 9.98992 7.22905C9.7619 7.24408 9.54357 7.32694 9.36299 7.46698C9.17856 7.61249 9.04466 7.8124 8.98029 8.03833C8.91592 8.26425 8.92435 8.50471 9.00439 8.72557L10.9802 14.2451C11.0002 14.3001 11.0364 14.3478 11.0842 14.3817C11.1319 14.4156 11.1888 14.4342 11.2474 14.4349H16.3239C16.4699 14.4326 16.6113 14.4858 16.7196 14.5837C16.8279 14.6815 16.8951 14.8168 16.9075 14.9623C16.9123 15.0391 16.9013 15.116 16.8752 15.1884C16.8491 15.2607 16.8085 15.327 16.7557 15.383C16.703 15.439 16.6394 15.4836 16.5687 15.514C16.4981 15.5445 16.4219 15.5601 16.345 15.5599H11.2474C11.1888 15.5607 11.1319 15.5793 11.0842 15.6132C11.0364 15.6471 11.0002 15.6948 10.9802 15.7498L9.00439 21.2693C8.94511 21.4394 8.92725 21.6211 8.95229 21.7994C8.97733 21.9777 9.04455 22.1475 9.14837 22.2947C9.25219 22.4418 9.38961 22.562 9.54922 22.6454C9.70884 22.7288 9.88604 22.7729 10.0661 22.774C10.2577 22.7731 10.4462 22.7248 10.6145 22.6334L22.4903 15.9818C22.6643 15.883 22.809 15.7399 22.9097 15.567C23.0103 15.394 23.0633 15.1975 23.0633 14.9974C23.0633 14.7974 23.0103 14.6009 22.9097 14.4279C22.809 14.255 22.6643 14.1119 22.4903 14.0131Z'
          fill='#ffffff'
        />
      </svg>
    </BaseButton>
  );
};

export default SendButton;
