import { Row, Space } from 'antd';
import classNames from 'classnames';
import { isNil } from 'lodash';
import React, { useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import EmptyDataWithIcon from '@/components/EmptyData/EmptyDataWithIcon';
import AppTooltip from '@/components/app-tooltip/AppTooltip';
import { BaseButton } from '@/components/base-button/BaseButton';
import { BaseCollapse } from '@/components/base-collapse/BaseCollapse';
import FormBaseFilter from '@/components/base-filter';
import TableBase, { FetchColumnsType } from '@/components/common/table-base';
import { IconDownload } from '@/components/icon-svg/IconSvg';

import useAuthorization, * as s from '@/hooks/useAuthorization';
import useFetch from '@/hooks/useFetch';
import { useFilterConfigs } from '@/hooks/useFilterConfigs';
import useMutation from '@/hooks/useMutation';

import { ROUTER_IDS } from '@/utils/constants';
import { API } from '@/utils/constants/Apis';
import { EDIT_DOCUMENT_LIST_URL } from '@/utils/constants/RouteContants';
import { DataViewer } from '@/utils/helpers/common';
import { downloadSingleFileFromURI } from '@/utils/helpers/fileHelper';
import { convertFullWidthToHalfWidth } from '@/utils/helpers/globalHelper';
import { renderProjectCode } from '@/utils/helpers/project';

import Note from '@/assets/icons/Note.svg';
import PencilSimpleLine from '@/assets/icons/PencilSimpleLine.svg';
import DefaultEmptySvg from '@/assets/icons/table/base-table-empty.svg';

import { IProjectInfo } from '../add-edit/models';
import { STATUS_NAME } from '../detail/constant';
import Filter from './components/Filter';
import { RESPONSE_STATUS, SUBMISSION_MOT, SUBMISSION_STATUS } from './constants';
import { IDocumentList, IFormMethodRequiredDocument, IQueryFilterParams } from './model';

import './DocumentList.scss';

const DocumentList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const { hasPermission, isInternalRole, isExternalRole } = useAuthorization();
  const { data: defaultFilterConfigs, update: updateFilterConfigs } = useFilterConfigs<FieldValues>(ROUTER_IDS.PROJECT_DOCUMENT_LIST);
  const formFilter = useForm({ defaultValues: generateDefaultFilterForm().form });
  const [filter, setFilter] = useState(generateDefaultFilterForm().query);
  const { data: projectData } = useFetch<IProjectInfo>(API.GET_PROJECT_INFO(id ?? ''), 'GET');
  const { data: requiredDocument } = useFetch<IFormMethodRequiredDocument>(API.GET_DOCUMENT_LIST(id ?? ''), 'POST', filter);
  const { mutate: exportDocumentList } = useMutation(
    API.EXPORT_DOCUMENT_LIST(id ?? ''),
    {
      method: 'POST',
      bodyType: 'json',
      showToastError: true,
      showToastSuccess: false
    },
    {
      responseType: 'blob'
    }
  );

  function generateDefaultFilterForm() {
    const results = {
      form: {
        primary: {
          comunicationStatus: null,
          submissionStatus: null,
          submissionToMot: null
        }
      },
      query: {}
    };
    if (!id || !defaultFilterConfigs?.[id]?.primary) return results;
    const { submissionToMot } = defaultFilterConfigs[id]?.primary ?? {};
    results.form = {
      primary: {
        ...defaultFilterConfigs[id]?.primary,
        submissionToMot: submissionToMot !== null ? (submissionToMot ? SUBMISSION_MOT.need : SUBMISSION_MOT.unnecessary) : null
      }
    };
    results.query = {
      keywordSearch: '',
      ...defaultFilterConfigs[id]?.primary
    };
    return results;
  }

  const columnsDocumentList: FetchColumnsType<any> = [
    {
      title: 'NO',
      key: 'no',
      dataIndex: 'displayOrder',
      width: '3.3%',
      className: 'h-[38px]',
      align: 'center',
      render: (value: number) => {
        return <div className='whitespace-nowrap'>{value}</div>;
      }
    },
    {
      title: t('required_documents:document_name'),
      dataIndex: 'managementCode',
      render(value: string) {
        return <div className='whitespace-normal'>{DataViewer.display(value)}</div>;
      },
      width: '25.7%'
    },
    {
      title: t('required_documents:number_of_copies'),
      fixed: 'left',
      dataIndex: 'numberOfCopy',
      width: '7.4%',
      render(value: string) {
        return DataViewer.display(value);
      }
    },
    {
      title: t('required_documents:notes'),
      dataIndex: 'note',
      width: '39.8%',
      render(value: string) {
        return (
          <div className='text-ink text-html whitespace-break-spaces' dangerouslySetInnerHTML={{ __html: DataViewer.displayAsSanitizeHTML(value) }} />
        );
      }
    },
    {
      title: t('required_documents:submission_mot'),
      dataIndex: 'submissionToMot',
      key: 'submissionToMot',
      render(value: boolean | null) {
        if (isNil(value)) return DataViewer.display(value);
        let text = value ? t('required_documents:need') : t('required_documents:unnecessary');
        return DataViewer.display(text);
      },
      width: '10%'
    },
    {
      title: t('required_documents:submission_status'),
      dataIndex: 'isConfirm',
      render(value: boolean | null, record: IDocumentList) {
        if (isNil(value) || !record.submissionToMot) return DataViewer.display(null);
        const nameConfirm = record?.statusConfirm ? record.nameConfirm : `(${t('account_list:status_account:inactive')}) ${record.nameConfirm}`;
        return (
          <div className={classNames('flex gap-2 whitespace-pre-wrap', isExternalRole ? 'flex-row' : 'flex-col')}>
            <p className={isInternalRole ? '' : `status ${value ? 'submitted' : 'not-submitted'}`}>
              {value ? t('required_documents:submitted') : t('required_documents:not_submitted')}
            </p>
            {value && !isNil(record?.statusConfirm) && (
              <p>
                {t('required_documents:confirmed_by')} : <AppTooltip title={record?.nameConfirm}>{nameConfirm}</AppTooltip>
              </p>
            )}
          </div>
        );
      },
      width: '13.8%'
    }
  ];

  const columnsInternalDocument: FetchColumnsType<any> = [
    {
      title: 'NO',
      key: 'no',
      dataIndex: 'displayOrder',
      width: '3.3%',
      className: 'h-[38px]',
      align: 'center',
      render: (value: number) => {
        return <div className='whitespace-nowrap'>{value}</div>;
      }
    },
    {
      title: t('required_documents:document_name'),
      dataIndex: 'managementCode',
      render(value: string) {
        return <div className='whitespace-normal'>{DataViewer.display(value)}</div>;
      },
      width: '24%'
    },
    {
      title: t('required_documents:number_of_copies'),
      fixed: 'left',
      dataIndex: 'numberOfCopy',
      width: '7.4%',
      render(value: string) {
        return DataViewer.display(value);
      }
    },
    {
      title: t('required_documents:notes'),
      dataIndex: 'note',
      width: '39.8%',
      render(value: string) {
        return (
          <div className='text-ink text-html whitespace-break-spaces' dangerouslySetInnerHTML={{ __html: DataViewer.displayAsSanitizeHTML(value) }} />
        );
      }
    },
    {
      title: t('required_documents:response_status'),
      dataIndex: 'isConfirm',
      render(value: boolean | null, record: IDocumentList) {
        if (isNil(value)) return <p>{DataViewer.display(value)}</p>;
        const nameConfirm = record.statusConfirm ? record.nameConfirm : `(${t('account_list:status_account:inactive')}) ${record.nameConfirm}`;
        return (
          <div className='flex flex-col whitespace-pre-wrap'>
            <p className='pr-6'>{value ? t('required_documents:compatible') : t('required_documents:not_compatible')}</p>
            {value && !isNil(record?.statusConfirm) && (
              <AppTooltip title={record?.nameConfirm}>
                <p>
                  {t('required_documents:counterpart')} : {nameConfirm}
                </p>
              </AppTooltip>
            )}
          </div>
        );
      },
      width: '25.4%'
    }
  ];

  const updateFilter = (values: any) => {
    const { submissionToMot } = values?.primary ?? {};
    const formValues: IQueryFilterParams = {
      keywordSearch: values?.search ? convertFullWidthToHalfWidth(values?.search).toLowerCase() : '',
      ...values?.primary,
      submissionToMot: submissionToMot ? submissionToMot === SUBMISSION_MOT.need : null
    };

    setFilter({ ...formValues });
    if (!id) return;
    updateFilterConfigs({
      ...defaultFilterConfigs,
      [id]: {
        ...values,
        primary: {
          ...values.primary,
          submissionToMot: submissionToMot ? submissionToMot === SUBMISSION_MOT.need : null
        }
      }
    });
  };
  const { comunicationStatus, submissionStatus, submissionToMot } = formFilter.getValues('primary') || {};

  const generateFilterHeader = () => {
    return (
      <div>
        <FormProvider {...formFilter}>
          <FormBaseFilter
            onSubmit={(values) => {
              updateFilter(values);
            }}
            searchBox={{ placeholder: DataViewer.display(t('common:DOCUMENT_LIST_FIELDS_SEARCH_SEARCH_PLACEHOLDER')) }}
            tagSection={{
              renderTag: (fieldKey, fieldValue) => {
                if (fieldKey === 'submissionToMot') {
                  return <>{fieldValue === SUBMISSION_MOT.need ? t('required_documents:need') : t('required_documents:unnecessary')}</>;
                }
                if (fieldKey === 'submissionStatus') {
                  return (
                    <>{fieldValue === SUBMISSION_STATUS.submitted ? t('required_documents:submitted') : t('required_documents:not_submitted')}</>
                  );
                }
                if (fieldKey === 'comunicationStatus') {
                  return (
                    <>{fieldValue === RESPONSE_STATUS.compatible ? t('required_documents:compatible') : t('required_documents:not_compatible')}</>
                  );
                }
                return <>{fieldValue}</>;
              }
            }}
            primaryAction={{
              label: 'button:filter',
              name: 'primary',
              getPopupContainer: () => document.body,
              popoverContent: (
                <Filter
                  defaultValues={{
                    comunicationStatus,
                    submissionStatus,
                    submissionToMot: submissionToMot
                      ? submissionToMot === SUBMISSION_MOT.need
                        ? SUBMISSION_MOT.need
                        : SUBMISSION_MOT.unnecessary
                      : null
                  }}
                  onSubmit={(values: any) => {
                    formFilter.reset((previous) => ({
                      ...previous,
                      primary: { ...values }
                    }));
                    formFilter.handleSubmit((values) => updateFilter(values))();
                  }}
                />
              )
            }}
            secondaryAction={null}
          />
        </FormProvider>
      </div>
    );
  };

  const handleExport = async () => {
    const fileExport = await exportDocumentList({});
    const { lastName, firstName } = projectData?.projectApplicant || {};
    downloadSingleFileFromURI(
      fileExport?.data,
      `${projectData?.code}_${lastName} ${firstName}_${t('required_documents:required_documents_list')}.pdf`
    );
  };

  const title = renderProjectCode(projectData, t('basic_information:proposal_number'));

  const couldNotBeDelete = (record: IDocumentList) => {
    return !!(record?.documentTask?.statusName && record?.documentTask?.statusName !== STATUS_NAME.TODO);
  };

  return (
    <div className='form-basic-information document-list'>
      <div className='project-tabs-content container-scroll flex flex-col'>
        <header className='header-basic-information flex flex-col sticky top-0 gap-3'>
          <div className='flex flex-row justify-between'>
            <div className='flex flex-col'>
              <div className='title-24'>{t('required_documents:required_documents_list')}</div>
              <div className='title-14'>
                {title}
                <span className='proposal-number'>{projectData?.code ? `(${projectData.code})` : ''}</span>
              </div>
            </div>
            <Space size={16}>
              <div className='min-w-[120px]'>
                <BaseButton
                  className='btn-export'
                  onClick={handleExport}
                  type='tertiary'
                  size='medium'
                  icon={<IconDownload className='icon-export' />}
                >
                  {t('project_detail:buttons:export_pdf')}
                </BaseButton>
              </div>
              {isInternalRole && (
                <div className='min-w-[120px]'>
                  <BaseButton
                    type='secondary'
                    size='medium'
                    disabled={false}
                    icon={<PencilSimpleLine />}
                    onClick={() => {
                      navigate(EDIT_DOCUMENT_LIST_URL(id || ''));
                    }}
                  >
                    <div>{t('template_layout:edit_button')}</div>
                  </BaseButton>
                </div>
              )}
            </Space>
          </div>
          <div>{generateFilterHeader()}</div>
        </header>
        <div className='flex flex-col h-[calc(100vh-100px)] overflow-y-scroll pb-4 gap-3'>
          <BaseCollapse id='request_detail' title={t('required_documents:required_documents')} className='aloooooooo'>
            <Row>
              <p className='flex items-center gap-1'>
                <Note /> {t('required_documents:note')}
              </p>
            </Row>
            <Row>
              <TableBase
                className='w-full table-no-hover'
                showAddLine={false}
                columns={columnsDocumentList}
                rowClassName={(record: IDocumentList) => (couldNotBeDelete(record) ? 'inactive' : '')}
                scrollTable={{ x: 0, maxHeight: '100vh - 248px' }}
                showDownload={false}
                showDelete={false}
                locale={{
                  emptyText: hasPermission(s.VIEW_DOCUMENT_LIST, s.V) && (
                    <EmptyDataWithIcon
                      icon={<DefaultEmptySvg />}
                      title={null}
                      description={{
                        content: 'common:no_data'
                      }}
                      button={null}
                    />
                  )
                }}
                rowSelection={null}
                dataSource={requiredDocument?.projectRequiredDocuments}
                pagination={false}
              />
            </Row>
          </BaseCollapse>
          {isInternalRole && (
            <BaseCollapse id='request' title={t('required_documents:internal_documents')}>
              <Row>
                <TableBase
                  className='w-full table-no-hover'
                  showAddLine={false}
                  columns={columnsInternalDocument}
                  rowClassName={(record: IDocumentList) => (couldNotBeDelete(record) ? 'inactive' : '')}
                  scrollTable={{ x: 0, maxHeight: '100vh - 248px' }}
                  showDownload={false}
                  showDelete={false}
                  rowSelection={null}
                  pagination={false}
                  locale={{
                    emptyText: hasPermission(s.VIEW_DOCUMENT_LIST, s.V) && (
                      <EmptyDataWithIcon
                        icon={<DefaultEmptySvg />}
                        title={null}
                        description={{
                          content: 'common:no_data'
                        }}
                        button={null}
                      />
                    )
                  }}
                  dataSource={requiredDocument?.projectInternalDocuments}
                />
              </Row>
            </BaseCollapse>
          )}
        </div>
      </div>
    </div>
  );
};

export default DocumentList;
