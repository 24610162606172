export const FILE_STATUS = {
  WAITING_FOR_APPROVE: 'waiting_for_approve',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  PUBLIC: 'public'
};

export const FILE_TYPES = {
  TXT: 'txt',
  DOC: 'doc',
  DOCX: 'docx',
  XLS: 'xls',
  XLSX: 'xlsx',
  PDF: 'pdf',
  PPT: 'ppt',
  PPTX: 'pptx',
  PNG: 'png',
  JPG: 'jpg',
  JPEG: 'jpeg'
} as const;
export type FileTypes = (typeof FILE_TYPES)[keyof typeof FILE_TYPES];

export const FILE_ATTACHMENT_ALLOWED = [
  FILE_TYPES.TXT,
  FILE_TYPES.DOC,
  FILE_TYPES.DOCX,
  FILE_TYPES.XLS,
  FILE_TYPES.XLSX,
  FILE_TYPES.PDF,
  FILE_TYPES.PPT,
  FILE_TYPES.PPTX,
  FILE_TYPES.PNG,
  FILE_TYPES.JPG,
  FILE_TYPES.JPEG
];
export const INPUT_FILE_ALLOWED = FILE_ATTACHMENT_ALLOWED.map((ext) => `.${ext}`).join(',');

export const ATTACHMENT_FILE_STATUS = {
  APPROVAL: 'A',
  REJECT: 'R',
  WAITING: 'W',
  DONE: 'done',
  UPLOADING: 'uploading',
  ERROR: 'error'
} as const;

export type AttachmentStatus = (typeof ATTACHMENT_FILE_STATUS)[keyof typeof ATTACHMENT_FILE_STATUS];

export const FILE_EXCEL_ATTACHMENT_ALLOWED = [FILE_TYPES.XLS, FILE_TYPES.XLSX];
export const FILE_EXCEL_ALLOWED = FILE_EXCEL_ATTACHMENT_ALLOWED.map((ext) => `.${ext}`).join(',');
