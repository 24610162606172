import classNames from 'classnames';
import React from 'react';

import AppTooltip from '@components/app-tooltip/AppTooltip';

import ArrowsClockwiseIcon from '@assets/icons/ArrowsClockwise.svg';
import DownloadIcon from '@assets/icons/IconDownload.svg';
import TrashBlackIcon from '@assets/icons/TrashBlack.svg';
import WarningCircleIcon from '@assets/icons/WarningCircle.svg';

import './FileAttachment.scss';

const PaperClipIcon = ({ stroke = '#076CE3' }: { stroke?: string }) => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.5662 5.58772L5.70919 11.5432C5.51529 11.7572 5.4111 12.0376 5.41821 12.3263C5.42532 12.615 5.54318 12.8899 5.74738 13.0941C5.95158 13.2983 6.22648 13.4161 6.51518 13.4232C6.80388 13.4303 7.08425 13.3262 7.29825 13.1323L14.2803 6.05178C14.6681 5.62378 14.8765 5.06303 14.8622 4.48564C14.848 3.90824 14.6123 3.35843 14.2039 2.95003C13.7955 2.54162 13.2457 2.30591 12.6683 2.2917C12.0909 2.27748 11.5302 2.48585 11.1022 2.87366L4.12013 9.95413C3.48796 10.5863 3.13281 11.4437 3.13281 12.3377C3.13281 13.2317 3.48796 14.0891 4.12013 14.7213C4.7523 15.3535 5.6097 15.7086 6.50372 15.7086C7.39774 15.7086 8.25515 15.3535 8.88732 14.7213L14.66 8.96272'
        stroke={stroke}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export const FILE_ATTACHMENT_STATUS = {
  DONE: 'done',
  EDIT: 'edit',
  VIEW: 'view',
  UPLOADING: 'uploading',
  ERROR: 'error'
} as const;
export type FileAttachmentStatus = (typeof FILE_ATTACHMENT_STATUS)[keyof typeof FILE_ATTACHMENT_STATUS];
interface IFileAttachmentProps {
  className?: string;
  label: string;
  status: FileAttachmentStatus;
  msgErr?: string;
  onDelete?: () => void;
  onDownload?: () => void;
}
const FileAttachment = ({ label, status, className, msgErr, onDelete, onDownload }: IFileAttachmentProps) => {
  const ACTION = {
    [FILE_ATTACHMENT_STATUS.VIEW]: (
      <div className='icon-btn clickable' onClick={onDownload}>
        <DownloadIcon width={18} height={18} />
      </div>
    ),
    [FILE_ATTACHMENT_STATUS.EDIT]: (
      <div className='flex'>
        <div className='icon-btn clickable' onClick={onDownload}>
          <DownloadIcon width={18} height={18} />
        </div>
        <div className='icon-btn clickable' onClick={onDelete}>
          <TrashBlackIcon width={18} height={18} />
        </div>
      </div>
    ),
    [FILE_ATTACHMENT_STATUS.DONE]: (
      <div className='flex'>
        <div className='icon-btn clickable' onClick={onDownload}>
          <DownloadIcon width={18} height={18} />
        </div>
        <div className='icon-btn clickable' onClick={onDelete}>
          <TrashBlackIcon width={18} height={18} />
        </div>
      </div>
    ),
    [FILE_ATTACHMENT_STATUS.UPLOADING]: (
      <div className='icon-btn disabled' onClick={onDelete}>
        <TrashBlackIcon width={18} height={18} />
      </div>
    ),
    [FILE_ATTACHMENT_STATUS.ERROR]: (
      <div className='flex'>
        <div className='icon-btn non-clickable'>
          <AppTooltip title={msgErr || ''}>
            <WarningCircleIcon width={18} height={18} />
          </AppTooltip>
        </div>
        <div className='icon-btn clickable' onClick={onDelete}>
          <TrashBlackIcon width={18} height={18} />
        </div>
      </div>
    )
  };
  const ICON = {
    [FILE_ATTACHMENT_STATUS.VIEW]: <PaperClipIcon />,
    [FILE_ATTACHMENT_STATUS.EDIT]: <PaperClipIcon />,
    [FILE_ATTACHMENT_STATUS.DONE]: <PaperClipIcon />,
    [FILE_ATTACHMENT_STATUS.UPLOADING]: <ArrowsClockwiseIcon className='icon-loading' />,
    [FILE_ATTACHMENT_STATUS.ERROR]: <PaperClipIcon stroke='#F03D3E' />
  };
  return (
    <div className={classNames('file-attachment', className, status)}>
      <div className='file-attachment__icon'>{ICON[status]}</div>
      <AppTooltip title={label} placement='top'>
        <div className={classNames('file-attachment__label', !!onDownload && 'cursor-pointer')} onClick={onDownload}>
          {label}
        </div>
      </AppTooltip>
      <div className='file-attachment__action'>{ACTION[status]}</div>
    </div>
  );
};

export default FileAttachment;
