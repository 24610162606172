import { ERROR_CODE } from '@/utils/constants/AppConstants';

export const MAX_FIELD = 10;
export const STAKEHOLDER_DEFAULT_VALUE = {
  projectStakeholders: [
    {
      stakeholderEmail: '',
      canDelete: true
    }
  ]
};

export const MESSAGE_MAPPING = {
  [ERROR_CODE.ENTITY_NOT_FOUND]: 'common:MSG_P_006',
  [ERROR_CODE.ENTITY_REJECTED]: 'common:MSG_P_006',
  [ERROR_CODE.USER_RESTRICT]: 'common:MSG_P_033',
  [ERROR_CODE.USER_INACTIVE]: 'common:MSG_P_034'
};

export const TYPE_STAKEHOLDER = {
  APPROVE: 'Approve',
  PENDING: 'Pending'
};
