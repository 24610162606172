import { useAppDispatch, useAppSelector } from '@/hooks';
import classNames from 'classnames';
import { useEffect } from 'react';

import { setAlertNotification } from '@redux/globalReducer';

import CheckCircle from '@assets/icons/CheckCircle.svg';
import CloseIcon from '@assets/icons/CloseIcon.svg';
import { default as ErrorIcon } from '@assets/icons/Warning.svg';
import { default as WarningIcon } from '@assets/icons/WarningCircle.svg';

import './Notification.scss';

enum NOTICE {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning'
}

const Notification = () => {
  const { show, message, type, position } = useAppSelector((state) => state.global.alertNotification);
  const dispatch = useAppDispatch();

  const getPosition: () => string = () => {
    return position ? `notification-position-${position}` : 'notification-position-top';
  };

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        closeAlert();
      }, 3000);
    }
  }, [show]);

  const closeAlert = () => {
    dispatch(
      setAlertNotification({
        show: false,
        message: ''
      })
    );
  };

  const styledBy = (type: NOTICE) => {
    if (type === NOTICE.SUCCESS) return 'alert-success';
    if (type === NOTICE.ERROR) return 'alert-error';
    if (type === NOTICE.WARNING) return 'alert-warning';
    return '';
  };

  const getIcon = (type: NOTICE) => {
    if (type === NOTICE.SUCCESS) return <CheckCircle className='alert-notification__suffix-icon' />;
    if (type === NOTICE.ERROR) return <ErrorIcon className='alert-notification__suffix-icon' />;
    if (type === NOTICE.WARNING) return <WarningIcon className='alert-notification__suffix-icon' />;
    return <></>;
  };

  if (!show) {
    return <></>;
  }

  return (
    <div className={classNames('alert-notification', styledBy(type as NOTICE), getPosition())}>
      <div className='flex items-center gap-4'>
        {getIcon(type as NOTICE)}
        <span className='alert-notification__message'>{message}</span>
        <CloseIcon className='cursor-pointer' onClick={closeAlert} />
      </div>
    </div>
  );
};

export default Notification;
