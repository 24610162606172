import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ValidateMessageObject } from '../utils/interfaces/i18n';

function useMessageYupTranslation(msg?: string) {
  const [t] = useTranslation();

  const parsed = useMemo<ValidateMessageObject | undefined>(() => {
    if (!msg) {
      return undefined;
    }
    try {
      return JSON.parse(msg);
    } catch {
      return undefined;
    }
  }, [msg]);

  return useMemo<string | undefined | any>(() => {
    if (typeof msg === 'string' && !parsed) {
      return t(msg);
    }
    if (!parsed) {
      return undefined;
    }

    const optionsTx: Record<string, string> = {};
    if (parsed.optionsTx) {
      Object.keys(parsed.optionsTx).forEach((key) => {
        optionsTx[key] = t(parsed.optionsTx[key]);
      });
    }
    return t(parsed.keyT, { ...(parsed.options ?? {}), ...optionsTx }).toString();
  }, [parsed, t, msg]);
}

export { useMessageYupTranslation };
