import BaseDatePicker, { BaseDatePickerPropsType } from '@/components/common/base-date-picker';
import FormItem from '@/components/common/form-item';

interface IDateTimeProps extends BaseDatePickerPropsType {
  readOnly?: boolean;
  name?: string;
}

const Datetime: React.FC<IDateTimeProps> = ({ readOnly, placeholder, name, ...restProps }) => {
  if (name) {
    return (
      <FormItem name={name}>
        <BaseDatePicker className='h-full' readOnly={readOnly} placeholder={readOnly ? '' : placeholder} hideBorder {...restProps} />
      </FormItem>
    );
  }
  return <BaseDatePicker className='h-full' readOnly={readOnly} placeholder={readOnly ? '' : placeholder} hideBorder {...restProps} />;
};

export default Datetime;
