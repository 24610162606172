import lodash from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IProjectStakeholder } from '@/pages/project-management/add-edit/models';
import { IStepFive } from '@/pages/project-management/add-edit/models/scenarioB';

import AppTooltip from '@/components/app-tooltip/AppTooltip';
import { BaseCard } from '@/components/base-card/BaseCard';

import { DataViewer } from '@/utils/helpers/common';
import { IUserProfile } from '@/utils/interfaces/user';

import CheckedIcon from '@assets/icons/Checked.svg';
import WarningWaitingApprove from '@assets/icons/WarningWaitingApprove.svg';
import CloseIcon from '@assets/icons/close.svg';

import CardInfo from './CardInfo';

interface IStakeholderCardProps {
  data: Partial<IProjectStakeholder>;
  validateInfo?: IProjectStakeholder & { name?: string; waitingCreate: boolean; isSuccess: boolean; companyName?: string };
  orderNumber: number;
}
const StakeholderCard = ({ data, orderNumber }: IStakeholderCardProps) => {
  const { t } = useTranslation();

  let items: any = [];
  const itemEmail = {
    title: (
      <div className='flex items-center'>
        <AppTooltip title={data.stakeholderEmail}>
          <p className='mr-4 stakeholder-email'>{data.stakeholderEmail}</p>
        </AppTooltip>
        <span className='w-[18px] h-[18px]'>
          {data.rejected ? <CloseIcon width={18} height={18} /> : data.waitCreateAccount && <WarningWaitingApprove width={18} height={18} />}
        </span>
      </div>
    ),
    label: 'basic_information:email_address'
  };
  const itemText = {
    title: (
      <>
        {DataViewer.display(
          <div className={`${data.rejected ? 'text-negative' : 'text-warning'} font-medium`}>
            {t(`${data.rejected ? 'basic_information:account_rejected' : 'project_management:required_documents_tab:waiting_approve'}`)}
          </div>
        )}
      </>
    ),
    label: <></>
  };
  const itemReason = {
    title: (
      <>
        {DataViewer.display(
          <div className='flex'>
            <span className='mr-4 !font-normal min-w-[93px]'>{t('basic_information:account_reason')}</span>
            <span dangerouslySetInnerHTML={{ __html: DataViewer.displayAsSanitizeHTML(data.rejectReason) }}></span>
          </div>
        )}
      </>
    ),
    label: <></>
  };
  const isInactive = data.stakeHolderStatus === false && !data?.waitCreateAccount && !data?.rejected;
  const name = `${isInactive ? `(${t('announcement:invalid')})` : ''} ${data.stakeholderName}`;
  const itemUsername = {
    title: (
      <div className='flex items-center overflow-hidden'>
        <AppTooltip title={name}>
          <p className='mr-2 truncate max-w-full font-semibold whitespace-pre'>{name}</p>
        </AppTooltip>
        <span className='w-[18px] h-[18px]'>
          {!data.rejected && !data.waitCreateAccount && <CheckedIcon className='icon-check' width={18} height={18} />}
        </span>
      </div>
    ),
    label: 'basic_information:username'
  };
  const itemCompanyName = { title: data.stakeholderCompanyName, label: 'basic_information:company_name' };
  const itemRole = { title: data.roleName, label: 'account_list:role' };

  if (!data.rejected && !data.waitCreateAccount) {
    items = [itemEmail, itemUsername];
  } else if (data.rejected && !data.waitCreateAccount) {
    items = [itemEmail, itemText, itemReason, itemUsername, itemCompanyName, itemRole];
  } else if (!data.rejected && data.waitCreateAccount) {
    items = [itemEmail, itemText, itemUsername, itemCompanyName, itemRole];
  }
  return (
    <BaseCard className='mt-3' title={`${t('stakeholder:title')} ${orderNumber}`} total={1} questions={data?.stakeholderEmail ? 1 : 0}>
      <CardInfo columns={[{ items, colSpan: 24 }]} customTitle='overflow-hidden body-700 w-full' />
    </BaseCard>
  );
};

interface ISystemUserProps {
  data: IStepFive;
  user?: IUserProfile | null;
}
const UserSystemInfo = ({ data, user }: ISystemUserProps) => {
  const { t } = useTranslation();
  const { projectStakeholders } = data ?? {};
  const uniqueStakeholders = lodash.uniqWith(
    projectStakeholders.filter((item: IProjectStakeholder) => item.stakeholderEmail),
    (accountA: IProjectStakeholder, accountB: IProjectStakeholder) =>
      accountA.stakeholderEmail === accountB.stakeholderEmail && !accountA.rejected && !accountB.rejected
  );
  return (
    <div className='flex flex-col gap-[8px]'>
      <BaseCard title={`${t('basic_information:add_system_user')}1`} total={1} questions={1} className='scenario-b-card'>
        <CardInfo
          customTitle='overflow-hidden body-700 w-full'
          columns={[
            {
              items: [
                {
                  title: DataViewer.display(user?.email),
                  label: 'basic_information:email'
                },
                {
                  title: (
                    <div className='flex items-center overflow-hidden'>
                      <AppTooltip title={DataViewer.display(user?.name)}>
                        <p className='mr-2 stakeholder-email'>{DataViewer.display(user?.name)}</p>
                      </AppTooltip>
                      <span className='w-[18px] h-[18px]'>{<CheckedIcon width={18} height={18} className='icon-check' />}</span>
                    </div>
                  ),
                  label: 'basic_information:username'
                }
              ]
            }
          ]}
        />
      </BaseCard>
      {uniqueStakeholders?.map((stakeholder, i) => {
        const { id } = stakeholder;
        return <StakeholderCard key={id} data={stakeholder} orderNumber={i + 2} />;
      })}
    </div>
  );
};

export default UserSystemInfo;
