import classNames from 'classnames';
import React from 'react';

export interface ITableItem {
  className?: string;
  isTitle?: boolean;
  content: React.ReactNode;
  style?: any;
}
interface ITableTypeProps {
  items: ITableItem[][];
  className?: string;
}

const Table: React.FC<ITableTypeProps> = ({ items }) => {
  const maxCol = items.reduce((max, item) => Math.max(max, item.length), 0);

  return (
    <div className={classNames('w-full', 'h-full')}>
      <table className='sub-table h-full table-fixed'>
        <tbody>
          {items.map((row, index) => (
            <tr key={index}>
              {row.map((cell, cIndex) => {
                return (
                  <td
                    key={cIndex}
                    className={cell.className}
                    style={{
                      width: cell?.isTitle ? '150px' : undefined,
                      whiteSpace: cell?.isTitle ? 'initial' : undefined,
                      ...cell?.style,
                      height: '100%'
                    }}
                    colSpan={cIndex === row.length - 1 && row.length < maxCol ? maxCol - row.length + 1 : 1}
                  >
                    {cell.content ?? <></>}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    // <></>
  );
};

export default Table;
