import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks';
import { Ii18n } from '../../utils/interfaces/i18n';

import './LoadingPage.scss';

const LoadingPage = () => {
  const { t }: Ii18n = useTranslation();
  const loadingPageGlobal: boolean = useAppSelector((state) => state.global.loadingPageGlobal);

  return (
    <>
      {loadingPageGlobal && (
        <div className='loading-page'>
          <div>
            <Spin size='large' />
          </div>
          <div className='loading-message'>{t('common:loading_Message')}</div>
        </div>
      )}
    </>
  );
};

export default LoadingPage;
