import { axiosInstance } from '../../config/http';
import { IDocumentAdd, IDocumentEdit, ITaskMasterAddEdit } from '../interfaces/masterdata';
import { IParams } from '../interfaces/service';

export const getForms = async () => {
  const res = await axiosInstance.get(`/mst/forms`);

  return res;
};

export const searchDocuments = async (params?: any) => {
  const res = await axiosInstance.post(`/mst/documents/search`, params || {});

  return res;
};

export const checkDuplicateDocuments = async (params?: { filter: string }) => {
  const res = await axiosInstance.post(`/mst/documents/search`, params || {});

  return res;
};

export const getDocuments = async (params?: any) => {
  const res = await axiosInstance.get(`/mst/documents`, params || {});

  return res;
};

export const addDocument = async (params?: IDocumentAdd) => {
  const res = await axiosInstance.post(`/mst/Documents`, params);

  return res;
};

export const editDocument = async (params?: IDocumentEdit) => {
  const res = await axiosInstance.put(`/mst/Documents`, params);

  return res;
};

export const deleteDocuments = async (params: { documents: { id: string; version: string }[] }) => {
  const res = axiosInstance.delete(`/mst/Documents`, { data: params });
  return res;
};

export const addPhraseCategory = async (params: { name: string }) => {
  const res = await axiosInstance.post(`/prj/phrases/category`, params);
  return res;
};

export const addPhrase = async (params: { categoryId: string; title: string; content?: string }) => {
  const res = await axiosInstance.post(`/prj/phrases`, params);
  return res;
};
export const editPhrase = async (params: { id: string; categoryId: string; title: string; content?: string }) => {
  const res = await axiosInstance.put(`/prj/phrases/` + params.id, params);
  return res;
};
export const deletePhrase = async (params: { ids: { id: string; version: string }[] }) => {
  return axiosInstance.delete(`/prj/phrases`, { data: params });
};

export const getPhraseCategory = async () => {
  const res = await axiosInstance.get(`/prj/phrases/category`);
  return res;
};

export const searchTasks = async (params?: IParams) => {
  const res = await axiosInstance.post(`/mst/tasks/search`, params);
  return res;
};

export const checkDuplicateTask = async (params?: { filter: string }) => {
  const res = await axiosInstance.post(`/mst/tasks/search`, params);
  return res;
};

export const addTask = async (params?: ITaskMasterAddEdit) => {
  const res = await axiosInstance.post(`/mst/tasks`, params);
  return res;
};

export const editTask = async (params?: ITaskMasterAddEdit) => {
  const res = await axiosInstance.put(`/mst/tasks`, params);

  return res;
};

export const deleteMultipleTasks = async (params?: { ids: { id: string; version: string }[] }) => {
  const res = await axiosInstance.delete(`/mst/tasks`, {
    data: params
  });
  return res;
};
export const deleteTask = async (id: string | number, version: string) => {
  const res = await axiosInstance.delete(`/mst/tasks/${id}?version=${version}`);
  return res;
};

export const getTasks = async (params?: { name: string }, projectId?: string) => {
  const res = await axiosInstance.get(`/mst/tasks${projectId ? '/?projectId=' + projectId : ''}`);

  return res;
};

export const getCompanies = async () => {
  const res = await axiosInstance.get(`/mst/companies`);
  return res;
};

export const searchCompanies = async (params?: any) => {
  const res = await axiosInstance.post(`/mst/companies/search`, params);

  return res;
};

export const searchPhrase = async (params: any) => {
  const res = await axiosInstance.post(`prj/phrases/search`, params);

  return res;
};

export const deleteCompanies = async (ids: { id: string; version: string }[]) => {
  const res = await axiosInstance.delete('/mst/companies', { data: { ids } });
  return res;
};

export const updateCompany = async (params: { id: string; name: string; version: number }) => {
  const res = await axiosInstance.put('/mst/companies', params);
  return res;
};

export const addCompany = async (params: { name: string }) => {
  const res = await axiosInstance.post('/mst/companies', params);
  return res;
};
