import { Col, Row } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BaseCard } from '@/components/base-card/BaseCard';
import { FormAttachment } from '@/components/form-attachment/FormAttachment';
import { EditorWithCounter } from '@/components/form-box-editor';
import { FormInput } from '@/components/form-input/FormInput';
import { InfoIcon } from '@/components/icon-svg/IconSvg';

import useAuthorization from '@/hooks/useAuthorization';

import { CONTENT_MAX_LENGTH } from '@/utils/constants/AppConstants';

export default function ScenarioE() {
  const { t } = useTranslation();
  const form = useFormContext();
  const { user } = useAuthorization();
  const editRef = useRef<any>(null);
  const [noteRaw, setNoteRaw] = useState<string>('');
  const {
    field: { value }
  } = useController({
    name: 'steps[0]',
    control: form.control
  });
  const countDict = {
    phoneNumber: form.watch('steps[0].phoneNumber'),
    requestContent: Math.max(noteRaw.length - 1, 0)
  };
  useEffect(() => {
    setNoteRaw(editRef.current?.getText());
  }, [editRef]);

  const optionFilled = Object.values(countDict).filter((i) => !!i).length;

  return (
    <div className='mt-5'>
      <BaseCard title={String(t('basic_information:applicant_information'))} total={Object.keys(countDict).length} questions={optionFilled}>
        <Row gutter={16}>
          <Col span={6}>
            <FormInput
              disabled={true}
              defaultValue={user?.name || ''}
              name=''
              labelTx={String(t('inquiry:user_name_label'))}
              placeholderTx={String(t('placeholder:text_box', { field: String(t('inquiry:user_name_label')) }))}
              tooltipProps={{
                trigger: 'hover',
                className: 'truncate text-ink font-bold',
                title: value.userName
              }}
            />
          </Col>
          <Col span={6}>
            <FormInput
              disabled={true}
              defaultValue={user?.email || ''}
              name=''
              labelTx={String(t('basic_information:email_address'))}
              placeholderTx={String(t('placeholder:text_box', { field: String(t('basic_information:email_address')) }))}
              tooltipProps={{
                trigger: 'hover',
                className: 'truncate text-ink font-bold',
                title: value.email
              }}
            />
          </Col>
          <Col span={6}>
            <FormInput
              disabled={true}
              defaultValue={user?.organizationName}
              name=''
              labelTx={String(t('inquiry:company_label'))}
              placeholderTx={String(t('placeholder:text_box', { field: String(t('inquiry:company_label')) }))}
              tooltipProps={{
                trigger: 'hover',
                className: 'truncate text-ink font-bold',
                title: value.companyName
              }}
            />
          </Col>
          <Col span={6}>
            <FormInput
              defaultValue={value.phoneNumber}
              name={`steps[0].phoneNumber`}
              labelTx={String(t('inquiry:phone_label'))}
              placeholderTx={String(t('placeholder:text_box', { field: String(t('inquiry:phone_label')) }))}
              inputMode='numeric'
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24} className='mt-4'>
            <EditorWithCounter
              label={String(t('basic_information:request_content'))}
              name='steps[0].requestContent'
              required={true}
              limit={CONTENT_MAX_LENGTH}
              defaultValue={value.requestContent}
              toolbar={null}
              placeholder={String(t('placeholder:text_box', { field: String(t('basic_information:request_content')) }))}
              editorWrapperProps={{ className: '!h-[148px]' }}
              ref={editRef}
              onChange={(_content: string, text: string) => {
                setNoteRaw(text);
              }}
            />
          </Col>
        </Row>
      </BaseCard>
      <BaseCard title={'添付ファイル'} className='mt-4'>
        <div className='flex py-2'>
          <div className='py-[2px] mr-[4px]'>
            <InfoIcon width={14.63} height={14.63} />
          </div>
          <div className='font-normal leading-[22px] text-[14px] text-[#525A68]'>
            <p>{t('basic_information:note_for_upload_file')}</p>
          </div>
        </div>
        <FormAttachment name='steps[0].projectAttachments' defaultValue={value.projectAttachments || []} documentType='attachments'></FormAttachment>
      </BaseCard>
    </div>
  );
}
