import { Space } from 'antd';
import React from 'react';
import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BaseButton } from '@/components/base-button/BaseButton';

import Hidden from '@/assets/icons/notification/EyeSlash.svg';

export interface IFooterProps {
  onSubmit: (values: FieldValues) => void;
  totalNoticeChoose: number;
}

const Footer = ({ onSubmit, totalNoticeChoose: total }: IFooterProps) => {
  const { t } = useTranslation();

  return (
    <div className='form-footer h-[54px] px-5'>
      <Space size={16} className='w-full justify-space-between'>
        <span className='body-400'>{t('notification:select_notice', { number: total })}</span>
        <div className='min-w-[92px]'>
          <BaseButton type='tertiary' onClick={onSubmit}>
            <div className='flex w-[100%] gap-x-2'>
              <span className='w-[100%] text-center'>{t('notification:hidden')}</span>
              <div className='flex flex-col justify-center'>
                <Hidden className='icon-down popover-action__dropdown-icon' />
              </div>
            </div>
          </BaseButton>
        </div>
      </Space>
    </div>
  );
};

export default Footer;
