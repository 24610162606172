import { Col, Row, Space } from 'antd';
import { forwardRef } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BaseButton } from '@/components/base-button/BaseButton';
import { BaseCard } from '@/components/base-card/BaseCard';
import { BaseCollapse } from '@/components/base-collapse/BaseCollapse';
import { FormInputSelect } from '@/components/form-input-select/FormInputSelect';
import { FormInput } from '@/components/form-input/FormInput';

import PlusIcon from '@/assets/icons/PlusIcon.svg';

import { LANGUAGE } from '../../project-list/constants';
import { FIRST_FIELD, MAX_FIELD_PERSON_NUMBER } from '../contants';

type Props = {
  formMethod: any;
};

const LocalRepresentativeComponent = forwardRef((props: Props, ref) => {
  const { formMethod } = props;
  const { t } = useTranslation();
  const language = [
    {
      label: t('common:language_jp'),
      value: LANGUAGE.japan,
      id: LANGUAGE.japan
    },
    {
      label: t('common:language_en'),
      value: LANGUAGE.english,
      id: LANGUAGE.english
    }
  ];
  const { fields, append, remove } = useFieldArray({
    name: 'projectLocalSupporters',
    control: formMethod.control
  });

  const addNewProjectLocalSupporters = () => {
    if (fields.length >= MAX_FIELD_PERSON_NUMBER) return;
    append({ stakeholderEmail: '' });
  };

  const watchFields = (index: number) => {
    const fields = [
      `projectLocalSupporters.${index}.name`,
      `projectLocalSupporters.${index}.address`,
      `projectLocalSupporters.${index}.email`,
      `projectLocalSupporters.${index}.language`
    ];
    const watchFields = formMethod.watch(fields);
    const watchFieldsValid = watchFields.filter((field: any) => field);
    return watchFieldsValid.length;
  };

  const handleRemove = (index: number) => {
    remove(index);
  };
  return (
    <BaseCollapse
      ref={ref}
      className='gap-2'
      id='projectLocalSupporters'
      title={t('project:add:local_representative:title')}
      isError={!!formMethod?.formState?.errors?.projectLocalSupporters}
    >
      {fields.map((field, index) => (
        <BaseCard
          key={field.id}
          showIconRemove={fields.length > 1}
          index={index}
          onRemove={handleRemove}
          title={t('project:add:local_representative:local_representative_number', { number: index + 1 })}
          total={index === FIRST_FIELD ? 4 : 3}
          questions={watchFields(index)}
        >
          <Row gutter={16}>
            <Col span={6}>
              <FormInput
                key={field.id}
                defaultValue={formMethod.getValues(`projectLocalSupporters.${index}.name`)}
                name={`projectLocalSupporters.${index}.name`}
                labelTx='project:add:local_representative:name'
                placeholderTx={String(t('project:add:local_representative:name_placeholder'))}
              />
            </Col>
            <Col span={6}>
              <FormInput
                key={field.id}
                defaultValue={formMethod.getValues(`projectLocalSupporters.${index}.address`)}
                name={`projectLocalSupporters.${index}.address`}
                labelTx='project:add:local_representative:address'
                placeholderTx={String(t('project:add:local_representative:address_placeholder'))}
              />
            </Col>
            <Col span={6}>
              <FormInput
                key={field.id}
                defaultValue={formMethod.getValues(`projectLocalSupporters.${index}.email`)}
                name={`projectLocalSupporters.${index}.email`}
                labelTx='project:add:local_representative:email'
                placeholderTx={String(t('project:add:local_representative:email_placeholder'))}
              />
            </Col>
            {index === FIRST_FIELD && (
              <Col span={6}>
                <FormInputSelect
                  name={`projectLocalSupporters.${index}.language`}
                  label={t('project:add:local_representative:language')}
                  options={language}
                  placeholderTx={String(t('project:add:local_representative:language_placeholder'))}
                  width={'50%'}
                />
              </Col>
            )}
          </Row>
        </BaseCard>
      ))}
      <Space size={16}>
        <div className='min-w-[140px]'>
          <BaseButton
            icon={<PlusIcon />}
            disabled={fields.length === MAX_FIELD_PERSON_NUMBER}
            type='secondary'
            block
            size='medium'
            onClick={addNewProjectLocalSupporters}
          >
            {t('project:add:local_representative:add')}
          </BaseButton>
        </div>
      </Space>
    </BaseCollapse>
  );
});

export default LocalRepresentativeComponent;
