import { Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormInput } from '@/components/form-input/FormInput';
import { FormSelect } from '@/components/form-select/FormSelect';

import useOptionsGlobal from '@/hooks/useOptionsGlobal';

import { useFormScenarioAContext } from '../..';
import CounterLabel from '../../../components/counter-label';
import { relativePath } from '../../../utils';

type Props = {
  parentPath: string;
};
const AssignmentInfo = (props: Props) => {
  const { parentPath } = props;
  const formMethod = useFormScenarioAContext();
  const { t } = useTranslation();
  const { customCountryOptions, filterOption } = useOptionsGlobal();
  const PATHS = {
    COUNTRY_ID: relativePath('countryId', parentPath),
    COMPANY_NAME: relativePath('companyName', parentPath),
    ADDRESS: relativePath('address', parentPath),
    VISA_CATEGORY: relativePath('visaCategoryId', 'steps[0]')
  };
  const formWatching = formMethod.watch([PATHS.COMPANY_NAME, PATHS.ADDRESS, PATHS.COUNTRY_ID]);
  const [companyName, address] = formWatching;

  const counters = () => {
    let current = 0;
    const total = formWatching.length ?? 0;
    for (const item of formWatching) {
      if (item) {
        current++;
      }
    }
    return `${current}/${total}`;
  };

  return (
    <>
      <CounterLabel title={t('project:scenario_a:assignment_information')} count={counters()} />
      <Row gutter={16}>
        <Col span={6}>
          <FormInput
            required
            defaultValue={companyName}
            name={PATHS.COMPANY_NAME}
            labelTx='basic_information:assignment_training_company_name'
            placeholderTx='basic_information:place_holder_company_name_of_assignee'
          />
        </Col>
        <Col span={6}>
          <FormSelect
            required
            id={PATHS.COUNTRY_ID}
            name={PATHS.COUNTRY_ID}
            label={t('basic_information:destination_training_country')}
            options={customCountryOptions}
            filterOption={filterOption}
            placeholder={String(t('basic_information:place_holder_second_nationality'))}
          />
        </Col>
        <Col span={12}>
          <FormInput
            required
            defaultValue={address}
            name={PATHS.ADDRESS}
            label={t('basic_information:assignment_training_city')}
            placeholderTx={String(t('basic_information:place_holder_address_of_the_destination'))}
          />
        </Col>
      </Row>
    </>
  );
};

export default AssignmentInfo;
