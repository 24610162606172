import CommentWidgetContainer from './Container';

const CommentWidget = {
  /**
   * Use these styles to apply the CommentWidget layout and styling.
   * @example
   * ```scss
   * .wrapper {
   *   @extend .grid-layout--has-comment-widget;
   *   @include commentWidgetStyle(calc(100vh - 116px));
   *   .header-view-task-detail-project {
   *     @extend .grid-header--has-comment-widget;
   *   }
   *   .view-task-detail-project__content {
   *     @extend .grid-body--has-comment-widget;
   *   }
   * }
   * ```
   */
  Container: CommentWidgetContainer
};
export default CommentWidget;
