import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import './EmptyTextData.scss';

export interface IEmptyTextDataProps {
  className?: string;
  text?: string;
}
const EmptyTextData = ({ className, text = 'comment:no_data' }: IEmptyTextDataProps) => {
  const { t } = useTranslation();
  return <div className={classNames('empty-text-data ', className)}>{t(text)}</div>;
};

export default EmptyTextData;
