import { useAppDispatch } from '@/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { cloneDeep, isEmpty } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import EmptyDataWithIcon from '@/components/EmptyData/EmptyDataWithIcon';
import AppTooltip from '@/components/app-tooltip/AppTooltip';
import { BaseButton } from '@/components/base-button/BaseButton';
import FormBaseFilter, { RefActionControl } from '@/components/base-filter';
import { showBasePopup } from '@/components/base-popup/BasePopup';
import TableFetch from '@/components/common/table-fetch';

import { setAlertNotification } from '@/redux/globalReducer';

import useAuthorization, * as s from '@/hooks/useAuthorization';
import { useFilterConfigs } from '@/hooks/useFilterConfigs';
import useMutation from '@/hooks/useMutation';
import useOptionsGlobal from '@/hooks/useOptionsGlobal';

import { ROUTER_IDS } from '@/utils/constants';
import { FORMAT_DATE_JP, TYPE } from '@/utils/constants/AppConstants';
import { HTTP_STATUS_CODE } from '@/utils/constants/Http';
import { DataViewer } from '@/utils/helpers/common';
import { IDocumentMasterForm } from '@/utils/interfaces/form';
import { IDocument } from '@/utils/interfaces/masterdata';

import IconAddNew from '@/assets/icons/base-filter/icon-add-new.svg';

import {
  _generateQueryCheckDuplicate,
  formatDateTime,
  formatDayjsToString,
  getEndOfDay,
  handleLinkClick,
  renderSpecialText,
  sanitizeAndRemoveInsecureLinks
} from '../../../utils/helpers/globalHelper';
import { Ii18n } from '../../../utils/interfaces/i18n';
import CreateEditDocument from './components/CreateEditDocument';
import PrimaryFormFilter from './components/PrimaryFormFilter';
import { DEFAULT_DOCUMENT_MASTER, DEFAULT_FILTER_SEARCH_FORM, FormDocument, generateFilter } from './constant';
import { IFilterSearchForm, IFilters, IQueryFilterParams } from './utils';

import './DocumentMaster.scss';

function DocumentMasterManagement() {
  const { t }: Ii18n = useTranslation();
  const [document, setDocument] = useState<IDocumentMasterForm>();
  const { countryOptions } = useOptionsGlobal();
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const { data, update: updateFilterConfigs } = useFilterConfigs<IFilterSearchForm>(ROUTER_IDS.DOCUMENT_MASTER_LIST);
  const dispatch = useAppDispatch();
  const isMounted = useRef(false);
  const { isInternalUser, hasPermission } = useAuthorization();
  const filterTableRef = useRef<RefActionControl>({ toggle: () => {} });

  /**
   * Define form create
   */

  const formMethod = useForm<IDocumentMasterForm>({
    mode: 'all',
    shouldUnregister: false,
    resolver: yupResolver(FormDocument),
    defaultValues: DEFAULT_DOCUMENT_MASTER
  });

  /**
   * Define column table
   */
  const columns: ColumnsType<IDocument> = [
    {
      key: 'managementCode',
      dataIndex: 'managementCode',
      title: t('master_data:document_management:document_name') || '',
      render(text, record) {
        return (
          <>
            {hasPermission(s.DOCUMENT_LIST_BUTTON_CREATE, s.V) ? (
              <Link onClick={() => handleEditModal(record)} to={''} className='link-custom text-overflow-1-line-clamp'>
                <AppTooltip className='block max-w-full truncate w-fit' title={renderSpecialText(text)}>
                  {renderSpecialText(text)}
                </AppTooltip>
              </Link>
            ) : (
              renderSpecialText(text)
            )}
          </>
        );
      },
      width: 250
    },
    {
      key: 'name',
      dataIndex: 'name',
      title: t('master_data:document_management:submitter') || '',
      width: 160,
      render: (text, record: IDocument) => (
        <div className='inline-block'>
          <AppTooltip
            title={<span className='tooltip-title' dangerouslySetInnerHTML={{ __html: sanitizeAndRemoveInsecureLinks((text as string) || '') }} />}
          >
            <span
              className='whitespace-pre-wrap raw-data break-all text-overflow-3-line-clamp'
              dangerouslySetInnerHTML={{ __html: sanitizeAndRemoveInsecureLinks((text as string) || '') }}
              onClick={(e) => handleLinkClick(e)}
            />
          </AppTooltip>
        </div>
      )
    },
    {
      key: 'numOfCopy',
      dataIndex: 'numOfCopy',
      title: t('master_data:document_management:numOfCopy') || '',
      width: 120
    },
    {
      key: 'note',
      dataIndex: 'note',
      title: t('master_data:document_management:note') || '',
      render: (text, record: IDocument) => (
        <div className='inline-block'>
          <AppTooltip
            title={<span className='tooltip-title' dangerouslySetInnerHTML={{ __html: sanitizeAndRemoveInsecureLinks((text as string) || '') }} />}
          >
            <span
              className='whitespace-pre-wrap raw-data break-all text-overflow-3-line-clamp'
              dangerouslySetInnerHTML={{ __html: sanitizeAndRemoveInsecureLinks((text as string) || '') }}
              onClick={(e) => handleLinkClick(e)}
            />
          </AppTooltip>
        </div>
      )
    },
    {
      key: 'memo',
      dataIndex: 'memo',
      title: t('master_data:document_management:memo') || '',
      width: 250,
      render: (text, record: IDocument) => (
        <div className='inline-block'>
          <AppTooltip
            title={<span className='tooltip-title' dangerouslySetInnerHTML={{ __html: sanitizeAndRemoveInsecureLinks((text as string) || '') }} />}
          >
            <span
              className='whitespace-pre-wrap raw-data break-all text-overflow-3-line-clamp'
              dangerouslySetInnerHTML={{ __html: sanitizeAndRemoveInsecureLinks((text as string) || '') }}
              onClick={(e) => handleLinkClick(e)}
            />
          </AppTooltip>
        </div>
      )
    },
    {
      key: 'countryName',
      dataIndex: 'countryName',
      title: t('master_data:document_management:country') || '',
      width: 150
    },
    {
      key: 'updatedName',
      dataIndex: 'updatedName',
      title: t('master_data:document_management:author') || '',
      width: 140,
      render: (text, record: IDocument) => (
        <div className='inline-block'>
          <AppTooltip
            title={
              <span
                className='tooltip-title'
                dangerouslySetInnerHTML={{
                  __html: sanitizeAndRemoveInsecureLinks((text as string) || '')
                }}
              />
            }
          >
            <span
              className='whitespace-pre-wrap raw-data break-all text-overflow-3-line-clamp'
              dangerouslySetInnerHTML={{ __html: sanitizeAndRemoveInsecureLinks((text as string) || '') }}
              onClick={(e) => handleLinkClick(e)}
            />
          </AppTooltip>
        </div>
      )
    },
    {
      key: 'updatedDate',
      title: t('project:fields:updated_date'),
      dataIndex: 'updatedDate',
      render: (_: any, record) => (
        <AppTooltip title={dayjs(record?.updatedDate || record?.creaedDate).format(FORMAT_DATE_JP)}>
          {dayjs(record?.updatedDate || record?.creaedDate).format(FORMAT_DATE_JP)}
        </AppTooltip>
      ),
      sorter: true,
      width: 140
    }
  ];

  /**
   * Handle create new document record
   */
  const handleOpenCreateModal = () => {
    formMethod.reset(DEFAULT_DOCUMENT_MASTER);
    setDocument(DEFAULT_DOCUMENT_MASTER);
    setOpenCreateModal(true);
  };

  const { isDirty } = formMethod.formState;

  const wrapperRef = useRef<any>();

  const getDefaultFilters = () => {
    const results = {
      form: cloneDeep(DEFAULT_FILTER_SEARCH_FORM),
      query: {}
    };
    if (data && !isEmpty(data)) {
      results.form.primary = { ...data.primary };
      const { updatedDateFrom, updatedDateTo, countryId } = data.primary ?? {};
      results.query = {
        filter: generateFilter({
          keyword: '',
          updatedDateFrom: updatedDateFrom ? formatDateTime(updatedDateFrom) : undefined,
          updatedDateTo: updatedDateTo ? formatDateTime(updatedDateTo) : undefined,
          countryId
        })
      };
    }
    return results;
  };
  const [filter, setFilter] = useState<IFilters>(getDefaultFilters().query);

  const formFilter = useForm<IFilterSearchForm>({
    defaultValues: getDefaultFilters().form
  });

  const handleCloseCreateModal = useCallback(async () => {
    if (isDirty) {
      const title = t('common:MSG_C_002:title');
      const msg = t('common:MSG_C_002:description');
      const showPopup = await showBasePopup({
        title,
        msg,
        type: 'discard',
        modalConfigs: { getContainer: () => wrapperRef.current }
      });
      await setOpenCreateModal(showPopup !== 'confirm');
    } else setOpenCreateModal(false);
  }, [isDirty]);

  const handleEditModal = (row: IDocument) => {
    const data = {
      id: row.id,
      name: row.managementCode,
      numOfCopy: row.numOfCopy,
      submitterName: row.name,
      countryId: row.countryId,
      note: sanitizeAndRemoveInsecureLinks(row?.note),
      memo: row.memo,
      version: row.version,
      createdName: row.createdName,
      createdDate: formatDateTime(row.createdDate),
      updatedName: row.updatedName,
      updatedDate: formatDateTime(row.updatedDate)
    };
    formMethod.reset((pre) => {
      return {
        ...pre,
        ...data
      };
    });
    setTimeout(() => formMethod.reset((pre) => ({ ...pre, isDirty: false })), 0);
    setDocument(data);
    setOpenCreateModal(true);
  };

  const { loading, mutate: addEditDocument } = useMutation('/mst/Documents', {
    method: document?.id ? 'PUT' : 'POST',
    bodyType: 'json',
    showToastError: true,
    showToastSuccess: true,
    defaultSuccessMsg: t('common:MSG_C_003', {
      item: t(document?.id ? 'button:keep' : 'button:create'),
      name: t('master_data:phrase:title_label') || ''
    }),
    paramMsg: {
      item: t('master_data:phrase:title_label'),
      field: t('master_data:phrase:title_label'),
      name: t('master_data:phrase:title_label')
    }
  });

  const handleCreateMasterData = () => {
    formMethod.handleSubmit(async (formValues: IDocumentMasterForm) => {
      const data = {
        name: formValues?.submitterName || '',
        numOfCopy: formValues.numOfCopy || '',
        managementCode: formValues.name || '',
        note: formValues.note || '',
        memo: formValues.memo ?? ''
      };
      const body = formValues.id ? { ...data, id: formValues.id, version: formValues.version } : { ...data, countryId: formValues?.countryId || '' };
      const result = await addEditDocument({ ...body });
      if (result?.status === HTTP_STATUS_CODE.SUCCESS) {
        setOpenCreateModal(false);
        setFilter({ ...filter });
      }
    })();
  };

  const { mutate: deleteMultipleTasks } = useMutation(`/mst/Documents`, {
    method: 'DELETE',
    showToastError: true,
    paramMsg: {
      field: '',
      item: '',
      reason: t('master_data:error_msg:document_in_used')
    }
  });

  const handleDelete = async (keys: React.Key[], records: any[]) => {
    const idsToDelete = records.map((itemDocument) => {
      return {
        id: itemDocument.id || '',
        version: itemDocument.version || ''
      };
    });
    if (!idsToDelete.length) {
      return 0;
    }
    const result = await deleteMultipleTasks({
      documents: idsToDelete
    });
    if (!result) return 0;
    const { data } = result;
    if (data.successCount === keys.length) {
      dispatch(
        setAlertNotification({
          show: true,
          type: TYPE.SUCCESS,
          message: t('common:MSG_C_003', { item: t('master_data:document_management:delete') })
        })
      );
    }

    if (data.successCount < keys.length) {
      dispatch(
        setAlertNotification({
          show: true,
          type: TYPE.SUCCESS,
          message: t('common:MSG_C_020', { deletable: data.successCount, total: keys.length })
        })
      );
    }
    return data.successCount;
  };

  /**
   * fetch data from server when change search params
   */
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const generateFilterHeader = () => {
    return (
      <div className='pb-3'>
        <FormProvider {...formFilter}>
          <FormBaseFilter
            onSubmit={(values) => {
              updateFilter(values as IFilterSearchForm);
            }}
            searchBox={{ name: 'keyword', placeholder: DataViewer.display(t(`common:DOCUMENT_FIELDS_SEARCH_PLACEHOLDER`)) }}
            tagSection={{
              renderTag: (fieldKey, fieldValue) => {
                if (fieldKey === 'countryId') {
                  return <>{countryOptions?.find((i) => i.id === fieldValue)?.label ?? fieldValue}</>;
                }

                if (['updatedDateFrom', 'updatedDateTo'].includes(fieldKey)) {
                  return <>{formatDateTime(fieldValue)}</>;
                }

                return <>{fieldValue}</>;
              }
            }}
            primaryAction={{
              label: 'button:filter',
              name: 'primary',
              popoverContent: (
                <PrimaryFormFilter
                  onSubmit={(values) => {
                    formFilter.reset((previous) => ({ ...previous, primary: { ...(values as any) } }));
                    formFilter.handleSubmit(updateFilter)();
                  }}
                  defaultValues={formFilter.getValues('primary')}
                />
              ),
              refControl: filterTableRef
            }}
            secondaryAction={null}
          />
        </FormProvider>
      </div>
    );
  };

  const updateFilter = (values: IFilterSearchForm) => {
    const { keyword, primary } = values ?? {};
    const { updatedDateFrom, updatedDateTo } = primary ?? {};
    const formValues: IQueryFilterParams = {
      keyword,
      countryId: values?.primary?.countryId,
      updatedDateFrom: updatedDateFrom ? formatDayjsToString(updatedDateFrom) : undefined,
      updatedDateTo: updatedDateTo ? getEndOfDay(updatedDateTo) : undefined
    };
    setFilter({ filter: generateFilter(formValues) });
    updateFilterConfigs(values);
  };

  const { countryId, name } = formMethod?.watch();

  useEffect(() => {
    if (!name) return;
    formMethod.trigger('name');
  }, [countryId]);

  return (
    <div className='document-master-container'>
      <CreateEditDocument
        formMethod={formMethod}
        openCreateModal={openCreateModal}
        handleCloseCreateModal={handleCloseCreateModal}
        document={document}
        handleCreateMasterData={handleCreateMasterData}
        loading={loading}
        wrapperRef={wrapperRef}
      />
      <div className='document-master-list'>
        <div className='grid grid-cols-2 gap-4 page-list-header mb-[12px]'>
          <div className='title-24'>{t('sider:master_data_list_document')}</div>
          <div className='text-end'>
            {hasPermission(s.DOCUMENT_LIST_BUTTON_CREATE, s.V) && (
              <BaseButton
                size='medium'
                icon={<IconAddNew style={{ verticalAlign: 'sub' }} />}
                className='float-right'
                onClick={() => handleOpenCreateModal()}
              >
                {t('template_layout:create_button')}
              </BaseButton>
            )}
          </div>
        </div>
        <div className='page-content'>
          <div className='data-table'>
            <TableFetch
              tableHeader={generateFilterHeader()}
              apiEndpoint='/mst/documents/search'
              apiMethod='POST'
              columns={columns}
              filterDefault={filter}
              scrollTable={{ maxHeight: '100vh - 232px' }}
              showDelete={hasPermission(s.DELETE_DOCUMENT_LIST, s.V) ? true : false}
              showDownload={false}
              showAddLine={false}
              handleDeleteClick={hasPermission(s.DELETE_DOCUMENT_LIST, s.C) ? handleDelete : undefined}
              deleteConfirmPopup={{
                type: 'discard',
                title: t('common:MSG_029.1_title', { item: t('master_data:document_management:document') }) ?? '',
                msg:
                  `${t('common:MSG_029.1_description', { item: t('master_data:document_management:document') })} \n ${t(
                    'common:MSG_029_description2',
                    {
                      item: t('master_data:document_management:document') || ''
                    }
                  )}` || ''
              }}
              emptyDataWithoutFilter={
                <EmptyDataWithIcon
                  title={{
                    content: 'master_data:document_management:no_data_title'
                  }}
                  description={{
                    content: 'master_data:document_management:no_data_description'
                  }}
                  button={{
                    label: 'master_data:phrase:create',
                    props: {
                      onClick: handleOpenCreateModal
                    }
                  }}
                />
              }
              rowSelection={isInternalUser ? null : {}}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DocumentMasterManagement;
