import WarningIcon from '@/assets/icons/Warning.svg';

const ErrorFallback = () => {
  const codeWrapped = (content: string) => {
    return <code className='font-bold bg-white p-[2px] text-black'>{content}</code>;
  };
  return (
    <div className='bg-green30 p-2 m-2 rounded tex'>
      <div className='flex items-center gap-[5px]'>
        <WarningIcon width={20} height={20} />
        <p className='subtitle-18 !text-negative'>Error</p>
      </div>
      <p>
        This Component must be wrapped by
        {codeWrapped(`<FormProvider></FormProvider>`)}
      </p>
      <p>
        Define {codeWrapped(`formMethod = useForm()`)}
        And you can control apply filter with function
        {codeWrapped(`methods.handleSubmit(onSubmit)`)}
      </p>
    </div>
  );
};

export default ErrorFallback;
