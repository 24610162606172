import QueryFactory from '@/utils/helpers/filters/base';
import { convertFullWidthToHalfWidth } from '@/utils/helpers/globalHelper';

export interface IQueryFilterParams {
  keyword?: string;
}

export const generateFilter = (params?: IQueryFilterParams): string => {
  const filterFactory = new QueryFactory();

  if (params?.keyword) {
    const fields = ['nameSearch.ToLower()', 'createdNameSearch.ToLower()'];
    filterFactory.searchingBy(fields, convertFullWidthToHalfWidth(params.keyword).toLowerCase());
  }

  return filterFactory.sanitize().stringifyData();
};
