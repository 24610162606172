import CaretDown from '@/layouts/assets/icons/CaretDown.svg';
import { Col, Collapse, Row } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IStepFive, IStepFour, IStepSix, IStepThree, IStepTwo } from '@/pages/project-management/add-edit/models/scenarioB';
import { useFormScenarioBContext } from '@pages/project-management/add-edit/scenario/type-b';
import { GENDER_OPTIONS } from '@pages/project-management/add-edit/scenario/type-b/constants';
import { PARENT_PATH as STEP_FIVE_PARENT_PATH } from '@pages/project-management/add-edit/scenario/type-b/form/step-five';
import { PARENT_PATH as STEP_FOUR_PARENT_PATH } from '@pages/project-management/add-edit/scenario/type-b/form/step-four';
import { PARENT_PATH as STEP_SIX_PARENT_PATH } from '@pages/project-management/add-edit/scenario/type-b/form/step-six';
import { PARENT_PATH as STEP_THREE_PARENT_PATH } from '@pages/project-management/add-edit/scenario/type-b/form/step-three';
import { PARENT_PATH as STEP_TWO_PARENT_PATH } from '@pages/project-management/add-edit/scenario/type-b/form/step-two';

import { FormInput } from '@/components/form-input/FormInput';
import { FormSelect } from '@/components/form-select/FormSelect';

import useAuthorization from '@/hooks/useAuthorization';
import useOptionsGlobal from '@/hooks/useOptionsGlobal';

import { Counters } from '@/utils/helpers/counters';

import AdditionalInfo from './AdditionalInfo';
import AssignmentInfo from './AssignmentInfo';
import FamilyInfo from './FamilyInfo';
import LocalPresentativeInfo from './LocalPresentativeInfo';
import SystemUserInfo from './SystemUserInfo';
import TransfereeInfo from './TransfereeInfo';
import TravelInfo from './TravelInfo';
import VisaInfo from './VisaInfo';

import './styles.scss';

interface ILabelTitle {
  title: string;
  questions?: number;
  total?: number;
  size?: number | string;
}
const LabelTitle = (props: ILabelTitle) => {
  const { title, questions, total, size = 18 } = props;
  const { t } = useTranslation();
  return (
    <div className='flex flex-row'>
      <span className={`subtitle-${size}`}>{t(title)}</span>
      {!!total && (
        <div className='ml-[8px] pr-[8px] pl-[8px] bg-gray2 rounded text-[14px] font-bold'>
          {questions}/{total}
        </div>
      )}
    </div>
  );
};

const ScenarioBStepSeven = () => {
  const { t } = useTranslation();
  const { watch } = useFormScenarioBContext();
  const [activeKey, setActiveKey] = useState<string[]>(['1', '2', '3', '4', '5', '6']);
  const [step2, step3, step4, step5, step6] = watch([
    STEP_TWO_PARENT_PATH,
    STEP_THREE_PARENT_PATH,
    STEP_FOUR_PARENT_PATH,
    STEP_FIVE_PARENT_PATH,
    STEP_SIX_PARENT_PATH
  ]) as [IStepTwo, IStepThree, IStepFour, IStepFive, IStepSix];
  const { user } = useAuthorization();
  const { countryOptions } = useOptionsGlobal();
  const genderOptions = GENDER_OPTIONS.map((item) => ({ ...item, label: t(item.label) }));

  const renderExpandIcon = (isActive: boolean | undefined) => {
    return <CaretDown type='caret-right' className={`${isActive ? 'rotate-0' : '-rotate-90'}`} />;
  };

  const handleChange = (key: string | string[]) => {
    setActiveKey(Array.isArray(key) ? key : [key]);
  };

  const schemaApplicationInfo = { data: ['name', 'organizationName'] };
  const counterApplicationInfo = new Counters(schemaApplicationInfo, user);
  return (
    <div className='scenario-b-step-seven'>
      <Collapse
        activeKey={activeKey}
        collapsible='header'
        className='bg-white flex flex-col gap-[12px]'
        onChange={handleChange}
        items={[
          {
            key: '1',
            label: (
              <LabelTitle
                title={t('basic_information:applicant_information')}
                total={schemaApplicationInfo.data.length}
                questions={counterApplicationInfo.get('data')}
              ></LabelTitle>
            ),
            className: 'scenario-b-collapse-section',
            children: (
              <Row gutter={16}>
                <Col span={12}>
                  <FormInput value={user?.name} disabled name='' labelTx='basic_information:applicant_name' placeholderTx='' />
                </Col>
                <Col span={12}>
                  <FormSelect
                    value={user?.organizationName}
                    options={[]}
                    disabled
                    name=''
                    label={t('basic_information:applicant_company')}
                    placeholder=''
                  />
                </Col>
              </Row>
            )
          },
          {
            key: '2',
            label: <LabelTitle title={t('project:scenario_b:applicant_title')}></LabelTitle>,
            className: 'scenario-b-collapse-section',
            children: (
              <div className='flex flex-col gap-[8px]'>
                {<TransfereeInfo data={step2} countries={countryOptions} genders={genderOptions} />}
                {<AssignmentInfo data={step2} countries={countryOptions} />}
                {<VisaInfo data={step2} />}
                {<TravelInfo data={step2} />}
              </div>
            )
          },
          {
            key: '3',
            label: <LabelTitle title={t('basic_information:family_information')}></LabelTitle>,
            className: 'scenario-b-collapse-section',
            children: <FamilyInfo data={step3} countries={countryOptions} genders={genderOptions} />
          },
          {
            key: '4',
            label: <LabelTitle title={t('project:scenario_b:local_supporter')}></LabelTitle>,
            className: 'scenario-b-collapse-section',
            children: <LocalPresentativeInfo data={step4} />
          },
          {
            key: '5',
            label: <LabelTitle title={t('basic_information:add_system_user')}></LabelTitle>,
            className: 'scenario-b-collapse-section',
            children: <SystemUserInfo data={step5} user={user} />
          },
          {
            key: '6',
            label: <LabelTitle title={t('project:scenario_b:additional_information')}></LabelTitle>,
            className: 'scenario-b-collapse-section',
            children: <AdditionalInfo data={step6} />
          }
        ]}
        bordered={false}
        expandIcon={({ isActive }) => renderExpandIcon(isActive)}
      />
    </div>
  );
};
export default ScenarioBStepSeven;
