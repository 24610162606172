import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { IProjectQuestion } from '@pages/project-management/project-questionaire/components/question-list';
import { useGetExternalQuestions } from '@pages/project-management/project-questionaire/external-questions/hooks/useGetExternalQuestions';

import EmptyDataWithIcon from '@components/EmptyData/EmptyDataWithIcon';
import { BaseButton } from '@components/base-button/BaseButton';
import TableBase, { FetchColumnsType } from '@components/common/table-base';
import CommonTag from '@components/common/tag/CommonTag';

import { EXTERNAL_QUESTION_DETAIL_URL } from '@utils/constants/RouteContants';
import { DataViewer } from '@utils/helpers/common';

import IconLock from '@assets/icons/LockKey.svg';

export const ExternalQuestionList = () => {
  const { t } = useTranslation();
  const { token = '' } = useParams();
  const navigate = useNavigate();

  const { questions, loading } = useGetExternalQuestions(token);

  const handleOpenDetail = (id: string) => {
    if (!id || !token) return;
    navigate(EXTERNAL_QUESTION_DETAIL_URL(token, id));
  };

  const columns: FetchColumnsType<IProjectQuestion> = [
    {
      title: t('project:question_list:applicant'),
      key: 'name',
      width: 240,
      className: 'max-w-[240px]',
      render: (_value: any, record: IProjectQuestion) => (
        <div className='flex'>
          {record.lock ? <IconLock className='mr-2' /> : ''}
          {DataViewer.display(record?.name)}
        </div>
      ),
      tooltip: (_value: any, record: IProjectQuestion) => DataViewer.display(record?.name),
      ellipsis: true
    },
    {
      title: t('project:question_list:last_name'),
      dataIndex: 'createdName',
      width: 240,
      className: 'max-w-[240px]',
      key: 'lastName',
      render: (_: any, record: IProjectQuestion) => DataViewer.display(record?.lastName),
      tooltip: (_: any, record: IProjectQuestion) => DataViewer.display(record?.lastName)
    },
    {
      title: t('project:question_list:first_name'),
      key: 'firstName',
      width: 240,
      className: 'max-w-[240px]',
      render: (_: any, record: IProjectQuestion) => DataViewer.display(record?.firstName),
      tooltip: (_: any, record: IProjectQuestion) => DataViewer.display(record?.firstName),
      ellipsis: true
    },
    {
      title: t('project:question_list:answer_status'),
      key: 'answer_status',
      width: 240,
      className: 'max-w-[240px]',
      render: (_: any, record: IProjectQuestion) => (
        <CommonTag type={record.totalAnswer < record.totalQuestion ? 'default' : 'success'} text={`${record.totalAnswer}/${record.totalQuestion}`} />
      )
    },
    {
      title: t('project:question_list:action'),
      key: 'check_answer',
      render: (_: any, record: IProjectQuestion) => (
        <BaseButton type='secondary' size='medium' className='!h-[38px] !w-[100px]' onClick={() => handleOpenDetail(record?.id)}>
          {record.lock ? t('project:question_list:check_answer') : t('project:question_list:input_answer')}
        </BaseButton>
      )
    }
  ];

  return (
    <div className='mx-auto max-w-[1880px]'>
      <h3 className='text-24 font-bold mt-0 mb-[12px] text-lnk'>{t('template_layout:questionnaire')}</h3>
      <TableBase
        columns={columns}
        scrollTable={{ maxHeight: '100vh - 200px' }}
        dataSource={questions}
        rowSelection={null}
        locale={{
          emptyText: (
            <span className={classNames(loading && 'hidden')}>
              <EmptyDataWithIcon
                title={null}
                description={{
                  content: 'common:no_data'
                }}
                button={null}
              />
            </span>
          )
        }}
        loading={loading}
      />
    </div>
  );
};
