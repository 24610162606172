import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// Define the initial state using that type
const initialState: any = {
  questionActive: '',
  loadingQuestionnaire: false
};

export const templateSpaceName = 'template';

export const templateSlice = createSlice({
  name: templateSpaceName,
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setQuestionActive: (state, action: PayloadAction<any>) => {
      state.questionActive = action.payload;
    },
    setLoadingQuestionnaire: (state, action: PayloadAction<boolean>) => {
      state.loadingQuestionnaire = action.payload;
    }
  }
});

export const { setQuestionActive, setLoadingQuestionnaire } = templateSlice.actions;

export default templateSlice.reducer;
