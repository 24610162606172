// Required documents table permission

export const REQUIRED_DOCUMENT_SELECT_ROW = 'REQUIRED_DOCUMENT_SELECT_ROW';
export const REQUIRED_DOCUMENT_NO_LABEL = 'REQUIRED_DOCUMENT_NO_LABEL';
export const REQUIRED_DOCUMENT_SELECT_NAME = 'REQUIRED_DOCUMENT_SELECT_NAME';
export const REQUIRED_DOCUMENT_EDIT_NUMBER_OF_COPY = 'REQUIRED_DOCUMENT_EDIT_NUMBER_OF_COPY';
export const REQUIRED_DOCUMENT_PICK_SUBMISSION_DATELINE = 'REQUIRED_DOCUMENT_PICK_SUBMISSION_DATELINE';
export const REQUIRED_DOCUMENT_SELECT_SUBMISSION_METHOD = 'REQUIRED_DOCUMENT_SELECT_SUBMISSION_METHOD';
export const REQUIRED_DOCUMENT_EDIT_NOTE = 'REQUIRED_DOCUMENT_EDIT_NOTE';
export const REQUIRED_DOCUMENT_SELECT_SUBMITTER = 'REQUIRED_DOCUMENT_SELECT_SUBMITTER';
export const REQUIRED_DOCUMENT_CHECKED_DOCUMENT_CONFIRMED = 'REQUIRED_DOCUMENT_CHECKED_DOCUMENT_CONFIRMED';
export const REQUIRED_DOCUMENT_ADD_LINE = 'REQUIRED_DOCUMENT_ADD_LINE';
export const REQUIRED_DOCUMENT_DELETE_LINE = 'REQUIRED_DOCUMENT_DELETE_LINE';
export const REQUIRED_DOCUMENT_INSERT_LINE = 'REQUIRED_DOCUMENT_INSERT_LINE';

// Lack for add new line and delete line

// Required documents table permission
export const ADDITIONAL_DOCUMENT_SELECT_ROW = 'ADDITIONAL_DOCUMENT_SELECT_ROW';
export const ADDITIONAL_DOCUMENT_SELECT_NAME = 'ADDITIONAL_DOCUMENT_SELECT_NAME';
export const ADDITIONAL_DOCUMENT_EDIT_NUMBER_OF_COPY = 'ADDITIONAL_DOCUMENT_EDIT_NUMBER_OF_COPY';
export const ADDITIONAL_DOCUMENT_PICK_SUBMISSION_DATELINE = 'ADDITIONAL_DOCUMENT_PICK_SUBMISSION_DATELINE';
export const ADDITIONAL_DOCUMENT_SELECT_SUBMISSION_METHOD = 'ADDITIONAL_DOCUMENT_SELECT_SUBMISSION_METHOD';
export const ADDITIONAL_DOCUMENT_EDIT_NOTE = 'ADDITIONAL_DOCUMENT_EDIT_NOTE';
export const ADDITIONAL_DOCUMENT_SELECT_SUBMITTER = 'ADDITIONAL_DOCUMENT_SELECT_SUBMITTER';
export const ADDITIONAL_DOCUMENT_CHECKED_DOCUMENT_CONFIRMED = 'ADDITIONAL_DOCUMENT_CHECKED_DOCUMENT_CONFIRMED';
export const ADDITIONAL_DOCUMENT_ADD_LINE = 'ADDITIONAL_DOCUMENT_ADD_LINE';
export const ADDITIONAL_DOCUMENT_DELETE_LINE = 'ADDITIONAL_DOCUMENT_DELETE_LINE';
export const ADDITIONAL_DOCUMENT_INSERT_LINE = 'ADDITIONAL_DOCUMENT_INSERT_LINE';
