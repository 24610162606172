import BaseTextArea, { IBaseTextAreaProps } from '@/components/common/base-text-area';
import FormItem from '@/components/common/form-item';

import { MAX_LENGTH_TEXT_AREA } from '../../constants';
import { ValidateRules } from '../../helpers';
import { AnswerType } from '../../modals';

interface ITextAreaProps extends IBaseTextAreaProps {
  name?: string;
  type?: AnswerType;
}
const TextArea: React.FC<ITextAreaProps> = ({ readOnly, placeholder, name, type, ...restProps }) => {
  const validateObj =
    type && ValidateRules[type]
      ? {
          [type]: ValidateRules[type]
        }
      : undefined;

  if (name) {
    return (
      <FormItem showErrorOption='icon' name={name} rules={{ maxLength: MAX_LENGTH_TEXT_AREA, validate: validateObj }}>
        <BaseTextArea className='h-full' readOnly={readOnly} placeholder={readOnly ? '' : placeholder} hideBorder {...restProps} />
      </FormItem>
    );
  }

  return <BaseTextArea className='h-full' readOnly={readOnly} placeholder={readOnly ? '' : placeholder} hideBorder {...restProps} />;
};

export default TextArea;
