import i18n from '@/i18n';

import { STATUS } from '../DummyData/ProjectStatus';

const disabledByStatus = (status: string): boolean => {
  if (status && (status === STATUS.COMPLETED || status === STATUS.CANCEL)) {
    return true;
  }
  return false;
};

const isShowLabelProject = (status: STATUS): boolean => {
  return [STATUS.CANCEL, STATUS.COMPLETED].includes(status);
};

const renderProjectCode = (projectData: any, projectCode: string) => {
  if (!projectData) return;
  const projectUnpublished = !projectData?.isPublished ? i18n.t('announcement:list:label:unpublished') : '';
  return `${projectUnpublished} ${projectCode}`;
};

export { disabledByStatus, isShowLabelProject, renderProjectCode };
