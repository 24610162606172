import { t } from 'i18next';
import * as Yup from 'yup';

import { QUERY_OPERATION, QUERY_TYPE } from '@/utils/constants';
import { NOTE_MAX_LENGTH, TEXT_FIELD_MAX_LENGTH } from '@/utils/constants/AppConstants';
import { convertFullWidthToHalfWidth } from '@/utils/helpers/globalHelper';
import { stringifyObjectValidateYup } from '@/utils/method';
import { checkDuplicateTask } from '@/utils/services/master';

import { TaskMasterQueryFactory } from '../utils';

const validateDuplicate = async (title: string, ctx: any): Promise<boolean> => {
  const countryId = ctx.parent.countryId;
  const id = ctx.parent.id;
  const factory = new TaskMasterQueryFactory();
  factory.and({
    fieldTitle: 'countryId',
    queryType: QUERY_TYPE.TEXT,
    queryValue: countryId,
    operation: QUERY_OPERATION.EQUAL
  });

  factory.and({
    fieldTitle: 'managementCodeSearch.ToLower()',
    queryType: QUERY_TYPE.TEXT,
    queryValue: convertFullWidthToHalfWidth(title.toLocaleLowerCase()),
    operation: QUERY_OPERATION.EQUAL
  });

  const body = {
    filter: factory.toFilterString()
  };

  const result = await checkDuplicateTask(body);
  if (result?.data?.data?.length && result?.data?.data?.some((item: any) => item.id !== id)) {
    return false;
  }

  return true;
};

export const schemaForm = Yup.object().shape({
  category: Yup.string().required(
    stringifyObjectValidateYup({
      keyT: 'common:MSG_001_select',
      optionsTx: { field: t('master_data:task:category') || '' }
    })
  ),
  managementName: Yup.string()
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: t('master_data:task:management_name') || '' }
      })
    )
    .max(
      TEXT_FIELD_MAX_LENGTH,
      t('common:MSG_028', {
        field: t('master_data:task:management_name') || '',
        maxLength: TEXT_FIELD_MAX_LENGTH
      }) || ''
    )
    .debounceValidate(
      validateDuplicate,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_019',
        optionsTx: { field: 'master_data:task:management_name' || '' }
      })
    ),
  displayName: Yup.string()
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: t('master_data:task:display_name') || '' }
      })
    )
    .max(
      TEXT_FIELD_MAX_LENGTH,
      t('common:MSG_028', {
        field: t('master_data:task:display_name') || '',
        maxLength: TEXT_FIELD_MAX_LENGTH
      }) || ''
    ),
  countryId: Yup.string().required(
    stringifyObjectValidateYup({
      keyT: 'common:MSG_001_select',
      optionsTx: { field: t('master_data:document_management:country') || '' }
    })
  ),
  taskContent: Yup.string()
    .requiredTextEditorChange(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: 'master_data:task:task_content' }
      })
    )
    .nullable()
    .htmlStrippedMaxLength(
      NOTE_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'master_data:task:task_content', limited: NOTE_MAX_LENGTH }
      })
    )
});

export const DEFAULT_TASK_MASTER = {
  id: undefined,
  category: undefined,
  displayName: undefined,
  managementName: undefined,
  taskContent: undefined,
  countryId: undefined
};
