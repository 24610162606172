export enum PROJECT_STATUS {
  NOT_START = 'not-start',
  PROCESSING = 'processing',
  VISA_ISSUANCE = 'visa-issuance',
  COMPLETED = 'completed',
  CANCEL = 'cancel'
}

export const PROJECT_TABS: { [index: string]: string } = {
  BASIC_INFORMATION: 'Basic_information',
  SCHEDULE: 'Schedule',
  DOCUMENTS: 'Required_documents',
  QUESTION: 'Question',
  CUSTOMER_REQUEST: 'Customer_request_list',
  TODO: 'List_task',
  MEMO: 'Memo'
};

export enum SUPPORT_TYPE_NAME {
  FULL = 'フルサポート',
  NORMAL = 'サポート'
}

export const DISABLE_ASSIGNEE = ['赴任者（研修生）のビザ取得手続き', '赴任者（研修生）＋ご家族のビザ取得手続き'];
