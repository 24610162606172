import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { QuestionDetail } from '@pages/project-management/project-questionaire/components/question-detail/compononents/QuestionDetail';
import { IQuestionnaire } from '@pages/project-management/project-questionaire/components/question-detail/modals';
import { axiosExternal } from '@pages/project-management/project-questionaire/external-questions/http';

import useFetch from '@hooks/useFetch';
import useMutation from '@hooks/useMutation';

import { API } from '@utils/constants/Apis';

export const ExternalQuestionDetail = () => {
  const { token = '', questionId = '' } = useParams();
  const [query, setQuery] = useState<any>({});

  const {
    data: question,
    loading,
    fetchData
  } = useFetch<IQuestionnaire>(API.GET_EXTERNAL_QUESTION_DETAIL(token, questionId), 'GET', undefined, query, { instance: axiosExternal });
  const { loading: loadingSaveQuestionnaire, mutate: saveQuestion } = useMutation(API.PUT_EXTERNAL_QUESTION_EDIT(token, questionId), {
    instance: axiosExternal,
    method: 'PUT',
    showToastError: true
  });

  return (
    <div className='px-[20px] py-[16px] bg-gray1 h-screen'>
      <QuestionDetail
        isExternalQuestion
        question={question}
        loading={loading}
        loadingSaveQuestionnaire={loadingSaveQuestionnaire}
        fetchData={fetchData}
        saveQuestion={saveQuestion}
        setQuery={setQuery}
      />
    </div>
  );
};
