import { DefaultOptionType } from 'antd/es/select';
import { Dayjs } from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { FieldValues, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BaseButton } from '@/components/base-button/BaseButton';
import { FormInputDate } from '@/components/form-input-date/FormInputDate';
import { FormSelect } from '@/components/form-select/FormSelect';

import useAuthorization from '@/hooks/useAuthorization';
import useCompany from '@/hooks/useCompany';
import useFetch from '@/hooks/useFetch';
import useMotPic from '@/hooks/useMotPic';
import useOptionsGlobal from '@/hooks/useOptionsGlobal';

import { DataInput } from '@/utils/helpers/common';
import { convertDataToOptions } from '@/utils/helpers/projectList';
import { Ii18n } from '@/utils/interfaces/i18n';

import { DEFAULT_FILTER_FORM, DEFAULT_FILTER_FORM_EXTERNAL, TYPE_OF_TASK } from './constant';

interface IFilterProps {
  defaultValues: any;
  onSubmit: (values: FieldValues) => void;
  onReset?: () => void;
}

const PrimaryFormFilter = ({ defaultValues, onSubmit, onReset }: IFilterProps) => {
  const form = useForm({
    defaultValues: DEFAULT_FILTER_FORM.primary
  });
  const [motPicOption, setMotPicOption] = useState<DefaultOptionType[]>([]);
  const { watch } = useFormContext(); // retrieve all hook methods
  const { t }: Ii18n = useTranslation();
  const { isInternalRole } = useAuthorization();
  const { customCountryOptions, visaCategoriesGroupOption, filterOption } = useOptionsGlobal();
  const watchPicBy = watch('picBy');
  const watchCountryCode = form.watch('countryIds');
  const createDateTo = form.watch('createDateTo');
  const createDateFrom = form.watch('createDateFrom');
  const departureTo = form.watch('departureTo');
  const departureFrom = form.watch('departureFrom');
  const { loading: loadingMotPic, userMotPicOptions } = useMotPic();
  const { companyOptions, loadingCompanyForAccount: loadingCompany } = useCompany();
  const { data: statusData, loading: loadingStatus } = useFetch<any[]>('/prj/status', 'GET');
  const categoryOptions = useMemo(() => {
    return visaCategoriesGroupOption.filter((country) => watchCountryCode?.includes(country.id));
  }, [visaCategoriesGroupOption, watchCountryCode]);

  useEffect(() => {
    const categoryId = form.getValues('categoryId');
    if (
      categoryId &&
      categoryOptions.length &&
      !categoryOptions.some((category) => category.options.some((item: any) => item.value === categoryId))
    ) {
      form.setValue('categoryId', null);
    }
  }, [categoryOptions, watchCountryCode]);

  useEffect(() => {
    if (!loadingMotPic && !loadingCompany && !loadingStatus) {
      form.reset({
        ...defaultValues,
        departureFrom: DataInput.switchFormatDate(defaultValues.departureFrom) as Dayjs,
        departureTo: DataInput.switchFormatDate(defaultValues.departureTo) as Dayjs,
        createDateFrom: DataInput.switchFormatDate(defaultValues.createDateFrom) as Dayjs,
        createDateTo: DataInput.switchFormatDate(defaultValues.createDateTo) as Dayjs
      });
    }
  }, [loadingMotPic, loadingCompany, loadingStatus]);

  useEffect(() => {
    if (userMotPicOptions?.length) {
      setMotPicOption([{ label: t('project:filter:not_yet_assigned'), value: '' }, ...userMotPicOptions]);
    }
  }, [userMotPicOptions]);

  const handleReset = () => {
    form.reset(isInternalRole ? DEFAULT_FILTER_FORM.primary : DEFAULT_FILTER_FORM_EXTERNAL.primary);
  };
  const disableResetBtn = () => {
    return !Object.values(form.getValues()).find(Boolean);
  };
  return (
    <>
      {!loadingMotPic && !loadingCompany && !loadingStatus && (
        <FormProvider {...form}>
          <div className='p-4 w-[600px] max-h-[718px] overflow-y-scroll'>
            <h3 className='text-xl font-semibold'>{t('project:list:filter_btn_label')}</h3>
            <div className='item mb-5'>
              <FormSelect
                name='countryIds'
                mode='multiple'
                label={t('project:filter:contry_of_travel:label')}
                options={customCountryOptions}
                placeholder={String(t('project:add:please_select_field', { field: t('project:filter:contry_of_travel:label') }))}
                filterOption={filterOption}
                handleChange={(value) => {
                  if (!value?.length) {
                    form.setValue('countryIds', null);
                    form.setValue('categoryId', null);
                  }
                }}
              />
            </div>
            <div className='item mb-5'>
              <FormSelect
                name='categoryId'
                label={t('project:filter:visa_category:label')}
                options={categoryOptions}
                disabled={!watchCountryCode?.length}
                placeholder={String(t('project:add:please_select_field', { field: t('project:filter:visa_category:label') }))}
                filterOption={(input: string, option?: DefaultOptionType) => {
                  const lowercaseInput = input.toLowerCase();
                  return !!option?.options?.some((item: any) => item.label.toLowerCase().startsWith(lowercaseInput));
                }}
              />
            </div>
            {isInternalRole && (
              <>
                <div className='item mb-5'>
                  <FormSelect
                    name='picIds'
                    mode='multiple'
                    label={t('project:filter:mot_pick:label')}
                    options={motPicOption}
                    disabled={watchPicBy === TYPE_OF_TASK.ME}
                    placeholder={String(t('project:add:please_select_field', { field: t('project:filter:mot_pick:label') }))}
                    handleChange={(value) => {
                      if (!value?.length) {
                        form.setValue('picIds', null);
                      }
                    }}
                  />
                </div>
                <div className='item mb-5'>
                  <FormSelect
                    name='companyId'
                    label={t('basic_information:applicant_company')}
                    options={companyOptions}
                    placeholder={String(t('project:add:please_select_field', { field: t('basic_information:applicant_company') }))}
                  />
                </div>
              </>
            )}
            <div className='mb-3 flex items-center justify-between gap-4'>
              <FormSelect
                name='status'
                label={t('project:fields:status')}
                options={statusData?.map((item) => convertDataToOptions(item, { id: 'value', name: 'label' }))}
                placeholder={String(t('project:add:please_select_field', { field: t('project:filter:status:label') }))}
              />
            </div>
            <div className='mb-3 flex items-center justify-between gap-4'>
              <div className='w-[262px]'>
                <FormInputDate
                  name='departureFrom'
                  labelTx='project:filter:scheduled_travel_date:label'
                  placeholder={t('placeholder:date')}
                  disabledDate={(d) => {
                    return Boolean(departureTo && d.startOf('day').isAfter(departureTo));
                  }}
                />
              </div>
              <span className='w-[18px] h-[2px] mt-[35px] bg-[#525a68]'></span>
              <div className='w-[262px]'>
                <FormInputDate
                  name='departureTo'
                  label='&nbsp;'
                  placeholder={t('placeholder:date')}
                  disabledDate={(d) => {
                    return Boolean(departureFrom && d.endOf('day').isBefore(departureFrom));
                  }}
                />
              </div>
            </div>
            <div className='mb-3 flex items-center justify-between gap-4'>
              <div className='w-[262px]'>
                <FormInputDate
                  name='createDateFrom'
                  labelTx={t('project:filter:create_date:label')}
                  placeholderTx={t('placeholder:date')}
                  disabledDate={(d) => {
                    return Boolean(createDateTo && d.startOf('day').isAfter(createDateTo));
                  }}
                />
              </div>
              <span className='w-[18px] h-[2px] mt-[35px] bg-[#525a68]'></span>
              <div className='w-[262px]'>
                <FormInputDate
                  name='createDateTo'
                  label='&nbsp;'
                  placeholder={t('placeholder:date')}
                  disabledDate={(d) => {
                    return Boolean(createDateFrom && d.endOf('day').isBefore(createDateFrom));
                  }}
                />
              </div>
            </div>
            <div className='flex justify-end space-x-2'>
              <div className='w-[120px]'>
                <BaseButton onClick={handleReset} type='tertiary' size='medium' disabled={disableResetBtn()}>
                  {t('project:filter:btn_reset')}
                </BaseButton>
              </div>
              <div className='w-[120px]'>
                <BaseButton onClick={form.handleSubmit(onSubmit)} type='primary' size='medium'>
                  <span>{t('project:filter:btn_aply')} </span>
                </BaseButton>
              </div>
            </div>
          </div>
        </FormProvider>
      )}
    </>
  );
};

export default PrimaryFormFilter;
