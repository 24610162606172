import { Col, Row, Space } from 'antd';
import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { useFormScenarioBContext } from '@pages/project-management/add-edit/scenario/type-b';
import { relativePath } from '@pages/project-management/add-edit/scenario/utils';

import { BaseButton } from '@/components/base-button/BaseButton';
import { BaseCard } from '@/components/base-card/BaseCard';
import BaseRadioGroups from '@/components/common/base-radio';
import FormItem from '@/components/common/form-item';
import { FormInput } from '@/components/form-input/FormInput';
import { FormSelect } from '@/components/form-select/FormSelect';

import useCountField from '@/hooks/useCountField';

import PlusIcon from '@/assets/icons/PlusIcon.svg';
import InfoIcon from '@assets/icons/Info.svg';

import { LANGUAGE_OPTIONS } from '../../constants';
import {
  DEFAULT_LOCAL_REPRESENTATIVE,
  LOCAL_REPRESENTATIVE,
  LOCAL_REPRESENTATIVE_FIELDS,
  LOCAL_REPRESENTATIVE_FIELDS_EXCLUDE_LANG,
  LOCAL_REPRESENTATIVE_PATH,
  MAX_FIELD_PERSON_NUMBER,
  NEED_SUPPORTER_PATH
} from './constants';

import './styles.scss';

export const PARENT_PATH = 'steps[2]';
const ScenarioBStepFour = () => {
  const { t } = useTranslation();
  const { watch, control, clearErrors } = useFormScenarioBContext<'step-4'>();
  const PATHS = {
    NEED_SUPPORTER: relativePath(NEED_SUPPORTER_PATH, PARENT_PATH),
    LOCAL_REPRESENTATIVE: relativePath(LOCAL_REPRESENTATIVE_PATH, PARENT_PATH)
  };
  const [needSupporter, localRepresentatives] = watch([PATHS.NEED_SUPPORTER, PATHS.LOCAL_REPRESENTATIVE]);
  const { append, remove, replace } = useFieldArray({
    name: PATHS.LOCAL_REPRESENTATIVE,
    control
  });

  const personalInChargeOptions = [
    { value: LOCAL_REPRESENTATIVE.NEEDED, label: t('common:options:needed') },
    { value: LOCAL_REPRESENTATIVE.NOT_NEEDED, label: t('common:options:not_needed') },
    { value: LOCAL_REPRESENTATIVE.UNKNOWN, label: t('common:options:unknown') }
  ];

  const addNewMember = () => {
    if (localRepresentatives?.length >= MAX_FIELD_PERSON_NUMBER) return;
    append({ ...DEFAULT_LOCAL_REPRESENTATIVE, formId: uuid() });
  };

  const handleRemove = (index: number) => {
    remove(index);
  };

  const handleChangeLocalRepresentative = (value: string | number | boolean) => {
    if (value !== LOCAL_REPRESENTATIVE.NEEDED) {
      clearErrors(PATHS.LOCAL_REPRESENTATIVE);
      replace([]);
    } else {
      clearErrors(PATHS.LOCAL_REPRESENTATIVE);
      replace([{ ...DEFAULT_LOCAL_REPRESENTATIVE, formId: uuid() }]);
    }
  };

  return (
    <div className='scenario-b-step-four'>
      <div className='scenario-b-step-four__form-title'>
        <p className='title-20 mb-[12px]'>{t('project:scenario_b:local_representative_title')}</p>
        <div className='flex items-center'>
          <span className='subtitle-18'>
            {t('project:scenario_b:local_representative_question')}
            <span className='text-negative subtitle-18'>(*)</span>
          </span>
        </div>
      </div>
      <div className='scenario-b-step-four__form-content'>
        <FormItem name={PATHS.NEED_SUPPORTER}>
          <BaseRadioGroups options={personalInChargeOptions} isVertical onChange={handleChangeLocalRepresentative} />
        </FormItem>
        {needSupporter === LOCAL_REPRESENTATIVE.NEEDED && (
          <div className='scenario-b-step-four__personal-in-charge'>
            <p className='title-20'>{t('project:scenario_b:local_representative_guide')}</p>
            <div className='flex items-center'>
              <InfoIcon className='block mr-[4px]' width={18} height={18} />
              <span className='body-400'>{t('project:scenario_b:local_representative_information')}</span>
            </div>
            {localRepresentatives?.map((field: any, index: number) => (
              <LocalSupporter
                key={field?.id ?? field?.formId}
                showTrashIcon={localRepresentatives?.length > 1}
                index={index}
                onRemove={handleRemove}
                parentPath={PATHS.LOCAL_REPRESENTATIVE}
              />
            ))}
            <Space size={16}>
              <div className='min-w-[140px]'>
                <BaseButton
                  icon={<PlusIcon />}
                  disabled={localRepresentatives?.length === MAX_FIELD_PERSON_NUMBER}
                  type='secondary'
                  block
                  size='medium'
                  onClick={addNewMember}
                >
                  {t('basic_information:add_local_contact')}
                </BaseButton>
              </div>
            </Space>
          </div>
        )}
      </div>
    </div>
  );
};

interface ILocalSupporter {
  showTrashIcon: boolean;
  index: number;
  onRemove: Function;
  parentPath: string;
}
const LocalSupporter = ({ parentPath, showTrashIcon, index, onRemove }: ILocalSupporter) => {
  const { t } = useTranslation();
  const watchingFields = index === 0 ? LOCAL_REPRESENTATIVE_FIELDS : LOCAL_REPRESENTATIVE_FIELDS_EXCLUDE_LANG;
  const { answered, total } = useCountField(`${parentPath}[${index}]`, watchingFields);
  const { getValues } = useFormScenarioBContext<'step-4'>();
  const getFieldPath = (index: number, field: string) => `${parentPath}[${index}].${field}`;

  return (
    <BaseCard
      showIconRemove={showTrashIcon}
      index={index}
      onRemove={onRemove}
      title={t('project:add:family_information:local_representative_number', { number: index + 1 })}
      total={total}
      questions={answered}
    >
      <div className='grid gap-4'>
        <Row gutter={16}>
          <Col span={6}>
            <FormInput
              required
              defaultValue={getValues(getFieldPath(index, 'name'))}
              name={getFieldPath(index, 'name')}
              labelTx='project:add:local_representative:name'
              placeholderTx='project:add:local_representative:name_placeholder'
            />
          </Col>
          <Col span={6}>
            <FormInput
              required
              defaultValue={getValues(getFieldPath(index, 'address'))}
              name={getFieldPath(index, 'address')}
              labelTx='project:add:local_representative:address'
              placeholderTx='project:add:local_representative:address_placeholder'
            />
          </Col>
          <Col span={6}>
            <FormInput
              required
              defaultValue={getValues(getFieldPath(index, 'email'))}
              name={getFieldPath(index, 'email')}
              labelTx='project:add:local_representative:email'
              placeholderTx='project:add:local_representative:email_placeholder'
            />
          </Col>
          {index === 0 && (
            <Col span={6}>
              <FormSelect
                required
                id={getFieldPath(index, 'language')}
                name={getFieldPath(index, 'language')}
                label={t('project:add:local_representative:language')}
                placeholder={t('project:add:local_representative:language_placeholder')}
                options={LANGUAGE_OPTIONS.map((item) => ({ ...item, label: t(item.label) }))}
              />
            </Col>
          )}
        </Row>
      </div>
    </BaseCard>
  );
};
export default ScenarioBStepFour;
