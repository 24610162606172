import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseCard } from '@/components/base-card/BaseCard';
import { FormAttachment } from '@/components/form-attachment/FormAttachment';
import { EditorWithCounter } from '@/components/form-box-editor';
import { InfoIcon } from '@/components/icon-svg/IconSvg';

import { NOTE_MAX_LENGTH } from '@/utils/constants/AppConstants';

import { useFormScenarioAContext } from '../..';
import { relativePath } from '../../../utils';

const PARENT_PATH = 'steps[3]';

export default function ScenarioAStepFive() {
  const { watch } = useFormScenarioAContext<'step-5'>();
  const { t } = useTranslation();
  const PATHS = {
    NOTE: relativePath('note', PARENT_PATH),
    ATTACHMENTS: relativePath('projectAttachments', PARENT_PATH)
  };
  const [note, fileAttachments] = watch([PATHS.NOTE, PATHS.ATTACHMENTS]);
  const [noteLength, setNoteLength] = useState<number>(0);

  return (
    <div>
      <p className='title-20 text-black'>{t('basic_information:additional_information')}</p>

      <BaseCard className='mt-3' title={String(t('basic_information:note'))} total={1} questions={noteLength ? 1 : 0}>
        <EditorWithCounter
          onCharacterChange={setNoteLength}
          label={
            <div className='flex'>
              <div className='py-[2px] mr-[4px]'>
                <InfoIcon width={14.63} height={14.63} />
              </div>
              <div className='font-normal leading-[22px] text-[14px] text-[#525A68]'>
                <p>{t('project:scenario_a:additional_information_guide1')}</p>
              </div>
            </div>
          }
          className='mt-3'
          name={PATHS.NOTE}
          limit={NOTE_MAX_LENGTH}
          defaultValue={note}
          toolbar={null}
          placeholder={t('basic_information:place_holder_note') || ''}
          editorWrapperProps={{ className: '!h-[148px]' }}
        />
      </BaseCard>
      <BaseCard className='mt-3' title={String(t('basic_information:attachment_file'))}>
        <div className='flex py-2'>
          <div className='py-[2px] mr-[4px]'>
            <InfoIcon width={14.63} height={14.63} />
          </div>
          <div className='font-normal leading-[22px] text-[14px] text-[#525A68]'>
            <p>{t('project:scenario_a:additional_information_guide2')}</p>
          </div>
        </div>
        <FormAttachment name={PATHS.ATTACHMENTS} defaultValue={fileAttachments || []} documentType='attachments'></FormAttachment>
      </BaseCard>
    </div>
  );
}
