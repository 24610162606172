import { Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import AppSubLabel from '@/components/app-label/AppSubLabel';
import AppTooltip from '@/components/app-tooltip/AppTooltip';
import { BaseButton } from '@/components/base-button/BaseButton';
import { BaseCard } from '@/components/base-card/BaseCard';

import { setLoadingPage } from '@/redux/globalReducer';

import { ERROR_CODE } from '@/utils/constants/AppConstants';
import { NOT_EXITS_URL, STAKEHOLDER_UPDATE_URL } from '@/utils/constants/RouteContants';
import { DataViewer } from '@/utils/helpers/common';
import { getStakeholderDetail } from '@/utils/services/StakeholderApiService';

import PencilSimpleLine from '@/assets/icons/PencilSimpleLine.svg';
import Close from '@/assets/icons/close.svg';
import Check from '@/assets/icons/ic_check.svg';
import Warning from '@/assets/icons/template/icon-warning.svg';

import { TYPE_STAKEHOLDER } from '../constants';
import { IStakeholderViewProps } from '../models';

import '../Styles.scss';

const View = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [stakeholderData, setStakeholderData] = useState<IStakeholderViewProps[]>([]);
  const stakeholderDetailData = async () => {
    try {
      dispatch(setLoadingPage(true));
      const { data } = await getStakeholderDetail(id as string);
      setStakeholderData(data);
      dispatch(setLoadingPage(false));
    } catch (error: any) {
      switch (error?.code) {
        case ERROR_CODE.ERR_BAD_REQUEST:
          navigate(NOT_EXITS_URL);
          break;
        default:
          break;
      }
    }
  };
  useEffect(() => {
    stakeholderDetailData();
  }, []);

  const renderStakeholderApprove = (field: IStakeholderViewProps, index: number) => {
    return (
      <BaseCard index={index} key={index + 1} className='form-title' title={`${t('stakeholder:title')}${index + 1}`}>
        <AppSubLabel label={t('stakeholder:label:email_address')} title={field.stakeHolderEmail} />
        <AppSubLabel
          label={t('stakeholder:label:user_name')}
          title={
            (!field.rejected && !field.waitCreateAccount && !field.stakeHolderStatus ? `(${t('announcement:invalid')})` : '') + field.stakeHolderName
          }
          icon={<Check />}
        />
      </BaseCard>
    );
  };
  const renderStakeholderPending = (field: IStakeholderViewProps, index: number) => {
    return (
      <BaseCard index={index} key={index + 1} className='form-title' title={`${t('stakeholder:title')}${index + 1}`}>
        <div className='mb-2'>
          <AppSubLabel
            label={t('stakeholder:label:email_address')}
            subLabel={field.rejected ? t('stakeholder:label:remanded') : t('stakeholder:label:pending')}
            title={field.stakeHolderEmail}
            icon={field.rejected ? <Close className='w-[20px] ver-align-bottom' /> : <Warning />}
            classSubLabel={field.rejected ? 'rejected' : 'waiting-approve'}
          />
        </div>
        {field.rejected && field.rejectReason && (
          <div className='ml-[185px] wordWrap-break flex mb-2 gap-[16px]'>
            <span className='shrink-0'>{t('stakeholder:label:reason_remand:label')}</span>
            <AppTooltip
              className='font-bold max-h-[140px] overflow-auto'
              title={<span className='tooltip-title' dangerouslySetInnerHTML={{ __html: DataViewer.displayAsSanitizeHTML(field.rejectReason) }} />}
            >
              <div>
                <span dangerouslySetInnerHTML={{ __html: DataViewer.displayAsSanitizeHTML(field.rejectReason) }} />
              </div>
            </AppTooltip>
          </div>
        )}
        <AppSubLabel label={t('stakeholder:label:user_name')} title={field.stakeHolderName} />
        <AppSubLabel label={t('stakeholder:label:company_name')} title={field.stakeHolderCompanyName} />
        <AppSubLabel label={t('stakeholder:label:privileges')} title={field.role} />
      </BaseCard>
    );
  };
  const renderMap = {
    [TYPE_STAKEHOLDER.APPROVE]: renderStakeholderApprove,
    [TYPE_STAKEHOLDER.PENDING]: renderStakeholderPending
  };
  const getRenderFunctionKey = (field: IStakeholderViewProps) => {
    if (field.stakeHolderStatus) return TYPE_STAKEHOLDER.APPROVE;
    if (field.rejected || field.waitCreateAccount) return TYPE_STAKEHOLDER.PENDING;
    return TYPE_STAKEHOLDER.APPROVE;
  };
  const renderStakeholder = (field: IStakeholderViewProps, index: number) => {
    const renderFunctionKey = getRenderFunctionKey(field);
    const renderFunction = renderMap[renderFunctionKey];

    return renderFunction(field, index);
  };
  return (
    <div id='stakeholder-management' className='overflow-hidden h-full'>
      <div className='stakeholder-container h-full'>
        <div className='header flex justify-between sticky top-0 bg-gray1'>
          <div className='title-24 whitespace-pre-line font-bold text-24 leading-7'>{t('stakeholder:name')}</div>
          <Space size={16}>
            <div className='min-w-[120px]'>
              <BaseButton block size='medium' type='secondary' icon={<PencilSimpleLine />} onClick={() => navigate(STAKEHOLDER_UPDATE_URL(id ?? ''))}>
                {t('stakeholder:button:edit')}
              </BaseButton>
            </div>
          </Space>
        </div>
        {stakeholderData.length > 0 && (
          <div className='max-h-[calc(100vh-138px)] overflow-y-scroll stakeholder-form stakeholder-tabs-content px-5 py-4 rounded-[10px] border-[#DBDBE0] gap-3'>
            {stakeholderData.map((field, index) => {
              return renderStakeholder(field, index);
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default View;
