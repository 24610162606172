import { Tag } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import { useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { STATUS_NAME } from '@/pages/project-management/detail/constant';
import { DEADLINE_STATUS, TASK_TYPE } from '@/pages/project-management/task/constants';

import AppTooltip from '@/components/app-tooltip/AppTooltip';
import { BaseButton } from '@/components/base-button/BaseButton';
import BaseModal from '@/components/base-modal/BaseModal';
import { BasePopupProps, showBasePopup } from '@/components/base-popup/BasePopup';
import { TagTaskType } from '@/components/common/task/TagTaskType';
import { FormInputDate } from '@/components/form-input-date/FormInputDate';
import FormMultipleSelect, { IConfirmCallbackParams } from '@/components/form-select/FormMultipleSelect';
import { FormSelect } from '@/components/form-select/FormSelect';
import SelectionPreview from '@/components/form-select/SelectionPreview';

import useFetch from '@/hooks/useFetch';
import useMutation from '@/hooks/useMutation';

import { API } from '@/utils/constants/Apis';
import { FORMAT_DATE_EN } from '@/utils/constants/AppConstants';
import { HTTP_STATUS_CODE } from '@/utils/constants/Http';
import { VIEW_PROJECT_URL } from '@/utils/constants/RouteContants';
import { DataViewer } from '@/utils/helpers/common';
import { Ii18n } from '@/utils/interfaces/i18n';

import Trash from '@/assets/icons/TrashIcon.svg';
import WarningWaitingApprove from '@/assets/icons/WarningWaitingApprove.svg';

import { IDetailTaskData, IFormMOT, IFormStakeHolder, IOptions, IStatus, ProjectTaskPic } from '../../models';

import '../index.scss';

interface HeaderInternalRoleProps {
  detailData: IDetailTaskData;
  fetchData: () => Promise<void>;
}

const HeaderInternalRole = ({ detailData, fetchData }: HeaderInternalRoleProps) => {
  const { t }: Ii18n = useTranslation();
  const { idProject, idTask } = useParams();
  const { data: listStatus } = useFetch<IStatus[]>('/prj/status?type=task', 'GET');
  const form = useFormContext();
  const navigate = useNavigate();
  const [isWarningDeadline, setIsWarningDeadline] = useState<boolean>(false);

  const queryStakeHolder = useRef({ query: 'activeOnly' });
  const stakeHolderEndpoint = detailData && detailData.taskType === TASK_TYPE.OTHER ? API.GET_STAKEHOLDERS_BY_PROJECT(idProject ?? '') : '';
  const { data: stakeHolderEmails } = useFetch<IFormStakeHolder[]>(stakeHolderEndpoint, 'GET', queryStakeHolder.current);

  const userPicEndpoint =
    detailData && [TASK_TYPE.TODO, TASK_TYPE.MOT].includes(detailData.taskType as 'TODO' | 'MOT') ? API.GET_USERS_MOT_PIC(true) : '';
  const { data: userPics } = useFetch<IFormMOT[]>(userPicEndpoint, 'GET');

  const picOptions = (): {
    [key: string]: IOptions[];
  } => {
    const results: {
      [key: string]: IOptions[];
    } = {
      all: [],
      filtered: []
    };
    if (!detailData) return results;
    if (detailData.taskType === TASK_TYPE.OTHER) {
      results.all =
        stakeHolderEmails?.map((f: IFormStakeHolder) => ({
          id: f.stakeHolderId,
          label: f.stakeHolderName,
          value: f.stakeHolderEmail
        })) ?? [];
      results.filtered = results.all;
    } else if ([TASK_TYPE.TODO, TASK_TYPE.MOT].includes(detailData.taskType as 'TODO' | 'MOT')) {
      userPics?.forEach((f: IFormMOT) => {
        results.all.push({
          id: f.id ?? '',
          label: f.name,
          value: f.email
        });
        if (f.status)
          results.filtered.push({
            id: f.id ?? '',
            label: f.name,
            value: f.email
          });
      });
    }
    return results;
  };

  const statusOptions = listStatus?.map((item) => {
    return {
      label: item.name,
      value: item.id
    };
  });

  const { mutate: updateDeadline } = useMutation(API.UPDATE_DEADLINE_TASK_DETAIL(idProject ?? '', idTask ?? ''), {
    method: 'PUT',
    bodyType: 'json',
    showToastError: true,
    showToastSuccess: true,
    defaultSuccessMsg: t('common:MSG_C_003', { item: t('button:keep') }) ?? ''
  });

  const handleChangeDeadline = async (value: Dayjs | null) => {
    try {
      const result = await updateDeadline({ deadline: value ? dayjs(value).format(FORMAT_DATE_EN) : null });
      if (result?.status === HTTP_STATUS_CODE.SUCCESS) fetchData();
    } catch (error) {}
  };

  const { mutate: updatePic } = useMutation(API.UPDATE_PIC_TASK_DETAIL(idProject ?? '', idTask ?? ''), {
    method: 'PUT',
    bodyType: 'json',
    showToastError: true,
    showToastSuccess: true,
    defaultSuccessMsg: t('common:MSG_C_003', { item: t('button:keep') }) ?? '',
    defaultErrorMsg: 'MSG_C_019',
    paramMsg: {
      field: t('project:task_of_project:mot_pick:label')
    }
  });

  const { mutate: updateStatus } = useMutation(API.UPDATE_STATUS_TASK_DETAIL(idProject ?? '', idTask ?? ''), {
    method: 'PUT',
    bodyType: 'json',
    showToastError: true,
    showToastSuccess: true,
    defaultSuccessMsg: t('common:MSG_C_003', { item: t('button:keep') }) ?? ''
  });

  const handlePreChangeStatus = async (newVal: string) => {
    let content: BasePopupProps = {};
    if (!detailData?.deadline) setIsWarningDeadline(true);
    else {
      content = {
        type: 'confirm',
        title: t('project:task_of_project:change_status:title') || '',
        msg:
          detailData.projectTaskPics && detailData.projectTaskPics.length
            ? t('common:MSG_C_021', { item: t('project:task_of_project:status:label') || '' })
            : t('common:MSG_P_031')
      };
      const response = await showBasePopup(content);
      if (response === 'confirm') {
        handleChangeStatus(newVal);
      } else throw new Error('cancel');
    }
  };

  const handleConfirmDeadline = () => {
    form.resetField('status', { defaultValue: detailData?.statusName, keepDirty: false });
    setIsWarningDeadline(false);
  };

  const handleChangeStatus = async (value: string) => {
    try {
      const result = await updateStatus({ statusId: value });
      if (result?.status === HTTP_STATUS_CODE.SUCCESS) fetchData();
      else form.resetField('status', { defaultValue: detailData?.statusName });
    } catch (errors) {}
  };

  const { mutate: deleteTask } = useMutation(`/prj/projects/${idProject}/task`, {
    method: 'DELETE',
    bodyType: 'json',
    showToastError: true,
    showToastSuccess: true,
    defaultSuccessMsg: t('common:MSG_C_003', { item: t('button:delete') }) ?? ''
  });

  const handleDeleteTask = async () => {
    const showPopup = await showBasePopup({
      type: 'discard',
      title: t('common:MSG_C_012:title', { field: t('project:task_of_project:task') }) ?? '',
      msg: t('common:MSG_C_012:description', { field: t('project:task_of_project:task') }) ?? ''
    });
    if (showPopup === 'confirm') {
      try {
        const payload = {
          tasks: [
            {
              id: idTask,
              version: detailData?.version || ''
            }
          ]
        };
        const result = await deleteTask(payload);
        if (result?.status === HTTP_STATUS_CODE.SUCCESS) {
          if (!idProject) return;
          navigate(VIEW_PROJECT_URL(idProject));
        }
      } catch (error) {}
    }
  };

  const handleConfirmPic = async ({ removed, added, original }: IConfirmCallbackParams<ProjectTaskPic & IOptions>) => {
    try {
      const picPayloads: Partial<ProjectTaskPic & IOptions>[] = [];
      removed.forEach((item) => {
        const matched = original.find((i) => i.value === item.value);
        if (!matched) return;
        picPayloads.push({ ...matched, picEmail: item.value, deleted: true });
      });
      added.forEach((item) => {
        picPayloads.push({ picEmail: item.value });
      });
      const result = await updatePic({ pics: picPayloads });
      if (result?.status === HTTP_STATUS_CODE.SUCCESS) {
        await fetchData();
      } else {
        form.reset((prev) => ({
          ...prev,
          picId: original
        }));
      }
    } catch (error) {}
  };

  const handleCancelPic = () => {
    const originValues =
      detailData.projectTaskPics.map((item) => ({
        ...item,
        label: item.picName,
        value: item.picEmail
      })) ?? [];
    form.reset((prev) => ({
      ...prev,
      picId: originValues
    }));
  };

  const handlePicCondition = async () => {
    const content: BasePopupProps = {
      type: 'confirm',
      title: t('project:task_of_project:change_pic:title') || '',
      msg: t('project:task_of_project:change_pic:content')
    };
    return showBasePopup(content);
  };

  const handleOnChangePic = (selectedValues: (ProjectTaskPic & IOptions)[]) => {
    const items = selectedValues.map((item: any) => {
      return {
        label: item.label,
        value: item.value
      };
    });
    form.setValue('picId', items);
  };

  const { deadline } = form.watch();

  const messageWarningRequired = () => {
    if ((!detailData?.deadline && detailData?.statusName === STATUS_NAME.TODO) || !detailData?.departureDate) return '';
    if (!deadline && detailData?.statusName !== STATUS_NAME.TODO) return DEADLINE_STATUS.ERROR;
    if (!!detailData?.deadline) {
      const isWarning = dayjs(detailData?.deadline).isAfter(dayjs(detailData?.departureDate));
      if (!isWarning) return;
      return DEADLINE_STATUS.WARNING;
    }
  };

  const isTaskDelay = () => {
    return detailData?.statusName !== STATUS_NAME.COMPLETED && dayjs(detailData?.deadline).isBefore(dayjs(), 'day');
  };

  return (
    <div className='view-task-project-header flex items-start justify-between'>
      <div className='flex items-start gap-[8px] max-w-[490px] grow'>
        <div className='mt-[8px]'>
          <TagTaskType type={detailData?.taskType ?? ''} />
        </div>
        <AppTooltip className='truncate inline-block w-full' title={DataViewer.display(detailData?.taskName)}>
          <div className='text-lnk text-[24px] font-bold truncate inline-block w-full'>{DataViewer.display(detailData?.taskName)}</div>
        </AppTooltip>
      </div>
      <div className='flex items-start gap-[8px] justify-end'>
        <div className='flex items-center gap-[8px]'>
          <div className={classNames('text-lnk text-[14px] font-medium', messageWarningRequired() ? 'pb-[18px]' : '')}>
            {t('project:task_of_project:deadline')}
          </div>
          <div className='w-[293px] mr-[4px]'>
            <FormInputDate
              name='deadline'
              labelTx=''
              placeholder={t('placeholder:date')}
              triggerShowModal='both'
              allowClear={false}
              customMessage={() => {
                if (!messageWarningRequired())
                  return {
                    status: '',
                    content: ''
                  };
                if (messageWarningRequired() === DEADLINE_STATUS.ERROR)
                  return {
                    status: DEADLINE_STATUS.ERROR,
                    content: (
                      <div className='text-[14px] font-normal leading-[22px] h-[26px] mt-0 text-negative'>
                        {t('common:MSG_001_textbox', { field: t('project:task_of_project:deadline') })}
                      </div>
                    )
                  };
                return {
                  status: DEADLINE_STATUS.WARNING,
                  content: (
                    <div className='text-[14px] font-normal leading-[22px] h-[26px] mt-0 text-warning'>
                      {t('project:task_of_project:warning_deadline_after_departure_date')}
                    </div>
                  )
                };
              }}
              onChange={handleChangeDeadline}
            />
          </div>
        </div>
        <div className='flex items-center gap-[8px]'>
          <div className='text-lnk text-[14px] font-medium w-[45px]'>{t('project:task_of_project:mot_pick:label')}</div>
          <div className='w-[314px] mr-[4px]'>
            <FormMultipleSelect<ProjectTaskPic & IOptions>
              name='picId'
              mode='multiple'
              label=''
              options={picOptions().filtered}
              onChange={handleOnChangePic}
              confirmations={{
                condition: handlePicCondition,
                onCancel: handleCancelPic,
                onConfirm: handleConfirmPic
              }}
              tagShowTooltip
              labelInValue
              disabled={detailData?.taskType === TASK_TYPE.MOT}
              placeholder={detailData?.taskType === TASK_TYPE.MOT ? '' : t('project:task_of_project:mot_pick:placeholder')}
            />
          </div>
        </div>
        <div className='flex items-center gap-[8px]'>
          <div className='text-lnk text-[14px] font-medium w-[70px]'>{t('project:task_of_project:status:label')}</div>
          <div className='w-[101px]'>
            <SelectionPreview options={statusOptions as DefaultOptionType[]} value={form.watch('status')} label={detailData?.statusName || ''}>
              <FormSelect
                allowClear={false}
                name='status'
                label=''
                options={statusOptions}
                placeholder={t('project:task_of_project:status:placeholder')}
                preChange={handlePreChangeStatus}
              />
            </SelectionPreview>
          </div>
        </div>
        {isTaskDelay() && (
          <div className='h-[40px] items-center d-flex'>
            <Tag
              className={classNames(
                'tag-delay-task flex items-center justify-center !px-[8px] !py-[2px] !gap-[4px] !rounded-[4px] !bg-yellow10 !border-warning !text-yellow mr-[0]'
              )}
              color='#F2A227'
              icon={<WarningWaitingApprove />}
            >
              {t('project:task_of_project:delay')}
            </Tag>
          </div>
        )}

        {detailData?.statusName === STATUS_NAME.TODO && (
          <BaseButton
            type='outline-red'
            size='medium'
            icon={<Trash />}
            onClick={handleDeleteTask}
            className={classNames('delete-btn !min-w-[0] !rounded-[8px]', messageWarningRequired() ? 'mt-[2px]' : '')}
          >
            {t('project:task_of_project:delete')}
          </BaseButton>
        )}
      </div>

      <BaseModal
        openModal={isWarningDeadline}
        maskClosable={false}
        onCancel={() => handleConfirmDeadline()}
        contentElement={
          <div className='base-popup'>
            <div className='font-[700] text-[20px] text-lnk leading-[120%]'>{t('project:task_of_project:warning_change_status_deadline') || ''}</div>
            <div className='font-[500] text-[14px] text-textGray mt-[16px] whitespace-pre-line leading-[160%]'>{t('common:MSG_P_030')}</div>
            <div className='flex-base-button grid-cols-2 gap-4 mt-[24px]'>
              <BaseButton onClick={() => handleConfirmDeadline()} size='medium' type='primary'>
                {t('common:yes')}
              </BaseButton>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default HeaderInternalRole;
