import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';

import { axiosInstance } from '@/config/http';

export interface IApiResponseDataList<T> {
  totalCount?: number;
  totalPage?: number;
  pageSize?: number;
  pageIndex?: number;
  data: T;
}

interface IApiResponse<T> {
  data: T | null;
  loading: boolean;
  error: Error | null;
  fetchData: () => Promise<T | undefined>;
  options?: IFetchOptions;
}

interface IFetchOptions {
  instance?: any;
  autoFetch?: boolean;
}

export type FetchMethod = 'GET' | 'POST';

/**
 * Fetch data from API
 * @param url - your API endpoint. This MUST be defined in src/utils/constants/Apis.ts.
 *              Ensure the URL is correctly set up in the constants file before using this hook.
 * @param method HTTP method (GET, POST, PUT, DELETE)
 * @param params request body (for POST, PUT, DELETE)
 * @param query request query (for GET)
 * @param options flag to check if this data need to be automatically fetched or manually fetched
 * @returns {IApiResponse} - data, loading, error, fetchData
 * @example
 * const { data, loading, error, fetchData } = useFetch<Data | Data[]>(API.YOUR_API_ENDPOINT, 'GET');
 */
const useFetch = <T>(url: string, method: FetchMethod = 'GET', params?: any, query?: any, options?: IFetchOptions): IApiResponse<T> => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [loadFirstTime, setLoadFirstTime] = useState<boolean>(true);
  const { instance = axiosInstance, autoFetch = true } = options ?? {};

  const fetchData = async () => {
    setLoading(true);
    setError(null);

    try {
      let response: AxiosResponse<T>;
      if (!url) {
        return;
      }
      switch (method.toUpperCase()) {
        case 'GET':
          response = await instance.get(url, {
            params: { ...params, ...query }
          });
          break;
        case 'POST':
          response = await instance.post(url, params);
          break;
        // Add cases for other HTTP methods if needed
        default:
          throw new Error(`Unsupported HTTP method: ${method}`);
      }
      setData(response.data);
      return response.data;
    } catch (error) {
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loadFirstTime && !autoFetch) {
      return;
    }
    if (url) fetchData();

    // Cleanup function
    return () => {
      // Cleanup logic if needed
    };
  }, [url, method, params, query]);

  useEffect(() => {
    setLoadFirstTime(false);
  }, []);

  return { data, loading, error, fetchData };
};

/**
 * Mock fetch data for testing
 * @param url your API endpoint
 * @param method HTTP method
 * @param params request body
 * @param query request query
 * @param mockData mock data to return. please remove it when integrating with useFetch
 */
const useFetchMock = <T>(url: string, method: FetchMethod = 'GET', mockData: any, params?: any, query?: any): IApiResponse<T> => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = async (): Promise<any> => {
    setLoading(true);
    setError(null);

    await new Promise((resolve, reject) => {
      setTimeout(() => {
        try {
          setData(mockData);
          resolve(mockData);
        } catch (error) {
          setError(error as Error);
          reject(error);
        } finally {
          setLoading(false);
        }
      }, 1000);
    });
  };

  useEffect(() => {
    if (url) fetchData();
  }, [url, method, params, query]);

  return { data, loading, error, fetchData };
};

export { useFetchMock };
export default useFetch;
