export const ACCOUNT_MASTER_DEFAULT_VALUE = {
  id: '',
  upn: '',
  email: '',
  name: '',
  roleName: '',
  organizationId: undefined,
  organizationName: '',
  locked: false,
  createdBy: '',
  createdName: '',
  createdDate: '',
  updatedBy: '',
  updatedName: '',
  updatedDate: '',
  version: 1,
  rejected: false,
  reasonReject: '',
  color: ''
};

export const ACCOUNT_MASTER_CREATE_VALUE = {
  upn: '',
  email: '',
  name: '',
  organizationId: undefined,
  roleId: undefined
};

export const ACTION = {
  APPROVE: 'Approve',
  REJECT: 'Reject',
};
