import { get } from 'lodash';
import * as yup from 'yup';

import {
  ERROR_CODE,
  EXTERNAL_ADMIN,
  EXTERNAL_GENERAL_USER,
  INTERNAL_ADMIN,
  INTERNAL_GENERAL_USER,
  NOTE_FILED_MAX_LENGTH,
  TEXT_FIELD_MAX_LENGTH,
  TEXT_FIELD_MIN_LENGTH,
  TEXT_FIELD_SHORT_MAX_LENGTH
} from '@/utils/constants/AppConstants';
import { stringifyObjectValidateYup } from '@/utils/method';
import { getRoleList } from '@/utils/services/AccountMasterApiService';
import { validationEmail } from '@/utils/services/ProjectApiService';

export const validateSameEmail = async (title: string, ctx: any): Promise<boolean> => {
  const companyId = ctx.parent.organizationId;
  if (!companyId) return true;
  const params = {
    emails: [title],
    companyId
  };
  try {
    const res = await validationEmail(params);
    if (res?.data[0].isSuccess || [ERROR_CODE.USER_INACTIVE, ERROR_CODE.USER_WAITING_CREATE].includes(res?.data[0].errorCode)) {
      return false;
    }
    return true;
  } catch (error: any) {
    return true;
  }
};

const companyHaveRole = async (value: string, ctx: any): Promise<boolean> => {
  const { data } = await getRoleList();
  const fieldCompany = get(ctx, `parent.organizationId`);
  const isCompanyMot = fieldCompany === process.env.REACT_APP_COMPANY_MOT;
  const newRoleData = data.filter((role: any) => {
    return isCompanyMot ? [INTERNAL_ADMIN, INTERNAL_GENERAL_USER].includes(role.code) : [EXTERNAL_ADMIN, EXTERNAL_GENERAL_USER].includes(role.code);
  });

  const checkRole = (item: any) =>
    (isCompanyMot
      ? item.code === INTERNAL_ADMIN || item.code === INTERNAL_GENERAL_USER
      : item.code === EXTERNAL_ADMIN || item.code === EXTERNAL_GENERAL_USER) && item.id === value;

  return newRoleData.some(checkRole);
};

export const FormCreateAccountMaster = yup.object().shape({
  name: yup
    .string()
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: 'account_master:add:username:label' }
      })
    )
    .notUrl(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_092',
        optionsTx: { field: 'account_master:add:username:label' }
      })
    )
    .max(
      TEXT_FIELD_SHORT_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'account_master:add:username:label', limited: TEXT_FIELD_SHORT_MAX_LENGTH }
      })
    ),
  email: yup
    .string()
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: 'account_master:add:email:label' }
      })
    )
    .fullEmail(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_A_016',
        optionsTx: { field: 'account_master:add:email:label' }
      })
    )
    .max(
      TEXT_FIELD_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'account_master:add:email:label', limited: TEXT_FIELD_MAX_LENGTH }
      })
    )
    .debounceValidate(validateSameEmail, stringifyObjectValidateYup({ keyT: 'common:MSG_A_010' })),
  organizationId: yup.string().required(
    stringifyObjectValidateYup({
      keyT: 'common:MSG_C_001_select',
      optionsTx: { field: 'account_master:add:company_name:label' }
    })
  ),
  roleId: yup
    .string()
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_001_select',
        optionsTx: { field: 'account_master:add:authority:label' }
      })
    )
    .test('check-company', stringifyObjectValidateYup({ keyT: 'common:MSG_C_025' }), (value, ctx) => companyHaveRole(value, ctx))
});

export const FormUpdateAccountMaster = yup.object().shape({
  name: yup
    .string()
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: 'account_master:add:username:label' }
      })
    )
    .notUrl(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_092',
        optionsTx: { field: 'account_master:add:username:label' }
      })
    )
    .max(
      TEXT_FIELD_SHORT_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'account_master:add:username:label', limited: TEXT_FIELD_SHORT_MAX_LENGTH }
      })
    ),
  roleId: yup.string().required(
    stringifyObjectValidateYup({
      keyT: 'common:MSG_C_001_select',
      optionsTx: { field: 'account_master:add:authority:label' }
    })
  ),
  status: yup.string().required(
    stringifyObjectValidateYup({
      keyT: 'common:MSG_C_001_select',
      optionsTx: { field: 'account_master:edit:status:label' }
    })
  )
});

export const FormApproveReject = yup.object().shape({
  reasonReject: yup
    .string()
    .nullable()
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: 'account_master:edit:reason_remand:label' }
      })
    )
    .min(
      TEXT_FIELD_MIN_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: 'account_master:edit:reason_remand:label', limited: TEXT_FIELD_MIN_LENGTH }
      })
    )
    .htmlStrippedMaxLength(
      NOTE_FILED_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'account_master:edit:reason_remand:label', limited: NOTE_FILED_MAX_LENGTH }
      })
    )
});
