import { IFormFilter } from '../models';

export const TYPE_CONFIRM = {
  noData: '--',
  unConfirm: 'UnConfirm',
  confirm: 'Confirm',
  emptyData: '0/0'
};

export const DEFAULT_FILTER_FORM: IFormFilter = {
  primary: {
    confirmationCheck: null,
    publishedDateFrom: null,
    publishedDateTo: null
  }
};
