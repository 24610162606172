import AddEditStakeholder from '@pages/project-management/add-edit/scenario/type-c/form/Step6';

const PARENT_PATH = 'steps[2]';

export default function ScenarioAStepFour() {
  return (
    <AddEditStakeholder
      paths={{
        parentPath: PARENT_PATH,
        stakeHolderPath: 'projectStakeholders',
        hasOtherStakeHolderPath: 'hasOtherStakeHolder'
      }}
    />
  );
}
