import { useAppSelector } from '@/hooks';
import { Space } from 'antd';
import { useContext } from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { ExternalProxyContext } from '@pages/project-management/add-edit/ProjectExternalProxy';

import { BaseButton } from '@/components/base-button/BaseButton';
import { TYPE_ICON, showBasePopup } from '@/components/base-popup/BasePopup';

import { getProjectDetails } from '@/redux/project-v2/projectSelector';

import useMutation from '@/hooks/useMutation';

import { API } from '@/utils/constants/Apis';
import { UPLOAD_STATUS } from '@/utils/constants/AppConstants';
import { CREATE_PROJECT_SUCCESS, PROJECT_LIST_URL, SAVE_AS_DRAFT_PROJECT_SUCCESS } from '@/utils/constants/RouteContants';
import { validateWithoutErrorTypes } from '@/utils/helpers/validateHelper';

import CaretLeft from '@/assets/icons/CaretLeft.svg?react';

import { SCENARIO_TYPES } from '../../contants';
import { IFormScenarioE } from '../../models';
import { FormObserver, ScenarioStepMapping } from '../../models/projectExternalProxy';
import ScenarioEContent from './ScenarioE';

export default function ScenarioE() {
  const { loading, setLoading } = useContext(ExternalProxyContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const formObserver = useFormContext<FormObserver<keyof ScenarioStepMapping>>();
  const [selectedStep, scenarioType] = formObserver.watch(['selectedStep', 'scenarioType']);
  const { defaultValues: defaultFormValues } = formObserver.formState;
  const defaultValues = defaultFormValues?.steps?.[0];
  const { data: projectDetails } = useAppSelector(getProjectDetails);

  const { mutate: createScenarioE } = useMutation(API.CREATE_SCENARIO_E, {
    method: 'POST',
    bodyType: 'json',
    showToastError: true,
    showToastSuccess: false
  });

  const { mutate: updateScenarioE } = useMutation(API.CREATE_SCENARIO_E, {
    method: 'PUT',
    bodyType: 'json',
    showToastError: true,
    showToastSuccess: false
  });
  const formatProjectAttachments = (formData: any) => {
    let projectAttachments = formData?.projectAttachments
      ?.filter((file: any) => file.status === UPLOAD_STATUS.DONE)
      ?.map((file: any, index: number) => ({
        ...file,
        displayOrder: index,
        deleted: file?.deleted ?? false
      }));

    defaultValues?.projectAttachments?.forEach((item: any) => {
      if (!projectAttachments.find((i: any) => i.id === item.id) && item.id) {
        projectAttachments.push({ ...item, deleted: true });
      }
    });

    return projectAttachments;
  };
  const onSave = () => {
    formObserver.handleSubmit(async (formValues) => {
      const values = formValues?.steps?.[0];
      const showPopup = await showBasePopup({
        title: String(t('common:MSG_P_012.title')),
        msg: String(t('common:MSG_P_012.content')),
        type: TYPE_ICON.CONFIRM
      });
      if (showPopup !== TYPE_ICON.CONFIRM) return;
      setLoading(true);
      // Call API
      const result = !id ? await createScenarioE(getPayload(values, false)) : await updateScenarioE(getPayload(values, false));
      if (!!result) {
        setTimeout(() => {
          setLoading(false);
          navigate(CREATE_PROJECT_SUCCESS);
        }, 500);
        return;
      }
      setLoading(false);
    })();
  };
  const onSaveAsDraft = async () => {
    const { steps } = formObserver.formState.errors ?? {};
    const hasErrorNotRequired = validateWithoutErrorTypes((steps as FieldValues)?.[0], ['required', 'optionality', 'requiredTextEditorChange']);
    if (hasErrorNotRequired) return;
    const values = formObserver.getValues('steps.0');
    const showPopup = await showBasePopup({
      title: String(t('common:MSG_P_002_title')),
      msg: String(t('common:MSG_P_002_content')),
      type: TYPE_ICON.CONFIRM
    });
    if (showPopup !== TYPE_ICON.CONFIRM) return;
    setLoading(true);
    // Call API
    const result = !id ? await createScenarioE(getPayload(values)) : await updateScenarioE(getPayload(values));
    if (!!result) {
      formObserver.reset((prev) => ({ ...prev, isDirty: false }), { keepValues: true, keepDirty: false });

      setTimeout(() => {
        setLoading(false);
        navigate(SAVE_AS_DRAFT_PROJECT_SUCCESS(result?.data || ''));
      }, 500);
      return;
    }
    setLoading(false);
  };

  const getPayload = (values: IFormScenarioE, isDraft = true) => {
    const note = `<div style="display: flex; gap: 4px"><strong id="label-phone-scenario-e" style="flex-shrink: 0">${t('inquiry:phone_label')}</strong>: <strong id="value-phone-scenario-e" style="white-space: pre-line; word-break: break-word">${values.phoneNumber}</strong></div><div style="display: flex; gap: 4px"><strong id="label-note-scenario-e" style="flex-shrink: 0">${t('basic_information:request_content')}</strong>: <div id="value-note-scenario-e" style="white-space: pre-line; word-break: break-word">${values.requestContent}</div></div>`;
    let command: any = {
      phoneNumber: values.phoneNumber,
      requestContent: values.requestContent,
      projectAttachments: formatProjectAttachments(values),
      note,
      isDraft,
      version: projectDetails?.version
    };
    if (id) {
      command['id'] = id;
    }
    return {
      command: JSON.stringify(command)
    };
  };
  return (
    <div className='flex flex-col gap-4 h-full'>
      <div className='sticky flex justify-between top-0 z-50 bg-gray1'>
        <div className='title-24 whitespace-pre-line font-bold text-24'>
          {!!id ? (
            <p className='title-24 whitespace-pre-line font-bold text-24'>
              <span className='title-24 text-negative'>{t('basic_information:isDraft')}</span> {defaultValues?.code}
            </p>
          ) : (
            t('project:add:title')
          )}
        </div>
        <Space size={16}>
          <div className='min-w-[120px]'>
            <BaseButton
              block
              size='medium'
              type='tertiary'
              disabled={loading}
              onClick={() => {
                formObserver.setValue('selectedStep', 1, { shouldDirty: false });
              }}
            >
              <img src={CaretLeft}></img>
              {t('button:back')}
            </BaseButton>
          </div>
          <div className='min-w-[120px]'>
            <BaseButton block size='medium' type='tertiary' disabled={loading} onClick={() => navigate(PROJECT_LIST_URL)}>
              {t('button:cancel')}
            </BaseButton>
          </div>
          <div className='min-w-[120px]'>
            <BaseButton block size='medium' type='secondary' disabled={loading} onClick={onSaveAsDraft}>
              {t('common:button:save_daft')}
            </BaseButton>
          </div>
          <div className='min-w-[120px]'>
            <BaseButton block size='medium' disabled={loading} onClick={onSave}>
              <span> {t('button:send')} </span>
            </BaseButton>
          </div>
        </Space>
      </div>
      <div className='h-[calc(-136px + 100vh)] overflow-y-scroll w-full border border-solid border-gray2 rounded-lg p-4 bg-white'>
        <p className='subtitle-18 text-black'>{t('project:scenario:title_1')}</p>
        <p className='subtitle-18 text-black'>{t('project:scenario:title_2')}</p>
        {selectedStep === 2 && scenarioType === SCENARIO_TYPES.SCENARIO_E && <ScenarioEContent />}
      </div>
    </div>
  );
}
