import { DefaultOptionType } from 'antd/es/select';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BaseButton } from '@/components/base-button/BaseButton';
import { FormInputDate } from '@/components/form-input-date/FormInputDate';
import { FormInputSelect } from '@/components/form-input-select/FormInputSelect';
import { FormSelect } from '@/components/form-select/FormSelect';

import useAuthorization from '@/hooks/useAuthorization';

import { CATEGORY_PROCESS_OPTION } from '@/utils/constants/master-data/task/task';
import { DataInput } from '@/utils/helpers/common';
import { Ii18n } from '@/utils/interfaces/i18n';

import Minus from '@/assets/icons/Minus.svg';

import { TASK_TYPE } from '../../constant';

interface IFilterProps {
  onSubmit: (values: any) => void;
  onReset?: () => void;
  picOptions: DefaultOptionType[];
  defaultValues: { [key: string]: any };
}

const TaskFormFilter = ({ onSubmit, picOptions, defaultValues }: IFilterProps) => {
  const { t }: Ii18n = useTranslation();
  const form = useForm({ defaultValues });
  const { isInternalRole } = useAuthorization();

  const categoryOption = useMemo(() => {
    const categoryOptionByRole = isInternalRole ? CATEGORY_PROCESS_OPTION : CATEGORY_PROCESS_OPTION.filter((item) => item.value !== TASK_TYPE.TODO);
    return categoryOptionByRole.map((item: any) => ({
      ...item,
      label: t(item.label)
    }));
  }, [CATEGORY_PROCESS_OPTION]);

  const statusOption = [
    {
      label: '開始前',
      value: '開始前'
    },
    {
      label: '進行中',
      value: '進行中'
    },
    {
      label: '遅延',
      value: '遅延'
    },
    {
      label: '完了',
      value: '完了'
    }
  ];

  const disabledButtonReset = () => {
    const { category, status, deadlineDateFrom, deadlineDateTo, picIds } = form.watch();
    return !category && !status && !deadlineDateFrom && !deadlineDateTo && !picIds;
  };

  const handleReset = () => {
    form.reset({
      category: null,
      status: null,
      deadlineDateFrom: null,
      deadlineDateTo: null,
      picIds: null
    });
  };

  const handleSubmit = (values: any) => {
    const { deadlineDateFrom, deadlineDateTo } = values ?? {};
    const convertValue = {
      ...values,
      deadlineDateFrom: DataInput.formatTime(deadlineDateFrom),
      deadlineDateTo: DataInput.formatTime(deadlineDateTo)
    };
    onSubmit(convertValue);
  };

  return (
    <>
      <FormProvider {...form}>
        <div className='p-[20px] w-[680px] flex flex-col gap-[12px] task-form-filter'>
          <div className='text-20 font-bold'>{t('button:filter')}</div>
          <div className='flex gap-[16px]'>
            <FormInputSelect
              name='category'
              labelTx={t('project_detail:task_list:task_category')}
              options={categoryOption}
              placeholderTx={`${t('common:MSG_001_select', { field: t('project_detail:task_list:task_category') })}`}
            />
            <FormInputSelect
              name='status'
              labelTx={t('project_detail:task_list:status')}
              options={statusOption}
              placeholderTx={`${t('common:MSG_001_select', { field: t('project_detail:task_list:status') })}`}
            />
          </div>
          <div className='flex gap-[16px]'>
            <FormInputDate
              name='deadlineDateFrom'
              labelTx='project_detail:task_list:deadline'
              placeholder={t('placeholder:date')}
              disabledDate={(d) => {
                return Boolean(form.getValues('deadlineDateTo') && d.startOf('day').isAfter(form.getValues('deadlineDateTo')));
              }}
            />
            <div className='mt-10'>
              <Minus />
            </div>
            <FormInputDate
              name='deadlineDateTo'
              label='&nbsp;'
              placeholder={t('placeholder:date')}
              disabledDate={(d) => {
                return Boolean(form.getValues('deadlineDateFrom') && d.endOf('day').isBefore(form.getValues('deadlineDateFrom')));
              }}
            />
          </div>
          <FormSelect
            mode='multiple'
            name='picIds'
            label={String(t('project_detail:task_list:task_pic'))}
            placeholder={String(
              t('placeholder:select', {
                field: t('project_detail:task_list:task_pic')
              })
            )}
            options={picOptions}
          />
          <div className='flex justify-end space-x-2'>
            <div className='w-[120px]'>
              <BaseButton type='tertiary' size='medium' disabled={disabledButtonReset()} onClick={handleReset}>
                {t('master_data:document_management:btn_reset')}
              </BaseButton>
            </div>
            <div className='w-[120px]'>
              <BaseButton type='primary' size='medium' onClick={form.handleSubmit(handleSubmit)}>
                <span>{t('master_data:document_management:btn_apply')} </span>
              </BaseButton>
            </div>
          </div>
        </div>
      </FormProvider>
    </>
  );
};

export default TaskFormFilter;
