import { DefaultOptionType } from 'antd/es/select';
import { DetailedHTMLProps, ImgHTMLAttributes, useEffect, useMemo, useState } from 'react';

import AppTooltip from '@/components/app-tooltip/AppTooltip';

import { convertFullWidthToHalfWidth } from '@/utils/helpers/globalHelper';

import { useAppSelector } from '.';
import { ICategory, IForm } from '../utils/interfaces/masterdata';
import { ICountry } from '../utils/interfaces/template';

const renderImageCountry = (code: string) => {
  if (!code.toLocaleLowerCase().includes('other') && !code.toLocaleLowerCase().includes('uae') && !code.toLocaleLowerCase().includes('common')) {
    return code.toLocaleLowerCase();
  }
  return '';
};

export const mappingOptionsCountry = (countries: ICountry[]) => {
  return countries.map((c: ICountry) => {
    const imgUrl = renderImageCountry(c.code.toLocaleLowerCase());
    const FlagIcon = (props: DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) => {
      return <img src={new URL(`../assets/country-flags/${imgUrl}.svg`, import.meta.url).href} width={28} height={20} loading='lazy' {...props} />;
    };
    return {
      label: c?.countryName || c?.name || '',
      value: c.id,
      imgUrl: renderImageCountry(c.code.toLocaleLowerCase()),
      FlagIcon,
      id: c.id,
      code: c.code
    };
  });
};

export const FlagIcon = ({
  imgUrl = '',
  ...props
}: { imgUrl: string } & DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) => {
  return <img src={new URL(`../assets/country-flags/${imgUrl}.svg`, import.meta.url).href} width={28} height={20} loading='lazy' {...props} />;
};

export interface ICountryOption extends DefaultOptionType {
  label: string | number;
  value: string | number;
  imgUrl?: string;
  code?: string;
  id?: string;
  FlagIcon: (props: DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) => JSX.Element;
}
/**\
 * @returns filterOption - function to filter the option with type countries (which has label include flag icon) in the select
 * @returns countryOptions - list of countries with flag icon
 * @returns formOptions - list of forms
 * @returns otherCountry - other country object
 * @returns visaCategoriesGroupOption - list of visa categories group
 * @returns customCountryOptions - list of countries with flag icon and formatted label
 */
const useOptionsGlobal = () => {
  const countries = useAppSelector((state) => state.global.countries);
  const forms = useAppSelector((state) => state.global.forms);
  const [countryOptions, setCountryOptions] = useState<ICountryOption[]>([]);

  const [otherCountry, setOtherCountry] = useState<ICountry>();
  const [formOptions, setFormOptions] = useState<DefaultOptionType[]>([]);
  const [visaCategoriesGroupOption, setVisaCategoriesGroupOption] = useState<DefaultOptionType[]>([]);

  const customCountryOptions = useMemo(() => {
    return countryOptions.map((option: DefaultOptionType, index: number) => {
      return {
        ...option,
        originLabel: option.label,
        label: (
          <>
            <div className='flex items-center base-input-select-option'>
              <AppTooltip title={option.label}>
                {option.imgUrl && (
                  <div className='flex items-center'>
                    {option?.FlagIcon && <option.FlagIcon className='mr-[11px]' alt={String(option.label)} />}
                    <span className='w-full'>{option.label}</span>
                  </div>
                )}
                {!option.imgUrl && <span className='w-full'>{option.label}</span>}
              </AppTooltip>
            </div>
          </>
        )
      };
    }) as DefaultOptionType[];
  }, [countryOptions]);
  useEffect(() => {
    if (countries && countries.length > 0) {
      setCountryOptions(mappingOptionsCountry(countries));
      const findOtherCountry = countries.find((c: ICountry) => c.code.toLocaleLowerCase().includes('other'));
      setOtherCountry(findOtherCountry);

      // set visaCategoriesGroupOption
      let categoriesGroup: DefaultOptionType[] = countries.map((country: ICountry) => {
        return {
          ...country,
          title: country.countryName,
          label: <span className='font-bold'>{country.countryName}</span>,
          options: country?.categories?.map((category: ICategory) => ({
            label: category.code.toUpperCase(),
            value: category.id
          }))
        };
      });
      setVisaCategoriesGroupOption(categoriesGroup);
    }
  }, [countries]);

  useEffect(() => {
    if (forms) {
      setFormOptions(
        forms.map((f: IForm) => {
          return {
            label: f.name,
            value: f.id
          };
        })
      );
    }
  }, [forms]);

  const filterOption = (input: string, option?: DefaultOptionType) => {
    const labelToCheck = ((option?.originLabel as string) ?? '').trim();
    const formattedLabel = convertFullWidthToHalfWidth(labelToCheck) ?? '';
    const lowercaseInput = input.toLowerCase();
    const formattedLabelLowercase = formattedLabel.toLowerCase();
    return formattedLabelLowercase.startsWith(convertFullWidthToHalfWidth(lowercaseInput));
  };

  return {
    customCountryOptions,
    countryOptions,
    formOptions,
    otherCountry,
    visaCategoriesGroupOption,
    filterOption
  };
};

export default useOptionsGlobal;
