import { useAppDispatch } from '@/hooks';
import Quill from 'quill';
import { Delta } from 'quill/core';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { TASK_TYPE } from '@/pages/template-management/view/constants';
import { PharseMasterType } from '@pages/announcement-management/add-edit/models';

import BaseModal from '@/components/base-modal/BaseModal';
import { FILE_ATTACHMENT_STATUS, FileAttachmentStatus } from '@/components/form-attachment/FileAttachment';
import { IModuleToolbarOption } from '@/components/form-box-editor/components/SelectBox';
import { MentionBlot } from '@/components/form-box-editor/modules';
import { DEFAULT_SUGGEST_KEY } from '@/components/form-box-editor/modules/Mention';

import { setAlertNotification } from '@/redux/globalReducer';

import useAuthorization from '@/hooks/useAuthorization';
import useFetch from '@/hooks/useFetch';
import useMiniWindow from '@/hooks/useMiniWindow';
import useMutation from '@/hooks/useMutation';

import { ALLOW_ALL_FILE, LOCALSTORAGE, MAPPING_ERROR_CODE_KEY, TYPE } from '@/utils/constants/AppConstants';
import { COMMENT_CHAT_OF_TASK_URL, VIEW_TASK_OF_PROJECT_URL } from '@/utils/constants/RouteContants';
import { handleDownloadSingleFile } from '@/utils/helpers/fileHelper';
import { uploadMultipleFileWithType } from '@/utils/services/FileService';
import { API } from '@utils/constants/Apis';
import { convertFullWidthToHalfWidth, groupByKey } from '@utils/helpers/globalHelper';

import CommentView from './View';
import { COMMENT_TYPE } from './components/CommentBox';
import { COMMENT_MODE, MAXIMUM_FILE, MAXIMUM_SIZE_UPLOAD_TO_BYTE, READED, WIDGET_TYPE } from './constants';
import { FileTypeComment, IAccountData, IComment, ICommentForm, IProjectDetail, IReqComment, IStakeHolder, UserSuggestions } from './models';

import './styles/Container.scss';

export type FileError = {
  status: FileAttachmentStatus;
  msgError: string;
};
export interface ICommentWidgetContainerProps {
  className?: string;
  styles?: React.CSSProperties;
  projectId: string;
  projectProcessId: string | null;
  taskId: string;
  taskType: TASK_TYPE.TODO | TASK_TYPE.OTHER | TASK_TYPE.MOT | string;
  setHasComment?: Dispatch<SetStateAction<boolean>>;
}

const CommentWidgetContainer = (props: ICommentWidgetContainerProps) => {
  const { projectId, projectProcessId, taskId, taskType, setHasComment } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const queryStakeHolder = useRef({ query: 'activeOnly' });
  const { isExternalRole, isInternalRole } = useAuthorization();
  const localStorageUser = localStorage.getItem(LOCALSTORAGE.USER);
  const parseLocalStorageUser = localStorageUser ? JSON.parse(localStorageUser) : {};
  const { open: openDocumentList } = useMiniWindow();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { data: projectDetail } = useFetch<IProjectDetail>(API.GET_PROJECT_DETAIL(projectId ?? ''), 'GET');
  const { data: phraseMaster } = useFetch<PharseMasterType[]>(isInternalRole ? API.GET_PHRASES() : '', 'GET');
  const { data: stakeHolders } = useFetch<IStakeHolder[]>(API.GET_STAKEHOLDERS_BY_PROJECT(projectId), 'GET', queryStakeHolder.current);
  const { data: usersMotPic } = useFetch<IAccountData[]>(API.GET_USERS_MOT_PIC(true), 'GET');
  const { mutate: postCommentAPI } = useMutation(API.POST_COMMENT_TASK_OF_PROJECT(projectId, projectProcessId ?? '', taskId), {
    method: 'POST',
    bodyType: 'json',
    showToastError: true,
    showToastSuccess: false
  });
  const { mutate: updateCommentAPI } = useMutation(API.UPDATE_COMMENT_TASK_OF_PROJECT(projectId, projectProcessId ?? '', taskId), {
    method: 'PUT',
    bodyType: 'json',
    showToastError: true,
    showToastSuccess: false
  });
  const { mutate: getAllCommentsAPI } = useMutation(API.GET_COMMENTS_TASK_OF_PROJECT(projectId, projectProcessId ?? '', taskId), {
    method: 'GET',
    showToastError: true,
    showToastSuccess: false
  });
  const { mutate: readCommentsAPI } = useMutation(API.READ_COMMENTS_TASK_OF_PROJECT(taskId), {
    method: 'PUT',
    bodyType: 'json',
    showToastError: true,
    showToastSuccess: false
  });
  const form = useForm<ICommentForm>({ defaultValues: { chat: '', files: [], mode: COMMENT_MODE.DEFAULT } });
  const [comments, setComments] = useState<IComment[]>([]);
  const [showTitleComment, setShowTitleComment] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loadingAttachments, setLoadingAttachments] = useState(false);
  const [selectedImagePreview, setSelectedImagePreview] = useState('');

  const usersCustomerAndMot = useMemo<UserSuggestions[]>(() => {
    const stakeholders: UserSuggestions[] = [];
    const taskPics: UserSuggestions[] = [];

    (stakeHolders || []).forEach((el) => {
      if (el.stakeHolderStatus && el.stakeHolderId !== parseLocalStorageUser?.id) {
        stakeholders.push({
          id: el.stakeHolderId ?? '',
          name: el.stakeHolderName ?? '',
          email: el.stakeHolderEmail ?? '',
          value: el.stakeHolderId ?? '',
          label: el.stakeHolderName ?? ''
        });
      }
    });

    (usersMotPic || []).forEach((el) => {
      if (el.status && el.id !== parseLocalStorageUser?.id && el.id === projectDetail?.picId) {
        taskPics.push({
          ...el,
          value: el.id ?? '',
          label: el.name ?? ''
        });
      }
    });

    return [...stakeholders, ...taskPics];
  }, [stakeHolders, projectDetail]);

  const usersTodo = useMemo<UserSuggestions[]>(() => {
    return (
      usersMotPic
        ?.filter((e) => e.status && e.id !== parseLocalStorageUser?.id)
        .map((el) => ({
          ...el,
          value: el.id ?? '',
          label: el.name ?? ''
        })) ?? []
    );
  }, [usersMotPic, parseLocalStorageUser?.id]);

  const usersSuggestions = taskType === TASK_TYPE.TODO ? usersTodo : usersCustomerAndMot;

  const [chat, files, mode] = form.watch(['chat', 'files', 'mode']);
  const refEditor = useRef<Quill>();
  const refIdUnread = useRef<string | null>(null);
  const allFileUploaded = useRef(files);

  useEffect(() => {
    if (refEditor.current) {
      if (mode === COMMENT_MODE.EDIT) (refEditor.current.getModule('mention') as any).options = () => {};
      else
        (refEditor.current.getModule('mention') as any).options = {
          getSuggestions: (search: string) => handleSuggestions(search),
          suggestKey: DEFAULT_SUGGEST_KEY
        };
    }
  }, [mode, usersSuggestions]);

  useEffect(() => {
    getAllComments();
    const pathname = window.location.pathname;
    if (pathname === COMMENT_CHAT_OF_TASK_URL(projectId, taskId)) {
      setShowTitleComment(false);
    }
  }, []);

  useEffect(() => {
    if (!setHasComment) return;
    const emptyComment = (!chat || chat === '<p></p>') && !files?.length;
    setHasComment(!emptyComment);
  }, [chat, files]);

  useEffect(() => {
    allFileUploaded.current = [...files];
    const elementAttachments = document.querySelector('.ql-toolbar-file-attachment.inline-block');
    if (!elementAttachments) return;
    if (files?.filter((el) => !el.deleted).length >= MAXIMUM_FILE) {
      elementAttachments.className = 'ql-toolbar-file-attachment inline-block pointer-events-none opacity-30';
    } else {
      elementAttachments.className = 'ql-toolbar-file-attachment inline-block';
    }
  }, [files]);

  useEffect(() => {
    setLoading(true);
    onResetForm();
  }, [projectId, projectProcessId, taskId]);

  useEffect(() => {
    const listCommentId = document.getElementById('listComment');
    if (!listCommentId) return;
    const handleClickImage = (event: any) => {
      if (event?.target?.tagName === 'IMG') {
        setSelectedImagePreview(event.target?.src ?? '');
      }
    };
    listCommentId.addEventListener('mousedown', handleClickImage);
    return () => {
      listCommentId.removeEventListener('mousedown', handleClickImage);
    };
  }, [comments]);

  const onScrollToUnreadComment = () => {
    const listCommentElement = document.getElementById('listComment');
    const unReadElement = document.getElementById(refIdUnread.current ?? 'unRead');

    if (!listCommentElement) return;
    const parentRect = listCommentElement.getBoundingClientRect();

    if (unReadElement) {
      const elementRect = unReadElement.getBoundingClientRect();
      const offsetTop = elementRect.top - parentRect.top + listCommentElement.scrollTop;
      listCommentElement.scrollTo({
        top: offsetTop,
        left: 0,
        behavior: 'instant'
      });
    } else {
      listCommentElement.scrollTo({
        top: listCommentElement?.scrollHeight ?? 0,
        left: 0,
        behavior: 'instant'
      });
    }
  };

  const getAllComments = async () => {
    try {
      if (!projectId || !projectProcessId || !taskId) return;
      const { data } = await getAllCommentsAPI({});
      if (data) {
        setComments(
          data?.map((el: IComment) => {
            return {
              ...el,
              type: el?.createdBy === parseLocalStorageUser?.id ? COMMENT_TYPE.ME : COMMENT_TYPE.OTHER
            };
          }) ?? []
        );
        const readedAllComments = searchParams.get(READED);
        if (readedAllComments) {
          await readCommentsAPI({});
          navigate(`${VIEW_TASK_OF_PROJECT_URL(projectId ?? '', taskId ?? '')}`, { replace: true });
        }
        setTimeout(() => onScrollToUnreadComment(), 100);
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (data: ICommentForm) => {
    if (data?.mode === COMMENT_MODE.DEFAULT) return onPostComment(data);
    onUpdateComment(data);
  };

  const onResetForm = () => {
    getAllComments();
    form.reset();
    allFileUploaded.current = [];
  };

  const convertCommentRequest = (data: ICommentForm) => {
    const usersMention = getUserMentionsInEditor();
    const dataReq: IReqComment = {
      content: data?.chat ?? '',
      usersMention,
      attachments: files.filter((el) => !el.errorMsg) ?? []
    };
    return dataReq;
  };

  const getUserMentionsInEditor = () => {
    if (refEditor?.current) {
      const userMentions = [];
      const delta = new Delta(refEditor?.current?.getContents());
      for (const op of delta.ops) {
        const attributes = MentionBlot.getAttributeFromOp(op);
        if (attributes?.id) {
          userMentions.push({ userId: attributes.id });
        }
      }
      return userMentions;
    }
    return [];
  };

  const onPostComment = async (data: ICommentForm) => {
    const dataReq = convertCommentRequest(data);
    if (!projectId || !projectProcessId || !taskId) return;
    try {
      const { data: comment } = await postCommentAPI(dataReq);
      if (comment) onResetForm();
    } catch (e) {}
  };

  const onUpdateComment = async (data: ICommentForm) => {
    const dataReq = convertCommentRequest(data);
    if (!projectId || !projectProcessId || !taskId || !data?.commentId) return;
    try {
      const { data: comment } = await updateCommentAPI(dataReq, { commentId: data?.commentId });
      if (comment) onResetForm();
    } catch (e) {}
  };

  const renderFileError = (file: FileTypeComment, messageError: string) => {
    return {
      id: uuidv4(),
      name: file.name,
      errorMsg: t(messageError, { files: ALLOW_ALL_FILE }),
      status: FILE_ATTACHMENT_STATUS.ERROR
    };
  };

  const validateFile = (file: File) => {
    const validFileTypes = ALLOW_ALL_FILE.split('/') || [];
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    if (!fileExtension || !validFileTypes.includes(fileExtension)) {
      return renderFileError(file, t('common:MSG_C_008', { files: ALLOW_ALL_FILE }));
    }
    if (file.size > MAXIMUM_SIZE_UPLOAD_TO_BYTE) {
      return renderFileError(file, t('common:MSG_C_006'));
    }
    const isDuplicateName = !!allFileUploaded.current.find((item: any) => !item.deleted && item.name === file.name);
    if (isDuplicateName) {
      return renderFileError(file, t('common:MSG_C_009', { fileName: file.name }));
    }
    return false;
  };

  const handleChangeFile = async (files: File[]) => {
    if (!files.length) return;
    const filesUploadedCount = allFileUploaded.current.filter((el) => !el.deleted)?.length;
    if (files.length > 10 || files.length + filesUploadedCount > 10) {
      return dispatch(
        setAlertNotification({
          show: true,
          type: TYPE.ERROR,
          message: t('common:MSG_C_007')
        })
      );
    }
    const newFiles: File[] = [];
    const fileValidatorErrors: FileTypeComment[] = [];

    files.forEach((file) => {
      const fileValidation = validateFile(file);
      if (fileValidation) fileValidatorErrors.push(fileValidation);
      else newFiles.push(file);
    });

    if (!newFiles.length) {
      form.setValue('files', [...allFileUploaded.current, ...fileValidatorErrors], { shouldDirty: true });
      return;
    }

    try {
      const formData = new FormData();
      newFiles.forEach((item) => {
        const file = new File([item], item.name);
        formData.append(`files`, file);
      });
      setLoadingAttachments(true);
      const { data } = await uploadMultipleFileWithType('attachments', formData);
      let filesUploaded: FileTypeComment[] = [...allFileUploaded.current, ...fileValidatorErrors];
      if (data?.files?.length) {
        filesUploaded = [...filesUploaded, ...data.files];
      }
      if (data?.errors?.length) {
        const filesErrors = data.errors.map((el: any) => {
          const messageError = MAPPING_ERROR_CODE_KEY[el?.errorDetail];
          return renderFileError(el, messageError);
        });
        filesUploaded = [...filesUploaded, ...filesErrors];
      }
      form.setValue('files', filesUploaded, { shouldDirty: true });
    } catch (e) {
    } finally {
      setLoadingAttachments(false);
    }
  };

  const handleSuggestions = async (search: string) => {
    try {
      const halfWidthLowercaseSearch = convertFullWidthToHalfWidth(search.toLowerCase());
      const results = usersSuggestions?.filter((user) => {
        const halfWidthLowercaseName = convertFullWidthToHalfWidth(user.name.toLowerCase());
        const halfWidthLowercaseEmail = convertFullWidthToHalfWidth(user.email.toLowerCase());
        const matchedName = halfWidthLowercaseName.includes(halfWidthLowercaseSearch);
        const matchedEmail = halfWidthLowercaseEmail.includes(halfWidthLowercaseSearch);
        return matchedName || matchedEmail;
      });
      return results ?? [];
    } catch (error) {
      return [];
    }
  };

  const onDownloadFile = (file: FileTypeComment) => {
    if (!file) return;
    return handleDownloadSingleFile(file.blobPath ?? '', file.name ?? '');
  };

  const onDeleteFile = (id: string) => {
    if (!id) return;
    const newFiles = files.map((file) => {
      return {
        ...file,
        deleted: file.deleted || file.id === id
      };
    });
    form.setValue('files', newFiles, { shouldDirty: true });
  };

  const convertPhasesData = () => {
    if (!phraseMaster?.length) return [];
    const groupedData = groupByKey(phraseMaster ?? [], 'categoryName');
    return Object.entries(groupedData || {}).map(([categoryName, data]) => ({
      name: categoryName,
      children: data,
      collapsible: 'header',
      key: categoryName
    })) as IModuleToolbarOption[];
  };

  const onFocusEditor = async () => {
    const existCommentUnread = !!comments?.filter((el) => !el.readed)?.length;
    const firstUnreadIndex = comments.findIndex((comment: IComment) => !comment.readed);
    const idOldCommentUnread = comments?.[firstUnreadIndex]?.id ?? null;
    refIdUnread.current = idOldCommentUnread;
    try {
      if (!taskId || !existCommentUnread) return;
      const { data } = await readCommentsAPI({});
      if (data) await getAllComments();
      if (idOldCommentUnread) onScrollToUnreadComment();
    } catch (e) {}
  };

  const handleCloseEditMode = () => {
    form.setValue('mode', COMMENT_MODE.DEFAULT, { shouldDirty: false });
    form.setValue('chat', '', { shouldDirty: false });
    form.setValue('files', [], { shouldDirty: false });
    form.setValue('commentId', '', { shouldDirty: false });
  };

  const onClickEditBtn = (comment: IComment) => {
    form.setValue('mode', COMMENT_MODE.EDIT, { shouldDirty: false });
    form.setValue('chat', comment?.content, { shouldDirty: false });
    form.setValue('files', comment?.attachments, { shouldDirty: false });
    form.setValue('commentId', comment?.id, { shouldDirty: false });
  };

  const openCommentChatWindow = () => {
    if (!projectId || !taskId) return;
    openDocumentList(COMMENT_CHAT_OF_TASK_URL(projectId, taskId), 'mini_window_comment_chat');
  };

  const getWidgetType = () => {
    if (isExternalRole) {
      return WIDGET_TYPE.EXTERNAL;
    } else if (isInternalRole) {
      return WIDGET_TYPE.INTERNAL;
    }
    return WIDGET_TYPE.UNKNOWN;
  };
  return (
    <>
      <FormProvider {...form}>
        <CommentView
          refEditor={refEditor}
          type={getWidgetType()}
          options={convertPhasesData()}
          list={comments ?? []}
          onChangeFile={handleChangeFile}
          onSubmit={form.handleSubmit(onSubmit)}
          onDownloadFile={onDownloadFile}
          onDeleteFile={onDeleteFile}
          onFocusEditor={onFocusEditor}
          handleCloseEditMode={handleCloseEditMode}
          onClickEditBtn={onClickEditBtn}
          openCommentChatWindow={openCommentChatWindow}
          showTitle={showTitleComment}
          loading={loading}
          loadingAttachments={loadingAttachments}
          {...props}
        />
      </FormProvider>
      <BaseModal
        openModal={!!selectedImagePreview.length}
        onCancel={() => setSelectedImagePreview('')}
        maskClosable={true}
        contentElement={
          <div style={{ backgroundImage: `url(${selectedImagePreview})` }} className='w-full h-full bg-contain bg-no-repeat bg-center' />
        }
        width={890}
        wrapClassName='modal-comment-image-preview'
      />
    </>
  );
};

export default CommentWidgetContainer;
