import React from 'react';

const CounterLabel = ({ title, count }: { title: string; count: string }) => {
    return (
        <div className='flex items-center justify-start'>
            <p className='subtitle-16'>{title}</p>
            <div className='body-700 px-[4px] bg-gray2 rounded-[4px] ml-[8px]'>{count}</div>
        </div>
    );
};

export default CounterLabel;