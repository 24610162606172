import { useAppDispatch } from '@/hooks';
import { Dispatch, SetStateAction, createContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { ConfirmCancelEditing } from '@/components/confirm-popup';

import { fetchUserInfo } from '@/redux/globalReducer';
import { cleanupProjectDetails, cleanupTravelTypes, fetchTravelTypes, fetchUserRoles, setProjectDetails } from '@/redux/project-v2/projectReducer';

import useAuthorization from '@/hooks/useAuthorization';

import { IUserProfile } from '@/utils/interfaces/user';
import { getProjectExternal } from '@/utils/services/ProjectApiService';

import { SCENARIO_TYPES } from './contants';
import { DEFAULT_FORM_OBSERVER } from './contants/projectExternalProxy';
import { FormObserver, ScenarioStepMapping } from './models/projectExternalProxy';
import FirstStep from './scenario/first-step';
import ScenarioA from './scenario/type-a';
import { convertResponseForAType } from './scenario/type-a/helpers';
import ScenarioB from './scenario/type-b';
import { convertResponseForBType } from './scenario/type-b/helpers';
import ScenarioC from './scenario/type-c';
import { convertResponseForCType } from './scenario/type-c/helpers';
import ScenarioDForm from './scenario/type-d';
import { convertResponseForDType } from './scenario/type-d/helpers';
import ScenarioE from './scenario/type-e';
import { convertResponseForEType } from './scenario/type-e/helpers';
import { schemaProxy } from './validation/scenario';

export const convertScenarioTypeToStep = (type: SCENARIO_TYPES, responses: any, user?: IUserProfile | null) => {
  switch (type) {
    case SCENARIO_TYPES.SCENARIO_A:
      return convertResponseForAType(responses, user);
    case SCENARIO_TYPES.SCENARIO_B:
      return convertResponseForBType(responses);
    case SCENARIO_TYPES.SCENARIO_C:
      return convertResponseForCType(responses, user);
    case SCENARIO_TYPES.SCENARIO_D:
      return convertResponseForDType(responses, user);
    case SCENARIO_TYPES.SCENARIO_E:
      return convertResponseForEType(responses);
    default:
      return responses;
  }
};

/**
 * @description ProjectExternalProxy to control all Add/Edit steps of project external.
 * ---
 * **If u wanna using this component, u need define:**
 * @type - Scenario Step Type. See detail at: {@link src/pages/project-management/add-edit/models/projectExternalProxy.ts}.
 * @function **renderOtherStepsByType** - declare your render inside this function.
 * @function **convertScenarioTypeToStep** - declare your logic for generate default values of form (which is control your steps) inside this function.
 * @function **schemaProxy** - declare your schema validation for your form type inside this function.
 * ---
 * @example - easy to use form for control steps:
 * ```tsx
 * const formOfTypeBForStep2 = useFormContext<FormObserver<'scenario-b', 'step-2'>>();
 * const { watch, control, getValues } = formOfTypeBForStep2;
 * const [countryCode, visaCategoryId] = watch([PATHS.ASSIGNMENT_COUNTRY, PATHS.VISA_CATEGORY]);
 * ...
 * ```
 */
type ExternalProxyContextType = { loading: boolean; setLoading: Dispatch<SetStateAction<boolean>> };
export const ExternalProxyContext = createContext<ExternalProxyContextType>({
  loading: false,
  setLoading: () => {}
});
const ProjectExternalProxy = () => {
  const { id } = useParams();
  const { user } = useAuthorization();
  const dispatch = useAppDispatch();
  const formObserver = useForm<FormObserver<keyof ScenarioStepMapping>>({
    mode: 'all',
    defaultValues: () => getDefaultFormValues(),
    resolver: schemaProxy
  });
  const [loading, setLoading] = useState(false);
  const [selectedStep, scenarioType] = formObserver.watch(['selectedStep', 'scenarioType']);
  const { isDirty, isSubmitSuccessful } = formObserver.formState;

  useEffect(() => {
    if (!user) return;
    dispatch(fetchUserRoles());
  }, [user]);

  useEffect(() => {
    dispatch(fetchUserInfo());
    dispatch(fetchTravelTypes());
    return () => {
      dispatch(cleanupProjectDetails());
      dispatch(cleanupTravelTypes());
    };
  }, []);

  const getDefaultFormValues = async () => {
    if (!id) return DEFAULT_FORM_OBSERVER;
    try {
      const { data } = await getProjectExternal(String(id));
      dispatch(setProjectDetails({ data }));
      return convertScenarioTypeToStep(data.typeName, data, user);
    } catch (error) {
      dispatch(cleanupProjectDetails());
      return DEFAULT_FORM_OBSERVER;
    }
  };

  const renderOtherStepsByType = (type: SCENARIO_TYPES) => {
    switch (type) {
      case SCENARIO_TYPES.SCENARIO_A:
        return <ScenarioA />;
      case SCENARIO_TYPES.SCENARIO_B:
        return <ScenarioB />;
      case SCENARIO_TYPES.SCENARIO_C:
        return <ScenarioC />;
      case SCENARIO_TYPES.SCENARIO_D:
        return <ScenarioDForm />;
      case SCENARIO_TYPES.SCENARIO_E:
        return <ScenarioE />;
      default:
        return null;
    }
  };

  return (
    <ExternalProxyContext.Provider value={{ loading, setLoading }}>
      <FormProvider {...formObserver}>
        {selectedStep === 1 && <FirstStep />}
        {selectedStep !== 1 && renderOtherStepsByType(scenarioType)}
        <ConfirmCancelEditing condition={isDirty && !isSubmitSuccessful} />
      </FormProvider>
    </ExternalProxyContext.Provider>
  );
};

export default ProjectExternalProxy;
