import { useAppDispatch } from '@/hooks';
import { cloneDeep, isEmpty } from 'lodash';
import React, { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import EmptyDataWithIcon from '@/components/EmptyData/EmptyDataWithIcon';
import AppTooltip from '@/components/app-tooltip/AppTooltip';
import { BaseButton } from '@/components/base-button/BaseButton';
import TableFetch from '@/components/common/table-fetch';

import { setAlertNotification } from '@/redux/globalReducer';

import { useFilterConfigs } from '@/hooks/useFilterConfigs';
import useMutation from '@/hooks/useMutation';

import { ROUTER_IDS } from '@/utils/constants';
import { TYPE } from '@/utils/constants/AppConstants';
import { MASTER_LIST_URL } from '@/utils/constants/RouteContants';
import * as MasterDataCompany from '@/utils/constants/master-data/company/company';
import { formatDateTime } from '@/utils/helpers/globalHelper';

import IconAddNew from '@assets/icons/base-filter/icon-add-new.svg';

import useAuthorization, * as s from '../../../../hooks/useAuthorization';
import AddEditModal from '../add-edit';
import { DEFAULT_FILTER_FORM, MOT_COMPANY_ID } from '../constants';
import { IAddEditForm, ICompany, IFilterForm, IFilters } from '../models';
import { generateFilter } from '../utils';
import TableHeader from './Header';

const CompanyMasterList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isInternalAdmin, hasPermission } = useAuthorization();
  const { data, update: updateFilterConfigs } = useFilterConfigs<IFilterForm>(ROUTER_IDS.COMPANY_MASTER_LIST);
  const [recordEditing, setRecordEditing] = useState<ICompany | IAddEditForm>();
  const wrapperRef = useRef<any>(null);
  const { mutate: deleteCompanies } = useMutation(`/mst/companies`, {
    method: 'DELETE',
    showToastError: true,
    paramMsg: { reason: t('master_data:company:delete:MSG_C_026_description') }
  });

  const getDefaultFilters = () => {
    const results = {
      form: cloneDeep(DEFAULT_FILTER_FORM),
      query: {}
    };
    if (data && !isEmpty(data)) {
      results.form.primary = data.primary;
      results.query = { filter: generateFilter(results.form) };
    }
    return results;
  };
  const [filter, setFilter] = useState<IFilters>(getDefaultFilters().query);
  const formFilter = useForm<IFilterForm>({
    defaultValues: getDefaultFilters().form
  });

  const handleDelete = async (keys: React.Key[], records: any[]) => {
    const ids = records.map((item) => ({ id: item.id, version: item.version }));
    const responses = await deleteCompanies({ ids });
    if (!responses) return 0;
    const { data } = responses;
    if (data.successCount === keys.length) {
      dispatch(
        setAlertNotification({
          show: true,
          type: TYPE.SUCCESS,
          message: t('common:MSG_C_003', { item: t('common:button:delete') })
        })
      );
    }

    if (data.successCount < keys.length) {
      dispatch(
        setAlertNotification({
          show: true,
          type: TYPE.SUCCESS,
          message: t('common:MSG_C_020', { deletable: data.successCount, total: keys.length })
        })
      );
    }
    return data.successCount;
  };

  const generateColumns = () => {
    const columns = [
      {
        title: t('master_data:company:table_name_column'),
        key: 'name',
        width: 970,
        className: 'max-w-[981px] h-[38px]',
        render: (_value: any, item: ICompany) => {
          return (
            <AppTooltip title={item.name}>
              {isInternalAdmin ? (
                <Link
                  className='w-fit max-w-full truncate block text-link underline hover:underline'
                  to={`${MASTER_LIST_URL}?companyId=${item.id}`}
                  onClick={(e) => {
                    e.preventDefault();
                    setRecordEditing(item);
                  }}
                >
                  {item.name}
                </Link>
              ) : (
                <>{item.name}</>
              )}
            </AppTooltip>
          );
        },
        ellipsis: true
      },
      {
        title: t('master_data:company:table_updated_by_column'),
        dataIndex: 'updatedName',
        width: 300,
        className: 'max-w-[300px] h-[38px]',
        key: 'updater',
        render: (_: any, record: ICompany) => (
          <AppTooltip title={record?.updatedName ?? record?.createdName}>
            <span className='truncate inline-block max-w-full align-top'>{record?.updatedName ?? record?.createdName}</span>
          </AppTooltip>
        ),
        ellipsis: true
      },
      {
        title: t('master_data:company:table_updated_at_column'),
        dataIndex: 'updatedDate',
        key: 'updatedDate',
        width: 300,
        className: 'max-w-[300px] h-[38px]',
        render: (_: any, record: ICompany) => (
          <AppTooltip title={formatDateTime((record?.updatedDate || record?.createdDate) ?? '')}>
            {formatDateTime((record?.updatedDate || record?.createdDate) ?? '')}
          </AppTooltip>
        ),
        sorter: true,
        columnKey: 'updatedDate'
      }
    ];
    return columns;
  };

  const updateFilter = (values: IFilterForm) => {
    setFilter({ filter: generateFilter(values) });
    updateFilterConfigs(values);
  };

  const gotoAddNew = () => setRecordEditing({ name: '' });
  return (
    <div className='company-master' ref={wrapperRef}>
      <div className='grid grid-cols-2 gap-4 page-list-header mb-[12px]'>
        <div className='title-24'>{t('master_data:company:title')}</div>
        <div className='text-end'>
          {hasPermission(MasterDataCompany.CREATE_NEW_COMPANY_LIST, s.C) && (
            <BaseButton size='medium' icon={<IconAddNew style={{ verticalAlign: 'sub' }} />} onClick={gotoAddNew} className='float-right'>
              {t('master_data:create_new')}
            </BaseButton>
          )}
        </div>
      </div>
      <div className='page-content'>
        <div className='data-table'>
          <TableFetch
            tableHeader={
              <div className='mb-[12px]'>
                <FormProvider {...formFilter}>
                  <TableHeader onSubmit={updateFilter} />
                </FormProvider>
              </div>
            }
            rowSelection={
              isInternalAdmin
                ? {
                    getCheckboxProps: (record) => ({
                      disabled: record.id === MOT_COMPANY_ID
                    })
                  }
                : null
            }
            apiEndpoint='/mst/companies/search'
            apiMethod='POST'
            columns={generateColumns()}
            filterDefault={filter}
            scrollTable={{ maxHeight: '100vh - 248px' }}
            showDelete={hasPermission(MasterDataCompany.COMPANY_LIST, s.C)}
            showDownload={false}
            handleDeleteClick={hasPermission(MasterDataCompany.COMPANY_LIST, s.C) ? handleDelete : undefined}
            deleteConfirmPopup={{
              type: 'discard',
              title: t('common:MSG_C_012:title', { field: t('master_data:company:label') }) ?? '',
              msg: t('common:MSG_C_012:description', { field: t('master_data:company:label') }) ?? ''
            }}
            emptyDataWithoutFilter={
              <EmptyDataWithIcon
                title={{
                  content: 'master_data:company:nodata:title'
                }}
                description={{
                  content: 'master_data:company:nodata:description'
                }}
                button={{
                  label: 'master_data:create_new',
                  props: {
                    onClick: gotoAddNew
                  }
                }}
              />
            }
          />
        </div>
      </div>
      <AddEditModal
        open={Boolean(recordEditing)}
        setOpen={(open) => {
          if (!open) setRecordEditing(undefined);
        }}
        wrapperRef={wrapperRef}
        detail={recordEditing}
        onSubmitted={(status) => {
          if (status) {
            setRecordEditing(undefined);
            setFilter({ ...filter });
          }
        }}
      />
    </div>
  );
};

export default CompanyMasterList;
