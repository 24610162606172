import AddEditStakeholder from '@pages/project-management/add-edit/scenario/type-c/form/Step6';

export const PARENT_PATH = 'steps[3]';
const StepFive = () => {
  return (
    <AddEditStakeholder
      paths={{
        parentPath: PARENT_PATH,
        stakeHolderPath: 'projectStakeholders',
        hasOtherStakeHolderPath: 'involvedMembers'
      }}
    />
  );
};

export default StepFive;
