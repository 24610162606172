import Image1 from '@/assets/images/tutorial/tutorial_1.svg';
import Image2 from '@/assets/images/tutorial/tutorial_2.svg';
import Image3 from '@/assets/images/tutorial/tutorial_3.svg';
import Image4 from '@/assets/images/tutorial/tutorial_4.svg';
import Image5 from '@/assets/images/tutorial/tutorial_5.svg';
import Image6 from '@/assets/images/tutorial/tutorial_6.svg';
import Image7 from '@/assets/images/tutorial/tutorial_7.svg';
import Image8 from '@/assets/images/tutorial/tutorial_8.svg';

import { ContentTutorial } from '../models';

export const NUMBER_START = 0;
export const NUMBER_NEXT = 1;

export const slidesTutorial: ContentTutorial[] = [
  {
    id: 1,
    img: <Image1 />,
    description: 'tutorial:note1'
  },
  {
    id: 2,
    img: <Image2 width={1051} height={510} />,
    description: 'tutorial:note2'
  },
  {
    id: 3,
    img: <Image3 width={1051} height={510} />,
    description: 'tutorial:note3'
  },
  {
    id: 4,
    img: <Image4 width={1051} height={510} />,
    description: 'tutorial:note4'
  },
  {
    id: 5,
    img: <Image5 width={1051} height={510} />,
    description: 'tutorial:note5'
  },
  {
    id: 6,
    img: <Image6 width={1051} height={510} />,
    description: 'tutorial:note6'
  },
  {
    id: 7,
    img: <Image7 />,
    description: 'tutorial:note7'
  },
  {
    id: 8,
    img: <Image8 width={1051} height={510} />,
    description: 'tutorial:note8'
  }
];
