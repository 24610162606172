import Inputmask from 'inputmask';
import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import { Control, useController } from 'react-hook-form';

import { useMessageYupTranslation } from '../../hooks/useI18n';
import { TextField, TextFieldProps } from '../text-field/TextField';

export interface IFormInputProps extends TextFieldProps {
  name: string;
  control?: Control;
  handleOnChange?: (value: string) => void;
  errorText?: string;
  isTrimValue?: boolean;
  debounceTime?: number;
  maskConfigs?: Inputmask.Options;
  maskCallback?: (ins?: Inputmask.Instance) => void;
  onBlur?: (value: any) => void;
}

const defaultMaskConfigs = {
  keepStatic: true,
  showMaskOnFocus: false,
  showMaskOnHover: false,
  definitions: {
    '9': {
      validator: '[0-9]',
      cardinality: 1,
      definitionSymbol: '*'
    }
  }
};

export const FormInput = forwardRef((props: IFormInputProps, ref: any) => {
  const {
    name,
    control,
    onBlur,
    onSubmit,
    errorText = '',
    handleOnChange,
    defaultValue = '',
    formatType,
    isTrimValue,
    debounceTime,
    maskConfigs,
    maskCallback,
    ...rest
  } = props;
  // state
  const {
    field,
    fieldState: { error }
  } = useController({
    name: name as string,
    defaultValue,
    control
  });
  const msgError = useMessageYupTranslation(error?.message);
  const textFieldRef = useRef<any>(ref);

  useImperativeHandle(ref, () => ({
    ...textFieldRef.current
  }));

  const inputVal = useMemo(() => {
    return field.value ?? defaultValue ?? '';
  }, [field.value]);
  // render
  return (
    <TextField
      ref={(el: any) => {
        if (el) {
          if (el?.input) {
            if (maskConfigs) {
              const im = new Inputmask({ ...defaultMaskConfigs, ...maskConfigs });
              im.mask(el.input);
              maskCallback?.(im);
            }
            field.ref(el);
          }
          textFieldRef.current = el;
        }
      }}
      defaultValue={defaultValue}
      isTrimValue={isTrimValue}
      error={msgError || errorText || undefined}
      value={inputVal}
      onChangeText={(value: string) => {
        field.onChange(value);
        handleOnChange && handleOnChange(value);
      }}
      className='FormInput'
      onBlur={onBlur}
      {...rest}
    />
  );
});
