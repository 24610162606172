import { Table } from 'antd';
import type { ColumnGroupType, ColumnType, TableProps } from 'antd/es/table';
import { ExpandableConfig, SortOrder, TableRowSelection } from 'antd/es/table/interface';
import classNames from 'classnames';
import React, { Key, useEffect, useMemo, useRef, useState } from 'react';

import EmptyDataWithIcon from '@/components/EmptyData/EmptyDataWithIcon';
import AppTooltip from '@/components/app-tooltip/AppTooltip';
import { BasePopupProps } from '@/components/base-popup/BasePopup';

import useResize from '@/hooks/useResize';

import { ANTD_TO_QUERY_SORT } from '@/utils/constants';
import { groupByKey } from '@/utils/helpers/globalHelper';

import TableHeader from './Header';
import Sorter from './Sorter';

import './index.scss';

export interface IDefaultSort<T> {
  field: keyof T;
  order: keyof typeof ANTD_TO_QUERY_SORT;
}

export interface IObject {
  [key: string]: any;
}

export interface IGroupData<T> {
  id: string;
  children: T[];
  order?: number;
}

export interface IFetchColumnType<T> extends ColumnType<T> {
  tooltip?: (value: any, record: T, index: number) => string;
}

export interface IFetchColumnGroupType<T> extends ColumnGroupType<T> {
  tooltip?: (value: any, record: T, index: number) => string;
}

export type FetchColumnsType<T = unknown> = (IFetchColumnType<T> | IFetchColumnGroupType<T>)[];

export interface ITableCustomProps<T> extends Omit<TableProps<any>, 'rowSelection'> {
  columns: FetchColumnsType<T>;
  recordDelete?: IObject;
  scrollTable?: IObject;
  showAddLine?: boolean;
  showDelete?: boolean;
  showDownload?: boolean;
  groupBy?: keyof T;
  groupSort?: (groupKey1: string, groupKey2: string) => number;
  groupRowRender?: (record: IGroupData<any>) => React.ReactNode | string;
  groupHeaderClassName?: string;
  groupLabelAlign?: 'left' | 'center' | 'right';
  handleDownloadClick?: (keys: React.Key[], items: T[]) => void;
  handleDeleteClick?: (keys: Key[], row?: T[]) => void;
  alwaysExpandGroup?: boolean;
  deleteConfirmPopup?: BasePopupProps;
  rowKey?: (record: any) => string;
  tableHeader?: React.ReactNode;
  defaultSort?: IDefaultSort<T>[];
  rowSelection?: TableRowSelection<T> | null;
  titleSelectedRowsCustom?: string;
}

const TableBase = <T extends IObject>({
  handleDeleteClick,
  columns,
  scrollTable,
  showAddLine,
  showDelete,
  showDownload,
  pagination = false,
  groupBy,
  tableHeader,
  groupSort,
  groupRowRender,
  groupHeaderClassName,
  groupLabelAlign = 'left',
  handleDownloadClick,
  deleteConfirmPopup,
  defaultSort,
  titleSelectedRowsCustom,
  rowKey = (record: T) => record.id,
  rowSelection: defaultRowSelection,
  dataSource,
  alwaysExpandGroup = false,
  locale,
  ...restProps
}: ITableCustomProps<T>) => {
  const [expandRowKeys, setExpandRowKeys] = useState<React.Key[] | undefined>(undefined);
  const isGrouped = groupBy || dataSource?.some((i) => i.children);
  const columnOptions = useMemo(() => {
    const newOptions: FetchColumnsType<T> = columns.map((op) => ({
      ellipsis: true,
      ...op,
      className: classNames(op.className, 'group', 'w-full'),
      sortIcon: (sorterProps: { sortOrder: SortOrder }) => <Sorter {...sorterProps} />
    }));
    if (newOptions.length && columns.length) {
      newOptions.forEach((option, indexOption) => {
        option.onCell = (data: T) => {
          if (indexOption === 0 && data.children) {
            const isCheckboxMode = defaultRowSelection !== null;
            const colNumbers = newOptions.reduce((sum, i) => sum + (i.colSpan || 1), 0);
            const totalHeader = isCheckboxMode ? colNumbers + 1 : colNumbers;
            return data.children
              ? {
                  colSpan: totalHeader,
                  className: classNames(
                    '!px-[8px]',
                    'bg-blue3',
                    data.className,
                    'group-row-header',
                    `!text-${groupLabelAlign}`,
                    groupHeaderClassName
                  ),
                  align: groupLabelAlign
                }
              : { colSpan: 1 };
          }
          return data.children ? { colSpan: 0 } : columns[indexOption]?.onCell ? columns[indexOption]?.onCell(data) : {};
        };
        option.render = (value, record, index) => {
          const content = columns[indexOption]?.render
            ? columns[indexOption].render?.(value, record, index)
            : record[newOptions[indexOption]?.key?.toString() ?? ''];
          if (option.tooltip) {
            return (
              <AppTooltip title={option.tooltip(value, record, index)} className='truncate inline-block align-top max-w-full w-full'>
                {content}
              </AppTooltip>
            );
          }
          return <div className='truncate max-w-full inline-block align-top w-full h-full'>{content}</div>;
        };
      });
      newOptions[0].render = (value, record, index) => {
        if (record?.children) {
          const content = groupRowRender
            ? groupRowRender({
                ...record,
                id: record?.id,
                children: record?.children ?? []
              })
            : record.id;
          return (
            <AppTooltip title={content} className={`truncate inline-block align-top max-w-[calc(100%-30px)] body-700`}>
              {content}
            </AppTooltip>
          );
        } else {
          return columns[0]?.render ? columns[0].render(value, record, index) : record[newOptions[0]?.key?.toString() ?? ''];
        }
      };
    }

    return newOptions;
  }, [columns]);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const headerSectionRef = useRef<HTMLDivElement | null>(null);

  let rowSelection: TableRowSelection<any> = {
    onChange: (_newSelectedRowKeys: React.Key[], selectedRow) => {
      const selected = selectedRow.filter((i) => !groupBy || !i?.children?.length).map((i) => rowKey(i));
      setSelectedRowKeys(selected);
    },
    getCheckboxProps: (record: T & { isGroupHeader?: boolean }) => ({
      disabled: record.children // Disable checkbox for header rows
    }),
    onCell: (data: T) => {
      const defaultOnCell = defaultRowSelection?.onCell?.(data);
      const checkboxColSpan = data.children ? { colSpan: 0 } : { colSpan: 1 };
      return {
        ...checkboxColSpan,
        ...defaultOnCell
      };
    },
    selectedRowKeys,
    ...(defaultRowSelection ?? {})
  };

  const expandableConfig: ExpandableConfig<T> | undefined = isGrouped
    ? {
        expandRowByClick: !alwaysExpandGroup,
        defaultExpandAllRows: true,
        expandedRowKeys: expandRowKeys ? expandRowKeys : (dataSource ?? []).map((i) => i[groupBy ? groupBy : 'id']),
        onExpandedRowsChange(expandedKeys) {
          setExpandRowKeys([...expandedKeys]);
        },
        indentSize: 0
      }
    : undefined;
  if (alwaysExpandGroup && expandableConfig) {
    expandableConfig.expandIcon = (props) => undefined;
  }

  const expandedKeys = dataSource?.map((item) => rowKey(item)).join('-') || '';
  useEffect(() => {
    setExpandRowKeys(undefined);
  }, [expandedKeys]);

  useEffect(() => {
    setSelectedRowKeys([]);
  }, [dataSource]);

  const calculateView = (): T[] | IGroupData<T>[] => {
    const list = (dataSource ?? []) as T[];
    if (groupBy) {
      const groupDict = groupByKey(list, groupBy);
      const keys = groupSort ? Object.keys(groupDict).sort(groupSort) : Object.keys(groupDict);

      const newList = keys.reduce((groups: IGroupData<T>[], k: string, index: number) => {
        groups.push({
          id: k,
          children: [...groupDict[k]]
        });
        return groups;
      }, []);
      return newList;
    }
    return list;
  };

  const { height } = useResize({ elRef: headerSectionRef });
  const calculateScrollTable = () => {
    if (!scrollTable) return;
    const { maxHeight } = scrollTable ?? {};
    if (!maxHeight) return scrollTable;
    const defaultHeight = 78;
    return {
      y: `calc(${maxHeight} - ${height ?? defaultHeight}px)`,
      ...scrollTable
    };
  };

  const customLocale = useMemo(() => {
    return {
      emptyText: (
        <EmptyDataWithIcon
          title={null}
          description={{
            content: 'common:no_data'
          }}
          button={null}
        />
      ),
      ...locale
    };
  }, [locale]);

  return (
    <div className={`base-table table-custom antd-table-scrollbar-customize ${showAddLine ? 'add-line-table' : ''}`}>
      <div ref={headerSectionRef}>
        <TableHeader
          isShowDelete={!!(showDelete && selectedRowKeys.length)}
          isShowDownload={!!showDownload}
          selectedRowKeys={selectedRowKeys}
          titleSelectedRowsCustom={titleSelectedRowsCustom}
          selectedRow={
            calculateView()
              .reduce((arr: T[], current: T | IGroupData<T>) => {
                if ('children' in current) {
                  return [...arr, current as T, ...(current.children as T[])];
                } else {
                  return [...arr, current as T];
                }
              }, [] as T[])
              .filter((i) => selectedRowKeys.includes(i.id)) as T[]
          }
          handleDownloadClick={(keys, items) => handleDownloadClick && handleDownloadClick(keys, items ?? [])}
          handleDeleteClick={handleDeleteClick}
        >
          {tableHeader}
        </TableHeader>
      </div>
      <div>
        <Table
          key={calculateView().length}
          rowClassName={(record: T) => {
            return [!('children' in record) ? `bg-white` : `table-toggle-header`].join(' ');
          }}
          columns={columnOptions}
          showSorterTooltip={false}
          rowKey={(record) => record.id}
          locale={customLocale}
          dataSource={calculateView()}
          scroll={calculateScrollTable()}
          rowSelection={defaultRowSelection !== null ? rowSelection : undefined}
          expandable={expandableConfig}
          pagination={pagination}
          {...restProps}
        />
      </div>
    </div>
  );
};

export default TableBase;
