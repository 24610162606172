import Notification from './components/alert/Notification';
import { BasePopup } from './components/base-popup/BasePopup';
import { AppWrapper } from './layouts/AppWrapper';
import ModalTutorial from './pages/tutorial';
import AppRoutes from './routes';

import './App.scss';

function App() {
  return (
    <div className='App'>
      <AppWrapper>
        <AppRoutes />
        <Notification />
        <BasePopup />
        <ModalTutorial />
      </AppWrapper>
    </div>
  );
}

export default App;
