import Counter from './Counter';
import FileAttachment from './FileAttachment';
import FileBlot from './FileBlot';
import LinkBlot from './LinkBlot';
import MagicUrl from './MagicUrl';
import Mention from './Mention';
import MentionBlot from './MentionBlot';

export const CUSTOM_MODULES = {
  LINK_BLOT: 'LinkBlot',
  MENTION_TAG: 'MentionBlot',
  FILE_BLOT: 'FileBlot',
  EMBED_IMAGE_BLOT: 'EmbedImageBlot',
  MENTION: 'mention',
  MAGIC_URL: 'magicUrl',
  COUNTER: 'counter',
  FILE_ATTACHMENT: 'fileAttachment',
  HISTORY: 'history'
};
export type CustomModules = (typeof CUSTOM_MODULES)[keyof typeof CUSTOM_MODULES];
export { Mention, MagicUrl, Counter, LinkBlot, MentionBlot, FileAttachment, FileBlot };
