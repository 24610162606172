import { Blocker, BlockerFunction, useBlocker } from 'react-router-dom';

interface IBlockerFunctionArgs {
  currentLocation: BlockerFunction['arguments'][0];
  nextLocation: BlockerFunction['arguments'][1];
  historyAction: BlockerFunction['arguments'][2];
}
export interface IPendingNavigate {
  condition: boolean | BlockerFunction;
}
export enum BLOCKER_STATE {
  UN_BLOCKED = 'unblocked',
  BLOCKED = 'blocked',
  PROCEEDING = 'proceeding'
}
export const usePendingNavigate = ({ condition }: IPendingNavigate): { blocker: Blocker } => {
  const blocker = useBlocker(({ currentLocation, nextLocation, historyAction }: IBlockerFunctionArgs) => {
    const value = typeof condition === 'boolean' ? condition : condition({ currentLocation, nextLocation, historyAction });
    return value && currentLocation.pathname !== nextLocation.pathname;
  });
  return { blocker };
};
