import lodash, { get, keys } from 'lodash';
import { FieldErrors } from 'react-hook-form';
import { v4 as uuid } from 'uuid';

import { FormObserver } from '@pages/project-management/add-edit/models/projectExternalProxy';

import { APPLICANT_GENDER } from '@/utils/constants';
import { LOCALSTORAGE, UPLOAD_STATUS } from '@/utils/constants/AppConstants';
import { DataInput, DataViewer, isJsonString } from '@/utils/helpers/common';
import { IUserProfile } from '@/utils/interfaces/user';

import { SCENARIO_TYPES } from '../../contants';
import { DEFAULT_FORM_OBSERVER } from '../../contants/projectExternalProxy';
import { IProjectExternal, IProjectStakeholder } from '../../models';
import { IStepFive, IStepFour, IStepThree, IStepTwo } from '../../models/scenarioA';
import { relativePath } from '../utils';
import {
  DEFAULT_SCENARIO_A_STEP_2,
  DEFAULT_SCENARIO_A_STEP_3,
  DEFAULT_SCENARIO_A_STEP_4,
  DEFAULT_SCENARIO_A_STEP_5,
  DEFAULT_SCENARIO_A_STEP_6,
  DEFAULT_SCENARIO_A_STEP_STATUS,
  STEP_JUMP
} from './constants';
import { HAS_OTHER_STAKE_HOLDER, STAKEHOLDER_FIELDS } from './form/step-four/constants';
import { LOCAL_REPRESENTATIVE, LOCAL_REPRESENTATIVE_FIELDS } from './form/step-three/constants';
import { REQUIRED_APPLICANT_FIELDS, REQUIRED_ASSIGNMENT_FIELDS } from './form/step-two/constants';

export const getUserProfile = () => {
  const user = localStorage.getItem(LOCALSTORAGE.USER) ?? '';
  return isJsonString(user) ? JSON.parse(user) : null;
};

export const convertResponseForAType = (data: IProjectExternal, user?: IUserProfile | null) => {
  const formDefault = { ...DEFAULT_FORM_OBSERVER, scenarioType: SCENARIO_TYPES.SCENARIO_A };
  formDefault.steps = [
    { ...DEFAULT_SCENARIO_A_STEP_2 },
    { ...DEFAULT_SCENARIO_A_STEP_3 },
    { ...DEFAULT_SCENARIO_A_STEP_4, companyId: user?.organizationId },
    { ...DEFAULT_SCENARIO_A_STEP_5 },
    { ...DEFAULT_SCENARIO_A_STEP_6 }
  ];
  const cloneDefaultStepStatus = DEFAULT_SCENARIO_A_STEP_STATUS.map((item) => ({ ...item }));
  formDefault.stepStatus = [...DEFAULT_FORM_OBSERVER.stepStatus, ...cloneDefaultStepStatus];
  if (data) {
    formDefault.selectedStep = data.step;

    // default value for step status
    for (const item of formDefault.stepStatus) {
      const matched = data.stepStatus?.find((status) => status.id === item.id);
      if (!matched) continue;
      item.value = matched.value;
    }

    // default value for step 2
    const projectApplicant = {
      ...data.projectApplicant,
      dateOfBirth: TimeConverter.toDatePicker(data.projectApplicant.dateOfBirth),
      gender: GenderConverter.toOptionValue(data.projectApplicant?.gender),
      departureDate: TimeConverter.toDatePicker(data.projectApplicant.departureDate)
    };
    formDefault.steps[0] = {
      ...DEFAULT_SCENARIO_A_STEP_2,
      projectApplicant,
      projectAssignmentInfo: data.projectAssignmentInfo ?? {},
      visaCategoryId: data.visaCategoryId ?? null,
      travelTypeId: data.travelTypeId ?? null
    };

    // default value for step 3
    const needSupporter = NeedSupportRadioConverter.toOptionValue(data?.localSupportOption);
    const projectLocalSupporters = data.projectLocalSupporters?.map((item) => {
      const newItem: any = { ...item };
      newItem.formId = uuid();
      return newItem;
    });
    formDefault.steps[1] = {
      ...DEFAULT_SCENARIO_A_STEP_3,
      projectLocalSupporters: projectLocalSupporters ?? [],
      needSupporter
    };

    // default value for step 4
    let hasOtherStakeHolder = null;
    if (data?.hasOtherStakeHolder === true) hasOtherStakeHolder = HAS_OTHER_STAKE_HOLDER.YES;
    else if (data?.hasOtherStakeHolder === false) hasOtherStakeHolder = HAS_OTHER_STAKE_HOLDER.NO;
    const projectStakeholders = [];
    const userProfile = getUserProfile();
    // ignore current user from stakeholders list for step 4
    for (const item of data?.projectStakeholders) {
      if (item.stakeholderId === userProfile?.id) continue;
      projectStakeholders.push(item);
    }
    formDefault.steps[2] = {
      ...DEFAULT_SCENARIO_A_STEP_4,
      projectStakeholders,
      hasOtherStakeHolder
    };

    // default value for step 5
    const projectAttachments = data.attachments?.map((item) => {
      const newItem: any = { ...item };
      newItem.formId = uuid();
      newItem.status = UPLOAD_STATUS.DONE;
      return newItem;
    });
    formDefault.steps[3] = {
      ...DEFAULT_SCENARIO_A_STEP_5,
      projectAttachments: projectAttachments ?? [],
      note: data.note ?? ''
    };
  }

  return formDefault;
};

export const getGenderBy = (gender: any) => {
  if (typeof gender === 'boolean') return gender;
  if (gender === APPLICANT_GENDER.FEMALE) return true;
  if (gender === APPLICANT_GENDER.MALE) return false;
  return null;
};

export class ScenarioBodyConverter {
  private _data: any;
  private _formValues: FormObserver<'scenario-a'> | null = null;

  get data() {
    return this._data;
  }
  set data(newValue: any) {
    this._data = { ...this._data, ...newValue };
  }

  constructor(form: FormObserver<'scenario-a'>) {
    this._formValues = form;
    this._data = {};
  }

  basedOn(type: 'isDraft' | 'isPublished', projectDetails: IProjectExternal | null) {
    const { version, id } = projectDetails ?? {};
    if (type === 'isDraft') {
      this.data.isDraft = true;
      this.data.step = this._formValues?.selectedStep;
    }
    if (typeof version === 'number') {
      this.data.version = version;
    }
    if (id) this.data.id = id;
    this.data.stepStatus = this._formValues?.stepStatus?.map((item) => ({ id: item.id, value: item.value })) ?? [];
    return this;
  }

  attachFromStepTwoInfo() {
    const stepTwo = (this._formValues?.steps?.[0] as IStepTwo) ?? {};
    const { projectApplicant, projectAssignmentInfo, visaCategoryId, travelTypeId } = stepTwo;
    if (projectApplicant)
      this.data.projectApplicant = {
        ...projectApplicant,
        dateOfBirth: DataInput.formatTime(projectApplicant?.dateOfBirth),
        gender: GenderConverter.toPayload(projectApplicant?.gender),
        departureDate: DataInput.formatTime(projectApplicant?.departureDate)
      };
    if (projectAssignmentInfo) this.data.projectAssignmentInfo = projectAssignmentInfo;
    if (visaCategoryId) this.data.visaCategoryId = visaCategoryId;
    if (travelTypeId) this.data.travelTypeId = travelTypeId;
    return this;
  }

  attachFromStepThreeInfo(projectDetails: IProjectExternal | null) {
    const stepThree = (this._formValues?.steps?.[1] as IStepThree) ?? {};
    const { needSupporter, projectLocalSupporters } = stepThree;
    this.data.localSupportOption = NeedSupportRadioConverter.toPayload(needSupporter);
    this.data.projectLocalSupporters = projectLocalSupporters ?? [];

    let newPrjLocalSupporters: IProjectExternal['projectLocalSupporters'] = projectLocalSupporters ?? [];
    if (projectDetails) {
      projectDetails.projectLocalSupporters?.forEach((item) => {
        const isDeleted = item.id && !projectLocalSupporters?.find((f) => f.id === item.id);
        if (!isDeleted) return;
        newPrjLocalSupporters.push({ ...item, deleted: true });
      });
    }

    let language = newPrjLocalSupporters[0]?.language ?? undefined;
    for (const index in newPrjLocalSupporters) {
      const item = newPrjLocalSupporters[index];
      item.language = language;
      item.displayOrder = Number(index);
    }
    this.data.projectLocalSupporters = newPrjLocalSupporters;
    return this;
  }

  attachFromStepFourInfo(projectDetails: IProjectExternal | null) {
    const stepFour = (this._formValues?.steps?.[2] as IStepFour) ?? {};
    const { hasOtherStakeHolder, projectStakeholders } = stepFour;
    if (hasOtherStakeHolder === HAS_OTHER_STAKE_HOLDER.YES) this.data.hasOtherStakeHolder = true;
    else if (hasOtherStakeHolder === HAS_OTHER_STAKE_HOLDER.NO) this.data.hasOtherStakeHolder = false;

    const uniqueStakeholders = lodash.uniqWith(
      projectStakeholders?.filter((item: IProjectStakeholder) => item.stakeholderEmail),
      (accountA: IProjectStakeholder, accountB: IProjectStakeholder) =>
        accountA.stakeholderEmail === accountB.stakeholderEmail && !accountA.rejected && !accountB.rejected
    );
    const newPrjStakeholders: IProjectExternal['projectStakeholders'] = uniqueStakeholders.map((item: any, index: number) => ({
      ...item,
      displayOrder: index + 1,
      deleted: item?.deleted ?? false
    }));

    const userProfile = getUserProfile();
    projectDetails?.projectStakeholders?.forEach((item) => {
      if (item.stakeholderId === userProfile?.id) return;
      const isDeleted = item.stakeholderEmail && item.id && !newPrjStakeholders.find((f) => f.id === item.id);
      if (!isDeleted) return;
      newPrjStakeholders.push({ ...item, deleted: true });
    });

    this.data.projectStakeholders = newPrjStakeholders ?? [];

    return this;
  }

  attachFromStepFiveInfo(projectDetails: IProjectExternal | null) {
    const stepFive = (this._formValues?.steps?.[3] as IStepFive) ?? {};
    const { projectAttachments, note } = stepFive;
    const newPrjAttachments = [];
    for (const index in projectAttachments) {
      const file = projectAttachments[index];
      if (file.status !== 'done') continue;
      file.displayOrder = Number(index);
      file.deleted = false;
      newPrjAttachments.push(file);
    }

    const defaultAttachments = projectDetails?.attachments ?? projectDetails?.projectAttachments;
    defaultAttachments?.forEach((item) => {
      const isDeleted = !projectAttachments.find((i: any) => i.id === item.id) && item.id;
      if (!isDeleted) return;
      newPrjAttachments.push({ ...item, deleted: true });
    });

    this.data.projectAttachments = newPrjAttachments;
    this.data.note = DataViewer.isEmptyHTML(note) ? '' : note;
    return this;
  }

  sanitize() {
    const loop = (data: { [key in string | number]: any }) => {
      for (const key in data) {
        if (data[key] === null) {
          delete data[key];
        } else if (data[key] instanceof Array) {
          data[key].forEach(loop);
        } else if (typeof data[key] === 'object') {
          loop(data[key]);
        }
      }
    };
    loop(this.data);
    return this;
  }

  embedToJSON() {
    return {
      command: JSON.stringify(this.data)
    };
  }
}

export class DirectorConverter {
  private _builder: ScenarioBodyConverter | null = null;
  constructor(builder: ScenarioBodyConverter) {
    this._builder = builder;
  }

  processDraft(projectDetails: IProjectExternal | null) {
    if (!this._builder) return this;
    this._builder
      .basedOn('isDraft', projectDetails)
      .attachFromStepTwoInfo()
      .attachFromStepThreeInfo(projectDetails)
      .attachFromStepFourInfo(projectDetails)
      .attachFromStepFiveInfo(projectDetails);
    return this;
  }

  processPublished(projectDetails: IProjectExternal | null) {
    if (!this._builder) return this;
    this._builder
      .basedOn('isPublished', projectDetails)
      .attachFromStepTwoInfo()
      .attachFromStepThreeInfo(projectDetails)
      .attachFromStepFourInfo(projectDetails)
      .attachFromStepFiveInfo(projectDetails);
    return this;
  }

  getJSON() {
    return this._builder ? this._builder.sanitize().embedToJSON() : null;
  }
}

export class FormFieldObserver {
  private _formValues: FormObserver<'scenario-a'> | null = null;
  constructor(formValues: FormObserver<'scenario-a'>) {
    this._formValues = formValues;
  }

  isValidAt(stepId: number, formErrors: FieldErrors<FormObserver<'scenario-a'>>): boolean {
    const stepIndex = stepId - STEP_JUMP;
    let errCurrentStep;
    if (stepIndex >= 0) {
      errCurrentStep = get(formErrors, `steps[${stepIndex}]`);
    }
    return Boolean(errCurrentStep);
  }

  requiredFilledAllAt(stepId: number): boolean {
    switch (stepId) {
      case 2:
        return this.requiredFilledAtStepTwo();
      case 3:
        return this.requiredFilledAtStepThree();
      case 4:
        return this.requiredFilledAtStepFour();
      case 5:
        return true;
      case 6:
        return true;
      default:
        return false;
    }
  }

  requiredFilledAtStepTwo(): boolean {
    const stepTwo = this._formValues?.steps?.[0] as IStepTwo;
    if (!stepTwo) return false;
    const requiredFields = [
      ...REQUIRED_APPLICANT_FIELDS.map((field) => relativePath(field, 'projectApplicant')),
      ...REQUIRED_ASSIGNMENT_FIELDS.map((field) => relativePath(field, 'projectAssignmentInfo'))
    ];
    for (const fieldPath of requiredFields) {
      const fieldValue = get(stepTwo, fieldPath);
      if (!fieldValue) return false;
    }
    return true;
  }

  private _detectFilledAllRequiredForArray<T>(array: T[], requiredFields: string[]): boolean {
    if (!array) return false;
    for (const member of array) {
      const missingField = keys(member).find((key) => {
        const isFieldRequired = requiredFields.includes(key);
        const fieldValue = member?.[key as keyof T];
        return isFieldRequired && !fieldValue;
      });
      if (!missingField) continue;
      return false;
    }
    return true;
  }

  requiredFilledAtStepThree(): boolean {
    const stepThree = (this._formValues?.steps?.[2] as IStepThree) ?? {};
    if (stepThree.needSupporter === LOCAL_REPRESENTATIVE.NOT_NEEDED) return true;
    if (!stepThree.projectLocalSupporters || !stepThree.needSupporter) return false;
    return this._detectFilledAllRequiredForArray(stepThree.projectLocalSupporters, LOCAL_REPRESENTATIVE_FIELDS);
  }

  requiredFilledAtStepFour(): boolean {
    const stepFour = (this._formValues?.steps?.[3] as IStepFour) ?? {};
    if (stepFour.hasOtherStakeHolder === HAS_OTHER_STAKE_HOLDER.NO) return true;
    if (!stepFour.projectStakeholders || stepFour.hasOtherStakeHolder === null) return false;
    return this._detectFilledAllRequiredForArray(stepFour.projectStakeholders, STAKEHOLDER_FIELDS);
  }
}

export class TimeConverter {
  /**
   * @description Convert date string from response to value of date picker format
   * @param date - date string
   * @returns Date - dayjs format FORMAT_DATE_EN
   */
  static toDatePicker(date?: string | null) {
    if (!date) return null;
    return DataInput.formatTime(date);
  }
}

export class GenderConverter {
  static toPayload(gender: any) {
    if (typeof gender === 'boolean') return gender;
    if (gender === APPLICANT_GENDER.FEMALE) return true;
    if (gender === APPLICANT_GENDER.MALE) return false;
    return null;
  }
  static toOptionValue(value?: boolean) {
    if (value === true) return APPLICANT_GENDER.FEMALE;
    if (value === false) return APPLICANT_GENDER.MALE;
    return null;
  }
}

export class NeedSupportRadioConverter {
  static toPayload(value?: LOCAL_REPRESENTATIVE | null) {
    if (value === LOCAL_REPRESENTATIVE.NEEDED) return 1;
    if (value === LOCAL_REPRESENTATIVE.NOT_NEEDED) return 2;
    if (value === LOCAL_REPRESENTATIVE.UNKNOWN) return 3;
    return undefined;
  }

  static toOptionValue(value?: number) {
    if (value === 1) return LOCAL_REPRESENTATIVE.NEEDED;
    if (value === 2) return LOCAL_REPRESENTATIVE.NOT_NEEDED;
    if (value === 3) return LOCAL_REPRESENTATIVE.UNKNOWN;
    return null;
  }
}
