import { Col, Row } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import AppLabelSpace from '@/components/app-label/AppLabelSpace';

import { DataViewer } from '@/utils/helpers/common';

interface ColumnItem {
  items: Item[];
  colSpan?: number;
}

interface Item {
  title: string | React.ReactNode;
  label: string;
}

interface CommonViewInfoProps {
  id: string;
  columns: ColumnItem[];
  commonInfoProps?: any;
}
const CommonViewInfo: FC<CommonViewInfoProps> = (props) => {
  const {
    id = 'common-view-info',
    columns,
    commonInfoProps = {
      labelClass: 'body-400',
      titleClass: 'body-700'
    }
  } = props;
  const { t } = useTranslation();

  return (
    <Row gutter={16}>
      {columns.map((col) => (
        <Col span={col?.colSpan ?? 12} key={`${id}-col-view-info-${uuidv4()}`}>
          {col.items.map((i) => (
            <div className='mt-2' key={`col-item-view-info-${uuidv4()}`}>
              <AppLabelSpace {...commonInfoProps} title={DataViewer.display(i.title)} label={t(i.label)} />
            </div>
          ))}
        </Col>
      ))}
    </Row>
  );
};

export default CommonViewInfo;
