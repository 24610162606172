// import InputMask from 'inputmask';
import { TextAreaProps } from 'antd/es/input';
import TextArea from 'antd/es/input/TextArea';
import classNames from 'classnames';
import React from 'react';

import { REQUIRED_DOT } from '@/utils/constants/AppConstants';
import { ColorDefault } from '@/utils/themes/color';

import './index.scss';

export interface IBaseTextAreaProps extends TextAreaProps {
  // Add any additional props you need for your component
  hideBorder?: boolean;
  labelInput?: string;
  labelClassName?: string;
  suffix?: React.ReactNode;
}

const BaseTextArea: React.FC<IBaseTextAreaProps> = ({
  required,
  autoSize = { minRows: 3, maxRows: 6 },
  hideBorder,
  className,
  labelInput,
  labelClassName,
  onChange,
  suffix,
  ...restProps
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange && onChange(e);
  };

  return (
    <div className='body-400 flex w-full'>
      {labelInput && (
        <>
          <span className={labelClassName ?? ''}>{labelInput}</span>
          {required && (
            <span className='require' color={ColorDefault.negative}>
              {REQUIRED_DOT}
            </span>
          )}
        </>
      )}
      <TextArea
        onChange={handleChange}
        className={classNames('grow', hideBorder ? 'non-border' : '', className ?? '')}
        autoSize={autoSize}
        {...restProps}
      />
      <div className={classNames('flex', 'items-center', 'ml-2')}>{suffix}</div>
    </div>
  );
};

export default BaseTextArea;
