import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import IconAddNew from '@/assets/icons/project/icon-add-new.svg';
import DefaultAddEmptySvg from '@/assets/icons/table/base-table-empty-with-smile-face.svg';
import DefaultEmptySvg from '@/assets/icons/table/base-table-empty.svg';

import { BaseButton } from '../base-button/BaseButton';

export interface IEmptyDataWithIconProps {
  title: {
    content?: string;
    props?: { [key in string]: any };
  } | null;
  description: {
    content?: string;
    props?: { [key in string]: any };
  } | null;
  button: {
    label?: string;
    props?: { [key in string]: any };
  } | null;
  icon?: React.ReactNode;
}

const DEFAULT_TITLE: IEmptyDataWithIconProps['title'] = {
  content: 'project:list:create_new_project'
};

const DEFAULT_DESCRIPTION: IEmptyDataWithIconProps['description'] = {
  content: 'project:list:empty_project_table_description'
};

const DEFAULT_BUTTON: IEmptyDataWithIconProps['button'] = {
  label: 'project:list:add_new_btn_label'
};

const EmptyDataWithIcon = (props: IEmptyDataWithIconProps): React.ReactNode => {
  const { t } = useTranslation();

  const {
    title = DEFAULT_TITLE,
    description = DEFAULT_DESCRIPTION,
    button = DEFAULT_BUTTON,
    icon = props.button ? <DefaultAddEmptySvg width={60} height={60} /> : <DefaultEmptySvg width={60} height={60} />
  } = props;

  const { className: titleClassName, ...titleProps } = title?.props ?? {};
  const { className: descriptionClassName, ...descriptionProps } = description?.props ?? {};
  const { className: buttonClassName, ...buttonProps } = button?.props ?? {};
  return (
    <div className='empty-data-icon flex flex-col items-center'>
      <span className='mt-[40px]'>{icon}</span>
      {title !== null && (
        <p className={classNames('text-16 font-semibold text-lnk', titleClassName)} {...titleProps}>
          {t(title?.content ?? '')}
        </p>
      )}
      {description !== null && (
        <p className={classNames('text-base font-[400] text-textGray', titleClassName)} {...descriptionProps}>
          {t(description?.content ?? '')}
        </p>
      )}
      {button !== null && (
        <BaseButton size='medium' icon={<IconAddNew style={{ verticalAlign: 'sub' }} />} {...buttonProps}>
          {t(button?.label ?? '')}
        </BaseButton>
      )}
    </div>
  );
};

export default EmptyDataWithIcon;
