import lodash, { keys } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useFormContext } from 'react-hook-form';

import FormBaseFilter from '@/components/base-filter';

import useTargetAudience from '@/hooks/useTargetAudience';

import { DataViewer } from '@/utils/helpers/common';
import { formatDateTime } from '@/utils/helpers/globalHelper';

import Filter from './components/Filter';
import RenderTag from './RenderTag';
import { IFormFilter } from './models';

export interface ITableHeaderProps { onSubmit: SubmitHandler<IFormFilter> }

const TableHeader = ({ onSubmit }: ITableHeaderProps) => {
  const { t } = useTranslation();
  const formFilter = useFormContext<IFormFilter>();
  const { targetAudienceOptions } = useTargetAudience();

  const watchPrimaryFields = formFilter.watch('primary');
  const totalFiltered = useMemo(() => {
    return lodash.filter(
      lodash.values(watchPrimaryFields),
      (value) => !lodash.isNil(value) && value !== '' && (!lodash.isArray(value) || value.length > 0)
    ).length;
  }, [watchPrimaryFields]);
  const primaryKeys = keys(watchPrimaryFields);

  return (
    <div className='mb-4'>
      <FormBaseFilter
        onSubmit={onSubmit as any}
        searchBox={{ placeholder: DataViewer.display(t('common:ANNOUNCEMENT_FIELDS_SEARCH_PLACEHOLDER')) }}
        tagSection={
          <RenderTag
            onSubmit={onSubmit}
            form={formFilter}
            options={targetAudienceOptions}
            fields={watchPrimaryFields}
            total={totalFiltered}
            keys={primaryKeys}
          />
        }
        primaryAction={{
          label: 'button:filter',
          name: 'primary',
          popoverContent: (
            <Filter
              defaultValues={formFilter.watch('primary')}
              onSubmit={(values) => {
                const previous = formFilter.getValues();
                formFilter.reset({
                  ...previous,
                  primary: {
                    ...values,
                    publishedDateFrom: values?.publishedDateFrom ? formatDateTime(values?.publishedDateFrom) : null,
                    publishedDateTo: values?.publishedDateTo ? formatDateTime(values?.publishedDateTo) : null
                  }
                });
                formFilter.handleSubmit(onSubmit)();
              }}
              onReset={() => { }}
            />
          ),
          totalFiltered: totalFiltered || 0
        }}
        secondaryAction={null}
      />
    </div>
  );
};

export default TableHeader;
