import classNames from 'classnames';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BaseButton, Type } from '@/components/base-button/BaseButton';
import { ConfirmCancelEditing } from '@/components/confirm-popup';

import { Ii18n } from '@/utils/interfaces/i18n';

import { IAttachment } from '../add-edit/Edit';
import { ANNOUNCEMENT_STATUS, ATTACHMENT_STATUS, AnnouncementStatus } from '../view/models';
import { WrapHeaderAction } from './WrapLayout';

export const getSubTitle = (t: Ii18n['t'], announcementStatus?: AnnouncementStatus) => {
  const subtitleMap: Record<AnnouncementStatus, JSX.Element> = {
    [ANNOUNCEMENT_STATUS.PUBLISH]: <></>,
    [ANNOUNCEMENT_STATUS.SAVE]: <span className='unpublished text-24'>({t('announcement:unpublished')})</span>,
    [ANNOUNCEMENT_STATUS.WAITING]: <span className='pending text-24'>({t('announcement:pending')})</span>
  };
  return announcementStatus ? subtitleMap[announcementStatus] : null;
};

export const generateActionButton = ({
  showCondition,
  onClickHandler,
  loading,
  icon,
  buttonText,
  type = 'secondary',
  className,
  disabled
}: {
  showCondition: boolean;
  onClickHandler: () => Promise<void> | void;
  loading?: boolean;
  icon: React.ReactElement;
  buttonText: string;
  type?: Type;
  className?: string;
  disabled?: boolean;
}) => {
  if (!showCondition) return null;
  return (
    <BaseButton
      block
      size='medium'
      type={type}
      className={classNames('action-btn', className)}
      onClick={onClickHandler}
      disabled={loading || disabled}
      loading={loading}
      icon={icon}
    >
      {buttonText}
    </BaseButton>
  );
};

type ButtonGroupProps = {
  name: string;
  loading: boolean;
  onSave: () => Promise<void> | void;
  onPublish: () => Promise<void> | void;
  onCancel: () => void;
  onError?: (error: any) => void;
};

export const ButtonGroupAction = ({ name, loading, onSave, onPublish, onCancel, onError }: ButtonGroupProps) => {
  const { t } = useTranslation();
  const { handleSubmit, control } = useFormContext();
  const { isDirty, isSubmitting } = useFormState({
    control
  });

  const attachmentFile = useWatch({ name });

  const isDisableBtn = isSubmitting || loading;

  const disablePublishBtn = () => {
    if (!attachmentFile?.length) return false;

    return !!attachmentFile.some((item: IAttachment) => item.status === ATTACHMENT_STATUS.REJECT || item.status === ATTACHMENT_STATUS.WAITING);
  };

  const handleError = (error: any) => {
    const firstError = Object.keys(error).reduce((field: any, a) => {
      return !!error[field] ? field : a;
    }, null);

    if (firstError === 'content' || firstError === 'attachments') {
      const errorElement = document.getElementsByClassName(`ql-blank`)[0];
      (errorElement as HTMLElement)?.focus();
      errorElement?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
    }

    onError?.(error);
  };

  return (
    <WrapHeaderAction>
      <BaseButton disabled={isDisableBtn} block size='medium' type='tertiary' onClick={onCancel}>
        {t('button:cancel')}
      </BaseButton>
      <BaseButton disabled={isDisableBtn} type='secondary' block size='medium' onClick={handleSubmit(onSave, handleError)}>
        <span> {t('button:keep')} </span>
      </BaseButton>
      <BaseButton disabled={isDisableBtn || disablePublishBtn()} block size='medium' onClick={handleSubmit(onPublish, handleError)}>
        <span> {t('button:send')} </span>
      </BaseButton>
      <ConfirmCancelEditing condition={isDirty} />
    </WrapHeaderAction>
  );
};

ButtonGroupAction.defaultProps = {
  onError: () => {}
};
