import React, { forwardRef, isValidElement, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import FormBoxEditor, { IBasicFormEditorProps } from './FormEditor';

export interface IEditorWithCounterProps extends IBasicFormEditorProps {
  label?: string | React.ReactNode;
  required?: boolean;
  limit?: number;
  onCharacterChange?: (num: number) => void;
  styleCounterLabel?: string;
}

const EditorWithCounter = (props: IEditorWithCounterProps, ref: any) => {
  const { label, required, onCharacterChange, limit = 3000, className = '', styleCounterLabel } = props;
  const { t } = useTranslation();
  const counterRef = useRef<any>();

  const generateLabel = () => {
    if (!label) return <div></div>;
    if (isValidElement(label)) return label;
    return (
      <div className={`mb-2 flex gap-1 items-center ${className}`}>
        <p className='subtitle-14'>{t(label as string)}</p>
        {required && <div className='text-negative text-14 font-medium'>(*)</div>}
      </div>
    );
  };
  return (
    <>
      <div className='flex justify-between items-center'>
        {generateLabel()}
        <div ref={counterRef} className={styleCounterLabel ?? ''}></div>
      </div>
      <FormBoxEditor ref={ref} {...props} modules={{ counter: { container: counterRef, callback: onCharacterChange, limit }, ...props?.modules }} />
    </>
  );
};

export default forwardRef(EditorWithCounter);
