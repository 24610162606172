import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import translationEN from './languages/en-us.json';
import translationJP from './languages/ja-jp.json';

const resources = {
  en: translationEN,
  jp: translationJP
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'jp',
    fallbackLng: 'jp',
    debug: false,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
