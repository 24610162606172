import * as yup from 'yup';

import { NOTE_MAX_LENGTH, TEXT_FIELD_MAX_LENGTH, TEXT_FIELD_NUMBER_MAX_LENGTH } from '@/utils/constants/AppConstants';
import { stringifyObjectValidateYup } from '@/utils/method';

export const FormRequiredDocument = yup.object().shape({
  projectRequiredDocuments: yup.array().of(
    yup.object().shape({
      documentId: yup
        .string()
        .required(
          stringifyObjectValidateYup({
            keyT: 'common:MSG_001_select',
            optionsTx: { field: 'required_documents:document_name' }
          })
        )
        .max(
          TEXT_FIELD_MAX_LENGTH,
          stringifyObjectValidateYup({
            keyT: 'common:MSG_C_011',
            optionsTx: { field: 'required_documents:document_name', limited: TEXT_FIELD_MAX_LENGTH }
          })
        ),
      numberOfCopy: yup.string().max(
        TEXT_FIELD_NUMBER_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: { field: 'required_documents:number_of_copies', limited: TEXT_FIELD_NUMBER_MAX_LENGTH }
        })
      ),
      note: yup.string().htmlStrippedMaxLength(
        NOTE_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: { field: 'required_documents:notes', limited: NOTE_MAX_LENGTH }
        })
      ),
      submissionToMot: yup.string().required(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_001_select',
          optionsTx: { field: 'required_documents:submission_mot' }
        })
      )
    })
  ),
  projectInternalDocuments: yup.array().of(
    yup.object().shape({
      documentId: yup
        .string()
        .required(
          stringifyObjectValidateYup({
            keyT: 'common:MSG_001_select',
            optionsTx: { field: 'required_documents:document_name' }
          })
        )
        .max(
          TEXT_FIELD_MAX_LENGTH,
          stringifyObjectValidateYup({
            keyT: 'common:MSG_C_011',
            optionsTx: { field: 'required_documents:document_name', limited: TEXT_FIELD_MAX_LENGTH }
          })
        ),
      numberOfCopy: yup.string().max(
        TEXT_FIELD_NUMBER_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: { field: 'required_documents:number_of_copies', limited: TEXT_FIELD_NUMBER_MAX_LENGTH }
        })
      ),
      note: yup.string().htmlStrippedMaxLength(
        NOTE_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: { field: 'required_documents:notes', limited: NOTE_MAX_LENGTH }
        })
      )
    })
  )
});
