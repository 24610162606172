import { get } from 'lodash';
import { AnyObject, Maybe, TestContext, ValidationError, addMethod, string } from 'yup';

import { SUFFIX_FOR_VALIDATE } from '@/components/form-box-editor/FormEditor';

import { CustomError } from '../constants';
import { EMAIL_REGEX, ERROR_CODE, LOCALSTORAGE, URL_REGEX } from '../constants/AppConstants';
import { validationEmail } from '../services/ProjectApiService';
import { stripHtml } from './globalHelper';
import { isFullSize, isHalfSize } from './validateHelper';

declare module 'yup' {
  interface StringSchema<TType, TContext, TDefault, TFlags> {
    fullSize(msg: any): this;
    halfSize(msg: any): this;
    fullEmail(msg: any): this;
    htmlStrippedMaxLength(maxLength: number, msg: any): this;
    notUrl(msg: any): this;

    /** This function control debouncing validate text field
     * Attention:
     * Field's name must uniq on current page.
     * This may be a rare situation, but using two fields with the same name for  this method can cause unexpected errors.
     * @class Watcher control register debounce of field
     * @param cbFunction - this callback function as promise. It return value of validate as boolean.
     * @param msg - message when error.
     * @param delay - time delay to debounce call cbFunction.
     */
    debounceValidate(cbFunction: (value: string, ctx: TestContext<Maybe<AnyObject>>) => Promise<Boolean>, msg: string, delay?: number): this;
    /**
     * @description This function validate email stakeholder by calling API with debounce
     * @param delay - time delay to debounce call cbFunction. Default is 300ms.
     */
    stakeHolderEmailValidate(delay?: number): this;
    stakeHolderEmailInternalValidate(delay?: number): this;
    requiredTextEditorChange(msg: any): this;
  }
}

addMethod(string, 'fullSize', function fullSize(msg) {
  return this.test('fullSize', msg, (value) => {
    return isFullSize(value);
  });
});

addMethod(string, 'halfSize', function halfSize(msg) {
  return this.test('halfSize', msg, (value) => {
    return isHalfSize(value);
  });
});

addMethod(string, 'fullEmail', function fullEmail(msg) {
  return this.test('fullEmail', msg, (value) => (value ? EMAIL_REGEX.test(String(value)) : true));
});

addMethod(string, 'htmlStrippedMaxLength', function htmlStrippedMaxLength(maxLength, msg) {
  return this.test('htmlStrippedMaxLength', msg, (value, ctx) => {
    const fieldName = get(ctx, `options.key`);
    const fieldCounter = get(ctx, `parent.${fieldName}${SUFFIX_FOR_VALIDATE}`);
    const textContent = stripHtml(value || '');
    const count = fieldName && typeof fieldCounter === 'number' ? fieldCounter : textContent.length;
    return count <= maxLength;
  });
});

addMethod(string, 'notUrl', function url(msg) {
  return this.test('invalid-url', msg, (value) => (value ? !URL_REGEX.test(String(value)) : true));
});

addMethod(string, 'requiredTextEditorChange', function requiredTextEditorChange(msg) {
  return this.test({
    name: 'requiredTextEditorChange',
    exclusive: false,
    params: {},
    message: msg,
    test: (value, ctx) => {
      const fieldName = get(ctx, `options.key`);
      let fieldCounter = get(ctx, `parent.${fieldName}${SUFFIX_FOR_VALIDATE}`);
      if (fieldCounter === undefined) {
        fieldCounter = stripHtml(value || '').length;
      }
      return fieldCounter && fieldCounter !== 0;
    }
  });
});

interface IWatcherResolvers {
  [key: string]: { context: Promise<boolean | ValidationError>; next: (...args: any[]) => boolean };
}
interface IWatcherTimeLogs {
  [key: string]: NodeJS.Timeout;
}
class Watcher {
  name = 'debounceValidate';
  resolvers: IWatcherResolvers = {};
  timeLogs: IWatcherTimeLogs = {};

  private _getResolver(path: string) {
    return this.resolvers[path];
  }

  private _register(path: string) {
    if (this._isExist(path)) return this;
    let resolve: any = () => {};
    this.resolvers[path] = {
      context: new Promise((res) => {
        resolve = res;
      }),
      next: resolve
    };
  }

  private _unRegister(path: string) {
    if (this._isExist(path)) {
      delete this.resolvers[path];
    }
    return this;
  }

  private _isExist(path: string) {
    return Boolean(this.resolvers[path]);
  }

  private _addTimeout(path: string, id: NodeJS.Timeout) {
    this.timeLogs[path] = id;
    return this;
  }

  private _removeTimeout(path: string) {
    const timeoutId = this.timeLogs[path];
    if (timeoutId) clearTimeout(timeoutId);
  }

  debounce(path: string, func: Function, delay: number) {
    this._register(path);
    const resolver = this._getResolver(path);
    this._removeTimeout(path);
    const timeoutId = setTimeout(
      () =>
        func()
          .then((res: boolean) => {
            this._unRegister(path);
            resolver.next(res);
          })
          .catch(() => {
            this._unRegister(path);
            resolver.next(false);
          })
          .finally(() => {
            delete this.timeLogs[path];
          }),
      delay
    );
    this._addTimeout(path, timeoutId);
    return resolver;
  }
}
const watcher = new Watcher();

addMethod(
  string,
  'debounceValidate',
  function debounceValidate(
    cbFunction: (val: string | undefined, ct: TestContext<Maybe<AnyObject>>) => Promise<boolean>,
    msg: string,
    delay: number = 300
  ) {
    return this.test('debounceValidate', msg, (value, ctx) => {
      const resolver = watcher.debounce(ctx.path, () => cbFunction(value as string, ctx), delay);
      return resolver.context;
    });
  }
);

/**
 * @description This function validate email stakeholder by calling API with debounce
 */
const validateEmailStakeHolder = async (value: any, ctx: any, companyId: string, userEmail: string) => {
  const skipValid =
    (ctx.parent.id && ctx.parent.rejected) ||
    (!ctx.parent.canDelete && ctx.parent.waitCreateAccount) ||
    (ctx.parent.id && !ctx.parent.stakeHolderStatus);
  if (skipValid) return true;
  if (!companyId || !value || !EMAIL_REGEX.test(value)) return true;
  const params = {
    emails: [value || ''],
    companyId
  };
  try {
    if (userEmail === value) {
      throw new CustomError({ type: 'duplicate', message: 'common:MSG_P_037' });
    }
    const res = await validationEmail(params);
    if (res?.data[0].errorCode === ERROR_CODE.ENTITY_NOT_FOUND || res?.data[0].errorCode === ERROR_CODE.ENTITY_REJECTED) {
      throw new CustomError({ type: ERROR_CODE.ENTITY_NOT_FOUND, message: 'common:MSG_P_006' });
    } else if (res?.data[0].errorCode === ERROR_CODE.USER_RESTRICT) {
      throw new CustomError({ type: ERROR_CODE.USER_RESTRICT, message: 'common:MSG_P_033' });
    } else if (res?.data[0].errorCode === ERROR_CODE.USER_INACTIVE && !res?.data?.waitingCreate) {
      throw new CustomError({ type: ERROR_CODE.USER_INACTIVE, message: 'common:MSG_P_034' });
    }
    return true;
  } catch (error: any) {
    return ctx.createError({ type: error.type, message: error.message });
  }
};

addMethod(string, 'stakeHolderEmailValidate', function stakeHolderEmailValidate(delay: number = 300) {
  return this.test('stakeHolderEmailValidate', 'stakeholder email invalid', (value, ctx) => {
    const userProfiles = localStorage.getItem(LOCALSTORAGE.USER) ?? '{}';
    const companyId = JSON.parse(userProfiles)?.organizationId;
    const userEmail = JSON.parse(userProfiles)?.email;
    const resolver = watcher.debounce(ctx.path, () => validateEmailStakeHolder(value as string, ctx, companyId, userEmail), delay);
    return resolver.context;
  });
});

addMethod(string, 'stakeHolderEmailInternalValidate', function stakeHolderEmailInternalValidate(delay: number = 300) {
  return this.test('stakeHolderEmailInternalValidate', 'stakeholder email invalid', (value, ctx) => {
    const userProfiles = localStorage.getItem(LOCALSTORAGE.USER) ?? '{}';
    const userEmail = JSON.parse(userProfiles)?.email;
    const companyId = ctx?.from[1]?.value?.applicantCompany?.value;
    const resolver = watcher.debounce(ctx.path, () => validateEmailStakeHolder(value as string, ctx, companyId, userEmail), delay);
    return resolver.context;
  });
});
