import classNames from 'classnames';
import React, { MouseEventHandler } from 'react';

const EditButton = ({ onClick, className }: { className?: string; onClick: MouseEventHandler<SVGSVGElement> }) => {
  return (
    <svg
      className={classNames('invisible cursor-pointer group-hover:visible group active:scale-95', className)}
      width='40'
      height='32'
      viewBox='0 0 40 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <g filter='url(#filter0_d_8883_616116)'>
        <path
          d='M2 10C2 5.58172 5.58172 2 10 2H28C32.4183 2 36 5.58172 36 10V20C36 24.4183 32.4183 28 28 28H10C5.58172 28 2 24.4183 2 20V10Z'
          fill='#F5F5F7'
          shapeRendering='crispEdges'
        />
        <path
          className='active:stroke-action'
          d='M16.75 20.1889H13.375C13.2258 20.1889 13.0827 20.1296 12.9773 20.0241C12.8718 19.9187 12.8125 19.7756 12.8125 19.6264V16.4834C12.8122 16.4104 12.8264 16.338 12.8541 16.2705C12.8819 16.2029 12.9227 16.1415 12.9742 16.0897L21.4117 7.65218C21.4641 7.59904 21.5265 7.55683 21.5953 7.52802C21.6641 7.49921 21.7379 7.48437 21.8125 7.48438C21.8871 7.48437 21.9609 7.49921 22.0298 7.52802C22.0986 7.55683 22.1609 7.59904 22.2133 7.65218L25.3492 10.7881C25.4024 10.8405 25.4446 10.9029 25.4734 10.9717C25.5022 11.0405 25.517 11.1143 25.517 11.1889C25.517 11.2635 25.5022 11.3373 25.4734 11.4061C25.4446 11.475 25.4024 11.5373 25.3492 11.5897L16.75 20.1889Z'
          stroke='#525A68'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          className='active:stroke-action'
          d='M25.1875 20.1875H16.75'
          stroke='#525A68'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          className='active:stroke-action'
          d='M19.5625 9.5L23.5 13.4375'
          stroke='#525A68'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <filter id='filter0_d_8883_616116' x='0' y='0' width='40' height='32' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
          <feOffset dx='1' dy='1' />
          <feGaussianBlur stdDeviation='1.5' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_8883_616116' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_8883_616116' result='shape' />
        </filter>
      </defs>
    </svg>
  );
};

export default EditButton;
