import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Counters } from '@/utils/helpers/counters';

/**
 * @description This hook supports counting the number of fields in the field array.
 * ---
 * @example - Example usage:
 * ```tsx
 * const defaultValues = {steps: [{ member: {answer: 'yes', answer2: 'ok'}} ] };
 * const form = useForm({ defaultValues });
 * const { answered, total } = useCountField('steps[0]', ['answer1', 'answer2']);
 * console.log(answered, total); // 1, 2
 * ```
 * ---
 * @param {string} parentPath - The parent path of the field array.
 * @param {string[]} subscriptions - The subscriptions of the field array.
 */
const useCountField = <T extends string = string>(parentPath: T, subscriptions: T[]) => {
  const [answered, setAnswered] = useState(0);
  const { watch } = useFormContext();
  const [formValues] = watch([parentPath]);

  const counterSchema = {
    sub: subscriptions
  };

  const cleanup = () => {
    setAnswered(0);
  };

  const calculateCount = () => {
    if (!formValues) {
      cleanup();
      return;
    }
    const counters = new Counters(counterSchema, formValues);
    const newAnswered = counters.get('sub');
    setAnswered(newAnswered);
  };

  const refCallback = useRef(calculateCount);
  useEffect(() => {
    refCallback.current = calculateCount;
  });

  useEffect(() => {
    refCallback?.current?.();
  }, []);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (type !== 'change' || !name || !name.includes(parentPath)) return;
      refCallback?.current?.();
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return { answered, total: subscriptions.length };
};

export default useCountField;
