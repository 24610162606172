import { useRef } from 'react';

const useMiniWindow = () => {
  const miniWindow = useRef<Window | null>(null);

  const open = (url: string, title: string, width: number = 1250, height: number = 1647) => {
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    miniWindow.current = window.open(url, title, `location=yes,width=${width},height=${height},left=${left},top=${top},scrollbars=yes,status=yes`);
  };

  return { miniWindow, open };
};

export default useMiniWindow;
