import React from 'react';

import AppTooltip from '@/components/app-tooltip/AppTooltip';

import useAuthorization from '@/hooks/useAuthorization';

import { FORMAT_TIME } from '@/utils/constants/AppConstants';
import { DataViewer } from '@/utils/helpers/common';
import { ID_SYSTEM } from '@/utils/helpers/constants';
import { parseJson } from '@/utils/helpers/globalHelper';
import { createParamTypeNotice } from '@/utils/helpers/notification';
import { INoticeData } from '@/utils/interfaces/notificationInterface';
import { ColorDefault } from '@/utils/themes/color';

import System from '@/assets/icons/notification/System.svg';

type ITypeNoticeProps = {
  data: INoticeData;
};

const TypeMessageNotice = ({ data }: ITypeNoticeProps) => {
  const { isInternalRole } = useAuthorization();
  const isNoticeSystem = data.createdBy === ID_SYSTEM;
  const convertParam = parseJson(data?.params ?? '');
  const paramTypeNotice = createParamTypeNotice(data.content, convertParam, data.origin, isInternalRole, String(data.originUserName));
  const title = [paramTypeNotice?.projectCode, paramTypeNotice?.companyName, paramTypeNotice?.travelOrApplicantName].filter((i) => i).join(' ');

  return (
    <>
      <div className='form-image center-item pl-4'>
        {!data.readed && <div className='online w-[14px] h-[14px]'></div>}
        <div
          className='avatar w-[50px] h-[50px] center-item justify-center body-700'
          style={{ backgroundColor: isNoticeSystem ? ColorDefault.white : convertParam.color || data.color || data.originUserColor }}
        >
          {isNoticeSystem ? <System /> : <>{paramTypeNotice?.userName}</>}
        </div>
      </div>
      <div className='pl-4'>
        <div className='max-w-[450px]'>
          <AppTooltip className='form-title' title={paramTypeNotice?.title}>
            <span className={`${!data.readed ? 'body-700' : 'body-400'}`}>{paramTypeNotice?.title}</span>
          </AppTooltip>
        </div>
        <AppTooltip className={`d-flex ${!data.readed ? 'body-700' : 'body-400'}`} title={title}>
          {paramTypeNotice?.projectCode && (
            <div className='max-w-[150px]'>
              <span className='pr-4 block truncate'>{paramTypeNotice?.projectCode}</span>
            </div>
          )}
          {paramTypeNotice?.companyName && (
            <div className='max-w-[150px]'>
              <span className='pr-4 block truncate'>{paramTypeNotice?.companyName}</span>
            </div>
          )}
          {paramTypeNotice?.travelOrApplicantName && (
            <div className='max-w-[150px]'>
              <span className='block truncate'>{paramTypeNotice?.travelOrApplicantName}</span>
            </div>
          )}
        </AppTooltip>
        <div className='justify-space-between w-[125px]'>
          <span className='body-small-regular'>{DataViewer.displayTime(paramTypeNotice?.createDate)}</span>
          <span className='body-small-regular'>{DataViewer.displayTime(paramTypeNotice?.createDate, FORMAT_TIME)}</span>
        </div>
      </div>
    </>
  );
};

export default TypeMessageNotice;
