import * as yup from 'yup';

import { NOTE_MAX_LENGTH, UPLOAD_STATUS } from '@/utils/constants/AppConstants';
import { stringifyObjectValidateYup } from '@/utils/method';

export const stepSixSchema = yup.object().shape({
  note: yup
    .string()
    .nullable()
    .htmlStrippedMaxLength(
      NOTE_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'basic_information:note', limited: NOTE_MAX_LENGTH }
      })
    ),
  projectAttachments: yup.array().of(
    yup.object().shape({
      status: yup.string().test(
        'file-invalid',
        stringifyObjectValidateYup({
          keyT: 'common:MSG_FILE_INVALID'
        }),
        (value) => value === UPLOAD_STATUS.DONE
      )
    })
  )
});
